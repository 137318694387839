<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="el-tabs__content pj">
        <el-form
          ref="form"
          :model="form"
          label-width="90px"
          class="pjform"
          label-position="left"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="票据类型:">
                <el-button
                  type="text"
                  :class="{ active: item.active }"
                  size="small"
                  v-for="(item, index) in billtype"
                  :key="index"
                  @click="billtypefn(item, index)"
                  >{{ item.name }}</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="剩余期限:">
                <el-button
                  type="text"
                  :class="{ active: item.active }"
                  size="small"
                  v-for="(item, index) in billdate"
                  :key="index"
                  @click="billtypefn(item, index)"
                  >{{ item.name }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="票面金额:">
                <el-button
                  type="text"
                  :class="{ active: item.active }"
                  size="small"
                  v-for="(item, index) in billprice"
                  :key="index"
                  @click="billtypefn(item, index)"
                  >{{ item.name }}</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="瑕疵:">
                <el-button
                  type="text"
                  :class="{ active: item.active }"
                  size="small"
                  v-for="(item, index) in flaw"
                  :key="index"
                  @click="billtyperadiofn(item, index)"
                  >{{ item.name }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="综合筛选:">
              <el-radio-group v-model="radioinfo" @change="radiofn">
                <el-radio :label="0">全部</el-radio>

                <el-radio :label="1">我报价的</el-radio>
                <el-radio :label="2">白名单票据</el-radio>
                <el-radio :label="3">偏好企业</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-col :span="6">
              <el-form-item label="我报价的:">
                <el-checkbox-group v-model="form.quotedBill">
                  <el-checkbox label="我报价的" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="白名单票据:">
                <el-checkbox-group v-model="form.whiteCorpBill">
                  <el-checkbox label="白名单票据" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
                <el-col :span="6">
              <el-form-item label="偏好企业:">
                <el-checkbox-group v-model="form.payAttention">
                  <el-checkbox label="偏好企业" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col> -->
          </el-row>
          <div class="pjgjss" v-show="isshowlable">
            <el-row>
              <el-col :span="6">
                <el-form-item label="高级搜索:">
                  <el-input
                    v-model="form.acceptorSearch"
                    placeholder="请输入承兑人"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.billAmountStr"
                    placeholder="最低金额(万)"
                    type="number"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5" class="pjblx">
                <div>~</div>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.billAmountEnd"
                    placeholder="最高金额(万)"
                    type="number"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.minOfDays"
                    placeholder="最少天数"
                    size="small"
                    type="number"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5" class="pjblx">
                <div>~</div>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.maxOfDays"
                    placeholder="最多天数"
                    size="small"
                    type="number"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" class="pjsearchbtn"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <div class="pjsearch">
          <span @click="ishowlablefn">
            {{ !isshowlable ? '高级搜索' : '点击收起' }}
            <i
              class="el-submenu__icon-arrow el-icon-arrow-down"
              :class="{ isacitve: isshowlable }"
            ></i>
          </span>
        </div>
        <el-table :data="listData" highlight-current-row style="width: 100%">
          <el-table-column label="类型" width="100">
            <template slot-scope="scope">{{
              scope.row.billType | billType
            }}</template>
          </el-table-column>
          <el-table-column label="发布时间" min-width="170">
            <template slot-scope="scope">{{
              scope.row.createTime | formatDate
            }}</template>
          </el-table-column>
          <el-table-column
            prop="acceptor"
            label="承兑人"
            min-width="150"
          ></el-table-column>
          <el-table-column label="票面金额(元)" min-width="150" align="right">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.billAmount)
            }}</template>
          </el-table-column>
          <el-table-column label="到期日" min-width="150">
            <template slot-scope="scope">{{
              scope.row.expireTime | formatDate
            }}</template>
          </el-table-column>
          <el-table-column label="年化利率" min-width="150">
            <template slot-scope="scope">{{
              scope.row.sellRateStr + '~' + scope.row.sellRateEnd
            }}</template>
          </el-table-column>
          <el-table-column label="每十万元扣费(元)" min-width="160">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.deductionStr) +
              '~' +
              $changeMoneyThousand(scope.row.deductionEnd)
            }}</template>
          </el-table-column>

          <el-table-column label="瑕疵" width="80">
            <template slot-scope="scope">{{
              scope.row.flawStatus == 1 ? '无' : '有'
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <!-- <el-button size="medium" type="text" @click="handleEdit(scope.row)" >我要报价</el-button> -->

              <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                v-if="
                  scope.row.quotationStatus == 1 ||
                  scope.row.quotationStatus == 3 ||
                  scope.row.quotationStatus == 5 ||
                  scope.row.quotationStatus == 8 ||
                  scope.row.quotationStatus == null
                "
                >我要报价</el-button
              >
              <el-button
                size="medium"
                type="text"
                @click="handleinfo(scope.row)"
                v-if="
                  scope.row.quotationStatus == 2 ||
                  scope.row.quotationStatus == 4
                "
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="data-list">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="pageSize"
            :page-size="pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="票据详情"
      :visible.sync="dialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
      class="billinfoprice"
      v-if="dialogVisible"
    >
      <billinfopirce ref="chi" :billid="billid"></billinfopirce>
      <span slot="footer" class="dialog-footer">
        <el-button class="handelDialog" type="primary" @click="handelDialog(1)"
          >查看交易信息</el-button
        >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="surebtn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="交易信息详情"
      :visible.sync="contractDialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
      class="contractInfo"
      v-if="contractDialogVisible"
    >
      <section class="info-card-A">
        <div class="card-number">
          <!-- <div class="bill-title" style="margin-top: 30px;">合同信息</div> -->
          <div class="httotal" style="margin-top: 20px">
            已添加合同
            <span>{{ financInfo.list && financInfo.list.length }}</span>
            笔，合同总计金额
            <span>{{ $changeMoneyThousand(contractAmount) }}</span> 元。
          </div>
          <div
            class="maincontin"
            v-for="(item, index) in financInfo.list"
            :key="index"
          >
            <div :class="{ 'collapse-active': item.isCollapse }">
              <span class="mainht">
                <i
                  aria-hidden="true"
                  :class="
                    item.isCollapse ? 'fa fa-plus-circle' : 'fa fa-minus-circle'
                  "
                  @click="item.isCollapse = !item.isCollapse"
                ></i>
                <span class="info-key">合同编号:</span>
                <span class="info-value">{{ item.contractNo }}</span>
                <a
                  class="page-head-back downsize"
                  size="small"
                  @click="downloadfn(item)"
                >
                  <i class="el-icon-download"></i>
                  <span>一键下载</span>
                </a>
              </span>

              <div class="htmsg1">
                <el-row>
                  <el-col :span="8" class="lb_body">
                    <span class="info-key">合同编号:</span>
                    <span class="info-value">{{ item.contractNo }}</span>
                  </el-col>
                  <el-col :span="8" class="lb_body">
                    <span class="info-key">合同金额:</span>
                    <span class="info-value"
                      >{{ $changeMoneyThousand(item.contractAmount) }}元</span
                    >
                  </el-col>
                  <el-col :span="8" class="lb_body">
                    <span class="info-key">合同签署时间:</span>
                    <span class="info-value">{{
                      moment(item.contractTime).format('YYYY-MM-DD')
                    }}</span>
                  </el-col>
                </el-row>
              </div>
              <div class="card-hd sub-title">相关合同</div>
              <div
                class="card-bd marrimg"
                style="padding-left: 30px; padding-right: 30px"
              >
                <transition
                  name="fade"
                  v-if="
                    item.billContractList &&
                    item.billContractList.length &&
                    item.billContractList.length > 0
                  "
                >
                  <el-row>
                    <picfile :picfilelist="item.billContractList"></picfile>
                  </el-row>
                </transition>
                <div>
                  <div>
                    共有文件:
                    <span class="numcolor">{{
                      item.billContractList.length
                    }}</span>
                    个
                  </div>
                </div>
              </div>
              <div class="card-hd sub-title">相关发票</div>
              <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
                <el-row>
                  <picfile :picfilelist="item.billInvoiceList"></picfile>
                </el-row>
                <div style="padding: 0px 0px 30px 0px">
                  共有文件:
                  <span class="numcolor">{{
                    item.billInvoiceList.length
                  }}</span>
                  个
                  <span style="margin-left: 32px">累计发票金额：</span>
                  <span class="numcolor">{{
                    $changeMoneyThousand(item.invoiceAllAmount)
                  }}</span>
                  元
                </div>
                <div>
                  <el-table
                    :data="item.billInvoiceList"
                    highlight-current-row
                    v-loading="listLoading"
                    style="width: 100%"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="80"
                    ></el-table-column>
                    <el-table-column
                      prop="invoiceBuyer"
                      label="购买方"
                      min-width="200"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceBuyer }}</el-badge
                        >
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="invoiceSeller"
                      label="销售方"
                      min-width="200"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceSellerChange == 2 ? '改' : ''
                          "
                          >{{ scope.row.invoiceSeller }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceCode"
                      label="发票代码"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceCode }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceNo"
                      label="发票号码"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceNo }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                    prop="invoiceCheckCode"
                    label="校验码"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-badge
                        class="isdot"
                        :value="
                          scope.row.invoiceCheckCodeChange == 2 ? '改' : ''
                        "
                        >{{ scope.row.invoiceCheckCode }}</el-badge
                      >
                    </template>
                  </el-table-column>
                    <el-table-column
                      prop="invoiceOpenTime"
                      label="开票日期"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceOpenTimeChange == 2 ? '改' : ''
                          "
                          >{{ scope.row.invoiceOpenTime }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceAmount"
                      label="开具金额(元)"
                      width="200"
                      align="left"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceAmountChange == 2 ? '改' : ''
                          "
                          >{{
                            $changeMoneyThousand(scope.row.invoiceAmount)
                          }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceAmountWithoutTax"
                      label="开具金额(不含税)"
                      width="200"
                      align="left"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceAmountChange == 2 ? '改' : ''
                          "
                          >{{
                            $changeMoneyThousand(
                              scope.row.invoiceAmountWithoutTax
                            )
                          }}</el-badge
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelDialog(2)"
          >查看票据信息</el-button
        >
      </span>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from '@/api/api.js';
import billinfopirce from '../pjbase/billinfopirce.vue';
import moment from 'moment';
import picfile from '@/components/picfile';

function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
export default {
  components: {
    billinfopirce: billinfopirce,
    picfile,
  },
  data() {
    return {
      dialogVisible: false,
      breadcrumblist: ['票据中心'],
      pageSizes: 10,
      pageNum: 1,
      form: {
        billTypes: [], //票据类型
        billExpires: [], //剩余期限,
        billAmounts: [], //票面金额,
        quotedBill: false, //报价的票据,
        whiteCorpBill: false, //白名单票据
        payAttention: false, //偏好设置
        flawStatus: '',
        acceptorSearch: '', //承兑人,
        billAmountStr: '', //最低金额,
        billAmountEnd: '', //最高金额,
        minOfDays: '', //最少天数,
        maxOfDays: '', //最多天数
      },
      radioinfo: 0,
      billtype: [
        { name: '商票', active: false, value: 1 },
        { name: '国股', active: false, value: 2 },
        { name: '城商', active: false, value: 3 },
        { name: '三农', active: false, value: 4 },
        { name: '财票', active: false, value: 5 },
      ],
      billprice: [
        { name: '10万以下', active: false, value: 1 },
        { name: '10-100万', active: false, value: 2 },
        { name: '100万以上', active: false, value: 3 },
      ],
      billdate: [
        { name: '90天以内', active: false, value: 1 },
        { name: '90-180天', active: false, value: 2 },
        { name: '180-360天', active: false, value: 3 },
      ],
      flaw: [
        { name: '有瑕疵', active: false, value: 2 },
        { name: '无瑕疵', active: false, value: 1 },
      ],
      listData: [],
      pageSize: [5, 10, 20, 30],
      total: 0,
      isshowlable: false,
      showlable: '高级搜索',
      billid: '',
      quotationStatus: '',

      contractDialogVisible: false,
      listLoading: false,
      financInfo: {},
      detailsList: {},
      contractAmount: '',
    };
  },
  created() {
    this.getlist({
      pageNum: 1,
      pageSize: 10,
      quotedBill: false,
      whiteCorpBill: false,
      payAttention: false,
    });
  },
  methods: {
    moment: moment,
    onSubmit() {
      this.form.billTypes = [];
      this.form.billExpires = [];
      this.form.billAmounts = [];
      this.billtype.map((el) => {
        el.active && this.form.billTypes.push(el.value);
      });
      this.billdate.map((el) => {
        el.active && this.form.billExpires.push(el.value);
      });
      this.billprice.map((el) => {
        el.active && this.form.billAmounts.push(el.value);
      });
      if (this.flaw.some((el) => el.active)) {
        this.flaw.map((el) => {
          el.active && (this.form.flawStatus = el.value);
        });
      } else {
        this.form.flawStatus = '';
      }

      this.getlist(this.form);
    },
    resetForm(formName) {
      console.log(this.$refs[formName].resetFields);
      this.billtype.map((el) => (el.active = false));
      this.$refs[formName].resetFields();
    },
    billtypefn(item, index) {
      item.active = !item.active;
    },
    billtyperadiofn(item, index) {
      if (item.active) {
        item.active = false;
      } else {
        this.flaw.map((el) => {
          el.active = false;
        });
        item.active = true;
      }
    },
    handleSizeChange(val) {
      this.pageSizes = val;

      this.$router.replace({
        path: '/bill/billcenterzj',
        query: { pageSize: this.pageSizes, pageNum: 1 },
      });
      var params = Object.assign(this.form, { pageNum: 1, pageSize: val });
      this.getlist(params);

      console.log(`每页 ${val} 条`, this.pageNum);
    },
    handleCurrentChange(val) {
      console.log(val, 999);
      this.pageNum = val;
      this.$router.replace({
        path: '/bill/billcenterzj',
        query: { pageSize: this.pageSizes, pageNum: this.pageNum },
      });
      var params = Object.assign(this.form, {
        pageNum: val,
        pageSize: this.pageSizes,
      });
      this.getlist(params);
      console.log(`当前页: ${val}`);
    },
    getlist(params) {
      if (this.$route.query.pageNum) {
        this.pageNum = +this.$route.query.pageNum;
        this.pageSizes = +this.$route.query.pageSize;
      }
      //      console.log(params,22)

      this.$router.push({
        path: '/bill/billcenterzj',
        query: { pageSize: this.pageSizes, pageNum: this.pageNum },
      });
      api.billHallZj(params).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    ishowlablefn() {
      this.isshowlable = !this.isshowlable;
    },
    surebtn() {
      this.$refs.chi.$refs['formchild'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$refs.chi.offerprice.allChargeAmount =
            +this.$refs.chi.offerprice.allChargeAmount.replace(/\,/g, '');
          this.$refs.chi.offerprice.chargeAmount =
            +this.$refs.chi.offerprice.chargeAmount.replace(/\,/g, '');

          this.$refs.chi.offerprice.dealAmount =
            +this.$refs.chi.offerprice.dealAmount.replace(/\,/g, '');
          this.$refs.chi.offerprice.quotationStatus = this.quotationStatus;

          api.fundSideQuote(this.$refs.chi.offerprice).then((res) => {
            if (res.success) {
              this.$message.success('报价成功');
              this.onSubmit();
            } else {
              this.$message.error(res.message);
            }
            loading.close();
            this.dialogVisible = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleEdit(val) {
      this.dialogVisible = true;
      this.billid = val.billId;
      this.quotationStatus = val.quotationStatus;

      this.getInfo({ billId: val.billId });
    },
    handleinfo(row) {
      this.$router.push({
        path: '/bill/bill-approvaled-details',
        query: { id: row.billId, isshowback: 1, billFundStatus: 2, isjk: 1 },
      });
    },
    radiofn(val) {
      switch (val) {
        case 0:
          this.form.quotedBill = false;
          this.form.whiteCorpBill = false;
          this.form.payAttention = false;
          break;
        case 1:
          this.form.quotedBill = true;
          this.form.whiteCorpBill = false;
          this.form.payAttention = false;
          break;
        case 2:
          this.form.quotedBill = false;
          this.form.whiteCorpBill = true;
          this.form.payAttention = false;
          break;
        case 3:
          this.form.quotedBill = false;
          this.form.whiteCorpBill = false;
          this.form.payAttention = true;
          break;
      }
      console.log(val);
    },
    handelDialog(val) {
      if (val == 1) {
        this.contractAmount = JSON.parse(
          sessionStorage.getItem('contractAmount')
        );
        this.dialogVisible = false;
        this.contractDialogVisible = true;
        console.log('----------执行了');
      } else if (val == 2) {
        this.dialogVisible = true;
        this.contractDialogVisible = false;
      }
    },
    //获取合同发票详情
    getInfo(param) {
      api.supplementDetail(param).then((res) => {
        if ((res.code = '100000')) {
          console.log(res, '-----------res');
          this.financInfo = res.datas;
          // this.financnum = res.datas.grantCredit;
          // this.financeFile = res.datas.financeFile;
          console.log(this.financInfo.list.length, '----------financInfo.list');
          if (this.financInfo.list.length > 0) {
            this.financInfo.list.forEach((el) => {
              this.$set(el, 'isCollapse', false);
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    downloadfn(item) {
      api
        .downLoadFile({
          billId: item.billId,
          billContractId: item.billContractId,
          fileName: item.contractName,
        })
        .then((res) => {
          openDownloadDialog(res, item.contractName + '.zip');
        })
        .catch((err) => {});
    },
  },
  watch: {
    // contractAmount: {
    //   immediate: true, // 很重要！！！
    //   handler (val) {
    //     this.contractAmount = JSON.parse(sessionStorage.getItem('contractAmount'))
    //   }
    // }
  },
};
</script>

<style lang="scss">
.el-tabs__content.pj {
  padding: 6px 24px 0 24px;
}
.pjform {
  margin-top: 10px;
  background: rgba(246, 247, 250, 1);
  border-radius: 2px;
  padding: 24px 25px;
  .el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(136, 136, 136, 1);
  }
  .el-button--text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding: 2px 8px;
  }
  .el-button--text.active {
    background: rgba(41, 55, 166, 1);
    border-radius: 4px;
    color: #ffffff;
    padding: 2px 8px;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .pjdouable {
    .el-form-item__content {
      margin-left: 0 !important;
    }
    margin-left: 20px;
  }
  .pjblx {
    text-align: right;
    width: 30px;
    position: relative;
    top: 10px;
  }
  .pjgjss {
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    padding-top: 16px;
  }
  .pjsearchbtn {
    width: 65px;
    height: 32px;
    line-height: 0;
  }
}
.pjsearch {
  .el-submenu__icon-arrow {
    position: inherit;
    color: rgba(0, 0, 0, 0.85) !important;
    margin-left: 8px;
  }
  span {
    background: rgba(246, 247, 250, 1);
    display: inline-block;
    padding: 1px 16px;
    border-radius: 2px;
    cursor: pointer;
  }
  .isacitve.el-submenu__icon-arrow {
    transform: rotateZ(180deg);
  }
  text-align: center;
  margin-bottom: 24px;
}
.contractInfo {
  .isdot {
    .el-badge__content.is-fixed {
      top: 10px;
      right: 0px;
    }
    .el-badge__content {
      background-color: #cfcfcf;
    }
  }
  .el-dialog__body {
    padding: 0 20px;
  }
  .el-dialog__footer {
    text-align: center;
  }
}
.page-head-back {
  color: #2937a6;
  float: right;
  margin-right: 40px;
  margin-top: 16px;
  border: 0px scope #fff;
  i {
    font-size: 14px;
  }
  &.downsize {
    margin-top: 0;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
  }
}
.sub-title {
  border-bottom: none;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #262626;
}
.card-number {
  position: relative;
  .maincontin {
    margin-top: 30px;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    border-radius: 4px;
    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: #f1f1f1;
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
.billinfoprice {
  .el-dialog__footer {
    position: relative;
    .handelDialog {
      position: absolute;
      top: 10px;
      left: 20px;
    }
  }
}
</style>
