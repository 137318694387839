<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
     align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="170"></el-table-column>
          <el-table-column prop="absPlanName" label="计划名称" min-width="170"  >
    
          </el-table-column>
          <el-table-column  label="计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'></el-table-column>
  
    
          <el-table-column  label="退回理由"  prop='reason' min-width="160" ></el-table-column>
          <el-table-column  label="备注"  prop='remark' min-width="160" ></el-table-column>


          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [

        {
          label: "项目名称: ",
          type: "input",
          field: "absPlanName",
          size: "small",
        },
      ],
      total: 0,
      listData: [],
      isbl:false
    };
  },
  created() {
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false
  },
  methods: {
    moment: moment,

    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {

 var temp=''

     temp= api.getReturnAbsPlanList(param)
   
     temp.then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        
        }
      });
    },
   handleEditinfo(row){
           this.$router.push({
         name: "专项计划简介",
                    query: { id: row.absApproveId,isshowreason:1,isshowbtn:2}
      });
    },

  }
};
</script>


