<!-- 票据业务统计 -->
<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="bill-statistics">
         <div class="section-second">
        <div class="bill-box">
                  <div class="bill-title">票据业务概况</div>
        </div>

      
       <div class="section-second-content">
        <div class="card-item card-bgImg1">
          <div class="card-money card-money1">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{ $changeMoneyThousand(totalListingBillAmount) }}</span>
            
            </div>
          </div>
          <div class="card-title">{{ this.corp.corpType === 1 ? "意向报价票据总额" : "挂牌票据总金额"}}(元)</div>
        </div>
        <div class="card-item card-bgImg2">
          <div class="card-money card-money2">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{$changeMoneyThousand(totalDelistingBillAmount) }}</span>
      
            </div>
          </div>
          <div class="card-title">{{ this.corp.corpType === 1 ? "摘牌成交票据总额" : "被摘牌票据总金额"}}(元)</div>
        </div>
    
      </div>
      </div>
      <!-- <div class="bill-box">
        <div class="bill-title">易票据业务概况</div>
        <div class="bill-cont">
          <div class="bill-card bg">
            <div class="bill-card-item">
              <div class="bill-card-item-title"> {{ this.corp.corpType === 1 ? "意向报价票据总额" : "挂牌票据总金额"}}</div>
              <div class="bill-card-item-total">
                <span>{{totalListingBillAmount}}</span>元
              </div>
            </div>
            <div class="bill-card-item">
              <div class="bill-card-item-title">{{ this.corp.corpType === 1 ? "摘牌成交票据总额" : "被摘牌票据总金额"}}</div>
              <div class="bill-card-item-total">
                <span>{{totalDelistingBillAmount }}</span>元
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="bill-box">
        <div class="bill-title">易票据业务总览</div>
        <div class="bill-cont">
          <el-row :gutter="30">
            <el-col :span="12" class="position-relative">
              <div id="listingChart" class="echarts-box"></div>
              <div class="select-wrap">
                <el-select v-model="value1" @change="changeFn">
                  <el-option
                    :value="item.value"
                    :label="item.label"
                    v-for="(item, index) in selOption"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" class="position-relative">
              <div id="delistingChart" class="echarts-box"></div>
              <div class="select-wrap">
                <el-select v-model="value2" @change="changeFn2">
                  <el-option
                    :value="item.value"
                    :label="item.label"
                    v-for="(item, index) in selOption"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import echarts from "echarts";
import api from "@/api/api";
const labelText = [
  ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
  [
    "1天",
    "2天",
    "3天",
    "4天",
    "5天",
    "6天",
    "7天",
    "8天",
    "9天",
    "10天",
    "11天",
    "12天",
    "13天",
    "14天",
    "15天",
    "17天",
    "18天",
    "19天",
    "20天",
    "21天",
    "22天",
    "23天",
    "24天",
    "25天",
    "26天",
    "27天",
    "28天",
    "29天",
    "30天",
    "31天"
  ],
  [
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月"
  ]
];
export default {
  data() {
    return {
      breadcrumblist: ["业务统计"],
      totalListingBillAmount: 0,
      totalDelistingBillAmount: 0,
      labelText: [],
      labelText1: [],
      labelText2: [],
      echart1Data: {
        xAxisData: [],
        data: [],
        data2: []
      },
      echart2Data: {
        xAxisData: [],
        data: [],
        data2: []
      },
      selOption: [
        { value: 1, label: "周视图" },
        { value: 2, label: "月视图" },
        { value: 3, label: "年视图" }
      ],
      value1: 1,
      value2: 1,
      selOption1: [],
      selOption2: [],
      corp: {},
    };
  },
  created() {
    var corp = JSON.parse(sessionStorage.getItem("corpList"));
    this.corp = corp;
  },
  mounted() {

    this.getListingBillCensusFn();

    //   企业端: 挂牌
    // 判断是资金端  还是企业端
    this.listing();

      //   企业端: 被摘牌

      this.delisting();
      this.getListingBillCensusOverviewFn();
      this.getListingBillDealCensusOverviewFn();



  },
  methods: {
    changeFn() {
      //   企业端: 挂牌
      //   this.echart1Data.xAxisData = labelText[this.value1 - 1];
      this.getListingBillCensusOverviewFn();
      
    },
    changeFn2() {
      //   企业端: 被摘牌
      //   this.echart2Data.xAxisData = labelText[this.value2 - 1];
      this.getListingBillDealCensusOverviewFn();
    },
    getListingBillCensusOverviewFn() {
    // 企业端: 挂牌票据额度统计总揽
    //这里区分 资金端 和 企业
    
    if(this.corp.corpType == 1){
      api
        .getDelistingQuoteBillCensusOverview({ dateCycleType: this.value1 })
        .then(res => {
          if (res.success) {
            // sumBillAmount :柱状
            // amount : 折线
            let data = res.datas.billCensusResponses;
            let labelText = [];
            if (this.value1 == 1) {
              labelText = data.map(item => {
                return "星期" + item.week;
              });
            }
            if (this.value1 == 2) {
              labelText = data.map(item => {
                return item.monthDate + "日";
              });
            }
            if (this.value1 == 3) {
              labelText = data.map(item => {
                return item.yearMonth + "月";
              });
            }
            this.echart1Data.data = data.map(item => {
              return item.sumBillAmount;
            });
            this.echart1Data.data2 = data.map(item => {
              return item.amount;
            });
            this.echart1Data.xAxisData = labelText;
          }
          this.listing();
          console.log(this.echart1Data.xAxisData, 8888);
        });
    }else {
            api
        .getListingBillCensusOverviewFn({ dateCycleType: this.value1 })
        .then(res => {
          if (res.success) {
            // sumBillAmount :柱状
            // amount : 折线
            let data = res.datas.billCensusResponses;
            let labelText = [];
            if (this.value1 == 1) {
              labelText = data.map(item => {
                return "星期" + item.week;
              });
            }
            if (this.value1 == 2) {
              labelText = data.map(item => {
                return item.monthDate + "日";
              });
            }
            if (this.value1 == 3) {
              labelText = data.map(item => {
                return item.yearMonth + "月";
              });
            }
            this.echart1Data.data = data.map(item => {
              return item.sumBillAmount;
            });
            this.echart1Data.data2 = data.map(item => {
              return item.amount;
            });
            this.echart1Data.xAxisData = labelText;
          }
          this.listing();
          console.log(this.echart1Data.xAxisData, 8888);
        });
    }


    },
    arrFn() {},
    getListingBillDealCensusOverviewFn() {
      // 企业端: 被摘牌票据额度统计总揽
      
    if(this.corp.corpType == 1){
      api
        .getDelistingBillCensusDealOverview({ dateCycleType: this.value2 })
        .then(res => {
          if (res.success) {

            let data = res.datas.billCensusResponses;
            let labelText = [];
            if (this.value2 == 1) {
              labelText = data.map(item => {
                return "星期" + item.week;
              });
            }
            if (this.value2 == 2) {
              labelText = data.map(item => {
                return item.monthDate + "日";
              });
            }
            if (this.value2 == 3) {
              labelText = data.map(item => {
                return item.yearMonth + "月";
              });
            }
            this.echart2Data.data = data.map(item => {
              return item.sumBillAmount;
            });
            this.echart2Data.data2 = data.map(item => {
              return item.amount;
            });
            this.echart2Data.xAxisData = labelText;
          
          }
          this.delisting();
        });
    }else {

      api
        .getListingBillDealCensusOverviewFn({ dateCycleType: this.value2 })
        .then(res => {
          if (res.success) {
        let data = res.datas.billCensusResponses;
            let labelText = [];
            if (this.value1 == 1) {
              labelText = data.map(item => {
                return "星期" + item.week;
              });
            }
            if (this.value1 == 2) {
              labelText = data.map(item => {
                return item.monthDate + "日";
              });
            }
            if (this.value1 == 3) {
              labelText = data.map(item => {
                return item.yearMonth + "月";
              });
            }
            this.echart2Data.data = data.map(item => {
              return item.sumBillAmount;
            });
            this.echart2Data.data2 = data.map(item => {
              return item.amount;
            });
            this.echart2Data.xAxisData = labelText;
          
          }
          this.delisting();
        });
    }

    },
    getListingBillCensusFn() {

    if(this.corp.corpType == 1){

      api.getDelistingBillCensus().then(res => {
        if (res.success) {
          // 总挂牌票据金额 ,
          this.totalListingBillAmount =
            res.datas.billCensusResponse.totalIntentionBillAmount;
          console.log(
            this.totalListingBillAmount,
            res.datas.billCensusResponse
          );
          // 总摘牌票据金额
          this.totalDelistingBillAmount =
            res.datas.billCensusResponse.totalIntentionDealBillAmount;
        }
      });

    }else {
        api.getListingBillCensus().then(res => {
        if (res.success) {
          // 总挂牌票据金额 ,
          this.totalListingBillAmount =
            res.datas.billCensusResponse.totalListingBillAmount;
          console.log(
            this.totalListingBillAmount,
            res.datas.billCensusResponse
          );
          // 总摘牌票据金额
          this.totalDelistingBillAmount =
            res.datas.billCensusResponse.totalDelistingBillAmount;
        }
      });
    }


    },
    listing() {
      let myChart = echarts.init(document.getElementById("listingChart"));
      myChart.setOption({
        title: {
          text:  this.corp.corpType === 1 ? "意向报价票据额度" : "挂牌票据额度",
          textStyle: {
            fontSize: 14
          },
          padding: [10, 0, 0, 20]
        },
        color: ["#1890FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: this.echart1Data.xAxisData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value"
          },
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "50%",
            data: this.echart1Data.data,
            itemStyle: {
              normal: {
                color: "rgba(24,144,255, 0.8)"
              }
            }
          },
          {
            name: "",
            type: "line",
            barWidth: "50%",
            data: this.echart1Data.data2,
            itemStyle: {
              normal: {
                color: "rgba(24,144,255, 0.8)"
              }
            }
          }
        ]
      });
    },
    delisting() {
      let myChart = echarts.init(document.getElementById("delistingChart"));
      myChart.setOption({
        title: {
          text: this.corp.corpType === 1 ? "摘牌成交票据额度" : "被摘牌票据额度",
          textStyle: {
            fontSize: 14
          },
          padding: [10, 0, 0, 20]
        },
        color: ["#1890FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: this.echart2Data.xAxisData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value"
          },
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "50%",
            data: this.echart2Data.data,
            itemStyle: {
              normal: {
                color: "rgba(251,222,99,1)"
              }
            }
          },
          {
            name: "",
            type: "line",
            barWidth: "50%",
            data: this.echart2Data.data2,
            itemStyle: {
              normal: {
                color: "rgba(251,222,99,1)"
              }
            }
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.bill-statistics {
   .section-second-content {
    height: 75%;
    background-color: white;
    .card-item {
      width: 24%;
      height: 100px;
      /*margin-left: 24px;*/
      margin-top: 30px;
      /*float: left;*/
      .card-title {
        margin-left: 0.8%;
        /*margin-top: 24px;*/
        font-size: 14px;
        padding-left: 40px;
        /*color: #fff;*/
      }
      .card-money1 {
        background-image: url("../../assets/zsx.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money2 {
        background-image: url("../../assets/zkl.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }

      .card-money {
        font-size: 30px;
        color: #262626;
        line-height: 45px;
        margin-left: 14px;
        margin-top: 14px;
        .money-bold {
          font-family: HelveticaNeue-Medium;
        }
      }
    }
  }
  .section-second-content {
    height: 75%;
    background-color: white;
    overflow: hidden;
    .card-item {
      width: 22%;
    
      margin-left: 24px;
      margin-top: 30px;
      float: left;
      .card-title {
        margin-left: 8px;
        /*margin-top: 24px;*/
        font-size: 14px;
        color: #fff;
      }
      .card-money {
        margin-left: 8px;
        margin-top: 14px;
        font-size: 14px;
        color: #fff;
        .money-bold {
          font-family: HelveticaNeue-Bold;
        }
      }
    }
  }
  .card-bgImg1 {
  background-image: url("../../assets/workbench/zsxeu.png");
  background-size: 100% 100%;
}
.card-bgImg2 {
  background-image: url("../../assets/workbench/zjded.png");
  background-size: 100% 100%;
}
  .section-second{
    margin: 0;
    width: 100%;
  }
  .bill-box {
    background: #fff;
    border-radius: 2px;

    .bill-title {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      // padding-left: 12px;
      // margin-bottom: 24px;
      padding: 16px 12px 16px 28px;
      border-bottom: 1px solid #e6e6e6;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -7px;
        width: 3px;
        height: 14px;
        background: #cb8e51;
      }
    }
    .bill-cont {
      padding: 24px;
      .bill-card {
        display: flex;
        align-items: center;
        padding: 32px;
        &.bg {
          width: 100%;
          height: 122px;
          background: url("../../assets/bg_pjywgk.png") no-repeat;
          background-size: 100% 100%;
          border-radius: 2px;
        }
        &-item {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
          &-title {
            margin-bottom: 4px;
          }
          &-total {
            span {
              font-size: 24px;
              color: #fff;
              line-height: 32px;
              font-weight: 600;
            }
          }
          &.bill-card-item {
            margin-left: 80px;
          }
        }
      }
      .echarts-box {
        height: 400px;
      }
    }
  }
  .position-relative {
    position: relative;
    .select-wrap {
      position: absolute;
      right: 60px;
      top: 0;
    }
  }
}
</style>
