<template>
  <div class="workbench">
    <div class="header-box clearfix">
      <div class="header-box1">
        <div class="logo-box">
          <div class="logo">
            <img
              class="logo-img"
              :src="getLogoUrl ? api.getImgURL(getLogoUrl) : defaultLogo"
              alt=""
            />
          </div>
          <div class="companyType" v-if="corpList.corpType == 2">
            {{ corpList.corpCode }}
          </div>
        </div>
        <div class="baseInfo-box">
          <div class="companyName">{{ $store.state.corpName }}</div>
          <div class="markInfo clearfix">
            <!-- <span class="markInfo1">受邀会员</span> -->
            <span
              class="markInfo2"
              :class="{ authenticated: corpList.corpInfoCheck == 43 }"
              >{{ corpList.corpInfoCheck == 43 ? '已认证' : '未认证' }}</span
            >
          </div>
          <div class="otherInfo">
            <!-- <span class="otherInfo1">账户安全</span> -->
            <span class="otherInfo2" @click="goEnterpriseInfo('first')"
              >企业资料</span
            >
            <span class="otherInfo3" @click="goEnterpriseInfo('second')"
              >认证资料</span
            >
          </div>
        </div>
      </div>
      <div class="header-box2">
        <div>
          <div class="box2-title">专属客服</div>
          <!-- <div>金融服务部</div>
          <div>汪小姐</div>
          <div>139 8888 8888</div> -->
        </div>
      </div>
      <div class="header-box3">
        <div class="box3-title">优企通公告</div>
        <div class="notice-list-box">
          <div
            class="all-list"
            :style="{ top: -number + 'px' }"
            :class="{ marquee_top: number }"
          >
            <div
              class="clearfix notice-box"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="handleNotckDetail(item.noticeId)"
            >
              <el-tooltip
                v-if="item"
                placement="top"
                effect="light"
                :content="item.noticeTitle"
              >
                <div class="fl notice-title" v-if="item">
                  {{ item.noticeTitle }}
                </div>
              </el-tooltip>
              <div class="fr" v-if="item">
                {{
                  item.createTime
                    ? moment(item.createTime).format('YYYY-MM-DD HH:mm')
                    : ''
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <div
        class="module module1"
        v-if="wuser.corpType == 2 && isChainCorp == 9"
      >
        <div class="module-title clearfix">
          <div class="title-text">业务概况</div>
        </div>
        <div class="module1-box">
          <div>
            <div
              class="module1-content clearfix"
              :class="{ firstPage: pageNum == 2, secondPage: pageNum == 2 }"
            >
              <div class="module1-item">
                <span>总授信额度</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.totalCreditLine)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>可用开单额度</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.availablePayableBillLine)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已用开单金额</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.usedPayableBillLine)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>需清分金额</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(
                      financingInfo.needToClearPayableBillLine
                    )
                  }}</span>
                  元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module1" v-if="isChainCorp == 10">
        <div class="module-title clearfix">
          <div class="title-text">业务概况</div>
        </div>
        <div class="module1-box">
          <div>
            <div
              class="module1-content clearfix"
              :class="{ firstPage: pageNum == 2, secondPage: pageNum == 2 }"
            >
              <div class="module1-item">
                <span>总授信额度</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.totalCreditLine)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已开立金额</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.usedPayableBillLine)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>待清分金额</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(
                      financingInfo.needToClearPayableBillLine
                    )
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已融资金额</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo.amountOfFinancing || 0)
                  }}</span>
                  元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module1" v-if="wuser.corpType == 3">
        <div class="module-title clearfix">
          <div class="title-text">优企通概况</div>
        </div>
        <div class="module1-box">
          <div>
            <div
              class="module1-content clearfix"
              :class="{ firstPage: pageNum == 2, secondPage: pageNum == 2 }"
            >
              <div class="module1-item">
                <span>持有总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.payableBillAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>应收总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.receivableAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已融资总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.financeAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已转让总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.transferAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module1" v-if="wuser.corpType == 1">
        <div class="module-title clearfix">
          <div class="title-text">优企通概况</div>
        </div>
        <div class="module1-box">
          <div>
            <div
              class="module1-content clearfix"
              :class="{ firstPage: pageNum == 2, secondPage: pageNum == 2 }"
            >
              <div class="module1-item">
                <span>授信总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.grantCreditAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已放款总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.loanAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>已收总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.gatheringAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
              <div class="module1-item">
                <span>待还总金额（元）：</span>
                <div class="amount-box">
                  <span>{{
                    $changeMoneyThousand(financingInfo2.unGatheringAmountTotal)
                  }}</span>
                  元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="module module2">
          <div class="module-title clearfix">
            <div class="title-text">待办事项</div>
            <div class="title-btn">
              <span
                :class="{ 'arrow-left': todoitem > 3, usable: pageNum1 == 2 }"
                @click="pageNum1 = 1"
              ></span>
              <span
                v-if="todoitem > 3"
                class="arrow-right"
                :class="{ usable: pageNum1 == 1 }"
                @click="pageNum1 = 2"
              ></span>
              <span v-else></span>
            </div>
          </div>
          <div class="module2-box">
            <div>
              <div
                class="module2-content clearfix"
                :class="{
                  firstPage1: pageNum1 == 2,
                  secondPage1: pageNum1 == 2,
                }"
              >
                <div
                  class="module2-item"
                  v-for="(item, index) in toDoList"
                  :key="index"
                  v-if="
                    item.role.includes(wuser.corpType) ||
                    item.role.includes(isChainCorp)
                  "
                >
                  <span>{{ item.title }}</span>
                  <div class="number-box">
                    <span @click="gotoPath(item)">{{ item.number }}</span>
                    <i @click="gotoPath(item)">起</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="module module3">
          <div class="module-title">
            <div class="title-text">进行中的项目</div>
          </div>
          <div class="module3-box">
            <div class="module3-item">
              <span>优企通项目</span>
              <div class="number-box">
                <span>{{ projectInfo.payableBillProjectNum }}</span
                >起
              </div>
            </div>
            <div class="module3-item">
              <span>优企通融资</span>
              <div class="number-box">
                <span>{{ projectInfo.receivableProjectNum }}</span> 起
              </div>
            </div>
            <div class="module3-item">
              <span>普惠通项目</span>
              <div class="number-box"><span>0</span> 起</div>
            </div>
            <div class="module3-item">
              <span>普惠通融资</span>
              <div class="number-box"><span>0</span> 起</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="module module4"
        v-if="corpList.corpType == 2 || corpList.corpType == 3"
      >
        <div class="module-title">
          <div class="title-text">快捷业务</div>
        </div>
        <div class="quick-work-box">
          <div v-for="(item, index) in shortcutsList" :key="index">
            <div
              class="quick-work-item"
              @click="openClick(item)"
              v-if="item.role.includes(corpList.corpType)"
            >
              <img :src="item.icon" alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NoticeDetail ref="noticeDetail"></NoticeDetail>
  </div>
</template>
<script>
import echarts from 'echarts';
import api from '@/api/api';
import Map from './Map';
import defaultLogo from '@/assets/workbench/logo.png';
import moment from 'moment';
import NoticeDetail from './noticeDetail.vue';
const icon1 = require('../../assets/quick-work1.png');
const icon2 = require('../../assets/quick-work2.png');
const icon3 = require('../../assets/quick-work3.png');
const icon4 = require('../../assets/quick-work4.png');
const icon5 = require('../../assets/quick-work5.png');
const icon6 = require('../../assets/quick-work6.png');
// import corpUserAuthMixin from '@/mixin/corpUserAuthMixin'

export default {
  components: {
    Map,
    NoticeDetail,
  },
  // mixin:[corpUserAuthMixin],
  data() {
    return {
      todoitems: null,
      // 1 资金  2 核心  3 链属
      shortcutsList: [
        {
          title: '链属企业邀请',
          icon: icon5,
          role: [2],
          linke: '/Business/Business_management',
          type: 3,
          roleList: [
            'core_admin',
            'core_agent',
            'general_admin',
            'general_agent',
            'general_core_admin',
            'general_core_agent',
          ],
        },
        {
          title: '优企通邀请开单',
          icon: icon2,
          role: [3],
          linke: '/easypay/lseasypaitoopen',
          type: 2,
          roleList: ['general_admin', 'general_agent'],
        },
        {
          title: '优企通开立',
          icon: icon1,
          role: [2],
          linke: '/easypay/toopen',
          type: 1,
          roleList: [
            'core_admin',
            'core_agent',
            'general_core_admin',
            'general_core_agent',
          ],
        },
        {
          title: '优企通转让',
          icon: icon6,
          role: [3],
          linke: '/easypay/mange/3?pageNum=1&pageSize=10',
          type: 5,
          roleList: ['general_admin', 'general_agent'],
        },
        {
          title: '优企通支付',
          icon: icon3,
          role: [2],
          linke: '/easypay/distinguishingmangenew/1?pageSize=10&pageNum=1',
          type: 3,
          roleList: [
            'core_admin',
            'core_agent',
            'general_core_admin',
            'general_core_agent',
          ],
        },
        {
          title: '优企通清分',
          icon: icon3,
          role: [3],
          linke: '/easypay/lsdistinguishingmange/1?pageSize=10&pageNum=1',
          type: 3,
          roleList: ['general_admin', 'general_agent', 'general_first'],
        },
      ],
      EZTList: [
        {
          title: '持有总金额(元)',
          price: 510000000,
        },
        {
          title: '待收总金额(元)',
          price: 510000000,
        },
        {
          title: '已融资总金额(元)',
          price: 510000000,
        },
        {
          title: '已转让总金额(元)',
          price: 510000000,
        },
      ],
      ZJFList: [
        {
          title: '授信总额(元)',
          price: 510000000,
        },
        {
          title: '已融资总金额(元)',
          price: 510000000,
        },
        {
          title: '已还款总金额(元)',
          price: 510000000,
        },
        {
          title: '待还款总金额(元)',
          price: 510000000,
        },
      ],
      pageNum: 1,
      pageNum1: 1,
      echart0Data: {
        xAxisData: [
          '石油和天然气开采',
          '有色金属矿采选',
          '非金属矿采选',
          '屠宰及肉类',
          '植物油',
          '水产品',
          '家用电器及电子产品',
          '医药及医疗器材',
          '纺织',
          '服装及日用品',
          '银行业',
          '证券',
          '金融租赁',
          '物业管理',
          '房地产中介服务',
          '机械设备租赁',
          '计算机及通讯',
          '水利管理',
          '工程技术与规划管理',
          '市场管理',
        ],
        data1: [
          1500000, 2320000, 2010000, 1540000, 3300000, 4100000, 2010000,
          1540000, 1900000, 3100000, 1540000, 3300000, 1010000, 1500000,
          2320000, 1340000, 3100000, 1900000, 4100000, 3200000,
        ],
        data2: [
          1500000, 2320000, 2010000, 1540000, 1500000, 2320000, 1900000,
          4100000, 3100000, 1010000, 1340000, 900000, 1540000, 1500000, 2320000,
          3300000, 3100000, 1010000, 1340000, 900000,
        ],
        data3: [
          1200000, 1320000, 1010000, 1340000, 900000, 3100000, 2300000, 1500000,
          2320000, 1540000, 3300000, 1900000, 2100000, 1320000, 1010000,
          1010000, 1900000, 1340000, 900000, 1200000,
        ],
        data4: [
          2200000, 1820000, 1910000, 2340000, 1540000, 1500000, 2320000,
          3300000, 3100000, 2900000, 1900000, 3300000, 3100000, 1010000,
          1500000, 2320000, 1340000, 900000, 3300000, 1200000,
        ],
      },
      echart1Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data1: [
          2000000, 3000000, 1000000, 4000000, 7000000, 12000000, 9000000,
          7000000, 2000000, 4000000, 0, 0,
        ],
        data2: [
          5000000, 5000000, 4000000, 6000000, 1000000, 8000000, 1000000, 0,
          3000000, 7000000, 2000000, 0,
        ],
        data3: [
          0, 8000000, 1000000, 0, 0, 9000000, 1000000, 0, 0, 2000000, 7000000,
          0,
        ],
      },
      echart2Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data: [7, 7, 9, 14, 18, 22, 25, 28, 23, 18, 14, 9],
      },
      echart3Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data: [7, 7, 9, 14, 18, 22, 25, 28, 23, 18, 14, 9],
      },
      echart4Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data: [
          70000000, 280000000, 70000000, 90000000, 220000000, 250000000,
          230000000, 180000000, 140000000, 180000000, 140000000, 90000000,
        ],
      },
      echart5Data: {
        type: ['商票', '国股', '城商', '三农', '财票'],
        data: [
          { value: 335, name: '商票' },
          { value: 310, name: '国股' },
          { value: 234, name: '城商' },
          { value: 135, name: '三农' },
          { value: 548, name: '财票' },
        ],
      },
      echart6Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data1: [
          2000000, 3000000, 1000000, 4000000, 7000000, 3000000, 9000000,
          7000000, 2000000, 4000000, 6000000, 2000000,
        ],
        data2: [
          5000000, 5000000, 4000000, 6000000, 1000000, 3000000, 1000000,
          5000000, 3000000, 7000000, 2000000, 1000000,
        ],
        data3: [
          2000000, 8000000, 1000000, 3000000, 5000000, 9000000, 1000000,
          2000000, 4000000, 2000000, 7000000, 3000000,
        ],
      },
      echart7Data: {
        data: [
          {
            task: '中国银行中车专项',
            startTime: '2018-04-18 01:17:12',
            endTime: '2018-05-18 01:19:10',
            status: 0,
          },
          {
            task: '工商银行扶贫专项',
            startTime: '2018-04-28 01:18:15',
            endTime: '2018-05-22 01:19:20',
            status: 0,
          },
          {
            task: '农业银行下乡',
            startTime: '2018-05-20 02:11:32',
            endTime: '2018-06-01 02:18:50',
            status: 0,
          },
          {
            task: '兴业银行中车专项',
            startTime: '2018-05-18 02:18:50',
            endTime: '2018-07-18 03:16:38',
            status: 0,
          },
          {
            task: '光大银行',
            startTime: '2018-07-18 02:19:48',
            endTime: '2018-08-28 02:21:57',
            status: 0,
          },
          {
            task: '大兴融资租赁',
            startTime: '2018-08-24 03:16:38',
            endTime: '2018-09-18 03:19:38',
            status: 0,
          },
        ],
      },
      echart8Data: {
        xAxisData: [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ],
        data1: [
          2000000, 3000000, 1000000, 4000000, 7000000, 4000000, 3000000,
          6000000, 2000000, 4000000, 1000000, 2000000,
        ],
        data2: [
          5000000, 5000000, 4000000, 3000000, 1000000, 4000000, 1000000,
          2000000, 3000000, 7000000, 2000000, 3000000,
        ],
        data3: [
          2000000, 8000000, 1000000, 5000000, 3000000, 5000000, 1000000,
          2000000, 4000000, 2000000, 7000000, 3000000,
        ],
      },
      api: api,
      corpList: {},
      src: '',
      financingInfo: {},
      financingInfo2: {},
      projectInfo: {},
      defaultLogo: defaultLogo,
      wuser: JSON.parse(sessionStorage.user),
      noticeList: [],
      moment: moment,
      number: 0,
      marqueetimer: null,
      curRoleCodes: null,
      user: {},
      isChainCorp: JSON.parse(sessionStorage.corpList).isChainCorp + 9,
    };
  },
  computed: {
    todoitem() {
      return this.todoitems;
    },
    getLogoUrl() {
      return this.$store.state.logoURL;
    },
    OverviewList() {
      return this.corpList.corpType === 1
        ? this.ZJFList
        : this.corpList.corpType === 3
        ? this.EZTList
        : [];
    },
    toDoList() {
      return [
        {
          title: '开单待审批',
          role: [1, 2, 3],
          number: this.projectInfo.payableBillPendingNum,
          roleList: [
            'general_core_admin',
            'general_core_audit',
            'core_admin',
            'core_first',
            'general_admin',
            'general_first',
            'fund_admin',
            'fund_first',
            'fund_second',
            'fund_third',
          ],
          path:
            this.wuser.corpType === 1
              ? this.selectPath()
              : '/easypay/openapproval/1',
        },
        {
          title: '接收待确认',
          role: [10],
          roleList: [
            'general_core_admin',
            'general_core_audit',
            'general_core_agent',
          ],
          number: this.projectInfo.payableBillConfirmNum,
          path: '/easypay/mange/2',
        },
        {
          title: '收单待确认',
          role: [3],
          roleList: ['general_admin', 'general_agent'],
          number: this.projectInfo.payableBillConfirmNum,
          path: '/easypay/mange/2',
        },
        {
          title: '融资待审批',
          role: [10],
          roleList: [
            'general_core_admin',
            'general_core_audit',
            'general_core_agent',
          ],
          number: this.projectInfo.payableBillFinanceConfirmNum,
          path: '/easypayApproveManage/easypayFinancing/financingApprove/1?pageSize=10&pageNum=1',
        },
        {
          title: '融资待签约',
          role: [1, 10, 3],
          roleList: [
            'general_admin',
            'fund_admin',
            'general_agent',
            'general_core_admin',
            'general_core_audit',
            'general_core_agent',
          ],
          number: this.projectInfo.payableBillFinanceConfirmNum,
          path: '/financingProjectManage/easypaySign/financingSign/2',
        },
        {
          title: '请求开单待审批',
          role: [2],
          number: this.projectInfo.payableBillPendingConfirmNum,
          roleList: [
            'general_core_admin',
            'general_core_audit',
            'core_admin',
            'core_first',
          ],
          path: '/easypay/openrequestapproval/1',
        },
        {
          title: '清分待审批',
          role: [1, 2],
          number: this.projectInfo.payableBillLiquidationNum,
          roleList: [
            'general_core_admin',
            'general_core_audit',
            'core_admin',
            'core_first',
            'fund_admin',
            'fund_first',
          ],
          path: '/easypay/distinguishingapprovalfix/1',
        },
      ];
    },
  },
  created() {
    this.getCorpByCorpId();
    this.corpList = JSON.parse(sessionStorage.getItem('corpList'));
    this.user = JSON.parse(sessionStorage.getItem('user'));
    let { roleCodes } = this.user;
    this.curRoleCodes = roleCodes[0];
    this.getBusinessInfo();
    this.todoItems();
  },
  mounted() {
    this.init();
  },
  methods: {
    todoItems() {
      this.$nextTick(() => {
        this.todoitems = document.querySelectorAll('div.module2-item').length;
      });
    },
    // 路径
    selectPath() {
      if (this.curRoleCodes === 'fund_second') {
        return '/fund/easypay/openapproval/second/1?pageSize=10&pageNum=1'; // 复审
      } else if (this.curRoleCodes === 'fund_third') {
        return '/fund/easypay/openapproval/three/1?pageSize=10&pageNum=1'; // 终审
      } else {
        return '/fund/easypay/openapproval/1?pageSize=10&pageNum=1'; // 初审
      }
    },
    // 待办事项跳转
    gotoPath(item) {
      if (item.roleList.includes(this.curRoleCodes)) {
        this.$router.push(item.path);
      } else {
        this.$message.warning('当前权限不支持！请联系管理员');
      }
    },
    // 快捷操作跳转链接
    openClick(item) {
      if (item.roleList.includes(this.curRoleCodes)) {
        this.$router.push(item.linke);
      } else {
        this.$message.warning('当前权限不支持！请联系管理员');
      }
    },
    init() {
      sessionStorage.name = '工作台';
      this.getNewsList();
      // this.echartFun0();
      // this.echartFun1();
      // this.echartFun2();
      // this.echartFun3();
      // this.echartFun4();
      // this.echartFun5();
      // this.echartFun6();
      // this.echartFun7();
      // this.echartFun8();
      // this.startMove();
    },
    getNewsList() {
      let param = {
        noticeCategory: '2',
        pageSize: 1000,
      };
      api.getNoticeList(param).then((res) => {
        if (res.success) {
          this.noticeList = res.datas.list;
          if (this.noticeList.length > 3) {
            this.startMove(this.number);
          }
        }
      });
    },
    handleNotckDetail(noticeId) {
      this.$refs.noticeDetail.initInfo(noticeId);
    },
    startMove(num) {
      let addList = [
        this.noticeList[0],
        this.noticeList[1],
        this.noticeList[2],
      ];
      const copyList = JSON.parse(JSON.stringify(this.noticeList));
      this.noticeList = [...this.noticeList, ...addList];
      var max = copyList.length;
      var that = this;
      this.marqueetimer = setInterval(function () {
        num++;
        if (num > max) {
          num = 0;
        }
        that.number = num * 40;
      }, 4000);
    },
    echartFun0() {
      console.log(document.getElementById('graph0'));
      let myChart = echarts.init(document.getElementById('graph0'));
      myChart.setOption({
        color: ['#223273', '#FACC14', '#2EC25B', '#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ['优企通', '保理', '票据', 'ABS'],
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.echart0Data.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '优企通',
            type: 'bar',
            stack: '业务类别',
            barWidth: '40%',
            data: this.echart0Data.data1,
            itemStyle: {
              normal: {
                color: 'rgba(34,50,115, 0.8)',
              },
            },
          },
          {
            name: '保理',
            type: 'bar',
            stack: '业务类别',
            data: this.echart0Data.data2,
            itemStyle: {
              normal: {
                color: 'rgba(250,204,20, 0.8)',
              },
            },
          },
          {
            name: '票据',
            type: 'bar',
            stack: '业务类别',
            data: this.echart0Data.data3,
            itemStyle: {
              normal: {
                color: 'rgba(46,194,91, 0.8)',
              },
            },
          },
          {
            name: 'ABS',
            type: 'bar',
            stack: '业务类别',
            data: this.echart0Data.data4,
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255, 0.8)',
              },
            },
          },
        ],
      });
    },
    echartFun1() {
      let myChart = echarts.init(document.getElementById('graph1'));
      myChart.setOption({
        title: {
          text: '年度优企通业务',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#FACC14', '#2EC25B', '#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: ['开单', '转让', '融资'],
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '13%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.echart1Data.xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '开单',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart1Data.data1,
          },
          {
            name: '转让',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart1Data.data2,
          },
          {
            name: '融资',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart1Data.data3,
          },
        ],
      });
    },
    echartFun2() {
      let myChart = echarts.init(document.getElementById('graph2'));
      myChart.setOption({
        title: {
          text: '年度融资项目分布',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.echart2Data.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '50%',
            data: this.echart2Data.data,
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255, 0.8)',
              },
            },
          },
        ],
      });
    },
    echartFun3() {
      let myChart = echarts.init(document.getElementById('graph3'));
      myChart.setOption({
        title: {
          text: '年度融资项目',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.echart3Data.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '50%',
            data: this.echart3Data.data,
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255, 0.8)',
              },
            },
          },
        ],
      });
    },
    echartFun4() {
      let myChart = echarts.init(document.getElementById('graph4'));
      myChart.setOption({
        title: {
          text: '年度确权金额',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.echart4Data.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '50%',
            data: this.echart4Data.data,
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255, 0.8)',
              },
            },
          },
        ],
      });
    },
    echartFun5() {
      let myChart = echarts.init(document.getElementById('graph5'));
      myChart.setOption({
        title: {
          text: '年度挂牌总额',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#3ba1ff', '#f2637b', '#36cbcb', '#fad337', '#836FFF'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        legend: {
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
          data: this.echart5Data.type,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: this.echart5Data.data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      });
    },
    echartFun6() {
      let myChart = echarts.init(document.getElementById('graph6'));
      myChart.setOption({
        title: {
          text: '年度优企通业务',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#FACC14', '#2EC25B', '#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: ['tradeacdraft', 'bankacdraft', 'fiscalacdraft'],
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '13%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.echart6Data.xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: 'tradeacdraft',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart6Data.data1,
          },
          {
            name: 'bankacdraft',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart6Data.data2,
          },
          {
            name: 'fiscalacdraft',
            type: 'line',
            stack: '业务类别',
            areaStyle: { normal: {} },
            data: this.echart6Data.data3,
          },
        ],
      });
    },
    echartFun7() {
      this.echart7Data.data.forEach(function (obj) {
        obj.range = [obj.startTime, obj.endTime];
      });

      let chart = new G2.Chart({
        container: 'graph7',
        forceFit: true,
        height: 430,
        padding: [60, 20, 50, 160],
      });
      chart.source(this.echart7Data.data);

      chart.coord().transpose().scale(1, -1);
      chart.interval().position('task*range');
      chart.render();
    },
    echartFun8() {
      let myChart = echarts.init(document.getElementById('graph8'));
      myChart.setOption({
        title: {
          text: '年度资产汇集规模',
          textStyle: {
            fontSize: 14,
          },
          padding: [10, 0, 0, 20],
        },
        color: ['#FACC14', '#2EC25B', '#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ['优企通', '保理', '票据', 'ABS'],
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.echart8Data.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '优企通',
            type: 'bar',
            stack: '业务类别',
            barWidth: '40%',
            data: this.echart8Data.data1,
            itemStyle: {
              normal: {
                color: 'rgba(250,204,20, 0.8)',
              },
            },
          },
          {
            name: '保理',
            type: 'bar',
            stack: '业务类别',
            data: this.echart8Data.data2,
            itemStyle: {
              normal: {
                color: 'rgba(46,194,91, 0.8)',
              },
            },
          },
          {
            name: '票据',
            type: 'bar',
            stack: '业务类别',
            data: this.echart8Data.data3,
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255, 0.8)',
              },
            },
          },
        ],
      });
    },
    getCorpByCorpId() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      api
        .GetCorpByCorpId({ relationCorpId: user.relationCorpId })
        .then((res) => {
          if (res.success) {
            this.corpList = res.datas.corp;
            this.src = res.datas.logoURL;
            this.financingSummary(this.corpList.corpId);
            this.todoItem(this.corpList.corpId);
            sessionStorage.setItem('corpList', JSON.stringify(this.corpList));
          }
        });
    },
    getProjectCode() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      let param = {
        corpId: user.relationCorpId,
      };
      api.SignSendMessage(param).then((res) => {
        if (res.success) {
          if (res.datas.sendMessage) {
            this.$message.success('发送成功');
            this.projectCode = res.datas.projectCode;
            var user = JSON.parse(sessionStorage.getItem('user'));
            let meessage =
              '提示信息：CFCA向您尾号为' +
              user.phone +
              '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
            this.$prompt(meessage, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
              // inputErrorMessage: "邮箱格式不正确"
            })
              .then(({ value }) => {
                let param = {
                  checkCode: value,
                  projectCode: this.projectCode,
                };
                api.CheckProjectCode(param).then((res) => {
                  if (res.success) {
                    this.$alert('开启电子签章成功', '', {
                      confirmButtonText: '确定',
                      callback: (action) => {},
                    });
                  } else {
                    this.$alert(res.message, '', {
                      confirmButtonText: '确定',
                      callback: (action) => {},
                    });
                  }
                });
              })
              .catch(() => {});
          }
        }
      });
    },
    // 融资概况
    financingSummary(corpId) {
      api.financingSummary({ corpId }).then((res) => {
        if (res.success) {
          this.financingInfo = res.datas.data;
        }
      });
    },
    // 待办事项
    todoItem(type) {
      api.todoItem({ corpId: type }).then((res) => {
        if (res.success) {
          this.projectInfo = res.datas.data;
        }
      });
    },

    getBusinessInfo() {
      if (this.corpList.corpType == 1) {
        api.fundTodoItem().then((res) => {
          if (res.success) {
            this.financingInfo2 = res.datas.data;
          }
        });
      } else if (this.corpList.corpType == 3) {
        api.enterpriseTodoItem().then((res) => {
          if (res.success) {
            this.financingInfo2 = res.datas.data;
          }
        });
      }
    },
    goEnterpriseInfo(active) {
      this.$router.push({
        path: '/Business/enterpriseInfo',
        query: { active },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.workbench {
  background-color: #f1f1f1;
  padding: 0 30px 50px;
}
.header-box {
  margin-left: -30px;
  margin-right: -30px;
  height: 320px;
  background: url(../../assets/workbench/header1.png) center top no-repeat;
  background-size: cover;
  padding: 30px 50px;
  color: #fff;
}
.header-box1 {
  float: left;
  width: 50%;
}
.logo-box {
  float: left;
  margin-right: 16px;
}
.logo {
  width: 66px;
  height: 66px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  // align-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;
  // color: #fff;
  background-color: #fff;
}
.logo-img {
  display: block;
  // width: auto;
  // height: auto;
  max-width: 100%;
  max-height: 100%;
}
.companyType {
  width: 66px;
  height: 66px;
  background: url(../../assets/workbench/coreCompany.png) center no-repeat;
  text-align: center;
  color: #bc6a00;
  font-family: 'PingFang-SC-Heavy';
  font-size: 12px;
}
.baseInfo-box {
  float: left;
}
.companyName {
  font-family: 'PingFang-SC-Bold';
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}
.markInfo {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 20px;
}
.markInfo1,
.markInfo2 {
  float: left;
  padding-left: 34px;
  padding-right: 16px;
  border-radius: 10px;
  margin-right: 10px;
  color: #ffa60c;
  background: url(../../assets/workbench/syqy.png) 10px center no-repeat
    rgba(0, 0, 0, 0.15);
  background-size: 20px;
}
.markInfo2 {
  color: #5988ff;
  background-image: url(../../assets/workbench/wrz.png);
  &.authenticated {
    background-image: url(../../assets/workbench/yrz.png);
  }
}
.otherInfo {
  font-size: 14px;
}
.otherInfo1,
.otherInfo2,
.otherInfo3 {
  display: inline-block;
  margin-right: 20px;
  color: #99beff;
  line-height: 22px;
  padding-left: 28px;
  background: url(../../assets/workbench/zhaq_nor.png) left center no-repeat;
  background-size: 20px;
  cursor: pointer;
}
.otherInfo2 {
  background-image: url(../../assets/workbench/qyzl_nor.png);
}
.otherInfo3 {
  background-image: url(../../assets/workbench/rzzl_nor.png);
}
.otherInfo1:hover {
  color: #fff;
  background-image: url(../../assets/workbench/zhaq_sel.png);
}
.otherInfo2:hover {
  color: #fff;
  background-image: url(../../assets/workbench/qyzl_sel.png);
}
.otherInfo3:hover {
  color: #fff;
  background-image: url(../../assets/workbench/rzzl_sel.png);
}
.header-box2 {
  float: left;
  width: 14%;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  display: flex;
  justify-content: center;
}
.box2-title {
  color: #99beff;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
  padding-left: 22px;
  background: url(../../assets/workbench/service.png) left center no-repeat;
  background-size: 16px;
}
.header-box3 {
  float: left;
  width: 36%;
  padding-left: 30px;
  font-size: 14px;
  line-height: 30px;
  color: #99beff;
  div.clearfix {
    cursor: pointer;
    .fl:before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d1e2ff;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
  div.clearfix:hover {
    color: #fff;
    .fl:before {
      background-color: #fff;
    }
  }
}
.box3-title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #fff;
}
.notice-list-box {
  height: 120px;
  overflow: hidden;
  position: relative;
  .all-list {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .marquee_top {
    transition: top 0.5s;
  } /*关键样式*/
  .notice-box {
    .notice-title {
      display: inline-block;
      max-width: 280px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.main-container {
  position: relative;
  top: -110px;
  width: 100%;
  height: 400px;
}
.module {
  background-color: #fff;
  margin-bottom: 16px;
}
.module4 {
  .map-box {
    padding: 24px 32px;
  }
}
.quick-work-box {
  display: flex;
  justify-content: center;
  padding: 20px 25px;
  .quick-work-item {
    cursor: pointer;
    width: 318px;
    height: 80px;
    background: rgba(250, 250, 250, 1);
    // opacity:1;
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    // box-sizing: border-box;
    margin-left: 60px;
    padding: 0 25px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 15px;
    }
  }
}
.module-title {
  font-size: 16px;
  font-weight: 600;
  padding: 16px 24px;
  border-bottom: 1px solid #e6e6e6;
  .title-text {
    color: #000;
    opacity: 0.85;
    line-height: 24px;
  }
  .title-text:before {
    display: inline-block;
    content: '';
    width: 3px;
    height: 14px;
    margin-right: 8px;
    background-color: #d19f53;
    vertical-align: middle;
  }
  &.clearfix {
    .title-text {
      float: left;
    }
    .title-btn {
      float: right;
    }
    .arrow-left,
    .arrow-right {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 12px;
      background: url(../../assets/workbench/left.png) center no-repeat;
      cursor: pointer;
    }
    .arrow-right {
      background-image: url(../../assets/workbench/right.png);
    }
    .arrow-left.usable {
      background-image: url(../../assets/workbench/left1.png);
    }
    .arrow-right.usable {
      background-image: url(../../assets/workbench/right1.png);
    }
  }
}

.module1-box {
  padding: 32px 24px;
  & > div {
    overflow: hidden;
  }
}
.module1-content {
  width: 200%;
  transition: margin 0.8s;
  &.firstPage {
    margin-left: 0;
  }
  &.secondPage {
    margin-left: -100%;
  }
  .module1-item:nth-child(2) {
    background-image: url(../../assets/workbench/zjded.png);
  }
  .module1-item:nth-child(3) {
    background-image: url(../../assets/workbench/yysxed.png);
  }
  .module1-item:nth-child(4) {
    background-image: url(../../assets/workbench/yyjded.png);
  }
  .module1-item:nth-child(5) {
    background-image: url(../../assets/workbench/rzgk_kysx.png);
  }
  .module1-item:nth-child(6) {
    background-image: url(../../assets/workbench/rzgk_kyjd.png);
  }
  .module1-item:nth-child(7) {
    background-image: url(../../assets/workbench/rzgk_xqjd.png);
  }
  .module1-item:nth-child(8) {
    background-image: url(../../assets/workbench/rzgk_rzed.png);
  }
}
.module1-item {
  float: left;
  width: 12.2%;
  height: 122px;
  margin-right: 0.4%;
  flex-shrink: 0;
  background: url(../../assets/workbench/zsxeu.png) center no-repeat;
  background-size: 100% 122px;
  border-radius: 8px;
  padding: 32px;
  color: #fff;
  & > span {
    font-size: 14px;
    line-height: 22px;
  }
  .amount-box {
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    span {
      font-size: 24px;
      font-family: 'HelveticaNeue-Bold';
      line-height: 32px;
    }
  }
}
.module1-item:nth-child(4n) {
  margin-right: 0;
}
.module2 {
  float: left;
  width: 54%;
  margin-right: 1%;
}
.module3 {
  float: left;
  width: 45%;
}
.module2-box {
  padding: 0 24px 0 60px;

  & > div {
    width: 100%;
    overflow: hidden;
  }
  .module2-item {
    float: left;
    width: 16.66%;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    padding: 46px 0;
  }
  .number-box {
    color: #2937a6;
    margin-top: 6px;
    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      cursor: pointer;
      text-decoration: underline;
    }
    i {
      cursor: pointer;
      font-style: normal;
    }
  }
}
.module2-content {
  width: 200%;
  transition: margin 0.5s;
  &.firstPage1 {
    margin-left: 0;
  }
  &.secondPage1 {
    margin-left: -100%;
  }
}
.module3-box {
  display: flex;
  justify-content: space-around;
  .module3-item {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    padding: 46px 0;
  }
  .number-box {
    color: rgba(0, 0, 0, 0.85);
    margin-top: 6px;
    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      cursor: pointer;
      text-decoration: underline;
    }
    i {
      cursor: pointer;
      font-style: normal;
    }
  }
}
.module4 {
  .map-box {
    padding: 24px 32px;
  }
}
.module5 {
  .graph-content {
    height: 530px;
  }
}
.module6,
.module7,
.module8,
.module9 {
  .graph-content {
    float: left;
    width: 50%;
    height: 440px;
    margin-top: 20px;
  }
}
.graph7-box {
  .graph7-title {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
  }
}
.module9 {
  padding-bottom: 20px;
}
.footer-link {
  text-align: none;
  a {
    text-decoration: none;
  }
}
</style>
