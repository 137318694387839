<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList">
      <checkResult v-if="type == 2"></checkResult> 
      <baseInfo :formData="formData"></baseInfo> 
      <receivablesList :tableList="tableList" :formData="formData"></receivablesList> 
      <div class="card-hd" style="height: 150px;">
        <el-row style="padding-top: 40px;">
          <el-col :span="7" class="lb_body" :offset="7" v-if="type == 1">
            <el-button style="width: 160px;height: 40px;font-size:16px;" size="small" @click="reject">拒绝</el-button>
          </el-col>
          <el-col :span="8" class="lb_body" v-if="type == 1">
            <el-button style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;" size="small" @click="agree">同意</el-button>
          </el-col>
          <el-col :span="8" class="lb_body" :offset="10" v-if="type == 2">
            <el-button style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;" size="small" @click="back">返回</el-button>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 弹窗 -->
  <el-dialog title='审核意见' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='40%'>
    <el-form :model="agreedetail" label-width="60px"  ref="addform" >
      <el-form-item label="备注："  >
        <el-input v-model="agreedetail.remark" type="textarea" :rows="6" maxlength="200" >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showAddDialog=false">取 消</el-button>
      <el-button type="primary" @click="submitAddForm" :disabled="bclick">提交</el-button>
    </div>
  </el-dialog>
  <el-dialog title='拒绝原因' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false'  width='40%'>
    <el-form :model="refusedetail" label-width="100px"  ref="addform" >
      <el-form-item label="拒绝原因：" prop="reason" >
        <el-select v-model="refusedetail.reason" placeholder="请选择">
          <el-option
            v-for="item in refuselist"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注："   >
        <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200" >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showAddDialog2=false">取 消</el-button>
      <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
    </div>
  </el-dialog>
  </layout-main-view>
</template>

<script>
import checkResult from './checkResult'
import baseInfo from './baseInfo'
import receivablesList from './receivablesList'
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
export default {
  components: {
    checkResult,
    baseInfo,
    receivablesList
  },
  data() {
    return {
      breadcrumblist:['优企通额度转换详情'],
      agreedetail:{
        receivableAccountFinanceId:this.$route.query.id,
        approveResult:1,
        remark:'',
        approveNode:this.$route.query.node
      },
      refusedetail:{
        receivableAccountFinanceId:this.$route.query.id,
        approveResult:2,
        remark:'',
        approveNode:this.$route.query.node,
        reason:''
      },
      refuselist:[],
      bclick:false,
      showAddDialog:false,
      showAddDialog2:false,
      type: this.$route.query.type,  // 1待审批 2已审批
      formData: {},
      tableList: [],
      convertCurrency: convertCurrency,
    }
  },
  created() {
    this.FinancingProjectDetails(this.$route.query.id)
  },
  methods: {
    FinancingProjectDetails(receivableAccountFinanceId) {
      api.FinancingProjectDetails({ receivableAccountFinanceId })
      .then(res => {
        if (res.success) {
          this.formData = res.datas.data;
          this.tableList = res.datas.list;
          let upper = this.convertCurrency(this.formData.financeAmount);
          this.$set(this.formData, "upper", upper);
          let upper2 = this.convertCurrency(this.formData.preFinanceAmount);
          this.$set(this.formData, "upper2", upper2);
          let upper3 = this.convertCurrency(this.formData.relationReceivedAccountAmounts);
          this.$set(this.formData, "upper3", upper3);
        }
      });
    },
    back() {
      history.go(-1)
    },
    getrefuselist(){
      // type 25 保理转优企通 退回原因
      api.getDicListByType({type:25}).then(res => {
        if(res.success){
          this.refuselist=res.datas.list
        }
      })
    },
    // 同意
    agree() {
      this.showAddDialog = true
    },
    //拒绝
    reject() {
      this.getrefuselist()    //获取拒绝原因
      this.showAddDialog2 = true;
    },
    submitAddForm(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.bclick){
        return
      }
      this.bclick=true
      api.submitReceivableAccountFinanceResult(this.agreedetail).then(res => {
        loading.close()
        if(res.success){
          this.showAddDialog=false
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              history.go(-1)
            }
          });
        }else{
          this.$message.error(res.message)
          this.bclick=false
        }
      }).catch((err) => {
        loading.close()
        this.bclick=false
      })
    },
    submitAddForm2(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.bclick){
        return
      }
      this.bclick=true
      api.submitReceivableAccountFinanceResult(this.refusedetail).then(res => {
        loading.close()
        if(res.success){
          this.showAddDialog2=false
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              history.go(-1)
            }
          });
        }else{
          this.$message.error(res.message)
          this.bclick=false
        }
      }).catch((err) => {
        loading.close()
        this.bclick=false
      })
    }
  }

}
</script>

<style>

</style>
