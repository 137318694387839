<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList blrzstep">
          <steps :stepList="stepList" :current="current"></steps>

      <div class="easypayby goback yfdtwo">
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form :model="info" ref="info" label-width="160px" class="demo-ruleForm" label-position="left">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="融资项目编号: ">
                  <span>{{info.receivableAccountFinanceNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="债权企业全称: ">
                  <span>{{info.financeCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="社会统一信用代码: ">
                  <span>{{info.creditCode}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账款到期日: ">
                  <span>{{info.accountEndTime ? moment(info.accountEndTime).format('YYYY-MM-DD'): ''}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="22">
              <el-col :span="8">
                <el-form-item label="应收账款合计金额(元): ">
                  <span>{{$changeMoneyThousand(info.relationReceivedAccountAmounts)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="大写: ">
                  <span>{{convertCurrency(info.relationReceivedAccountAmounts)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="期望融资年化利率: ">
                  <span>{{info.expectFinanceInterestMin}}%~{{info.expectFinanceInterestMax}}%</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="期望融资金额(元): ">
                  <span>{{$changeMoneyThousand(info.preFinanceAmount)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="大写: ">
                  <span>{{convertCurrency(info.preFinanceAmount)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="核心企业确权: ">
                  <span>{{info.corpAck==1?'可以':'不可以'}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="资金机构: ">
                  <span>{{info.fundCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="融资金额(元): ">
                  <span>{{$changeMoneyThousand(info.financeAmount)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="大写: ">
                  <span>{{convertCurrency(info.financeAmount)}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="融资年化利率: ">
                  <span>{{info.financeInterest}}%</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账户开户行: ">
                  <span>{{info.payeeOpenBank}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业账号: ">
                  <span>{{info.payeePublicAccountNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定应收账款</div>
        <div class="httotal">
          已添加应收账款
          <span>{{tableList.length}}</span> 笔，账款总计金额
          <span>{{$changeMoneyThousand(totalcount)}}</span> 元。
        </div>
        <div>
          <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="账款金额(元)" min-width="120" align="right">
              <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="签署日期" min-width="120">
              <template slot-scope="scope">{{ scope.row.accountSignDate | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="到期日期" min-width="120">
              <template slot-scope="scope">{{ scope.row.accountCloseDate | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="补件状态" min-width="120">
              <template slot-scope="scope">{{ scope.row.supplySuccess==1?'待补件':scope.row.supplySuccess==2?'已补件':'无需补件' }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="操作" min-width="120">
              <template slot-scope="scope">
                <div v-if="financeSupplyStatus != 4">
                  <el-button
                    v-if="scope.row.supplySuccess==2"
                    size="medium"
                    type="text"
                    @click="handleNew(scope.row)"
                  >查看</el-button>
                  <el-button v-else size="medium" type="text" @click="handleNew(scope.row)">补件</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-hd" style="height: 150px;">
          <el-row style="padding-top: 40px;">
            <el-col :span="7" class="lb_body" :offset="10">
              <el-button
                style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"

                size="small"
                @click="reject"
              >确定</el-button>
            </el-col>

          </el-row>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="上传其他资料"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="1200px"
      class="otherfileform"
    >
      <el-form :model="formupload" label-width="120px" ref="addform" class="boltdetial">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="账款编号: ">
              <span>{{formupload.receivableAccountNo}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账款金额: ">
              <span>{{$changeMoneyThousand(formupload.accountAmount)}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到期日期: ">
              <span>{{formupload.accountCloseDate ? moment(formupload.accountCloseDate).format('YYYY-MM-DD'): ''}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="上传其他资料: ">
          <div class="uploadfont wxtsk">
            <div class="uploadfont">
              温馨提示：仅支持JPG、PNG、PDF文件格式。
              <br />
              <span class="pt16">上传其他补充资料包括但不限于出入库单，物流单，结算单，银行流水等。</span>
            </div>
          </div>
        <div class="card-bd marrimg" style="padding-bottom: 51px;padding-left:30px;" v-for="(iten,ket) in  uploadfilelist" :key="ket">
                                        <el-row>
                                                    <p>{{iten.name}}: </p>
                                                            <div style="overflow:hidden">
                                                <div v-for="(items,key) in  iten.accountFileList" :key="key" class="htleft" >
                                                <img :src="api.getImgURL(items.fileUrl)" alt=""  width="100%" height="144px" preview-text="补件资料" :preview="'bj-'+ket+key" v-show="/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))">
                                        </div>
                                        </div>
                                        <div v-for="(items,key) in   iten.accountFileList" :key="key" class="htleft files" v-show="!/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))">

                                                <div >
                                                    <a
                              @click='downloadsingle(items)'

                                                    >{{items.fileName}}</a>
                                                    <p>
                                                    <i class="fa fa-file-text-o"></i>
                                                    </p>
                                                </div>


                                        </div>

                            </el-row>

                        </div>
                        <!-- <div   style="padding-bottom: 51px;padding-left:30px;">
                                    共有文件: <span class="numcolor">{{totalfilenum}}</span> 个
                            </div>  -->

        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog=false"  type="primary">确定</el-button>

      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import steps from "@/views/base/steps.vue";


export default {
    components: {
            steps
        },
  data() {
    return {
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["保理融资"],
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {
        financeAmount: 0
      },
      multipleSelection: [], //选中的表格
      moment: moment,
      showAddDialog: false,
      formupload: {},
      Authorization: { Authorization: sessionStorage.token },
      bclick: false,
      uploadfilelist: [],
      subsingle: {
        fileDtoList: [],
        receivableAccountFinanceId: this.$route.query.id,
        receivableAccountId: ""
      },
      allupload: {},
      totalcount: 0,
      financeSupplyStatus: '',
      refuselist2:[],
      api,
        stepList: [
                                    {
                                    name: "发起融资",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "项目内审",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "平台撮合",
                                    realName: "",
                                    createTime: ""
                                    },
                                       {
                                    name: "机构报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "选择报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "补件确权",
                                    realName: "",
                                    createTime: ""
                                    },
                                     {
                                    name: "等待签约",
                                    realName: "",
                                    createTime: ""
                                    },
                                      {
                                    name: "签约完成",
                                    realName: "",
                                    createTime: ""
                                    },


                                ],
                            current:1
    };
  },

  created() {
    this.financeSupplyStatus = this.$route.query.financeSupplyStatus
    this.getAddDocumentsDetail();
    this.getfilename()
  },
  methods: {
    getAddDocumentsDetail() {
      api
        .getAddDocumentsDetail({
          financeId: this.$route.query.id
        })
        .then(res => {
          if (res.success) {
            var arr = [];
            this.info = res.datas.detail;
            this.tableList = this.info.accounts;
            this.tableList.map(el => {
              arr.push(el.accountAmount);
            });
            this.totalcount = (0).adds(...arr);
        this.getstatus()

          }
        });
    },
        getstatus(){
          if(this.info.state == 'D'){
                // 作废
                this.current=-1
                }else{
                if(this.info.financeSignStatus == 50){
                  this.current=8
                // 签约完成
                }else if(this.info.financeSignStatus >= 20 && this.info.financeSignStatus < 50){
                  this.current=6
                // 等待签约
                }else if(this.info.financeSignStatus < 20 && this.info.financeSupplyApproveStatus >= 20 && this.info.financeSupplyApproveStatus <= 57 && this.info.corpAckStatus > 1 && this.info.corpAckStatus <= 6){
                // 补件确权
                this.current=5
                }else if(this.info.financeQuoteStatus == 3 && this.info.financeSupplyApproveStatus < 20 ){
                  this.current=4
                // 选择报价
                }else if(this.info.financeQuoteStatus == 2){
                  this.current=3
                // 机构报价
                }else if(this.info.approveStatus >= 41 && this.info.approveStatus <= 49){
                  this.current=2
                // 平台撮合
                }else if(this.info.approveStatus > 20 && this.info.approveStatus <= 23){
                // 项目内审
                this.current=1
                }else if(this.info.approveStatus == 20){
                  this.current=1
                // 发起融资
                }else{
                  this.current=6

                // 等待签约
                }
                }


    },



    //拒绝
    reject() {
      this.$router.go(-1);
    },

    corpAckStatusfn(val) {
      var str = "";
      switch (val) {
        case 1:
          str = "初始状态";
          break;
        case 2:
          str = "待确权";
          break;
        case 3:
          str = "已确权";
          break;
        case 4:
          str = "无需确权";
          break;
        case 5:
          str = "部分确权";
          break;
        case 6:
          str = "确权申请审核中";
          break;
      }

      return str;
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.')
      let suffix = file.name.substr(index + 1)
      if (!/jpg|bmp|png|jpeg|zip|rar|pdf/.test(suffix)) {
        this.$message.error("请上传png,jpg,jpeg,pdf格式的文件");
        return false;
      }
    },




    handleNew(data) {
      this.uploadfilelist = [];
      this.subsingle.fileDtoList = [];
      this.showAddDialog = true;
      this.formupload = data;
      this.uploadfilelist = data.supplyFiles;
      this.uploadfilelist.map(el => {
        el.arr = [];
        el.filelist = [];
        if (el.accountFileList.length > 0) {
          el.accountFileList.map(item => {
            el.arr.push({
              fileCode: item.fileCode,
              fileUrl: item.fileUrl,
              uid: item.uid,
              fileName: item.fileName
            });
            this.subsingle.fileDtoList.push({
              fileCode: item.fileCode,
              fileUrl: item.fileUrl,
              uid: item.uid,
              fileName: item.fileName
            });
            el.filelist.push({
              url: api.getImgURL(item.fileUrl),
              name: item.fileName,
              uid: item.uid
            });
          });
        }
      });
            this.refuselist2.map(el=>{
                this.uploadfilelist.map(ele=>{
                        if(ele.fileCode==el.code){
                                this.$set(ele,'name',el.name)

                        }
                })
            })

      console.log(this.subsingle.fileDtoList, 767);
      this.subsingle.receivableAccountId = data.receivableAccountId;
    },
    getfilename(){
              api.getDicListByType({type:17}).then(res => {
                                    if(res.code=='100000'){
                                          this.refuselist2=res.datas.list


                                    }

                         })
    },
          downloadsingle(row){
          api.filesdownsingle({
           filePath: row.fileUrl,
         }).then(res => {
              openDownloadDialog(res,row.fileName)
         }).catch((err) => {
      })
    },
  }
};
</script>
<style lang="scss">
.boltdetial {
  .upload-demo.upload-contentr .el-upload-list__item {
    width: 30%;
  }
}
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
.fr {
  float: right;
}

// thead {
//   .el-table-column--selection {
//     .cell {
//       display: none;
//     }
//   }
// }
.otherfileform {
  .el-upload-dragger {
    width: 236px;
    height: 92px;
    padding-top: 14px;
  }
  .el-form-item__label {
    font-size: 16px;
    color: #000;
  }
  .uploadline {
    margin-bottom: 20px;
  }
}
</style>
