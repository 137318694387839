//获取字典
export const GET_dict = 'GET_dict'
//设置字典值
export const UPDATE_dict = 'UPDATE_dict'
//更新用户信息
export const UPDATE_userInfo = 'UPDATE_userInfo'
//更新供应商名称
export const UPDATE_supName = 'UPDATE_supName'
//更新导航

export const UPDATE_navName = 'UPDATE_navName'
export const UPDATE_leftNav = 'UPDATE_leftNav'
//fixabs tab

export const UPDATE_tab = 'UPDATE_tab'

// 更新加密后的登录密码
export const UPDATE_password = 'UPDATE_password'

// 更新logo
export const UPDATE_logoURL = 'UPDATE_logoURL'

// 更新企业名称
export const UPDATE_corpName = 'UPDATE_corpName'

// 更新头部导航栏
export const UPDATE_Name = 'UPDATE_Name'