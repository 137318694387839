<template>
  <div class="headerLogin header-base" v-if="isTitle">
    <div class="headerLogin_img">
      <img
        src="../../assets/login/login_logo_b.jpg"
        style="width: 100%; margin-left: 20px"
        alt
      />
    </div>
    <div>
      <span
        class="headerLogin_nav"
        :class="{ active: active == item.name || $route.name == item.name }"
        @click="goleftnav(item)"
        v-for="item in topnav"
        :key="item.name"
        >{{ item.name }}</span
      >
    </div>
    <div class="headerLogin_tag">
      <el-popover
        placement="bottom"
        width="160"
        v-model="logoutVisible"
        trigger="click"
        class="item headerLogin_logout"
      >
        <p class="menu-item" @click="(editPswVisible = true), getCaptcha1()">
          <i class="el-icon-s-tools"></i>
          <span>修改密码</span>
        </p>
        <p class="menu-item" @click="loadCorps()">
          <i class="el-icon-user-solid"></i>
          <span>切换企业登录</span>
        </p>
        <p class="menu-item">
          <i class="el-icon-warning"></i>
          <span @click="showDialog3(false)">关于平台协议</span>
        </p>
        <p class="menu-item">
          <i class="el-icon-warning"></i>
          <span @click="logout">安全退出</span>
        </p>

        <div class="nickname" type="text" slot="reference">
          <img
            :src="getLogoUrl ? api.getImgURL(getLogoUrl) : defaultLogo"
            alt
          />
          <span> {{ getCorpName }} <i class="el-icon-caret-bottom"></i></span>
        </div>
      </el-popover>
      <span
        v-if="curRoleCodes"
        class="headerLogin_logout msgBox"
        @click="goMessage"
      >
        <img src="../../assets/workbench/msg.png" alt />
        <span class="msgNum" v-show="count">{{ count }}</span>
      </span>
    </div>
    <!-- <el-dialog title="提示信息" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" class="open-account-box">
        <div class="open-account-text">
          <div>亲爱的用户：</div>
          <div>本系统使用【中金支付】作为资金结算通道，在使用金融产品之前需要在平台完成中金资金账户的开立。本账户完全由贵司独立控制，请安心使用</div>
        </div>
        <div slot="footer" class="dialog-footer open-account-btn">
          <el-button type="primary" @click="openAccountBtn(1)">开启中金资金账户</el-button>
        </div>
    </el-dialog> -->
    <!-- <el-dialog :title="openResText" :visible.sync="resultDialogVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" class="open-account-result">
      <div class="open-success" v-if="openSuccess">
        <img src="../../assets/openAccount/open-success.png" alt="">
        <div class="open-result-text">开户成功，请确认如下开户信息</div>
        <div class="account-info">
          <div class="clearfix">
            <div class="fl">开户名称:</div>
            <div class="fr">{{resultData.cltNm}}</div>
          </div>
          <div class="clearfix">
            <div class="fl">资金账号:</div>
            <div class="fr">{{resultData.subNo}}</div>
          </div>
        </div>
      </div>
      <div class="open-error" v-else>
        <img src="../../assets/openAccount/open-error.png" alt="">
        <div class="open-result-text">开户失败，请点击重新申请开户</div>
        <div class="account-info">
          <div>失败原因：</div>
          <div>{{errorMsg}}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer open-result-btn">
        <el-button type="primary" v-if="openSuccess" @click="resultDialogVisible = false">确认</el-button>
        <el-button type="primary" v-else @click="openAccountBtn(2)">重新申请</el-button>
      </div>
    </el-dialog> -->
    <el-dialog
      id="dialog2"
      class="agreement-dialog2"
      :visible.sync="agreementDialog2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      center
    >
      <div class="agreement-container">
        <member-services v-if="this.fileType == '1'"></member-services>
        <privacy-protocol v-if="this.fileType == '2'"></privacy-protocol>
        <disclaimer v-if="this.fileType == '3'"></disclaimer>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="closeDialog(false)">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog-box"
      title="平台协议"
      :visible.sync="agreementDialog3"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="20%"
      center
    >
      <a href="#" @click="showDialog3('1')">《会 员 服 务 协 议》</a>
      <a href="#" @click="showDialog3('2')">《隐 私 协 议》</a>
      <a href="#" @click="showDialog3('3')">《免 责 声 明》</a>
      <span slot="footer">
        <el-button type="primary" @click="closeDialog(true)">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="agreement-dialog"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      center
    >
      <div class="agreement-container">
        <service-agreement></service-agreement>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="!readDisabled" @click="hasRead()"
          >同意</el-button
        >
        <el-button type="primary" v-else :disabled="readDisabled"
          >阅读并同意 {{ readCount }}秒</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="输入该企业的登录密码"
      :visible.sync="loginVisible"
      width="350px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-position="left"
        label-width="0px"
        class="lie"
      >
        <el-form-item prop="name">
          <el-input
            type="text"
            v-model.trim="loginForm.name"
            placeholder="账号"
            @change="getcompname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item prop="relationCorpId">
          <el-select
            v-model="loginForm.relationCorpId"
            placeholder="请选择企业"
            style="width: 100%"
          >
            <el-option
              v-for="item in corpOptions"
              :key="item.corpId"
              :label="item.corpName ? item.corpName : item.registerCorpName"
              :value="item.corpId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            :type="password"
            v-model="loginForm.pass"
            placeholder="密码"
            class="content-fontPass"
          ></el-input>
          <i
            class="fa fa-eye eyetab"
            aria-hidden="true"
            @click="eyefn"
            style="right: 10px"
          ></i>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                v-model="loginForm.captcha"
                placeholder="验证码"
              ></el-input>
            </el-col>
            <el-col :span="12" class="login-captcha">
              <img
                :src="captchaPath"
                @click="getCaptcha()"
                alt
                width="150"
                height="40"
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-row>
          <div class="grid-content bg-purple-dark fr mima">
            <span>
              <router-link to="/retrieve_password" style="color: #1a2891"
                >忘记密码 ?</router-link
              >
            </span>
          </div>
        </el-row>
        <br />
        <el-form-item style="width: 100%">
          <el-button type="primary" @click.native.prevent="loginSubmit"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="切换登录企业"
      :visible.sync="corpVisible"
      :center="true"
      width="25%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="display: flex; justify-content: center">
        <el-select v-model="valueCorp" clearable placeholder="请选择登录企业">
          <el-option
            v-for="item in corpOptions"
            :key="item.corpId"
            :label="item.corpName ? item.corpName : item.registerCorpName"
            :value="item.corpId"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="quickLoginFn">企业用户登录</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="editPswVisible"
      width="450px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="lieseb"
    >
      <el-form
        :model="editPswForm"
        :rules="editPswRules"
        ref="editPswForm"
        label-position="left"
        label-width="120px"
        class="lie"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="editPswForm.oldPassword"
            placeholder="旧密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="输入新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="editPswForm.newPassword"
            placeholder="8位以上且必须为英文大小写字母加数字特殊字符组合"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="newPassword1">
          <el-input
            type="password"
            v-model="editPswForm.newPassword1"
            placeholder="再次输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="verifyCode">
          <el-row>
            <el-col :span="12">
              <el-input
                v-model="editPswForm.verifyCode"
                placeholder="输入图形验证码"
              ></el-input>
            </el-col>
            <el-col :span="12" class="captcha">
              <img
                :src="editPswForm.captchaPath"
                @click="getCaptcha1()"
                alt
                width="100"
                height="50"
              />
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPswVisible = false">取消</el-button>
        <el-button type="primary" @click.native="submit">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api';
import bus from '@/util/bus.js';
import { getUUID } from '@/util/index.js';
import serviceAgreement from '@/components/agreementList/service-agreement';
import defaultLogo from '@/assets/workbench/logo.png';
import * as types from '@/store/mutation-types';
import Disclaimer from '@/components/agreementList/disclaimer';
import privacyProtocol from '@/components/agreementList/privacy-protocol';
import memberServices from '@/components/agreementList/member-services';
import { getPassword } from '@/util/common.js';
export default {
  name: 'HeaderBase',
  watch: {
    $route: {
      handler(to, from) {
        if (to.path != from.path) {
          this.active = sessionStorage.name;
        }
      },
      deep: true,
    },
  },
  components: {
    'service-agreement': serviceAgreement,
    disclaimer: Disclaimer,
    'privacy-protocol': privacyProtocol,
    'member-services': memberServices,
  },
  // mixins: [corpUserAuthMixin],
  data() {
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.editPswForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      fileType: '1',
      api: api,
      src: '',
      password: '',
      curRoleCodes: true,
      logoutVisible: false,
      editPswVisible: false,
      corpOptions: [],
      corpVisible: false,
      defaultLogo: defaultLogo,
      editPswForm: {
        uuid: '',
        captchaPath: '',
        oldPassword: '',
        newPassword: '',
        verifyCode: '',
        newPassword1: '',
      },
      editPswRules: {
        verifyCode: [
          { required: true, message: '图形验证码不能为空', trigger: 'blur' },
        ],
        oldPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*]).{9,}$/,
            message:
              '密码需大于8位以上且必须为英文大小写字母加数字特殊字符组合',
            trigger: 'blur',
          },
        ],
        newPassword1: [
          { required: true, message: '请输入密码' },
          { validator: checkPassword, trigger: 'blur' },
        ],
      },
      valueCorp: '',
      loginVisible: false,
      loginForm: {
        name: '',
        pass: '',
        captcha: '',
        uuid: '',
        relationCorpId: '',
      },
      captchaPath: '',
      rules: {
        pass: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        name: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        captcha: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
        ],
        relationCorpId: [
          { required: true, message: '请选择企业', trigger: 'change' },
        ],
      },
      isTitle: true,
      isUse: '/login',
      company: '',
      title: '',
      active: '工作台',
      selectTopNav: {},
      item: {},
      corpList: {}, //当前登录企业
      count: 0,
      projectCode: '',
      dialogVisible: false,
      resultDialogVisible: false,
      openSuccess: '',
      openResText: '',
      resultData: {},
      errorMsg: '',
      loading: '',
      agreementDialog: false,
      agreementDialog2: false,
      agreementDialog3: false,
      readCount: '',
      readDisabled: true,
      userId: JSON.parse(sessionStorage.getItem('user')).userId,
      roleCodes: JSON.parse(sessionStorage.user).roleCodes[0],
      quickFlag: false,
      // 资金方E正通跳转
      pathList: {
        fund_first: '/fund/credit/review/1',
        fund_second: '/fund/easypay/openapproval/second/1',
        fund_third: '/fund/easypay/openapproval/three/1',
        fund_admin: '/fund/credit/apply',
      },
      // 链属企业E正通跳转
      pathList3: {
        general_admin: '/easypay/mange/1',
        general_first: '/easypay/openapproval/1',
        general_agent: '/easypay/mange/1',
      },
    };
  },
  computed: {
    getCorpName() {
      return this.$store.state.corpName;
    },
    getLogoUrl() {
      return this.$store.state.logoURL;
    },
    topnav() {
      if (this.roleCodes === 'general_first') {
        return [
          {
            name: '工作台',
            path: '/workbench',
          },
          {
            name: '优企通',
            path: '',
          },
        ];
      } else {
        return [
          {
            name: '工作台',
            path: '/workbench',
          },
          {
            name: '优企通',
            path: '',
          },
          {
            name: '企业管理',
            path:
              JSON.parse(sessionStorage.user).corpType != 6
                ? '/business/Business_management'
                : '/Business/message?pageSize=10&pageNum=1',
          },
        ];
      }
    },
  },
  mounted() {
    this.active = sessionStorage.name;
    this.messageCenterMessageTotal();
  },
  created() {
    bus.$emit('getMessageCenterMessageTotal');
    console.log(this.$route, 'route');
    this.getCorpByCorpId();
    var corpList = JSON.parse(sessionStorage.getItem('corpList'));
    this.corpList = corpList;
    if (sessionStorage.user) {
      if (JSON.parse(sessionStorage.user).corpType == 1) {
        this.topnav[2] = {
          name: '易保理',
          path: '/fund/factoring/FundFactoringManageIndex/1',
        };
        this.topnav[1] = {
          name: '优企通',
          path: this.pathList[this.roleCodes],
        };
        if (this.roleCodes.includes('admin')) {
          this.topnav[this.topnav.length - 1] = {
            name: '企业管理',
            path: '/Business/user_management',
          };
        } else {
          this.topnav[this.topnav.length - 1] = {
            name: '企业管理',
            path: '/Business/message',
          };
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 2) {
        this.topnav[1] = {
          name: '优企通',
          path: '/easypay/amountmange/1',
        };
        if (this.roleCodes.includes('admin')) {
          this.topnav[this.topnav.length - 1] = {
            name: '企业管理',
            path: '/Business/Business_management',
          };
        } else {
          this.topnav[this.topnav.length - 1] = {
            name: '企业管理',
            path: '/Business/message',
          };
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 3) {
        this.topnav[1] = {
          name: '优企通',
          path: this.pathList3[this.roleCodes],
        };
      } else if (JSON.parse(sessionStorage.user).corpType == 6) {
        this.topnav[1] = {
          name: '优企通',
          path: '/dbeasypay/mange/1',
        };
      }
    } else {
      this.$router.push({
        path: '/login',
      });
    }
  },

  methods: {
    startLoading: function () {
      this.loading = this.$loading({
        lock: true,
        text: 'loading...',
        background: 'rgba(0, 0, 0, 0.3)',
      });
    },
    eyefn() {
      this.password = this.password == 'password' ? 'text' : 'password';
    },
    endLoading: function () {
      this.loading.close();
    },
    logout() {
      this.$emit('logout', '');
    },
    // 查询企业是否开户
    checkOpenAccount() {
      return new Promise((resolve, reject) => {
        api
          .checkOpenAccount()
          .then((res) => {
            if (res.success) {
              // 未开户
              this.dialogVisible = true;
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 企业开户
    openAccountBtn(type) {
      this.startLoading();
      if (type == 1) {
        this.dialogVisible = false;
      } else {
        this.resultDialogVisible = false;
      }
      api.getOpenAccountStatus().then((res) => {
        if (res.success) {
          // 开户成功
          this.openSuccess = true;
          this.openResText = '资金账户开户成功';
          this.resultData = res.datas.openAccount;
        } else {
          // 开户失败
          this.openSuccess = false;
          this.openResText = '资金账户开户失败';
          this.errorMsg = res.message;
        }
        this.endLoading();
        this.resultDialogVisible = true;
      });
    },
    goleftnav(item) {
      // this.checkOpenAccount().then(res => {
      this.item = item;
      this.quickFlag = false;
      if (item.name == '工作台') {
        this.getCorpByCorpId(item); //获取企业认证状态
      } else if (item.name == '优企通') {
        this.getCorpByCorpId(item); //获取企业认证状态
        this.showDialog(); // 判断否阅读过《优企通服务协议》
      } else {
        this.getCorpByCorpId(item); //获取企业认证状态
        // 这里需要判断是否进行过企业认证
      }
      // })
    },
    isCfvcaUserId() {
      //是否开启电子签章
      // 企业认证过了之后需要判断电子签章状态
      if (this.corpList.cfvcaUserId) {
        //签章成功之后 1 判断有没有获取projectCode  获取projectCode\
        var corp = JSON.parse(sessionStorage.getItem('corpList'));
        if (corp.projectCode != null && corp.projectCode != '') {
          // this.active = this.item.name;
          // sessionStorage.name = this.item.name;
          if (this.item.path) {
            this.$router.push({ path: this.item.path });
          }
        } else {
          console.log('projectCode 发送短信 ');
          this.getProjectCode();
        }
      } else {
        this.$alert(
          '亲爱的用户：\n 系统检测到您尚未授权开启平台电子签章。授权开启电子签章是平台基本的企业业务授权动作，可以合法合理的保障用户的财产和信息安全。点击下方按钮立即授权开启电子签章。',
          '提示信息',
          {
            confirmButtonText: '开启电子签章',
            callback: (action) => {
              api.CorpSignCreateUser().then((res) => {
                if (res.code == 100000) {
                  this.$message.success(res.message);
                  this.getCorpByCorpId();
                  //调用发短信接口
                } else {
                  this.$message.error(res.message);
                }
              });
            },
          }
        );
      }
    },
    companyPerfectFn() {
      this.$alert('企业信息未完善，请继续完善', '企业认证状态', {
        confirmButtonText: '确定',
        showClose: false,
        callback: (action) => {
          if (action == 'confirm') {
            this.$router.push({
              path: '/CompanyPerfect',
              query: {
                corpId: this.corpList.corpId,
              },
            });
          }
        },
      });
    },
    getCorpByCorpId(item) {
      var user = JSON.parse(sessionStorage.getItem('user'));
      api
        .GetCorpByCorpId({
          relationCorpId: user.relationCorpId,
        })
        .then((res) => {
          if (res.success) {
            this.corpList = res.datas.corp;
            sessionStorage.setItem('corpList', JSON.stringify(this.corpList));
            sessionStorage.setItem('logoURL', JSON.stringify(res.logoURL));
            this.$store.commit(types.UPDATE_logoURL, res.datas.logoURL);
            this.$store.commit(types.UPDATE_corpName, res.datas.corp.corpName);
            // 企业信息完善 1 已完善 2 未完善 3企业资料完善完成 4个人资料完善完成 registerIdentify 1 已检测 2 未检测
            if (
              // 可以替换为下面代码
              // [1,2,3,4].includes(res.datas.corp.corpInfoComplete) && [2,null].includes(res.datas.corp.registerIdentify)
              (res.datas.corp.corpInfoComplete == 2 ||
                res.datas.corp.corpInfoComplete == 3 ||
                res.datas.corp.corpInfoComplete == 1 || // 添加活体判断可能会影响其他问题
                res.datas.corp.corpInfoComplete == 4) &&
              (res.datas.corp.registerIdentify == 2 ||
                res.datas.corp.registerIdentify == null)
            ) {
              if (res.datas.corp.corpInfoComplete == 2) {
                this.$router.push({
                  path: '/Company',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
              if (res.datas.corp.corpInfoComplete == 3) {
                if (!this.quickFlag) {
                  this.companyPerfectFn();
                }
                return;
              }
              if (res.datas.corp.corpInfoComplete == 4) {
                this.$router.push({
                  name: 'CompanyConfirm',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
              //人脸识别
              if (
                res.datas.corp.registerIdentify == 2 ||
                res.datas.corp.registerIdentify == 3 ||
                res.datas.corp.registerIdentify == null
              ) {
                this.$router.push({
                  name: 'CompanyVideo',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
            } else {
              //信息完善之后 需要判断企业是否已经认证 "企业信息审核： 40初始状态 41平台初审通过 43 平台复审通过"
              if (res.datas.corp.corpInfoCheck == 43) {
                // this.checkOpenAccount()
                if (!res.datas.corp.cfvcaUserId) {
                  this.$alert('开启CFCA签章', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                      api.CorpSignCreate().then((res) => {
                        if (res.success) {
                          this.$message.success(res.message);
                        } else {
                          this.$message.error(res.message);
                        }
                      });
                    },
                  });
                }
                this.isCfvcaUserId();
              } else {
                if ([40, 41, 42, 44].includes(res.datas.corp.corpInfoCheck)) {
                  this.$router.push({
                    path: '/CompanyResult',
                    query: {
                      corpId: this.corpList.corpId,
                    },
                  });
                }
              }
            }
          }
        })
        .then(() => {
          if (this.quickFlag) {
            this.$router.push('/workbench');
            location.reload();
            this.active = '工作台';
          }
        });
    },

    getProjectCode() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      let param = {
        corpId: user.relationCorpId,
      };
      api.SignSendMessage(param).then((res) => {
        if (res.success) {
          if (res.datas.sendMessage) {
            this.$message.success('发送成功');
            this.projectCode = res.datas.projectCode;
            var user = JSON.parse(sessionStorage.getItem('user'));
            let meessage =
              '提示信息：CFCA向您尾号为' +
              user.phone +
              '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
            this.$prompt(meessage, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
              // inputErrorMessage: "邮箱格式不正确"
            })
              .then(({ value }) => {
                let param = {
                  checkCode: value,
                  projectCode: this.projectCode,
                };
                api.CheckProjectCode(param).then((res) => {
                  if (res.success) {
                    this.$alert('开启电子签章成功', '', {
                      confirmButtonText: '确定',
                      callback: (action) => {},
                    });
                  } else {
                    this.$alert(res.message, '', {
                      confirmButtonText: '确定',
                      callback: (action) => {},
                    });
                  }
                });
              })
              .catch(() => {});
          }
        }
      });
    },
    loadCorps() {
      api
        .getCorpIdByUserName({
          userName: JSON.parse(sessionStorage.user).userName,
        })
        .then((res) => {
          if (res.code == '100000') {
            if (res.datas.list.length < 2) {
              this.$message.error('没有其他企业身份');
            } else {
              var corpList = JSON.parse(sessionStorage.getItem('corpList'));
              this.corpOptions = res.datas.list.filter(function (v) {
                return v.corpId != corpList.corpId;
              });
              this.corpVisible = true;
              this.valueCorp = '';
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 获取验证码
    getCaptcha() {
      this.loginForm.uuid = getUUID();
      this.captchaPath = api.verifycode(this.loginForm.uuid);
    },
    getCaptcha1() {
      this.$nextTick(() => {
        this.$refs.editPswForm.clearValidate();
      });
      this.editPswForm.uuid = getUUID();
      this.editPswForm.captchaPath = api.verifycode(this.editPswForm.uuid);
    },
    getcompname() {
      this.corpOptions = [];
      this.loginForm.relationCorpId = '';
      api.getCorpIdByUserName({ userName: this.loginForm.name }).then((res) => {
        if (res.code == '100000') {
          var corpList = JSON.parse(sessionStorage.getItem('corpList'));
          this.corpOptions = res.datas.list.filter(function (v) {
            return v.corpId != corpList.corpId;
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //存sessionStorage
    saveStorage(res, password) {
      let tokens =
        res.datas.token.token_type + ' ' + res.datas.token.access_token;
      sessionStorage.setItem('user', JSON.stringify(res.datas.token.user));
      sessionStorage.setItem('token', tokens);
      sessionStorage.logtimer = Date.now();
      sessionStorage.setItem('layoutToken', res.datas.token.access_token);
      sessionStorage.refreshtoken = res.datas.token.refresh_token;
      this.$store.commit(types.UPDATE_userInfo, res.datas.token.user); //更新store中的用户信息
      this.$store.commit(types.UPDATE_password, password); //更新加密后的密码字符串
      sessionStorage.setItem('userId', JSON.stringify(password));
      this.getCorpByCorpId(); //获取企业信息
    },
    quickLoginFn() {
      if (this.valueCorp == '') {
        this.$message.warning('请选择登录企业');
        return;
      }
      let _this = this;
      api
        .quickLogin({
          corpId: this.valueCorp,
          password: JSON.parse(sessionStorage.getItem('userId')),
        })
        .then((res) => {
          _this.corpVisible = false;
          if (res.code == '100000') {
            this.quickFlag = true;
            this.saveStorage(res, JSON.parse(sessionStorage.getItem('userId')));
          } else if (res.code == '10001') {
            this.$message({
              message: '密码有效期已过期，请及时修改密码',
              type: 'error',
            });
            this.$router.push({
              path: '/retrieve_password',
            });
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              onClose: function () {
                _this.loginVisible = true;
                _this.loginForm.relationCorpId = _this.valueCorp;
                _this.loginForm.name = JSON.parse(sessionStorage.user).userName;
                _this.getCaptcha();
              },
            });
          }
        });
    },
    loginSubmit(ev) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let password = getPassword(this.loginForm.pass);
          console.log(password, 'password===<<<>>>');
          let LoginAdminUser = {
            userName: this.loginForm.name,
            password: password,
            uuid: this.loginForm.uuid,
            verifyCode: this.loginForm.captcha,
            corpType: '3',
            relationCorpId: this.loginForm.relationCorpId,
            channelCode: 'YRY', // 渠道
          };
          // this.$emit('reload')
          api.Login(LoginAdminUser).then((res) => {
            console.log(12333131, res);
            // return
            if (res.code == '100000') {
              this.saveStorage(res, password);
              this.quickFlag = true;
              // sessionStorage.setItem("user", JSON.stringify(res.datas.token.user))
              this.loginVisible = false;
              // this.$router.push("/workbench");
              // location.reload()
              this.saveStorage(
                res,
                JSON.parse(sessionStorage.getItem('userId'))
              );
              // this.getCorpByCorpId()
              // 存储是否为核心+链属企业
              // this.active = "工作台";
              // this.$emit('reload')
            } else if (res.code == '10001') {
              this.$message({
                message: '密码有效期已过期，请及时修改密码',
                type: 'error',
              });
              this.$router.push({
                path: '/retrieve_password',
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
              });
            }
          });
        }
      });
    },
    //修改密码
    submit() {
      this.$refs.editPswForm.validate((valid) => {
        if (valid) {
          let params = {
            oldPassword: this.editPswForm.oldPassword,
            newPassword: this.editPswForm.newPassword,
            newPassword1: this.editPswForm.newPassword1,
            verifyCode: this.editPswForm.verifyCode,
            uuid: this.editPswForm.uuid,
            userId: this.userId,
          };
          api.UpdatePassword(params).then((res) => {
            if (res.success) {
              this.$alert('修改成功', '提示', {
                confirmButtonText: '确定',
                callback: (action) => {
                  sessionStorage.clear();
                  this.$router.push('/login');
                },
              });
            } else {
              this.$message.error(`${res.message}`);
            }
          });
        }
      });
    },
    // 站内信
    goMessage() {
      this.$router.push({ name: '站内信管理' });
    },
    // 获取新消息条数
    messageCenterMessageTotal() {
      bus.$on('getMessageCenterMessageTotal', (data) => {
        let param = {
          corpId: this.corpList.corpId,
        };
        api.messageCenterMessageTotal(param).then((res) => {
          if (res.success) {
            this.count = res.datas.count;
          }
        });
      });
    },
    // 弹出协议
    showDialog() {
      if (
        JSON.parse(sessionStorage.getItem('corpList')).agreedConsent !== 2 &&
        JSON.parse(sessionStorage.getItem('corpList')).corpType !== 1
      ) {
        this.agreementDialog = true;
        this.readCountDown();
      }
    },
    showDialog3(type) {
      if (type === false) {
        this.agreementDialog3 = true;
      } else {
        this.agreementDialog2 = true;
        this.fileType = type;
      }
    },
    closeDialog(v) {
      if (v) this.agreementDialog3 = false;
      this.agreementDialog2 = false;
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      this.agreementDialog = false;
      this.readDisabled = false;
      api.readAgreement().then((res) => {
        if (res.success) {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';
.headerLogin_tag {
  // float: right;
  // margin-top: 30px;
  // width: 30%;
  display: flex;
  flex-direction: row-reverse;
  // margin-top: -64px;
}

.menu-item {
  height: 35px;
  line-height: 35px;
  width: 100%;
  cursor: pointer;
  > i {
    padding: 0 10px;
  }
}
.headerLogin_nav {
  float: left;
  // width: 10%;
  max-width: 20%;
  min-width: 7%;
  height: 64px;
  font-size: 18px;
  cursor: pointer;
  color: #2937a6;
  font-weight: bold;
  // opacity: 0.65;
  // color: rgba(255, 255, 255, 0.7);
  text-align: center;
  line-height: 64px;
  font-family: PingFangSC-Regular;
}

.headerLogin_nav:hover {
  font-weight: bold;
  border-top: 2px solid #2937a6;
}

.active {
  background: #2937a6;
  // background: rgb(63, 123, 240);
  font-weight: bold;
  color: #ffffff;
  font-family: PingFang-SC-Bold;
}

.headerLogin {
  width: 100%;
  height: 64px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #e6e6e6;
  // background-color: #2937A6;
  // border-bottom: 1px solid #e6e6e6;
}

.headerLogin_img {
  float: left;
  padding-left: 0px;
  width: 208px;
  height: 64px;
  @include flexCtn();
  align-items: center;
  margin-right: 70px;
}

.headerLogin_title {
  float: left;
  font-size: 20px;
  color: #595959;
  margin-left: 20px;
  margin-top: 15px;
}

.headerLogin_tag {
  float: right;
  // margin-top: 30px;
  // width: 30%;
}

.headerLogin_login {
  float: right;
  font-size: 14px;
  color: #333333;
  margin-right: 20px;
  margin-top: 20px;
}

.headerLogin_logout {
  float: right;
  font-size: 14px;
  color: #2937a6;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  // color: #2937A6;
  &.item {
    // max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    border: none;
    padding: 0;
  }
}

.msgBox {
  position: relative;
  img {
    vertical-align: middle;
  }
  .msgNum {
    position: absolute;
    top: -10px;
    right: -10px;
    min-width: 20px;
    height: 20px;
    background-color: rgba(244, 37, 37, 0.8);
    border-radius: 50%;
    font-size: 12px;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
  }
}
.telBox {
  cursor: default;
  font-family: 'HelveticaNeue';
  font-size: 18px;
  color: #595959;
  line-height: 20px;
  img {
    vertical-align: middle;
  }
  span {
    opacity: 0.65;
    vertical-align: middle;
  }
}
.fl {
  float: left;
}
a {
  text-decoration: none;
  color: #000;
  &:hover {
    color: #2937ae;
  }
}
</style>
<style lang="scss">
.header-base {
  .open-account-box {
    .el-dialog {
      height: 400px;
    }
    .open-account-text {
      background-color: #eef5f9;
      border-radius: 5px;
      padding: 20px 20px 100px;
      line-height: 36px;
      color: #000;
      font-size: 16px;
    }
    .el-dialog__header {
      border-bottom: 1px solid #ccc;
    }
    .el-dialog__footer {
      position: absolute;
      top: 220px;
      width: 100%;
      height: 300px;
      background: url(../../assets/openAccount/info-bgi.png) center top
        no-repeat;
      background-size: 100%;
      padding: 140px 0 0;
    }
    .open-account-btn {
      text-align: center;
      .el-button--primary,
      .el-button--primary:focus,
      .el-button--primary:hover {
        background-color: #faad14;
        border-image-width: 6px;
        border-color: rgba(255, 255, 255, 0.1);
        font-size: 16px;
        padding: 14px 50px;
      }
    }
  }
  .open-account-result {
    .el-dialog__header {
      border-bottom: 1px solid #ccc;
    }
    .open-success,
    .open-error {
      img {
        display: block;
        margin: 30px auto;
      }
      .open-result-text {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
      .account-info {
        width: 60%;
        background-color: #fafafa;
        border-radius: 5px;
        padding: 20px;
        margin: 20px auto;
        line-height: 32px;
      }
    }
    .open-result-btn {
      text-align: center;
      .el-button--primary {
        padding: 10px 20px;
      }
    }
  }
}
.nickname {
  height: 24px;
  display: flex;
  justify-content: space-between;
  > img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
  }
  > span {
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 22px;
    color: #2f5597;
  }
}
.dialog-box {
  .el-dialog {
    min-width: 400px;
  }
}
</style>
<style>
.lieseb .el-form-item {
  margin-bottom: 30px;
}
</style>
