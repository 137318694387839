<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList blrzstep">
      <steps :stepList="stepList" :current="current"></steps>
      <div class="easypayby goback yfdtwo">
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form
            :model="info"
            label-position="left"
            ref="info"
            label-width="160px"
            class="demo-ruleForm"
            v-if="type == 1">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="融资项目编号: ">
                  <span>{{info.receivableAccountFinanceNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="债权企业全称: ">
                  <span>{{info.financeCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="社会统一信用代码: ">
                  <span>{{info.financeCorpCode}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账款到期日: ">
                  <span>{{info.accountEndTime ? moment(info.accountEndTime).format('YYYY-MM-DD'): ''}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="22">
              <el-col :span="8">
                <el-form-item label="账款合计金额（元）: ">
                  <span>{{info.relationReceivedAccountAmounts}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账款合计金额（大写）: ">
                  <span>{{info.upper3}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="期望融资年化利率（％）: ">
                  <span>{{info.expectFinanceInterestMin}}％~{{info.expectFinanceInterestMax}}％</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="期望融资金额（元）: ">
                  <span>{{info.preFinanceAmount}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="期望融资金额（大写）: ">
                  <span>{{info.upper2}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="核心企业确权: ">
                  <span v-if="info.corpAck == 1">确权</span>
                  <span v-else>未确权</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="企业账户开户行: ">
                  <span>{{info.payeeOpenBank}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业账号: ">
                  <span>{{info.payeePublicAccountNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form v-if="type == 2" :model="info" ref="info" label-width="160px" class="demo-ruleForm" label-position="left">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="融资项目编号: ">
                  <span>{{info.receivableAccountFinanceNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="债权企业全称: ">
                  <span>{{info.financeCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="社会统一信用代码: ">
                  <span>{{info.financeCorpCode}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账款到期日: ">
                  <span>{{info.accountEndTime ? moment(info.accountEndTime).format('YYYY-MM-DD'): ''}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="22">
              <el-col :span="8">
                <el-form-item label="账款合计金额（元）: ">
                  <span>{{$changeMoneyThousand(info.relationReceivedAccountAmounts)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账款合计金额（大写）: ">
                  <span>{{info.upper3}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="期望融资年化利率（％）: ">
                  <span>{{info.expectFinanceInterestMin}}％~{{info.expectFinanceInterestMax}}％</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="期望融资金额（元）: ">
                  <span>{{$changeMoneyThousand(info.preFinanceAmount)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="期望融资金额（大写）: ">
                  <span>{{info.upper2}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="核心企业确权: ">
                  <span v-if="info.corpAck == 1">确权</span>
                  <span v-else>未确权</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="资金机构: ">
                  <span>{{info.fundCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="签约融资额度（元）: ">
                  <span>{{$changeMoneyThousand(info.financeAmount)}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="大写: ">
                  <span>{{info.upper}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="融资年化利率（％）: ">
                  <span>{{info.financeInterest}}％</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业账户开户行: ">
                  <span>{{info.payeeOpenBank}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业账号: ">
                  <span>{{info.payeePublicAccountNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="yfdbaseline"></div>
        <div v-if="type == 1">
          <div class="yfdsize">有意向资金机构</div>
          <div class="httotal">
            有
            <span>{{tableList.length}}</span> 个意向报价，
            最低意向利率
            <span>{{interestMin}}</span> %,
            最高意向金额
            <span>{{amountMax}}</span> 元.
          </div>
        </div>
        <div v-if="type == 2">
          <div class="yfdsize">绑定应收账款</div>
          <div class="httotal">
            已添加应收账款
            <span>{{tableList.length}}</span> 笔，账款总计金额
            <span>{{ $changeMoneyThousand( info.relationReceivedAccountAmounts ) }}</span> 元。
          </div>
        </div>
        <div  class="reset-table">
          <el-table v-if="type == 1" ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column v-if="type == '1'" :selectable='checkSwitch' type="selection" width="65" ></el-table-column>
            <el-table-column prop="fundCorpName" label="资金机构" min-width="200"></el-table-column>
            <el-table-column prop="fundCorpNature" label="机构性质" min-width="170"></el-table-column>
            <el-table-column prop="corpAck" label="需要确权" min-width="120">
              <template slot-scope="scope">{{ scope.row.corpAck == 1 ?'需要':'不需要' }}</template>
            </el-table-column>
            <el-table-column  label="需要补充信息" min-width="150">
              <template slot-scope="scope">{{scope.row.supplyFileSet.length>0?scope.row.supplyFileSet.join(','):'不需要' }}</template>
            </el-table-column>
            <el-table-column prop="intentionInterest" label="意向融资年化利率(％)" min-width="170"></el-table-column>
            <el-table-column prop="intentionAmount" label="意向融资金额(元)" min-width="170">
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="有效确认时间" min-width="150">
              <template slot-scope="scope">
                {{
                scope.row.quoteTimeLimit == 1 ?'24小时' :
                scope.row.quoteTimeLimit == 2 ? '48小时' :
                scope.row.quoteTimeLimit == 3 ? '72小时' :
                scope.row.quoteTimeLimit == 4 ? '无时限' :''
                }}
              </template>
            </el-table-column>
          </el-table>
          <el-table v-if="type == 2" ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额(元)" min-width="170">
              <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.accountAmount ) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="150">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="150">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align:center;padding: 30px 0;" class="xytext" v-if="type == '1'">
            <el-checkbox v-model="checked" @change="showDialog(checked)">我已阅读并同意</el-checkbox>
            <a href="javascript:;" @click="showDialog(checked,'2')">平台收费服务协议</a>
          </div>
          <div class="card-hd" style="height: 150px;">
            <el-row style="padding-top: 40px;" v-if="type == '1'">
              <el-col :span="7" class="lb_body" :offset="7">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;"
                  size="small"
                  @click="reject"
                >取消</el-button>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="agree" :disabled="payStatus == 2"
                >接受报价</el-button>
              </el-col>
            </el-row>
            <el-row style="padding-top: 40px;" v-if="type == '2'">
              <!-- <el-col :span="7" class="lb_body" :offset="7">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;"
                  size="small"
                  @click="cancel"
                >取消</el-button>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="sure"
                >确定</el-button>
              </el-col> -->
              <el-col :span="7" class="lb_body" :offset="10">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;"
                  size="small"
                  @click="cancel"
                >返回</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <el-dialog id="dialog" class="agreement-dialog" :visible.sync="agreementDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="50%" center>
       <div class="agreement-container">
      <charge-agreement v-if="fileType == '2'" :chargeInfo="chargeInfo"></charge-agreement>
    </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" v-if="!showType && !readDisabled" @click="hasRead()">同意</el-button>
          <el-button type="primary" v-if="!showType && readDisabled" :disabled="readDisabled">阅读并同意 {{readCount}}秒</el-button>
          <el-button type="primary" @click="closeDialog">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog title="缴纳平台服务费" :visible.sync="dialogVisible" width="70%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="dialog-info-title">尊敬的用户，接受资方企业报价之前需向平台缴纳相应的服务费用</div>
        <el-alert title="平台服务费用按照融资金额的0.3%进行计算" type="warning" show-icon :closable="false"></el-alert>
        <ul class="base-info-ul">
          <li>收费类型：{{getTypes(costInfo1.types)}}</li>
          <li>收费企业：{{costInfo1.receivedCorpName}}</li>

          <li>金额：{{$changeMoneyThousand(costInfo1.amount)}} 元人民币</li>
<!--          <li>收费类型：{{getTypes(costInfo2.types)}}</li>-->
<!--          <li>收费企业：{{costInfo2.receivedCorpName}}</li>-->

<!--          <li>金额：{{$changeMoneyThousand(costInfo2.amount)}} 元人民币</li>-->
          <li>总计：</li>
          <li>总额：{{$changeMoneyThousand(0..add(...[costInfo1.amount, costInfo2.amount]))}} 元人民币</li>
        </ul>
        <div style="text-align: center;margin-top: 20px;">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmPaymentServiceFee" style="margin-left: 40px;" :disabled="forbidden">缴费</el-button>
        </div>
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import steps from "@/views/base/steps.vue";
import chargeAgreement from "@/components/agreementList/charge-agreement";

export default {
  components: {
    steps,
    chargeAgreement
  },
  data() {
    return {
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      loading: "",
      breadcrumblist: ["保理融资"],
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {},
      multipleSelection: [], //选中的表格
      moment: moment,
      type: "",
      amountMax: "",
      interestMin: "",
      list: [],
      stepList: [
        {
          name: "发起融资",
          realName: "",
          createTime: ""
        },
        {
          name: "项目内审",
          realName: "",
          createTime: ""
        },
        {
          name: "平台撮合",
          realName: "",
          createTime: ""
        },
        {
          name: "机构报价",
          realName: "",
          createTime: ""
        },
        {
          name: "选择报价",
          realName: "",
          createTime: ""
        },
        {
          name: "补件确权",
          realName: "",
          createTime: ""
        },
        {
          name: "等待签约",
          realName: "",
          createTime: ""
        },
        {
          name: "签约完成",
          realName: "",
          createTime: ""
        }
      ],
      current: 1,
      dialogVisible: false,
      forbidden: false,
      costInfo: [],
      costInfo1: {},
      costInfo2: {},
      receivableAccountFinanceId: this.$route.query.receivableAccountFinanceId,
      payStatus: '',
      checked: false,
      agreementDialog: false,
      fileType: "2",
      showType: '',
      readDisabled: true,
      readCount: "",
      chargeInfo: {
        no: "", // 合同编号
        financeCorpName: "", // 融资申请人/账户名
        legalName: "", // 法定代表人
        creditCode: "", // 统一社会信用代码
        telephone: "", // 联系方式
        registerAddr: "", // 联系地址
        openBankReceiver: "", // 开户行
        publicAccountReceiver: "", // 账号
      },
    };
  },

  created() {
    this.type = this.$route.query.type;
    this.amountMax = this.$route.query.amountMax;
    this.interestMin = this.$route.query.interestMin;
    if (this.type == 1) {
      this.getInfo(this.receivableAccountFinanceId);
    } else {
      this.getInfo2(this.receivableAccountFinanceId);
    }
    this.getContractContent()
  },
  methods: {
    startLoading: function(){
			this.loading = this.$loading({
				lock: true,
				text: '请等待',
				background: 'rgba(0, 0, 0, 0.5)',
			})
		},
		endLoading: function(){
			this.loading.close()
		},
    // selectable(row,index){
    //   if((row.route_type==='-host')|| (row.route_type==='-net')){
    //      return true
    //   }else{
    //       return false
    //   }
    // },
    checkSwitch(row, index) {
      if (row.status == 2) {
        return 0;
      } else {
        return 1;
      }
    },
    getInfo(receivableAccountFinanceId) {
      api
        .GetFinanceQuotedDetail({
          financeId: receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.payStatus = res.datas.detail.payStatus // 缴费状态 1待支付 2支付中 3支付成功 4支付失败
            this.info = res.datas.detail;
            this.info.corpAck = this.info.corpAck + "";
            this.tableList = this.info.quotes;

            let upper = this.convertCurrency(this.info.financeAmount);
            this.$set(this.info, "upper", upper);
            let upper2 = this.convertCurrency(this.info.preFinanceAmount);
            this.$set(this.info, "upper2", upper2);
            let upper3 = this.convertCurrency(
              this.info.relationReceivedAccountAmounts
            );
            this.$set(this.info, "upper3", upper3);
            this.getDic();
            this.getstatus();
          }
        });
    },
    getDic() {
      api.getDicListByType({ type: 17 }).then(res => {
        if (res.success) {
          this.list = res.datas.list;
          //
          this.tableList.forEach(element => {
            var jsarr = JSON.parse(element.claimsCorpProof);
          });
        }
      });
    },
    getInfo2(receivableAccountFinanceId) {
      api
        .FinancingProjectDetails({
          receivableAccountFinanceId: receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.info = res.datas.data;
            this.info.corpAck = this.info.corpAck + "";
            this.tableList = res.datas.list;
            let upper = this.convertCurrency(this.info.financeAmount);
            this.$set(this.info, "upper", upper);
            let upper2 = this.convertCurrency(this.info.preFinanceAmount);
            this.$set(this.info, "upper2", upper2);
            let upper3 = this.convertCurrency(
              this.info.relationReceivedAccountAmounts
            );
            this.$set(this.info, "upper3", upper3);
            this.getstatus();
          }
        });
    },
    agree() {
      if (!this.checked) {
        this.$message.warning("请先阅读并同意协议");
        return false;
      }
      if (this.multipleSelection.length == 1) {
        if(this.payStatus == 1 || this.payStatus == 4) {
          let amount = this.multipleSelection[0].intentionAmount
          this.serviceFeeBreakDown(amount)
          this.dialogVisible = true
        } else if(this.payStatus == 3) {
          this.SubmitQuoteResult()
        }
      } else {
        this.$message.error("请选择意向资金机构");
      }
    },
    serviceFeeBreakDown(amount) {
      this.startLoading()
      // type 1保理模式 2票据模式
      api.serviceFeeBreakDown({amount, type: 1}).then(res => {
        if(res.success) {
          // types 1科技费,2信息费,3支付手续费
          res.datas.list.forEach(v => {
            this.costInfo.push({
              receivableAccountFinanceId: this.receivableAccountFinanceId,
              openCorpName: v.openCorpName,
              receivedCorpName: v.receivedCorpName,
              payerFundAccount: v.payerFundAccount,
              beneficiaryFundAccount: v.beneficiaryFundAccount,
              amount: v.amount,
              types: v.types,
            })

            if(v.types == 1) {
              this.costInfo1 = v
            } else if(v.types == 2) {
              this.costInfo2 = v
            }
            this.endLoading()
          })
        } else {
          this.endLoading()
					this.$message.error(res.message);
        }
      })
    },
    getTypes(types) {
      if(types == 1) {
        return '平台服务费'
      } else if(types == 2) {
        return '信息费'
      } else if(types == 3) {
        return '支付手续费'
      } 
    },
    // 缴纳服务费
    confirmPaymentServiceFee() {
      this.startLoading()
      this.forbidden = true
      api.confirmPaymentServiceFee({payableBillPayList: this.costInfo}).then(res => {
        if(res.code == 'SUCCESS') {
          this.endLoading()
          this.forbidden = false
          this.$alert('缴费成功，请接受报价', "", {
            confirmButtonText: "确定",
            callback: action => {
              this.SubmitQuoteResult()
            }
          });
        } else {
          this.endLoading()
          this.forbidden = false
          this.$alert(res.msg, "", {
            confirmButtonText: "确定",
            callback: action => {
              this.dialogVisible = false
            }
          });
        }
      })
    },
    // 接受报价
    SubmitQuoteResult() {
      let param = {
        receivableAccountFinanceId: this.receivableAccountFinanceId,
        receivableAccountQuoteId: this.multipleSelection[0].receivableAccountQuoteId
      };
      let messages = "选择报价成功，等待融资补件";
      if (this.multipleSelection[0].supplyFileSet.length == 0) {
        messages = "选择报价成功，等待签约";
      }
      api.SubmitQuoteResult(param).then(res => {
        if (res.success) {
          this.dialogVisible = false
          this.$alert(messages, "", {
            confirmButtonText: "确定",
            callback: action => {
              history.go(-1);
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //取消
    reject() {
      this.$router.go(-1);
    },
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(val.pop());
      } else {
        this.multipleSelection = val;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    sure() {},
    handleEdit(data) {
      this.$router.push({
        name: "应收账款详情",
        query: { id: data.receivableAccountId, isshowbtn: 2 }
      });
    },
    getstatus() {
      if (this.info.state == "D") {
        // 作废
        this.current = -1;
      } else {
        if (this.info.financeSignStatus == 50) {
          this.current = 8;
          // 签约完成
        } else if (
          this.info.financeSignStatus >= 20 &&
          this.info.financeSignStatus < 50
        ) {
          this.current = 6;
          // 等待签约
        } else if (
          this.info.financeSignStatus < 20 &&
          this.info.financeSupplyApproveStatus >= 20 &&
          this.info.financeSupplyApproveStatus <= 57 &&
          this.info.corpAckStatus > 1 &&
          this.info.corpAckStatus <= 6
        ) {
          // 补件确权
          this.current = 5;
        } else if (
          this.info.financeQuoteStatus == 3 &&
          this.info.financeSupplyApproveStatus < 20
        ) {
          this.current = 4;
          // 选择报价
        } else if (this.info.financeQuoteStatus == 2) {
          this.current = 3;
          // 机构报价
        } else if (
          this.info.approveStatus >= 41 &&
          this.info.approveStatus <= 49
        ) {
          this.current = 2;
          // 平台撮合
        } else if (
          this.info.approveStatus > 20 &&
          this.info.approveStatus <= 23
        ) {
          // 项目内审
          this.current = 1;
        } else if (this.info.approveStatus == 20) {
          this.current = 1;
          // 发起融资
        } else {
          // 等待签约
        }
      }
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      if(type) {
        if (type == "2") {
          this.$set(this.$data, "fileType", "2");
          this.agreementDialog = true;
          // this.readCountDown();
        }
      } else {
        if (this.checked) {
          this.checked = false;
          this.agreementDialog = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      if (!this.showType) {
        this.readCountDown();
      }
      this.checked = true;
      this.agreementDialog = false;
      this.readDisabled = false;
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    // 获取 融资服务协议内容
    getContractContent() {
      let param = {
        itemId: 1,
        itemType: 1
      }
      api.getContractContent(param).then(res => {
        if(res.success) {
          this.chargeInfo = {...this.chargeInfo, ...res.datas.content}
        }
      })
    }
  }
};
</script>
<style lang="scss">
.reset-table thead .el-table-column--selection .cell {
  display: none;
}
.blrzstep {
  .el-steps--horizontal {
    padding: 0;
  }
}
</style>
<style lang="scss" scope>
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}

.easypayby {
  padding-bottom: 40px;

  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;

  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #f0f7ff;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;

  span {
    color: #da2b2b;
    line-height: 22px;
  }
}

.red-color {
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}

.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }

  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }

    .el-input {
      width: 80%;
    }

    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }

  .pec.elspan {
    .el-input {
      width: 90%;
    }

    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }

  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }

    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }

  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;

    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }

    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;

      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }

    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }

    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;

      span {
        text-indent: 8em;
        display: inline-block;
      }
    }

    .uploadlist {
      padding: 30px 16px 0 16px;

      .upload-demo {
        margin-left: 8em;
      }

      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }

      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }

      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }

      .el-icon-plus {
        font-size: 30px;
      }
    }

    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }

    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;

      span {
        color: #f5212d;
      }
    }
  }
}

.easyformbtn {
  text-align: center;

  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;

    span {
      color: #ffffff;
    }
  }

  button:nth-last-child(1) {
    background-color: #2937a6;
  }

  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }

  button:hover {
    background-color: #edf5ff;

    span {
      color: #67a9fd;
    }
  }
}

.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }

  .iconactive {
    transform: scaleY(-1);
  }
}

.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;

  i {
    margin-right: 6px;
  }
}

.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}

.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}

.pt16 {
  position: relative;
  top: -16px;
}

.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }

  .el-form-item {
    width: calc(100% - 20px);
  }

  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }

  .nolabwid {
    width: calc(25% - 80px);

    .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-form-item__label {
      width: 0 !important;
    }
  }
}

.textindl {
  text-indent: 160px;
}

.fr {
  float: right;
}

// thead {
//   .el-table-column--selection {
//     .cell {
//       display: none;
//     }
//   }
// }
.base-info-ul {
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  li {
    display: inline-block;
    width: 32%;
    line-height: 36px;
  }
}
.dialog-info-title {
  font-weight: bold;
  line-height: 36px;
}
</style>
