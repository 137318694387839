<!-- 票据详情 -->
<template>
    <div class="bill-details adjust">
        <div class="upload-box">
          <steps :stepList="stepList" :current="current"></steps>
            <slot></slot>
            <el-row :gutter="30">
                <el-col :span="12">
                    <div class="upload-box-item">
                        <div class="upload-box-item-header">
                            <div>
                                <span>票据正面</span>
                            </div>
                            <div>
                                <el-checkbox v-model="detailsList.hideStatus" disabled>隐藏收款人</el-checkbox>
                            </div>
                        </div>
                        <div class="upload-box-item-cont">
                            <img :src="detailsList.billFileFront" :data-img="detailsList.billFileFront" @click="$imgPreview" class="upload-img">
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="upload-box-item">
                        <div class="upload-box-item-header">
                            <div>
                                <span>票据背面</span>
                            </div>
                        </div>
                        <div class="upload-box-item-cont">
                            <img :src="detailsList.billFileBack" :data-img="detailsList.billFileBack" @click="$imgPreview" class="upload-img">
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="bill-form-box">
            <div class="bill-title">票据信息</div>
            <el-row :gutter="20">
                <el-col :span="8">
                    <span class="label">承兑人：</span>
                    <span class="value">{{detailsList.acceptor}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">票面金额(元)：</span>
                    <span class="value">{{$changeMoneyThousand(detailsList.billAmount)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">大写：</span>
                    <span class="value">{{detailsList.billAmountCpt}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">票据号码：</span>
                    <span class="value">{{detailsList.billNum}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">到期日：</span>
                    <span class="value">{{moment(detailsList.expireTime).format('YYYY-MM-DD')}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">背书次数：</span>
                    <span class="value">{{detailsList.endorseNum}}</span>
                </el-col>
           
                <el-col :span="8">
                    <span class="label">期望卖出价：</span>
                    <div class="value">
                        <span>{{detailsList.sellRateStr}}%</span>
                        <span class="">~</span>
                        <span>{{detailsList.sellRateEnd}}%</span>
                    </div>
                </el-col>
                <el-col :span="8">
                    <span class="label">每十万扣费：</span>
                    <div class="value">
                        <span>{{$changeMoneyThousand(detailsList.deductionStr)}}元</span>
                        <span >~</span>
                        <span>{{$changeMoneyThousand(detailsList.deductionEnd)}}元</span>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                     <el-col :span="24">
                    <span class="label">票据瑕疵：</span>
                  
                    <ul class="value flaw-list">
                      

                        <li v-for="(item,index) in detailsList.billFlaw" :key="index">
                            <span v-if="index === detailsList.billFlaw.length-1">{{item}}</span>
                            <span v-else>{{item}}，</span>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <billpricebjinfo v-show='$route.query.isshowback==1' v-on:aaa='aaa'></billpricebjinfo>
        </div>
        <!-- 新增合同信息 -->
        <section class="info-card-A">

          <div class="card-number">
              <div class="bill-title" style="margin-top: 30px;">合同信息</div>
            <div class="httotal" style="margin-top:20px;">
              已添加合同
              <span>{{financInfo.list&&financInfo.list.length}}</span> 笔，合同总计金额
              <span>{{$changeMoneyThousand(detailsList.contractAmount)}}</span> 元。
            </div>
            <div
              class="maincontin"
              v-for="(item,index) in financInfo.list"
              :key="index"
            >
              <div :class="{'collapse-active': item.isCollapse}">
                <span class="mainht">
                  <i
                    aria-hidden="true"
                    :class="item.isCollapse ? 'fa fa-plus-circle' : 'fa fa-minus-circle'"
                    @click="item.isCollapse = !item.isCollapse"
                  ></i>
                  <span class="info-key">合同编号:</span>
                  <span class="info-value">{{item.contractNo}}</span>
                  <a
                    class="page-head-back downsize"
                    size="small"
                      @click="downloadfn(item)"
                  >
                    <i class="el-icon-download"></i>
                    <span>一键下载</span>
                  </a>
                </span>

                <div class="htmsg1">
                  <el-row>
                    <el-col :span="8" class="lb_body">
                      <span class="info-key">合同编号:</span>
                      <span class="info-value">{{item.contractNo}}</span>
                    </el-col>
                    <el-col :span="8" class="lb_body">
                      <span class="info-key">合同金额:</span>
                      <span class="info-value">{{$changeMoneyThousand(item.contractAmount)}}元</span>
                    </el-col>
                    <el-col :span="8" class="lb_body">
                      <span class="info-key">合同签署时间:</span>
                      <span class="info-value">{{moment(item.contractTime).format('YYYY-MM-DD')}}</span>
                    </el-col>
                  </el-row>
                </div>
                <div class="card-hd sub-title">相关合同</div>
                <div class="card-bd marrimg" style="padding-left:30px;padding-right: 30px;">
                  <transition
                    name="fade"
                    v-if="item.billContractList&&item.billContractList.length&&item.billContractList.length>0"
                  >
                    <el-row>
                      <picfile :picfilelist='item.billContractList'></picfile>
                    </el-row>
                  </transition>
                  <div>
                    <div>
                      共有文件:
                      <span class="numcolor">{{item.billContractList.length}}</span> 个
                    </div>
                  </div>
                </div>
                <div class="card-hd sub-title">相关发票</div>
                <div class="card-bd marrimg" style="padding:24px 30px 51px 30px;">
                  <el-row>
                    
                    <picfile :picfilelist='item.billInvoiceList'></picfile>

                  </el-row>
                  <div style="padding: 0px 0px 30px 0px ;">
                    共有文件:
                    <span class="numcolor">{{item.billInvoiceList.length}}</span> 个
                    <span style="margin-left:32px;">累计发票金额：</span>
                    <span class="numcolor">{{$changeMoneyThousand(item.invoiceAllAmount)}}</span> 元
                  </div>
                  <div>
                    <el-table
                      :data="item.billInvoiceList"
                      highlight-current-row
                      v-loading="listLoading"
                      style="width: 100%;"
                    >
                      <el-table-column type="index" label="序号" width="80"></el-table-column>
                      <el-table-column prop="invoiceBuyer" label="购买方" min-width="200">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceBuyerChange==2?'改':''"
                          >{{scope.row.invoiceBuyer}}</el-badge>
                        </template>
                      </el-table-column>

                      <el-table-column prop="invoiceSeller" label="销售方" min-width="200">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceSellerChange==2?'改':''"
                          >{{scope.row.invoiceSeller}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column prop="invoiceCode" label="发票代码" min-width="120">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceCodeChange==2?'改':''"
                          >{{scope.row.invoiceCode}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column prop="invoiceNo" label="发票号码" min-width="120">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceNoChange==2?'改':''"
                          >{{scope.row.invoiceNo}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column prop="invoiceCheckCode" label="校验码" min-width="120">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceCheckCodeChange==2?'改':''"
                          >{{scope.row.invoiceCheckCode}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column prop="invoiceOpenTime" label="开票日期" min-width="120">
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceOpenTimeChange==2?'改':''"
                          >{{scope.row.invoiceOpenTime}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="invoiceAmount"
                        label="开具金额(元)"
                        width="200"
                        align="left"
                      >
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceAmountChange==2?'改':''"
                          >{{$changeMoneyThousand(scope.row.invoiceAmount)}}</el-badge>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="invoiceAmountWithoutTax"
                        label="开具金额(不含税)"
                        width="200"
                        align="left"
                      >
                        <template slot-scope="scope">
                          <el-badge
                            class="isdot"
                            :value="scope.row.invoiceAmountWithoutTaxChange==2?'改':''"
                          >{{$changeMoneyThousand(scope.row.invoiceAmountWithoutTax)}}</el-badge>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
    </div>
</template>
<script>
  import moment from 'moment'
  import billpricebjinfo from '@/views/pjbase/billpricebjinfo' 
  import steps from "@/views/base/steps.vue";
  import api from "@/api/api"
  import   picfile  from '@/components/picfile';


function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
export default {
    components: {
    billpricebjinfo,
    steps,
    picfile,
  },
  props: {
    detailsList: {
      type: Object,
      default() {
        return {
          hideStatus : 1, 
          billFileFront: '',
          billFileBack: '',
          billFiles: '',
          acceptor: '',
          billAmount: '',
          billAmountCpt: '',
          billNum: '',
          expireTime: '',
          endorseNum: '',
          billFlaw: [],
          sellRateStr: '',
          sellRateEnd: '',
          deductionStr: '',
          deductionEnd: '',
          contractAmount: ''
        }
      }
    }
  },
  watch:{
    detailsList: {
      immediate: true, // 很重要！！！
      handler (val) {
        if(this.detailsList.billId){
          this.getcurrent()
        }
      }
    }
  },
  data() {
    return {
      stepList: [
        {
        name: "票据发布",
        realName: "",
        createTime: ""
        },
        {
        name: "票据内审",
        realName: "",
        createTime: ""
        },
        {
        name: "平台撮合",
        realName: "",
        createTime: ""
        },
            {
        name: "机构报价",
        realName: "",
        createTime: ""
        },
        {
        name: "选择报价",
        realName: "",
        createTime: ""
        },
        {
        name: "等待签约",
        realName: "",
        createTime: ""
        },
        {
        name: "签约完成",
        realName: "",
        createTime: ""
        },
      ],
      current:1,
      pmsg:{},

      api: api,
      listLoading: false,
      financInfo: {},
      // financnum: {},
      // financeFile: {},
    }
  },
  created() {
    this.getInfo({ billId: this.$route.query.id });
  },
  methods: {
    moment: moment,
    aaa(val){
      this.$emit('pmsg',val)
      console.log(val,'ssd')
    },
    getcurrent(){
        
      if(this.detailsList.offlineStauts == 2){
          this.current=-1;
          return
      }

      if(this.detailsList.quoteStatus == 2){
          this.current=7             
      //签约完成
      }else if(this.detailsList.quoteStatus == 1 && this.detailsList.offerStatus == 2){
          this.current=5

      //等待签约
      }else if(this.detailsList.fundQuoteStatus == 2){
          this.current=4

      //选择保价
      }else if(this.detailsList.billApproveStatus == 47){
          this.current=3

      //机构报价
      }else if(this.detailsList.billApproveStatus > 23){
          this.current=2
      //平台撮合
      }else if(this.detailsList.billApproveStatus <= 23){
          this.current=1
      //票据内审
      }else if(billApproveStatus = 20){
          this.current=1

      //发布票据
      }
                
    },

    
    //获取合同发票详情
    getInfo(param) {
      api.supplementDetail(param).then(res => {
        if ((res.code = "100000")) {
          console.log(res, '-----------res')
          this.financInfo = res.datas;
          // this.financnum = res.datas.grantCredit;
          // this.financeFile = res.datas.financeFile;
          console.log(this.financInfo.list.length, '----------financInfo.list')
          if (this.financInfo.list.length > 0) {
            this.financInfo.list.forEach(el => {
              this.$set(el, "isCollapse", false);
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    downloadfn(item){
      api.downLoadFile({
        billId: item.billId,
        billContractId: item.billContractId,
        fileName: item.contractName
      }).then(res => {
            openDownloadDialog(res,item.contractName+'.zip')
      }).catch((err) => {})
    },
  }
}
</script>
<style lang="scss">
    .bill-details {
        position: relative;
        background: #fff;
        padding: 33px 25px;
        
        .upload-box {
            .el-steps--horizontal{
                        padding: 0;
                }
            margin-bottom: 20px;
            &-item {
                border: 1px solid #eee;
                background: #fff;
                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    padding: 0 20px;
                    background: #eee;
                }
                &-cont {
                    position: relative;
                    height: 300px;
                    overflow: auto;
                    .upload-img {
                        width: 100%;
                            object-fit:contain;
                            height: 100%;
                    }
                }
            }
        }
        .label {
            display: inline-block;
            width: 130px;
            height: 40px;
            line-height: 40px;
           
            margin-right: 20px;
        }
        .value {
            display: inline-block;
            height: 40px;
            line-height: 40px;
        }
        .flaw-list {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
            }
        }
        .connect-line {
            padding: 0 20px;
        }
    }

// 合同发票，发票列表小圆圈改的样式
.adjust {
  .isdot {
    .el-badge__content.is-fixed {
      top: 10px;
      right: 0px;
    }
    .el-badge__content {
      background-color: #cfcfcf;
    }
  }
}
</style>
<style lang="scss" scoped>
// 合同发票

  // .info-card-A {
  //   padding: 0 32px;
  //   height: calc(100vh - 203px);
  //   overflow-y: auto;
  // }
  .page-head-back {
    color: #2937a6;
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    i {
      font-size: 14px;
    }
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .sub-title {
      border-bottom: none;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: 700;
      color: #262626;
  }
  .card-number {
    position: relative;
    .maincontin {
      margin-top: 30px;
      border: 1px solid #E6E6E6;
      margin-bottom: 20px;
      border-radius: 4px;
        .mainht {
        color: #262626;
        font-size: 16px;
        font-weight: bold;
        text-indent: 32px;
        display: inline-block;
        border-bottom: 1px solid #E6E6E6;
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f1f1f1;
      }
      .numcolor {
        font-size: 18px;
        color: #f5212d;
      }
    }
  }
</style>