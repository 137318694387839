<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList" style="padding: 10px 24px;">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist">
        <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row v-loading="listLoading" style="width: 100%;">
          <el-table-column prop="checkAccoutDateCn" label="对账单月份">
            <template slot-scope="scope">
              {{getMonth(scope.row.checkAccoutDateCn)}}
            </template>
          </el-table-column >
          <el-table-column prop="corpAckStatus" label="确认状态" :formatter="statusfn">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="viewDetails(scope.row)">查看详情</el-button>
              <!-- corpAckStatus===3 账单有误才显示以下按钮 -->
              <el-button v-if="scope.row.corpAckStatus===3" size="small" type="text" @click="viewDetails(scope.row)">状态变更</el-button>
            </template>
          </el-table-column>
        </el-table>

      </data-list>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";

export default {
  data() {
    return {
      breadcrumblist: ["对账单"],
      isShowAll: false,
      isHedden: false,
      filterList: [
         {
          label: "对账单月份: ",
          type: "datemonthrange",
          field: ["checkAccountStartDate","checkAccountEndDate"],
          size: "small"
        },
         {
            label: "确认状态: ",
            type: "select",
            field: "corpAckStatus",
            size: "small",
            options:[{label:'未确认',value:1},{label:'已确认',value:2},{label:'账单有误',value:3}]
        },
       
      ],
      api: api,
      total: 0,
      listLoading: false,
      // listData: [{checkAccoutDateCn: '2020年03月06日',corpAckStatus: 1}],
      listData: [],
      pageNo: 1,
      pageSize: 10,
      filters: {}
    };
  },
  created() {},
  methods: {
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters)
    },
    //查询
    getLists(param) {
      api.getCheckAccountListPage(param).then(res => {
        console.log(res, 'list')
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    statusfn(val){
      let state=''
      switch (val.corpAckStatus) {
        case 2:
          state='已确认'
          break;
        case 3:
        state='账单有误'
          break;
        default: 
          state="未确认"
          break;
      }
      return state
    },
     viewDetails(row) {
      let statementData = {
        checkAccoutDateCn: row.checkAccoutDateCn,
        corpAckStatus: row.corpAckStatus,
        payableBillCheckAccountId: row.payableBillCheckAccountId,
        accountCorpType: row.accountCorpType,
        corpName: row.corpName,
      }
      sessionStorage.setItem('statementData', JSON.stringify(statementData))
      this.$router.push({
        name: "对账单详情",
        // query: { 
        //   checkAccoutDateCn: row.checkAccoutDateCn,
        //   corpAckStatus: row.corpAckStatus,
        //   payableBillCheckAccountId: row.payableBillCheckAccountId
        //   }
      });
    },
    getMonth(month) {
      return month.slice(0, 7)
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";

.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
