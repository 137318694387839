<template>
  <div class="common-page-dataList">
      <el-tabs type="border-card"  class="n-tabs">
                  <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >

        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="originalName" label="开单企业" min-width="200"></el-table-column>
					<el-table-column prop="payableBillAmount" label="优企通金额(元)" min-width="200" :formatter='el=>$changeMoneyThousand(el.payableBillAmount)'></el-table-column>
					<!-- <el-table-column prop="fundYearInterest" label="资金年化利率(%)" min-width="170" ></el-table-column> -->
          <el-table-column prop="promisePayTime" label="承诺付款日" min-width="150"  :formatter="el=>moment(el.promisePayTime).format('YYYY-MM-DD')"/>
					<el-table-column prop="payableBillClearAmount" label="需清分金额(元)" min-width="170" :formatter='el=>$changeMoneyThousand(el.payableBillClearAmount)'></el-table-column>
					<el-table-column prop="numbers" label="流转节点（个）" min-width="150"></el-table-column>
					<el-table-column prop="" label="同步审批状态" min-width="150">
            <template slot-scope="scope">
              {{ getApproveStatus(scope.row.payableBillApproveStatus, scope.row.payableBillApproveStatusPlat, scope.row.payableBillApproveStatusFund)}}
            </template>
          </el-table-column>
          <el-table-column prop="isOverdue" label="是否逾期" min-width="110"></el-table-column>
          <el-table-column label="操作" width="120" >
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">审批</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
      </el-tabs>
  </div>
</template>
<script>
  import api from "@/api/api";
  import moment from 'moment'
  import {changeMoneyThousand} from '@/util/common'

export default {
  data() {
    return {
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      moment:moment,
      changeMoneyThousand:changeMoneyThousand,
      filterList: [
        // {
        //   label: "优企通编号: ",
        //   type: "input",
        //   size: "small",
        //   field: "payableBillNo",
        // },
        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["startPromisePayTime", "endPromisePayTime"],
          size: "small"
        },
        {
          label: "优企通金额(元): ",
          type: "inputrange",
          field: "startPayableBillAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "endPayableBillAmount",
          size: "small"
        },
      ]
    };
  },
  created() {
      //  console.log(this.message,8878)

  },
  methods: {

      headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
   handleRefresh(filters) {
            this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.type = 1 
      param.typeStr = 1  // 1核心审批 2平台审批 3资方审批
      param.openCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      api.getPayableBillLiquidationListZj(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(data){
      let status // 同步审批状态
      if(data.payableBillApproveStatus == 22 || data.payableBillApproveStatusPlat == 22 || data.payableBillApproveStatusFund == 22 ) {
        status = 2  // 提出异议
      } else {
        status = 1  // 待审批/确认无误
      }
      this.$router.push({name:'逐级清分审批详情',query: { id:data.payableBillNo,isshowbtn:1,payableBillLiquidationId:data.payableBillLiquidationId, approveStatus: data.payableBillApproveStatus, payableBillId: data.payableBillId, status}})
    },
    // 同步审批状态
    getApproveStatus(status, platStatus, fundStatus) {
      if(status == 22 || platStatus == 22 || fundStatus == 22) {
        return '提出异议' 
      } else if(status == 20 || platStatus == 20 || fundStatus == 20) {
        return '待审批' 
      } else {
        return '确认无误'
      }
    }
  }
};
</script>

<style lang="scss">

.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
