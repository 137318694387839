<template>
   <div class="billinfopricezi">
       <div class="clearfix">
            <div class="left pic">
                <div class="titlemsg">
                    系统已经按照票方要求使用技术手段隐去收款人
                </div>
                <div class="picfont">
                    <img :src="detailsList.billFileFront" alt="" width="490" height="310">
                </div>
                 <div>
                    <img :src="detailsList.billFileBack" alt="" width="490" height="310">
                </div>
            </div>
            <div class="left billinfo">
                <div class="billinfotop">           
                    <div class="rtitlebill">
                    票面信息
                        </div>
                        <p>
                            <span>承兑人: </span> <i>{{detailsList.acceptor}}</i>
                        </p>
                        <p>
                            <span>票据金额: </span> <i>{{$changeMoneyThousand(detailsList.billAmount)}}</i>
                        </p>
                        <p>
                            <span>大写: </span> <i>{{convertCurrency(detailsList.billAmount)}}</i>
                        </p>
                        <p>
                            <span>到期日: </span> <i>{{detailsList.expireTime|formatDate}}</i><em>剩余{{detailsList.betweenDays}}天</em>
                        </p>
                            <p>
                            <span>票据瑕疵: </span> <i>{{detailsList.billFlaw}}</i>
                        </p>
                            <p>
                            <span>背书次数: </span> <i>{{detailsList.endorseNum}}</i>
                        </p>
                   </div>
                   <div class="billinfobottom">
                        <div class="titlemsg">
                    最高出价：年化利率 <span>{{detailsList.sellRateEnd}}%</span><i>（{{detailsList.deductionEnd}}元/十万)</i>
                </div>
                        <div class="rtitlebill">
                            出价
                        </div>
                        <el-form ref="formchild" :model="offerprice" label-width="126px" :rules='rules'>
                            <el-form-item label="按年化利率(%): " prop='offerPriceYearInterest'>
                                <el-input v-model="offerprice.offerPriceYearInterest" size='mini' @blur="changprice"></el-input>
                            </el-form-item>
                             <el-form-item label="按每十万扣费(元): ">
                                <el-input v-model="offerprice.chargeAmount" size='mini' readonly></el-input>
                            </el-form-item>
                              <el-form-item label="平台服务费(元): ">
                                <el-input v-model="pricetype.fwf" size='mini' readonly></el-input>
                            </el-form-item>
                              <el-form-item label="平台杂费(元): ">
                                <el-input v-model="pricetype.zf" size='mini' readonly></el-input>
                            </el-form-item>
                             <el-form-item label="总计扣费(元): ">
                                <el-input v-model="offerprice.allChargeAmount" size='mini' readonly></el-input>
                            </el-form-item>
                             <el-form-item label="成交金额(元): ">
                                <el-input v-model="offerprice.dealAmount" size='mini' readonly></el-input>
                            </el-form-item>
                              <el-form-item label="确认报价时限: ">
                                     <el-select
                                    v-model="offerprice.quoteTimeLimit"
                                    placeholder="请选择确认时限"
                                    size='mini'
                                    >
                                    <el-option label="自审批完毕24小时" value="1"></el-option>
                                    <el-option label="自审批完毕48小时" value="2"></el-option>
                                    <el-option label="自审批完毕72小时" value="3"></el-option>
                                    <el-option label="无时限" value="4"></el-option>
                                    </el-select>
                
                            </el-form-item>
                       </el-form>
                   </div>
            </div>
       </div>
   </div>
  
</template>
<script>
    import api from "@/api/api.js";
import {convertCurrency} from '@/util/common.js'


    export default {
        components: {
     
        },
        props:{
           billid:Number
        },
        data() {
            return {
                    rules: {
                        offerPriceYearInterest: [
                            { required: true, message: '请输入年化利率', trigger: 'blur' },
                            { pattern: /^\d{1,12}(\.\d{1,2})?$/, message: '请输入12位以内的数字,小数点后最多2位' },
                            
                        ]
                    },
                    detailsList:{},
                    convertCurrency:convertCurrency,
                  offerprice:{
                   billId:this.billid,
                   billNo:'',
                   offerPriceYearInterest:'',//年华利率
                   chargeAmount:'',//每10万元扣
                   allChargeAmount:'',//总计扣费,
                   dealAmount:'',//成交金额
                   quoteTimeLimit:'1',//确认时限
                   hangCorpId:'',//挂牌企业id
                   hangCorpName:'',//挂牌企业名称
                   remainDays:'',//剩余天数
                   billAmount:'',//票据金额
                  },
                  pricetype:{
                      fwf:0,
                      zf:0

                  }

            }
        },
        mounted() {
                this.getinfo()
          
        },
        methods: {
                getinfo(){

                    api.BillDetails({billId: this.billid}).then(res => {
                    sessionStorage.setItem("contractAmount",  JSON.stringify(res.datas.billDto.contractAmount))
                    this.detailsList = res.datas.billDto
                    this.offerprice.hangCorpId=this.detailsList.listingCorpId
                    this.offerprice.hangCorpName=this.detailsList.listingCorpName
                    this.offerprice.billNo=this.detailsList.billNo
                    this.offerprice.remainDays=this.detailsList.betweenDays
                    this.offerprice.billAmount=this.detailsList.billAmount
                          this.getProductFee()
                  //  this.approvalForm.approveNode = this.detailsList.billApproveStatus
                    this.detailsList.billFile.forEach((item,index) => {
                        if(item.fileType == 2) {
                            this.detailsList.billFileBack = api.getImgURL(item.fileUrl)
                        }else {
                            this.detailsList.billFileFront = api.getImgURL(item.fileUrl)
                        }
                    })
                    // this.detailsList.billFlaw = this.detailsList.billFlaw.split(',');
                    // this.detailsList.hideStatus == 1?this.detailsList.hideStatus=false:this.detailsList.hideStatus=true
                }) 
                    // this.subform.name=this.form.name
                    //  this.getlist(this.subform)

                },
                changprice(){
                   
                    //票面贴现利息
                 //  var txlx=+(+this.offerprice.offerPriceYearInterest).div(36000).mul(+this.detailsList.betweenDays,+this.detailsList.billAmount).toFixed(2) 
                    //每十万元扣分
                //    this.offerprice.chargeAmount=txlx.div(+this.detailsList.billAmount.div(100000)).toFixed(2)
                    var txlx=+(+this.offerprice.offerPriceYearInterest).div(36000).mul(+this.detailsList.betweenDays,+this.detailsList.billAmount)
                    this.offerprice.chargeAmount=txlx.div(+this.detailsList.billAmount.div(100000)).toFixed(2)
                    // this.offerprice.allChargeAmount=(+this.pricetype.zf).adds(+this.pricetype.fwf,+txlx)
                    // this.offerprice.dealAmount=+this.offerprice.billAmount.sub(this.offerprice.allChargeAmount)
                       api.echoAmount({offerPriceYearInterest:this.offerprice.offerPriceYearInterest,billAmount:this.detailsList.billAmount,remainDays:this.detailsList.betweenDays}).then(res=> {
                         var data=res.datas.data
                         this.offerprice.dealAmount=this.$changeMoneyThousand(data.dealAmount)
                         this.offerprice.allChargeAmount=this.$changeMoneyThousand(data.allChargeAmount)       
                    }) 
                },
                getProductFee(){
                     api.getProductFee().then(res => {
                         console.log(res,'res')
                            this.pricetype.fwf=this.$changeMoneyThousand(res.datas.list.filter(el=>el.feeType==1)[0].productCost)
                            this.pricetype.zf=this.$changeMoneyThousand(res.datas.list.filter(el=>el.feeType==2)[0].productCost)

           
                    }) 
                    
                }

        
        }
    };
</script>


<style lang="scss">
.billinfoprice{
    .el-dialog__body{
        padding: 0;
    }
}
.billinfopricezi{
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
            height: 760px;
            overflow: hidden;
        
    .pic{
        width: 538px;
        background:rgba(245,245,245,1);
        padding: 24px;
        .titlemsg{
            height:38px;
            background:rgba(235,244,255,1);
            border-radius:4px;
            border:1px solid rgba(204,224,255,1);
            font-size:14px;
            font-weight:400;
            color:rgba(0,0,0,0.65);
            line-height:38px;
            text-align: center;

        }
        .picfont{
            margin: 9px 0;
        }
    }
    .billinfo{
        width: 422px;
        padding: 24px;
        p{
            span{
                display:inline-block;
                width: 70px;
                margin-right: 8px;
                font-weight:400;
                color:rgba(89,89,89,1);
            }
            i{
                font-style: normal;
                font-size:14px;
                font-weight:400;
                color:rgba(38,38,38,1);

            }
            em{
                font-style: normal;
                color:#2937A6;
            }
        }
        .billinfotop{
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(0,0,0,0.09);
        }
        .billinfobottom{
            margin-top: 16px;
               .titlemsg{
            height:38px;
            background:rgba(235,244,255,1);
            border-radius:4px;
            border:1px solid rgba(204,224,255,1);
            font-size:14px;
            font-weight:400;
            color:rgb(0,0,0);
            line-height:38px;
            text-align: center;
                span{
                    color: #F49831;
                }
                i{
                    color: #2937A6;
                    font-style: normal;

                }

            }
          .el-form-item__label{
              color:rgba(0,0,0,0.95)
              
          }
          .el-form-item{
              margin-bottom: 16px;
              height: 32px;
          }
          .el-form-item__error{
              top:94%;
          }
        }
    }
    .rtitlebill{
        font-size:16px;
        font-weight:500;
        color:rgba(0,0,0,0.85);
        line-height: 38px;
        height: 38px;

    }
}
</style>
