<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="corpName" label="接收企业" min-width="170"></el-table-column>
          <el-table-column label="转让金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.assignAmount) }}</template>
          </el-table-column>
          <el-table-column prop="promisePayTime" label="承诺付款日" min-width="130">
            <template slot-scope="scope">{{ scope.row.promisePayTime | formatDate }}</template>
          </el-table-column>
          <el-table-column label="转让日期" min-width="120">
            <!--<template slot-scope="scope">{{ scope.row.assignTime | formatHour }}</template>-->
            <template slot-scope="scope">
              {{scope.row.assignTime ? moment(scope.row.assignTime).format('YYYY-MM-DD HH:mm'): ''}}
            </template>
          </el-table-column>
          <!--<el-table-column prop="status" label="审批状态" width="100" align="left"></el-table-column>-->
          <el-table-column label="操作" width="150" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "../../api/api.js";
import pdf from "vue-pdf";
import { changeMoneyThousand } from "@/util/common";
import moment from 'moment'

export default {
  components: {
    pdf
  },
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "优企通编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
         {
          label: "接收企业",
          type: "input",
          field: "corpName",
          size: "small"
        },
        // {
        //   label: "审批状态:",
        //   type: "select",
        //   field: "payableBillApproveStatus",
        //   options: [
        //     { label: "待内部复核", value: "1" },
        //     { label: "待平台审核", value: "2" },
        //     { label: "待资方审核", value: "3" },
        //     { label: "审批完成", value: "4" },
        //     { label: "全部", value: "5" }
        //   ],
        //   size: "small"
        // },
        {
          label: "转让日期:",
          type: "daterange",
          field: ["assignTimeStr", "assignTimeEnd "],
          size: "small"
        },
        {
          label: "转让金额区间(元): ",
          type: "inputrange",
          field: "assignAmountStr",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "assignAmountEnd",
          size: "small"
        },
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      centerDialogVisible: false,
      api: api,
      row: {},
      moment: moment,
      changeMoneyThousand:changeMoneyThousand,
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "" // pdf文件地址
    };
  },
  created() {},
  methods: {
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },

    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.corpId = "1";
      api.AssignList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {

     if(row.payableBillNo.indexOf('-')!=-1){
                this.$router.push({
        name: "优企通流转子单详情",
        query: { id: row.payableBillId, isshowbtn: 2,flag:true }
      });
     }else{
          this.$router.push({
        name: "优企通流转详情",
        query: { id: row.payableBillId, isshowbtn: 2,flag:true}
      });
     }

    },
    preview(row) {
      this.row = row;
      window.open(api.getImgURL(row.evidencePath))
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   var binaryData = [];
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
      //   console.log("this.src ===>", this.src);
        // this.scr = pdf.createLoadingTask(this.src);
        // window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(url) + '&.pdf');

        // console.log('res ===>',res);
        // this.src = res;
        // this.centerDialogVisible = true;
        // this.listLoading = false;
      // });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
