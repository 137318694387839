<template>
  <div class="content easypay-manager">
    <div class="content-inner">
      <section class="info-card-A" style="height: calc(100vh - 150px)">
        <steps :stepList="stepList" :current="current"></steps>

        <div class="card-number">
          <span class="card-number-title">优企通编号:</span>
          <span class="card-number-value">{{ financInfo.payableBillNo }}</span>
          <el-button
            class="card-number-btn"
            type="primary"
            size="small"
            icon="el-icon-zoom-in"
            @click="preview"
            >预览</el-button
          >
        </div>

        <div class="card-hd sub-title">
          <span class="txbst">基本信息</span>
        </div>
        <div class="card-bd card-bd-row">
          <el-row>
            <el-col :span="8" class="lb_body">
              <span class="info-key">开单企业:</span>
              <span class="info-value index">{{
                financInfo.openCorpName
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">接收企业:</span>
              <span class="info-value">{{ financInfo.receivedCorpName }}</span>
            </el-col>

            <el-col :span="8" class="lb_body">
              <span class="info-key">承诺付款日:</span>
              <span class="info-value">{{
                moment(financInfo.promisePayTime).format('YYYY-MM-DD')
              }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="lb_body">
              <span class="info-key">优企通金额(元):</span>
              <span class="info-value">{{
                $changeMoneyThousand(financInfo.payableAmount)
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">优企通金额(大写):</span>
              <span class="info-value">{{ financInfo.remark }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">开立日:</span>
              <span class="info-value">{{
                moment(financInfo.createTime).format('YYYY-MM-DD')
              }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="lb_body">
              <span class="info-key">承诺付款天数:</span>
              <span class="info-value">{{ financInfo.promiseDay }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">授信编号:</span>
              <span class="info-value">{{ financInfo.grantCreditNo }}</span>
            </el-col>
            <!--<el-col :span="8" class="lb_body">-->
            <!--<span class="info-key">授信起始日:</span>-->
            <!--<span class="info-value">{{moment(financInfo.grantCloseTime).format('YYYY-MM-DD')}}</span>-->
            <!--</el-col>-->
            <el-col :span="8" class="lb_body">
              <span class="info-key">授信截止日:</span>
              <span class="info-value">{{
                moment(financInfo.grantCloseTime).format('YYYY-MM-DD')
              }}</span>
            </el-col>
          </el-row>
          <!--<el-row>-->
          <!--<el-col :span="8" class="lb_body">-->
          <!--<span class="info-key">接收企业全称:</span>-->
          <!--<span class="info-value">{{financInfo.receivedCorpName}}</span>-->
          <!--</el-col>-->
          <!--<el-col :span="8" class="lb_body">-->
          <!--<span class="info-key">社会统一信用代码:</span>-->
          <!--<span class="info-value">{{financInfo.receivedCorpCreditCode}}</span>-->
          <!--</el-col>-->

          <!--</el-row>-->

          <el-row>
            <!-- <el-col :span="8" class="lb_body">
              <span class="info-key">资金年化利率:</span>
              <span class="info-value">{{financInfo.fundYearInterest}}%</span>
            </el-col> -->
            <el-col :span="8" class="lb_body">
              <span class="info-key">预估融资利率(%/年化):</span>
              <span class="info-value"
                >{{ financInfo.financeYearInterest }}%</span
              >
            </el-col>
          </el-row>
          <div class="card-line"></div>
        </div>
        <div class="card-hd sub-title">
          <span class="txbst">合同信息</span>
        </div>
        <div
          class="maincontin"
          v-for="(item, index) in financInfo.payableBillContract"
          :key="index"
        >
          <!-- <div class="maincontin">  -->

          <span class="mainht">
            <span class="info-key">合同编号:</span>
            <span class="info-value">{{ item.contractNo }}</span>
            <a
              class="page-head-back downsize"
              style="color: #2937a6"
              size="small"
              :href="api.filesdownLoadFile(item)"
              >一键下载</a
            >
          </span>
          <div class="card-hd sub-title" style="margin-bottom: 24px">
            相关合同
            <!-- <el-button
                        type="text"
                        class="page-head-back"
                        style="color: #2937A6;"
                        size="small"
                        @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div class="htmsg">
            <el-row>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同编号:</span>
                <span class="info-value">{{ item.contractNo }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同名称:</span>
                <span class="info-value">{{ item.contractName }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同金额:</span>
                <span class="info-value"
                  >{{ $changeMoneyThousand(item.contractAmount) }}元</span
                >
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同签署时间:</span>
                <span class="info-value">{{
                  item.contractTime
                    ? moment(item.contractTime).format('YYYY-MM-DD')
                    : '--'
                }}</span>
              </el-col>
            </el-row>
          </div>

          <div
            class="card-bd marrimg"
            style="padding-left: 30px; margin-top: -20px"
          >
            <transition
              name="fade"
              v-if="
                item.payableBillContractList &&
                item.payableBillContractList.length &&
                item.payableBillContractList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillContractList"></picfile>
              </el-row>
            </transition>
            <div>
              <div>
                共有文件:
                <span class="numcolor">{{
                  item.payableBillContractList.length
                }}</span>
                个
              </div>
            </div>
          </div>
          <div class="card-hd sub-title">
            相关发票
            <!-- <el-button
                        type="text"
                        class="page-head-back"
                        style="color: #2937A6;"
                        size="small"
                        @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <transition
              name="fade"
              v-if="
                item.payableBillInvoiceList &&
                item.payableBillInvoiceList.length &&
                item.payableBillInvoiceList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillInvoiceList"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="15%"-->
            <!--height="100px"-->
            <!--preview-text="发票"-->
            <!--:preview="index+'2'"-->
            <!--v-for="(items,key) in  item.payableBillInvoiceList"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div style="padding: 0px 0px 30px 0px">
              共有文件:
              <span class="numcolor">{{
                item.payableBillInvoiceList.length
              }}</span>
              个
              <span style="margin-left: 32px">累计发票金额：</span>
              <span class="numcolor">{{
                $changeMoneyThousand(item.invoiceAllAmount)
              }}</span>
              元
            </div>
            <div>
              <el-table
                :data="item.payableBillInvoiceList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                  prop="invoiceBuyer"
                  label="购买方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceBuyer }}</el-badge
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="invoiceSeller"
                  label="销售方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceSellerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceSeller }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCode"
                  label="发票代码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceNo"
                  label="发票号码"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceNo }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCheckCode"
                  label="校验码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCheckCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCheckCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceOpenTime"
                  label="开票日期"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceOpenTimeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceOpenTime }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  label="开具金额(元)"
                  width="150"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmount)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmountWithoutTax"
                  label="开具金额(不含税)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountWithoutTaxChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmountWithoutTax)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-hd sub-title">
            其他附件
            <!-- <el-button
                        type="text"
                        class="page-head-back"
                        style="color: #2937A6;"
                        size="small"
                        @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div
            class="card-bd marrimg"
            style="padding-bottom: 51px; padding-left: 30px"
          >
            <!--<span class="info-key">物流类型:</span>-->
            <transition
              name="fade"
              v-if="
                item.payableBillWuliuList &&
                item.payableBillWuliuList.length &&
                item.payableBillWuliuList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillWuliuList"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="19%"-->
            <!--height="144px"-->
            <!--preview-text="其他附件"-->
            <!--:preview="index"-->
            <!--v-for="(items,key) in  item.payableBillWuliuList"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <!--<span class="info-key">银行类型:</span>-->
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="10%"-->
            <!--height="100px"-->
            <!--preview-text="其他附件"-->
            <!--:preview="index"-->
            <!--v-for="(items,key) in  item.payableBillBankList"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div>
              共有文件:
              <span class="numcolor">{{
                item.payableBillWuliuList.length +
                item.payableBillBankList.length
              }}</span>
              个
            </div>
          </div>
        </div>
      </section>
      <div class="card-footer" v-if="$route.query.isshowbtn == 1">
        <el-button
          size="primary"
          @click="openrefusediag"
          class="card-btn"
          type="primary"
          >退 回</el-button
        >
        <el-button
          size="primary"
          @click="openagreediag"
          class="card-btn"
          type="primary"
          >通 过</el-button
        >
      </div>
      <div class="card-footer" v-if="$route.query.isshowbtn == 2">
        <el-button class="card-btn" type="primary" @click="goBack"
          >返回</el-button
        >
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="审核意见"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="agreedetail" label-width="60px" ref="addform">
        <el-form-item label="备注：" prop="content">
          <el-input
            v-model="agreedetail.content"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm" :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="拒绝原因"
      :visible.sync="showAddDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="refusedetail" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="denialReason">
          <el-select v-model="refusedetail.denialReason" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="content">
          <el-input
            v-model="refusedetail.content"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAddForm2('addform')"
          :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api/api';
import { baseURL } from '@/api/sys';
import pdf from 'vue-pdf';
import steps from '../../../views/base/steps.vue';
import picfile from '@/components/picfile';

Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
export default {
  components: {
    pdf,
    steps,
    picfile,
  },
  data() {
    return {
      pid: '', //pid
      state: '', //是否入库
      financInfo: {}, //基本信息
      isShowAll: false,
      isHedden: false,
      filterList: [],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      baseURL: baseURL,
      api: api,
      agreedetail: {
        approveType: 4,
        content: '',
        payableBillId: '',
        result: 1,
        status: '',
      },
      refusedetail: {
        approveType: 4,
        content: '',
        denialReason: '',
        payableBillId: '',
        result: 2,
        status: '',
      },
      showAddDialog: false,
      showAddDialog2: false,
      bclick: false,
      refuselist: [],
      centerDialogVisible: false,
      src: '',
      stepList: [
        {
          name: '邀请开单',
          realName: '',
          createTime: '',
        },
        {
          name: '核心企业确认',
          realName: '',
          createTime: '',
        },
        {
          name: '开立审核',
          realName: '',
          createTime: '',
        },
        {
          name: '担保审核',
          realName: '',
          createTime: '',
        },
        {
          name: '接收确认',
          realName: '',
          createTime: '',
        },
        {
          name: '平台审核',
          realName: '',
          createTime: '',
        },
        {
          name: '资方审核',
          realName: '',
          createTime: '',
        },
        {
          name: '正常持有',
          realName: '',
          createTime: '',
        },
      ],
      current: '',
    };
  },
  created() {
    console.log(this.$route, 123);
    this.current = 6;
    this.getInfo({ payableBillId: this.$route.query.id }); //获取基本信息
    this.getrefuselist(); //获取拒绝原因
  },
  mounted() {},
  methods: {
    moment: moment,
    //返回
    goBack() {
      //   this.$router.push({name:'开立审批'})
      window.history.go(-1);
    },
    getreceivedCorpAck(val) {
      //获取企业确认时限
      var timer = '';
      switch (val) {
        case '0':
          timer = '自审批完毕24小时';
          break;
        case '1':
          timer = '自审批完毕48小时';
          break;
        case '2':
          timer = '自审批完毕72小时';
          break;
        case '3':
          timer = '无时限';
          break;
      }
      return timer;
    },
    //获取详情
    getInfo(param) {
      api.createfirstdetail(param).then((res) => {
        if ((res.code = '100000')) {
          this.financInfo = res.datas.PayableBill;
          this.financnum = res.datas.grantCredit;
          this.financeFile = res.datas.financeFile;

          if (this.financInfo.payableBillOpenType == 1) {
            //判断担保 null无担保审核 否则有担保审核
            if (this.financInfo.guaranteeCorpId != null) {
              //判断是否资方审核 1 有资方 2  无资方
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '担保审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 7;
                }
              } else {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '担保审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              }
            } else {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 2;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              } else {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 2;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 3;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 5;
                }
              }
            }
          } else if (this.financInfo.payableBillOpenType == 2) {
            if (this.financInfo.guaranteeCorpId != null) {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '担保审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 4;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 5;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 6;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 8;
                }
              } else {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '担保审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 4;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 7;
                }
              }
            } else {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 7;
                }
              } else {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              }
            }
          } else {
            //转让
            if (this.financInfo.payableBillApproveStatus < 31) {
              this.current = 1;
            } else if (
              this.financInfo.payableBillApproveStatus >= 31 &&
              this.financInfo.payableBillApproveStatus < 99
            ) {
              this.current = 2;
            } else {
              this.current = 3;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    openagreediag() {
      this.showAddDialog = true;
      this.agreedetail.content = '';
      this.bclick = false;
    },
    getrefuselist() {
      api.getDicListByType({ type: 6 }).then((res) => {
        if (res.code == '100000') {
          this.refuselist = res.datas.list;
        }
      });
    },
    openrefusediag() {
      this.showAddDialog2 = true;
      this.refusedetail.content = '';
      this.refusedetail.denialReason = this.refuselist[0].name;
      this.bclick = false;
    },
    submitAddForm() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      var operatorTypeval = 7;
      switch (this.financInfo.payableBillApproveStatus) {
        case 45:
          operatorTypeval = 7;
          break;
        case 51:
          operatorTypeval = 8;
          break;
        case 53:
          operatorTypeval = 9;
          break;
      }
      var yfdMessageDto = {
        itemId: this.$route.query.id,
        itemType: 1,
        operatorAction: 1,
        operatorType: operatorTypeval,
        phone: user.phone,
      };

      api.sendPhoneMessage(yfdMessageDto).then((res) => {
        if (res.success) {
          let meessage =
            '提示信息：CFCA向您尾号为' +
            user.phone.slice(user.phone.length - 4) +
            '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
          this.$prompt(meessage, '请输入短信验证码', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(({ value }) => {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            });
            if (this.bclick) {
              return;
            }
            this.bclick = true;
            this.agreedetail.payableBillId = this.financInfo.payableBillId;
            this.agreedetail.status = this.financInfo.payableBillApproveStatus;
            yfdMessageDto.verifyCode = value;
            var params = Object.assign({ yfdMessageDto }, this.agreedetail);

            api
              .submitPayableBillResult(params)
              .then((res) => {
                loading.close();
                if (res.code == '100000') {
                  this.showAddDialog = false;
                  let message = '审批通过';
                  if (this.agreedetail.status == 45) {
                    message = '审批通过，请等待复审';
                  }
                  if (this.agreedetail.status == 51) {
                    message = '审批通过，请等待终审';
                  }
                  if (this.agreedetail.status == 53) {
                    message = '审批通过';
                  }
                  this.$alert(message, '', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                      history.go(-1);
                    },
                  });
                } else {
                  this.$message.error(res.message);
                  this.bclick = false;
                }
              })
              .catch((err) => {
                loading.close();

                this.bclick = false;
              });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitAddForm2() {
      // var user = JSON.parse(sessionStorage.getItem("user"));
      // var  operatorTypeval=7
      //     switch (this.financInfo.payableBillApproveStatus) {
      //       case 45:
      //         operatorTypeval=7
      //         break;
      //       case 51:
      //         operatorTypeval=8
      //         break;
      //       case 53:
      //         operatorTypeval=9
      //         break;
      //     }
      //              var yfdMessageDto={
      //                         itemId:this.$route.query.id,
      //                         itemType:1,
      //                         operatorAction:2,
      //                         operatorType:operatorTypeval,
      //                         phone:user.phone,

      //                   }

      //                 api.sendPhoneMessage(yfdMessageDto).then(res => {
      //                        if(res.success){
      //                                     let meessage =
      //   "提示信息：CFCA向您尾号为" +
      //   user.phone +
      //   "的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码";
      //             this.$prompt(meessage, "请输入短信验证码", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",closeOnClickModal:false}).then(({ value }) => {

      //                               yfdMessageDto.verifyCode=value
      //                              const loading = this.$loading({
      //                                   lock: true,
      //                                   text: "Loading",
      //                                   spinner: "el-icon-loading",
      //                                   background: "rgba(0, 0, 0, 0.7)"
      //                                 });
      if (this.bclick) {
        return;
      }
      this.bclick = true;
      this.refusedetail.payableBillId = this.financInfo.payableBillId;
      this.refusedetail.status = this.financInfo.payableBillApproveStatus;
      // var params=Object.assign({yfdMessageDto},this.refusedetail)
      api
        .submitPayableBillResult(this.refusedetail)
        .then((res) => {
          // loading.close();

          if (res.code == '100000') {
            let message = '操作成功, 退回重新修改';

            this.$alert(message, '', {
              confirmButtonText: '确定',
              callback: (action) => {
                history.go(-1);
              },
            });
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch((err) => {
          // loading.close();

          this.bclick = false;
        });
      // })
      //                                }else{
      //                                      this.$message.error(res.message)
      //                                }

      //           })
    },
    preview(row) {
      this.row = row;
      //   api
      //     .DownloadContract({ contractNo: this.financInfo.contractNo })
      //     .then(res => {
      //       var binaryData = [];
      //       binaryData.push(res);
      //       this.src = window.URL.createObjectURL(
      //         new Blob(binaryData, { type: "application/zip" })
      //       );
      //       this.centerDialogVisible = true;
      //     });
      window.open(api.getImgURL(this.financInfo.evidencePath));
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;

  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }

  .info-card-A {
    padding: 0 32px;
  }

  .card-bd-row {
    line-height: 24px;
  }

  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;

    .txbst {
      position: relative;
      left: -32px;
    }
  }

  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }

  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .maincontin {
    margin-top: 30px;

    .marrimg {
      img {
        margin-right: 1%;
      }
    }

    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }

    .htmsg {
      margin: 10px 0 10px 32px;
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }

    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }

      .el-badge__content {
        background-color: #cfcfcf;
      }
    }

    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
</style>
