<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row v-loading="listLoading" style="width: 100%;" >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="originalName" label="开单企业" min-width="200"></el-table-column>
          <el-table-column prop="fundCorpName" label="资金机构" min-width="120" />
					<el-table-column prop="payableBillAmount" label="优企通金额(元)" min-width="150" :formatter='el=>$changeMoneyThousand(el.payableBillAmount)'></el-table-column>
					<!-- <el-table-column prop="fundYearInterest" label="资金年化利率(%)" min-width="160"></el-table-column> -->
          <el-table-column prop="promisePayTime" label="承诺付款日" min-width="130" :formatter="el=>moment(el.promisePayTime).format('YYYY-MM-DD')"/>
					<el-table-column prop="payableBillFinanceAmount" label="已融资金额(元)" min-width="150" :formatter='el=>$changeMoneyThousand(el.payableBillFinanceAmount)'></el-table-column>
          <!-- <el-table-column prop="payableBillClearAmount" label="需清分金额(元)" min-width="150" :formatter='el=>$changeMoneyThousand(el.payableBillClearAmount)'></el-table-column> -->
          <el-table-column prop="overdueDays" label="逾期天数(天)" min-width="130" />
          <el-table-column prop="penaltyInterest" label="罚息利率(%)" min-width="130"/>
          <!-- <el-table-column prop="penalty" label="罚息金额(元)" min-width="130"  :formatter='el=>$changeMoneyThousand(el.penalty)'/> -->
          <el-table-column prop="settlementChannelStr" label="结算渠道" min-width="110"/>
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import { baseURL } from "@/api/sys";
import { changeMoneyThousand } from "@/util/common";
import moment from "moment";

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      changeMoneyThousand: changeMoneyThousand,
      filterList: [
        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["startPromisePayTime", "endPromisePayTime"],
          size: "small"
        },
        {
          label: "融资金额: ",
          type: "inputrange",
          field: "startPayableBillFinanceAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "endPayableBillFinanceAmount",
          size: "small"
        }
      ]
    };
  },
  created() {},
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.guaranteeCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      param.type = 2 // 1未清分 2已清分
      api.getGuaranteeOverdueZj(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEdit(row) {
      // isOverdue: 1 逾期
      this.$router.push({
        name: "清分结算资金机构详情",
        query: {
          payableBillNo: row.payableBillNo,
          payableBillId: row.payableBillId,
          isshowbtn: 2, 
          status: 1,
          isOverdue: 1,
          settlementChannel: row.settlementChannel
        }
      });
    },
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
