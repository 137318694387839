<template>
  <div class="wrap-linging">
    <div class="ling-wrap">
      <div class="searchBtn" v-if="showSearch">
        <img src='../../assets/lingCertification/success.png' alt />
        <p>人脸识别申请成功</p>
        <p>请等待认证结果</p>
        <div class="submitBtnCss" id="serachBtn" @click="searchResult">查询认证结果</div>
      </div>
      <div class="success" v-if="showSuccess">
        <img src='../../assets/lingCertification/success.png' alt />
        <p>人脸识别通过</p>
        <p>请等待平台认证审查</p>
        <div class="com_set_font">审核时间一般为1~3个工作日</div>
      </div>
      <div class="fail" v-if="showFail">
        <img src='../../assets/lingCertification/fail.png' alt />
        <p>人脸识别失败</p>
        <p>请重新认证</p>
        <div class="com_set_font">请重新扫描</div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
export default {
  data() {
    return {
      // corpId: '345',
      showSuccess: false,
      showFail: false,
      showSearch: true,
      //corpId: this.$route.query.corpId
      corpId: this.$route.query.corpId
    };
  },
  created() {},
  mounted() {
    console.log(this.$route.query.corpId)
  },
  methods: {
    searchResult() {
      api
        .getProductFeeFn(this.corpId)
        .then(res => {
          this.showSearch = false;
          if (res.success) {
            if (res.datas.data.registerIdentify === 1) {
              this.showSuccess = true;
            } else {
              this.showFail = true;
            }
          } else {
            this.showFail = true;
          }
          console.log(res);
        })
        .catch(e => {
          this.showSearch = false;
          this.showFail = true;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@function fun($width) {
  @return ($width/$rem) + rem;
}
$rem: 75px;
.wrap-linging {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  background-color: #ccc;
}
.submitBtnCss {
  display: inline-block;
  width: fun(300px);
  height: fun(65px);
  line-height: fun(65px);
  background-color: blueviolet;
  border-radius: fun(20px);
  color: #fff;
  margin-top: fun(100px);
  font-size: fun(30px);
}
.com_set_font {
   font-size: fun(30px);
}
.ling-wrap p {
  line-height: 1.5;
  font-size: fun(50px);
  font-weight: 600;
}
</style>