<template>
    <div>
        <layout-main-view
            :breadcrumb="breadcrumblist"
            :tabOptions="tabOptions"
            @tabclick="tabclick"
            >
            <fundAwaitReview v-if="showtab == 1" key="1"></fundAwaitReview>
            <fundAlreadyReview v-if="showtab == 2" key="2"></fundAlreadyReview>
        </layout-main-view>
    </div>
</template>

<script>
import fundAwaitReview from "./fundAwaitReview.vue";
import fundAlreadyReview from "./fundAlreadyReview.vue";
export default {
    components: {
        "fundAwaitReview": fundAwaitReview,
        'fundAlreadyReview':fundAlreadyReview,
    },
    data(){
        return{
            breadcrumblist: ["授信复核"],
            showtab: 1,
            tabOptions: [
                { label: "待审核", value: "1", path: "/fund/credit/review/1" },
                { label: "已审核", value: "2", path: "/fund/credit/review/2" },
            ],
            activeName: "first",
            total: 0,
            listLoading: false,
            listData: [],
            handleEditShow: false,
        }
    },
    created() {
    },
    mounted(){
        this.showtab = this.$route.params.state;
    },
    methods: {
        tabclick(val) {
            this.showtab = val;
            this.handleEditShow = false;
        },
    }
}
</script>

<style>

</style>