<template>
  <div>
    <layout-main-view
      :breadcrumb="['供应商额度管理']"
      :tabOptions="[
        { label: '待审批', value: '1', path: '/easypay/supplier-quota/1' },
        { label: '已审批', value: '2', path: '/easypay/supplier-quota/2' },
        { label: '已退回', value: '3', path: '/easypay/supplier-quota/3' },
      ]"
      @tabclick="tabclick"
    >
      <SupplierQuotaTable v-if="modelType" :type="modelType"></SupplierQuotaTable>
    </layout-main-view>
  </div>
</template>

<script>
import SupplierQuotaTable from "./supplier-quota-table.vue";
export default {
  components: { SupplierQuotaTable },
  data() {
    return {};
  },
  computed: {
    /**
     * 本来想直接用state但是用0 1 -1,菜单高亮失灵，没办法只能用123映射了
     */
    modelType() {
      const { state } = this.$route.params;
      if (state) {
        return {
          1: "0",
          2: "1",
          3: "-1",
        }[state];
      }
      return "";
    },
  },
  methods: {
    tabclick(val) {
      this.type = val;
    },
  },
};
</script>
