<template>
    <div class="common-page-dataList">
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                    :filters="filterList"
                    :isShowAll="isShowAll"
                    :isHedden="isHedden"
                    @data-showAll="filterShowAll"
                    @data-refresh="handleRefresh"
                    :total="total"
                    ref="datalist"
            >
                <el-table
                        :data="listData"
                        :header-cell-style="headerStyle"
                        highlight-current-row
                        v-loading="listLoading"
                        style="width: 100%;"
                >
                    <el-table-column prop="grantCreditNo" label="授信编号" min-width="150"></el-table-column>
                    <el-table-column prop="creditAgreementNo" label="授信协议编号" min-width="150"></el-table-column>
                    <el-table-column prop="fundCorpName" label="资金机构" min-width="150"></el-table-column>
                    <!--<el-table-column prop="corpNature" label="机构类型" min-width="120"></el-table-column>-->
                    <el-table-column label="授信总金额(元)" min-width="160">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.availableAmount) }}</template>
                    </el-table-column>
                    <el-table-column label="已用额度(元)" min-width="170">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.usedAmount) }}</template>
                    </el-table-column>
                    <el-table-column label="可用额度(元)" min-width="170">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.remainAmount)}}</template>
                    </el-table-column>
                    <el-table-column prop="creditDays" label="授信剩余期限(天)" min-width="160"></el-table-column>
                    <el-table-column label="授信起始日" min-width="130">
                        <template slot-scope="scope">{{ scope.row.effectiveTime | formatDate }}</template>
                    </el-table-column>
                    <el-table-column prop="closeDate" label="授信截止日" min-width="130">
                        <template slot-scope="scope">{{ scope.row.closeDate | formatDate }}</template>
                    </el-table-column>
                    <!-- <el-table-column prop="fundYearInterest" label="资金年化利率(％)" min-width="150"></el-table-column> -->
                    <el-table-column prop="financeYearInterest" label="融资年化利率(％)" min-width="150"></el-table-column>
                    <el-table-column label="操作" min-width="120" align="left" fixed="right">
                       <template slot-scope="scope">
                           <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
                       </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
    </div>
</template>
<script>
    import api from "../../api/api.js";
    import moment from 'moment'

    export default {
        message2() {
            return this.message.map(el => {
                return {label: el.corpName, value: el.corpId};
            });
        },
        data() {
            return {
                isShowAll: false,
                isHedden: false,
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                pageNo: 1,
                pageSize: 10,
                filterList: [
                    {
                        label: "授信编号: ",
                        type: "input",
                        field: "grantCreditNo",
                        size: "small"
                        // isSeparated: 1
                    },
                    {
                        label: "授信协议编号: ",
                        type: "input",
                        field: "creditAgreementNo",
                        size: "small"
                        // isSeparated: 1
                    },
                    {
                        label: "资金机构:",
                        type: "input",
                        field: "fundCorpName",
                        size: "small"
                    },
                    {
                        label: "授信期限:",
                        type: "daterange",
                        field: ["startTime", "endTime"],
                        size: "small"
                    },

                ]
            };
        },
        props: {
            isAvaliable: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            moment: moment,
            created() {
                api.getGrantCreditCorpList().then(res => {
                    if (res.code == 100000) {
                        this.message = res.datas.list;
                    } else {
                        this.$message.error(res.message);
                    }
                });
                this.getCorpList();
            },
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                this.getLists(filters);
            },
            //查询
            getLists(param) {
                console.log("param ====>", param);
                // param.corpId = '1';
                api.AvailableList(param).then(res => {
                    console.log("res ===>", res);
                    if (res.success) {
                        this.list = [];
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                        console.log("list ===>", this.listData);
                    }
                    this.listLoading = false;
                });
            },
            getCorpList() {
                api.getCorpList({type: 1}).then(res => {
                    if (res.success) {
                        this.filterList[0].options = res.datas.corp.map(val => ({
                            label: val.corpName,
                            value: val.corpId
                        }));
                    }
                });
            },
            //详情
    handleEdit(row) {
      this.$router.push({
        path: "/easypay/creditRecordDetail",
        query: { id: row.grantCreditId },
      });
    },
        }
    };
</script>

<style lang="scss">
    @import "../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
</style>
