<!-- 账款详情 -->
<template>
  <div class="absmaininfo assetDetail">
    <layout-main-view :breadcrumb='breadcrumblist'>
      <div class="common-page-dataList">
        <div class="easypayby bl">
          <div class="yfdtwo">
            <div class="yfdsize" style="font-weight:bold;">项目概况</div>
            <div class="formstyle">
              <el-form :model="baseInfo" ref="baseInfo" label-width="198px" class="demo-ruleForm keytext">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span class="info-key">项目名称:</span>
                    <span class="info-value index">{{baseInfo.absPlanName}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">项目简称: </span>
                    <span class="info-value index">{{baseInfo.absPlanAbbr}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">项目编号: </span>
                    <span class="info-value index">{{baseInfo.absPlanNo}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span class="info-key">资产支持证券总规模(元): </span>
                    <span class="info-value index">{{$changeMoneyThousand(baseInfo.preAssertScaleAmount)}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">时间期限: </span>
                    <span class="info-value index">{{baseInfo.collectStartDate ? moment(baseInfo.collectStartDate).format('YYYY-MM-DD'): ''}} - {{baseInfo.collectEndDate ? moment(baseInfo.collectEndDate).format('YYYY-MM-DD'): ''}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">原始权益人: </span>
                    <span class="info-value index">{{baseInfo.oriProfitPeople}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span class="info-key">优先级规模(元): </span>
                    <span class="info-value index">{{$changeMoneyThousand(baseInfo.priorityLevelScale)}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">次优级规模(元): </span>
                    <span class="info-value index">{{$changeMoneyThousand(baseInfo.subPriorityLevelScale)}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="info-key">劣后级规模(元): </span>
                    <span class="info-value index">{{$changeMoneyThousand(baseInfo.badPriorityLevelScale)}}</span>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
          <div class="yfdtwo" v-if="leaderCorpType == 1">
            <div class="yfdsize" style="font-weight:bold;">打包资产</div>
            <el-alert class="alert-box"
              :title="'资产包共 ' + number + ' 笔账款，账款总计金额 ' + $changeMoneyThousand(totalAmount) + ' 元。'"
              type="warning" :closable="false"
              show-icon>
            </el-alert>
            <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="packageNo" label="资产包编号" min-width="100">
              </el-table-column>
              <el-table-column prop="corpName" label="资产实际收集人" min-width="100">
              </el-table-column>
              <el-table-column prop="number" label="包含资产数量" min-width="100">
              </el-table-column>
              <el-table-column prop="amount" label="资产合计金额（元）" min-width="100">
                <template slot-scope="scope">
                  {{$changeMoneyThousand(scope.row.amount)}}
                </template>
              </el-table-column>
              <el-table-column prop="packageStatus" label="状态" min-width="100">
                <template slot-scope="scope">
                  {{getFactoringDisplace(scope.row.packageStatus)}}
                </template>
              </el-table-column>
              <el-table-column prop="count" label="置换资产数量" min-width="100">
              </el-table-column>
              <el-table-column label="操作" min-width="70">
                <template slot-scope="scope">
                  <el-button size="medium" type="text" @click="handleDetail(scope.row, scope.$index)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="yfdtwo" v-else>
            <div class="yfdsize" style="font-weight:bold;">账款信息</div>
            <el-alert class="alert-box"
              :title="'资产包共 ' + number1 + ' 笔账款，账款总计金额 ' + $changeMoneyThousand(totalAmount1) + ' 元。'"
              type="warning" :closable="false"
              show-icon>
            </el-alert>
            <el-table :data="listData1" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="absAccountNo" label="账款编号" min-width="100">
              </el-table-column>
              <el-table-column prop="debtCorpName" label="债务企业" min-width="100">
              </el-table-column>
              <el-table-column prop="claimsCorpName" label="债权企业" min-width="100">
              </el-table-column>
              <el-table-column prop="absAccountAmount" label="账款金额（元）" min-width="100">
                <template slot-scope="scope">
                  {{$changeMoneyThousand(scope.row.absAccountAmount)}}
                </template>
              </el-table-column>
              <el-table-column prop="absAccountSignDate" label="签署日期" min-width="100">
                <template slot-scope="scope">
                  {{scope.row.absAccountSignDate ? moment(scope.row.absAccountSignDate).format('YYYY-MM-DD'): ''}}
                </template>
              </el-table-column>
              <el-table-column prop="absAccountCloseDate" label="计划付款日" min-width="100">
                <template slot-scope="scope">
                  {{scope.row.absAccountCloseDate ? moment(scope.row.absAccountCloseDate).format('YYYY-MM-DD'): ''}}
                </template>
              </el-table-column>
              <el-table-column prop="factoringDisplace" label="账款状态" min-width="100">
                <template slot-scope="scope">
                  {{getFactoringDisplace(scope.row.factoringDisplace, scope.row.platformDisplace)}}
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="170">
                <template slot-scope="scope">
                  <el-button size="medium" type="text" v-if="scope.row.factoringDisplace == 1 && scope.row.platformDisplace == 5 && !banAudit" @click="handleCheck(scope.row, 1)">审查</el-button>
                  <el-button size="medium" type="text" v-else @click="handleCheck(scope.row, 2)">详情</el-button>
                  <el-button size="medium" type="text" v-if="checkStep == 1 && isshowbtn == 1 && scope.row.factoringDisplace == 3" @click="handleUnlock(scope.row)">取消锁定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-bottom" v-if="isshowbtn==1">
            <el-button class="card-btn" type="primary" v-if="checkStep != 1" @click="openrefusediag">退回</el-button>
            <el-button class="card-btn" type="primary" v-else @click="goBack">返回</el-button>
            <el-button class="card-btn" type="primary" @click="openagreediag" :disabled="!submitBtn && !banAudit">同意</el-button>
          </div>
          <div class="card-bottom" v-if="isshowbtn==2">
            <el-button class="card-btn" type="primary" @click="goBack">返回</el-button>
          </div>
        </div>
        <!-- 弹窗 -->
        <!-- 资产包详情 -->
        <el-dialog title='账款列表' :visible.sync="showAssetsDialog" :close-on-click-modal="false" :show-close='false' width='70%'>
          <el-table :data="listData2" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;">
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="absAccountNo" label="账款编号" min-width="100">
            </el-table-column>
            <el-table-column prop="contractNo" label="合同编号" min-width="100">
            </el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="100">
            </el-table-column>
            <el-table-column prop="claimsCorpName" label="债权企业" min-width="100">
            </el-table-column>
            <el-table-column prop="absAccountAmount" label="账款金额（元）" min-width="140">
              <template slot-scope="scope">
                {{$changeMoneyThousand(scope.row.absAccountAmount)}}
              </template>
            </el-table-column>
            <el-table-column prop="absAccountSignDate" label="签署日期" min-width="100">
              <template slot-scope="scope">
                {{scope.row.absAccountSignDate ? moment(scope.row.absAccountSignDate).format('YYYY-MM-DD'): ''}}
              </template>
            </el-table-column>
            <el-table-column prop="absAccountCloseDate" label="计划付款日" min-width="110">
              <template slot-scope="scope">
                {{scope.row.absAccountCloseDate ? moment(scope.row.absAccountCloseDate).format('YYYY-MM-DD'): ''}}
              </template>
            </el-table-column>
            <el-table-column prop="factoringDisplace" label="账款状态" min-width="100">
              <template slot-scope="scope">
                {{getFactoringDisplace(scope.row.factoringDisplace, scope.row.platformDisplace)}}
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="170">
              <template slot-scope="scope">
                <el-button size="medium" type="text" v-if="scope.row.factoringDisplace == 1 && scope.row.platformDisplace == 5 && !banAudit" @click="handleCheck(scope.row, 1)">审查</el-button>
                <el-button size="medium" type="text" v-else @click="handleCheck(scope.row, 2)">详情</el-button>
                <el-button size="medium" type="text" v-if="checkStep == 1 && isshowbtn == 1 && scope.row.factoringDisplace == 3" @click="handleUnlock(scope.row, 1)">取消锁定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="showAssetsDialog=false">关 闭</el-button>
          </div>
        </el-dialog>
        <el-dialog title='审核意见' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false' width='40%'>
          <el-form :model="agreedetail" label-width="60px" ref="addform">
            <el-form-item label="备注：">
              <el-input v-model="agreedetail.remark" type="textarea" :rows="6" maxlength="200"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="showAddDialog=false">取 消</el-button>
            <el-button type="primary" @click="submitAddForm" :disabled="bclick">提交</el-button>
          </div>
        </el-dialog>
        <el-dialog title='拒绝原因' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false' width='40%'>
          <el-form :model="refusedetail" label-width="100px" ref="addform">
            <el-form-item label="拒绝原因：" prop="reason">
              <el-select v-model="refusedetail.reason" placeholder="请选择">
                <el-option
                  v-for="item in refuselist"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="showAddDialog2=false">取 消</el-button>
            <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
          </div>
        </el-dialog>
      </div>
    </layout-main-view>
  </div>
</template>
<script>
  import api from "@/api/api";
  import moment from 'moment'
  export default {
    data() {
      return {
        breadcrumblist:['资产详情'],
        baseInfo: {},
        listData: [],
        listData1: [],
        listData2: [],
        absPlanId: this.$route.query.id,
        absPlanCorp: this.$route.query.absPlanCorp,
        leaderCorpType: this.$route.query.leaderCorpType, // 1保理商主导
        isshowbtn: this.$route.query.isshowbtn,
        number: 0,
        number1: 0,
        totalAmount: 0,
        totalAmount1: 0,
        agreedetail: {
          type: 2, // 1平台 2保理商
          itemId: this.$route.query.absPlanCorp,
          approveNode: '',
          approveResult: 1 , // 1 通过 3 退回(驳回)
          remark: ''        
        },
        refusedetail: {
          type: 2, // 1平台 2保理商
          itemId: this.$route.query.absPlanCorp,
          approveNode: '',
          approveResult: 3,  // 1 通过 3 退回(驳回)
          remark: '',
          reason: ''   
        },
        showAddDialog: false,
        showAddDialog2: false,
        showAssetsDialog: false,
        bclick: false,
        refuselist: [],
        checkStep: this.$route.query.checkStep,
        accountsArr: [],
        banAudit: false,
        currentIndex: '',
        absPlanCorpDto: [],
        absPlanAccountIds: [], // 账款id数组
        corpAccountIds: sessionStorage.getItem('corpAccountIds')
      }
    },
    computed: {
      // 控制按钮状态
      submitBtn() {
        this.accountsArr.forEach(v => {
          this.absPlanAccountIds.push(v.absPlanAccountId)
        })
        return this.accountsArr.every(v => v.factoringDisplace == 3) // factoringDisplace 为3 账款锁定状态
      }
    },
    created() { 
      this.getAbsPlanDetail()
      this.checkSigning()
      if(this.isshowbtn == 1) { // 未审核
        if(this.leaderCorpType == 1) {
          this.getAccountListByCorpOverride()
        } else {
          this.packagedAssets()
        }
      } else if(this.isshowbtn == 2) {  // 已审核
        this.approvedDetail()
      }
      this.getrefuselist()
      if(this.checkStep == 1) {
        this.agreedetail.approveNode = 40
        this.refusedetail.approveNode = 40
      } else if(this.checkStep == 2) {
        this.agreedetail.approveNode = 41
        this.refusedetail.approveNode = 41
      } else if(this.checkStep == 3) {
        this.agreedetail.approveNode = 44
        this.refusedetail.approveNode = 44
      }
    },
    methods: {
      moment: moment,
      headerStyle ({row,rowIndex}) {
        return {background:"#FAFAFA"}
      },
      // 获取退回原因
      getrefuselist() {
        // 保理资产审查
        api.getDicListByType({ type: 30 }).then(res => {
          if (res.success) {
            this.refuselist = res.datas.list;
          }
        });
      },
      // 获取计划详情
      getAbsPlanDetail() {
        // type 1正常查看详情 2 退回详情
        api.getAbsPlanDetail({absPlanId: this.absPlanId, type: 1}).then(res => {
          if(res.success) {
            this.baseInfo = {...res.datas.planDetail.absPlanDto, ...res.datas.planDetail.absPlanDetailDto}
          }
        })
      },
      // 获取资产包列表 保理商主导
      getAccountListByCorpOverride(param) {
        // type 6资方查看资产
        // approveNode 1初审 2复审 3终审
        api.getAccountListByCorpOverride({absPlanId: this.absPlanId, type: 6, approveNode: this.checkStep}).then(res => {
          if(res.success) {
            this.listData = res.datas.detail.list
            this.totalAmount = res.datas.detail.totalAmount
            this.getNumber(res.datas.detail.list)
            res.datas.detail.list.forEach(v => {
              this.accountsArr.push(...v.absPlanAccounts)
            })
            if(param == 'dialogList') {
              this.listData2 = this.listData[this.currentIndex].absPlanAccounts
            }
            this.getAbsPlanCorpDto(this.listData)
          }
        })
      },
      // 获取账款列表 非保理商主导
      packagedAssets() {
        // type 1平台 2保理商
        api.packagedAssets({absPlanId: this.absPlanId, type: 2}).then(res => {
          if(res.success) {
            this.listData1 = res.datas.list
            this.accountsArr = res.datas.list
            this.totalAmount1 = res.datas.totalAmount
            this.number1 = res.datas.number
            this.getAbsPlanCorpDto(this.listData1)
          }
        })
      },
      // 账款/资产包（已审核）
      approvedDetail() {
        let paramsObj = JSON.parse(this.corpAccountIds)
        // type 1平台 2保理
        api.approvedDetail({absPlanCorps: paramsObj.absPlanCorps, absPlanAccountIds: paramsObj.absPlanAccountIds, type: 2}).then(res => {
          if(res.success) {
            if(this.leaderCorpType == 1) {  // 保理主导-资产包
              this.listData = res.datas.list
              this.getNumber(res.datas.list, 1)
            } else {  // 非保理主导-账款
              this.listData1 = res.datas.list.length > 0 ? res.datas.list[0].absPlanAccounts : []
              this.getNumber(this.listData1, 2)
            }
          }
        })
      },
      getNumber(data, flag) {
        // flag 1已审查-资产包 2已审查-账款  其他 未审查
        if(flag == 1) {
          let arr = []
          let arr2 = []
          data.forEach(v => {
            arr.push(v.number)
            arr2.push(v.amount)
          })
          this.number = 0..add(...arr)
          this.totalAmount = 0..add(...arr2)
        } else if(flag == 2) {
          let arr = []
          data.forEach(v => arr.push(v.absAccountAmount))
          this.number1 = data.length
          this.totalAmount1 = 0..add(...arr)
        } else {
          let arr = []
          data.forEach(v => arr.push(v.number))
          this.number = 0..add(...arr)
        }
      },
      // 账款状态
      getFactoringDisplace(val, val2) {
        if(val == 1) {
          if(val2) {
            if(val2 == 5) {
              return '新增'
            } else {
              return '待平台审'
            }
          } else {
            return '新增'
          }
        } else if(val == 2) {
          return '需要置换'
        } else if(val == 3) {
          return '锁定'
        }
      },
      handleDetail(row, index)  {
        this.currentIndex = index
        this.showAssetsDialog = true
        this.listData2 = row.absPlanAccounts
      },
      getAbsPlanCorpDto(data) {
        data.forEach(v => {
          this.absPlanCorpDto.push({
            absPlanCorp: v.absPlanCorp,
            platfromCheck: v.platfromCheck,
            factoringCheck: v.factoringCheck,
          })
        })
      },
      // 审查
      handleCheck(row, isshowbtn) {
        this.$router.push({name: '资方账款详情', query: {absAccountId: row.absAccountId, absPlanAccountId: row.absPlanAccountId, isshowbtn: isshowbtn}})
      },
      // 退回
      openrefusediag() {
        if(this.banAudit) {
          this.updatePlanStatus()
          return false
        }
        this.showAddDialog2 = true;
        this.refusedetail.remark = "";
        this.refusedetail.reason = this.refuselist[0].name;
        this.bclick = false;
      },
      // 同意
      openagreediag() {
        if(this.banAudit) {
          this.updatePlanStatus()
          return false
        }
        this.showAddDialog = true;
        this.agreedetail.remark = "";
        this.bclick = false;
      },  
      submitAddForm() {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        if (this.bclick) {
          return;
        }
        this.bclick = true;
        this.agreedetail.absPlanCorpDto = this.absPlanCorpDto
        this.agreedetail.absPlanAccountIds = this.absPlanAccountIds
        api
          .absProjectApprove(this.agreedetail)
          .then(res => {
            loading.close();
            if (res.code == "100000") {
              this.showAddDialog = false;
              this.$alert("审核通过", "", {
                confirmButtonText: "确定",
                callback: action => {
                  history.go(-1);
                }
              });
            } else {
              this.$message.error(res.message);
              this.bclick = false;
            }
          })
          .catch(err => {
            loading.close();

            this.bclick = false;
          });
      },
      submitAddForm2() {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        if (this.bclick) {
          return;
        }
        this.bclick = true;
        this.refusedetail.absPlanCorpDto = this.absPlanCorpDto
        this.refusedetail.absPlanAccountIds = this.absPlanAccountIds
        api
          .absProjectApprove(this.refusedetail)
          .then(res => {
            loading.close();

            if (res.code == "100000") {
              this.$alert(res.message, "", {
                confirmButtonText: "确定",
                callback: action => {
                  history.go(-1);
                  this.showAddDialog2 = false;
                }
              });
            } else {
              this.$message.error(res.message);
              this.bclick = false;
            }
          })
          .catch(err => {
            loading.close();

            this.bclick = false;
          });
      },
      goBack() {
        history.go(-1);
      },
      // 校验是否签约
      checkSigning() {
        api.checkSigning({absPlanId: this.absPlanId}).then(res => {
          if(res.success) {
            this.banAudit = res.datas.data  // true 签约中 不可操作审查
          }
        })
      },
      // 修改计划已结束
      updatePlanStatus() {
        this.$confirm('该计划已封包，审查中止', '提示', {
          confirmButtonText: '结束计划',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          api.updatePlanOver({absPlanId: this.absPlanId, absPlanCorp: this.absPlanCorp}).then(res => {
            if(res.success) {
              this.$alert(res.message, '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  history.go(-1);
                }
              });
            }
          })
        }).catch(() => {});
      },
      // 账款取消锁定
      handleUnlock(row, type) {
        // type 1平台 2保理商
        api.unlock({absPlanAccountId: row.absPlanAccountId, type: 2}).then(res => {
          if(res.success) {
             this.$alert(res.message, "", {
              confirmButtonText: "确定",
              callback: action => {
                if(type == 1) {
                  this.getAccountListByCorpOverride('dialogList')
                } else {
                  this.packagedAssets()
                }
              }
            });
          }
        })
      }
    },
  }
</script>
<style lang="scss">
.absmaininfo.assetDetail{
  .content-container-inner{
    background-color: #F1F1F1 !important;
    padding-top: 0px;
  }
  .card-bottom {
    width: calc(100vw - 230px);
  }
}
</style>