<template>
  <div>
    <div class="main-contentTop1"></div>
    <div class="main-TopContentBottom">
      <div class="steps">
        <el-steps :active="step" align-center>
            <el-step title="企业信息认证"></el-step>
            <el-step title="个人信息认证"></el-step>
            <el-step title="企业资料补充"></el-step>
            <el-step title="用户人脸识别"></el-step>
            <el-step title="注册申请成功"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="register_main">
      <!-- <el-form
        ref="registerForm"
        label-position="right"
        label-width="140px"
        class="demo-ruleForm registerDemo login-container"
      ></el-form> -->
      <div class="main-TopContent" style="text-align: center;height: 80%;" v-if="type == 1">
        <img src="../../assets/login/rz_cg.png" style="margin-top: 50px;" alt />
        <div>
          <h2>注册申请成功</h2>
        </div>
        <!-- <div>
          <span>请耐心等待平台审核。谢谢合作！</span>
        </div> -->
        <div>
           <el-button @click="goBackHome" type="primary" style="width:238px;margin-top: 20px; ">返回登录</el-button>
        </div>
      </div>
      <div class="main-TopContent" style="text-align: center;height: 80%;" v-if="type == 2">
        <img src="../../assets/login/rz_dengdai.png" style="margin-top: 50px;" alt />
        <div>
          <h2>您的企业认证信息平台审核中，请耐心等待</h2>
        </div>
        <div>
          <span>审核时间一般为1-3个工作日。</span>
        </div>
        <div>
           <el-button @click="openHome" type="primary" style="width:238px;margin-top: 20px; ">返回首页</el-button>
        </div>
      </div>
      <div class="main-TopContent" style="text-align: center;height: 80%;" v-if="type == 3">
        <img src="../../assets/login/rz_shibai.png" style="margin-top: 50px;" alt />
        <div>
          <h2>您的企业没有通过认证！</h2>
        </div>
        <div>
          <span>请核对您提交的资料，重新发起认证申请。</span>
        </div>
        <div class="back">
          <div class="title">失败原因：{{corpList.approveReason}}</div>
          <!--<div class="sub-title">{{corpList.approveReason}}</div>-->
          <div class="title">备注: {{corpList.approveRremark}}</div>
          <!--<div class="sub-title">{{corpList.approveRremark}}</div>-->
        </div>
        <div>
          <el-button @click="reApply" type="primary" style="width:238px;margin-top: 20px; ">重新申请</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import upload from "../../api/upload";
import _ from "lodash";
export default {
  data() {
    return {
      type:2,
      step:4,
    };
  },
  mounted() {
    this.getCorpByCorpId();
  },

  methods: {
    getCorpByCorpId() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      api
        .GetCorpByCorpId({
          relationCorpId: user.relationCorpId
        })
        .then(res => {
          if (res.code == 100000) {
            this.corpList = res.datas.corp;
            sessionStorage.setItem("corpList", JSON.stringify(this.corpList));
            //信息完善之后 需要判断企业是否已经认证 "企业信息审核： 40初始状态 41平台初审通过 43 平台复审通过"
            if (res.datas.corp.corpInfoCheck == 43) {
              this.type = 1;
              this.step = 4;
            } else {
                //如果是40初始状态
              if (res.datas.corp.corpInfoCheck == 40) {
                  //是通过活体认证
                  if(res.datas.corp.registerIdentify === 1){
                      this.step = 4;
                      //是通过活体认证
                  }else{
                      this.step = 3;
                  }
                this.type = 2;
              } else if (res.datas.corp.corpInfoCheck == 41) {
                  //是通过活体认证
                  if(res.datas.corp.registerIdentify === 1){
                      this.step = 4;
                      //是通过活体认证
                  }else{
                      this.step = 3;
                  }
                this.type = 2;
              } else if (res.datas.corp.corpInfoCheck == 42) { //初审拒绝
                this.type = 3;
                this.step = 3;
              } else if (res.datas.corp.corpInfoCheck == 44) { // 复审拒绝
                this.type = 3;
                this.step = 3;
              }
            }
          }
        });
    },
    reApply() {
      var par = JSON.parse(sessionStorage.getItem("corpList"))
        var paraems = {}
      if (par.registerType == 1) {
        // 1 法人
        paraems.name = par.legalName
        paraems.identificationNumber = par.legalIdCard
      }

      if (par.registerType == 2) {
        // 经办人
        paraems.name = par.agentName
        paraems.identificationNumber = par.agentIdCard

      }
      console.log(par,'parparpar')
          
      this.$router.push({
        path: "/Company",
        query: {
          corpId: this.$route.query.corpId,
          ...paraems
        }
      });
    },
    goBackHome(){
        this.$router.push({ path: "/login" });
    },
    openHome(){
      window.location.replace(window.location.origin + '/#/login')
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.el-form-item__content {
  line-height: 30px !important;
}
.register_main {
  margin: auto;
  width: 1110px;
  background: white;
  height: 600px;
}
.required:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}
.required1:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 20px;
}
.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss" >
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1 {
  height: 239px;
  /*background-color: red;*/
  background: url("../../assets/login/cusrz.jpg") center;
  background-size: cover
}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top: 0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon {
    width: 32px;
    height: 32px;
  }
}
.back {
  text-align: none;
  margin-left: 150px;
  margin-top: 30px;
  width: 800px;
  height: 192px;
  background: rgba(250, 250, 250, 1);
  .title {
    margin-left: 30px;
    padding-top: 30px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(89, 89, 89, 1);
    line-height: 22px;
  }
  .sub-title {
    margin-left: 30px;
    padding-top: 10px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(38, 38, 38, 1);
    line-height: 22px;
  }
}
</style>
