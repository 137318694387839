<template>
  <div class="receivablesList">
    <div class="yfdsize" style="font-weight:bold;">绑定应收账款</div>
    <div class="httotal">
      已添加应收账款
      <span>{{tableList.length}}</span> 笔，账款总计金额
      <span>{{ $changeMoneyThousand(formData.relationReceivedAccountAmounts) }}</span> 元。
    </div>
    <div>
      <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
        <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
        <el-table-column prop="accountAmount" label="应收账款金额" min-width="120">
          <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.accountAmount)}}</template>
        </el-table-column>
        <el-table-column prop="accountSignDate" label="账款起始日期" min-width="120">
          <template slot-scope="scope">{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
        </el-table-column>
        <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="120">
          <template slot-scope="scope">{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
    }
  },
  props: {
    tableList: {},
    formData: {}
  },
  methods: {
    handleEdit(data){
      this.$router.push({
        name: "应收账款详情",
        query: { id: data.receivableAccountId, isshowbtn: 2 }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.receivablesList {
  padding: 0 42px;
  background-color: #fff;
}
</style>
