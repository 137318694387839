import { render, staticRenderFns } from "./LivingCertificationcus.vue?vue&type=template&id=3a06a528&scoped=true"
import script from "./LivingCertificationcus.vue?vue&type=script&lang=js"
export * from "./LivingCertificationcus.vue?vue&type=script&lang=js"
import style0 from "./LivingCertificationcus.vue?vue&type=style&index=0&id=3a06a528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a06a528",
  null
  
)

export default component.exports