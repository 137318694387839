<script>
import * as weapon from '@/util/weapon.js';
import './dataFilter.less';
import moment from 'moment';
export default {
  name: 'data-filter',
  data() {
    return {
      value6: '',
      showAll: false,
      usedFilter: [],
      date: '',
      filterForm: {},
      filterAnimateType: false,
      filtersIsShow: true,
      _usedFilterIsShow: false,
      emitAvailable: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      monthPickerOptions: {
        shortcuts: [
          {
            text: '本月',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()]);
            },
          },
          {
            text: '今年至今',
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  props: {
    filters: {
      type: Array,
      default: [],
    },
    isShowAll: {
      type: Boolean,
      default: false,
    },
    isHedden: {
      type: Boolean,
      default: false,
    },
    filterAction: {
      type: null,
      default: '',
    },
    sessionStorageFilterKey: {
      type: String,
    },
    otherFunc: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.path != from.path) {
          this.handleReset(true);
        }
      },
      deep: true,
    },
    filterList: {
      handler(newData) {
        var filter = {};
        newData.forEach((item, index) => {
          if (item.value) {
            if (Array.isArray(item.field)) {
              item.field.forEach((key, index) => {
                filter[key] = item.value[index];
              });
            } else {
              filter[item.field] = item.value;
            }
          }
        });

        this.paramsFilter = filter;

        if (this.emitAvailable) {
          this.$emit('do-filter', this.paramsFilter);
          this.emitAvailable = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    // filterList() {

    //   return this.filters;
    // }
    filterList() {
      let _list = [];
      this.filters.forEach((item) => {
        _list.push(
          Object.assign(
            {
              value: '',
            },
            item
          )
        );
      })

      return _list;
    },
  },
  created() {},
  beforeMount() {
    var usedFilter;
    if ((usedFilter = sessionStorage.getItem(this.sessionStorageFilterKey))) {
      this.usedFilter = JSON.parse(usedFilter);
    }
    for (let index in this.usedFilter) {
      this.$set(
        this.filterForm,
        this.usedFilter[index].prop,
        this.usedFilter[index].value
      );
    }
  },
  methods: {
    handleDateTimeRangeInput(index, arr) {
      var dateFormate = [],
        tem = '';
      // console.log("handleDateTimeRangeInput",arr);
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          if (item) {
            tem = moment(item).format('YYYY-MM-DD HH:mm:ss');
            dateFormate.push(tem);
          } else {
            this.$set(this.filterList[index], 'value', []);
          }
        });
        this.$set(this.filterList[index], 'value', dateFormate);
      }
      this.$forceUpdate();
    },
    handleDateRangeInput(index, arr) {
      console.log(arr);
      var dateFormate = [],
        tem = '';
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          if (item) {
            tem = moment(item).format('YYYY-MM-DD');
            dateFormate.push(tem);
          } else {
            this.$set(this.filterList[index], 'value', []);
          }
        });
        this.$set(this.filterList[index], 'value', dateFormate);
        // this.$set(this.filterList[index],'value','');
      } else {
        this.$set(this.filterList[index], 'value', '');
      }
      this.$forceUpdate();
    },
    handleMonthRangeInput(index, arr) {
      var dateFormate = [],
        tem = '';
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          if (item) {
            tem = moment(item).format('YYYY-MM');
            dateFormate.push(tem);
          } else {
            this.$set(this.filterList[index], 'value', []);
          }
        });
        this.$set(this.filterList[index], 'value', dateFormate);
        // this.$set(this.filterList[index],'value','');
      } else {
        this.$set(this.filterList[index], 'value', '');
      }
      this.$forceUpdate();
    },
    handleYearInput(index, val) {
      if (val) {
        if (this.filterList[index].dateType == 'year') {
          this.$set(
            this.filterList[index],
            'value',
            new Date(val).getFullYear()
          );
        } else {
          var dateFormate = moment(val).format('YYYY');
          this.$set(this.filterList[index], 'value', dateFormate);
        }
      } else {
        this.$set(this.filterList[index], 'value', '');
      }
      this.$forceUpdate();
    },
    handleDateInput(index, val) {
      if (val) {
        if (this.filterList[index].dateType == 'month') {
          this.$set(this.filterList[index], 'value', [
            new Date(val).getFullYear(),
            new Date(val).getMonth() + 1,
          ]);
        } else {
          var dateFormate = moment(val).format('YYYY-MM-DD');
          this.$set(this.filterList[index], 'value', dateFormate);
        }
      } else {
        this.$set(this.filterList[index], 'value', '');
      }
      this.$forceUpdate();
    },
    handleFilter() {
      var filter = {};

      this.filterList.forEach((item, index) => {
        if (item.value) {
          if (Array.isArray(item.field)) {
            item.field.forEach((key, index) => {
              filter[key] = item.value[index];
            });
          } else {
            filter[item.field] = item.value;
          }
        }
      });
      this.paramsFilter = filter;
      this._usedFilterIsShow = true;
      this.usedFilter = [];
      this.filterList.forEach((item, index) => {
        if (this.filterForm[item.prop] != '') {
          this.usedFilter.push(item);
        }
        this.filterForm[item.prop] = item.value;
      });
      sessionStorage.setItem(
        this.sessionStorageFilterKey,
        JSON.stringify(this.usedFilter)
      );
      //	console.log(this.paramsFilter,888)
      this.$emit('do-filter', this.paramsFilter);
    },
    filterShowAll() {
      this.$emit('do-filter-showAll');
      console.log('filterShowAll =====', this.isShowAll);
    },
    handleReset(justReset = false) {
      for (let prop in this.filterForm) {
        this.filterForm[prop] = '';
      }
      this.usedFilterIsShow = false;
      this.filterList.forEach((item, index) => {
        // console.log(item, 'iten');
        this.$set(item, 'value', '');
        this.$forceUpdate();
      });
      if (!justReset) {
        this.$emit('do-filter', this.paramsFilter);
      }
    },
    handleInput(index, val) {
      this.$set(this.filterList[index], 'value', val);
      this.$forceUpdate();
    },
    handleInputrangemin(index, val) {
      this.$set(this.filterList[index], 'value', val);
      this.$forceUpdate();
    },
    handleInputrangemax(index, val) {
      console.log(index, val, 666);
      this.$set(this.filterList[index], 'value', val);
      this.$forceUpdate();
    },
    handleCascaderSelect(index, val) {
      this.$set(this.filterList[index], 'value', val);
    },
    listToggleShow() {
      this.filterAnimateType = this.filterAnimateType ? false : true;
      this.filtersIsShow = true;
      this.showAll = !this.showAll;
    },
    handleHidefilters() {
      this.filterAnimateType = false;
    },
    animationEnd1() {
      this.filtersIsShow = this.filterAnimateType;
    },
    handelFilterDel(index) {
      this.emitAvailable = true;
      this.$set(this.filterList[index], 'value', '');
    },
  },
  render(h) {
    return (
      <div class='data-filter'>
        <div class={['filter-list', { showAll: this.isShowAll }]}>
          <form ref='filterForm'>
            {weapon.arrayEach(this.filterList, (item, index) => {
              switch (item.type) {
                case 'input':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-input
                        class='item-content'
                        clearable={true}
                        name={item.field}
                        value={item.value}
                        size={item.size}
                        on-input={this.handleInput.bind(this, index)}
                        placeholder={item.placeholder}
                      />
                    </div>
                  );
                  break;
                case 'inputrange':
                  if (item.isSeparated) {
                    return (
                      <div class='filter-item index'>
                        <label>{item.label}</label>
                        <el-input
                          class='item-content'
                          name={item.field}
                          value={item.value}
                          size={item.size}
                          type='number'
                          on-input={this.handleInputrangemax.bind(this, index)}
                        />
                        <span>{item.isSeparated ? '~' : ''}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div class='filter-item index2'>
                        <label>{item.label}</label>
                        <el-input
                          class='item-content'
                          name={item.field}
                          value={item.value}
                          size={item.size}
                          type='number'
                          on-input={this.handleInputrangemin.bind(this, index)}
                        />
                      </div>
                    );
                  }

                  break;
                case 'select':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-select
                        class='item-content'
                        clearable={!item.noClearable}
                        on-input={this.handleInput.bind(this, index)}
                        name={item.field}
                        value={item.value}
                        size={item.size}
                        placeholder='请选择'
                      >
                        {item.options
                          ? weapon.arrayEach(item.options, (option, index) => {
                              let label = null,
                                value = null;
                              if (_.isObject(option)) {
                                label = option.label;
                                value = option.value;
                              } else {
                                label = option;
                                value = option;
                              }
                              return (
                                <el-option
                                  label={label}
                                  value={value}
                                  key={value}
                                />
                              );
                            })
                          : ''}
                      </el-select>
                    </div>
                  );
                  break;

                case 'selectSearch':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-select
                        class='item-content'
                        clearable
                        on-input={this.handleInput.bind(this, index)}
                        name={item.field}
                        value={item.value}
                        size={item.size}
                        placeholder='请选择'
                        filterable
                      >
                        {item.options
                          ? weapon.arrayEach(item.options, (option, index) => {
                              let label = null,
                                value = null;
                              if (_.isObject(option)) {
                                label = option.label;
                                value = option.value;
                              } else {
                                label = option;
                                value = option;
                              }
                              return (
                                <el-option
                                  label={label}
                                  value={value}
                                  key={value}
                                />
                              );
                            })
                          : ''}
                      </el-select>
                    </div>
                  );
                  break;

                case 'tree':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-cascader
                        options={item.options}
                        show-all-levels={false}
                        value={item.value}
                        props={item.props}
                        size={item.size}
                        on-input={this.handleCascaderSelect.bind(this, index)}
                      />
                    </div>
                  );
                  break;
                case 'date':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-date-picker
                        class='item-content'
                        editable={false}
                        type={item.dateType || 'date'}
                        on-input={this.handleDateInput.bind(this, index)}
                        value={item.value}
                        size={item.size}
                        picker-options={item.option || {}}
                        placeholder='选择日期'
                      />
                    </div>
                  );
                  break;
                case 'year':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-date-picker
                        editable={false}
                        value={item.value}
                        size={item.size}
                        type={item.dateType || 'year'}
                        on-input={this.handleYearInput.bind(this, index)}
                        placeholder='选择年'
                      />
                    </div>
                  );
                  break;
                case 'daterange':
                  return (
                    <div class='filter-item filter-item-daterange'>
                      <label>{item.label}</label>
                      <el-date-picker
                        class='range-width'
                        editable={false}
                        value={item.value}
                        type='daterange'
                        size={item.size}
                        picker-options={item.pickerOptions || {}}
                        start-placeholder='开始日期'
                        end-placeholder='结束日期'
                        placeholder='选择日期范围'
                        range-separator=' ~ '
                        on-input={this.handleDateRangeInput.bind(this, index)}
                      />
                    </div>
                  );
                  break;
                // 新增自选日期的选择项
                case 'daterangeMore':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-date-picker
                        class='range-width'
                        editable={false}
                        value={item.value}
                        size={item.size}
                        type='daterange'
                        align='left'
                        picker-options={this.pickerOptions}
                        start-placeholder='开始日期'
                        end-placeholder='结束日期'
                        placeholder='选择日期范围'
                        range-separator=' ~ '
                        on-input={this.handleDateRangeInput.bind(this, index)}
                      />
                    </div>
                  );
                  break;
                case 'datemonthrange':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-date-picker
                        class='range-width'
                        editable={false}
                        value={item.value}
                        size={item.size}
                        type='monthrange'
                        align='left'
                        unlink-panels
                        picker-options={this.monthPickerOptions}
                        start-placeholder='开始月份'
                        end-placeholder='结束月份'
                        placeholder='选择月份范围'
                        range-separator=' ~ '
                        on-input={this.handleMonthRangeInput.bind(this, index)}
                      />
                    </div>
                  );
                  break;
                case 'datetimerange':
                  return (
                    <div class='filter-item'>
                      <label>{item.label}</label>
                      <el-date-picker
                        editable={false}
                        value={item.value}
                        type='datetimerange'
                        value-format='yyy-MM-dd HH:mm:ss'
                        start-placeholder='开始时间'
                        end-placeholder='结束时间'
                        placeholder='选择时间范围'
                        range-separator=' 至 '
                        size={item.size}
                        on-input={this.handleDateTimeRangeInput.bind(
                          this,
                          index
                        )}
                      />
                    </div>
                  );
                  break;

                default:
                  break;
              }
            })}
          </form>
        </div>
        {this.isHedden ? (
          <div>
            <el-button
              type='primary'
              class='btn-doFilter'
              on-click={this.handleFilter}
            >
              查询
            </el-button>
            <el-button plain class='btn-doFilter' on-click={this.filterShowAll}>
              {!this.isShowAll ? '展开' : '收起'}
            </el-button>
            <el-button
              type='primary'
              class='btn-doFilter'
              on-click={this.handleReset}
            >
              重置
            </el-button>
            {this.otherFunc.map((val) => (
              <el-button
                type={val.type || 'primary'}
                class={val.class + 'btn-doFilter' || 'btn-doFilter'}
                on-click={val.func}
              >
                {val.text}
              </el-button>
            ))}
          </div>
        ) : (
          <div class='data-btns'>
            <el-button
              type='primary'
              class='btn-doFilter'
              on-click={this.handleFilter}
            >
              查询
            </el-button>
            <el-button
              type='primary'
              class='btn-doFilter'
              on-click={this.handleReset}
            >
              重置
            </el-button>
            {this.otherFunc.map((val) => (
              <el-button
                type={val.type || 'primary'}
                // class="btn-doFilter"
                class={val.class ? val.class + ' btn-doFilter' : 'btn-doFilter'}
                on-click={val.func}
              >
                {val.text}
              </el-button>
            ))}
          </div>
        )}
      </div>
    );
  },
};
</script>

<style scoped></style>
