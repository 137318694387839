
<template>
    <el-dialog :visible.sync="isShowDialog" id="notice-dialog" center>
        <div class="dynamic-details">
            <div class="article">
                <div class="article-header">
                        <div class="article-title">{{ form.noticeTitle }}</div>
                        <div class="article-date">{{ form.createTime ? moment(form.createTime).format('YYYY-MM-DD HH:mm'): '' }}</div>
                </div>
                <div class="article-content"  id="contentBox"></div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment'
import api from "@/api/api";

export default {
    name: 'ReadNews',
    data() {
        return {
            breadcrumblist: ['公告详情'],
            form: {
                noticeTitle: '',
                noticeContent: '',
                createTime: ''
            },
            moment:moment,
            isShowDialog: false
        }
    },
  
    methods: {
        initInfo(noticeId) {
            let param = {
                noticeId,
            };
            api.noticeDetail(param).then(res => {
                if(res.success) {
                    const notice = res.datas.notice;
                    const next = res.datas.next;
                    const previous = res.datas.previous;
                    const { noticeTitle, noticeContent, createTime } = notice;
                    this.form = {
                        noticeTitle,
                        noticeContent,
                        createTime
                    }
                    if(next) {
                        this.nextTitle = next.noticeTitle;
                    }
                      if(previous) {
                        this.previousTitle = previous.noticeTitle;
                    }
                }
            }).then(() => {
                this.isShowDialog = true;
                this.$nextTick(() => {
                document.getElementById('contentBox').innerHTML = this.form.noticeContent ? this.form.noticeContent : '';
                })
            })
        },
    }
}
</script>

<style lang="scss" scoped>
#notice-dialog {
    .dynamic-details {
        background: #ffffff;
        .article {
            margin: 0 auto 0;
            background: #fff;
            .article-header {
                border-bottom: 1px solid #e6e6e6;
                .article-title {
                    font-size: 24px;
                    font-weight: 600;
                    color: rgba(0,0,0,.85);
                    text-align: center;
                    line-height: 36px;
                    margin-bottom: 8px;
                }
                .article-date {
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    color: rgba(0,0,0,.65);
                    letter-spacing: .75px;
                    text-align: center;
                    line-height: 20px;
                    margin-bottom: 24px;
                }
            }
            .article-content {
                font-size: 16px;
                line-height: 32px;
                color: #595959;
            }
          
        }
    }
  
}
</style>

<style>   
#notice-dialog .el-dialog.el-dialog--center{
        height: 70%;
          overflow: hidden;
        overflow-y: scroll;
}
#notice-dialog .el-dialog.el-dialog--center::-webkit-scrollbar{
    width:8px;
}
#notice-dialog .el-dialog.el-dialog--center::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:10px;
    height: 100px;
}
#notice-dialog .el-dialog.el-dialog--center::-webkit-scrollbar-thumb:hover{
    background: #333;
    cursor: pointer;
}
</style>   
