<template>
  <div>
    <el-button @click="getUkey">默认按钮</el-button>
  </div>
</template>


<script>
import ukey from '@/util/ukey.js';
export default {
  name: '',
  props: {},
  watch: {},
  computed: {},
  data() {
    return {};
  },
  components: {},
  created() { 
    //   ukey.LoadObj()
  },
  mounted() {},
  methods: {
    async getUkey() {
      let res = await ukey.LoadObj();
      console.log(res,'ukey');
console.log(await ukey.GetCertInfoOnClick('SubjectDN'),'')
 
      
 
console.log(await ukey.SignHashOnClick('22'),'加密1 ')
console.log(await ukey.SignHashOnClick('33'),'加密2 ')

    }
  }
};
</script>

<style lang='scss' scoped>
</style>
