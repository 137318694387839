<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>
                <abscountdetail></abscountdetail>
              
        </layout-main-view>
    </div>
</template>
<script>
    import api from "@/api/api";
    import  abscountdetail from '@/views/absbase/abscountdetail' 
 


    export default {
        data() {
            return {
                breadcrumblist:['资产账款详情'],
     
            }
        },
        created() {

        },
        methods: {
    
        },
        components: {
           abscountdetail

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         background: #ffffff;
         padding-top: 20px;
       }

   }
</style>