
        <!-- 票据详情 -->
<template>
<div>


    <div class="yfdsize rback  abs-title">
          退回原因
        </div>
        <div class="card-bd card-bd-row ">
          <el-row>
            <el-col :span="6" class="lb_body">
              <img src="../../assets/cuowu.png" alt=""
                style="vertical-align: middle;">
              <span class="info-key">专项计划被退回:</span>
            </el-col>
             <el-col :span="6" class="lb_body">
              <span class="info-key">退回理由:</span>
              <span class="info-value index">{{backinfo.reason}}</span>
            </el-col>
             <el-col :span="6" class="lb_body">
      
              <span class="info-key">操作人:</span>
              <span class="info-value index">{{backinfo.approveOperatorName}}</span>
            </el-col>
             <el-col :span="6" class="lb_body">

              <span class="info-key">操作时间:</span>
              <span class="info-value index">{{backinfo.createTime|formatHour}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="lb_body">
              <span class="info-key">备注:</span>
              <span class="info-value index">{{backinfo.remark}}</span>
            </el-col>
          </el-row>
        </div>
</div>
</template>
<script>
    import moment from 'moment'



    export default {
        props: {
             backinfo: {
                type: Object,
                default() {
                    return {
                    
                    }
                }
            }
            
        },
        watch:{
             backinfo: {
                    immediate: true, // 很重要！！！
                    handler (val) {
                    
                            console.log(val,'dd')
                       
                    }
            }

        },
        data() {
            return {
              
            }
        },
        mounted() {
                console.log(this.backinfo)
     
        },
        methods: {
            moment: moment,
     
        }
    }
</script>
<style lang="scss">
     .titlemsgabs{
         width: 300px;
         font-size: 13px;


     } 
  .abs-xmgk-details{
      background: #ffffff;
      margin-bottom: 20px;

 
          .el-row{
              text-indent: 1em;
              .el-col{
                  margin-bottom: 14px;
              }
          }
        .abs-title{
            position: relative;
         font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
      
            margin-bottom: 24px;
        
        }

  
  }
  
</style>