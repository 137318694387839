// import {DictionaryAll} from '../api/api'
import * as types from '@/store/mutation-types';
export default {
  [types.UPDATE_supName]({ commit, state }, params) {
    console.log('action==>', state);
    if (!state) {
      commit(types.UPDATE_supName, sessionStorage.getItem('supName'));
    }
  },
  // 请求字典数据
  // [types.GET_dict]({ commit, state }, params) {
  //     DictionaryAll(params).then((res) => {
  //         if (res.code === '1') {
  //             commit(types.UPDATE_dict,res.data.list)
  //             sessionStorage.setItem('dict',JSON.stringify(res.data.list))
  //         } else {
  //             vm.$message({
  //                 type: 'error',
  //                 message: res.msg
  //             });
  //         }
  //     }).catch((err) => {
  //         console.log(err)
  //     })
  // }
};
