<template>
  <div>
    <div class="main-contentTop1 active"></div>
    <div class="main-TopContentBottom">
      <div class="steps">
          <el-steps :active="2" align-center >
            <el-step title="个人信息认证"></el-step>
            <el-step title="用户人脸识别"></el-step>
            <el-step title="个人认证成功"></el-step>
          </el-steps>
        </div>
    </div>

    <div class="register_main">
      <el-form
        ref="registerForm"
        label-position="right"
        label-width="140px"
        class="demo-ruleForm registerDemo login-container"
      ></el-form>
      <div class="main-TopContent" style="text-align: center;height: 80%;" >

        <div id="qrcode" ref="qrcode"></div> <!-- 创建一个div，并设置id为qrcode -->
        <div>
          <div>
          <h3>请使用微信扫一扫功能，扫描以上二维码，在手机端完成视频验证</h3>
          <h3>请确认为当前注册人本人进行视频验证</h3>
          <h4>人脸认证成功后，请
            <el-button @click="getCorpByCorpId" type="primary">刷新页面</el-button>
          </h4>
          <!-- <el-button @click="pass" type="primary" style="width:238px;margin-top: 20px; ">手动通过人脸识别</el-button> -->
        </div>
        </div>
        <el-button
              type="primary"
              size="small"
              @click.native="back()"
              style="width: 160px;height: 40px;background: white;color: black;margin-right: 32px;"
            >上一步</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import upload from "../../api/upload";
import _ from "lodash";
import QRCode from 'qrcodejs2'  // 引入qrcode

export default {
  data() {
    return {
      type: "",
      step:'',
      corpId:JSON.parse(sessionStorage.getItem('corpList')).corpId,
      userId: JSON.parse(sessionStorage.getItem('user')).userId,
      qrcode: null
    };
  },
  mounted() {
    this.getCorpByCorpId();
    // this.qrcode();

  },

  methods: {
    back() {
      this.$router.push({
        path: '/customerprefect',
        query: {
          corpId: this.userId
        }
      });
    },
    async getCorpByCorpId() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      await api.getVerifyFaceIdentity({userId:user.userId}).catch((err) => {
        console.log(err);
      });
        api
        .getPersonalInformationAuth({
          userId: user.userId
        })
        .then(res => {
          if (res.code == 100000) {
            // registerIdentify  2 未检测 3成功 4检查失败
            if(res.datas.personalInforAuth == null || res.datas.personalInforAuth !=3){
    
                this.getQRCode();
            }else {
                this.$router.push({path : "/customeresult", query: {
                    corpId: user.userId}
                });
            }
          }
        });
    },
    pass(){
      var user = JSON.parse(sessionStorage.getItem("user"));
      window.location.reload()
      api.getPersonalInformationAuth({ userId: user.userId}).then(res =>{
        if(res.success){
            this.$alert(res.message, '', {
            confirmButtonText: '确定',
            callback: action => {
                this.$router.push({path : "/customeresult", query: {
                    corpId: user.userId}
                });
            }});
        }else {
            this.$message.error(res.message);
        }
      })
    },

    async getQRCode() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      let res = await api.getFaceldentityURL({
        userId: user.userId,
        returnUrl: `${location.origin}/#/faceVerifyResult`
      })
      if (!res.success) {
        this.$message.error(res.message);
        return false;
      }
      let text = res.datas.url;
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      } else {
        this.qrcode = new QRCode('qrcode', {
          width: 300,
          height: 300,
          // text: 'http://192.168.21.68:9999/#/livingCertification?corpId=122',//二维码地址 
          text,
          colorDark: "#000",
          colorLight: "#fff",
        })
      }
      this.$refs.qrcode.title = ''
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.el-form-item__content {
  line-height: 30px !important;
}
.register_main {
  margin: auto;
  width: 1110px;
  background: white;
  height: 600px;
}
.required:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}
.required1:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 20px;
}
.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss" >
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1.active{
  height: 239px;
  /*background-color: red;*/
  background: url("../../assets/workbench/header.png") center;
  background-size: cover

}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top: 0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon {
    width: 32px;
    height: 32px;
  }
}
.back {
  text-align: none;
  margin-left: 150px;
  margin-top: 30px;
  width: 800px;
  height: 192px;
  background: rgba(250, 250, 250, 1);
  .title {
    margin-left: 30px;
    padding-top: 30px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(89, 89, 89, 1);
    line-height: 22px;
  }
  .sub-title {
    margin-left: 30px;
    padding-top: 10px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(38, 38, 38, 1);
    line-height: 22px;
  }
}
#qrcode {
display: inline-block;
margin-top: 20px;
    img {
        width: 300px;
        height: 300px;
        background-color: #fff; //设置白色背景色
        padding: 6px; // 利用padding的特性，挤出白边
    }
}
</style>
