<template>
  <div>
      <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <openApprovalNot v-if="showtab==1"></openApprovalNot>
            <openApprovalYet v-if="showtab==2"></openApprovalYet>
    </layout-main-view>


  </div>
</template>
<script>
import api from "@/api/api";
import openApprovalNot from "./openApprovalNot";
import openApprovalYet from "./openApprovalYet";

export default {
    components: {
    'openApprovalNot':openApprovalNot,
    'openApprovalYet':openApprovalYet
  },
  data() {
    return{
      breadcrumblist: ["开立审批"],
          tabOptions: [
        { label: "待审核", value: "1", path: "/fund/easypay/openapproval/1" },
        { label: "已审核", value: "2", path: "/fund/easypay/openapproval/2" },
      ],
            showtab:1
    }
  },
  created() {
    console.log(this.$route)
    this.showtab=this.$route.params.state
  },
  methods: {
     tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">

</style>
