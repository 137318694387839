<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
        <waitdistinguishing v-if="showtab == 1"></waitdistinguishing>
        <readlydistinguishing v-if="showtab == 2"></readlydistinguishing>
        <overduedistinguishing v-if="showtab == 3"></overduedistinguishing>
    </layout-main-view>
  </div>
</template>
<script>

import waitdistinguishing from "./watiClearClose";
import readlydistinguishing from "./readClearClose";
import overduedistinguishing from "./overClearClose";
import moment from 'moment'

export default {
  components: {
    "waitdistinguishing": waitdistinguishing,
    'readlydistinguishing':readlydistinguishing,
    'overduedistinguishing':overduedistinguishing
  },
  data() {
    return {
      breadcrumblist: ["应收清分"],
      showtab: 1,
      tabOptions: [
        { label: "待清分", value: "1", path: "/fund/easypay/clearCloseManage/1" },
        { label: "已清分", value: "2", path: "/fund/easypay/clearCloseManage/2" },
        { label: "逾期未清分", value: "3", path: "/fund/easypay/clearCloseManage/3" },
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false
    };
  },
  created() {
  },
  mounted(){
      this.showtab = this.$route.params.state;
  },
  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>