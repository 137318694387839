<template>
    <div>
        <!-- 这个页面是授权管理列表页面内容。 -->
        <layout-main-view
            :breadcrumb="breadcrumblist"
            :tabOptions="tabOptions"
            @tabclick="tabclick"
            >
            <useableLimit v-if="showtab == 1" key="1"></useableLimit>
            <historyLimit v-if="showtab == 2" key="2"></historyLimit>
        </layout-main-view>
    </div>
</template>

<script>
import useableLimit from "./useableLimit.vue";
import historyLimit from "./historyLimit.vue";
export default {
    components: {
        "useableLimit": useableLimit,
        'historyLimit':historyLimit,
    },
    data(){
        return{
            breadcrumblist: ["授信管理"],
            showtab: 1,
            tabOptions: [
                { label: "可用额度", value: "1", path: "/fund/business/authorizationManage/1" },
                { label: "历史额度", value: "2", path: "/fund/business/authorizationManage/2" },
            ],
            activeName: "first",
            total: 0,
            listLoading: false,
            listData: [],
            handleEditShow: false,
            // optionsList:[],
        }
    },
    created() {
        // this.getCorpList();
    },
    mounted(){
        this.showtab = this.$route.params.state;
    },
    methods: {
        tabclick(val) {
            this.showtab = val;
            this.handleEditShow = false;
        },
        getCorpList(){
            let para = {
                type:2
            }
            api.getFundList(para).then((res)=>{
                if(res.success){
                    this.optionsList = res.datas.corpList;
                    console.log(this.optionsList);
                }
            })
        }






    }
}
</script>

<style>

</style>