<template>
	<section class="content-container">
		<div class="grid-content bg-purple-light">
			<div class="ly-page-head">
				<div class="current-position">
					<el-breadcrumb separator="/" class="breadcrumb-inner">
						<el-breadcrumb-item v-for="item in breadcrumb" :key="item.path" >
							{{ item }}
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div class="content-wrapper ly-page-body">
				<div class="common-tabs" v-if="tabOptions.length > 0">
					<ul>
						<li v-for="item in tabOptions"
							:key="item.value"
							:class="{'active':item.value==isactive }"
							@click="activefn(item)">{{item.label}}</li>
					</ul>
				</div>
				<div class="content-container-inner">
					<slot />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'layout-main-view',
	data () {
		return {
			isactive:'1'
		}
	},
	created(){
		this.isactive = this.$route.params.state
	},
	  props: {
		tabOptions: {
		type: Array,
			default () {
				return []
			}
		},
		breadcrumb: {
			type: Array,
			default () {
				return false
			}
		},
	  },
	methods:{
		has(obj,path){
			console.log(obj,path,77,this.$route.matched)
			return _.has(obj, path)
		},
		activefn(data) {
			console.log(data,789)
				// if(/type/.test(data.path)){
				// 	var arr=data.path.match(/type=(\d)/)[1]
				// }
				this.isactive = data.value
				this.$router.push({ path: data.path, params: { state: data.value },query:{pageNum:1,pageSize:10 }})
				// this.pageNum=1;
				this.$emit('tabclick', data.value)
				},
		},
}
</script>
<style lang="scss">
/* element-ui 面包屑样式覆盖 start */
.current-position{
	.el-breadcrumb{
	  .el-breadcrumb__inner{
	  	color:#8C8C8C;
		  font-weight: normal;
	  }
	}
	.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: #595959;
	}
}
.content-container{
	.ly-page-head{
		height: 58px;
		padding: 16px;
		background-color: #F1F1F1;
	}
	.current-position .el-breadcrumb__item:last-child .el-breadcrumb__inner{
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: rgba(0,0,0,0.85);
		line-height: 28px;
	}
	&-inner {
		padding: 16px;
		padding-top: 0;
		background-color: #F1F1F1;
	}
}
.common-tabs{
	background: #F1F1F1;
	// margin-top: 6px;
  line-height: 28px;
	ul{
		overflow: hidden;
		margin: 0;
		padding-left: 16px;
		padding-right: 16px;
		// padding-bottom: 8px;
		// padding-top: 24px;
	}
	li{
		padding: 0 24px;
		background:#F7FAFF;
		border: 1px solid #A7C1F0;
		border-bottom: none;
		margin-right:4px;
		float: left;
		text-align: center;
		position: relative;
		height: 32px;
		line-height: 32px;
		cursor: pointer;
		font-size: 14px;
		color: #2937A6;
		// color: rgba(0,0,0,0.65);
		border-radius: 2px 2px 0 0;
		&:hover{
			// color: #2937A6;
		}
		&.active{
			background: #2937A6;
			// background: #E6F0FF;
			border-radius: 2px;
			font-size: 14px;
			color: #FFF;
			// color: #2937A6;
		}
	}
}

/* element-ui 面包屑样式覆盖 end */
</style>
