<template>
  <div class="certification-page">
      <div class="main-container">
          <img src="../../assets/login/login_bg.jpg" alt="">
          <div class="title">用户人脸识别说明</div>
          <div>优企通人脸识别是CFCA厂商提供的生物识别技术。主要使用手机的摄像头采集含有人脸的图像或视频流，并自动在图像中检测和跟踪人脸，验证识别对象是否为本人完成操作。</div>
          <ul>
              <li>人脸识别对象和身份证画像比对，确认人证信息相符。</li>
              <li>同公安网联网核查身份证信息真伪，确保身份信息真实可靠。</li>
              <li>抵御照片、换脸、面具、遮挡以及屏幕翻拍等攻击手段，帮助用户辨别欺诈行为，保障用户的权利和资产安全。</li>
          </ul>
          <div>优企通平台向您郑重承诺您的人脸识别信息只作为本平台服务授权使用的依据，不会以任何方式作为商业数据进行流通。</div>
      <el-alert
    title="建议视频录制时长不超过5秒，视频大小不得超过10M"
    type="warning"
    :closable="false"
    show-icon>
  </el-alert>
      </div>
      <div class="footer-box">
          <div class="footer-btn" @click="submitForm">开始检测</div>
      </div>
      <input
      type="file"
  v-show="false"
      accept="video/*"
      id="video"
      ref="submitBtn"
      name="video"
      @change="uploadVideo"
      capture="user"
    />
  </div>
</template>
<script>
import api from "../../api/api";
export default {
    data() {
        return {
        }
    },
    created() {
      console.log(this.$route.query,'query')
        localStorage.setItem('corpId', this.$route.query.corpId);
        localStorage.setItem('userId', this.$route.query.userId); 
    },
    mounted() {
    },
    methods: {
      uploadVideo(e) {
      const file = e.target.files[0];
      this.switchVideo(file);
    },
    switchVideo(file) {
      const size = (file.size / 1024 / 1024).toFixed(3);
      if (size > 10) {
        this.$message.error('视频大小不得超过10M!');
      } else {
        
        const failHandle = () => this.$confirm("人脸识别失败，请重新再试！","提示",{
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal:false
            });


        var formData = new FormData();
        // formData.append('corpId', Number(localStorage.corpId));
        // formData.append('userId', Number(localStorage.userId));
        // formData.append('operType', '2');
        let corpId = localStorage.corpId
        let userId = localStorage.userId 
        let operType = '2'
         formData.append("file",file);
        api.getLivingCertificate(formData,corpId,userId,operType).then((res) => {
          if (res.success) {
            this.$confirm("人脸识别成功，请刷新电脑当前页！","提示",{
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal:false
            });
          } else {
            failHandle()
          }
          this.$refs.submitBtn.value=''
        }).catch(failHandle);
      }
    },
        submitForm() {
            this.$refs.submitBtn.click()
        }
    },
};
</script>
<style lang="scss" scoped>
@function fun($width) {
  @return ($width/$rem) + rem;
}
$rem: 75px;
.certification-page {
    max-width: fun(750px);
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: fun(120px);
    .main-container {
        padding: fun(50px) fun(50px) fun(30px);
        background-color: #fff;
        img {
            display: block;
            width: fun(128px);
            margin: 0 auto;
            border-radius: fun(8px);
        }
        .title {
            font-size: fun(36px);
            font-weight: bold;
            text-align: center;
            color: #222;
            margin: fun(40px) 0;
        }
        div,li {
            color: #555;
            font-size: fun(30px);
            line-height: fun(46px);
            margin-bottom: fun(36px);
        }
        ul {
            padding-left: 0;
        }
        li {
            &:before {
                content: "\2022"; 
                color: #3A48BF; 
                font-size: fun(40px);
                margin-right: fun(10px);
                vertical-align: middle;
            }
        }
    }
    .footer-box {
        position: fixed;
        width: 100%;
        max-width: fun(750px);
        bottom: 0;
        height: fun(120px);
        padding: fun(16px) fun(32px);
        border-top: 1px solid #ddd;
        background-color: #fff;
        .footer-btn {
            background-color: #3A48BF;
            border-radius:8px;
            color: #fff;
            font-size: fun(34px);
            line-height: fun(88px);
            text-align: center;
        }
    }
}
</style>