<!-- 票据详情 -->
<template>
    <div class="abs-check-details">
        
            <div class="abs-title">绑定账款: </div>
              <div class="httotal">
            已绑定<span>{{zclist.length}}</span>笔账款,
            账款总计金额
            <span>{{$changeMoneyThousand(moneytotal)}}</span> 元。
         </div>

  
        <el-table
          :data="zclist"
            align='center'
          highlight-current-row
      
          style="width: 100%;"
        >
        <el-table-column prop="absAccountNo" label="账款编号" min-width="180"></el-table-column>
           <!-- <el-table-column prop="contractNo" label="合同编号" min-width="170"> </el-table-column> -->
          <el-table-column prop="claimsCorpName" label="债权企业" min-width="210" v-if="isbl||planCorpType==2" ></el-table-column>
          <el-table-column prop="debtCorpName" label="债务企业" min-width="210" v-if="isbl||planCorpType==3" ></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>

          <el-table-column  label="签署日期"  prop='absAccountSignDate' min-width="160" >
                <template slot-scope="scope">
               {{scope.row.absAccountSignDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column  label="计划付款日期"  prop='absAccountCloseDate' min-width="160" >
                  <template slot-scope="scope">
               {{scope.row.absAccountCloseDate|formatDate}}
       
                </template>
          </el-table-column>
          <!-- <el-table-column prop="debtCorpName" label="账款状态" min-width="210"  :formatter='getapproveStatus'></el-table-column> -->


          <el-table-column label="操作" width="120" align="left"       >
            <template slot-scope="scope" >
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:1}}" class="initiation">查看详情</router-link>
               
       
            </template>
          </el-table-column>
        </el-table>

       
        
       
    </div>
</template>
<script>
    import moment from 'moment'



    export default {
        props: {
            datalist: {
                type: Array,
                default() {
                    return []
                }
            },
             moneytotal: {
                type: Number,
                default() {
                    return 0
                }
            },
            planCorpType:{
                 type: Number,
                default() {
                    return 1
                }
            }
        },
        watch:{


        },
        data() {
            return {
                showAddDialog:false,
                zclist:[],
                isbl:false
              
            }
        },
        mounted() {
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false

                console.log(this.datalist,'datalist',this.planCorpType) 
                this.datalist.map(el=>{
                        if(el.absPlanAccounts.length){
                                    el.absPlanAccounts.map(ele=>{
                                        this.zclist.push(ele)
                                    })
                        }
                })
     
        },
        methods: {
            moment: moment,
            handleEditinfo(row){
                console.log(row)
                this.zclist=row.absPlanAccounts
                this.showAddDialog=true
            },
            getapproveStatus(data){
                 switch (data.approveStatus) {
                     case 0:
                      return  '初始'   
                         break;
                     case 1:
                      return  '新增'   
                         break;
                        case 2:
                      return  '需置换'   
                         break;
                        case 3:
                      return  '已锁定'   
                         break;
                 }
            },
            handleopen(row){
                window.open('/abs/absplaninfo?id=14&isshowbtn=2')
            }
     
        }
    }
</script>
<style lang="scss">
  .abs-check-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
       a.initiation{
                 text-decoration: none;
               color: #409EFF;
       }
  }

  
 
  
</style>