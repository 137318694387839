<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"

        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"

          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
            <el-table-column prop="billNo" label="票据编号" min-width="160"></el-table-column>
            <el-table-column label="票据类型"  min-width='110'>
                <template slot-scope="scope">
                    {{scope.row.billType|billType}}
                </template>

            </el-table-column>
            <!-- <el-table-column prop="acceptor" label="承兑人"  min-width='110'></el-table-column> -->
            <!--<el-table-column prop="payableBillAmount" label="期望每十万元扣费(元)" min-width="100"  >-->
            <!--<template slot-scope="scope">-->
            <!--{{$changeMoneyThousand(scope.row.deductionStr)+'~'+$changeMoneyThousand(scope.row.deductionEnd)}}-->
            <!--</template>-->
            <!--</el-table-column>-->
              <el-table-column label="期望每十万元扣费(元)" min-width="190"  >
            <template slot-scope="scope">
                      {{$changeMoneyThousand(scope.row.deductionStr)+'~'+$changeMoneyThousand(scope.row.deductionEnd)}}
             </template>


          </el-table-column>
            <el-table-column prop="fundYearInterest" label="期望成交利率(%)"  min-width='160' >
                <template slot-scope="scope">
                    {{scope.row.sellRateStr+'~'+scope.row.sellRateEnd}}
                </template>

            </el-table-column>
            <el-table-column label="票面金额(元)"  min-width='160'>
                <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.billAmount) }}</template>
            </el-table-column>
            <el-table-column label="有无瑕疵"  min-width='110'>
                <template slot-scope="scope">{{scope.row.flawStatus==1?'无':'有'}}</template>
            </el-table-column>
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [

        {
          label: "票据金额(元): ",
          type: "inputrange",
          field: "billAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "billAmountMax",
          size: "small"
        },
     {
          label: "期望利率(%): ",
          type: "input",
          field: "sellRate",
          size: "small",
        },
           {
          label: "票据类型: ",
          type: "select",
          field: "billType",
          size: "small",
          options:[{label:'商票',value:1},{label:'国股',value:2},{label:'城商',value:3},{label:'三农',value:4},{label:'财票',value:5}]

        },
            {
          label: "有无瑕疵: ",
          type: "select",
          field: "flawStatus",
          size: "small",
          options:[{label:'无瑕疵',value:1},{label:'有瑕疵',value:2}]

        },


      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      changeMoneyThousand:changeMoneyThousand
    };
  },
  created() {
  },
  methods: {
    moment: moment,

    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {

      api.toDoList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
      });
    },
    handleEdit(row) {
         this.$router.push({
                    path: "/bill/bill-approvaled-details",
                    query: { id: row.billId}
                });
    },

  }
};
</script>


