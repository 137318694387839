<template>
	<div class="common-page-dataList">
		<el-tabs type="border-card" class="n-tabs">
			<data-list
				:filters="filterList"
				@data-refresh="handleRefresh"
				:total="total"
				ref="datalist">
				<el-table
					:data="listData"
					:header-cell-style="headerStyle"
					highlight-current-row
					v-loading="listLoading"
					style="width: 100%;">
					<el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="originalName" label="开单企业" min-width="200"></el-table-column>
					<el-table-column prop="fundCorpName" label="资金机构" min-width="170"></el-table-column>
					<el-table-column prop="payableBillAmount" label="优企通金额(元)" min-width="170" :formatter='el=>$changeMoneyThousand(el.payableBillAmount)'></el-table-column>
					<!-- <el-table-column prop="fundYearInterest" label="资金年化利率(%)" min-width="170" ></el-table-column> -->
					<el-table-column prop="payableBillFinanceAmount" label="已融资金额(元)" min-width="170" :formatter='el=>$changeMoneyThousand(el.payableBillFinanceAmount)'></el-table-column>
					<el-table-column prop="payableBillFinanceAmount" label="待清分金额(元)" min-width="170" :formatter='el=>$changeMoneyThousand(el.payableBillFinanceAmount)'></el-table-column>
					<el-table-column prop="beClearNum" label="待清分企业数" min-width="150"></el-table-column>
          <el-table-column prop="approveResult" label="审批结果" min-width="150">
            <template slot-scope="scope">
              {{ getApproveResult(scope.row.approveResult)}}
            </template>
          </el-table-column>
					<el-table-column label="操作" width="120" >
						<template slot-scope="scope">
							<el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
			</data-list>
		</el-tabs>
	</div>
</template>
<script>
import api from "@/api/api";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";

export default {
  data() {
    return {
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      moment: moment,
      changeMoneyThousand: changeMoneyThousand,
      filterList: [
        {
          label: "优企通编号: ",
          type: "input",
          size: "small",
          field: "payableBillNo",
        },
        {
          label: "资金利率(%): ",
          type: "inputrange",
          field: "startFundYearInterest",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "endFundYearInterest",
          size: "small"
        },
        {
          label: "清分金额(元): ",
          type: "inputrange",
          field: "startPayableBillClearAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "endPayableBillClearAmount",
          size: "small"
        }
      ]
    };
  },
  created() {},
  methods: {
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.type = 2; // 1持有企业 2资金机构
      param.typeStr = 1; // 1核心审批 2平台审批 3资方审批
      // param.approveNode = 1; // 1初审 2复审 3终审(核心仅一审)
      param.openCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      api.getPayableBillLiquidationListApproved(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(data) {
      this.$router.push({
        name: "清分审批详情",
        query: { id: data.payableBillNo, isshowbtn: 2, checkType: 2, payableBillId: data.payableBillId, payableBillLiquidationApproveId: data.payableBillLiquidationApproveId }
      });
    },
    // 审批结果
    getApproveResult(status) {
      if(status) {
        return status == 1 ? '确认无误' : '提出异议'  // 1通过 2不通过
      }
    }
  }
};
</script>

<style lang="scss">
.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
