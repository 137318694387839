<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="170"></el-table-column>
          <el-table-column prop label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column prop label="资金机构" min-width="170"></el-table-column>
          <el-table-column prop label="融资年化利率" min-width="120"></el-table-column>
          <el-table-column label="融资金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableBillFinanceAmount) }}</template>
          </el-table-column>
          <el-table-column label="预计融资利息(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableBillFinanceAmount1) }}</template>
          </el-table-column>
          <el-table-column label="预计到帐金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableBillFinanceAmount2) }}</template>
          </el-table-column>
          <el-table-column prop label="审批状态" width="100"></el-table-column>
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">转让</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">融资</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "资金机构",
          type: "input",
          field: "supplierName",
          size: "small"
        },
        {
          label: "审批状态:",
          type: "select",
          field: "state",
          options: [
            { label: "待内部复核", value: "1" },
            { label: "待平台审核", value: "2" },
            { label: "待资方审核", value: "3" },
            { label: "审批完成", value: "4" },
            { label: "全部", value: "5" }
          ],
          size: "small"
        },
        {
          label: "融资金额区间: ",
          type: "inputrange",
          field: "payableAmountStr",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "payableAmountEnd",
          size: "small"
        },
        {
          label: "预估融资利率(%/年化)区间: ",
          type: "inputrange",
          field: "min",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "max",
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: []
    };
  },
  created() {
  },
  methods: {
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    moment: moment,
    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.corpId = "1";
      api.FinanceList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      console.log("点击了详情 ==》", row);
      this.$router.push({
        path: "/easypay/easypayfirstdetial",
        query: { id: row.payableBillId }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
