<template>
    <div>
        <!-- 这里是企业名录列表内容。 -->
        <layout-main-view
            :breadcrumb="breadcrumblist"
            :tabOptions="tabOptions"
            @tabclick="tabclick"
            >
            <coreBusiness v-if="showtab == 1" key="1"></coreBusiness>
            <generalBusiness v-if="showtab == 2" key="2"></generalBusiness>
        </layout-main-view>
    </div>
</template>

<script>
import coreBusiness from "./coreBusiness.vue";
import generalBusiness from "./generalBusiness.vue";
export default {
    components: {
        "coreBusiness": coreBusiness,
        'generalBusiness':generalBusiness,
    },
    data(){
        return{
            breadcrumblist: ["企业客户管理"],
            showtab: 1,
            tabOptions: [
                { label: "授信企业", value: "1", path: "/fund/businessDirectory/businessMange/1" },
                { label: "融资企业", value: "2", path: "/fund/businessDirectory/businessMange/2" },
            ],
            activeName: "first",
            total: 0,
            listLoading: false,
            listData: [],
            handleEditShow: false
        }
    },
    created() {

    },
    mounted(){
        this.showtab = this.$route.params.state;
    },
    methods: {
        tabclick(val) {
            this.showtab = val;
            this.handleEditShow = false;
        },
    }
}
</script>

<style>

</style>