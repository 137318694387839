<template>
  <div class="verify-result">
    <el-result v-bind="result">
      <template slot="extra">
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  computed: {
    result() {
      const { code, subCode, msg, subMsg } = this.$route.query
      if (code === '0') {
        return {
          icon: 'success',
          title: '人脸识别完成',
          subTitle: '人脸识别完成，请在电脑端刷新页面查看结果！'
        }
      }
      return {
        icon: 'error',
        title: msg,
        subTitle: `${subMsg}，请回到电脑端重新扫码识别。`
      }
    }
  }
}
</script>

<style lang="scss">
.verify-result {
  margin: 0 auto;
  width: 80vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>