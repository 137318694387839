<!-- 票据详情 -->
<template>
    <div class="bill-approvaled-details">
        <layout-main-view :breadcrumb='breadcrumblist'>
                
            <bill-details :detailsList='detailsList' ref="dtil" v-on:pmsg='pmsg'>
                      <div class="approval-result marbotm0" >
                <div class="approval-title">审批结果</div>
                <div class="approval-cont">
                    <div>
                        <i v-if="billjson.approveResult == 1" class="el-icon-circle-check"></i>
                        <i v-else class="el-icon-circle-close"></i>
                        <span>{{$route.query.billApproveStatusShow}}</span>
                    </div>
                    <div>
                        <span>结果：</span>
                        <span>{{billjson.approveResult == 1?"通过":"退回" }}</span>
               
                 
                    </div>
                    <div v-if="billjson.approveResult !== 1">
                        <span>退回理由：</span>
                        <span>{{billjson.reason}}</span>
                    </div>
                    <div>
                        <span>审批人：</span>
                        <span>{{billjson.operatorName}}</span>
                    </div>
                    <div>
                        <span>审批时间：</span>
                        <span>{{billjson.createTime | formatDate }}</span>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                    <span>备注：</span>
                    <span>{{billjson.remark?billjson.remark:'无'}}</span>
                </div>
            </div>

            </bill-details>
            <div class="footer-btn">
                <el-button type="primary" @click="agree">返回</el-button>
 
            </div>
        </layout-main-view>
  

    </div>
</template>
<script>
    import api from "@/api/api";
    import moment from 'moment'
    import billDetails from '@/components/billDetails' 
    export default {
        data() {
            return {
                breadcrumblist:['票据报价审批'],
                detailsList: {},
                approvalVisible: false,
                regionList: [],
                approvalForm: {
                    billId: this.$route.query.id,
                    approveResult: '',
                    reason: '',
                    refuseReason: '',
                    remark: '',
                    approveNode: '',
                },
                active: 0,
                approvalDetailsList: {
                    approveResult:''
                },
                agreedetail:{
                       billId:this.$route.query.id,
                       approveResult:1,
                       remark:'',
                       approveNode:20,

                   },
                   refusedetail:{

                   },
                showAddDialog:false,
                showAddDialog2:false,
                refuselist:[],
                billjson:{},
                agreeinfo:{
                    approveResult:1
                }
            }
        },
        created() {
            this.init();
            api.GetApproveListByBillId({billId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.approvalDetailsList = res.datas.billApproveDtos[0]
                }
            })
            this.getbillApproveDetail()
         
        },
        mounted(){
         

        },
         watch:{
             billjson: {
                    immediate: true, // 很重要！！！
                    handler (val) {
                        if(this.billjson){
                                console.log(val,222)
                        }                    
                       
                    }
            }

        },
        filters: {
            formatDate: value => {
                return moment(value).format('YYYY-MM-DD');     
            }
        },
        methods: {
                  pmsg(val){
                    this.billjson=val
                  } , 
            init() {
                let id =  this.$route.query.id
                api.BillDetails({billId: id}).then(res => {
                    this.detailsList = res.datas.billDto
                    this.approvalForm.approveNode = this.detailsList.billApproveStatus
                    this.detailsList.billFile.forEach((item,index) => {
                        if(item.fileType == 2) {
                            this.detailsList.billFileBack = api.getImgURL(item.fileUrl)
                        }else {
                            this.detailsList.billFileFront = api.getImgURL(item.fileUrl)
                        }
                    })
                    this.detailsList.billFlaw = this.detailsList.billFlaw.split(',');
                    this.detailsList.hideStatus == 1?this.detailsList.hideStatus=false:this.detailsList.hideStatus=true
                }) 
            },
            agree() {              
                 history.go(-1)           
            },
          getbillApproveDetail(){

              var param={
                        billId:this.$route.query.id,
                      billQuoteApproveId:this.$route.query.billQuoteApproveId

              }
    
                  this.agreeinfo.approveResult=/通过/.test(this.$route.query.billApproveStatusShow)?1:3
                    this.agreeinfo.remark=this.$route.query.remark
                    this.agreeinfo.listingCorpName=this.$route.query.listingCorpName
             

          },
        
        },

  
        components: {
            'bill-details': billDetails
        }
    }
</script>
<style lang="scss">
    .bill-approvaled-details {
        .footer-btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
                &+.el-button {
                    margin-left: 40px;
                }
            }
        }
        .approval-result {
            padding: 33px 25px 0 25px;
         
            background: #fff;
     
            .approval-title {
                position: relative;
                font-size: 16px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                padding-left: 12px;
                margin-bottom: 24px;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -7px;
                    width: 3px;
                    height: 14px;
                    background: #CB8E51;
                }
            }
            .approval-cont {
                display: flex;
                justify-content: space-between;
                i {
                    color: #09BB07;
                }
            }
        }
        .success {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 100px 0;
            .center-cont {
                text-align: center;
                .approval-people,.approval-time {
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 15px;
                }
            }
            background: #fff;
            .el-steps {
                justify-content: center;
                .el-step__title {
                    &.is-success {
                        color: #2937A6;
                    }
                }
            }
            .el-icon-circle-check{
                font-size: 100px;
                color: #09BB07;
            }
            p {
                font-size: 20px;
                font-weight: 600;
                margin: 20px 0;
            }
            .el-button {
                width: 120px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin-top: 30px;
                text-align: center;
                &+.el-button {
                    margin-left: 50px;
                }
            }
        }
        .zjbjch{
            height: 100px;
            padding-left: 2em;
            background: #fff;
            padding-top: 10px;
            .el-row{
                margin-bottom: 20px;
            }
            .lab{
                display: inline-block;
                width: 140px;
            }
        }
    }
</style>