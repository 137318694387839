<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">

      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="billNo" label="票据编号" min-width="170"></el-table-column>
             <el-table-column prop="payableBillAmount" label="票据类型" min-width="120"  >
              <template slot-scope="scope">
              {{scope.row.billType|billType}}
            </template>
          </el-table-column>
            <el-table-column label="票面金额(元)" align='right' min-width="120">
              <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.billAmount) }}</template>
          </el-table-column>

          <el-table-column prop="approveNodeValus" label="退回节点" min-width="170"></el-table-column>
          <el-table-column prop="reason" label="退回理由" min-width="170"></el-table-column>

          <el-table-column prop="remark" label="备注" min-width="170"></el-table-column>

          <!-- <el-table-column prop="payableBillNo" label="退回理由" min-width="170"></el-table-column>
          <el-table-column prop="payableBillNo" label="备注" min-width="170"></el-table-column> -->
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [

      
         {
          label: "票据金额(元): ",
          type: "inputrange",
          field: "billAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "billAmountMax",
          size: "small"
        },
   
           {
          label: "票据类型: ",
          type: "select",
          field: "billType",
          size: "small",
          options:[{label:'商票',value:1},{label:'国股',value:2},{label:'城商',value:3},{label:'三农',value:4},{label:'财票',value:5}]

        },
            {
          label: "有无瑕疵: ",
          type: "select",
          field: "flawStatus",
          size: "small",
          options:[{label:'无瑕疵',value:1},{label:'有瑕疵',value:2}]

        },




      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      changeMoneyThousand:changeMoneyThousand
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
    param.openCorpId=JSON.parse(sessionStorage.user).relationCorpId
      api.getBillQuoteReturnList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
      });
    },
    handleEdit(row) {


           this.$router.push({
         path: "/bill/bill-approvaled-details",
                    query: { id: row.billId,isshowback:1,billFundStatus:3,billQuoteApproveId:row.billQuoteApproveId}
      });

    },
    statusfn(val){
      console.log(val,123)
      var state=''
      switch (val.status) {
        case 1:
          state='未确认'
          break;
        case 2:
          state='已确认'
          break;
        case 3:
          state='部分确认'
          break;
        case 4:
          state='挂起状态'
          break;
             case 5:
             state='无需清分'
          break;
      }
      return state
    }
  }
};
</script>


