<template>
  <div class="check-fail">
    <div class="header-img"></div>
    <div class="check-info">
      <el-steps :active="current" class="step-box" process-status="wait">
        <el-step :title="item.approveTypeName" description="" v-for="(item, index) in stepList" :key="index">
          <template slot="description" v-if="index < current">
            <div>审批结果：{{item.approveResult == 1 ? "确认无误" : "提出异议"}}</div>
            <div>经办人：{{item.approveOperatorName}}</div>
            <div>{{item.createTime ? moment(item.createTime).format('YYYY-MM-DD HH:mm'): ''}}</div>
          </template>
        </el-step>
      </el-steps>
      <div class="fail-info">
        <img src="../../assets/checkFail.png" class="fail-img"/>
        <div class="fail-text1">有审批方对清分提出异议</div>
        <div class="fail-text2">该审批暂时挂起，等待提出异议消除后再行审批</div>
      </div>
      <el-button class="button-back" size="small" @click="goback" >我知道了</el-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/api";

export default {
  data() {
    return {
      moment: moment,
      current: 0,
      stepList: [
        {approveTypeName: '企业审批', approveOperatorName: '', createTime: ''},
        {approveTypeName: '平台审批', approveOperatorName: '', createTime: ''},
        {approveTypeName: '资方审批', approveOperatorName: '', createTime: ''},
      ],
      type: this.$route.query.type,
      payableBillLiquidationId: this.$route.query.payableBillLiquidationId
    };
  },
  created() {
    this.getPayableBillLiquidationApprove()
  },
  mounted() {},
  methods: {
    goback() {
      history.go(-1);
    },
    // 获取审批记录
    getPayableBillLiquidationApprove() {
      api.getPayableBillLiquidationApprove({type: this.type, payableBillLiquidationId: this.payableBillLiquidationId}).then(res => {
        if(res.success) {
          this.current = res.datas.list.length
          let temp = [...res.datas.list,...this.stepList]
          let valueList = []
          let indexList = []
          temp.forEach((item, index) => {
            if(item.approveType == 1) {
              item.approveTypeName = '企业审批'
            } else if(item.approveType == 2) {
              item.approveTypeName = '平台审批'
            } else if(item.approveType == 3) {
              item.approveTypeName = '资方审批'
            }
            if(valueList.indexOf(item.approveTypeName) == -1) {
              valueList.push(item.approveTypeName)
              indexList.push(index)
            }
          }) 
          this.stepList = [temp[indexList[0]], temp[indexList[1]], temp[indexList[2]]]
        }
      })
    }
  }
};
</script>
<style lang="scss">
  .check-fail {
    .el-steps {
      justify-content: center;
      .el-step__title {
        &.is-finish {
          color: #2F5597;
        }
      }
      .el-step__head.is-finish {
        color: #2F5597;
        border-color: #2F5597;
      }
      .el-step__description.is-finish {
        color: #000;
      }
    }
  }
</style>
<style lang='scss' scoped>
.check-fail {
  .header-img {
    width: 100%;
    height: 216px;
    background: url(../../assets/checkResult.png) center no-repeat;
    background-size: 100%;
  }
  .check-info {
    position: relative;
    top: -120px;
    margin: 0 auto;
    background-color: #fff;
    width: 720px;
    padding: 24px;
    box-shadow:0px 4px 12px rgba(29,29,57,0.1);
    border-radius: 8px; 
    .fail-info {
      text-align: center;
      margin: 20px auto 30px;
    }
    .fail-img {
      display: block;
      width: 180px;
      height: 180px;
      margin: 0 auto 10px;
    }
    .fail-text1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color:rgba(0,0,0,0.85);
    }
    .fail-text2 {
      font-size: 14px;
      line-height: 22px;
      color:rgba(0,0,0,0.65);
    }
    .button-back {
      display: block;
      width: 160px;
      height: 40px;
      margin: 0 auto 50px;
      font-size:16px;
      background: #2937A6;
      color:#ffffff;
    }
  }
}
</style>
