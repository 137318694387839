<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">

      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column prop="fundCorpName" label="资金机构" min-width="170"></el-table-column>
          <el-table-column label="融资金额(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.financeAmount) }}</template>
          </el-table-column>
          <el-table-column prop="financeInterest" label="融资年化利率(％)" min-width="150"></el-table-column>
          <el-table-column prop="receivedAccountNum" label="应收账款数量" min-width="150"></el-table-column>
          <el-table-column
            label="补件状态"
            min-width="150"
            :formatter="el=>financeSupplyStatusfn(el.financeSupplyStatus)"
          >
          </el-table-column>
          <el-table-column
            label="确权状态"
            min-width="150"
            :formatter="el=>corpAckStatusfn(el.corpAckStatus)"
          ></el-table-column>
          <el-table-column label="操作" width="240">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.corpAckStatus == 2"
                size="medium"
                type="text"
                @click="submitConfirmationApply(scope.row)"
              >确权申请</el-button>

              <el-button

                size="medium"
                type="text"
                @click="handlefinanceSupplyStatuscomp(scope.row)"
              >详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api.js";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
     filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "receivableAccountNo",
          size: "small"
        },
        {
          label: "债权企业:",
          type: "input",
          field: "financeCorpName",
          size: "small"
        },
        {
          label: "融资金额(元): ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small"
        },
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,

      //易保理待补件页面中判断当前用户是哪种用户。
      coryType:'',

    };
  },
  created() {
    this.activeName = this.$route.params.state;

    this.coryType = JSON.parse(sessionStorage.getItem("user")).corpType
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      filters.state = this.$route.params.state;
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.review = 2,
      param.source = 1,
      param.financePayStatus = 1,
      api.getManageCompletedListPage(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      var isshowbtn;
      if(row.sign == 2){
          isshowbtn = 1;
      }else {
        isshowbtn = 2;
      }
      this.$router.push({
        path: "/financingProjectManage/easypaySign/SignDetial",
        query: {payableBillFinanceId: row.payableBillFinanceId,
        payableBillFinanceNo: row.payableBillFinanceNo,
        financeCorpId: row.financeCorpId,
        isshowbtn: isshowbtn,
        }
      });
    },


    handlefinanceSupplyStatuscomp(val){
          this.$router.push({
        name: "补件详情查看",
        query: { id: val.receivableAccountFinanceId,financeSupplyStatus: val.financeSupplyStatus }
      });
    },

    submitConfirmationApply(val) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      api.submitConfirmationApply({
          receivableAccountFinanceId: val.receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.$alert("请求确权成功，请等待债务方确权审核");
            loading.close();
            this.$router.push({ path: this.$route.path });
          } else {
            this.$message.error(res.message);
            loading.close();
          }
        })
        .catch(err => {
          loading.close();
        });
    },
    // corpAckStatusfn(val){
    //   var str='';
    //    switch (val.corpAck) {
    //      case 1:
    //        str='初始状态'
    //        break;
    //    case 2:
    //        str='待确权'
    //        break;
    //       case 3:
    //        str='已确权'
    //        break;
    //       case 4:
    //        str='无需确权'
    //        break;
    //        case 5:
    //        str='部分确权'
    //        break;
    //        case 6:
    //        str='确权申请审核中'
    //        break;
    //    }
    // },

    corpAckStatusfn(val) {
      var str = "";
      switch (val) {
        case 1:
          str = "初始状态";
          break;
        case 2:
          str = "待确权";
          break;
        case 3:
          str = "已确权";
          break;
        case 4:
          str = "无需确权";
          break;
        case 5:
          str = "部分确权";
          break;
        case 6:
          str = "确权申请审核中";
          break;
      }

      return str;
    },

    financeSupplyStatusfn(val) {
      var str = "";
      switch (val) {
        case 1:
          str = "初始状态";
          break;
        case 2:
          str = "待补件";
          break;
        case 3:
          str = "已补件";
          break;
        case 4:
          str = "无需补件";
          break;
        case 5:
          str = "部分补件";
          break;
      }
      return str;
    },
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
