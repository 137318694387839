<template>
  <div>
    <div class="section-second">
      <div class="line">优企通业务总览</div>
      <div class="section-second-content">
        <div class="card-item card-bgImg1">
          <div class="card-money card-money1">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{ $changeMoneyThousand(censusList.creditAmount) }}</span>
              <!--<span class="title-color" style="font-size: 14px;">元</span>-->
            </div>
          </div>
          <div class="card-title">总授信额度(元)</div>
        </div>
        <div class="card-item card-bgImg2">
          <div class="card-money card-money2">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{$changeMoneyThousand(censusList.openAmount) }}</span>
              <!--<span class="title-color" style="font-size: 14px;">元</span>-->
            </div>
          </div>
          <div class="card-title">总开立金额(元)</div>
        </div>
        <div class="card-item card-bgImg3">
          <div class="card-money card-money3">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{ $changeMoneyThousand(censusList.canOpenAmount)}}</span>
              <!--<span class="title-color" style="font-size: 14px;">元</span>-->
            </div>
          </div>
          <div class="card-title">可开立额度(元)</div>
        </div>
        <div class="card-item card-bgImg4">
          <div class="card-money card-money4">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{ $changeMoneyThousand(censusList.holdAvailableAmount) }}</span>
              <!--<span class="title-color" style="font-size: 14px;">元</span>-->
            </div>
          </div>
          <div class="card-title">总持有金额(元)</div>
        </div>
      </div>
    </div>
    <div class="section-f">
      <div class="line">核心数据统计</div>
      <div class="echarts-box">
        <div class="section-four">
          <div class="message">
            <template>
              <el-radio v-model="radio" label="1">
                优企通开立 Top10
                <el-tooltip placement="top-start">
                  <div slot="content">
                    本视图是按照企业和时间维度统计开立优企通
                    <br />的累计额度，其中包含已清分额度。
                  </div>
                  <div class="tip-content">?</div>
                </el-tooltip>
              </el-radio>
              <el-radio v-model="radio" label="2">
                优企通接收 Top10
                <el-tooltip placement="top-start">
                  <div slot="content">
                    本视图是按照企业和时间维度统计开立优企通
                    <br />的累计额度，其中包含已清分额度。
                  </div>
                  <div class="tip-content">?</div>
                </el-tooltip>
              </el-radio>
            </template>
            <span style="float: right;padding-right: 20px">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{getNameByCode(selectCode)}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="item.code"
                    v-for="(item, index) in option"
                    :key="index"
                  >{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
          <!-- <div id="drawLine" :style="{width: '100%', height: '400px'}"></div> -->
          <div>
            <v-chart :forceFit="true" :height="height" :data="data">
              <v-coord type="rect" direction="LB" />
              <v-tooltip />
              <v-axis data-key="country" :label="label" />
              <v-bar position="country*population" />
            </v-chart>
          </div>
        </div>
        <div class="section-four2">
          <div class="message2">
            <span>优企通融资总额</span>
            <span style="float: right;padding-right: 20px" class="quanbu">
              <el-dropdown @command="handleCommand2">
                <span class="el-dropdown-link">
                  {{getNameByCode(selectCode2)}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="item.code"
                    v-for="(item, index) in option"
                    :key="index"
                  >{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
          <!-- <div id="main" :style="{width: '100%', height: '400px'}"></div> -->
          <div>
            <v-chart :forceFit="true" :height="height1" :data="data1" :scale="scale1">
              <v-tooltip :showTitle="false" data-key="item*percent" />
              <v-axis />
              <v-legend data-key="item" />
              <v-pie position="percent" color="item" :v-style="pieStyle" :label="labelConfig" />
              <v-coord type="theta" />
            </v-chart>
          </div>
        </div>
      </div>
    </div>
    <layout-footer />
  </div>
</template>
<script>
const DataSet = require("@antv/data-set");

const sourceData = [
  { country: "中国", population: 131744 },
  { country: "印度", population: 104970 },
  { country: "美国", population: 29034 },
  { country: "印尼", population: 23489 },
  { country: "巴西", population: 18203 }
];

const dv = new DataSet.View().source(sourceData);
dv.transform({
  type: "sort",
  callback(a, b) {
    return a.population - b.population > 0;
  }
});
const data = dv.rows;

const label = { offset: 12 };

const sourceData1 = [
  { item: "事例一", count: 40 },
  { item: "事例二", count: 21 },
  { item: "事例三", count: 17 },
  { item: "事例四", count: 13 },
  { item: "事例五", count: 9 }
];

const scale1 = [
  {
    dataKey: "percent",
    min: 0,
    formatter: ".0%"
  }
];

const dv1 = new DataSet.View().source(sourceData1);
dv1.transform({
  type: "percent",
  field: "count",
  dimension: "item",
  as: "percent"
});
const data1 = dv1.rows;

import api from "./../../api/api";
import echarts from "echarts";
// let echarts = require("echarts/lib/echarts");
// require("echarts/lib/chart/funnel");
// require("echarts/lib/chart/bar");
// 引入提示框和title组件
// require("echarts/lib/component/tooltip");
// require("echarts/lib/component/title");
import { convertCurrency } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import LayoutFooter from "@/components/layout-footer";
// const corpId = JSON.parse(sessionStorage.getItem("user")).corpId;
export default {
  components: {
    "layout-footer": LayoutFooter,
  },
  data() {
    return {
      radio: "1",
      corpList: {},
      censusList: {},
      financeCensusData: {},
      option: [],
      selectCode: "4",
      selectCode2: "4",
      chartOption: [],
      chartOption2: [],
      data,
      height: 400,
      label: label,

      data1,
      scale1,
      height1: 400,
      pieStyle: {
        stroke: "#fff",
        lineWidth: 1
      },
      labelConfig: [
        "percent",
        {
          formatter: (val, item) => {
            return item.point.item + ": " + val;
          }
        }
      ]
    };
  },
  created() {},
  mounted() {
    this.GetCensus();
    this.ReceiveCensus();
    this.OpenCensus();
    this.FinaceCensus();
    this.GetDicListByType();
  },
  watch: {
    selectCode() {
      this.ReceiveCensus();
      this.OpenCensus();
    },
    selectCode2() {
      this.FinaceCensus();
    },
    radio() {
      this.ReceiveCensus();
      this.OpenCensus();
    }
  },
  methods: {
    GetCensus() {
      api.GetCensus().then(res => {
        if (res.success) {
          this.censusList = res.datas.response;
        }
      });
    },
    ReceiveCensus() {
      if (this.radio === "1") {
        return;
      }
      api.ReceiveCensus({ searchType: this.selectCode }).then(res => {
        if (res.success) {
          this.chartOption = res.datas.response;
          this.drawLine();
        }
      });
    },
    OpenCensus() {
      if (this.radio === "2") {
        return;
      }
      api.OpenCensus({ searchType: this.selectCode }).then(res => {
        if (res.success) {
          this.chartOption = res.datas.response;
          this.drawLine();
        }
      });
    },
    FinaceCensus() {
      api.FinaceCensus({ searchType: this.selectCode2 }).then(res => {
        if (res.success) {
          const RES = res.datas.response;
          this.chartOption2 = [
            { name: "放款金额", value: RES.loanAmount },
            {
              name: "优企通融资金额",
              value: RES.payableBillAmount
            },
            {
              name: "融资利息",
              value: RES.loanServiceFee
            },
            {
              name: "平台总服务费",
              value: RES.platformServiceFee
            }
          ];
          this.mainLine();
        }
      });
    },
    GetDicListByType() {
      api.getDicListByType({ type: 5 }).then(res => {
        if (res.success) {
          this.option = res.datas.list.map(val => ({
            name: val.name,
            code: val.code
          }));
        }
      });
    },
    handleCommand(command) {
      this.selectCode = command;
    },
    handleCommand2(command) {
      this.selectCode2 = command;
    },
    getNameByCode(code) {
      let option = this.option.find(val => val.code === code) || {};
      return option.name;
    },

    drawLine() {
      let chartPie = echarts.init(document.getElementById("drawLine"));
      chartPie.setOption({
        title: {},
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "7%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: this.chartOption.map(val => val.corpName)
        },
        series: [
          {
            name: "2012年",
            type: "bar",
            barWidth: 30,
            data: this.chartOption.map(val => val.amount)
          }
        ],
        itemStyle: {
          normal: {
            color: "#1890FF"
          }
        }
      });
    },
    mainLine() {
      let chartPie = echarts.init(document.getElementById("main"));
      chartPie.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            selectedMode: "single",
            radius: [0, "30%"],

            label: {
              normal: {
                position: "inner"
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            }
          },
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "55%"],
            data: this.chartOption2
          }
        ]
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.tip-content {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid rgb(170, 170, 170);
  color: rgb(170, 170, 170);
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}
.echarts-box {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 49%;
  }
  & > div:nth-child(1) {
    border-right: 1px solid #e6e6e6;
  }
}
.section-top {
  margin-left: 100px;
  margin-top: 20px;
  width: 80%;
  background-color: white;
  width: 1100px;
  height: 176px;
}
.section-top-content {
  float: left;
  width: 50%;
  height: 100%;
  background-color: white;
}
.section-top-content-left {
  float: left;
  width: 20%;
  height: 100%;
  background-color: white;
  .image-head-portrait {
    margin-left: 24px;
    margin-top: 32px;
    width: 64px;
    height: 64px;
    background-image: url("../../assets/workbench/rzqy.png");
    background-size: 100% 100%;
    flex: 1;
    position: relative;
  }
  .company-vertity {
    margin-left: 24px;
    margin-top: 0px;
    width: 64px;
    height: 64px;
    background-image: url("../../assets/workbench/hxqy.png");
    background-size: 100% 100%;
    flex: 1;
    position: relative;
  }
}

.section-top-content-right {
  float: left;
  width: 80%;
  height: 100%;
  background-color: white;

  .tag {
    margin-top: 10px;
    margin-left: 10px;
  }
  .vertity {
    margin-top: 60px;
    margin-left: 10px;
  }
}
.title {
  margin-top: 32px;
  margin-left: 10px;
  font-size: 23px;
  font-family: Helvetica;
}
.section-top-content-money {
  float: left;
  width: 100%;
  height: 100%;
  background-color: white;
}
.money {
  margin-left: 10px;
  width: 100%;
  .money-item {
    float: left;
    width: 32%;
    height: 100px;
    background-color: white;
    .money-item-title {
      margin-top: 20px;
      color: #000000;
      font-size: 14px;
      .title-color {
        color: #2937A6;
      }
    }
  }
}
.money-margin {
  margin-left: 24px;
}
h3 {
  padding: 10px 24px 10px 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.message {
  padding: 10px 24px 10px 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.message2 {
  padding: 10px 24px 10px 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.line {
  /*border-bottom: 1px solid #e6e6e6;*/
  padding: 10px 24px 10px 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  font-weight: bold;
}
.section-second {
  margin-left: 100px;
  margin-top: 20px;
  width: 100%;
  background-color: white;
  height: 218px;
  // width: 1100px;

  .section-second-content {
    height: 75%;
    background-color: white;
    .card-item {
      width: 24%;
      height: 65%;
      /*margin-left: 24px;*/
      margin-top: 30px;
      /*float: left;*/
      .card-title {
        margin-left: 0.8%;
        /*margin-top: 24px;*/
        font-size: 14px;
        padding-left: 40px;
        /*color: #fff;*/
      }
      .card-money1 {
        background-image: url("../../assets/zsx.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money2 {
        background-image: url("../../assets/zkl.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money3 {
        background-image: url("../../assets/kkl.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money4 {
        background-image: url("../../assets/kcy.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money {
        font-size: 30px;
        color: #262626;
        line-height: 45px;
        margin-left: 14px;
        margin-top: 14px;

        /*color: #fff;*/
        .money-bold {
          font-family: HelveticaNeue-Medium;
        }
      }
    }
  }
}
.section-three {
  margin-left: 100px;
  margin-top: 20px;
  width: 80%;
  background-color: white;
  height: 286px;
  // width: 1100px;

  .section-three-left {
    width: 54%;
    height: 100%;
    float: left;
    background-color: white;
  }
  .section-three-right {
    float: left;
    margin-left: 20px;
    width: 44%;
    height: 100%;
    background-color: white;
    .notice-margin {
      margin-left: 24px;
      margin-top: 10px;
      clear: both;
      height: 20px;
      line-height: 20px;
      text-align: center;
    }
  }
}
.section-f {
  margin-top: 20px;
  margin-left: 32px;
  // width: 1100px;
  width: 95%;
  height: 527px;
  background: white;
}
.section-four {
  margin-top: 20px;
  width: 50%;
  height: 463px;
}
.clear {
  clear: both;
}
.section-four2 {
  margin-top: 20px;
  width: 50%;
  height: 463px;
}
.section-five {
  margin-left: 100px;
  margin-top: 20px;
  // width: 1100px;
  background-color: white;
  height: 513px;
}
.fl {
  float: left;
}

.section-top {
  margin-left: 32px;
  margin-top: 20px;
  width: 80%;
  background-color: white;
  // width: 1100px;
  height: 176px;
}
.section-top-content {
  float: left;
  width: 50%;
  height: 100%;
  background-color: white;
}
.section-top-content-left {
  float: left;
  width: 20%;
  height: 100%;
  background-color: white;
  .image-head-portrait {
    margin-left: 24px;
    margin-top: 32px;
    width: 64px;
    height: 64px;
    background-image: url("../../assets/workbench/rzqy.png");
    background-size: 100% 100%;
    flex: 1;
    position: relative;
  }
  .company-vertity {
    margin-left: 24px;
    margin-top: 0px;
    width: 64px;
    height: 64px;
    background-image: url("../../assets/workbench/hxqy.png");
    background-size: 100% 100%;
    flex: 1;
    position: relative;
  }
}

.section-top-content-right {
  float: left;
  width: 80%;
  height: 100%;
  background-color: white;

  .tag {
    margin-top: 10px;
    margin-left: 10px;
  }
  .vertity {
    margin-top: 60px;
    margin-left: 10px;
  }
}
.title {
  margin-top: 32px;
  margin-left: 10px;
  font-size: 23px;
  font-family: Helvetica;
}
.section-top-content-money {
  float: left;
  width: 100%;
  height: 100%;
  background-color: white;
}
.money {
  margin-left: 10px;
  width: 100%;
  .money-item {
    float: left;
    width: 32%;
    height: 100px;
    background-color: white;
    .money-item-title {
      margin-top: 20px;
      color: #000000;

      font-size: 14px;
      .title-color {
        color: #2937A6;
      }
    }
  }
}
.money-margin {
  margin-left: 24px;
}
.line {
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 24px 10px 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  font-weight: bold;
}
.section-second {
  margin-left: 32px;
  margin-top: 20px;
  width: 95%;
  background-color: white;
  height: 218px;
  // width: 1100px;

  .section-second-content {
    height: 75%;
    background-color: white;
    .card-item {
      width: 22%;
      height: 65%;
      margin-left: 24px;
      margin-top: 30px;
      float: left;
      .card-title {
        margin-left: 8px;
        /*margin-top: 24px;*/
        font-size: 14px;
        color: #fff;
      }
      .card-money {
        margin-left: 8px;
        margin-top: 14px;
        font-size: 14px;
        color: #fff;
        .money-bold {
          font-family: HelveticaNeue-Bold;
        }
      }
    }
  }
}
.card-bgImg1 {
  background-image: url("../../assets/workbench/zsxeu.png");
  background-size: 100% 100%;
}
.card-bgImg2 {
  background-image: url("../../assets/workbench/zjded.png");
  background-size: 100% 100%;
}
.card-bgImg3 {
  background-image: url("../../assets/workbench/yyjded.png");
  background-size: 100% 100%;
}
.card-bgImg4 {
  background-image: url("../../assets/workbench/yysxed.png");
  background-size: 100% 100%;
}

.section-three {
  margin-left: 32px;
  margin-top: 20px;
  width: 80%;
  background-color: white;
  height: 286px;
  // width: 1100px;

  .section-three-left {
    width: 54%;
    height: 100%;
    float: left;
    background-color: white;
  }
  .section-three-right {
    float: left;
    margin-left: 20px;
    width: 44%;
    height: 100%;
    background-color: white;
    .notice-margin {
      margin-left: 24px;
      margin-top: 10px;
      clear: both;
      height: 20px;
      line-height: 20px;
      text-align: center;
    }
  }
}
.section-four {
  background-color: white;
  height: 463px;
}
.section-five {
  margin-left: 32px;
  margin-top: 20px;
  // width: 1100px;
  background-color: white;
  height: 513px;
}
.fl {
  float: left;
}
</style>
