<!-- 票据审批已通过 -->
<template>

    <div class="bill-approvaled">
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                :filters="filterList"
                :isShowAll="isShowAll"
                :isHedden="isHedden"
                @data-showAll="filterShowAll"
                @data-refresh="handleRefresh"
                :total="total"
                ref="datalist"
            >
                <el-table
                    :data="listData"
                    :header-cell-style="headerStyle"
                    highlight-current-row
                    v-loading="listLoading"
                    style="width: 100%;"
                    >
                    <el-table-column prop="billNo" label="票据编号" min-width="120"></el-table-column>
                    <el-table-column prop="billType" label="票据类型" min-width="120">
                        <template slot-scope="scope">{{scope.row.billType | formatType}}</template>
                    </el-table-column>
                         <el-table-column prop="billAmount" label="票面金额(元)" min-width="160">
                <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.billAmount) }}</template>

                         </el-table-column>
                    <el-table-column prop="acceptor" label="承兑人" min-width="120"></el-table-column>
                    <el-table-column label="有无瑕疵"  width="120" >
                        <template slot-scope="scope">{{scope.row.flawStatus==1?'无':'有'}}</template>
                    </el-table-column>
                             <el-table-column label="报价每十万元扣费(元)" width="190" align='right'  >
                             <template slot-scope="scope">
                                 {{getnewprice(scope.row,'chargeAmount')}}
                            <!-- {{$changeMoneyThousand(JSON.parse(scope.row.billFundQuotes))}} -->
                                </template>
                      </el-table-column>
                    <el-table-column prop="fundYearInterest" label="报价成交利率(%)"  width="160">
                        <template slot-scope="scope">
                            {{getnewprice(scope.row,'offerPriceYearInterest')}}
                  
                        </template>

                    </el-table-column>

                   <el-table-column prop="billApproveStatusShow" label="审批状态"  width="140">


                    </el-table-column>

                    <el-table-column label="操作" width="120" align="left">
                        <template slot-scope="scope">
                            <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
    </div>
 
</template>
<script>
    import api from "@/api/api";
    import {changeMoneyThousand} from '@/util/common'
    import moment from 'moment'
    export default {
  
        data() {
            return {
                isShowAll: false,
                isHedden: false,
                activeName: '2',
                filterList: [
              
                    {
                        label: "承兑人: ",
                        type: "input",
                        field: "acceptorSearch",
                        size: "small",
                    },
                    {
                        label: "票据类型: ",
                        type: "select",
                        field: "billType",
                        size: "small",
                        options:[{label:'商票',value:1},{label:'国股',value:2},{label:'城商',value:3},{label:'三农',value:4},{label:'财票',value:5}]
                    },
                    {
                        label: "票面金额: ",
                        type: "inputrange",
                        field: "billAmountMin",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "billAmountMax",
                        size: "small"
                    },
                ],
                listData: [],
                total: 0,
                listLoading: false,
                listData: [],
                changeMoneyThousand:changeMoneyThousand
            }
        },
        filters: {
            formatType: value => {
                switch(value) {
                case 1:
                    value = '商票'
                    break;
                case  2:
                    value = '国股'
                    break;
                case  3:
                    value = '城商'
                    break;
                case  4:
                    value = '三农'
                    break;
                case  5:
                    value = '财票'
                    break;
                }
                return value;
            }
        },
        methods: {
            moment: moment,
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
            },
            headerStyle({ row, rowIndex }) {
                return { background: "#FAFAFA" };
            },
            handleRefresh(filters) {
                this.getLists(filters);
            },
            // 查询
            getLists(param) {
                       switch (JSON.parse(sessionStorage.user).roleCodes[0]) {
                            case 'fund_first':

                             param.approveNode=1
                            break;
                                case 'fund_second':

                             param.approveNode=2

                            break;
                                case 'fund_third':

                             param.approveNode=3
                            break;
                                        default:
                            param.approveNode=1;

                            break;

                        }

                api.pendingApprovaled(param).then(res => {
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(row) {
                this.$router.push({
                    name: "审批详情摘牌",
                    query: { id: row.billId,billApproveStatusShow:row.billApproveStatusShow,remark:row.remark,listingCorpName:row.listingCorpName,billQuoteApproveId:row.billQuoteApproveId,isshowback:1,billFundStatus:2}
                });
            },
            getnewprice(row,val){
    
                    if(row.billFundQuotes){
                          return    this.$changeMoneyThousand(JSON.parse(row.billFundQuotes)[val])
                    }else{
                        return  this.$changeMoneyThousand(row[val])
                    }
            }
        }
    }
</script>
<style lang="scss">
</style>
