<template>
  <div>
    
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <ing-financing-sign v-if="showtab == 1"></ing-financing-sign>
      <waitsign  v-if="showtab == 5" />
      <stay-financing-sign  v-if="showtab == 2"></stay-financing-sign>
      <already-financing-sign  v-if="showtab == 3"></already-financing-sign>

      <back-financing-sign  v-if="showtab == 4"></back-financing-sign>
    </layout-main-view>
  </div>
</template>
<script>
import {} from "../../../api/api";
import ingFinancingSign from "./ingFinancingSign";
import waitsign from "./waitsign.vue";

import StayFinancingSign from "./stayFinancingSign.vue";
import AlreadyFinancingSign from "./alreadyFinancingSign.vue";
import backFinancingSign from "./backFinancingSign";


export default {
  components: {
    "ing-financing-sign":ingFinancingSign,
    "waitsign": waitsign,

    "stay-financing-sign": StayFinancingSign,
    "already-financing-sign": AlreadyFinancingSign,
    "back-financing-sign":backFinancingSign
  },
  data() {
    return {
      breadcrumblist: ["融资项目管理"],
      showtab: 0,
      tabOptions: [
        { label: "融资中", value: "1", path: "/financingProjectManage/easypaySign/financingSign/1" },
        { label: "签约待确认", value: "5", path: "/financingProjectManage/easypaySign/financingSign/5" },
        { label: "待签约", value: "2", path: "/financingProjectManage/easypaySign/financingSign/2" },
        { label: "已签约", value: "3", path: "/financingProjectManage/easypaySign/financingSign/3" },
        { label: "已退回", value: "4", path: "/financingProjectManage/easypaySign/financingSign/4" },

      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: []
    };
  },
    mounted(){
        this.showtab = this.$route.params.state;
        console.log('this.showtab ===>',this.showtab);
    },
  methods: {
    tabclick(val) {
      this.showtab = val;
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
