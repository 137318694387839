<template>
<div>
  <div class="easypayby bl">
    <div class="yfdtwo">
      <div class="yfdsize" style="font-weight:bold;">审批结果</div>
      <div class="formstyle">
        <el-form :model="result" ref="result" label-width="198px" class="demo-ruleForm keytext">
          <el-row :gutter="20">
            <el-col :span="8">
              <span class="info-key">审批结果: </span>
              <span class="info-value index">{{result.approveNodeValus}}</span>
            </el-col>
            <el-col :span="8">
              <span class="info-key">审批人: </span>
              <span class="info-value index">{{result.approvePerson}}</span>
            </el-col>
            <el-col :span="8">
              <span class="info-key">审批时间: </span>
              <span class="info-value index">{{result.approveTime ? moment(result.approveTime).format('YYYY-MM-DD HH:mm'): ''}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" v-if="result.approveResult == 2">
              <span class="info-key">拒绝原因: </span>
              <span class="info-value index">{{result.reason}}</span>
            </el-col>
            <el-col :span="16">
              <span class="info-key">备注: </span>
              <span class="info-value index">{{result.approveRemark}}</span>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import moment from 'moment'

export default {
  data() {
    return {
      moment:moment,
      result: {
        approveNodeValus: this.$route.query.approveNodeValus,
        approveResult: this.$route.query.approveResult,   // 1通过 2退回
        approvePerson: this.$route.query.approvePerson,
        approveTime: this.$route.query.approveTime,
        approveRemark: this.$route.query.approveRemark,
        reason: this.$route.query.reason
      }
    };
  },
  created() {
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.yfdbaseline{
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby{
  padding-bottom: 6px;
}
.yfdtwo{
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner{
    color: rgb(96,98,102)
  }
}
.httotal{
  height: 35px;
  background: #FFF3F3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span{
    color: #DA2B2B;
    line-height: 22px;
  }
}
.formstyle{
  &>div{
    margin-bottom: 20px;
  }
  .el-select,.el-date-editor{
    width: 100%;
  }
  .elspan{
    .el-input__inner{
      border-radius: 4px 0 0 4px;
    }
    .el-input{
      width: 80%;
    }
    .el-form-item__content{
      span{
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #F7F7F7;
        border: 1px solid #D9D9D9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan{
    .el-input{
        width: 90%;
    }
    .el-form-item__content{
      span{
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper{
    input[type=text]{
      border:1px solid #fff;
    }
    .el-table__row{
      input{
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color:#fff !important;
  }
  .htbox{
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    .htpad{
      padding:0 17px;
      form{
        overflow: hidden;
      }
    }
    .htactive{
      height: 43px;
      overflow: hidden;
      background: #F1F1F1;
      line-height: 43px;
      span{
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno{
      height: 90px;
      border-bottom: 1px solid #E6E6E6;
      padding-top: 25px;
    }
    .uploadfont{
      color: rgba(0,0,0,0.45);
      line-height: 42px;
      span{
        text-indent:8em;
        display: inline-block;
      }
    }
    .uploadlist{
      padding: 30px 16px 0 16px;
      .upload-demo{
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item{
        margin-bottom: 40px;
      }
      .el-form-item__label{
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger{
        width: 300px;

      }
      .el-upload-dragger .el-upload__text,.el-icon-plus{
        color: rgba(0,0,0,0.45);
      }
      .el-icon-plus{
        font-size: 30px;
      }
    }
    .el-icon-delete{
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje{
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span{
        color: #F5212D
      }
    }
  }
}
.easyformbtn{
  text-align: center;
  button{
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span{
      color: #FFFFFF;
    }
  }
  button:nth-last-child(1){
    background-color:#2937A6
  }
  button:nth-last-child(2){
    span{
      color: #595959;
    }
  }
  button:hover{
    background-color:#edf5ff;
    span{
      color: #67a9fd;
    }
  }
}
.formstyle{
  .active{
    height: 133px;
    overflow: hidden;
  }
  .iconactive{
    transform: scaleY(-1);
  }
}
.addbtn{
  border: 1px dashed #CFCFCF;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i{
    margin-right: 6px;
  }
}
.yfdsize{
  font-size: 16px;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
  margin-bottom: 16px;
}
.wxts{
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16{
  position: relative;
  top: -16px;
}
.easypayby.bl{
  margin-top: 20px;
}
.htactive.bl{
  margin-bottom: 20px;
}
.marbtext{
  margin-bottom: 24px;
  font-size: 16px;
  color: #262626;
  font-weight: bold;
  span{
    opacity: .6;
  }
}
.bl{
  .numcolor{
    font-size: 18px;
    color: #F5212D;
  }
  img{
    margin-right: 1%;
  }
  .isdot{
    .el-badge__content.is-fixed{
      top:10px;
      right: 0px;
    }
    .el-badge__content{
      background-color: #ffcf04;
    }
  }
  .marbo22{
    margin-top: 22px;
  }
  .keytext .el-row{
    margin-bottom: 15px;
  }
  .info-key{
    padding-right: 10px;
    color: #262626;
  }
  .info-value{
    color: #595959;
  }
}
</style>
