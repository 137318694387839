<template>
  <layout-main-view
    :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-tabs type="border-card"  class="n-tabs">
        <data-list
          :filters="filterList"
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist">
          <el-button type="success" size='small' class="bladdbtn" @click="addrz">新增账款</el-button>
          <el-table :data="listData" align='center' highlight-current-row style="width: 100%;">
            <el-table-column prop="absAccountNo" label="账款编号" min-width="100">
            </el-table-column>
            <el-table-column prop="contractNo" label="合同编号" min-width="100"  >
            </el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="100"  >
            </el-table-column>
            <el-table-column prop="claimsCorpName" label="债权企业" min-width="100"  >
            </el-table-column>
            <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'>
            </el-table-column>
            <el-table-column prop="absAccountCloseDate" label="计划付款日" min-width="100">
              <template slot-scope="scope">
                {{moment(scope.row.absAccountCloseDate).format("YYYY-MM-DD")}}
              </template>
            </el-table-column>
            <el-table-column  label="账款类型"  prop='absAccountTypeShow' min-width="100" >
            </el-table-column>
            <el-table-column  label="账款状态"  prop='approveStatusShow' min-width="100" >
            </el-table-column>
            <el-table-column label="操作" width="120" align="left">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'
export default {
  data() {
    return {
      breadcrumblist: ["账款管理"],
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "计划付款日:",
          type: "daterange",
          field: ["absAccountCloseDateStart", "absAccountCloseDateEnd"],
          size: "small"
        },
        // {
        //   label: "账款类型:",
        //   type: "select",
        //   field: "absAccountType",
        //   size: "small",
        //   options: [
        //     {label:'应收账款',value:1},
        //     {label:'应付账款',value:2}
        //   ]
        // },
        // {
        //   label: "账单状态:",
        //   type: "select",
        //   field: "",
        //   size: "small",
        //   options: [
        //     {label:'',value:1}
        //   ]
        // },
        {
          label: "账款金额(元): ",
          type: "inputrange",
          field: "absAccountMinAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "absAccountMaxAmount",
          size: "small"
        },
      ],
      total: 0,
      listData: [],
    };
  },
  created() { },
  methods: {
    moment: moment,
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      api.getAbsAccountListPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEditinfo(row){
      let detailPage
      if(row.state == 'C') {
        // 作废
        detailPage = '账款详情'
      } else {
        if(row.approveStatus == 22 || row.approveStatus == 32) {
          detailPage = '退回账款详情'
        } else {
          detailPage = '账款详情'
        }
      }
      // isManage 是否是账款管理列表 1是
      let queryParams = { id: row.absAccountId,isshowbtn:2, approveStatus: row.approveStatus, isManage: 1 }
      this.$router.push({
        name: detailPage,
        query: queryParams
      });
    },
    addrz() {
      this.$router.push({name: '添加账款'})
    }
  }
};
</script>


