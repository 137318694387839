<!-- 票据详情 -->
<template>
    <div class="abs-xmgk-details">
      
            <div class="abs-title">项目概况: </div>
            <el-row >
                <el-col :span="8">
                    <span class="label">项目名称:</span>
                    <span class="value">{{detailsList.absPlanName}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">项目简称:</span>
                    <span class="value">{{detailsList.absPlanIntroduction}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">资产支持证券类别:</span>
                    <span class="value">{{assetsSupportBondTypefn(absPlanDetailDto.assetsSupportBondType)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">资产支持证券目标募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(detailsList.preAssertScaleAmount)}}</span>
                </el-col>
                  <el-col :span="8">
                    <span class="label">优先级募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.priorityLevelScale)}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">次优级募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.subPriorityLevelScale)}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">资产支持证券存续期:</span>
                    <span class="value">{{detailsList.cacheStoreExpire}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">资产支持证券预期收益率(%):</span>
                    <span class="value">{{detailsList.preProfitInterestMin+'-'+detailsList.preProfitInterestMax}}</span><el-tooltip placement="top">
                        <div slot="content" class="titlemsgabs"  >优先级资产支持证券的预期收益=资产支持证券本金金额×优先级资产支持证券对应的预期收益率×自专项计划设立日（含该日）起至该兑付日（不含该日）的实际天数÷365）<br/>次级资产支持证券不设置预期收益率。</div>
                                                                          <i class="el-icon-bell" style="color:orange;font-weight:bold" aria-hidden="true"></i>
                                                                        </el-tooltip>
                </el-col>
    
                
                      <el-col :span="8">
                    <span class="label">初始起算日:</span>
                   
                        <span>{{detailsList.collectStartDate|formatDate}}</span>
         
                    
                </el-col>
                   <el-col :span="8">
                    <span class="label">计划设立日:</span>
           
                        <span>{{detailsList.planFoundDate|formatDate}}</span>
          
                    
                </el-col>

                    <el-col :span="8">
                    <span class="label">法定截止日:</span>
                    <span class="value">{{detailsList.collectEndDate|formatDate}}</span>
                </el-col>

             
                <el-col :span="8">
                    <span class="label">资产支持证券面值:</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.assetsSupportBondAmount)}}</span>
            
                </el-col>
                    <el-col :span="24">
                    <span class="label">资产支持信用级别:</span>
                   
                        <span>{{(absPlanDetailDto.priorityLevel?'优先级'+absPlanDetailDto.priorityLevel:'优先级暂无评级')+'|'+(absPlanDetailDto.subPriorityLevel?'次优级'+absPlanDetailDto.subPriorityLevel:'次先级暂无评级')+'|'+(absPlanDetailDto.badPriorityLevel?'劣后级'+absPlanDetailDto.badPriorityLevel:'劣后级暂无评级')}}</span>

                    
                </el-col>
            
            
            </el-row>
     
         
       
        
       
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        props: {
            detailsList: {
                type: Object,
                default() {
                    return {
                    
                    }
                }
            },
             absPlanDetailDto: {
                type: Object,
                default() {
                    return {
                    
                    }
                }
            }
            
        },
        watch:{
             detailsList: {
                    immediate: true, // 很重要！！！
                    handler (val) {
                      
                        if(this.detailsList.absId){
                             this.getcurrent()
                        }
                     
                       
                    }
            }

        },
        data() {
            return {
              
            }
        },
        mounted() {
 
     
        },
        methods: {
            moment: moment,
             assetsSupportBondTypefn(data){
                if(data){
                    return data.replace(1,'优先级').replace(2,'次优级').replace(3,'劣后级')
                }else{
                    return '无'
                }
            }
     
        }
    }
</script>
<style lang="scss">
     .titlemsgabs{
         width: 300px;
         font-size: 13px;


     } 
  .abs-xmgk-details{
      background: #ffffff;
      margin-bottom: 20px;

 
          .el-row{
              text-indent: 1em;
              .el-col{
                  margin-bottom: 14px;
              }
          }
        .abs-title{
            position: relative;
         font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
      
            margin-bottom: 24px;
        
        }

  
  }
  
</style>