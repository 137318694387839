<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
<pjcheckedfirst v-if="showtab==1"></pjcheckedfirst>
<pjcheckedsecond v-if="showtab==2"></pjcheckedsecond>
          <!-- <billunpublished  v-if="showtab==1"></billunpublished>
          <billwaitmached  v-if="showtab==2"></billwaitmached>
          <billquotedprice  v-if="showtab==3"></billquotedprice>
          <billwaitsigned  v-if="showtab==4"></billwaitsigned>
          <billalreadsigned  v-if="showtab==5"></billalreadsigned>
          <billback  v-if="showtab==6"></billback> -->

          
          


     


    </layout-main-view>
  </div>
</template>
<script>
 import pjcheckedfirst from "./pjcheckedfirst";
 import pjcheckedsecond from "./pjcheckedsecond";


// import billunpublished from "./billunpublished";
// import billwaitmached from "./billwaitmached";
// import billquotedprice from "./billquotedprice";
// import billwaitsigned from "./billwaitsigned";
// import billalreadsigned from "./billalreadsigned";
// import billback from "./billback";





  import api from "@/api/api";


import moment from 'moment'


export default {
  components: {
    'pjcheckedfirst':pjcheckedfirst,
    'pjcheckedsecond':pjcheckedsecond
    // "billunpublished": billunpublished,
    // 'billwaitmached':billwaitmached,
    // 'billquotedprice':billquotedprice,
    // 'billwaitsigned':billwaitsigned,
    // 'billalreadsigned':billalreadsigned,
    // 'billback':billback

  },
    computed:{
   
  },
  data() {
    return {
      breadcrumblist: ["审批管理"],
      showtab: 1,
      tabOptions: [
        { label: "待审批", value: "1", path: "/bill/pjcheckmanage/1" },
        { label: "已审批", value: "2", path: "/bill/pjcheckmanage/2" },




      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      showlist:[]
    };
  },
  created() {
    this.getGrantCreditCorpList()

  },
  mounted(){
      this.showtab = this.$route.params.state;
      
  },

  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
        getGrantCreditCorpList(){                   //调取资金机构数据
               api.getGrantCreditCorpList().then(res => {
                    if (res.code == 100000) {
                      this.showlist=   res.datas.list
                    }else{
                            this.$message.error(res.message)

                    }
            });
    },
  }
};
</script>

