<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
          <billquotedprice  v-if="showtab==1"></billquotedprice>
          <billwaitsigned v-if="showtab==2"></billwaitsigned>
          <billalreadsigned v-if="showtab==3"></billalreadsigned>
          <!-- <billwaitsigned  v-if="showtab==2"></billwaitsigned>
          <billalreadsigned  v-if="showtab==3"></billalreadsigned> -->
          <billback  v-if="showtab==4"></billback>
       
          <billover  v-if="showtab==5"></billover>









    </layout-main-view>
  </div>
</template>
<script>


import billquotedprice from "./billquotedpricezj";
import billwaitsigned from "./billwaitsigned";
import billalreadsigned from "./billalreadsigned";
import billback from "./billbackzj";
import billover from "./billover";






  import api from "@/api/api";


import moment from 'moment'


export default {
  components: {

    'billquotedprice':billquotedprice,
    'billwaitsigned':billwaitsigned,
    'billalreadsigned':billalreadsigned,
    'billback':billback,
    billover

  },
    computed:{

  },
  data() {
    return {
      breadcrumblist: ["票据管理"],
      showtab: 1,
      tabOptions: [

        { label: "已报价", value: "1", path: "/bill/billmanagezj/1" },
        { label: "待签约", value: "2", path: "/bill/billmanagezj/2" },
        { label: "已签约", value: "3", path: "/bill/billmanagezj/3" },
             { label: "已结束", value: "5", path: "/bill/billmanagezj/5" },
        { label: "报价已拒绝", value: "4", path: "/bill/billmanagezj/4" },

   



      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      showlist:[]
    };
  },
  created() {
    this.getGrantCreditCorpList()

  },
  mounted(){
      this.showtab = this.$route.params.state;

  },

  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
        getGrantCreditCorpList(){                   //调取资金机构数据
               api.getGrantCreditCorpList().then(res => {
                    if (res.code == 100000) {
                      this.showlist=   res.datas.list
                    }else{
                            this.$message.error(res.message)

                    }
            });
    },
  }
};
</script>

