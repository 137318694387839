<!--用户账户-->
<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList address-management">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist"
        >
          <el-button type="primary" class="btn-doFilter" @click="openDialog(1)">+ 新增邮寄地址</el-button>
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%;"
          >
            <el-table-column prop="name" label="收件人" min-width="150"></el-table-column>
            <el-table-column prop="number" label="联系电话" min-width="150"></el-table-column>
            <el-table-column prop="address" label="收件地址" min-width="200"></el-table-column>
            <el-table-column label="操作" min-width="200">
              <template slot-scope="scope">
                <el-button  class="clear-button" type="text" @click="openDialog(2, scope.row)">编辑</el-button>
                <el-button  class="clear-button" type="text" @click="handleDelete(scope.row)">删除</el-button>
                <el-button  class="clear-button" :disabled="scope.row.isDefault == 1" type="text" @click="handleSetDefault(scope.row)">设为默认地址</el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
      <el-dialog :title="dialogTitle + '邮寄地址'" :visible.sync="dialogVisible" width="600px">
        <div style="margin-bottom: 20px;">单位名称：{{corpList.corpName}}</div>
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="120px" class="demo-ruleForm" :rules="rules">
          <el-form-item label="联系人：" prop="name">
            <el-input placeholder="请输入联系人" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="number">
            <el-input placeholder="请输入联系电话" maxlength="11" v-model="ruleForm.number"></el-input>
          </el-form-item>
          <el-form-item label="详细地址：" prop="address">
            <el-input placeholder="请输入详细地址" type="textarea"  v-model="ruleForm.address"></el-input>
          </el-form-item>
        </el-form>
        <div class="fr ">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="addCorpAddress">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
  import api from "@/api/api.js";
  import moment from 'moment'

  export default {
    data() {
      return {
        breadcrumblist: ['邮寄地址管理'],
        activeName: "1",
        total: 0,
        listLoading: false,
        listData: [],
        pageNo: 1,
        pageSize: 10,
        filters: '',
        dialogVisible: false,
        ruleForm: {
          name: '',
          number: '',
          address: '',
          isDefault: 2,  // 是否为默认地址 1是 2否
        },
        rules: {
          name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
          number: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: /^1[3456789]\d{9}$/, message: "请输入正确的联系电话", trigger: "blur" }
          ],
          address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        },
        corpList: JSON.parse(sessionStorage.getItem("corpList")),
        dialogTitle: '新增' ,
      };
    },
    created() {
    },
    methods: {
      moment: moment,
      headerStyle({row, rowIndex}) {
        return {background: "#FAFAFA"};
      },
      handleRefresh(filters) {
        this.filters = filters
        this.getLists(filters);
      },
      getLists(param) {
          api.getCorpAddressList(param).then(res => {
          if (res.success) {
            this.listData = res.datas.list;
            this.total = res.datas.total;
          }
          this.listLoading = false;
        });
      },
      openDialog (type, rowData) {
        this.dialogVisible = true
        if(type === 1) { // 新增
          this.$nextTick(() => {
            this.$refs.ruleForm.resetFields()
            this.ruleForm = {
              name: '',
              number: '',
              address: '',
              isDefault: 2,  // 是否为默认地址 1是 2否
            }
          })
        } else if(type === 2) {  // 编辑
          // this.ruleForm = rowData
          Object.assign(this.ruleForm, rowData)
        }
        
      },
      cancel(){
        this.dialogVisible = false;
        this.$refs.ruleForm.clearValidate()
        this.ruleForm = {
          name: '',
          number: '',
          address: '',
          isDefault: 2,  // 是否为默认地址 1是 2否
        }
      },
      // 新增/编辑
      addCorpAddress() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            api.addCorpAddress(this.ruleForm).then(res => {
              if (res.success) {
                this.$alert(res.message)
                this.dialogVisible = false
                this.getLists(this.filters)
              } else {
                this.$message.error(res.message)
              }
            })
          }
        })
      },
      // 删除
      handleDelete(row) {
        this.$confirm('确认删除？')
          .then(() => {
          api.deleteCorpAddress({corpAddressId: row.addressId}).then(res => {
            if (res.success) {
              this.$alert(res.message)
              this.getLists(this.filters)
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(_ => {});
      },
      // 设为/取消默认地址
      handleSetDefault(row) {
        // type: 1设置 
        api.setDefault({corpAddressId: row.addressId, type: 1}).then(res => {
          if (res.success) {
            this.$alert(res.message)
            this.getLists(this.filters)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "../../styles/moreDetail/moudleDetail.scss";
</style>
<style lang="scss">
.address-management {
  .btn-doFilter {
    margin-bottom: 16px;
  }
  .el-dialog__body {
    overflow: hidden;
  }
}
</style>
