<!-- 票据详情 -->
<template>
    <div class="abs-check-details">
        
            <div class="abs-title">绑定账款: </div>
              <div class="httotal">
            
            已收集资产总计金额
            <span>{{$changeMoneyThousand(moneytotal)}}</span> 元。
         </div>

  
        <el-table
          :data="datalist"
            align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="packageNo" label="资产包编号" min-width="170"></el-table-column>
          <el-table-column prop="corpName" label="资产实际收集人" min-width="170"  >
    
          </el-table-column>
                   <el-table-column prop="number" label="包含资产数量" min-width="210">

          </el-table-column>
          <el-table-column  label="资产合计金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.amount)'></el-table-column>

          <el-table-column  label="状态"  prop='approveStatus' min-width="120" :formatter='getapproveStatus' ></el-table-column>

          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>

  <el-dialog title='资产包详情' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation">
              
            <el-table
          :data="zclist"
            align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>
          <el-table-column prop="contractNo" label="合同编号" min-width="170"  >
    
          </el-table-column>
                             <el-table-column prop="claimsCorpName" label="债权企业" min-width="210"  ></el-table-column>
                   <el-table-column prop="debtCorpName" label="债务企业" min-width="210" ></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>

          <el-table-column  label="签署日期"  prop='absAccountSignDate' min-width="160" >
                <template slot-scope="scope">
               {{scope.row.absAccountSignDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column  label="计划付款日期"  prop='absAccountCloseDate' min-width="160" >
                  <template slot-scope="scope">
               {{scope.row.absAccountCloseDate|formatDate}}
       
                </template>
          </el-table-column>


          <el-table-column label="附件" width="120" align="left">
            <template slot-scope="scope">
    
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:1}}" class="initiation">查看详情</router-link>
       
            </template>
          </el-table-column>
        </el-table>


          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">确 定</el-button>
          </div>
        </el-dialog>
       
        
       
    </div>
</template>
<script>
    import moment from 'moment'



    export default {
        props: {
            datalist: {
                type: Array,
                default() {
                    return []
                }
            },
             moneytotal: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        watch:{


        },
        data() {
            return {
                showAddDialog:false,
                zclist:[]
              
            }
        },
        mounted() {
                console.log(this.datalist,'datalist') 
     
        },
        methods: {
            moment: moment,
            handleEditinfo(row){
                console.log(row)
                this.zclist=row.absPlanAccounts
                this.showAddDialog=true
            },
            getapproveStatus(data){
                 switch (data.approveStatus) {
                     case 0:
                      return  '初始'   
                         break;
                     case 1:
                      return  '新增'   
                         break;
                        case 2:
                      return  '需置换'   
                         break;
                        case 3:
                      return  '已锁定'   
                         break;
                 }
            },
            handleopen(row){
                window.open('/abs/absplaninfo?id=14&isshowbtn=2')
            }
     
        }
    }
</script>
<style lang="scss">
  .abs-check-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
       .initiation{
           a{
               text-decoration: none;
               color: #409EFF;
           }
       }
  }

  
 
  
</style>