<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <abswaisigngys ref="abswaisigngys" :datalist='datalist' v-if="isgys"  @fatherMentod='getinfo'></abswaisigngys>
            <abswaisigncore :datalist='datalist' v-if="iscore" @fatherMentod='getinfo'></abswaisigncore>
            <abswaitsignmoney :datalist='datalist' v-if="ismoney" @fatherMentod='getinfo'></abswaitsignmoney>
            <abswaitsignfix :datalist='datalist' v-if="isfix" @fatherMentod='getinfo'></abswaitsignfix>

        </div>
              <div class="card-hd card-bottom">
 
                    <div class="btnmar">
                         <el-button class="card-btn" type="primary" @click="goback">返回</el-button>
                    </div>
       
    
      </div>
        </layout-main-view>


    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  abswaisigngys from '@/views/absbasesign/abswaisigngys' 
    import  abswaisigncore from '@/views/absbasesign/abswaisigncore' 
    import  abswaitsignmoney from '@/views/absbasesign/abswaitsignmoney' 
    import  abswaitsignfix from '@/views/absbasesign/abswaitsignfix' 




 


    export default {

        data() {
            return {
                breadcrumblist:['计划待签约'],
                absPlanDto:{},
                absPlanDetailDto:{},
                datalist:[],//供应商,核心
                userid:'',
                isgys:false,
                iscore:false,
                ismoney:false,
                isfix:false
     
            }
        },
        created() {
            if(this.$route.query.issign==1){
                    this.breadcrumblist=['计划已签约']
            }
               
                   api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
   
                }
            });
                this.getinfo()
    
     
        },
        methods: {
            goback(){
                history.go(-1)
            },
            packfn(){
                console.log(this.$refs.pack,'pack')
                
                 this.$confirm('资产封包以后即进入签约流程，封包后资产将不可再进行置换操作.', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        dangerouslyUseHTMLString: true
                        }).then(() => {
                            var param={
                                absPlanId: this.$route.query.id,
                                list:[]
                            }
                            
                               api.submitPacket(param).then(res =>{ 
                                        if(res.success) {
                                            
                        
                                        }
                                    });
                        }).catch(() => {
                          
                        });
                
            },
            getinfo(){
                          this.userid=JSON.parse(sessionStorage.user)
             api.getSignInfo({absPlanId: this.$route.query.id,absAccountSign:this.$route.query.absAccountSign,leaderCorpType:this.$route.query.leaderCorpType}).then(res =>{ //审批结果查询
                if(res.success) {
                        this.datalist=res.datas.absPlanVo   
                         this.ismoney=this.userid.corpType==1
                        if(this.datalist.leaderCorpType==1&&!this.ismoney){
                                this.isfix=true
                        }else{
                            this.isgys=this.datalist.accountCorpType==1  
                            this.iscore=  this.datalist.accountCorpType==2 
                        }
                  
                       

                     
                }
            });
            }
    
        },
        components: {
           absaccountxmgk,
           abswaisigngys,
           abswaisigncore,
           abswaitsignmoney,
           abswaitsignfix

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }

   }
</style>