<template>
  <div>
      <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <openapprovalfirst v-if="showtab==1" :checktype="corpType"></openapprovalfirst>
            <openapprovalsecond v-if="showtab==2" :checktype="corpType"></openapprovalsecond>
    </layout-main-view>


  </div>
</template>
<script>
  import api from "@/api/api";
import openapprovalfirst from "./openapprovalfirst.vue";
import openapprovalsecond from "./openapprovalsecond";




export default {
    components: {
    'openapprovalfirst':openapprovalfirst,
    'openapprovalsecond':openapprovalsecond
  },
  data() {
    return{
      corpType:sessionStorage.currentPathName == "开立审批" ? 1 : 3,
      breadcrumblist:JSON.parse(sessionStorage.user).corpType==3?["转让审批"]:["开立审批"],
      // tabOptions: [
      //   { label: "待审批", value: "1", path: "/easypay/openapproval/1" },
      //   { label: "已审批", value: "2", path: "/easypay/openapproval/2" },
      // ],
      showtab:1
    }
  },
  computed: {
    tabOptions() {
      console.log(this.$route, '3333')
      return [
        { label: "待审批", value: "1", path: this.$route.path, },
        { label: "已审批", value: "2", path: this.$route.path },
      ]
    },
  },
  created() {
    console.log(this.corpType,'corpType')
    this.showtab=this.$route.params.state
  },
  methods: {
     tabclick(val) {
  this.showtab=val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">

</style>
