<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>
                <absdetial :absPlanDto='approvalDetailsList.absPlanDto' :absPlanDetailDto='approvalDetailsList.absPlanDetailDto' :actualCollectSupplierCorpIds='actualCollectSupplierCorpIds' :actualCollectCoreCorpIds='actualCollectCoreCorpIds' :backinfo='backinfo' :corp='corp' ></absdetial>
        
             <el-dialog title='拒绝原因' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='800px'>
          <el-form :model="refusedetail" label-width="100px"  ref="addform" >
                           <el-form-item label="拒绝原因：" >
                                <el-select v-model="refusedetail.reason" placeholder="请选择">
                                    <el-option
                                    v-for="item in refuselist"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.name">
                                    </el-option>
                                </el-select>
                           </el-form-item>
                           <el-form-item label="备注：" prop="content" >
                                    <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200" >

                                    </el-input>
                                </el-form-item>


          </el-form>

          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取 消</el-button>
              <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
          </div>
        </el-dialog>

        <div class=" card-bottom"  v-if="$route.query.isshowbtn==1">
        
                <el-row>
                <el-col :span="24" class="lb_body" >
                    <el-button  class="card-btn" size="small" @click="goBack" >返回</el-button>
                    <el-button class="card-btn" size="small" @click="openrefusediag" v-if="approvalDetailsList.receiveType==1">信息有误并退回</el-button>
                    <el-button  class="card-btn" size="small" @click="openrefusediag" v-if="approvalDetailsList.receiveType==2">不接收计划</el-button>
                        <el-button class="card-btn" size="small" @click="openagreediag" type="primary">确认并接受计划</el-button>

                </el-col>
                <!-- <el-col :span="8" class="lb_body">
                    <el-button class="card-btn" size="small" @click="openagreediag" type="primary">确认并接受计划</el-button>
                </el-col> -->
                </el-row>
         </div>
       <div class="card-hd card-bottom"  v-if="$route.query.isshowbtn==2">
           
        <el-row >
          <el-col class="lb_body btnmar">
            <el-button  class="card-btn" size="small" @click="goBack" type="primary">返回</el-button>
          </el-col>

        </el-row>
      </div>
        </layout-main-view>
    </div>
</template>
<script>
    import api from "@/api/api";
    import  absdetial from '@/views/absbase/absplandetial' 
 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划详情'],
                refusedetail:{
                        absPlanId:this.$route.query.id,
                    approveResult:3,
                    reason:'',
                    remark:''

                },
                agreedetail:{
                    absPlanId:this.$route.query.id,
                    approveResult:1,


                },
                showAddDialog:false,
                bclick:false,
                refuselist:[],
                releaseSuccess:true,
                approvalDetailsList:{},
                actualCollectCoreCorpIds:[],
                actualCollectSupplierCorpIds:[],
                backinfo:{},
                corp:{}

                
           
     
            }
        },
        created() {
         this.getrefuselist();
             if(!this.$route.query.isshowreason){
                api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                    if(res.success) {
                        this.approvalDetailsList =res.datas.planDetail
                    this.corp=this.approvalDetailsList.corp||{corpName:''}
                        if(this.approvalDetailsList.absPlanDto.actualCollectCoreCorpIds.length>0){
                                this.actualCollectCoreCorpIds=JSON.parse(this.approvalDetailsList.absPlanDto.actualCollectCoreCorpIds)
                        }
                        if(this.approvalDetailsList.absPlanDto.actualCollectSupplierCorpIds.length>0){
                                this.actualCollectSupplierCorpIds=JSON.parse(this.approvalDetailsList.absPlanDto.actualCollectSupplierCorpIds)
                        }
                       
                    }
                })
             }else{
                 api.getReturnAbsPlanDetail({absApproveId: this.$route.query.id}).then(res =>{ //审批结果查询
                    if(res.success) {
                        this.approvalDetailsList =res.datas.planDetail
                    this.corp=this.approvalDetailsList.corp||{corpName:''}
    
                        if(this.approvalDetailsList.absPlanDto.actualCollectCoreCorpIds.length>0){
                                this.actualCollectCoreCorpIds=JSON.parse(this.approvalDetailsList.absPlanDto.actualCollectCoreCorpIds)
                        }
                        if(this.approvalDetailsList.absPlanDto.actualCollectSupplierCorpIds.length>0){
                                this.actualCollectSupplierCorpIds=JSON.parse(this.approvalDetailsList.absPlanDto.actualCollectSupplierCorpIds)
                        }
                         if(this.approvalDetailsList.absApproveDto.approveId){
                                this.backinfo=this.approvalDetailsList.absApproveDto
                        }
                    }
                })
                 
             }
          
        },
        methods: {
    
           openagreediag(){
                     const loading = this.$loading({
                                            lock: true,
                                            text: "Loading",
                                            spinner: "el-icon-loading",
                                            background: "rgba(0, 0, 0, 0.7)"
                                          });

                api.receiveEnterpriseResult(this.agreedetail).then(res => {
                    loading.close()
                                            if(res.code=='100000'){
                                                     this.$router.push({name:'absplanagree'})

                                            }else{
                                                this.$message.error(res.message)
                                            }

                                }).catch(err => {
                                              loading.close();
                     });

              
           },
           submitAddForm2(){
                        const loading = this.$loading({
                                            lock: true,
                                            text: "Loading",
                                            spinner: "el-icon-loading",
                                            background: "rgba(0, 0, 0, 0.7)"
                                          });
    
                   api.receiveEnterpriseResult(this.refusedetail).then(res => {
                                              loading.close();

                                            if(res.code=='100000'){

                                                     this.$router.push({name:'absplanrefuse'})

                                            }else{
                                                this.$message.error(res.message)
                                            }

                                }).catch(err => {
                                              loading.close();
                     });

           },
           openrefusediag(){
                this.showAddDialog=true
           },
           goBack(){
                history.go(-1)
           },
         getrefuselist(){
                    api.getDicListByType({type:28}).then(res => {
                                if(res.code=='100000'){
                                        this.refuselist=res.datas.list
                                        this.refusedetail.reason=this.refuselist[0].name

                                }else{
                                                this.$message.error(res.message)
                                            }

                    })

         },

        },
        components: {
           absdetial

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         background: #ffffff;
         padding-top: 20px;
         padding-bottom: 200px;
          
       }
       .card-bottom{
  
           left: 230px;
           .btnmar{
            
           }
       }
      

   }

</style>