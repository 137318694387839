<template>
  <div>
    <!--<Step class="m-top" qiv-bind:step-value="stepValues"></Step>-->
    <div class="main-contentTop1">
    </div>
      <div class="main-TopContentBottom">
        <div class="steps">
          <el-steps :active="1" align-center >
           <el-step title="企业信息认证"></el-step>
            <el-step title="个人信息认证"></el-step>
            <el-step title="企业资料补充"></el-step>
            <el-step title="用户人脸识别"></el-step>
            <el-step title="注册申请成功"></el-step>
          </el-steps>
        </div>
      </div>

    <div class="register_main">
      <el-form
        :model="form"
        :rules="rules"
        ref="registerForm"
        label-position="right"
        label-width="150px"
        class="demo-ruleForm registerDemo login-container"
      >
        <div class="first_step">
          <h2>人员资料完善</h2>
          <el-form-item
            label="上传法人身份证："
            class="company1 upload-text-requird"
          >
            <div class="certificates">
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">人像面</span>
                <div
                  @click="getFileType('idCard')"
                  :class="{imgClass:!isEmpty(legalInfoIdCardFrontFileUrl)}"
                >
                  <el-upload
                  ref="uploadFile"
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="idCardUpload()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="uploadIdCardCallBack"
                    :on-remove="IDLegalInfojustRemove"
                    :file-list="fileList1"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(legalInfoIdCardFrontFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">国徽面</span>
                <div
                  @click="getFileType('idCardReverse')"
                  :class="{imgClass:!isEmpty(legalInfoIdCardBackFileUrl)}"
                >
                  <el-upload
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="idCardUpload2()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="idCardhandleAvatarSuccessCallback"
                    :on-remove="IDLegalInfoIdReverseRemove"
                    :file-list="fileList2"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(legalInfoIdCardBackFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
            </div>
            <label
              style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;"
            >仅支持JPG、PNG格式，文件小于5M。请尽量使用高清质量图片。</label>
          </el-form-item>
          <el-form-item label="法人姓名：" class="company1" prop="legalInfo.name">
            <el-input v-model="form.legalInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号码：" class="company1" prop="legalInfo.idCard">
            <el-input v-model="form.legalInfo.idCard"></el-input>
          </el-form-item>
          <el-form-item
            label="上传经办人身份证："
            class="company1"
            :class="form.registerType == 2 && 'upload-text-requird'"
            v-if= "form.corpType == 1 || form.corpType == 6 || form.corpType == 3"
          >
            <label
              style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;"
            >请上传彩色原件或加盖公司公章的复印件</label>
            <div class="certificates">
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">人像面</span>
                <div
                  @click="getFileType('iAgentIdCard')"
                  :class="{imgClass:!isEmpty(agentInfoIdCardFrontFileUrl)}"
                >
                  <el-upload
                  ref="uploadFile2"
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="iAgentIdCardUpload()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="iAgentIdCardUploadCallBack"
                    :on-remove="IDAgentInfojustRemove"
                    :file-list="fileList3"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(agentInfoIdCardFrontFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">国徽面</span>
                <div
                  @click="getFileType('iAgentIdCardReverse')"
                  :class="{imgClass:!isEmpty(agentInfoIdCardBackFileUrl)}"
                >
                  <el-upload
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="iAgentIdCardUpload2()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="iAgentIdAvatarSuccessCallBack"
                    :on-remove="IDAgentInfoReverseRemove"
                    :file-list="fileList4"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(agentInfoIdCardBackFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item  v-if= "form.corpType == 1 || form.corpType == 6 || form.corpType == 3" label="经办人姓名：" class="company1" prop="agentInfo.name">
            <el-input v-model="form.agentInfo.name"></el-input>
          </el-form-item>
          <el-form-item  v-if= "form.corpType == 1 || form.corpType == 6 || form.corpType == 3" label="经办人身份证号码：" class="company1" prop="agentInfo.idCard">
            <el-input v-model="form.agentInfo.idCard"></el-input>
          </el-form-item>
          <el-form-item class="item-btn">
            <el-button
              type="primary"
              size="small"
              @click.native="back()"
              style="width: 160px;height: 40px;background: white;color: black;margin-right: 32px; margin-bottom: 20px"
            >上一步</el-button>
            <el-button
              type="primary"
              size="small"
              @click.native="nextStep()"
              style="width: 160px;height: 40px;background: #2937A6;"
            >下一步</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import Step from "./Step";
import areaJson from "../../const/area.json";
import goverArea from "../../const/government.json";
import upload from "../../api/upload";
import _ from "lodash";
export default {
  data() {
    var checkEnpsLicense = (rule, value, callback) => {
      let reg = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]{0,18}$/;
      if (value.length < 16) {
        if (value.length !== 15) {
          callback(new Error("营业执照注册号15位数字"));
        } else {
          callback();
        }
      }
      if (value.length >= 16) {
        if (reg.test(value) !== true && value.length !== 18) {
          callback(new Error("统一社会信用代码18位大写字母加数字!"));
        } else {
          callback();
        }
      }
    };
    var checkSupName = (rule, value, callback) => {
      if (_.isEmpty(this.supName)) {
        if (!value) {
          callback(new Error("请输入企业名!"));
        } else if (value.length > 50) {
          callback(new Error("企业名最多为50个汉字!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      Authorization: {Authorization: sessionStorage.token},
      form: {
        agentInfo: {
          idCard: "", //经办人身份证号
          idCardBackFileName: "", //经办人国徽图片名称
          idCardBackFileUrl: "", //经办人国徽页图片url
          idCardFrontFileName: "", //经办人身份证正面图片名称
          idCardFrontFileUrl: "", //经办人身份证正面url地址
          name: "" //经办人姓名
        },
        registerType: "", //注册类型
        corpType: '',
        corpId: "",
        legalInfo: {
          idCard: "", //法人身份证号
          idCardBackFileName: "", //法人国徽图片名称
          idCardBackFileUrl: "", //法人国徽页图片url
          idCardFrontFileName: "", //法人身份证正面图片名称
          idCardFrontFileUrl: "", //法人身份证正面url地址
          name: ""
        }
        // corpId:"",
        // idCardFrontFileUrl:"",
        // idCardBackFileUrl:"",//法人国徽页
        // idCardFrontFileUrl:"",//法人身份证正面
        // idCardBackFileUrl:""//经办人身份证正面
      },
      corpId: "",
      stepValues: 2,
      fileType: {
        type: "",
        registerCode: ""
      },
      registerForm: {
        name: "",
        enpsLicense: "",
        provinceAddress: "",
        cityAddress: "",
        legalAddress: "",
        controller: 1,
        fillPersion: 1,
        idCardFrontFileUrl: "" // 法人身份证正面
      },
      provinceList: [],
      cityList: [],
      licenseUrl: "", // 营业执照
      agentJustUrl: "", // 代理人正面
      agentReverseUrl: "", // 代理人反面
      entrustUrl: "", // 委托代理书
      agentInfoIdCardFrontFileUrl: "", //经办人身份证正面
      agentInfoIdCardBackFileUrl: "", //经办人国徽页
      legalInfoIdCardFrontFileUrl: "", //法人身份证正面
      legalInfoIdCardBackFileUrl: "", //法人国徽页
      fileList1: [], //回显法人身份证正面
      fileList2: [], //回显法人身份证反面
      fileList3: [], //回显经办人身份证正面
      fileList4: [], //回显经办人身份证反面
      rules: {
        legalInfo: {
          name: [
            { required: true, message: "请输入法人姓名", trigger: "blur" }
          ],
          idCard: [
            { required: true, message: "请输入法人身份证号码", trigger: "blur" }
          ],
          // legalInfoIdCardFrontFileUrl: [
          //   { required: true, message: "请上传法人身份证", trigger: "blur" }
          // ]
        },
        agentInfo: {
          name: [
            { required: true, message: "请输入经办人姓名", trigger: "blur" }
          ],
          idCard: [
            { required: true, message: "请输入经办人身份证号码", trigger: "blur" }
          ],
          // agentInfoIdCardFrontFileUrl: [
          //   { required: true, message: "请上传经办人身份证", trigger: "blur" }
          // ]
        }
      },
      loading: {},
    };
  },
  components: {
    Step
  },
  mounted() {
    // this.getProvince();
    this.form.corpId = this.$route.query.corpId;
    this.getInfo();
  },
  computed: {
    supName() {
      return this.$store.getters.supName;
    }
  },
  methods: {
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    // 国徽页图片上传
    idCardUpload2() {
      return upload.eqptImgSrc();
    },
    // 国徽页图片上传
    iAgentIdCardUpload2() {
      return upload.eqptImgSrc();
    },
    //身份证正面图片上传
    idCardUpload() {
      return upload.uploadIDCard();
    },
    iAgentIdCardUpload() {
      return upload.uploadIDCard();
    },

    //回显数据
    getInfo() {
      console.log("this.corpId ====>", this.corpId);
      api.getCorpTrialDetail({ corpId: this.form.corpId }).then(res => {
        if (res.success) {
          this.form.legalInfo.name = res.datas.corpDetailList[0].legalName;
          this.form.legalInfo.idCard = res.datas.corpDetailList[0].legalIdCard;
          this.form.agentInfo.name = res.datas.corpDetailList[0].agentName;
          this.form.agentInfo.idCard = res.datas.corpDetailList[0].agentIdCard;
          this.form.registerType = res.datas.corpDetailList[0].registerType; // 注册类型 1 法人 2 经办人
          // 1 资产企业 2 核心企业 3 链属企业  6 担保企业'
          this.form.corpType = res.datas.corpDetailList[0].corpType;
          if (res.datas.corpDetailList[0].registerType == 1) {
            //法人
            this.rules.agentInfo = {};
          }
          res.datas.fileUrlByCorpList.forEach(el => {
            if (el.fileName == "法人身份证正面") {
              this.fileList1.push({
                name: el.fileName,
                url: api.getImgURL(el.fileUrl)
              });
              this.legalInfoIdCardFrontFileUrl = el.fileUrl;
              this.form.legalInfo.idCardFrontFileUrl = el.fileUrl;
            } else if (el.fileName == "法人身份证反面") {
              this.fileList2.push({
                name: el.fileName,
                url: api.getImgURL(el.fileUrl)
              });
              this.legalInfoIdCardBackFileUrl = el.fileUrl;
              this.form.legalInfo.idCardBackFileUrl = el.fileUrl;
            } else if (el.fileName == "经办人身份证正面") {
              this.fileList3.push({
                name: el.fileName,
                url: api.getImgURL(el.fileUrl)
              });
              this.agentInfoIdCardFrontFileUrl = el.fileUrl;
              // this.form.agentInfoIdCardFrontFileUrl = el.fileUrl;
              this.form.agentInfo.idCardFrontFileUrl = el.fileUrl;
            } else if (el.fileName == "经办人身份证反面") {
              this.fileList4.push({
                name: el.fileName,
                url: api.getImgURL(el.fileUrl)
              });
              this.agentInfoIdCardBackFileUrl = el.fileUrl;
              // this.form.agentInfoIdCardBackFileUrl = el.logoUrl;
              this.form.agentInfo.idCardBackFileUrl = el.fileUrl;
            }
          });
          console.log("this.form ===>", this.form);
        }
      });
    },

    //上传法人身份证正面图片上传之后的回显
    uploadIdCardCallBack(res,file) {
      if (res.code == 100000) {
        this.form.legalInfo.name = res.datas.ocrResult.Name;
        this.form.legalInfo.idCard = res.datas.ocrResult.IdentificationNumber;
        this.form.legalInfo.idCardFrontFileUrl =
          res.datas.uploadResult;
        this.legalInfoIdCardFrontFileUrl = res.datas.uploadResult;
        console.log(this.legalInfoIdCardFrontFileUrl, 5656565);
        this.$message.success('上传成功');
        this.loading.close();

      } else {
        this.$message.error(res.message);
        this.loading.close();
        let uid = file.uid // 关键作用代码，去除文件列表失败文件
          let idx = this.$refs.uploadFile.fileList.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
          console.log(idx,"=======",this.$refs.uploadFile.fileList);
          this.$refs.uploadFile.fileList.splice(0) // 关键作用代码，去除文件列表失败文件
      }
    },
    //法人国徽页
    idCardhandleAvatarSuccessCallback(res) {
      if (res.code == 100000) {
        this.form.legalInfo.idCardBackFileUrl =
          res.datas.uploadDate.message;
        this.legalInfoIdCardBackFileUrl = res.datas.uploadDate.message;
        console.log(this.legalInfoIdCardBackFileUrl, 89898989);
        this.$message.success('上传成功');
        this.loading.close();
      } else {
        this.$message.error(res.message);
        this.loading.close();
      }
    },
    //经办人身份证正面
    iAgentIdCardUploadCallBack(res,file) {
      if (res.code == 100000) {
        console.log("res.datas ===>", res.datas.uploadResult);
        this.form.agentInfo.name = res.datas.ocrResult.Name;
        this.form.agentInfo.idCard = res.datas.ocrResult.IdentificationNumber;
        this.form.agentInfo.idCardFrontFileUrl =
          res.datas.uploadResult;
        this.agentInfoIdCardFrontFileUrl = res.datas.uploadResult;
        console.log(this.form.agentInfo.idCardFrontFileUrl, 343434);
        this.$message.success('上传成功');
        this.loading.close();

      } else {
        this.$message.error(res.message);
        this.loading.close();
        let uid = file.uid // 关键作用代码，去除文件列表失败文件
          let idx = this.$refs.uploadFile2.fileList.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
          console.log(idx,"=======",this.$refs.uploadFile2.fileList);
          this.$refs.uploadFile2.fileList.splice(0) // 关键作用代码，去除文件列表失败文件
      }
    },
    //经办人国徽页
    iAgentIdAvatarSuccessCallBack(res) {
      if (res.code == 100000) {
        this.form.agentInfo.idCardBackFileUrl =
          res.datas.uploadDate.message;
        this.agentInfoIdCardBackFileUrl = res.datas.uploadDate.message;
        console.log(this.agentInfoIdCardBackFileUrl, 121212);
        this.$message.success('上传成功');
        this.loading.close();

      } else {
        this.$message.error(res.message);
        this.loading.close();

      }
    },

    // 获取省
    // 下载
    downloadFiles() {
      window.location.href =
        baseURL + searchImage.download_file_src + "?category=document";
    },
    getPersion(val) {
      if (val === 1) {
        this.agentJustUrl = "";
        this.agentReverseUrl = "";
        this.entrustUrl = "";
      }
    },
    getFileType(val) {
      this.fileType = {
        type: val,
        registerCode: sessionStorage.getItem("code")
      };
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.$message.success('上传成功');
        if (this.fileType.type === "license") {
          this.licenseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "legalFacade") {
          this.idCardFrontFileUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "legalReverse") {
          this.idCardBackFileUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "agentFacade") {
          this.agentJustUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "agentReverse") {
          this.agentReverseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "entrustTable") {
          this.entrustUrl = URL.createObjectURL(file.raw);
          return;
        }
      }
    },
    // 法人身份证正面
    IDLegalInfojustRemove(file) {
      this.form.legalInfo.idCardFrontFileUrl = "";
      this.legalInfoIdCardFrontFileUrl = "";
      console.log("法人身份证正面 ====>");
    },
    // 法人身份证反面
    IDLegalInfoIdReverseRemove(file) {
      this.form.legalInfo.idCardBackFileUrl = "";
      this.legalInfoIdCardBackFileUrl = "";
    },
    //经办人身份证正面
    IDAgentInfojustRemove() {
      this.form.agentInfo.idCardFrontFileUrl = "";
      this.agentInfoIdCardFrontFileUrl = "";
    },
    //经办人国徽面
    IDAgentInfoReverseRemove() {
      this.form.agentInfo.idCardBackFileUrl = "";
      this.agentInfoIdCardBackFileUrl = "";
    },
    // 代理人身份证正面
    agentJustRemove(file) {
      this.agentJustUrl = "";
    },
    // 代理人身份证反面
    agentReverseRemove(file) {
      this.agentReverseUrl = "";
    },
    // 代理人委托书
    entrustRemove(file) {
      this.entrustUrl = "";
    },
    beforeAvatarUpload(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      //判断文件大小 2M 以内
      const isLt2M = file.size / 1024 / 1024 < 5
      console.log('file =======>',file);
      if(!isLt2M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }

      const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        this.loading = loading;

      // //上传前配置
      // if (this.fileType.type === "license") {
      //     this.licenseUrl = "1";
      //     return;
      // }
      // if (this.fileType.type === "legalFacade") {
      //     this.idCardFrontFileUrl = "1";
      //     return;
      // }
      // if (this.fileType.type === "legalReverse") {
      //     this.idCardBackFileUrl = "1";
      //     return;
      // }
      // if (this.fileType.type === "agentFacade") {
      //     this.agentJustUrl = "1";
      //     return;
      // }
      // if (this.fileType.type === "agentReverse") {
      //     this.agentReverseUrl = "1";
      //     return;
      // }
      // if (this.fileType.type === "entrustTable") {
      //     this.entrustUrl = "1";
      //     return;
      // }
    },
    nextStep(val) {
      if (this.form.registerType == 1) {
        if (_.isEmpty(this.legalInfoIdCardFrontFileUrl)) {
          this.$message({
            message: "请上传法人身份证头像页",
            type: "error"
          });
          return;
        }
        if (_.isEmpty(this.legalInfoIdCardBackFileUrl)) {
          this.$message({
            message: "请上传法人身份证国徽页",
            type: "error"
          });
          return;
        }
      } else {
        if (_.isEmpty(this.agentInfoIdCardFrontFileUrl)) {
          this.$message({
            message: "请上传经办人身份证头像页",
            type: "error"
          });
          return;
        }
        if (_.isEmpty(this.agentInfoIdCardBackFileUrl)) {
          this.$message({
            message: "请上传经办人身份证国徽页",
            type: "error"
          });
          return;
        }

        var p = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        console.log("====>", p.test(this.form.legalInfo.idCard));
        if (p.test(this.form.legalInfo.idCard) == false) {
          this.$message({
            message: "请填写正确的法人身份证号",
            type: "error"
          });
          return;
        }
        if (p.test(this.form.agentInfo.idCard) == false) {
          this.$message({
            message: "请填写正确的经办人身份证号",
            type: "error"
          });
          return;
        }
      }

      // if (_.isEmpty(this.idCardBackFileUrl)) {
      //     this.$message({
      //         message: "请上传法人身份证国徽页",
      //         type: "error"
      //     });
      //     return;
      // }
      // if (_.isEmpty(this.idCardFrontFileUrl)) {
      //   this.$message({
      //     message: "请上传经办人个人信息页",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardBackFileUrl)) {
      //     this.$message({
      //         message: "请上传经办人国徽页",
      //         type: "error"
      //     });
      //     return;
      // }
      // const initData = {
      //     IdentificationNumber:"",
      //     Name:"",
      //     idCardFrontFileUrl:"",//经办人国徽页
      //     idCardBackFileUrl:"",//经办人身份证正面
      //     corpId:this.corpId
      //     }
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          let para = { ...this.form };
          api.personalDetailAdd(para).then(res => {
            // this.form.registerType  // 注册类型 1 法人 2 经办人
            if (res.code === "100000") {
              this.$router.push({
                name: "CompanyConfirm",
                query: { corpId: this.form.corpId}
              });
            } else {
              this.$message({
                message: res.message,
                type: "error"
              });
            }
          });
        }
      });

      // if (_.isEmpty(this.licenseUrl)) {
      //   this.$message({
      //     message: "请上传营业执照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardFrontFileUrl)) {
      //   this.$message({
      //     message: "请上法人身份证个人信息照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardBackFileUrl)) {
      //   this.$message({
      //     message: "请上法人身份证国徽照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.agentJustUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传代理人个人信息照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.agentReverseUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传代理人国徽照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.entrustUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传委托书照片",
      //     type: "error"
      //   });
      //   return;
      // }
      // areaJson.RECORDS.forEach(item => {
      //   if (this.registerForm.provinceAddress == item.id) {
      //     this.registerForm.provinceAddress = item.name;
      //   }
      //   if (this.registerForm.cityAddress == item.id) {
      //     this.registerForm.cityAddress = item.name;
      //   }
      // });
      // console.log('registerForm==>',this.registerForm)
      // this.$refs.registerForm.validate(valid => {
      //   if (valid) {
      //     let para = {
      //       registerCode: sessionStorage.getItem("code"),
      //       registerUserInfoEntity: {
      //         name:
      //           _.isEmpty(this.supName) == false
      //             ? this.supName
      //             : this.registerForm.name,
      //         enterpriseType: sessionStorage.getItem("enterpriseType"),
      //         registerCode: sessionStorage.getItem("code"),
      //         enpsLicense: this.registerForm.enpsLicense,
      //         legalAddress:
      //           this.registerForm.provinceAddress +
      //           "" +
      //           this.registerForm.cityAddress,
      //         controller: this.registerForm.controller,
      //         fillPersion: this.registerForm.fillPersion
      //       }
      //     };
      //     CompanyBaseInfo(para).then(res => {
      //       if (res.code === 0) {z
      //         this.$router.push("/register/perfect");
      //       } else {
      //         this.$message({
      //           message: res.message,
      //           type: "error"
      //         });
      //       }
      //     });
      //   }
      // });
      // this.$router.push("/CompanyConfirm");
    },
    back() {
      // this.$router.go(-1); //返回上一层
      this.$router.push({
        path: "/Company",
        query: { corpId: this.form.corpId }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.el-form-item__content {
  line-height: 30px !important;
}
.register_main{
  padding-bottom: 20px;
}
.required:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}
.required1:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 20px;
}
.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss" >
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1 {
  height: 239px;
  /*background-color: red;*/
  background: url("../../assets/login/cusrz.jpg") center;
  background-size: cover

}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top:0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon{
    width: 32px;
    height: 32px;
  }
}
</style>
