<template>
	<footer class="layout-footer">
        <!--<ul class="f-nav">-->
            <!--<li><router-link to="/">帮助</router-link></li>-->
            <!--<li><router-link to="/">隐私</router-link></li>-->
            <!--<li><router-link to="/">条款</router-link></li>-->
        <!--</ul>-->
		<p class="f-info">Copyright 2022 上海经证科技有限公司 版权所有</p>
		<p class="f-info">联系我们：service@sh-jzgroup.com</p>

	</footer>
</template>
<script>
export default {
	name: 'layout-footer',
	data() {
		return {

		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>
