<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList blrzstep">
          <steps :stepList="stepList" :current="current"></steps>

      
      <checkResult v-if="type == 2"></checkResult> 
      <baseInfo></baseInfo> 
      <receivablesList :tableList="tableList" :formData="formData"></receivablesList> 
      <div class="card-hd" style="height: 150px;">
        <el-row style="padding-top: 40px;">
          <el-col :span="7" class="lb_body" :offset="7" v-if="type == 1">
            <el-button style="width: 160px;height: 40px;font-size:16px;" size="small" @click="reject">拒绝</el-button>
          </el-col>
          <el-col :span="8" class="lb_body" v-if="type == 1">
            <el-button style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;" size="small" @click="agree">同意</el-button>
          </el-col>
          <el-col :span="8" class="lb_body" :offset="10" v-if="type == 2">
            <el-button style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;" size="small" @click="back">返回</el-button>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 弹窗 -->
  <el-dialog title='审核意见' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='40%'>
    <el-form :model="agreedetail" label-width="60px"  ref="addform" >
      <el-form-item label="备注："  >
        <el-input v-model="agreedetail.remark" type="textarea" :rows="6" maxlength="200" >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showAddDialog=false">取 消</el-button>
      <el-button type="primary" @click="submitAddForm" :disabled="bclick">提交</el-button>
    </div>
  </el-dialog>
  <el-dialog title='拒绝原因' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false'  width='40%'>
    <el-form :model="refusedetail" label-width="100px"  ref="addform" >
      <el-form-item label="拒绝原因：" prop="reason" >
        <el-select v-model="refusedetail.reason" placeholder="请选择">
          <el-option
            v-for="item in refuselist"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注："   >
        <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200" >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showAddDialog2=false">取 消</el-button>
      <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
    </div>
  </el-dialog>
  </layout-main-view>
</template>

<script>
import checkResult from './checkResult'
import baseInfo from './baseInfo'
import receivablesList from './receivablesList'
import api from "@/api/api";
import steps from "@/views/base/steps.vue";

export default {
  components: {
    checkResult,
    baseInfo,
    receivablesList,
    steps
  },
  data() {
    return {
      breadcrumblist:['融资提现详情'],
      agreedetail:{
        receivableAccountFinanceId:this.$route.query.id,
        typeStatus:1,
        remark:'',
      },
      refusedetail:{
        receivableAccountFinanceId:this.$route.query.id,
        typeStatus:2,
        remark:'',
        reason:'',
      },
      refuselist:[],
      bclick:false,
      showAddDialog:false,
      showAddDialog2:false,
      type: this.$route.query.type,  // 1待审批 2已审批
      formData: {},
      tableList: [],
            stepList: [
                                    {
                                    name: "发起融资",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "项目内审",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "平台撮合",
                                    realName: "",
                                    createTime: ""
                                    },
                                       {
                                    name: "机构报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "选择报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "补件确权",
                                    realName: "",
                                    createTime: ""
                                    },
                                     {
                                    name: "等待签约",
                                    realName: "",
                                    createTime: ""
                                    },
                                      {
                                    name: "签约完成",
                                    realName: "",
                                    createTime: ""
                                    },
                                
                                    
                                ],
                            current:1
    }
  },
  created() {
    this.FinancingProjectDetails(this.$route.query.id)
  },
  methods: {
    FinancingProjectDetails(receivableAccountFinanceId) {
      api.FinancingProjectDetails({ receivableAccountFinanceId })
      .then(res => {
        if (res.success) {
          this.formData = res.datas.data;
          this.tableList = res.datas.list;
          this.getstatus()
        }
      });
    },
    back() {
      history.go(-1)
    },
    getrefuselist(){
      // type 25 保理提现 退回原因
      api.getDicListByType({type:26}).then(res => {
        if(res.success){
          this.refuselist=res.datas.list
        }
      })
    },
    // 同意
    agree() {
      this.showAddDialog = true
    },
    //拒绝
    reject() {
      this.getrefuselist()    //获取拒绝原因
      this.showAddDialog2 = true;
    },
    submitAddForm(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.bclick){
        return
      }
      this.bclick=true
      api.withdrawApprove(this.agreedetail).then(res => {
        loading.close()
        if(res.success){
          this.showAddDialog=false
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              history.go(-1)
            }
          });
        }else{
          this.$message.error(res.message)
          this.bclick=false
        }
      }).catch((err) => {
        loading.close()
        this.bclick=false
      })
    },
    submitAddForm2(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.bclick){
        return
      }
      this.bclick=true
      api.withdrawApprove(this.refusedetail).then(res => {
        loading.close()
        if(res.success){
          this.showAddDialog2=false
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              history.go(-1)
            }
          });
        }else{
          this.$message.error(res.message)
          this.bclick=false
        }
      }).catch((err) => {
        loading.close()
        this.bclick=false
      })
    },
      getstatus(){
          if(this.formData.state == 'D'){
                // 作废
                this.current=-1
                }else{
                if(this.formData.financeSignStatus == 50){
                  this.current=8
                // 签约完成
                }else if(this.formData.financeSignStatus >= 20 && this.formData.financeSignStatus < 50){
                  this.current=6
                // 等待签约
                }else if(this.formData.financeSignStatus < 20 && this.formData.financeSupplyApproveStatus >= 20 && this.formData.financeSupplyApproveStatus <= 57 && this.formData.corpAckStatus > 1 && this.formData.corpAckStatus <= 6){
                // 补件确权
                this.current=5
                }else if(this.formData.financeQuoteStatus == 3 && this.formData.financeSupplyApproveStatus < 20 ){
                  this.current=4
                // 选择报价
                }else if(this.formData.financeQuoteStatus == 2){
                  this.current=3
                // 机构报价
                }else if(this.formData.approveStatus >= 41 && this.formData.approveStatus <= 49){
                  this.current=2
                // 平台撮合
                }else if(this.formData.approveStatus > 20 && this.formData.approveStatus <= 23){
                // 项目内审
                this.current=1
                }else if(this.formData.approveStatus == 20){
                  this.current=1
                // 发起融资
                }else{
                  this.current=6

                // 等待签约
                }
                }


    },
  }

}
</script>

<style>

</style>
