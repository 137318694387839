<template>
	<div class="data-list">
		<data-filter
			ref="datafilter"
			v-if="filters.length!=0"
			:filters="filters"
      :isShowAll="isShowAll"
      :isHedden="isHedden"
      @do-filter-showAll="doFilterShowAll"
			:otherFunc="otherFunc"
			@do-filter="doFilter" />
		<slot />
		<el-pagination layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="pageNum"
		    :page-sizes="pageSizes"
      	:page-size="pageSize"
		    >
		</el-pagination>
	</div>

</template>
<script>
import DataFilter from './dataFilter.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
export default {
  name: 'data-list',
  components:{
    [DataFilter.name]: DataFilter
  },
  data() {
    return {
      pageNum: 1,
      defaultPageSize: 10,
      pageSize: this.defaultPageSize,
      pageSizes: [5, 10, 20, 30],
      paramsFilter: {},
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    isHedden: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Object,
      default () {
        return {
          PAGE_SIZE: "pageSize",
          PAGE_NUMBER: "pageNum"
        }
      }
    },
    total: {
      type: Number,
      default: 0
    },
	  otherFunc: {
        type: Array,
		  default: () => []
	  }
  },
  computed: {

  },
  watch: {
    "$route": {
      handler(to, from) {
        if (to.path != from.path) {
          this.paramsFilter = {}
        }
        this.handleRoute(to)
      },
      deep: true
    }
  },
  created() {
    this.handleRoute(this.$route)
  },
  mounted() {},
  methods: {
   setRoutePage(){
        

      this.$router.push({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
      })
    },
    refresh() {
      var data = Object.assign({
        pageSize: this.pageSize,
        pageNo: this.pageNum
      }, this.paramsFilter);
      data[this.fields.PAGE_SIZE] = data.pageSize
      data[this.fields.PAGE_NUMBER] = data.pageNo
      delete data.pageSizes
      delete data.pageNo

      

      this.$emit('data-refresh', data)
    },
    doFilter(paramsFilter) {

      this.paramsFilter = paramsFilter;
      var data = Object.assign({
        pageSize: this.pageSize,
        pageNo: 1
      }, this.paramsFilter)
      data[this.fields.PAGE_SIZE] = data.pageSize
      data[this.fields.PAGE_NUMBER] = data.pageNo
      delete data.pageSizes
      delete data.pageNo
      this.$router.push({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          pageNum: 1
        })
      })
       

      this.$emit('data-refresh', data)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.$router.push({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          pageNum: this.$route.query.pageNum,
          pageSize: val
        })
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.$router.push({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          pageNum: val,
          pageSize: this.$route.query.pageSize
        })
      })
    },
    handleRoute(route) {

      if(this.uuid && this.activeUUid && this.uuid != this.activeUUid){
        return
      }
      var pgsizeSting = this.pageSizes.toString();
      var page = Object.assign({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, route.query)
      page.pageNum = Number(page.pageNum)
      if (!page.pageNum || !_.isNumber(page.pageNum)) {
        page.pageNum = this.pageNum
      } else {
        this.pageNum = Number(page.pageNum)
      }
      if (!page.pageSize || !_.isNumber(page.pageSize)) {
        if (pgsizeSting.indexOf(page.pageSize) == -1) {
          page.pageSize = this.defaultPageSize;
          this.$router.replace({
            name: route.name,
            query: Object.assign({}, this.$route.query, {
              pageSize: page.pageSize,
              pageNum: page.pageNum
            })
          })
          return false;
        } else {
          this.pageSize = Number(page.pageSize);
        }
      } else {
        this.pageSize = Number(page.pageSize)
      }
      if (isNaN(route.query.pageSize) || isNaN(route.query.pageNum)) {

        this.$router.replace({
          name: route.name,
          query: Object.assign({}, this.$route.query, {
            pageSize: page.pageSize,
            pageNum: page.pageNum||1
          })
        })
        return false;
      }
//新增默认值
        var filter = {};

    this.filters.forEach((item, index) => {
          if (item.value) {
            if (Array.isArray(item.field)) {
              item.field.forEach((key, index) => {
                filter[key] = item.value[index];
              });
            } else {
              filter[item.field] = item.value;
            }
          }
        });
    console.log(this.filters,'filters')
      var data = Object.assign({
        pageSize: page.pageSize,
        pageNo: page.pageNum
      }, {...filter, ...this.paramsFilter});
      data[this.fields.PAGE_SIZE] = data.pageSize
      data[this.fields.PAGE_NUMBER] = data.pageNo
      delete data.pageSizes
      delete data.pageNo
      // this.pageNum=page.pageNum
      
 
      this.$emit('data-refresh', data)
    },
    executeFilt() {
      this.$refs.datafilter.handleFilter()
      this.$refs.datafilter.isShowAll()
    },
    doFilterShowAll(){

      this.$emit('data-showAll')
    },
  }
}
</script>

<style>
</style>
