<template>
  <div class="account-result1">
    <div class="result-tips">
      <i class="el-icon-info"></i>
      温馨提示：亲爱的用户，银行已经受理了您的开户请求和相关资料，请耐心等待开户结果，开户结果会在1-3个工作日内进行通知。请及时登录系统查看。
    </div>
    <div class="result-box">
      <img src="../assets/login/rz_dengdai.png" alt="" class="result-icon">
      系统检测到贵司开户状态为：
      <div class="result-text">开户中</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },

  methods: {

  }
};
</script>

<style lang='scss' scoped>
.result-tips {
  background: rgba(235, 244, 255, 1);
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(204, 224, 255, 1);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  i {
    color: #1989fa;
  }
}
.result-box {
  color: #999;
  text-align: center;
  .result-icon {
    display: block;
    margin: 16px auto;
  }
  .result-text {
    color: #000;
    font-size: 24px;
    margin-top: 10px;
  }
}
</style>
