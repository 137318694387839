<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby goback yfdtwo">
    
          <absxmgk></absxmgk>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定资产账款</div>
        <div class="httotal">
          已添加资产账款
          <span>{{multipleSelection.length}}</span> 笔，账款总计金额
          <span>{{ $changeMoneyThousand(mounttoall)}}</span>
          元。
        </div>
        <div>
          <el-table
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="65"></el-table-column>
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额（元）" min-width="180">
              <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="160">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="160">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-hd" style="height: 150px;">
            <el-row style="padding-top: 40px;">
              <el-col :span="24"  class="lb_body" style="text-align:center">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="approvalApply"
                >发起融资申请</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, add } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import absxmgk from "@/views/absbase/absxmgk";




export default {
         components: {
           absxmgk

      
        },
  data() {

    return {
      moment: moment,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["专项计划简介"],
      mounttoall:0,
      multipleSelection: [], //选中的表格
      tableList:[]
    };
  },
  watch: {
    multipleSelection(newName, oldName) {
      this.jsmount(newName);
    }
  },
  created() {
    this.getList();

  },
  methods: {
    getList() {
      let claimsCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      api
        .GetRelationReceivedAccountsById({ claimsCorpId: claimsCorpId })
        .then(res => {
          if (res.success) {
            this.tableList = res.datas.list;
          }
        });
    },


    jsmount(newName) {
      var arr = [];
      newName.map(el => {
        arr.push(el.accountAmount);
      });
      this.mounttoall = (0).add(...arr);

    },
    approvalApply(){

    },
    handleSelectionChange(){

    }
 
  }
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  padding-bottom: 10px;
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
</style>
