<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column label="放款金额（元）" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.financeAmount)}}</template>
          </el-table-column>
          <el-table-column prop="financeInterest" label="融资年化利率（％）" min-width="150"></el-table-column>
          <el-table-column label="预计融资利息（元）" min-width="180">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.expectedFinancingInterest)}}</template>
          </el-table-column>
          <!-- <el-table-column label="预计融资手续费（元）" min-width="100">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.financeAmount)}}</template>
          </el-table-column> -->
          <el-table-column label="平台服务费（元）" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.platformServiceFee)}}</template>
          </el-table-column>
          <el-table-column label="平台杂费（元）" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.platformMiscellaneousFee)}}</template>
          </el-table-column>
          <el-table-column label="预计放款净额（元）" min-width="180">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.estimatedNetWithdrawal)}}</template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <upload class="uploadBox" :receivableAccountFinanceId="scope.row.receivableAccountFinanceId" @getLists="getLists"></upload>
              <el-button size="medium" type="text" @click="handleDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api.js";
import moment from 'moment'
import upload from './upload'
export default {
  components: {
    upload
  },
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "放款金额(元): ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small"
        },
        // {
        //   label: "放款净额(元): ",
        //   type: "inputrange",
        //   field: "financeAmountMin",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "financeAmountMax",
        //   size: "small"
        // }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.withdrawStatus = 1  // 1未提现 2已提现
      api.fundWithdrawPenging(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: "/factoring/FactoringQuoteDetial",
        query: {
          receivableAccountFinanceId:row.receivableAccountFinanceId,
          type:'2',
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.uploadBox {
  display: inline-block;
}
</style>
<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
