import axios from 'axios';
import { MessageBox } from 'element-ui';
import { Loading } from 'element-ui';
import { getUUID } from '../util/index.js';
var _axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 55 * 1000,
});

function interceptor() {
  var nowTime = '';
  _axios.interceptors.request.use(
    (config) => {
      loadFuc.showFullScreenLoading();
      nowTime = Date.now();
      // if(nowTime-sessionStorage.logtimer>60000*5&&nowTime-sessionStorage.logtimer<3600000*12){
      //   axios.get(baseURL+'/login/refreshToken?refreshToken='+sessionStorage.refreshtoken).then(function(res){
      //     console.log(res,123)
      //   }).catch(function (res){
      //     console.log(res);//发生错误时执行的代码
      //   });
      // }
      var token = sessionStorage.getItem('token');
      const traceId = getUUID().split('-').join('');
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        traceId,
      };
      config.headers.Authorization = token;
      return config;
    },
    (error) => {
      loadFuc.tryHideFullScreenLoading();
      return Promise.reject(error);
    }
  );
  _axios.interceptors.response.use(
    (response) => {
      loadFuc.tryHideFullScreenLoading();
      return response.data;
    },
    (error) => {
      loadFuc.tryHideFullScreenLoading();
      if (error.response) {
        switch (error.response.status) {
          case 401:
            vm.$router.push({ path: '/Login' });
            vm.$message.warning('登录过期！');
            sessionStorage.clear();
            break;
          case 500:
            MessageBox.alert('服务器错误', '', {
              type: 'error',
            });
            break;
          case 504:
            MessageBox.alert('连接服务器超时', '', {
              type: 'error',
            });
            break;
          case 408:
            MessageBox.alert('请求超时', '', {
              type: 'error',
            });
            break;
          case 404:
            MessageBox.alert('服务不存在', '', {
              type: 'error',
            });
            break;
          case 403:
            vm.$router.push({ path: '/forbidden' });
            vm.$message.warning('无权限访问');
            break;
          // default:
          //   MessageBox.alert('请求失败，请重试', '', {
          //     type: 'error'
          //   })
          //   break;
        }
      }
      return Promise.reject(error);
    }
  );
}
export default {
  interceptor,
  _axios,
};
let loadFuc = {
  loading: '',
  startLoading: function () {
    this.loading = Loading.service({
      lock: true,
      // text: 'Loading',
      background: 'rgba(0, 0, 0, 0)',
      target: '.container',
      // target: '.content-wrapper'
    });
  },
  endLoading: function () {
    this.loading.close();
  },
  needLoadingRequestCount: 0,
  showFullScreenLoading: function () {
    if (this.needLoadingRequestCount === 0) {
      this.startLoading();
    }
    this.needLoadingRequestCount++;
  },
  tryHideFullScreenLoading: function () {
    if (this.needLoadingRequestCount <= 0) {
      return;
    }
    this.needLoadingRequestCount--;
    if (this.needLoadingRequestCount === 0) {
      this.endLoading();
    }
  },
};
