<!-- 票据详情 -->
<template>
    <div class="abs-check-details">
        
            <div class="abs-title">需置换账款: </div>

              <div class="httotal">
            资产包共有<span>{{moneyfix.allSize}}</span> 笔账款，账款总计金额  <span>{{$changeMoneyThousand(moneyfix.allAmount)}}</span> 元，需要置换的资产<span>{{moneyfix.exSize}}</span>笔，账款总金额为 <span>{{$changeMoneyThousand(moneyfix.exAmount)}}</span> 元。

         </div>
   <div style="margin-bottom:20px;">
    <el-button type="primary"  @click="openfundsearch">{{isadd?'追加资产':'资产置换'}}</el-button>
   </div>
  
        <el-table
 
          :data="arrlist"
            align='center'
          highlight-current-row
      
          style="width: 100%;"
        >
        <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>
          <el-table-column prop="claimsCorpName" label="债权企业" min-width="210" v-if="isbl||planCorpType==2" ></el-table-column>
          <el-table-column prop="debtCorpName" label="债务企业" min-width="210" v-if="isbl||planCorpType==3" ></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>

          <el-table-column  label="签署日期"  prop='absAccountSignDate' min-width="160" >
                <template slot-scope="scope">
               {{scope.row.absAccountSignDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column  label="计划付款日期"  prop='absAccountCloseDate' min-width="160" >
                  <template slot-scope="scope">
               {{scope.row.absAccountCloseDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column label="操作" width="160" align="left"       >
            <template slot-scope="scope" >
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:isadd?1:9,absPlanId:$route.query.id}}" class="initiation">查看详情</router-link>

                            <el-button @click="delfn(scope.row)" type="text" v-if="isadd">删除</el-button>
                
               
       
            </template>
          </el-table-column>
        </el-table>

        <el-dialog title='资产置换条件' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false'  width='800px' class="fundsearch" >
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                      <el-row >
                        <el-col :span="24">
                            <el-form-item label="债权企业: " prop="claimsCorpName" v-if="planCorpType==2">
                                <el-input v-model="ruleForm.claimsCorpName"></el-input>
                            </el-form-item>
                        </el-col>                   
                      </el-row>
                       <el-row >
                        <el-col :span="24">
                            <el-form-item label="债务企业: " prop="debtCorpName" v-if="planCorpType==3">
                                <el-input v-model="ruleForm.debtCorpName"></el-input>
                            </el-form-item>
                        </el-col>                   
                      </el-row>
                        <el-row >
                        <el-col :span="24">
                            <el-form-item label="账款金额: " >
                                <el-col :span="11">
                                    <el-form-item prop="absAccountMinAmount">
                                    <el-input v-model="ruleForm.absAccountMinAmount"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="2" style="text-align: center;">-</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="absAccountMaxAmount">
                                    <el-input v-model="ruleForm.absAccountMaxAmount"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>    
                        </el-col>                
                      </el-row>
                         <el-row >
                        <el-col :span="24">
                            <el-form-item label="签署日期: " >
                                 <el-date-picker
                                    v-model="ruleForm.signdate"
                                    @change='signdatefn'
                                    value-format='yyyy-MM-dd'
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                    </el-date-picker>
                            </el-form-item>    
                        </el-col>                
                      </el-row>
                         <el-row >
                        <el-col :span="24">
                            <el-form-item label="计划付款日期: " >
                                           <el-date-picker
                                            v-model="ruleForm.plandate"
                                             value-format='yyyy-MM-dd'
                                    @change='plandatefn'

                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                                            </el-date-picker>
                            </el-form-item>    
                        </el-col>                
                      </el-row>
                 
              
                
                    <el-form-item>
                        <div style="text-align:center">
                            <el-button  @click="showAddDialog2=false">取消</el-button>
                            <el-button @click="resetForm('ruleForm')" type="primary">查询</el-button>
                        </div>
                     
                    </el-form-item>
                    </el-form>
        </el-dialog>

        <el-dialog title='资产包详情' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation" >
              
            <el-table
            :data="zclist"
            align='center'
            highlight-current-row
            ref="multipleTable"
            style="width: 100%;"
           @selection-change="handleSelectionChange"
        >
                    <el-table-column
                        type="selection"
                        width="80">
                        </el-table-column>
    <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>
          <el-table-column prop="claimsCorpName" label="债权企业" min-width="210" v-if="isbl||planCorpType==2" ></el-table-column>
          <el-table-column prop="debtCorpName" label="债务企业" min-width="210" v-if="isbl||planCorpType==3" ></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>

          <el-table-column  label="签署日期"  prop='absAccountSignDate' min-width="160" >
                <template slot-scope="scope">
               {{scope.row.absAccountSignDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column  label="计划付款日期"  prop='absAccountCloseDate' min-width="160" >
                  <template slot-scope="scope">
               {{scope.row.absAccountCloseDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column label="操作" width="120" align="left"       >
            <template slot-scope="scope" >
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:1}}" class="initiation">查看详情</router-link>
               
       
            </template>
          </el-table-column>
        </el-table>


          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取消</el-button>
              <el-button @click="choosechildfn" type="primary" >确 定</el-button>
          </div>
        </el-dialog>
        
       
    </div>
</template>
<script>
    import moment from 'moment'

    import api from "@/api/api";



    export default {
        props: {
            datalist: {
                type: Array,
                default() {
                    return []
                }
            },
             moneyfix: {
                type: Object,
                default() {
                    return {}
                }
            },
            planCorpType:{
                 type: Number,
                default() {
                    return 1
                }
            }
        },
        watch:{


        },
        data() {
            return {
                showAddDialog:false,
                showAddDialog2:false,
                zclist:[],
                isbl:false,
                isadd:false,
                ruleForm:{
                    absAccountCloseDateEnd:'',
                    absAccountCloseDateStart:'',
                    absAccountMaxAmount:'',
                    absAccountMinAmount:'',
                    absAccountSignDateEnd:'',
                    absAccountSignDateStart:'',
                    absPlanId:this.$route.query.id,
                    claimsCorpName:'',
                    debtCorpName:'',
                    pageNum:1,
                    pageSize:100





                },
                rules:{},
          
                countlist:{
                    absPlanId:this.$route.query.id,
                    list:[]
                },
                multipleSelection:[],
                arrlist:[]

              
            }
        },
        mounted() {
         this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false
        this.arrlist=JSON.parse(JSON.stringify(this.datalist))
                console.log(this.datalist,'datalist',this.planCorpType) 

     
        },
        methods: {
            moment: moment,
            getapproveStatus(data){
                 switch (data.approveStatus) {
                     case 0:
                      return  '初始'   
                         break;
                     case 1:
                      return  '新增'   
                         break;
                        case 2:
                      return  '需置换'   
                         break;
                        case 3:
                      return  '已锁定'   
                         break;
                 }
            },
            handleopen(row){
                window.open('/abs/absplaninfo?id=14&isshowbtn=2')
            },
            openfundsearch(){
                this.showAddDialog2=true
            },
            signdatefn(val){
                if(val){
                 this.ruleForm.absAccountSignDateStart=val[0]
                this.ruleForm.absAccountSignDateEnd=val[1]
                }else{
                this.ruleForm.absAccountSignDateStart=''
                this.ruleForm.absAccountSignDateEnd=''
                }
           

            },
            plandatefn(val){
                 if(val){
                    this.ruleForm.absAccountCloseDateStart=val[0]
                    this.ruleForm.absAccountCloseDateEnd=val[1]
                }else{
                      this.ruleForm.absAccountCloseDateStart=''
                      this.ruleForm.absAccountCloseDateEnd=''
                }

             
            },
            resetForm(){
              api.getUnboundAbsAccountList(this.ruleForm).then(res =>{ //审批结果查询
                if(res.success) {
                
                        this.zclist=res.datas.list
                        this.showAddDialog=true
                        this.arrlist.map(el=>{
                              this.zclist.map((ele,index)=>{
                                   if(ele.absAccountId==el.absAccountId){
                                       this.zclist.splice(index,1)
                                   }
                              })
                        })

   
                    }else{
                        this.$message.error(res.message)

                    }
                })
        
            },
            choosechildfn(){
              
               if(this.isadd){
                    this.multipleSelection.map(el=>{
                          this.arrlist.push(el)
                    })
                 
               }else{
                    this.arrlist=this.multipleSelection
               }
                     this.isadd=true
                    this.showAddDialog=this.showAddDialog2=false

            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //submitReplacementAccount
            },
            delfn(row){
                this.arrlist.splice(this.arrlist.findIndex(el=>el.absAccountId==row.absAccountId),1)
               
            }
     
        }
    }
</script>
<style lang="scss">
  .abs-check-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
       a.initiation{
                 text-decoration: none;
               color: #409EFF;
       }
       .fundsearch{
           .el-dialog__body{
               padding-right: 90px;
           }
           .el-date-editor.el-range-editor.el-date-editor--daterange{
               width: 100%;
           }
       }
  }

  
 
  
</style>