import { render, staticRenderFns } from "./easypaymessage.vue?vue&type=template&id=2b69af48&scoped=true"
import script from "./easypaymessage.vue?vue&type=script&lang=js"
export * from "./easypaymessage.vue?vue&type=script&lang=js"
import style0 from "./easypaymessage.vue?vue&type=style&index=0&id=2b69af48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b69af48",
  null
  
)

export default component.exports