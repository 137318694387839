<template>
    <div class="billyxjg bill-details">
            <div class="bill-title">有意向资金机构 <span class="billbjpc">有<span>{{datainfo.sumQuote}}</span>个意向报价，最低意向利率<span>{{datainfo.minInterest}}</span>%，最高预计成交金额  <span>{{$changeMoneyThousand(datainfo.maxDealAmount)}}</span> 元人民币</span></div>

              <el-table
                ref="singleTable"
                :data="tableData"
                highlight-current-row

                style="width: 100%">
                <el-table-column

                width="50"

                >
                     <template slot-scope="scope">
                        <el-radio v-model="radio" :label="scope.row.billFundQuoteId" :disabled='!scope.row.billFundQuoteId'></el-radio>

                    </template>
                </el-table-column>
                <el-table-column
                prop="fundCorpName"
                label="意向企业"
                min-width="120">
                </el-table-column>
                <el-table-column
                prop='fundCorpCode'
                label="社会统一信用代码"
                width="200">
                </el-table-column>
                <el-table-column
                prop="offerPriceYearInterest"
                sortable
     min-width="180"

                label="意向利率(%)" >
               <template slot-scope="scope">
                       {{scope.row.offerPriceYearInterest?scope.row.offerPriceYearInterest:'未报价'}}
    

                    </template>
                
                </el-table-column>
                  <el-table-column
                prop="chargeAmount"
                align='right'
                sortable
     min-width="200"

                label="意向每十万扣费(元)">
                <template slot-scope="scope">
                       <!-- {{isNaN($changeMoneyThousand(scope.row.chargeAmount))?'未报价':$changeMoneyThousand(scope.row.chargeAmount)}} -->
                     {{/\./.test($changeMoneyThousand(scope.row.chargeAmount))?$changeMoneyThousand(scope.row.chargeAmount):'未报价'}}
    

                    </template>
                </el-table-column>
                  <el-table-column
                prop="address"
                align='right'
     min-width="180"

                label="预计扣费总额(元)">
                       <template slot-scope="scope">
      
                       <!-- {{isNaN($changeMoneyThousand(scope.row.allChargeAmount))?'未报价':$changeMoneyThousand(scope.row.allChargeAmount)}} -->
                     {{/\./.test($changeMoneyThousand(scope.row.allChargeAmount))?$changeMoneyThousand(scope.row.allChargeAmount):'未报价'}}

                    </template>
                </el-table-column>
                  <el-table-column
                    align='right'
                    min-width="180"
                    label="预计成交金额(元)">
                      <template slot-scope="scope" >
                     {{/\./.test($changeMoneyThousand(scope.row.dealAmount))?$changeMoneyThousand(scope.row.dealAmount):'未报价'}}
                    </template>
                
           

                </el-table-column>
                  <el-table-column
                prop="date"
                sortable
     min-width="180"

                label="有效确认时间">
                          <template slot-scope="scope">
                       {{scope.row.quoteTimeLimit|quoteLimit}}

                    </template>
                </el-table-column>
            </el-table>
    </div>

</template>
<script>
    import api from "@/api/api.js";


    export default {
        components: {

        },
        data() {
            return {
                tableData: [],
                  radio:'',
                  datainfo:{}

            }
        },
        created() {
            this.getinfo()
            this.getBillQuoteCensusByBillId()
        },
        methods: {

                getinfo(){

                         api.getBillQuoteListByBillId({billId: this.$route.query.id}).then(res => {
                                          if(res.success){
                                               this.tableData=res.datas.billFundQuoteList
                                          }

                       })


                },
                getBillQuoteCensusByBillId(){
                            api.getBillQuoteCensusByBillId({billId: this.$route.query.id}).then(res => {
                                          if(res.success){
                                               this.datainfo=res.datas.resp
                                          }

                       })

                }

        }
    };
</script>


<style lang="scss">
.bill-details .bill-title{
   .billbjpc{
       font-size: 14px;
       font-weight: normal;
       text-indent: 5em;
       display: inline-block;
       span{
           color:forestgreen;
       }
   } 
}

</style>
