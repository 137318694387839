<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
     align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="170"></el-table-column>
          <el-table-column prop="absPlanName" label="计划名称" min-width="170"  >
    
          </el-table-column>
          <el-table-column  label="计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'></el-table-column>
          <el-table-column  label="计划发起人"  min-width="160" prop='planSponsor' ></el-table-column>
          <el-table-column  label="结束原因"  min-width="160" prop='overReason' :formatter='el=>reasonfn(el)' ></el-table-column>
          <el-table-column  label="结束时间"  min-width="160" >
                      <template slot-scope="scope">
                          {{scope.row.overTime|formatDate}}
            </template>
          </el-table-column>
 
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
   

        {
          label: "项目名称: ",
          type: "input",
          field: "absPlanName",
          size: "small",
        },
      ],
      total: 0,
      listData: [],
      isbl:false
    };
  },
  created() {
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false
  },
  methods: {
    moment: moment,

    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      api.endedList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        
        }
      });
    },
   handleEditinfo(row){
  
       

           this.$router.push({
         name: "abswaitsigngysinfo",
                    query: { id: row.absPlanId,absAccountSign:0,leaderCorpType:this.isbl?1:2}
      });
    },
    reasonfn(data){
        switch (data.overReason) {
            case 1:
             return '超时未接受'
                break;
            case 2:
             return '未接受计划'
                break;
             case 3:
             return '计划已结束'
   
        }
    
    }

  },
 
};
</script>


