<!--承诺付款协议-->
<template>
<div>
        <p style="line-height:39.65pt; margin:0pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:18pt">付款承诺函</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:286pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">编号：</span></p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">因 </span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">{{payData.receiveCompanyName}} </span><span
                style="font-family:微软雅黑; font-size:12pt">有限公司（以下简称“卖方”）向我司提供货物、服务或设施，我司承担相应付款责任，我司保证总计人民币（大写）</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">{{payData.amountMoney}}</span><span
                style="font-family:微软雅黑; font-size:12pt">的应付账款在</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline" v-if="payData.promisePayDate"> {{ moment(payData.promisePayDate).format("YYYY-MM-DD")}}</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline"> </span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline"> </span><span
                style="font-family:微软雅黑; font-size:12pt">（以下简称“</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">到期日”）前，按照我司在经证供应链平台接收到的清分明细表所列明的收款方（以下简称“最终持单人”）的收款信息付款。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">我司确认并同意：</span></p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">1、出具本承诺函（本承诺函包括全部和部分流转的优企通），即视为我司同意无条件按照清分明细表的信息付款。</span></p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">2、我司同意《应收账款电子凭证业务服务协议（适用</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">产品）》、《</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单协议》的相关约定，同意卖方在取得我司开立的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">后，有权将</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">项下我司应付款项全部或部分流转给其债权人或资金方。通过</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">全部</span><span
                style="font-family:微软雅黑; font-size:12pt">或部分流转，持有</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">卖方债权人或资金方（以下简称“新债权人”）有权要求我司履行付款义务，同时卖方不再享有向我司追索已流转</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">项下款项的权利。新债权人亦有权继续转让</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">，我司将依据经证供应链平台发送的清分明细表所列明的信息向</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">最终持单人付款，该付款义务的履行不受</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">流转</span><span
                style="font-family:微软雅黑; font-size:12pt">相关方之间、我司与卖方或我司与第三方之间任何商业纠纷的影响。</span></p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">3、我司与卖方签署的商务合同或订单等相关协议中约定的收款账户、</span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">到期</span><span
                style="font-family:微软雅黑; font-size:12pt">日、付款金额等信息与清分明细表中所列明的信息不一致时，以我司在经证供应链平台收到的清分明细表所列明的信息为准。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">本承诺函经</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">盖章（含电子签章）后生效，生效后所附</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold"></span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">自动</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">生效，不得撤销或更改。</span></p>
               
        <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:right; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">承诺方： </span><span
                style="font-family:微软雅黑; font-size:12pt">{{payData.currentComapnyName}}</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:right; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">{{payData.today}}</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
              
    </div>
    
    <!-- <div>
        <p style="line-height:39.65pt; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
            <a name="_cmntref1"></a><a name="_cmntref2"><span style="font-family:宋体; font-size:22pt">付款承诺函</span><span
                    style="-aw-comment-end:_cmntref1"></span></a></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:286pt; widows:0"><span style="font-family:宋体; font-size:12pt">编号：</span></p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">因 </span><span style="font-family:宋体; font-size:12pt;text-decoration:underline;color: #333;">{{payData.receiveCompanyName}}</span><span style="font-family:宋体; font-size:12pt;">有限公司（以下简称“卖方”）向我司提供货物、服务或设施，我司承担相应付款责任，我司保证总计人民币（大写）</span>
        <span style="font-family:宋体; font-size:12pt; text-decoration:underline;color: #333;">{{payData.amountMoney}}</span><span style="font-family:宋体; font-size:12pt">的应付账款在</span>
        <span style="font-family:宋体; font-size:12pt; text-decoration:underline;color: #333;"> {{ moment(payData.promisePayDate).format("YYYY-MM-DD")}}</span>
            <span style="font-family:宋体; font-size:12pt; ">（以下简称“承诺付款日”）前</span><span
                    style="font-family:宋体; font-size:12pt">，按照我司在深圳经证商业保理有限公司运营的互联网平台（网址：XXX，以</span><span style="font-family:宋体; font-size:12pt">下简称“优企通平台”）接收到的《最终还款明细表》所列明的收款方（以下简称“最终持单人”）的收款信息付款。</span></p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">我司确认并同意：</span></p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">1、我司基于本承诺函开立优企通，保证在接到本承诺函（本承诺函包括全部和部分流转的优企通），即无条件按照</span>
            <a name="_cmntref3"></a><a name="_cmntref4"><span
                    style="font-family:宋体; font-size:12pt">《最终还款明细表》</span><span
                    style="-aw-comment-end:_cmntref3"></span></a><span style="-aw-comment-end:_cmntref4"></span><span style="font-family:宋体; font-size:12pt">的信息付款。</span></p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">2、我司同意《优企通服务协议》、《优企通开单协议》的相关约定，同意卖方在取得我司开立的优企通后，有权将优企通项下我司应付款项全部或部分流转给其债权人。通过优企通全部或部分流转，持有优企通的卖方债权人（以下简称“新债权人”）有权要求我司履行付款义务，同时卖方不再享有向我司追索已流转优企通项下款项的权利。新债权人亦有权继续转让优企通，我司将依据优企通平台发送的《最终还款明细表》所列明的信息向优企通的最终持单人付款，该付款义务的履行不受优企通流转相关方之间、我司与卖方或我司与第三方之间任何商业纠纷的影响，我司不就该付款责任主张抵销或者进行抗辩。</span>
        </p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">3、我司与卖方签署的商务合同或订单等相关协议中约定的收款账户、承诺付款日、付款金额等信息与《最终还款明细表》中所列明的信息不一致时，以我司在优企通平台收到的《最终还款明细表》所列明的信息为准。</span>
        </p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt">4、如我司未按《最终还款明细表》所列明的信息，在上述约定的承诺付款日前付款</span><span style="font-family:宋体; font-size:12pt">，</span><span style="font-family:宋体; font-size:12pt">需按拖欠款项的实际天数及</span>
            <span style="font-family:宋体; font-size:12pt">{{payData.penaltyInterest}}%/年的逾期利率向持单人支付逾期利息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt 0pt 10pt; orphans:0; text-indent:22pt; widows:0"><span style="font-family:宋体; font-size:12pt; font-weight:bold">本承诺函经签章后生效，生效后所附优企通自动生效，不得撤销或更改。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; widows:0"><span style="font-family:宋体; font-size:12pt">&nbsp;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; widows:0">
        <span style="font-family:宋体; font-size:12pt">承诺方：{{payData.currentComapnyName}}</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; widows:0"><span style="font-family:宋体; font-size:12pt">{{payData.today}}</span></p>
    </div> -->
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            payData: {
                receiveCompanyName: '',
                amountMoney: '',
                promisePayDate: '',
                currentComapnyName: '',
                today: '',
            }
        }
    },
    props: {
      payInfo: Object,
    },
    mounted() {
        Object.assign(this.payData,this.payInfo);
        this.payData.today = moment(new Date()).format("YYYY-MM-DD");
    },
    methods: {
        moment
    }
}
</script>
