<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="financePayableBillNo" label="优企通编号" min-width="170"></el-table-column>
         <el-table-column prop="originalName" label="开单企业" min-width="120" />
          <!-- <el-table-column prop="payableBillAmount" label="优企通金额(元)" min-width="170"></el-table-column> -->
          <el-table-column prop="financeYearInterest" label="融资年化利率(%)"  min-width="120"></el-table-column>
          <el-table-column prop="promisePayTime" label="承诺付款日" width="160"  :formatter="el=>moment(el.promisePayTime).format('YYYY-MM-DD')"/>
          <el-table-column prop="payableBillFinanceAmount" label="已融资金额(元)" :formatter='el=>$changeMoneyThousand(el.payableBillFinanceAmount)' min-width="120"></el-table-column>
          <!-- <el-table-column prop="payableBillClearAmount" label="清分金额(元)" min-width="170" :formatter='el=>$changeMoneyThousand(el.payableBillClearAmount)'></el-table-column> -->
          <!-- <el-table-column prop="penalty" label="罚息金额(元)" min-width="130"  :formatter='el=>$changeMoneyThousand(el.penalty)'/> -->
          <!-- <el-table-column prop="status" label="确认状态" min-width="80"  :formatter="statusfn"  /> -->
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)" >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api";
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "优企通编号: ",
          type: "input",
          size: "small",
          field: "payableBillNo",
        },
            {
          label: "承诺付款日:",
          type: "daterange",
          field: ["startPromisePayTime", "endPromisePayTime"],
          size: "small"
        },
        // {
        //   label: "优企通金额: ",
        //   type: "inputrange",
        //   field: "startPayableBillAmount",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "endPayableBillAmount",
        //   size: "small"
        // },

      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: []
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.fundCorpId = JSON.parse(sessionStorage.user).relationCorpId

      api.GetReadyZf(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/fund/easypay/clearCloseDetial",
        query: { 
          payableBillNo: row.payableBillNo,
          financePayableBillNo: row.financePayableBillNo,
          payableBillFinanceId: row.payableBillFinanceId,
          isshowbtn: 2,
          isOverdue: row.penalty && row.penalty != 0 ? 1 : 0  // penalty有不为0的值时，为逾期数据
        }
      });
    },
    statusfn(val){
      var state=''
      switch (val.status) {
        case 1:
          state='未确认'
          break;
        case 2:
          state='已确认'
          break;
        case 3:
          state='部分确认'
          break;
        case 4:
          state='挂起状态'
          break;
           case 5:
             state='无需清分'
          break;
      }
      return state
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
