<template>
  <el-dialog
    title="退回"
    :visible.sync="dialogVisible"
    width="50%"
    append-to-body
    @close="handleClose"
  >
    <el-form ref="form" :model="dataForm" label-width="auto" :rules="rules">
      <el-form-item label="退回原因" prop="reasonRejection">
        <el-select
          v-model="dataForm.reasonRejection"
          placeholder="请选择退回原因"
        >
          <el-option
            v-for="item in reasonRejection"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="请填写备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loadingSubmit" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api/api";

const createDataForm = () => ({
  reasonRejection: null,
  remark: null,
});

export default {
  emits: ["success"],
  data() {
    return {
      dialogVisible: false,
      /** 提交loading */
      loadingSubmit: false,
      dataForm: createDataForm(),
      reasonRejection: [
        {
          label: "融资金额有误",
          value: "1",
        },
        {
          label: "其他",
          value: "2",
        },
      ],
    };
  },
  computed: {
    rules() {
      return {
        reasonRejection: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    show(form = {}) {
      // Object.assign(this.dataForm, form);
      this.dialogVisible = true;
    },
    handleClose() {
      this.dataForm = createDataForm();
    },
    async submit() {
      await this.$refs.form.validate();
      this.dialogVisible = false;
      this.$emit("success", this.dataForm);
    },
  },
};
</script>

<style lang="scss">
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
