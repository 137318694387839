
<template>
  <div class="page-403">
    <div class="btn-box">
      <el-button size="middle" @click="back">返回上一页</el-button>
    </div>
    
    <div class="text">对不起，您没有访问权限！</div>
  </div>
</template>
<script>
export default {

  data() {
    return {

    };
  },
  methods: {
    back() {
      this.$router.go(-2)
    },
  }
};
</script>
<style scoped lang="scss">
.page-403 {
  height: 100%;
  padding: 0 24px;
  background-color: #fff;
  text-align: center;
  .btn-box {
    text-align: right;
  }
  .text {
    font-size: 24px;
    margin-top: 200px;
  }
}
</style>
