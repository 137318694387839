<!-- 票据详情 -->
<template>
    <div class="abs-sign-details">
        
            <div class="abs-title">绑定账款: </div>
              <div class="httotal">
          资产包共有  <span>{{datalist.absAccountList.length}}</span>笔账款,
            
            账款总金额
            <span>{{$changeMoneyThousand(datalist.accountAllAmount)}}</span> 元。
         </div>

  
        <el-table
          :data="datalist.absAccountList"
            align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>
          <el-table-column prop="contractNo" label="合同编号" min-width="170"  >
    
          </el-table-column>


                   <el-table-column prop="debtCorpName" label="债务企业" min-width="210"></el-table-column>
                   <el-table-column prop="claimsCorpName" label="债权企业" min-width="210"></el-table-column>

          
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>
 

          <el-table-column  label="签约状态"  prop='approveStatus' min-width="120" :formatter='getapproveStatus' >

          </el-table-column>
          <el-table-column label="转让通知" width="220" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleopen(scope.row)" v-if="scope.row.factoringTransferNotify==1" >上传转让通知书</el-button>
              <!-- 当这里是这个 factoringTransferNotify === 3 时，这里将会直接跳转到线上 签约合同的状态。这个时候，即会出现合同的东西。 -->
              <el-button size="medium" type="text" @click="toSignDetail(scope.row)" v-if="scope.row.factoringTransferNotify==3" >上传转让通知书</el-button>
              <el-button size="medium" type="text" @click="handleseetext(scope.row,6,1)" v-if="scope.row.factoringTransferNotify==2">查看通知书</el-button>
              <el-button size="medium" type="text" @click="handleseetextShow(scope.row,7)" v-if="scope.row.uploadReceiptForFactoring==2">查看回执</el-button>
            </template>
          </el-table-column>
            <el-table-column label="付款确认书" width="160" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleseetext(scope.row,8, 2)" :disabled='scope.row.coreUploadPay==1'>查看确认书</el-button>
            </template>
          </el-table-column>
          <el-table-column label="签约"  min-width="240" align="left" v-if="!$route.query.issign">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row,1)" :disabled="scope.row.signPledgeContact==1">签署质押合同</el-button>
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row,2)" :disabled="scope.row.signFactoringContact==1">签署保理合同</el-button>

            </template>
          </el-table-column>

              <el-table-column label="签约"  min-width="240" align="left" v-if="$route.query.issign">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo2(scope.row,1)" >查看质押合同</el-button>
              <el-button size="medium" type="text" @click="handleEditinfo2(scope.row,2)" >查看保理合同</el-button>

            </template>
          </el-table-column>
        </el-table>

  <el-dialog title='上传转让通知书' v-if='showAddDialog' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation">
              
        <el-upload
         class="upload-demo"
            :action="baseURL+'/web/file/uploadFile'"
            :headers='Authorization'
            list-type="picture"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
          

             drag   

        >
          <i class="el-icon-plus"></i>
         <div class="el-upload__text">上传通知书</div>
</el-upload>


          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取 消</el-button>

              <el-button @click="uploadfn" type="primary">确 定</el-button>
          </div>
        </el-dialog>
      

      <el-dialog :visible.sync="showPact" :close-on-click-modal="false" :show-close="true" center  width="50%" class="img-dialog">
        <el-radio v-model="radio" label="1">上传转让通知书</el-radio>
        <!-- <el-radio v-model="radio" label="2">上传待签约合同</el-radio> -->
        <el-radio v-model="radio" label="3">线上签署转让通知书</el-radio>

        <div v-if="radio == 1" class="uploadfont wxtsk uploadContract">
            温馨提醒：确定已完成线下签约，请上传已签约合同附件。
          <br />
        </div>

        <div v-if="radio == 3" class="uploadfont wxtsk uploadContract">
            温馨提醒：将跳转到线上转让通知书签约页面，请观看注意事项并进行签约操作。合同附件格式PDF
          <br />
        </div>

        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
            <el-button @click="showPact = false">取消</el-button>
            <el-button type="primary" @click="subMitPact">确定</el-button>
        </span>
    </el-dialog>
        
       
    </div>
</template>
<script>
    import moment from 'moment'
    import api from "@/api/api";

import { baseURL } from '@/api/sys';



    export default {
        props: {
            datalist: {
                type: Object,
                default() {
                    return {}
                }
            },
             moneytotal: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        watch:{


        },
        data() {
            return {
                showAddDialog:false,
                imageUrl: '',
                Authorization:{Authorization:sessionStorage.token},
                baseURL,
                 uploadpic:{
                            absAccountId:'',
                            absPlanId:this.$route.query.id,
                            absAccountFileVoList:[]                       
                 },

                  //控制弹框的出现隐藏
                showPact:false,
                radio:"1",
                //这个参数 是点击了上传转让通知书 之后， 将会将下边的这个值 赋值如下
                showPactMsg:{}, 

            }
        },
        mounted() {
                console.log(this.datalist,'datalist',this.$route) 
     
        },
        methods: {
            // handleopen(row){
             
            //     this.showAddDialog=true
            //     this.uploadpic.absAccountId=row.absAccountId;
            //     this.uploadpic.absAccountFileVoList=[]
            // },
            /** 此处是点击了上传 转让通知书的按钮  之后， 触发的事件，是这样的。 */
          handleopen(row){
                this.showPactMsg = row;
                console.log(row,123)
                // this.showAddDialog=true;  此处将会触发将按钮弹框弹出来。
                this.showPact = true;  //这里将会出现一个弹框，弹框中有两种 情况一种线上， 一种弹框。
                // this.uploadpic.absAccountId=row.absAccountId;
                // this.uploadpic.absAccountFileVoList=[]

          },

          /*  这里是链属企业  E-ABS 专项计划页面中 待签约  应收账款中 ，点击了上传转让通知书按钮之后的 弹框样式是这样的。 */
          subMitPact(){
              if(this.radio == '1'){
                  // this.handleNew(this.showPactMsg);
                  this.showAddDialog=true;
                  this.uploadpic.absAccountId=this.showPactMsg.absAccountId;
                  this.uploadpic.absAccountFileVoList=[];

              }else if(this.radio == '3'){
                  this.toSignDetail(this.showPactMsg);
              }
          },
          /**  当状态为3的时候，将会跳转到线上签约的模式  跳转路由页面的写法如下所示：  */
          toSignDetail(data){
              console.log(data);
              this.$router.push({
                  path: "/abs/AbswaisigngysDetail",
                  query: {
                    absAccountId:data.absAccountId,
                    absPlanId: this.uploadpic.absPlanId,
                    signType: '4',
                    // receivableAccountFinanceId:data.receivableAccountFinanceId,
                    // receivableAccountId:data.receivableAccountId,
                    isshowbtn: '1',
                    // fundCorpId: data.fundCorpId,
                    corpType:'2',
                  }
              });
          },





              moment: moment,
              handleEditinfo(row,type){
                    this.$router.push({
                        name:'abswaitcontract',
                        query:{id:row.absAccountId,type:type,isshowbtn:1,absPlanId:this.$route.query.id}
                    })
        
             },
               handleEditinfo2(row,type){
                    this.$router.push({
                        name:'abswaitcontract',
                        query:{id:row.absAccountId,type:type,isshowbtn:2,absPlanId:this.$route.query.id}
                    })
        
             },
            getapproveStatus(data){
  
                var  str=''
                    if(data.absPledgeSignStatus==50&&data.absFactoringSignStatus==50){
                            str='双方已签约'
                    }else if(data.absPledgeSignStatus==41&&(data.absFactoringSignStatus==50||data.absFactoringSignStatus==41)){
                            str='资方已签约'
                    }else if(data.absFactoringSignStatus==41&&(data.absPledgeSignStatus==50||data.absPledgeSignStatus==41)){
                            str='资方已签约'
                    }else if(data.absPledgeSignStatus==31&&(data.absFactoringSignStatus==50||data.absFactoringSignStatus==31)){
                            str='企业已签约'

                    }else if(data.absFactoringSignStatus==31&&(data.absPledgeSignStatus==50||data.absPledgeSignStatus==31)){
                            str='企业已签约'

                    }else if (
                          data.absFactoringSignStatus == 40 ||
                          data.absPledgeSignStatus == 40
                        ) {
                          str = "资方签约中";
                        } else if (
                          data.absFactoringSignStatus == 30 ||
                          data.absPledgeSignStatus == 30
                        ) {
                          str = "企业签约中";
                        } else {
                          str = "双方待签约";
                        }
                    return str
            },
            


            handleAvatarSuccess(response, file) {
                  if(response.code=='100000'){
                               var  data=response.datas.uploadDate.message
                          
                             this.uploadpic.absAccountFileVoList.push({fileType:6,fileUrl:data,uid:file.uid,fileName:file.name})
                         }else{
                             

                         this.$message.error(file.name+'识别错误，请重新上传')

                         }

            },
            handleRemove(file){

                if(this.uploadpic.absAccountFileVoList.findIndex(el => el.uid == file.uid)!=-1){

                    this.uploadpic.absAccountFileVoList.splice(this.uploadpic.absAccountFileVoList.findIndex(el=>el.uid==file.uid),1)
            
     
                }
              

            },
            beforeAvatarUpload(file) {
                let index = file.name.lastIndexOf('.')
                let suffix = file.name.substr(index + 1)
                 if(!/jpg|gif|bmp|png|jpeg/i.test(suffix)){
                        this.$message.error('请上传png,jpg,jpeg格式的图片')
                        return false
                  }
            
            },
            uploadfn(){
                
              api.uploadAccountFile(this.uploadpic).then(res =>{ 
                    if(res.success) {
                        this.$message.success('上传成功')

                        //这里要及时的将首先跳出的弹框给关闭掉了。
                        this.showPact = false; 

                        this.showAddDialog=false

                        

                          this.$emit('fatherMentod')
                  
    
                    }else{
                      this.$message.error(res.message)
                    }
              });
                    
                 console.log(this.uploadpic)

            },

            handleseetextShow(row,type){
               console.log(row,type);
                    if(row.signManagementReceipt === 1){
                          this.$router.push({
                              path: "/abs/AbswaisigngysDetail",
                              query: {
                                absAccountId:row.absAccountId,
                                absPlanId: this.uploadpic.absPlanId,

                                signType: '6',   //查看回执，资金机构 对应的是 保理商，查看回执，这个时候传递参数为6
                                // receivableAccountFinanceId:data.receivableAccountFinanceId,
                                // receivableAccountId:data.receivableAccountId,
                                isshowbtn: '2',
                                // fundCorpId: data.fundCorpId,
                                corpType:'2',
                                onLineShow:1
                              }
                          });

                    }else{
                      ///abstranertext
                      this.$router.push({
                          name:'abstranertext',
                          query:{id:row.absAccountId,type:type,huizhi:4,onLineShow:0}
                      })
                      console.log(row)
                    }
            },


            handleseetext(row,type,isbl){
                if(isbl == 1) {
                    if(row.signManagementNotice === 1){
                        this.$router.push({
                            path: "/abs/AbswaisigngysDetail",
                            query: {
                            absAccountId:row.absAccountId,
                            absPlanId: this.uploadpic.absPlanId,
                            signType: '4',
                            // receivableAccountFinanceId:data.receivableAccountFinanceId,
                            // receivableAccountId:data.receivableAccountId,
                            isshowbtn: '2',
                            // fundCorpId: data.fundCorpId,
                            corpType:'2',
                            onLineShow:1
                            }
                        });
                    }else{
                        this.$router.push({
                            name:'abstranertext',
                            query:{id:row.absAccountId,type:type,huizhi:4,onLineShow:0, pageName:'转让通知书'}
                        })  
                    }
                } else if(isbl == 2) {
                    this.$router.push({
                        name:'abstranertext',
                        query:{id:row.absAccountId,type:type,huizhi:4,onLineShow:0}
                    })
                }
            }
        }
    }
</script>
<style lang="scss">
  .abs-sign-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
       .initiation{
           a{
               text-decoration: none;
               color: #409EFF;
           }
       }
         .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload-dragger{
      height: 100px;
      width: 140px;
      .el-icon-plus{
          font-size: 30px;
          margin-top: 20px;
      }
    
  }
}

  
 
  
</style>