<!-- 票据详情 -->
<template>
    <div class="abs-check-details">
        
            <div class="abs-title">资产封包: </div>
              <div class="httotal">
            本次计划汇集规模为
                <span>{{$changeMoneyThousand(totalAmount)}}</span> 元。
                已选择<span>{{total}}</span>个资产
        总计金额 <span>{{$changeMoneyThousand(totalmoney)}}</span> 元。
         </div>

  
        <el-table
          :data="datalist"
            align='center'
          highlight-current-row
@selection-change='handleSelectionChange'
          style="width: 100%;"
          :cell-class-name='cellname'
          class="parenttab"
           @cell-click='cellopenfn'
           ref="partable"
               @select='partablecheckany'
            @select-all='partablecheckall'
        >
            <el-table-column
      type="selection"
      width="80">
    </el-table-column>
          <el-table-column prop="packageNo" label="资产包编号" min-width="170">
             <template slot-scope="scope">
                   <i class="el-icon-circle-plus-outline"></i>{{scope.row.packageNo}}
            </template>
          </el-table-column>
          <el-table-column prop="corpName" label="资产实际收集人" min-width="170"  >
    
          </el-table-column>
          <el-table-column prop="number" label="包含资产数量" min-width="120"></el-table-column>

          <el-table-column  label="资产合计金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.amount)'></el-table-column>
          <el-table-column prop="choosenum" label="选中资产数量" min-width="120">
      
          </el-table-column>
          <el-table-column  label="选中资产合计金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.chooseamount)'></el-table-column>
        </el-table>

       <el-dialog title='资产包详情' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation" >
              
            <el-table
            :data="zclist"
            align='center'
            highlight-current-row
            ref="multipleTable"
            style="width: 100%;"
            @select='checkany'
            @select-all='checkall'
           
        >
                    <el-table-column
      type="selection"
      width="80">
    </el-table-column>
          <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>
          <el-table-column prop="contractNo" label="合同编号" min-width="170"  >
    
          </el-table-column>
             <el-table-column prop="claimsCorpName" label="债权企业" min-width="210" v-if="isbl||planCorpType==2" ></el-table-column>
          <el-table-column prop="debtCorpName" label="债务企业" min-width="210" v-if="isbl||planCorpType==3" ></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>

          <el-table-column  label="签署日期"  prop='absAccountSignDate' min-width="130" >
                <template slot-scope="scope">
               {{scope.row.absAccountSignDate|formatDate}}
       
                </template>
          </el-table-column>
          <el-table-column  label="计划付款日期"  prop='absAccountCloseDate' min-width="140" >
                  <template slot-scope="scope">
               {{scope.row.absAccountCloseDate|formatDate}}
       
                </template>
          </el-table-column>


          <el-table-column label="附件" width="120" align="left">
            <template slot-scope="scope">
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:1}}" class="initiation">查看详情</router-link>
              
       
            </template>
          </el-table-column>
        </el-table>


          <div slot="footer" class="dialog-footer">
              <el-button @click="choosechildfn">确 定</el-button>
          </div>
        </el-dialog>
        
       
    </div>
</template>
<script>
    import moment from 'moment'
import api from "@/api/api.js";




    export default {
           props: {
            datalist2: {
                type: Array,
                default() {
                    return []
                }
            },
              planCorpType:{
                 type: Number,
                default() {
                    return 1
                }
            }
        },
        watch: {
                zclist(val) {       
                    this.checkarr = [];
                    this.zclist.map((el, index) => {
                            if(el.ischecked){
                               this.checkarr.push(el)

                            }
                    })
                    this.$nextTick(function() {
                        if (this.checkarr.length > 0) {
                        this.checkarr.forEach(item => {
                            item.checked = true;

                              this.$refs.multipleTable.toggleRowSelection(item);
                        })
                    }


                    })
                },

         },
        data() {
            return {
                zclist:[],
                total:0,
                totalmoney:0,
                chooselist:[],
                showAddDialog:false,
                datalist:[],
                totalAmount:0,
                isbl:false

       
              
            }
        },
        created() {
         this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false

             api.getAccountListByCorp({absPlanId: this.$route.query.id,type:1}).then(res =>{ //审批结果查询
                if(res.success) {
                        this.datalist=res.datas.detail.list;
                        this.totalAmount=res.datas.detail.totalAmount
                        this.datalist.map(el=>{
                                this.$set(el,'ischecked',true)

                            el.absPlanAccounts.map(ele=>{
                                this.$set(ele,'ischecked',true)
                                this.total++
                            })
                    this.$nextTick(function() {
                              this.$refs.partable.toggleRowSelection(el);
                    })
                            el.choosenum=el.number
                            el.chooseamount=el.amount
                        })
                }
            })
      
     
        },
        methods: {
            moment: moment,

            handleSelectionChange(row){
        
          
                this.chooselist=row
                var arr=[]
                row.map(el=>arr.push(el.chooseamount))
                this.totalmoney=0..add(...arr)
   
            },
            partablecheckany(selection, row){
                row.ischecked=!row.ischecked;
                this.total=0;
                this.gettotal()

            },
            partablecheckall(row){
                this.total=0;

                      if (Array.isArray(this.datalist)) {
                        this.datalist.map(ele => {
                        ele.ischecked = row.length ? true : false;
                     
                        });
                    }
                this.gettotal()
            },

              handleEditinfo(row){
                this.zclist=row.absPlanAccounts
                this.showAddDialog=true     
            },

            checkany(selection, row){
                row.ischecked=!row.ischecked
                 
            },
            checkall(row) {
                    if (Array.isArray(this.zclist)) {
                        this.zclist.map(ele => {
                        ele.ischecked = row.length ? true : false;
                     
                        });
                    }


            },
            choosechildfn(){
                this.total=0;
                this.datalist.map(el=>{
                     el.choosenum=0
                     var arr=[]
                     el.absPlanAccounts.map(ele=>{
                          if(ele.ischecked){
                              el.choosenum++
                              arr.push(ele.absAccountAmount)
                        
                          }
                     })
                     el.chooseamount=0..add(...arr)
                })
                  var arrlist=[]
                this.chooselist.map(el=>{
                    arrlist.push(el.chooseamount)
                
                })
                this.totalmoney=0..add(...arrlist)
             this.gettotal()
            
                this.showAddDialog=false
            },
            cellname({row, column, rowIndex, columnIndex}){
                if(column.label=="资产包编号"){
                        return 'ishover'
                }


            },
            cellopenfn(row, column, cell, event){
                 if(column.label=="资产包编号"){
                              this.zclist=row.absPlanAccounts
                this.showAddDialog=true  
                }
            },
            gettotal(){
                 this.datalist.map(el=>{
                     if(el.ischecked){
                           el.absPlanAccounts.map(ele=>{         
                                if(ele.ischecked){
                                     this.total++
                                }
                            })
                     }
                   
                })
            }
     
        }
    }
</script>
<style lang="scss">
  .abs-check-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
  }
.initiation{
 .el-table__body-wrapper{
     max-height: 654px;
     min-height: 300px;
     overflow: auto;
 }

}
.parenttab{
    .ishover{
        cursor: pointer;
        i{
            font-size: 18px;
            position: relative;
            top: 2px;
        }
    }
}
 
 
  
</style>