<!-- 票据详情 -->
<template>
    <div>
        <el-tabs v-model="$store.state.acitveTab" @tab-click="handleClick">
        <el-tab-pane label="应收账款" name='1'>
         <abswaisigngys :datalist='receivableAccountList'  @fatherMentod='getinfo' ></abswaisigngys>
  </el-tab-pane>
  <el-tab-pane label="应付账款" name='2'>
       <abswaisigncore :datalist='payableAccountList' @fatherMentod='getinfo' ></abswaisigncore>
  </el-tab-pane>


</el-tabs>
        
      
        
       
        
       
    </div>
</template>
<script>
    import moment from 'moment'
import { mapMutations } from "vuex";

    import api from "@/api/api";
    import  abswaisigngys from '@/views/absbasesign/abswaisigngys' 
    import  abswaisigncore from '@/views/absbasesign/abswaisigncore' 



    export default {
        props: {
            datalist: {
                type: Object,
                default() {
                    return {}
                }
            },
             moneytotal: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        watch:{
             datalist: {
                    immediate: true, // 很重要！！！
                    handler (val) {
                     this.receivableAccountList=JSON.parse(JSON.stringify(val))
                   this.payableAccountList=JSON.parse(JSON.stringify(val))
                  this.receivableAccountList.absAccountList=val.receivableAccountList
                  this.payableAccountList.absAccountList=val.payableAccountList
                
                     
                       
                    }
            },
    
           
           
          
            

        },
        data() {
            return {
                receivableAccountList:{},
                payableAccountList:{},
                activeTab:'1'


              
            }
        },
        mounted() {
                console.log(this.$route,'route')
     
        },
        methods: {
                 ...mapMutations(["UPDATE_tab"]),

                getinfo(){
                    this.$emit('fatherMentod')
                },
                handleClick(tab) {
              
                    this.UPDATE_tab(tab.name)
               
                }
                
     
        },
               components: {

           abswaisigngys,
           abswaisigncore,


      
        }
    }
</script>
<style lang="scss">


  
 
  
</style>