<template>
  <div>
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <stay-financing-sign  v-if="showtab == 1"></stay-financing-sign>
      <already-financing-sign  v-if="showtab == 2"></already-financing-sign>
      <alreadpay  v-if="showtab == 3"></alreadpay>
      <clear-easypay v-if="corpType==1&&showtab==4"></clear-easypay>
      
    </layout-main-view>
  </div>
</template>
<script>
import StayFinancingSign from "./stayFinancingSign.vue";
import AlreadyFinancingSign from "./alreadyFinancingSign.vue";
import alreadpay from "./alreadpay";
import clearEasypay from "./clearEasypay";


export default {
  components: {
    "stay-financing-sign": StayFinancingSign,
    "already-financing-sign": AlreadyFinancingSign,
    alreadpay,
    'clear-easypay': clearEasypay,
  },
  data() {
    return {
      breadcrumblist: ["融资项目管理"],
      showtab: 1,
      tabOptions: [
        { label: "待签约", value: "1", path: "/fund/easypaySign/financingSign/1" },
        { label: "已签约", value: "2", path: "/fund/easypaySign/financingSign/2" },
        { label: "持有中", value: "3", path: "/fund/easypaySign/financingSign/3" },

      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      corpType: '',
    };
  },
  created() {
    // corpType  1资金机构 2.核心企业 3.一般企业  6.担保企业'
    this.corpType = JSON.parse(sessionStorage.user).corpType
    console.log(this.corpType, '---------this.corpType')
    if(this.corpType === 1) {

      this.tabOptions = [
        { label: "待签约", value: "1", path: "/fund/easypaySign/financingSign/1" },
        { label: "已签约", value: "2", path: "/fund/easypaySign/financingSign/2" },
        { label: "持有中", value: "3", path: "/fund/easypaySign/financingSign/3" },
        { label: "已清分", value: "4", path: "/fund/easypaySign/financingSign/4" },
      ]
    }
  },
  mounted(){
    this.showtab = this.$route.params.state;
    console.log('this.showtab ===>',this.showtab);
  },
  methods: {
    tabclick(val) {
      this.showtab = val;
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>