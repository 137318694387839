<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="corpName" label="接收企业" min-width="150"></el-table-column>
          <!--<el-table-column prop="corpIndustrySecondName" label="所属行业" min-width="120"></el-table-column>-->
          <!-- <el-table-column prop="approvalAmount" label="合同编号" min-width="150"></el-table-column> -->
          <el-table-column label="合同金额(元)" min-width="130">
            <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.contractAmount) }}</template>
          </el-table-column>
          <el-table-column label="优企通金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.payableAmount) }}</template>
          </el-table-column>
          <el-table-column label="开立日" min-width="130">
            <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
          </el-table-column>
          <el-table-column label="承诺付款日" min-width="130">
            <template slot-scope="scope">{{ scope.row.promisePayTime | formatDate }}</template>
          </el-table-column>
          <el-table-column prop="auditStateName" label="优企通状态" width="130" align="left"></el-table-column>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">详情</el-button>
              <el-button size="medium" type="text" v-if="scope.row.auditStateName == '接收审核' " @click="openAbolishdiag(scope.row)">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "../../api/api.js";
import pdf from "vue-pdf";
import { changeMoneyThousand } from "@/util/common";
import moment from 'moment'

export default {
  components: {
    pdf
  },
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "优企通编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
        {
          label: "接收企业名称",
          type: "input",
          field: "corpName",
          size: "small"
        },
        {
          label: "审批状态:",
          type: "select",
          field: "auditState",
          options: [
            { label: "开立审核", value: "1" },
            // { label: "担保审核", value: "6" },
            { label: "接收审核", value: "2" },
            { label: "平台审核", value: "3" },
            { label: "资方审核", value: "4" },
            { label: "已开单", value: "5" },
            { label: "全部", value: "0" }
          ],
          size: "small"
        },
        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["promisePayTimeStr", "promisePayTimeEnd"],
          size: "small"
        },
        // {
        //   label: "优企通金额(元): ",
        //   type: "inputrange",
        //   field: "payableAmountStr",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "payableAmountEnd",
        //   size: "small"
        // },
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      changeMoneyThousand:changeMoneyThousand,
      centerDialogVisible: false,
      api: api,
      row: {},
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "" // pdf文件地址
    };
  },
  created() {},
  methods: {
     openAbolishdiag(row) {
      this.$confirm("该优企通将作废, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning"
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          api
            .delete({ payableBillId: row.payableBillId })
            .then(res => {
              loading.close();
              if (res.code == "100000") {
                this.$alert("废除成功", "", {
                  confirmButtonText: "确定",
                  callback: action => {
                    this.handleRefresh({pageSize:10,pageNum:1})
                  }
                });
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(err => {
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消废除"
          });
        });
    },
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.corpId = "1";
      api.OpenList(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row){
      this.$router.push({name:'优企通流转详情',query: { id:row.payableBillId,isshowbtn:2,detail:true,auditState:row.auditState}})
    },
    preview(row) {
      this.row = row;
      window.open(api.getImgURL(row.evidencePath))
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   var binaryData = [];
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
      //   console.log("this.src ===>", this.src);
        // this.scr = pdf.createLoadingTask(this.src);
        // window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(url) + '&.pdf');

        // console.log('res ===>',res);
        // this.src = res;
        // this.centerDialogVisible = true;
        // this.listLoading = false;
      // });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
