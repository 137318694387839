<template>
  <layout-main-view :breadcrumb="breadcrumblist">

    <div class="common-page-dataList">
      <div class="easypayby goback yfdtwo">

        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form
            :model="payableBillAddRequest"
            ref="payableBillAddRequest"
            label-width="160px"
            class="demo-ruleForm"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="融资项目编号: ">
                  <span>{{info.receivableAccountFinanceNo}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="债权企业名称: ">
                  <span>{{info.financeCorpName}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应收账款能否确权: ">
                  <el-radio disabled v-model="info.corpAck" label="1">能</el-radio>
                  <el-radio disabled v-model="info.corpAck" label="2">否</el-radio>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="融资申请时间: ">
                  <span class="info-value">{{moment(info.createTime).format('YYYY-MM-DD HH:mm')}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="期望融资金额(元): " prop="preFinanceAmount">
                  <el-input disabled v-model="info.preFinanceAmount" placeholder="请输入期望融资金额"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望融资金额(大写): " class="minsizer">
                  <el-input disabled v-model="info.upper"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="inlinelv">
              <el-col :span="6" class="firlabwid">
                <el-form-item label="期望融资年化利率(%): " prop="expectFinanceInterestMin">
                  <el-input
                    disabled
                    v-model="info.expectFinanceInterestMin"
                    placeholder="请输入期望融资年化利率最小值"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="perwidth">
                <em>~</em>
              </el-col>
              <el-col :span="6" class="nolabwid">
                <el-form-item label=" " prop="expectFinanceInterestMax">
                  <el-input
                    disabled
                    v-model="info.expectFinanceInterestMax"
                    placeholder="请输入期望融资年化利率最大值"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款开户行: " prop="payableAmount">
                  <el-input disabled v-model="info.payeeOpenBank" placeholder="请输入期望融资年化利率最大值"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="收款账户: " class="minsizer">
                  <el-input disabled v-model="info.payeePublicAccountNo" placeholder="请输入期望融资年化利率最大值"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定应收账款</div>
        <div class="httotal">
          已添加应收账款
          <span>{{tableList.length}}</span> 笔，账款总计金额
          <span>{{ $changeMoneyThousand(info.relationReceivedAccountAmounts)}}</span> 元。
        </div>
        <div>
          <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%" v-loading="listLoading">
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="150"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="200"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额（元）" min-width="120">
              <template slot-scope="scope">{{$changeMoneyThousand(scope.row.accountAmount)}}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="120">
                       <template
                    slot-scope="scope"
            >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="120">
                  <template
                    slot-scope="scope"
            >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-hd" style="height: 150px;">
            <el-row style="padding-top: 40px;">
              <el-col :span="7" class="lb_body" :offset="7">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;"
                  size="small"
                  @click="reject"
                >取消</el-button>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="agree"

                >报价</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="请输入报价意向金额"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="1000px"
      center
    >
      <el-form :model="form" label-width="150px" :rules="rules" ref="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="债权企业: ">
              <span>{{info.financeCorpName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期望融资金额（元）: ">
              <span>{{info.preFinanceAmount}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期望融资年化利率（％）: ">
              <span>{{info.expectFinanceInterestMin}}％~{{info.expectFinanceInterestMax}}％</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="意向金额（元）: " prop="intentionAmount">
              <el-input v-model="form.intentionAmount" placeholder="请输入意向金额"  @change="tocapital"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="意向金额(大写): " class="minsizer">
              <el-input disabled v-model="form.intentionAmountUpper"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="企业确认时限" prop="quoteTimeLimit">
              <el-select v-model="form.quoteTimeLimit" placeholder="请选择确认时限">
                <el-option label="24小时" value="1"></el-option>
                <el-option label="48小时" value="2"></el-option>
                <el-option label="72小时" value="3"></el-option>
                <!--<el-option label="无时限" value="4"></el-option>-->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="意向融资年化利率（％）" prop="intentionInterest">
              <el-input v-model="form.intentionInterest" placeholder="请输入意向利率"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="融资补充凭证: ">
              <el-checkbox-group v-model="form.checkList">
                <!-- <el-checkbox border label="物流单据"></el-checkbox>
                <el-checkbox border label="入库单据"></el-checkbox>
                <el-checkbox border label="出库单据"></el-checkbox>
                <el-checkbox border label="银行流水"></el-checkbox> -->
                <el-checkbox border v-for="obj in list" :label="obj.name" :key="obj.code">{{obj.name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="info.corpAck==1">
          <el-col :span="24">
            <el-form-item label="补充确权文件: ">
                <el-checkbox border v-model="form.checked">确权文件</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import { changeMoneyThousand } from "@/util/common";
import moment from "moment";

export default {
  data() {
          var checkmaxmount = (rule, value, callback) => {
      if (!/^([1-9]\d{0,11}(.\d{1,2})?)$|^(0.\d?[1-9])$|^(0.[1-9]\d?)$/.test(value)) {
        callback(new Error("请输入12位以内的数字,小数点后最多2位"));
      } else {
        if (value > this.info.relationReceivedAccountAmounts) {
          callback(new Error("意向金额必须小于等于应收账款总金额"));
        } else {
          callback();
        }
      }
    };
    return {
      moment:moment,
      listLoading: false,
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      breadcrumblist: ["保理融资"],
      payableBillAddRequest: {},
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {
        upper: ""
      },
      receivableAccountFinanceId: 0,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        intentionAmount: "",
        intentionAmountUpper: "",
        intentionInterest: "",
        quoteTimeLimit: "",
        claimsCorpProof: "",
        corpAck: "",
        receivedCorpAck: "",
        checkList: [],
        checkList2: [],
        checked: false,
      },
      list: [], //
      rules: {
           intentionAmount: [
          { required: true, message: "请输入意向金额", trigger: "blur" },
           { validator: checkmaxmount, trigger: "blur" },
        ],
        intentionInterest: [
          { required: true, message: "请输入意向利率", trigger: "blur" }
        ],
        quoteTimeLimit: [
          { required: true, message: "请选择企业确认时限", trigger: "blur" }
        ]
      },
      quote: false,
    };

  },

  created() {
    this.receivableAccountFinanceId = this.$route.query.receivableAccountFinanceId;
    this.fundIsQuote(this.$route.query.receivableAccountFinanceId);
    this.getInfo(this.$route.query.receivableAccountFinanceId);
    api.getDicListByType({ type: 17 }).then(res => {
    if (res.success) {
      this.list = res.datas.list;
      }
    });

  },
  methods: {
    getInfo(receivableAccountFinanceId) {
      api
        .FinancingProjectDetails({
          receivableAccountFinanceId: receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.info = res.datas.data;
            this.info.corpAck = this.info.corpAck + "";
            this.tableList = res.datas.list;
            let upper = this.convertCurrency(this.info.preFinanceAmount);
            this.$set(this.info, "upper", upper);
          }
        });
    },
    fundIsQuote(receivableAccountFinanceId){
      api.FundIsQuote({
          receivableAccountFinanceId: receivableAccountFinanceId
        }).then(res => {
          if(res.success){
            this.quote = res.datas.quote;
          }else {

          }
      })
    },
    agree() {
      if(this.quote){ //true  代表已经报价
          this.$message.info('当前企业已报价');
      }else {
          this.dialogFormVisible = true;
      }
    },
    //拒绝
    reject() {
      this.$router.go(-1);
    },
    submit() {
      // GenerateQuoteRecord
        this.$set(this.form, "claimsCorpName", this.info.financeCorpName);
        this.$set(this.form, "claimsCorpId", this.info.financeCorpId);
        this.$set(this.form, "corpAck", this.info.corpAck);
        this.$set(this.form, "receivableAccountFinanceId", this.info.receivableAccountFinanceId);
        this.$set(this.form, "receivableAccountFinanceNo", this.info.receivableAccountFinanceNo);
        this.$set(this.form, "intentionAmount", this.form.intentionAmount);
        this.$set(this.form, "intentionAmountUpper", this.form.intentionAmountUpper);
        this.$set(this.form, "intentionInterest", this.form.intentionInterest);
        this.$set(this.form, "expectFinanceAmount", this.info.preFinanceAmount);
        this.$set(this.form, "expectInterestMin", this.info.expectFinanceInterestMin);
        this.$set(this.form, "expectInterestMax", this.info.expectFinanceInterestMax);

        console.log('form ====>',this.form);
        let arraylist = [];
        this.list.forEach(element => {
            this.form.checkList.forEach(ele =>{
              if(element.name == ele){
                arraylist.push(element.code)
              }
            })
        });
      let arrayStr = JSON.stringify(arraylist);
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.form.checked){
            this.info.corpAck = 1;
          }else {
            this.info.corpAck = 2;
          }

        this.$set(this.form, "claimsCorpName", this.info.financeCorpName);
        this.$set(this.form, "claimsCorpId", this.info.financeCorpId);
        this.$set(this.form, "corpAck", this.info.corpAck);
        this.$set(this.form, "receivableAccountFinanceId", this.info.receivableAccountFinanceId);
        this.$set(this.form, "receivableAccountFinanceNo", this.info.receivableAccountFinanceNo);
        this.$set(this.form, "intentionAmount", this.form.intentionAmount);
        this.$set(this.form, "intentionAmountUpper", this.form.intentionAmountUpper);
        this.$set(this.form, "intentionInterest", this.form.intentionInterest);
        this.$set(this.form, "claimsCorpProof", arrayStr);

          this.listLoading = true;
          api.GenerateQuoteRecord(this.form).then(res => {
            this.listLoading = false;
            if (res.success) {
              this.$alert("报价成功，等待融资企业选择报价", "", {
              confirmButtonText: "确定",
              callback: action => {
                history.go(-1);
              }
            });
              // this.$router.go(-1);
              // this.$alert(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    goback() {
      this.$router.go(-1);
    },
    tocapital() {
      this.form.intentionAmountUpper = this.convertCurrency(
        this.form.intentionAmount
      );
    },
    handleEdit(data){
      this.$router.push({name:'应收账款详情',query: { id:data.receivableAccountId,isshowbtn:2}})
    },
  }
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
.fr {
  float: right;
}
</style>
