
import moment from 'moment'
export default {
  // 数字格式化千分位
  formatMoney: value => {
    var result = '';
    if (value) {
      const fmtSplit = value.toString().split('.')
      var num = fmtSplit[0];
      // var num = (value || 0).toString(), result = '';
      while (num.length > 3) {
          result = ',' + num.slice(-3) + result;
          num = num.slice(0, num.length - 3);
      }
      if (num) { result = num + result; }
      if (fmtSplit.length > 1) result += `.${fmtSplit[1]}`
    } else {
      result = '0';
    }
    return result;
  },
  //去掉千分位
  removeMoney: value => {
    return parseFloat(s.replace(/[^\d\.-]/g, ""));
  },
  formatDate: value => {
    var date = ''
    if (value) date = moment(value).format('YYYY-MM-DD')
    return date
  },
  formatHour: value => {
    var date = ''
    if (value) date = moment(value).format('YYYY-MM-DD HH:mm:ss')
    return date
  },

  corpAckStatusTranlateName: value => {
    var name = '';
    switch(value) {
      case 1:
         name = '初始状态'
         break;
      case  2:
         name = '待确权'
         break;
      case  3:
        name = '已确权'
        break;
      case  4:
        name = '无需确权'
        break;
      case  5:
        name = '部分确权'
        break;
      case  6:
        name = '确权申请审核中'
        break;
     
    } 
    return name;     
  },
  billType: value => {
    var name = '';
    switch(value) {
      case 1:
         name = '商票'
         break;
      case  2:
         name = '国股'
         break;
      case  3:
        name = '城商'
        break;
      case  4:
        name = '三农'
        break;
      case  5:
        name = '财票'
        break;
     
    } 
    return name;     
  },
  quoteLimit: value => {
    var name = '';
    switch(value) {
      case 1:
         name = '24小时'
         break;
      case  2:
         name = '48小时'
         break;
      case  3:
        name = '72小时'
        break;
      case  4:
        name = '无限时'
        break;
        default :
        name='未报价'
     
    } 
    return name;     
  },
}