<template>
  <div>
    
      <el-form :model="formupload" label-width="140px" ref="addform" class="boltdetial">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="账款编号: ">
              <span>{{formupload.receivableAccountNo}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账款金额（元）: ">
              <span>{{$changeMoneyThousand(formupload.accountAmount)}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到期日期: ">
              <span>{{formupload.accountCloseDate ? moment(formupload.accountCloseDate).format('YYYY-MM-DD'): ''}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="上传其他资料: ">
          <div class="uploadfont" style="margin-bottom: 12px;">
            <div class="uploadfont wxtsk" style="height: 40px;">
              温馨提示：仅支持JPG、PNG、PDF文件格式。
              <!-- <br /> -->
              <!-- <span class="pt16">上传其他补充资料包括但不限于出入库单，物流单，结算单，银行流水等。</span> -->
            </div>
          </div>
          <div v-for="(item,index) in uploadfilelist" :key="index" class="uploadline">
            <el-upload
              class="upload-demo upload-contentr"
              :action="baseURL+'/web/file/uploadFile'"
              :on-success="(a,b,c)=>dragupotherfn(a,b,c,item,index)"
              :on-remove="(a,b)=>dragremoveotherfn(a,b,item,index)"
              :on-error="erruploadfp"
              :headers="Authorization"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              :file-list="item.filelist"
              :before-upload="beforeupfnfile"
              list-type="picture"
              drag>
              <i class="el-icon-plus"></i>
              <div class="el-upload__text">{{item.name}}</div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
import moment from 'moment'
import { baseURL } from "@/api/sys";
export default {
  data() {
    return {
      baseURL: baseURL,
      uploadfilelist: [],
      subsingle: {
        fileDtoList: [],
        receivableAccountFinanceId: this.$route.query.receivableAccountFinanceId,
        receivableAccountId: ""
      },
      formupload: {},
      Authorization: { Authorization: sessionStorage.token },
    }
  },
  created() {

  },
  methods: {
    moment: moment,
    erruploadfp(err, file) {
      this.$message.error("服务器错误");
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.')
      let suffix = file.name.substr(index + 1)
      if (!/jpg|bmp|png|jpeg|zip|rar|pdf|JPG|BMP|PNG|JPEG|ZIP|RAR|PDF/.test(suffix)) {
        this.$message.error("请上传png,jpg,jpeg,pdf格式的文件");
        return false;
      }
    },
    dragupotherfn(response, file, fileList, item, index) {
      //上传的其他附件
      if (response.success) {
        var data = response.datas.uploadDate.message;
        this.subsingle.fileDtoList.push({
          fileCode: item.fileCode,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name
        });
        item.arr.push({
          fileCode: item.fileCode,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name
        });
      } else {
        this.$message.error(file.name + "发票识别错误，请重新上传");
      }
    },
    dragremoveotherfn(file, fileList, item, index) {
      //移除其他的附件
      if (
        this.subsingle.fileDtoList.findIndex(el => el.uid == file.uid) != -1
      ) {
        this.subsingle.fileDtoList.splice(
          this.subsingle.fileDtoList.findIndex(el => el.uid == file.uid),
          1
        );
        item.arr.splice(item.arr.findIndex(el => el.uid == file.uid), 1);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
