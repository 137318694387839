import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from 'vue-router'
import store from './store'
import router from './router/index.js'
import VueImg from 'v-img'
import 'font-awesome/css/font-awesome.min.css'
import HeaderLogin from './views/base/headerLogin.vue'
import HeaderBase from './views/base/headerBase.vue'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import LayoutMainView from "@/components/layout-main-view"
import LayoutMainViewTabs from "@/components/layout-main-view-tabs"
import DataList from "@/components/datalist/DataList"
// import echarts from 'echarts'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import '@/assets/scss/common.scss'

import Viser from 'viser-vue'

import Vue2OrgTree from 'vue2-org-tree'
import '@/styles/vue2OrgTree/orgTree.less';

import globalFilter from '@/util/filters'

import { changeMoneyThousand } from "@/util/common";

import imgPreview from '@/components/imgPreview'
Vue.prototype.$changeMoneyThousand = changeMoneyThousand
Vue.use(Vue2OrgTree)

Vue.use(preview)
Vue.use(Viser)

Vue.use(Viewer)

Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.use(VueImg)
    // Vue.use(echarts)
Vue.use(changeMoneyThousand)

Vue.use(imgPreview)
    // 注册全局过滤器
Object.keys(globalFilter).forEach(key => {
    Vue.filter(key, globalFilter[key])
})
Vue.use(HeaderLogin);
Vue.use(HeaderBase);
Vue.component(HeaderLogin.name, HeaderLogin);
Vue.component(HeaderBase.name, HeaderBase);
Vue.component(LayoutMainView.name, LayoutMainView);
Vue.component(LayoutMainViewTabs.name, LayoutMainViewTabs);
Vue.component(DataList.name, DataList);
// Vue.prototype.$echarts = echarts

// router.beforeEach((to, from, next) => {
//   if (to.path == '/login') {
//     sessionStorage.removeItem('user')
//   }


//   next()


// })
Vue.filter('timeformat', function(val, tpl) {
    if (!val) return ''
    try {
        return moment(val).format(tpl)
    } catch (e) {
        return val
    }
})
window.vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
var htmlwidth = document.documentElement.clientWidth || document.body.clientWidth
var htmldom = document.getElementsByTagName('html')[0]
htmldom.style.fontSize = htmlwidth / 10 + 'px'