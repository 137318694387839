<template>
  <div>
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <StayMantching  v-if="showtab == 1"></StayMantching>
      <YetQuote  v-if="showtab == 2"></YetQuote>
      <StayPatchBolt  v-if="showtab == 3"></StayPatchBolt>
      <StaySign  v-if="showtab == 4"></StaySign>
      <YetSign  v-if="showtab == 5"></YetSign>
      <YetBack  v-if="showtab == 6"></YetBack>
    </layout-main-view>
  </div>
</template>
<script>
import {} from "../../api/api";
import StayMantching from "./stayMantching";
import StayPatchBolt from "./stayPatchBolt";
import StaySign from "./staySign";
import YetSign from "./yetSign";
import YetQuote from "./yetQuote";
import YetBack from "./yetBack";

export default {
  components: {
    "StayMantching": StayMantching,
    "YetQuote": YetQuote,
    "StayPatchBolt": StayPatchBolt,
    "StaySign": StaySign,
    "YetSign": YetSign,
    "YetBack": YetBack,
  },
  data() {
    return {
      breadcrumblist: ["易保理项目管理"],
      showtab: 1,
      tabOptions: [
        { label: "待撮合", value: "1", path: "/factoring/FactoringManageIndex/1" },
        { label: "已报价", value: "2", path: "/factoring/FactoringManageIndex/2" },
        { label: "待补件", value: "3", path: "/factoring/FactoringManageIndex/3" },
        { label: "待签约", value: "4", path: "/factoring/FactoringManageIndex/4" },
        { label: "已签约", value: "5", path: "/factoring/FactoringManageIndex/5" },
        { label: "已退回", value: "6", path: "/factoring/FactoringManageIndex/6" },
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: []
    };
  },
    mounted(){
        this.showtab = this.$route.params.state;
        console.log('this.showtab ===>',this.showtab);
    },
  methods: {
    tabclick(val) {
      this.showtab = val;
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>