<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <absbindaccountscheck ref="checkcop" :planCorpType='planCorpType' v-if="planCorpType"></absbindaccountscheck>
        </div>

          <div class="absstartbtn card-bottom">
 
                  <div class="btnmar">
                       <el-button class="card-btn" @click="goback">返回</el-button>
                      <el-button class="card-btn" type="primary" @click="packfn">打包资产并发起审批</el-button>
              </div>

              
       
      </div>
        </layout-main-view>

    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  absbindaccountscheck from '@/views/absbase/absbindaccountscheck' 


 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划简介'],
                absPlanDto:{},
                absPlanDetailDto:{},
                iscore:false,
                planCorpType:''
     
            }
        },
        created() {
             api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
                    this.iscore=this.absPlanDto.leaderCorpType!=1?true:false;
                    this.planCorpType=res.datas.planDetail.planCorpType
   
                }else{
                    this.$message.error(res.message);
                    
                }
            })
        },
        methods: {
            goback(){
                history.go(-1)
            },
            packfn(){
                console.log(this.$refs.checkcop,'lll','preAssertScaleAmount')
                if(this.iscore&&this.absPlanDtopreAssertScaleAmount>this.$refs.checkcop.totalmoney){
                        this.$message.warning('账款总金额必须大于等于募集规模')
                        return
                }
                if(this.$refs.checkcop.totalmoney==0){
                    this.$message.warning('请绑定账款')
                        return
                }
                var param={
                    absPlanId:this.$route.query.id,
                    list:[]
                }
                this.$refs.checkcop.chooselist.map(el=>param.list.push(el.absAccountId))
        
                   api.submitAccountApply(param).then(res =>{ //审批结果查询
                            if(res.success) {
                                      this.$alert('打包成功,等待审核', "", {
                                                  confirmButtonText: "确定",
                                                  callback: action => {
                                                    history.go(-1);
                                                  }
                                                });
            
                            }else{
                                this.$message.error(res.message);
                                
                            }
                     })

            }
    
        },
        components: {
           absaccountxmgk,
           absbindaccountscheck

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }
       .absstartbtn{
           text-align: center;
           button{
               width: 166px;
           }
       }
   }
</style>