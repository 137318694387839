<!-- 票据详情 -->
<template>
    <div class="bill-approvaled-details">
        <layout-main-view :breadcrumb='breadcrumblist'>

            <bill-details :detailsList='detailsList'></bill-details>
            <div class="footer-btn" v-if="detailsList.offerStatus!=2">
                <el-button type="primary" @click="refusefn">退回</el-button>
                <el-button type="primary" @click="agree">同意</el-button>
            </div>
             <div class="footer-btn" v-if="detailsList.offerStatus==2">
                <el-button type="primary" @click="overfn">结束</el-button>
          
            </div>
        </layout-main-view>
           <!-- 弹窗 -->
    <el-dialog
      title="审核意见"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="agreedetail" label-width="60px" ref="addform">
        <el-form-item label="备注：" prop="content">
          <el-input v-model="agreedetail.remark" type="textarea" :rows="6" maxlength="200"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog=false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm"  >提交</el-button>
        <!-- <el-button type="primary" @click="submitAddForm" >结束</el-button> -->

      </div>
    </el-dialog>

    <el-dialog
      title="拒绝原因"
      :visible.sync="showAddDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="refusedetail" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="denialReason">
          <el-select v-model="refusedetail.reason" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="content">
          <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2=false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm2('addform')" >提交</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
    import api from "@/api/api";
    import moment from 'moment'
    import billDetails from '@/components/billDetails' 
    export default {
        data() {
            return {
                breadcrumblist:['票据报价审批'],
                detailsList: {},
                approvalVisible: false,
                regionList: [],
                approvalForm: {
                    billId: this.$route.query.id,
                    approveResult: '',
                    reason: '',
                    refuseReason: '',
                    remark: '',
                    approveNode: '',
                },
                active: 0,
                approvalDetailsList: {},
                agreedetail:{
                       billId:this.$route.query.id,
                       approveResult:1,
                       remark:'',
                       approveNode:20,

                   },
                   refusedetail:{
                      billId:this.$route.query.id,
                       approveResult:2,
                       remark:'',
                       reason:'',
                       approveNode:20,
                   },
                showAddDialog:false,
                showAddDialog2:false,
                refuselist:[],
       
            }
        },
        created() {
            this.init();
            api.GetApproveListByBillId({billId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.approvalDetailsList = res.datas.billApproveDtos[0]
                }
            })
            this.getrefuselist()

        },
        filters: {
            formatDate: value => {
                return moment(value).format('YYYY-MM-DD');     
            }
        },
        methods: {
            init() {
                let id =  this.$route.query.id
                api.BillDetails({billId: id}).then(res => {
                    this.detailsList = res.datas.billDto
                    this.approvalForm.approveNode = this.detailsList.billApproveStatus
                    this.detailsList.billFile.forEach((item,index) => {
                        if(item.fileType == 2) {
                            this.detailsList.billFileBack = api.getImgURL(item.fileUrl)
                        }else {
                            this.detailsList.billFileFront = api.getImgURL(item.fileUrl)
                        }
                    })
                    this.detailsList.billFlaw = this.detailsList.billFlaw.split(',');
                    this.detailsList.hideStatus == 1?this.detailsList.hideStatus=false:this.detailsList.hideStatus=true
                }) 
            },
            agree() {
                    api.quoteEndCheck({billId:this.$route.query.id}).then(res => {
                                if (res.datas.flag) {
                                     this.showAddDialog=true
                                   
                                }else{
                                     this.$router.push({
                                                name: "被人抢了",
                                                query: { id:this.$route.query.id}
                                        });
                                }
                        
                            });
            },
            refusefn(){
                   api.quoteEndCheck({billId:this.$route.query.id}).then(res => {
                                if (res.datas.flag) {
                                          this.showAddDialog2=true
                                   
                                }else{
                                     this.$router.push({
                                                name: "被人抢了",
                                                query: { id:this.$route.query.id}
                                        });
                                }
                        
                            });
              
            },
            submitAddForm(){
                



                    var messages=''
                  switch (JSON.parse(sessionStorage.user).roleCodes[0]) {  
                            case 'fund_first':
                       
                             this.agreedetail.approveNode=20
                             messages='票据审批成功，等待复审'
                            break;
                                case 'fund_second':
    
                             this.agreedetail.approveNode=21
                             messages='票据审批成功，等待终审'


                            break;
                                case 'fund_third':
                   
                             this.agreedetail.approveNode=24
                             messages='票据审批成功，等待企业选择报价'

                            break;
                                                   default:
                            this.agreedetail.approveNode=20 
                             messages='票据审批成功，等待复审'


                            break;
                        }
                
                          
                           const loading = this.$loading({
                                    lock: true,
                                    text: "Loading",
                                    spinner: "el-icon-loading",
                                    background: "rgba(0, 0, 0, 0.7)"
                                      });
                               
                api.quoteApprove(this.agreedetail).then(res => {
                  
                            if(res.success){
                    
                                       this.$alert(messages, "", {
                                            confirmButtonText: "确定",
                                            callback: action => {
                                                history.go(-1);
                                            }
                                       });
                            }else{
                                      this.$alert(res.message)
                                
                            }
                        loading.close()
                }) 
            },
                getrefuselist() {
                    api.getDicListByType({ type: 23 }).then(res => {
                        if (res.code == "100000") {
                        this.refuselist = res.datas.list;
                        }
                    });
            
               },
                        submitAddForm2(){
          
                  switch (JSON.parse(sessionStorage.user).roleCodes[0]) {  
                            case 'fund_first':
                       
                             this.refusedetail.approveNode=20
                            break;
                                case 'fund_second':
    
                             this.refusedetail.approveNode=21

                            break;
                                case 'fund_third':
                   
                             this.refusedetail.approveNode=24
                            break;
                                                   default:
                            this.refusedetail.approveNode=20 

                            break;
                        }
                
                          
                           const loading = this.$loading({
                                    lock: true,
                                    text: "Loading",
                                    spinner: "el-icon-loading",
                                    background: "rgba(0, 0, 0, 0.7)"
                                      });
                               
                api.quoteApprove(this.refusedetail).then(res => {
                            if(res.success){
                                       this.$alert('操作成功', "", {
                                            confirmButtonText: "确定",
                                            callback: action => {
                                                history.go(-1);
                                            }
                                       });
                            }else{
                                      this.$alert(res.message)
                                
                            }
                        loading.close()
                }) 
            },

          overfn(){
                  api.quoteApproveManualEnd({billId:this.$route.query.id}).then(res => {
                            if(res.success){
                                   this.$alert('操作成功', "", {
                                            confirmButtonText: "确定",
                                            callback: action => {
                                                history.go(-1);
                                            }
                                       });
                            }
                }) 
              
          }

        },
        components: {
            'bill-details': billDetails
        }
    }
</script>
<style lang="scss">
    .bill-approvaled-details {
        .footer-btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
                &+.el-button {
                    margin-left: 40px;
                }
            }
        }
        .approval-result {
            padding: 33px 25px 0 25px;
            background: #fff;
            .approval-title {
                position: relative;
                font-size: 16px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                padding-left: 12px;
                margin-bottom: 24px;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -7px;
                    width: 3px;
                    height: 14px;
                    background: #CB8E51;
                }
            }
            .approval-cont {
                display: flex;
                justify-content: space-between;
                i {
                    color: #09BB07;
                }
            }
        }
        .success {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 100px 0;
            .center-cont {
                text-align: center;
                .approval-people,.approval-time {
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 15px;
                }
            }
            background: #fff;
            .el-steps {
                justify-content: center;
                .el-step__title {
                    &.is-success {
                        color: #2937A6;
                    }
                }
            }
            .el-icon-circle-check{
                font-size: 100px;
                color: #09BB07;
            }
            p {
                font-size: 20px;
                font-weight: 600;
                margin: 20px 0;
            }
            .el-button {
                width: 120px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin-top: 30px;
                text-align: center;
                &+.el-button {
                    margin-left: 50px;
                }
            }
        }
        .zjbjch{
            height: 100px;
            padding-left: 2em;
            .el-row{
                margin-bottom: 20px;
            }
            .lab{
                display: inline-block;
                width: 140px;
            }
        }
    }
</style>