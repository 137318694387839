<template>
  <div class="upload-box">
    <el-upload
      class="upload-demo"
      :headers="token"
      :action="fileUpload()"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      multiple
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList">
      <el-button size="small"><i class="el-icon-upload2" style="margin-left:0;"></i> 上传文件</el-button>
      <!-- <div slot="tip" class="el-upload__tip">支持扩展名：.doc .docx .pdf .jpg ...</div> -->
    </el-upload>
  </div>
</template>

<script>
import api from '@/api/api'
export default {
  data() {
    return {
        api:api,
      fileList: [],
      uploadList: [],
      token: {
        Authorization: sessionStorage.getItem('token')
      } 
    }
  },
  props: {
    limit: {}
  },
  methods: {
    handleRemove(file, fileList) {
      this.uploadList = fileList
    },
    fileUpload() {
      return api.uploadFile()
    },
    handleSuccess(response,file,fileList) {
      if(response.success) {
        // this.$message.success(response.message)
        this.$alert(response.message, '温馨提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.uploadList = fileList
          }
        });
      }
    },
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 ${this.limit}个文件`);
      this.$alert('当前限制选择' + this.limit + '个文件', '温馨提示', {
        confirmButtonText: '确定',
        callback: action => {}
      });
    },
  }
}
</script>

<style lang="less" scoped>
.upload-box {
  .upload-demo {
    width: 90%;
  }
  .el-upload__tip {
    margin-top: 0;
    margin-left: 20px;
    line-height: 20px;
  }
}
</style>
