<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
          <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
          <el-table-column prop="claimsCorpNature" label="所属行业" min-width="170"></el-table-column>
          <el-table-column prop="accountAmount" label="账款金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.accountAmount)  }}</template>
          </el-table-column>
          <el-table-column prop="accountSignDate" label="账款起始日期" min-width="150">
            <template slot-scope="scope">{{ scope.row.accountSignDate | formatDate }}</template>


          </el-table-column>
          <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="150">
            <template slot-scope="scope">{{ scope.row.accountCloseDate | formatDate }}</template>

          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">审批</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../../api/api.js";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
     filterList: [
         {
          label: "应收账款编号:",
          type: "input",
          field: "receivableAccountNo",
          size: "small"
        },
        {
          label: "债务企业:",
          type: "input",
          field: "debtCorpName",
          size: "small"
        },
        {
          label: "账款金额: ",
          type: "inputrange",
          field: "accountAmountStr",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "accountAmountEnd",
          size: "small"
        },
        {
          label: "到期日期:",
          type: "daterange",
          field: ["accountCloseDateStr", "accountCloseDateEnd"],
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      api.ApproveGetFirstTrialList(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(data) {

      this.$router.push({name:'应收账款详情',query: { id:data.receivableAccountId,isshowbtn:1,node:data.approveStatus}})

    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
