<template>
  <div class="container">
    <headerBase @logout="logout" @changenav="changenav"></headerBase>
    <div class="main">
      <aside class="collapsed" v-show="$route.path != '/workbench'">
        <el-menu
          :default-active="defaultactive"
          class="el-menu-vertical-demo"
          router
          unique-opened
          @select="handleSlect"
          v-show="!collapsed"
          background-color="#2937A6"
          text-color="#A6C6FF"
          active-text-color="#FFF"
        >
          <template v-for="(item, index) in navs">
            <!-- {{showMenu(item)}} -->
            <el-submenu
              :index="index + ''"
              v-if="item.children && showMenu(item)"
              :key="item.path"
            >
              <template slot="title">
                <i :class="item.iconCls"></i>
                {{ item.name }}
              </template>
              <div v-for="child in item.children" :key="child.path">
                <el-menu-item
                  :index="child.path.split('?')[0]"
                  v-if="showMenu(child)"
                  >{{ child.name }}</el-menu-item
                >
              </div>
            </el-submenu>
            <el-menu-item
              v-if="!item.children && showMenu(item)"
              :key="item.path"
              :index="item.path.split('?')[0]"
            >
              <i :class="item.iconCls"></i>
              {{ item.name }}
            </el-menu-item>
          </template>
        </el-menu>
      </aside>
      <div class="sokitmsg" :class="{ active: isActive }">
        <div class="clearfix">
          <span class="left">您有一条新的任务待处理</span
          ><span class="right" @click="isActive = true">关闭</span>
        </div>
        <div>
          {{ getMsgType(wsdata) }}
        </div>
        <div>
          {{ wsdata.message }}
        </div>
        <div>
          {{
            wsdata.createTime
              ? moment(wsdata.createTime).format('YYYY-MM-DD HH:mm')
              : ''
          }}
        </div>
        <div>
          <a
            href="javascript:;"
            v-if="wsdata.stationLetterType == 1"
            @click="handleTask(getMsgType(wsdata), wsdata)"
            >立即处理</a
          >
        </div>
        <div>
          <a href="javascript:;" @click="gotoznx" class="morediv"
            >查看更多消息</a
          >
        </div>
      </div>
      <!-- <transition name="fade" mode="out-in"> -->
      <router-view class="ly-main-view"></router-view>
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
import api from '../api/api';
import * as types from '@/store/mutation-types';
import { LeftNavfn } from '../router/leftnav';
import { mapMutations } from 'vuex';
import moment from 'moment';
import bus from '@/util/bus.js';
import { baseURL } from '@/api/sys.js';
// 加
Number.prototype['add'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
// 减
Number.prototype['sub'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    var n = r1 >= r2 ? r1 : r2;
    result = (Math.round(result * m - value * m) / m).toFixed(n);
  });
  return result;
};
// 乘
Number.prototype['mul'] = function (...arg) {
  var result = this;
  arg.forEach((value) => {
    var m = 0,
      s1 = result.toString(),
      s2 = value.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {}
    try {
      m += s2.split('.')[1].length;
    } catch (e) {}
    result =
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m);
  });
  return result;
};
// 除
Number.prototype['div'] = function (...arg) {
  var result = this;
  arg.forEach((value) => {
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    try {
      t1 = result.toString().split('.')[1].length;
    } catch (e) {}
    try {
      t2 = value.toString().split('.')[1].length;
    } catch (e) {}
    r1 = Number(result.toString().replace('.', ''));
    r2 = Number(value.toString().replace('.', ''));
    result = (r1 / r2) * Math.pow(10, t2 - t1);
  });
  return result;
};
export default {
  watch: {
    $route: {
      handler(to, from) {
        if (to.path != from.path && to.meta.isnav) {
          this.navs = [];
          if (to.matched.length > 1) {
            sessionStorage.name = to.meta.to;
            sessionStorage.leftnav = to.matched[1].name;
          } else {
            sessionStorage.name = to.meta.to;
            sessionStorage.leftnav = to.name;
          }
          sessionStorage.oldpath = to.path;
          this.getNaveList();
        }
        var a = '';
        if (!isNaN(sessionStorage.oldpath[sessionStorage.oldpath.length - 1])) {
          a = sessionStorage.oldpath.split('/').slice(0, -1).join('/') + '/1';
        } else {
          a = sessionStorage.oldpath;
        }
        this.UPDATE_navName({ name: a });
      },
    },
  },
  computed: {
    defaultactive: {
      get() {
        var a = this.$store.state.navName.name;
        if (a) {
          if (!isNaN(a[a.length - 1])) {
            a = a.split('/').slice(0, -1).join('/') + '/1';
          } else {
            a = this.$store.state.navName.name;
          }
        }
        return this.$store.state.navName.name;
      },
    },
  },

  data() {
    return {
      collapsed: false,
      sysUserName: '',
      roots: '',
      userInfo: {},
      routeList: [],
      type: '', //企业类型 0 是核心企业  1 是供应商
      navs: [],
      ws: '',
      isActive: true,
      pageUrl: '',
      wsdata: {
        payableBillReminderNode: '',
      },
      moment: moment,
      isAdmin: false,
    };
  },

  created() {
    this.navs = [];
    this.getNaveList();
    var a = '';
    if (sessionStorage.oldpath) {
      if (!isNaN(sessionStorage.oldpath[sessionStorage.oldpath.length - 1])) {
        a = sessionStorage.oldpath.split('/').slice(0, -1).join('/') + '/1';
      } else {
        a = sessionStorage.oldpath;
      }
    }
    this.UPDATE_navName({ name: a });
  },
  mounted() {
    this.$nextTick(function () {
      this.websokitfn();
    });
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e));
  },
  methods: {
    // 获取菜单
    getNaveList() {
      if (!sessionStorage.user) {
        this.$router.push('/login')
        return false
      }
      LeftNavfn(
        JSON.parse(sessionStorage.user).corpType,
        JSON.parse(sessionStorage.corpList).isChainCorp
      ).map((el) => {
        if (el.name == sessionStorage.name) {
          this.navs = el.children;
        }
      });
    },
    handleSlect(key, keyPath) {
      this.navs.map((_) => {
        if (_.children && _.children.length) {
          for (let item of _.children) {
            if (item.path.split('?')[0] == key) {
              sessionStorage.setItem('currentPathName', item.name);
            }
          }
        } else {
          if (_.path.split('?')[0] == key) {
            sessionStorage.setItem('currentPathName', _.name);
          }
        }
      });
    },
    ...mapMutations(['UPDATE_navName']),
    showMenu(v) {
      if (v.adminRole) {
        return v.adminRole.includes(
          JSON.parse(sessionStorage.user).roleCodes[0]
        );
      } else {
        return true;
      }
    },
    get(obj, path) {
      return _.get(obj, path);
    },
    beforeunloadHandler(e) {
      this.ws.close();
    },
    websokitfn() {
      const flag = process.env.VUE_APP_HOST !== 'dev';
      let url = flag ?  location.origin + '/api/websocket/' + sessionStorage.token : baseURL + '/websocket/' + sessionStorage.token
      // let url = location.origin + '/api/websocket/' + sessionStorage.token;
      if (/https/.test(baseURL)) {
        this.ws = new WebSocket(
          url
            .replace('https', 'wss')
            .replace('www', 'pm')
            .replace(/:\d{2,}/, '')
            .replace('/api', '')
        );
      } else {
        this.ws = new WebSocket(url.replace('http', 'ws'));
      }
      this.ws.onopen = function (evt) {
        console.log('Connection open ...');
      };
      this.ws.onmessage = (event) => {
        this.wsdata = JSON.parse(event.data).data[0];
        if (this.wsdata) {
          bus.$emit('getMessageCenterMessageTotal');
        }
        this.isActive = false;
        var timer = setTimeout(() => {
          this.isActive = true;
          clearTimeout(timer);
        }, 10000);
      };
      this.ws.onclose = function (evt) {
        console.log('Connection closed.');
      };
    },
    changenav(item) {
      this.navs = [];
      this.getNaveList();
    },
    //退出登录
    logout() {
      var _this = this;
      this.$confirm('确认退出吗?', '提示', {}).then(() => {
        api
          .toLogout({ accessToken: sessionStorage.layoutToken })
          .then((res) => {
            if (res.code == '100000') {
              sessionStorage.clear();
              this.$store.commit(types.UPDATE_userInfo, {});
              _this.$router.push('/login');
              sessionStorage.clear();
            }
          });
      });
    },
    gotoznx() {
      this.$router.push({ path: '/Business/message?pageSize=10&pageNum=1' });
      this.isActive = true;
    },
    // 消息类型
    // 1资金机构 2.核心企业 3.一般企业  6.担保企业
    getMsgType(row) {
      if (
        JSON.parse(sessionStorage.user).corpType == 2 ||
        JSON.parse(sessionStorage.user).corpType == 3
      ) {
        if (row.objType == null) {
          // 优企通
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/easypay/openapproval/1';
              return '优企通开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '优企通接收待确认';
            case 3:
              this.pageUrl =
                '/easypayApproveManage/easypayFinancing/financingApprove/1';
              return '优企通融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '优企通清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '优企通清分待确认';
            case 6:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/2';
              return '优企通融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '优企通被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '优企通融资失败';
            case 9:
              return '优企通流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 12:
              this.pageUrl = '/easypay/openrequestapproval/1';
              return '邀请开单待审核';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '优企通转单待审核';
            case 14:
              return '优企通融资签约';
            case 21:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/5';
              return '优企通融资签约待确认';
            case 22:
              return '优企通可发起融资申请';
            case 23:
              this.pageUrl = '/Business/message';
              return '银行账户管理';
            case 36:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/3';
              return '放款凭证待确认';
            case 39:
              return '放款成功';
            case 16:
              return row.payableBillReminderNodeName; // 优企通清分
            case 15:
              return row.payableBillReminderNodeName; // 优企通到期
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          switch (row.payableBillReminderNode) {
            case 21:
              this.pageUrl = '/factoring/ReceivableIndexIndex/1';
              break;
            case 22:
              this.pageUrl = '/factoring/CreditorsRightIndex/1';
              break;
            case 23:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 25:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 6:
              // 无需跳转
              break;
            case 27:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 41:
              this.pageUrl = '/factoring/FinacingApprovalIndex/1';
              break;
            case 43:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
            case 45:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
            case 47:
              this.pageUrl = '/factoring/FactoringManageIndex/2';
              break;
            case 48:
              this.pageUrl = '/factoring/FactoringManageIndex/3';
              break;
            case 49:
              this.pageUrl = '/factoring/AffirmcreditorRightIndex/1';
              break;
            case 50:
              // 无需跳转
              break;
            case 51:
              this.pageUrl = '/factoring/approvalsupplementsmain/1';
              break;
            case 54:
              this.pageUrl = '/factoring/FactoringManageIndex/4';
              break;
            case 55:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          switch (row.payableBillReminderNode) {
            case 1:
              break;
            case 6:
              this.pageUrl = '/bill/billmanage/3';
              break;
            case 13:
              this.pageUrl = '/bill/billmanage/4';
              break;
            case 15:
              this.pageUrl = '/bill/billmanage/5';
              break;
            case 20:
              this.pageUrl = '/bill/bill-manage/1';
              break;
            case 21:
              break;
            case 22:
              this.pageUrl = '/bill/billmanage/6';
              break;
            case 42:
              this.pageUrl = '/bill/billmanage/6';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 6) {
          // abs
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/abs/accountsCheck/publisher/1';
              break;
            case 2:
              this.pageUrl = '/abs/accountsCheck/acceptor/1';
              break;
            case 3:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 4:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 5:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 6:
              this.pageUrl = '/abs/absplan/5';
              break;
            case 11:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 14:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 20:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 33:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 34:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 35:
              this.pageUrl = '/abs/absplan/4';
              break;
          }
          return row.payableBillReminderNodeName;
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 6) {
        if (row.objType == null) {
          // 优企通
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/gteasypay/guaranteeapprovalmain/1';
              return '优企通开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '优企通接收待确认';
            case 3:
              this.pageUrl =
                '/easypayApproveManage/easypayFinancing/financingApprove/1';
              return '优企通融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '优企通清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '优企通清分待确认';
            case 6:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/2';
              return '优企通融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '优企通被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '优企通融资失败';
            case 9:
              return '优企通流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '优企通转单待审核';
            case 16:
              return row.payableBillReminderNodeName; // 优企通清分
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          return row.payableBillReminderNodeName;
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 1) {
        if (row.objType == null) {
          // 优企通
          if (!row.roleCodes) {
            return '';
          }
          const roleCodes = JSON.parse(row.roleCodes)[1];
          switch (row.payableBillReminderNode) {
            case 1:
              if (roleCodes === 'fund_first') {
                this.pageUrl = '/fund/easypay/openapproval/1';
              }
              if (roleCodes === 'fund_second') {
                this.pageUrl = '/fund/easypay/openapproval/second';
              }
              if (roleCodes === 'fund_third') {
                this.pageUrl = '/fund/easypay/openapproval/three';
              }
              return '优企通开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '优企通接收待确认';
            case 3:
              if (roleCodes === 'fund_first') {
                this.pageUrl = '/fund/easypayFinancing/financingApprove/1';
              }
              if (roleCodes === 'fund_second') {
                this.pageUrl =
                  '/fund/easypayFinancing/financingApprove/second/1';
              }
              if (roleCodes === 'fund_third') {
                this.pageUrl =
                  '/fund/easypayFinancing/financingApprove/three/1';
              }
              return '优企通融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '优企通清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '优企通清分待确认';
            case 6:
              this.pageUrl = '/fund/easypaySign/financingSign/1';
              return '优企通融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '优企通被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '优企通融资失败';
            case 9:
              return '优企通流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '优企通转单待审核';
            case 38:
              this.pageUrl = '/fund/easypaySign/financingSign/2';
              return '放款凭证企业退回';
            case 40:
              this.pageUrl = '/fund/easypaySign/financingSign/2';
              return '放款凭证平台退回';
            case 39:
              return '放款成功';
            case 16:
              return row.payableBillReminderNodeName; // 优企通清分
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          switch (row.payableBillReminderNode) {
            case 46:
              this.pageUrl = '/fund/factoring/FundFactoringManageIndex/1';
              break;
            case 53:
              this.pageUrl = '/factoring/approvalsupplementsmain/1';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          switch (row.payableBillReminderNode) {
            case 2: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 3: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 4: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 5: // 资方
              break;
            case 7: // 资方
              this.pageUrl = '/bill/billmanagezj/4';
              break;
            case 8: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 9: // 资方
              this.pageUrl = '/bill/pjcheckmanage/2';
              break;
            case 10: // 资方
              break;
            case 11: // 资方
              this.pageUrl = '/bill/billmanagezj/5';
              break;
            case 12: // 资方
              this.pageUrl = '/bill/billmanagezj/2';
              break;
            case 14: // 资方
              this.pageUrl = '/bill/billmanagezj/3';
              break;
            case 47: // 资方
              this.pageUrl = '/bill/billcenterzj';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 6) {
          // abs
          switch (row.payableBillReminderNode) {
            case 6:
              this.pageUrl = '/abs/absplan/5';
              break;
            case 11:
              this.pageUrl = '/abs/absplan/1';

              break;
            case 14:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 17:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 19:
              this.pageUrl = '/fund/abs/assetsCheck/recheck/1';
              break;
            case 20:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 21:
              this.pageUrl = '/fund/abs/assetsCheck/finalCheck/1';
              break;
            case 22:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 23:
              this.pageUrl = '/abs/absplan/2';
              break;
            case 33:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 34:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 35:
              this.pageUrl = '/abs/absplan/4';
              break;
          }
          return row.payableBillReminderNodeName;
        }
      }
    },
    // 去处理-跳转到相应页面
    handleTask(payableBillReminderNode, row) {
      this.$router.push({ path: this.pageUrl });
      this.handleCurrentChange(row);
    },
    // 置为已读
    handleCurrentChange(val) {
      // if(val.readStatus == 2) {
      //   val.readStatus = 1
      api.setToRead({ stationLetterId: val.stationLetterId }).then((res) => {
        if (res.success) {
          bus.$emit('getMessageCenterMessageTotal');
        }
      });
      // }
      this.isActive = true;
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  .main {
    display: flex;
    position: absolute;
    top: 64px;
    bottom: 0px;
    overflow: hidden;
    width: 100%;
    aside {
      flex: 0 0 230px;
      width: 230px;
      .el-menu {
        border-right: none;
        i {
          color: #a6c6ff;
        }
        &-item {
          color: #a6c6ff;
          &:hover {
            background-color: #2937a6 !important;
            color: #fff !important;
            i {
              color: #fff !important;
            }
          }
          &.is-active {
            i {
              color: #2937a6 !important;
            }
          }
        }
        .is-active {
          background-color: #fff !important;
          color: #1a2891 !important;
          border-left: 4px solid #be904c;
          border-right: none;
          font-size: 14px;
          i {
            color: #2937a6;
          }
        }
        .el-submenu {
          border-left: none;
          .is-active {
            background-color: #fff !important;
            color: #1a2891 !important;
            border-left: 4px solid #be904c;
          }
        }
      }

      &.collapsed {
        width: 60px;
        min-height: 1vh;
        height: 100%;
        background-color: #2937a6;
        background-image: url('../assets/yilian.png');
        background-repeat: no-repeat;
        background-position: 0% 100%;
        background-size: contain;
        overflow-y: auto;
        .item {
          position: relative;
        }
        .submenu {
          position: absolute;
          top: 0px;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }
      }
    }
    // 右边内容样式
    .menu-collapsed {
      flex: 0 0 60px;
      width: 60px;
    }
    .menu-expanded {
      flex: 0 0 230px;
      width: 230px;
    }
    .content-container {
      flex: 1;
      .breadcrumb-container {
        height: 30px;
        .title {
          float: left;
          color: #475669;
          margin-top: -3px;
        }
      }
      .content-wrapper {
        box-sizing: border-box;
      }
    }
  }
  .sokitmsg {
    height: 220px;
    width: 300px;
    position: fixed;
    right: 30px;
    bottom: 0;
    padding: 10px;
    transition: bottom 2s;
    z-index: 99999999;
    border-radius: 8px;
    border: 1px solid #ebeef5;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    div {
      margin-bottom: 10px;
    }
    div:nth-child(1) {
      margin-bottom: 15px;
      font-weight: bold;
      .left {
        font-size: 16px;
      }
      .right {
        cursor: pointer;
      }
    }
    div:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
    }
    div:nth-child(6) {
      text-align: right;
    }
    a {
      text-decoration: none;
      color: #409eff;
    }
    &.active {
      bottom: -300px;
    }
    .morediv {
      position: absolute;
      bottom: 10px;
      right: 10px;
      margin: 0;
    }
  }
}
</style>
<style lang="scss">
.el-submenu {
  &__icon-arrow {
    color: #99bfff !important;
  }
  &.is-active {
    .el-submenu__title {
      background-color: #2937a6 !important;
      color: #a6c6ff !important;
      i {
        color: #a6c6ff !important;
      }
      &:hover {
        color: #fff !important;
        i {
          color: #fff !important;
        }
        .el-submenu__icon-arrow {
          color: #99bfff !important;
        }
      }
    }
    .el-menu-item {
      background-color: #2937a6 !important;
      color: #a6c6ff !important;
    }
  }
  &__title:hover {
    background-color: #2937a6 !important;
    color: #fff !important;
    i {
      color: #fff !important;
    }
    .el-submenu__icon-arrow {
      color: #99bfff !important;
    }
  }
}
.el-loading-mask {
  z-index: 10000;
}
</style>
