<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
         <abswaitaccept  v-if="showtab==1"></abswaitaccept> 
         <absalreadaccept  v-if="showtab==2"></absalreadaccept> 
         <abssubstitution v-if="showtab==3"></abssubstitution>
         <abswaitsign v-if="showtab==4"></abswaitsign>
         <absalreadsign v-if="showtab==5"></absalreadsign>
         <absback v-if="showtab==6"></absback>

         <absover v-if="showtab==7"></absover>


         
 








    </layout-main-view>
  </div>
</template>
<script>






  import api from "@/api/api";


import moment from 'moment'
import abswaitaccept from './abswaitaccept'
import absalreadaccept from './absalreadaccept'
import abswaitsign from './abswaitsign'
import absalreadsign from './absalreadsign'

import abssubstitution from './abssubstitution'
import absback from './absback'

import absover from './absover'








export default {
  components: {
abswaitaccept,
absalreadaccept,
abswaitsign,
abssubstitution,
absalreadsign,
absback,
absover

  },
    computed:{

  },
  data() {
    return {
      breadcrumblist: ["专项计划管理"],
      showtab: 1,
      tabOptions: [
        { label: "待接收", value: "1", path: "/abs/absplan/1" },
        { label: "已接收", value: "2", path: "/abs/absplan/2" },
        { label: "需置换", value: "3", path: "/abs/absplan/3" },
        { label: "待签约", value: "4", path: "/abs/absplan/4" },
        { label: "已签约", value: "5", path: "/abs/absplan/5" },
        { label: "已退回", value: "6", path: "/abs/absplan/6" },
        { label: "已结束", value: "7", path: "/abs/absplan/7" },

      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      showlist:[]
    };
  },
  created() {
    this.getGrantCreditCorpList();
    if( JSON.parse(sessionStorage.user).corpType==1){
        this.tabOptions=[
        { label: "待接收", value: "1", path: "/abs/absplan/1" },
        { label: "已接收", value: "2", path: "/abs/absplan/2" },
        { label: "待签约", value: "4", path: "/abs/absplan/4" },
        { label: "已签约", value: "5", path: "/abs/absplan/5" },
        { label: "已退回", value: "6", path: "/abs/absplan/6" },
        { label: "已结束", value: "7", path: "/abs/absplan/7" },

      ]
    }
   


  },
  mounted(){
      this.showtab = this.$route.params.state;

  },

  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
        getGrantCreditCorpList(){                   //调取资金机构数据
               api.getGrantCreditCorpList().then(res => {
                    if (res.code == 100000) {
                      this.showlist=   res.datas.list
                    }else{
                            this.$message.error(res.message)

                    }
            });
    },
  }
};
</script>

