<template>
    <div class="boxBusiness">
        <!-- 企业法人信息内容。 -->
        <div class="lineBorder"></div>

        <div class="boxContent">
            <el-row>
                <el-col :span="8">法人姓名：{{msg.legalName}}</el-col>
                <!-- <el-col :span="8">性别：</el-col> -->
                <!-- <el-col :span="8">联系电话：</el-col> -->
                <el-col :span="8">证件类型：身份证</el-col>
                <el-col :span="8">证件号码： {{msg.legalIdCard}}</el-col>
                <!-- <el-col :span="8">出生日期：</el-col> -->
                <!-- <el-col :span="8">户籍地址：</el-col> -->
            </el-row>
        </div>
        <div class="imgContent">
            <el-row>
                <el-col :span="1.5">影像资料：</el-col>
                <el-col :span="22.5">
                    <img class="photo-box" v-for="(item, index) in imgList" :key="index" :src="getImgURL(item.fileUrl)" preview="imgList" :preview-text="item.fileName"/>
                </el-col>

            </el-row>
        </div>



    </div>
</template>

<script>
import api from '@/api/api' 
import moment from 'moment'
export default {
    data(){
        return{
            
        }
    },
    props:{
        msg:{},
        fileList:{},
    },  
    computed:{
        imgList() {
            return this.fileList.filter(v => +v.fileCode == 2 || +v.fileCode == 3)
        },
        
    },  
    methods:{
        moment,
        getImgURL(url) {
            return api.getImgURL(url)
        },
    }
}
</script>

<style lang='scss' scoped>
    .lineBorder{
        width: 100%;
        border-bottom: 1px solid #dcdfe6;
    }
    .boxBusiness{
        padding: 10px 20px 10px 20px;
        .boxContent{
            .el-row{
                #col-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                .el-col{
                    padding: 15px 0px 15px 0px;
                }
            }
        };

        .imgContent{
            .el-row{
                #col-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                .el-col{
                    padding: 10px 0px 10px 0px;
                }
            }
        }
    }

</style>