<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist">
        <el-table :data="listData" align='center' highlight-current-row style="width: 100%;">
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="120">
          </el-table-column>
          <el-table-column prop="absPlanName" label="项目名称" min-width="100"  >
          </el-table-column>
          <el-table-column  label="项目计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'>
          </el-table-column>
          <el-table-column prop="" label="汇集起止日" min-width="160">
            <template slot-scope="scope">
              {{moment(scope.row.collectStartDate).format("YYYY-MM-DD")}} - {{moment(scope.row.collectEndDate).format("YYYY-MM-DD")}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="预计发行利率区间(%)" min-width="160">
            <template slot-scope="scope">
              {{scope.row.preProfitInterestMin}} - {{scope.row.preProfitInterestMax}}
            </template>
          </el-table-column>
          
          <el-table-column  label="计划发起人"  prop='planSponsor' min-width="100" >
          </el-table-column>
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'
export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "项目名称:",
          type: "input",
          field: "absPlanName",
          size: "small"
        },
        {
          label: "项目规模(元): ",
          type: "inputrange",
          field: "preAssertScaleAmountStr",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "preAssertScaleAmountEnd",
          size: "small"
        },
      ],
      total: 0,
      listData: [],
    };
  },
  props: {
    checkStep: {}
  },
  created() { },
  methods: {
    moment: moment,
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(filters) {
      let param = {
        approveNode: this.checkStep,   // 1初审 2复审 3终审
      }
      Object.assign(param , filters)
      api.assetPendingListWeb(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEditinfo(row){
      let queryParams = { id: row.absPlanId, isshowbtn:1, leaderCorpType: row.leaderCorpType, absPlanCorp: row.absPlanCorp, checkStep: this.checkStep}
      this.$router.push({
        name: "资产详情",
        query: queryParams
      });
    },
  }
};
</script>


