<!--平台收费服务协议-->
<template>
    <div ref="dialog">
        <div>
        <p style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">协议</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">编号：{{chargeData.no}} </span></p>
        <p style="font-size:18pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:18pt; font-weight:bold">经证供应链</span><span
                style="font-family:微软雅黑; font-size:18pt; font-weight:bold">平台收费</span><span
                style="font-family:微软雅黑; font-size:18pt; font-weight:bold">服务协议</span></p>
        <p
            style="font-size:10.5pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:right; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:10.5pt">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">融资申请人：{{chargeData.financeCorpName}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人：{{chargeData.legalName}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">统一社会信用代码</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">：{{chargeData.creditCode}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系方式：{{chargeData.telephone}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系地址：{{
                chargeData.registerAddr
                }}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:18pt 0pt 6pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">运营方</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">：</span><span
                style="width:11pt; text-indent:0pt; display:inline-block"></span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">上海经证科技有限公司 </span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人： 朱志勇</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">统一社会信用代码</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">：91310109MA1G5WM16H</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:18pt 0pt 6pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">鉴于</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台（所涉域为*.scm.sh-jztech.com的中文网站及其客户端）是依法设立并合法拥有经营权的专业</span><span
                style="font-family:微软雅黑; font-size:12pt">供应链科技服务平台</span><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">下称</span><span
                style="font-family:微软雅黑; font-size:12pt">“</span><span
                style="font-family:微软雅黑; font-size:12pt; background-color: gold;">经证供应链平台</span><span style="font-family:微软雅黑; font-size:12pt">”或“平台”）。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">2、融资申请人在接受本协议约定的服务前（具体服务详见附件《融资服务指引》）已审慎阅读并充分理解本协议，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">尤其是含有加粗字的条款</span><span
                style="font-family:微软雅黑; font-size:12pt">，且同意按本协议的约定及不时发布的交易指引，接受平台提供的服务，</span><span
                style="font-family:微软雅黑; font-size:12pt">遵守平台管理规则及收费标准</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:normal">3、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">本协议一经融资申请人在网页选择“我已阅读并同意签署《平台收费服务协议》”，即表示融资申请人同意并签署了本协议，双方均应遵守本协议约定。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">一、平台费用</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">1、双方协议一致，平台费用包括：平台服务费。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">2、平台费用标准</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">平台服务费：指融资申请人融资申请成功后应当支付的平台服务费，按以下方式计算收取若不足500元，则按500元计算。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">平台服务费=融资金额×年化费率×预估融资期限/360</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">预估融资期限</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">=</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold"></span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">到期</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">日-</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">融资申请日-</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold"></span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold"></span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">日</span></p>
 
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">3、平台费用的支付</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">在</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">资金方实际放款日，融资申请人应当自行支付或委托第三方机构向平台支付平台服务费</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">人民币</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">{{chargeData.platformServiceFee}}</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">元</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">其中，融资金额为人民币{{chargeData.payableAmount}}元，年化费率为{{chargeData.serviceInterest}}%，预估融资期限为{{chargeData.preVouncherTime}}天</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">）。</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:2.5pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt;">融资申请人同意委托资金方或其他经证供应链平台确认的第三方支付机构代为支付或扣付平台费用至经证供应链平台指定账户。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:2.5pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">4、平台费用的退还</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:2.5pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">若融资申请失败的，</span><a name="_Hlk95488698"><span
                    style="font-family:微软雅黑; font-size:12pt; font-weight:bold">在资金方经平台系统拒绝融资流程</span></a><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">之日起5个工作日内，平台全额无息退还预估平台费用。</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">5、平台费用收款账户</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">开户行：上海银行普陀支行</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">账户名：上海经证科技有限公司</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">账号：03005172992</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">6、平台在融资申请人融资成功后开具相应金额的发票。</span><span
                style="font-family:微软雅黑; font-size:12pt">若融资申请人的开票信息有变动，应即刻通知平台，否则由此产生的不利后果由融资申请人自行承担。</span><span
                style="font-family:微软雅黑; font-size:12pt">融资申请人开票信息：</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">公司名称：{{chargeData.financeCorpName}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">税号：{{chargeData.taxNum}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">开户行：{{chargeData.openBankReceiver}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">银行账号：{{chargeData.publicAccountReceiver}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">地址：{{chargeData.registerAddr}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">电话：{{chargeData.telephone}}</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">7、双方应当各自承担因履行本协议所产生的税费。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">二、违约责任</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt">1、融资申请人违反附件《融资服务指引》第三条“承诺和保证”中的任一事项，或违反本协议及其附件其他约定义务的事项的构成违约，已支付的平台费用不予退还，并承担由此给平台造成的一切损失。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">2、融资申请人逾期支付平台费用，每逾期一天，应向平台支付逾付金额万分之五的违约金。</span></p>
        <p
            style="font-size:10.5pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:10.5pt">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">三、保密协议</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt">1、任意一方在订立本协议前后及协议履行过程中获悉的对方资料，除已向社会公开或根据本协议各方同意公开的内容外，均属该方商业机密。双方均有责任保</span><span
                style="font-family:微软雅黑; font-size:12pt">护对方的商业和技术机密，不得将对方的商业信息和技术资料及客户信息透露给第三方（根据有关法律法规规定向有权机构如政府执法部门、证券交易所等进行披露的除外），若违反此项条款，违约方将负责赔偿因泄露对方商业和技术机密、客户信息而给对方造成的所有损失。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">2、本协议保密义务为持续性义务，不因本协议的终止而终止。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">四、争议解决方式</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">1、本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt">2、因履行本协议发生的争议应通过协商解决，协商不成的，任何一方均有权将争议提交上海国际经济贸易仲裁委员会，按照提交仲裁时该会现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对各方均有约束力。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt">3、当产生任何争议及任何争议正按前条规定进行解决时，除争议事项外，双方应继续行使本协议项下的其他权利，履行本协议项下的其他义务。如本协议中的任何条款被认定为全部或部分不合法、无效或不可强制执行，并不影响其他条款的有效和执行。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">五、其他事项</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">1</span><span
                style="font-family:微软雅黑; font-size:12pt">、本协议中部分条款根据相关法律法规等的规定无效或部分无效的，该等无效不影响本协议项下其他条款的效力，各方仍应履行其在本协议项下义务。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2、融资申请人不可撤销同意，经证供应链平台可以根据客观情况的变化随时进行业务性调整或产品性调整，并变更本协议，变更后的本协议将会在经证供应链平台上重新公布，融资申请人愿意接受本协议及其修改。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">、本协议项下附件和补充协议构成本协议不可分割的一部分，与本协议具有同等法律效力。</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">4、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">融资申请人在平台注册的地址及联系方式，均为有效联络方式。融资申请人更改联系人、联系地址、联系电话的，应在相关信息变更之日起三日内更新其在平台的注册信息。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">5</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、本协议（</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">含</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">附件）</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">经各方加盖公章（包括电子签章形式）</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">即订立生效，为各方同意和认可，对各方均具有法律约束力。本</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">协议</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">及其修改或补充均可采用通过</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">经证供应链</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">平台以电子文本形式制成，可以有一份或者多份并且每一份具有同等法律效力，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">各方</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">均认可该形式的协议效力。</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">6</span><span
                style="font-family:微软雅黑; font-size:12pt">、本协议各方确认并同意由平台及其合作第三方电子签名认证机构所提供的与本协议有关的书面合同或者数据电文或者其他电子信息在无明显错误的情况下作为本协议有关事项的有效证据。</span>
        </p>
        <p
            style="font-size:12pt; line-height:23pt; margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">7</span><span
                style="font-family:微软雅黑; font-size:12pt">、融资申请人同意并授权平台对整个交易过程进行记录，平台出具的所涉交易过程中的各项证明文件包括但不限于对账单、证明、账户截图、资金流水截图等均具有法律效力。</span>
        </p>
        <p style="margin:6pt 0pt; orphans:0; text-align:justify; text-indent:22pt; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold; letter-spacing:-0.65pt">8</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold; letter-spacing:-0.65pt">、融资申请人声明：</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">本公司已认真阅读本协议，并充分理解本协议的法律涵义。</span></p>
         <p style="line-height:15.6pt; margin-top:30pt; orphans:0; text-indent:19.2pt; widows:0"><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">平台运营方</span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">:</span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">上海经证科技有限公司</span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold"></span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">                          </span><span
                style="font-family:黑体; font-size:10.5pt;margin-left:220pt; font-weight:bold">融资申请人</span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">:</span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold"> </span><span
                style="font-family:黑体; font-size:10.5pt; font-weight:bold">{{chargeData.financeCorpName}}</span></p>
        <p style="line-height:15.6pt; margin:0pt; orphans:0; text-indent:77pt; widows:0"><span
                style="font-family:黑体; font-size:10.5pt">（盖章）</span><span style="font-family:黑体; font-size:10.5pt">
            </span><span style="font-family:黑体; font-size:10.5pt">                                         </span><span
                style="font-family:黑体; margin-left:350pt;font-size:10.5pt">（盖章）</span></p>
        <!-- <p style="line-height:15.6pt; margin:0pt; orphans:0; text-indent:19.2pt; widows:0"><span
                style="font-family:黑体; font-size:10.5pt">&#xa0;</span></p>
        <p style="line-height:10.85pt; margin:0pt; orphans:0; text-indent:22pt; widows:0"><span
                style="font-family:黑体; margin-left:30pt;font-size:10.5pt">{{moment(date).format('YYYY年MM月DD日')}}</span><a name="page2"></a><span
                style="font-family:黑体; margin-left:250pt;font-size:10.5pt"> </span><span
                style="font-family:黑体; font-size:10.5pt">{{moment(date).format('YYYY年MM月DD日')}} </span></p> -->
    </div>

    </div>
</template>
<script>
import moment from "moment"
export default {
    data() {
        return {
            chargeData: {
                no: "", // 合同编号
                financeCorpName: "", // 融资申请人/账户名
                legalName: "", // 法定代表人
                creditCode: "", // 统一社会信用代码
                telephone: "", // 联系方式
                registerAddr: "", // 联系地址
                openBankReceiver: "", // 开户行
                publicAccountReceiver: "", // 账号
                serviceInterest: "",  // 技术服务费率
                informationServiceInterest: "" // 信息咨询费率
            },
            date:''
        }
    },
    props: {
        chargeInfo: Object
    },
    methods:{moment:moment,},
    mounted() {
        this.date = new Date()
        Object.assign(this.chargeData,this.chargeInfo);
        console.log(this.chargeData,'chh')

    }
}
</script>
