<template>
  <div class="boxContent">
    <layout-main-view :breadcrumb="breadcrumblist"></layout-main-view>
    <el-button
      class="buttonClass"
      type="primary"
      size="small"
      @click="backToUpPage"
      >返回</el-button
    >
    <div v-if="this.imgList1.length !== 0">
      <span class="photo-title">授信担保协议：</span>
      <img
        class="photo-box"
        v-for="(item, index) in imgList1"
        :key="index"
        :src="getImgURL(item)"
        preview="imgList"
        :preview-text="item.fileName"
      />
    </div>
    <div v-if="this.imgList2.length !== 0">
      <span class="photo-title">优企通担保协议：</span>
      <img
        class="photo-box"
        v-for="(item, index) in imgList2"
        :key="index"
        :src="getImgURL(item)"
        preview="imgList"
        :preview-text="item.fileName"
      />
    </div>
    <div v-if="this.imgList3.length !== 0">
      <span class="photo-title">授信协议附件：</span>
      <img class="photo-box" v-for="(item, index) in imgList3" :key="index" :src="(getFileType(item) === 'img' ? (downFile() + '?filePath=' + item + '&fileName=img'): (getFileType(item) === 'pdf' ? file : file2)) " :preview="getFileType(item) === 'img' && 'credit'" preview-text="" :title="getFileType(item)!=='img' && '点击下载'" @click="getFileType(item) !== 'img' && download(item)"/>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import moment from "moment";
import upload from '@/api/api'
import file from '@/assets/file.png'
import file2 from '@/assets/zip.png'
export default {
  data() {
    return {
      breadcrumblist: ["授信合同详情"],

      corpId: JSON.parse(sessionStorage.getItem("user")).relationCorpId,

      corpDetail: {},
      createTime: "",
      logoUrl: "",
      file:file,
      file2:file2,
      imgList1: [],
      imgList2: [],
      imgList3: [],
      imgTypeList: ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'PNG', 'JPG', 'JPEG', 'BMP', 'GIF'],
    };
  },
  created() {
    this.getImgList();
  },
  methods: {
    /** 这个方法是 获取到imgList1的方法，如下所示： */
    getImgList() {
      this.corpDetail = JSON.parse(
        sessionStorage.getItem("authorizationDetailMsg")
      );

      if (this.corpDetail.guaranteeUrl) {
        this.imgList1 = this.corpDetail.guaranteeUrl.split(",");
        console.log(this.imgList1);
      }
      if (this.corpDetail.payableGuaranteeUrl) {
        this.imgList2 = this.corpDetail.payableGuaranteeUrl.split(",");
      }
      if (this.corpDetail.grantAgreementFileUrl) {
        this.imgList3 = this.corpDetail.grantAgreementFileUrl.split(",");
        console.log(this.imgList3, "999");
        this.$previewRefresh();
      }
    },

    getImgURL(url) {
      return api.getImgURL(url);
    },
    // 获取企业认证信息
    getTrialDetail(corpId) {
      api.getTrialDetail({ corpId }).then((res) => {
        if (res.success) {
          this.createTime = res.datas.createTime;
        }
      });
    },

    getFileType(data) {
      let index = data.lastIndexOf(".");
      let suffix = data.substr(index + 1);
      if ( suffix === 'pdf') {
        return 'pdf';
      } else if(this.imgTypeList.indexOf(suffix) != -1 && suffix !== 'pdf') {
        return 'img';
      } else {
          return 'zip'
      }
    },
    download(data) {
      window.open(this.downFile() + "?filePath=" + data + "&fileName=file");
    },
     downFile() {
      return api.downFile();
    },
    backToUpPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.boxContent {
  position: relative;

  .buttonClass {
    position: absolute;
    z-index: 1000;
    right: 30px;
    top: 88px;
  }
}
.boxContent div {
  padding: 20px 0px 20px 40px;
  font-size: 16px;
  background: #fff;
}
.photo-title {
  display: inline-block;
  width: 150px;
}
.photo-box {
  width: 200px;
  margin-top: 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;
}

#backBtn {
  position: absolute;
  right: 50px;
  z-index: 100;
}
</style>
