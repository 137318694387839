<template>
  <div class="content easypay-manager">
    <div class="content-inner">
      <section
        class="info-card-A"
        style="height: calc(100vh - 150px)"
        ref="body1"
      >
        <steps :stepList="stepList" :current="current"></steps>
        <div
          class="card-bd card-bd-row refuseback"
          style="
            font-size: 14px;
            padding: 0px;
            background: rgba(255, 242, 244, 1);
            border: 1px solid rgba(255, 204, 205, 1);
          "
          v-if="$route.query.back == 'back'"
        >
          <el-row>
            <el-col :span="8" class="lb_body">
              <img
                src="../../../assets/cuowu.png"
                alt
                style="margin-left: -27px; vertical-align: middle"
              />
              <span class="info-key">被退回原因:</span>
              <span class="info-value index">{{ refusereason.reson }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="lb_body" style="font-size: 14px">
              <span class="info-key">退回节点:</span>
              <span class="info-value index">{{
                refusereason.approveNodeValus
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body" style="font-size: 14px">
              <span class="info-key">退回时间:</span>
              <span class="info-value index">{{
                refusereason.createTime
                  ? moment(refusereason.createTime).format('YYYY-MM-DD HH:mm')
                  : ''
              }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :span="24"
              class="lb_body"
              style="font-size: 14px; margin-bottom: 20px"
            >
              <span class="info-key">备注:</span>
              <span class="info-value index">{{ refusereason.remark }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="card-number">
          <span class="card-number-title">优企通编号:</span>
          <span class="card-number-value">{{ financInfo.payableBillNo }}</span>
          <el-button
            type="primary"
            v-if="type == 2"
            class="easy-detail-btn"
            icon="el-icon-view"
            @click="easyDetail"
            >详情</el-button
          >
          <el-button
            class="card-number-btn"
            type="primary"
            size="small"
            icon="el-icon-zoom-in"
            @click="preview"
            >预览</el-button
          >
        </div>
        <div class="card-hd sub-title">
          <span class="txbst">基本信息</span>
        </div>
        <div class="card-bd card-bd-row">
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="130px"
            class="demo-ruleForm"
            label-position="left"
            v-if="type == 1"
            :rules="rulesForm"
          >
            <div class="info">
              <el-col class="lb_body">
                <el-form-item label="优企通余额(元):" prop>
                  <span class="info-value">{{
                    $changeMoneyThousand(financInfo.balanceAmount)
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col class="lb_body">
                <el-form-item label="优企通净额(元):" prop>
                  <span class="info-value">{{
                    $changeMoneyThousand(financInfo.financeAmountable)
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col class="lb_body">
                <el-form-item label="承诺付款日:" class="pr12" prop>
                  <span class="info-value">{{
                    financInfo.promisePayTime | formatDate
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col class="lb_body">
                <el-form-item label="资金机构:" class="pr12" prop>
                  <span class="info-value">{{ financInfo.fundCorpName }}</span>
                  <!-- <span class="info-value">瑞合商业保理有限公司</span> -->
                </el-form-item>
              </el-col>
              <el-col class="lb_body">
                <el-form-item label="融资机构:" class="pr12" prop>
                  <span class="info-value">{{
                    financInfo.receivedCorpName
                  }}</span>
                  <!-- <span class="info-value">上海昭元市场营销策划有限公司</span> -->
                </el-form-item>
              </el-col>
              <el-col class="lb_body">
                <!-- v-show="parseFloat(financInfo.financeYearInterest) !== 0" -->
                <el-form-item
                  label="预估融资利率(%/年化):"
                  class="pr12"
                  prop
                  label-width="170px"
                >
                  <span class="info-value"
                    >{{ financInfo.financeYearInterest }}％</span
                  >
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                  label="融资总额(元): "
                  class="pr12"
                  prop="payableAmount"
                >
                  <el-input
                    type="number"
                    v-model="ruleform.payableAmount"
                    placeholder="融资总额不能大于该优企通净额"
                    @change="textChange"
                  ></el-input>
                </el-form-item>
              </el-col>

              <!-- <el-col :span="8">
                <el-form-item label="开立优企通金额(大写): ">
                  <el-input disabled></el-input>
                </el-form-item>
              </el-col>-->
              <el-col>
                <el-form-item label="预估融资利息(元): " class="pr12" prop>
                  <el-input
                    disabled
                    v-model="financInfoFinance.preFinanceInterest"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="融资利息承担方: " class="pr12" prop
                  >融资企业</el-form-item
                >
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item label="信息咨询费(元): " class="pr12">-->
              <!--                  <el-input v-model="financInfoFinance.informationFee" disabled></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col>
                <el-form-item label="预计融资金额(元): " class="pr12">
                  <el-input
                    v-model="financInfoFinance.preIncomeAmount"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                  class="info-key pr12"
                  label="平台服务费(元): "
                  prop
                >
                  <el-input
                    v-model="financInfoFinance.platformServiceFee"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="平台服务费承担方: " class="pr12" prop
                  >融资企业</el-form-item
                >
              </el-col>
            </div>
            <el-row>
              <div>
                <p v-if="type == 1" class="info-value">费用说明：</p>
                <p>
                  (1)预计融资期限＝优企通承诺付款日 －
                  预计放款日期（融资申请日2天后）
                </p>
                <p>(2)预计融资金额 ＝ 融资总额/（1 + 预计融资年利率 / 360 * 预计融资期限）</p>
                <p>
                  (3)预计融资利息 ＝ 预计融资金额 ＊ 预计融资年化利率(年化 )＊
                  预计融资期限／ 360
                </p>
                <p>
                  (4)平台服务费 ＝ (平台服务费率){{ serviceInterest }}% ／ 360 ＊
                  融资金额 ＊ 预计融资期限；最低收费标准为500元
                </p>
                <p>
                  (5)供应商到手金额 ＝ 融资金额 — 平台服务费，具体以银行放款为准。
                </p>
                <p style="color: red">
                  注：以融资审批通过后，合同签署时，实际放款利率为准
                </p>
              </div>
            </el-row>
            <!--            <p>(4)信息咨询费＝(信息咨询费率){{informationServiceInterest}}% ／ 360 ＊ 融资金额 ＊ 预计融资期限</p>-->
          </el-form>
        </div>
        <el-form
          :model="ruleform"
          ref="ruleform"
          label-width="150px"
          class="demo-ruleForm"
          label-position="left"
          v-if="type == 2"
        >
          <el-row class="row">
            <el-col :span="8" class="lb_body">
              <span class="info-key">融资项目编号:</span>
              <span class="info-value index">{{
                payableBillFinance.payableBillFinanceNo
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">融资子优企通编号:</span>
              <span class="info-value index">{{
                payableBillFinance.financePayableBillNo
              }}</span>
            </el-col>

            <el-col :span="8" class="lb_body">
              <span class="info-key">承诺付款天数:</span>
              <span class="info-value">{{ financInfo.promiseDay }}</span>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8" class="lb_body">
              <span class="info-key">优企通余额(元):</span>
              <span class="info-value index">{{
                $changeMoneyThousand(financInfo.balanceAmount)
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">优企通净额(元):</span>
              <span class="info-value index">{{
                $changeMoneyThousand(financInfo.financeAmountable)
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">承诺付款日:</span>
              <span class="info-value index">{{
                financInfo.promisePayTime | formatDate
              }}</span>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8" class="lb_body">
              <span class="info-key">资金机构:</span>
              <span class="info-value index">{{
                payableBillFinance.fundCorpName
              }}</span>
            </el-col>
            <el-col
              :span="8"
              class="lb_body"
              v-show="parseFloat(payableBillFinance.financeYearInterest) !== 0"
            >
              <span class="info-key"
                >{{
                  $route.query.alrealyFlag
                    ? '实际融资年化利率：'
                    : '融资年化利率：'
                }}
              </span>
              <span class="info-value index"
                >{{ payableBillFinance.financeYearInterest }}％</span
              >
            </el-col>
            <!-- <el-col :span="8" class="lb_body">
                <span class="info-key">企业账户:</span>
                <span class="info-value index">{{financInfo.receivedCorpName}}</span>
              </el-col>-->
            <el-col
              :span="8"
              class="lb_body"
              v-show="parseFloat(payableBillFinance.financeYearInterest) !== 0"
            >
              <span class="info-key">平台服务费(元):</span>
              <span class="info-value index">{{
                $changeMoneyThousand(payableBillFinance.platformServiceFee)
              }}</span>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8" class="lb_body">
              <span class="info-key"
                >{{
                  $route.query.alrealyFlag
                    ? '实际融资金额（元）：'
                    : '融资金额(元):'
                }}
              </span>
              <span class="info-value index">{{
                $changeMoneyThousand(payableBillFinance.amount)
              }}</span>
            </el-col>
            <!-- <el-col :span="8" class="lb_body">
                <span class="info-key">开立优企通金额(大写):</span>
                <span class="info-value index">{{payableBillFinance.balanceAmount}}</span>
              </el-col>-->
            <el-col :span="8" class="lb_body">
              <span class="info-key">{{
                $route.query.alrealyFlag
                  ? '实际融资利息（元）：'
                  : '预计融资利息(元):'
              }}</span>
              <span class="info-value index">{{
                $changeMoneyThousand(payableBillFinance.preFinanceInterest)
              }}</span>
            </el-col>
            <el-col :span="8" class="lb_body">
              <span class="info-key">{{
                $route.query.alrealyFlag
                  ? '实际融资金额（元）：'
                  : '预计融资金额(元):'
              }}</span>
              <span class="info-value index">{{
                $changeMoneyThousand(payableBillFinance.preIncomeAmount)
              }}</span>
            </el-col>
          </el-row>
        </el-form>
        <!-- <div v-if="type != 1" class="card-hd sub-title">
            <span class="txbst">融资收款账户</span>
          </div>
          <div v-if="type != 1" class="account-content">
            <div class="base-item">
              企业账户：
              <span style="margin-left: 20px">{{
                financInfo.receivedCorpName
              }}</span>
            </div>
            <div class="base-item">
              企业开户行：
              <span style="margin-left: 20px">{{
                payableBillFinance.openBankReceiver
              }}</span>
            </div>
            <div class="base-item">
              企业账号：
              <span style="margin-left: 20px">{{
                payableBillFinance.publicAccountReceiver
              }}</span>
            </div>
          </div>
        </div> -->
        <!-- <div v-if="type == 1" class="card-hd sub-title">
          <span class="txbst">融资收款账户</span>
        </div>
        <div v-if="type == 1" class="card-bd info-box card-bd-row">
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
            :rules="rulesForm"
          >
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="企业账户:" prop>
                  <span class="info-value">{{
                    financInfo.receivedCorpName
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业开户行: " prop="openBank" class="pr12">
                  <el-select
                    v-model="ruleform.openBank"
                    placeholder="请选择"
                    @change="getOpenBankNo"
                  >
                    <el-option
                      v-for="item in corpAccountPublicList"
                      :key="item.openBank"
                      :label="item.openBank"
                      :value="item.openBank"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="info-key pr12"
                  label="企业账号: "
                  prop="openBankNo"
                >
                  <el-select
                    v-model="ruleform.openBankNo"
                    placeholder="请选择"
                    @change="getCorpNo"
                  >
                    <el-option
                      v-for="item in corpAccountPublicNoList"
                      :key="item.accountNo"
                      :label="item.accountNo"
                      :value="item.accountNo"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span
            style="margin-left: 28px"
            v-if="type == 1 && isShow"
            class="info-value"
            >温馨提示：还没有绑定卡,</span
          >
          <a
            v-if="type == 1 && isShow"
            class="downsize"
            style="color: #2937a6; text-decoration: underline; cursor: pointer"
            size="small"
            @click="linkCompanyManage"
            >点击此处</a
          >
          <span v-if="type == 1 && isShow" class="info-value"
            >完成对公账户绑定。</span
          >
        </div> -->
        <div class="card-hd sub-title margin-top">
          <span class="txbst">缴费信息</span>
        </div>
        <div class="card-bd info-box card-bd-row">
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
            :rules="rulesForm"
          >
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="费用类型:" prop>
                  <span class="info-value">平台服务费</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="费用金额(元):" prop>
                  <span class="info-value">{{
                    $changeMoneyThousand(
                      financInfoFinance.platformServiceFee
                    ) ||
                    $changeMoneyThousand(payableBillFinance.platformServiceFee)
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="费用承担方:" prop>
                  <span class="info-value">融资企业</span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="支付方式:" prop>
                  <span class="info-value">线下支付</span>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="收款账户:" prop>
                  <span class="info-value">{{ serviceAccountInfo.accountName || '--' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="开户行:" prop>
                  <span class="info-value">{{ serviceAccountInfo.accountBank || '--' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="收款账号:" prop>
                  <span class="info-value">{{ serviceAccountInfo.account || '--' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <div class="subtitle margin-top">
              <span style="color: red">*</span>
              <span class="txbst3">{{
                type == 1 ? '转账凭证上传' : '转账凭证'
              }}</span>
              <span
                v-if="type == 1 || $route.query.approveStatus == 102"
                class="txbst2"
                >（支持上传拓展名：Office文件 *txt *.pdf *.jpg
                *.png。单个文件不超过10M）</span
              >
            </div>
            <div
              class="upload-box"
              v-if="
                (type == 1 || $route.query.approveStatus == 102) &&
                  !$route.query.detailFlag
              "
            >
              <el-upload
                ref="uploadFile"
                class="upload-demo"
                :action="baseURL + '/web/file/uploadFile'"
                :headers="Authorization"
                list-type="picture-card"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove2"
                :on-preview="handlePreview"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
              >
                <i class="el-icon-plus"></i>
                <span class="upload-info">上传</span>
              </el-upload>
            </div>
            <div v-else class="picture-box">
              <div class="picture-box-item" v-for="item in imgList" :key="item">
                <img
                  v-if="
                    imgType.includes(item.substring(item.lastIndexOf('.'))) &&
                      item.substring(item.lastIndexOf('.')).toLowerCase() !=
                        '.pdf'
                  "
                  :src="`${baseURL}` + `/web/file/downFile?filePath=${item}`"
                  @click="$imgPreview"
                  :data-img="
                    `${baseURL}` + `/web/file/downFile?filePath=${item}`
                  "
                />
                <img
                  v-else-if="
                    item.substring(item.lastIndexOf('.')).toLowerCase() ==
                      '.pdf'
                  "
                  src="../../../assets/file.png"
                  @click="previewPdf(item)"
                />
                <img
                  v-else
                  v-show="item && item.length > 0"
                  :src="zip"
                  @click="downFile(item)"
                />
              </div>
            </div> -->
          </el-form>
        </div>
        <div class="card-line"></div>
        <div class="card-hd sub-title">
          <span class="txbst">合同信息</span>
        </div>
        <div
          class="maincontin"
          v-for="(item, index) in financInfo.financInfoContract"
          :key="index"
        >
          <!-- <div class="maincontin">  -->
          <span class="mainht">
            <span class="info-key">合同编号:</span>
            <span class="info-value">{{ item.contractNo }}</span>
            <a
              class="page-head-back downsize"
              style="color: #2937a6"
              size="small"
              :href="api.filesdownLoadFile(item)"
              >一键下载</a
            >
          </span>
          <div class="contract info">
            <el-col>
              <span class="info-key">合同编号:</span>
              <span class="info-value">{{ item.contractNo }}</span>
            </el-col>
            <el-col>
              <span class="info-key">合同名称:</span>
              <span class="info-value">{{ item.contractName }}</span>
            </el-col>
            <el-col>
              <span class="info-key">合同金额:</span>
              <span class="info-value"
                >{{ $changeMoneyThousand(item.contractAmount) }}元</span
              >
            </el-col>
            <el-col>
              <span class="info-key">合同金额（大写）:</span>
              <span class="info-value"
                >{{ convertCurrency(item.contractAmount) }}元</span
              >
            </el-col>
            <el-col>
              <span class="info-key">合同签署时间:</span>
              <span class="info-value">{{
                item.contractTime
                  ? moment(item.contractTime).format('YYYY-MM-DD')
                  : '--'
              }}</span>
            </el-col>
          </div>
          <div class="card-hd sub-title" style="margin-bottom: 24px">
            相关合同
          </div>
          <div
            class="card-bd marrimg"
            style="padding-left: 30px; margin-top: -20px"
          >
            <transition
              name="fade"
              v-if="
                item.payableBillContractList &&
                item.payableBillContractList.length &&
                item.payableBillContractList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillContractList"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="10%"-->
            <!--height="120px"-->
            <!--preview-text="合同"-->
            <!--:preview="index+'0'"-->
            <!--v-for="(items,key) in  item.htlist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div>
              <div>
                共有文件:
                <span class="numcolor">{{ item.htlist.length }}</span> 个
              </div>
            </div>
          </div>
          <div class="card-hd sub-title">
            相关发票
            <!-- <el-button
              type="text"
              class="page-head-back"
              style="color: #2937A6;"
              size="small"
              @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <transition
              name="fade"
              v-if="item.fplist && item.fplist.length && item.fplist.length > 0"
            >
              <el-row>
                <picfile :picfilelist="item.fplist"></picfile>
              </el-row>
            </transition>
            <!-- <el-row> -->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="20%"-->
            <!--height="120px"-->
            <!--preview-text="发票"-->
            <!--:preview="index+'2'"-->
            <!--v-for="(items,key) in  item.fplist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div style="padding: 0px 0px 30px 0px">
              共有文件:
              <span class="numcolor">{{ item.fplist.length }}</span> 个
              <span style="margin-left: 32px">累计发票金额：</span>
              <span class="numcolor">{{
                $changeMoneyThousand(item.invoiceAllAmount)
              }}</span>
              元
            </div>
            <div>
              <el-table
                :data="item.payableBillInvoiceList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                  prop="invoiceBuyer"
                  label="购买方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceBuyer }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceSeller"
                  label="销售方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceSellerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceSeller }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCode"
                  label="发票代码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceNo"
                  label="发票号码"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceNo }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCheckCode"
                  label="校验码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCheckCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCheckCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceOpenTime"
                  label="开票日期"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceOpenTimeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceOpenTime }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  label="开具金额(元)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmount)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmountWithoutTax"
                  label="开具金额(不含税)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="
                        scope.row.invoiceAmountWithoutTaxChange == 2 ? '改' : ''
                      "
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmountWithoutTax)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-hd sub-title">
            其他附件
            <!-- <el-button
              type="text"
              class="page-head-back"
              style="color: #2937A6;"
              size="small"
              @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div
            class="card-bd marrimg"
            style="padding-bottom: 51px; padding-left: 30px"
          >
            <!--<span class="info-key">物流类型:</span>-->
            <transition
              name="fade"
              v-if="
                item.wuliulist &&
                item.wuliulist.length &&
                item.wuliulist.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.wuliulist"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="12%"-->
            <!--height="120px"-->
            <!--preview-text="其他附件"-->
            <!--:preview="index"-->
            <!--v-for="(items,key) in item.wuliulist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <!--<span class="info-key">银行类型:</span>-->
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="10%"-->
            <!--height="120px"-->
            <!--preview-text="其他附件"-->
            <!--:preview="index"-->
            <!--v-for="(items,key) in item.banklist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div>
              共有文件:
              <span class="numcolor">{{
                item.wuliulist.length + item.banklist.length
              }}</span>
              个
            </div>
          </div>
        </div>
        <div class="maincontin">
          <span class="mainht">
            <span class="info-key">融资补件资料</span>
          </span>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <span class="info-key">请根据资金方要求补充完善融资资料:</span>
            <div v-if="type == 1">
              <el-table
                :data="financInfo.informationList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  width="70"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  prop="informationName"
                  label="资料名称"
                  min-width="170"
                ></el-table-column>
                <el-table-column label="资料模版" min-width="100">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.provideTemplate == 1"
                      size="medium"
                      type="text"
                      @click="downloadModel(scope.row)"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="claimRemark"
                  label="资料要求"
                  min-width="200"
                ></el-table-column>
                <el-table-column
                  label="已上传资料"
                  min-width="200"
                  props="fileName"
                >
                  <template slot-scope="scope">
                    {{ scope.row.fileName }}
                    <i
                      v-if="scope.row.fileName"
                      class="el-icon-close deleteImg"
                      @click="deleteImg(scope.row)"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column label="上传资料" min-width="100">
                  <template slot-scope="scope">
                    <el-upload
                      class="upload-demo upload-list"
                      :action="baseURL + '/web/file/uploadFile'"
                      :before-upload="beforeUp"
                      :file-list="fileImgList"
                      :on-success="(a, b, c) => handleupfn(a, b, c, index)"
                      :data="fileTypes"
                      :headers="Authorization"
                    >
                      <el-button
                        size="medium"
                        type="text"
                        @click="upload(scope)"
                        >上传</el-button
                      >
                    </el-upload>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="type == 2">
              <el-table
                :data="modelList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  prop="informationName"
                  label="补件要求"
                  min-width="180"
                ></el-table-column>
                <!-- <el-table-column label="模版" min-width="170">
                  <template slot-scope="scope">
                    <el-button size="medium" type="text" @click="handleEdit(scope.row)">下载</el-button>
                  </template>
                </el-table-column>-->
                <el-table-column
                  prop="claimRemark"
                  label="说明备注"
                  min-width="200"
                ></el-table-column>
                <el-table-column prop="fileName" label="附件" min-width="130">
                  <template slot-scope="scope">
                    <!-- <span @click="cilckImg(scope.row)">{{scope.row.fileName}}</span> -->
                    <img
                      v-if="scope.row.fileType == '.jpg,.png,.jpeg,.bmg'"
                      :src="api.getImgURL(scope.row.fileUrl)"
                      alt="图片丢失"
                      width="50%"
                      height="50%"
                      :preview-text="scope.row.fileName"
                      :preview="1"
                    />
                    <div v-else>
                      <span>{{ scope.row.fileName }}</span>
                      <el-button
                        type="text"
                        @click="download(scope.row)"
                        style="margin-left: 10px"
                        >点击下载</el-button
                      >
                      <!-- <a
                          class="page-head-back downsize"
                          style="color: #2937A6;"
                          size="small"
                          :href="api.filesdownsingle(scope.row.fileUrl)"
                      >点击下载</a>-->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div>
          <!-- 13982772417732101
      {{getImgURL('files/2022-11-03/1667443844135822.png')}} -->
        </div>
        <div
          style="text-align: center; padding: 30px 0"
          class="xytext"
          v-if="financing != 'financing' && $route.query.isshowbtn != 2"
        >
          <el-checkbox v-model="checked" @change="showDialog(checked)"
            >我已阅读并同意</el-checkbox
          >
          <a
            href="javascript:;"
            style="display: inline-block; margin: 0 5px"
            @click="showDialog(checked, '1')"
            >融资服务指引</a
          >
          <a href="javascript:;" @click="showDialog(checked, '2')"
            >平台收费服务协议</a
          >
        </div>
      </section>
      <div class="card-footer" v-if="$route.query.isshowbtn == 1">
        <el-button v-if="type == 1" @click="goBack" class="card-btn"
          >取消</el-button
        >
        <el-button
          v-if="type == 2"
          size="small"
          @click="reject"
          class="card-btn"
          type="primary"
          >拒绝</el-button
        >
        <el-button
          v-if="type == 1"
          size="small"
          @click="financingAction"
          class="card-btn"
          type="primary"
          >发起融资</el-button
        >
        <el-button
          v-if="type == 2"
          size="small"
          @click="agreeAction"
          class="card-btn"
          type="primary"
          >同意</el-button
        >
      </div>
      <div class="card-footer" v-if="$route.query.isshowbtn == 2">
        <el-button
          class="card-btn"
          v-if="$route.query.approveStatus == 102 && !$route.query.detailFlag"
          type="primary"
          @click="reSubmit"
          >融资提交</el-button
        >
        <el-button class="card-btn" type="primary" @click="goBack"
          >返回</el-button
        >
      </div>
    </div>
    <!-- Form -->
    <el-dialog
      title="提示信息"
      :visible.sync="dialogFormVisible"
      @closed="closeRefuld"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="拒绝原因"
          :label-width="formLabelWidth"
          prop="reason"
        >
          <el-select v-model="form.reason" placeholder="请选择拒绝理由">
            <el-option
              v-for="item in rejectList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注信息" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="form.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRefuld">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
    <!-- 审核同意二次确认 -->
    <el-dialog title="提示信息" :visible.sync="agreeVisible" @close="close">
      <el-form :model="agreeForm">
        <el-form-item label="备注信息" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="agreeForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="agreeVisible = false">取消</el-button>
        <el-button type="primary" @click="commit">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title :visible.sync="contractDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <section
        class="info-card-A"
        style="padding-top: 32px"
        v-if="contractContent"
      >
        <div class="maincontin" v-html="contractContent"></div>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitApproval">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      id="dialog2"
      class="agreement-dialog2"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      center
    >
      <div class="agreement-container">
        <service-guide v-if="fileType == '1'"></service-guide>
        <charge-agreement
          v-if="fileType == '2'"
          :chargeInfo="chargeInfo"
        ></charge-agreement>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="!showType && !readDisabled"
          @click="hasRead()"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-show="!showType && readDisabled"
          :disabled="true"
          >阅读并同意 {{ readCount }}秒</el-button
        >
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import pdf from 'vue-pdf';
import api from '@/api/api';
import { baseURL } from '@/api/sys';
import upload from '@/api/upload.js';
import serviceGuide from '@/components/agreementList/service-guide';
import chargeAgreement from '@/components/agreementList/charge-agreement';
import steps from '../../base/steps.vue';
import picfile from '@/components/picfile';
import ukey from '@/util/ukey.js';
import pdfImg from '@/assets/file.png';
import zip from '@/assets/zip.png';
import { convertCurrency } from '@/util/common';

function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  } else {
    // 谷歌浏览器 创建a标签 添加download属性下载
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
export default {
  components: {
    pdf,
    serviceGuide,
    chargeAgreement,
    steps,
    picfile,
  },

  data() {
    return {
      pdfImg,
      zip,
      imgType: [
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.gif',
        '.PNG',
        '.JPG',
        '.JPEG',
        '.BMP',
        '.GIF',
      ],
      Authorization: { Authorization: sessionStorage.token },
      agreementDialog: false,
      fileType: '1',
      showType: '',
      readCount: '',
      readDisabled: true,
      checked: false,
      ruleform: {
        openBank: '',
        openBankNo: '',
      },
      ruleform2: {
        contractList: [{}],
      },
      financInfo: {},
      financInfoFileList: [],
      financInfoFinance: {},
      payableBillFinance: {},
      listData: [],
      tocapital: '',
      listLoading: false,
      financInfo: {}, //基本信息
      baseURL: baseURL,
      baseURL: baseURL,
      api: api,
      serviceInterest: 0,
      informationServiceInterest: 0,
      type: '', //1 代表发起融资详情页 2 代表复审详情页
      payableBillId: '',
      payableBillFinanceId: '',

      dialogFormVisible: false, // 拒绝原因
      formLabelWidth: '120px',
      form: {
        reason: '',
        remark: '',
      },
      rules: {
        reason: [
          { required: true, message: '请选择拒绝原因', trigger: 'change' },
        ],
      },
      rulesForm: {
        payableAmount: [
          { required: true, message: '请填写融资金额', trigger: 'blur' },
        ],
        openBank: [
          { required: true, message: '请选择企业开户行', trigger: 'change' },
        ],
        openBankNo: [
          { required: true, message: '请选择企业账号', trigger: 'change' },
        ],
      },
      agreeForm: {
        remark: '',
      },
      agreeVisible: false,
      rejectList: [], //拒绝原因
      isShowBtn: '',
      corpAccountPublicList: [], //开户行
      corpAccountPublicNoList: [], // 开户账号
      centerDialogVisible: false,
      src: '',
      modelList: [],
      fileTypes: {
        payableBillFinanceId: '',
      }, //上传参数
      fileImgList: [],
      row: {},
      index: '',
      contractContent: '',
      contractDialogVisible: false,
      param: {},
      imgUrl: '',
      refusereason: {},
      chargeInfo: {
        no: '', // 合同编号
        financeCorpName: '', // 融资申请人/账户名
        legalName: '', // 法定代表人
        creditCode: '', // 统一社会信用代码
        telephone: '', // 联系方式
        registerAddr: '', // 联系地址
        openBankReceiver: '', // 开户行
        publicAccountReceiver: '', // 账号
        serviceInterest: '', // 技术服务费率
        informationServiceInterest: '', // 信息咨询费率
        platformServiceFee: '', //平台服务费
        payableAmount: '', //融资金额
        financeYearInterest: '', //年化利率
        preFinanceInterest: '', //融资利息
        preVouncherTime: '', //融资日期天数
      },
      // 融资申请－融资审核－平台审核－资方审核－融资签约－融资放款
      stepList: [
        {
          name: '融资申请',
          realName: '',
          createTime: '',
        },
        {
          name: '融资审核',
          realName: '',
          createTime: '',
        },
        {
          name: '平台审核',
          realName: '',
          createTime: '',
        },
        {
          name: '资方审核',
          realName: '',
          createTime: '',
        },
        {
          name: '融资签约',
          realName: '',
          createTime: '',
        },
        {
          name: '融资放款',
          realName: '',
          createTime: '',
        },
      ],
      current: '',
      financing: '',
      loading: '',
      dialogVisible: false,
      forbidden: false,
      costInfo: [],
      costInfo1: {},
      costInfo2: {},
      isShow: false,
      isPermitted: false, //未入驻企业是否允许融资
      permitMsg: '',
      settlementChannel: this.$route.query.settlementChannel, // 0上海银行 1中金支付
      grantAgreementFileUrl: [],
      fileList: [],
      loading: {},
      excelfileExtend: '.jpg,.png,.jpeg,.bmg,.gif',
      payVoucherPath: [],
      imgType: [
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.gif',
        '.PNG',
        '.JPG',
        '.JPEG',
        '.BMP',
        '.GIF',
        '.pdf',
      ],
      imgList: [],
      corpId: '',
      serviceAccountInfo: {
        account: null,
        accountName: null,
        accountBank: null
      }
    };
  },
  created() {
    console.log(this.$route.query, 'v-if="$route.query.approveStatus "');
    this.corpId = JSON.parse(sessionStorage.getItem('corpList')).corpId;
    //this.$route.query.type 1 代表发起融资详情页 2 代表复审详情页
    this.current = 1;
    this.type = this.$route.query.type;
    this.payableBillId = this.$route.query.id;
    this.isShowBtn = this.$route.query.isshowbtn;
    if (this.$route.query.financing) {
      this.financing = this.$route.query.financing;
    }
    if (this.type == 2) {
      this.payableBillFinanceId = this.$route.query.payableBillFinanceId;
    }

    //融资
    if (this.$route.query.financePayStatus === 3) {
      //融资申请－融资审核－平台审核－资方审核－融资签约－融资放款
      this.current = 6;
    } else if (this.$route.query.financeSignStatus === 50) {
      //融资申请－融资审核－平台审核－资方审核－融资签约
      this.current = 5;
    } else if (
      this.$route.query.financeSignStatus >= 20 ||
      (this.$route.query.approveStatus >= 55 &&
        !(
          this.$route.query.approveStatus <= 111 &&
          this.$route.query.approveStatus >= 102
        ))
    ) {
      //融资申请－融资审核－平台审核－资方审核
      this.current = 4;
    } else if (this.$route.query.approveStatus == 20) {
      //融资申请
      this.current = 1;
    } else if (this.$route.query.approveStatus == 44) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus == 46) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus == 102) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus > 43) {
      //融资申请－融资审核－平台审核
      this.current = 3;
    } else if (this.$route.query.approveStatus > 22) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus == 21) {
      //融资申请
      this.current = 2;
    } else if (this.$route.query.approveStatus == 22) {
      //融资申请
      this.current = 1;
    }

    this.getInfo(); //获取基本信息
    this.getBindAccountList(); //获取开户行
    this.getContractContent(); //
    this.getPermitt(); //查看是否允许融资
    this.getServiceAmountAccountInfo()
  },
  mounted() {},
  methods: {
    /** 获取服务费账户信息 */
    async getServiceAmountAccountInfo() {
      const res = await api.getServiceAmountAccountInfo(this.$route.query.id)
      if (res.success && Object.keys(res.datas?.data || {}).length) {
        Object.assign(this.serviceAccountInfo, res.datas?.data)
      }
    },
    convertCurrency,
    previewPdf(item) {
      window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    },
    // getImgURL(item) {
    //   window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    // },
    // 文件回显
    getFile(list, expectList, name) {
      if (list.length > 0) {
        list.forEach((v) => {
          let filetype = v.substring(v.lastIndexOf('.')).toLowerCase();
          let temp = {};
          temp = {
            name: '付款凭证',
            url:
              this.excelfileExtend.indexOf(filetype) == -1 &&
              filetype !== '.pdf'
                ? require('@/assets/zip.png')
                : filetype == '.pdf'
                ? require('@/assets/file.png')
                : api.getImgURL(v), // 缩略图
            trueUrl: api.getImgURL(v), // 真实的文件地址
          };
          expectList.push(temp);
        });
      }
    },
    downFile(item) {
      window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    },
    beforeAvatarUpload(file) {
      // let excelfileExtend = ".jpg,.txt,.pdf,.png,.jpeg,.bmg,.zip"; //设置文件格式
      // let fileExtend = file.name
      //   .substring(file.name.lastIndexOf("."))
      //   .toLowerCase();
      // if (excelfileExtend.indexOf(fileExtend) <= -1) {
      //   this.$message.error("文件格式错误");
      //   return false;
      // }
      //判断文件大小 2M 以内
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.loading = loading;
    },
    // handleAvatarSuccess(res, file, fileList) {
    //   if (res.success) {
    //     this.$message.success('上传成功');
    //     this.fileList = fileList;
    //     let name = file.name;
    //     let filetype = name.substring(name.lastIndexOf('.')).toLowerCase();
    //     if (
    //       this.excelfileExtend.indexOf(filetype) == -1 &&
    //       filetype !== '.pdf'
    //     ) {
    //       file.url = require('@/assets/zip.png');
    //     } else if (filetype == '.pdf') {
    //       file.url = require('@/assets/file.png');
    //     }
    //   } else {
    //     this.$message.error(res.message);
    //     let uid = file.uid; // 关键作用代码，去除文件列表失败文件
    //     let index = this.$refs.uploadFile.fileList.length;
    //     let idx = this.$refs.uploadFile.fileList.findIndex(
    //       (item) => item.uid === uid
    //     ); // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
    //     this.$refs.uploadFile.fileList.splice(index, 1); // 关键作用代码，去除文件列表失败文件
    //   }
    //   this.loading.close();
    // },
    handleRemove2(file, fileList) {
      this.fileList = fileList;
    },
    // 关闭拒绝窗口
    closeRefuld() {
      this.dialogFormVisible = false;
      this.form.reason = '';
      this.form.remark = '';
    },
    moment: moment,
    startLoading: function () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      });
    },
    endLoading: function () {
      this.loading.close();
    },
    //获取合同模版
    getFinanceContact() {
      api.GetFinanceContact().then((res) => {
        if (res.success) {
          this.contractContent = res.datas.contract.contractContent;
        }
      });
    },
    getBindAccountList() {
      let params = {
        corpId: this.corpId,
        payableBillId: this.payableBillId,
      };
      api.getCorpAccountPublicListByCorpId(params).then((res) => {
        if (res.success) {
          this.corpAccountPublicList = res.datas.corpAccountPublicList;
          // if(this.corpAccountPublicList.length == 0){
          //   this.$message.error("该资金机构暂未添加企业对公账户，请联系资金方添加后再进行此操作")
          //   return false
          // }
          if (res.datas.corpAccountPublicList.length == 0) {
            this.isShow = true;
          }
        } else {
          this.$alert(res.message, '提示', {
            confirmButtonText: '确定',
            closeOnClickModal: false,
            type: 'warning',
            callback: (action) => {
              this.$router.go(-1);
            },
          });
        }
      });
    },
    getOpenBankNo() {
      // 获取开户账号

      //   this.chargeInfo.openBankReceiver = this.ruleform.openBank;
      this.ruleform.openBankNo = '';
      this.corpAccountPublicNoList = [];
      let params = {
        corpId: this.corpId,
        payableBillId: this.payableBillId,
      };
      api.getCorpAccountPublicListByCorpId(params).then((res) => {
        if (res.success) {
          this.corpAccountPublicList.forEach((_) => {
            if (this.ruleform.openBank == _.openBank) {
              this.corpAccountPublicNoList.push(_);
            }
          });
          if (res.datas.corpAccountPublicList.length == 0) {
            this.isShow = true;
          }
        }
      });
    },
    getCorpNo() {},
    //返回
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    //获取详情
    getInfo() {
      if (this.type == 1) {
        api
          .ToPayableBillFinance({ payableBillId: this.payableBillId })
          .then((res) => {
            if (res.success) {
              this.financInfo = res.datas.payableBill;
              this.serviceInterest = res.datas.serviceInterest;
              this.informationServiceInterest =
                res.datas.informationServiceInterest;
              this.chargeInfo.serviceInterest = res.datas.serviceInterest;
              this.chargeInfo.informationServiceInterest =
                res.datas.informationServiceInterest;
              // this.refusereason = res.datas.approveList[0];
              this.financInfo.informationList = res.datas.informationList;
              this.financInfo.financInfoContract =
                res.datas.payableBillFileList;
              this.financInfo.financInfoContract.forEach((el) => {
                el.htlist = [];
                el.fplist = [];
                el.otherlist = [];
                el.htlist = el.payableBillContractList;
                el.fplist = el.payableBillInvoiceList;
                el.wuliulist = el.payableBillWuliuList;
                el.banklist = el.payableBillBankList;
                // el.otherlist.push.apply(el.otherlist, el.payableBillWuliuList);
                // el.otherlist.push.apply(el.otherlist, el.payableBillBankList);
              });
            }
          });
      } else {
        api
          .FinanceDetial({
            payableBillId: this.payableBillId,
            payableBillFinanceId: this.payableBillFinanceId,
          })
          .then((res) => {
            if (res.success) {
              // debugger;
              this.financInfo = res.datas.payableBill;
              this.refusereason = res.datas.approveList[0];
              this.financInfo.financInfoContract =
                res.datas.payableBillFileList;
              this.payableBillFinance = res.datas.payableBillFinance;
              this.imgList = res.datas.payableBillFee.feePath
                ? res.datas.payableBillFee.feePath.split(',')
                : [];
              this.getFile(this.imgList, this.fileList, '转账凭证');
              this.imgList.map((_) => {
                console.log(_.substring(_.lastIndexOf('.')).toLowerCase());
              });
              for (
                let index = 0;
                index < res.datas.informationList.length;
                index++
              ) {
                const element = res.datas.informationList[index];
                for (
                  let i = 0;
                  i <
                  res.datas.payableBillFinance.payableBillFinanceFileList
                    .length;
                  i++
                ) {
                  const ele =
                    res.datas.payableBillFinance.payableBillFinanceFileList[i];
                  if (element.annexCode == ele.fileCode) {
                    var fileTypeList = ele.fileName.split('.');
                    this.modelList.push({
                      informationName: element.informationName,
                      claimRemark: element.claimRemark,
                      fileName: ele.fileName,
                      fileUrl: ele.fileUrl,
                      fileType: _.last(fileTypeList),
                    });
                  }
                }
              }

              this.financInfo.financInfoContract.forEach((el) => {
                el.htlist = [];
                el.fplist = [];
                el.otherlist = [];
                el.htlist = el.payableBillContractList;
                el.fplist = el.payableBillInvoiceList;
                el.wuliulist = el.payableBillWuliuList;
                el.banklist = el.payableBillBankList;
              });
            }
          });
      }
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    jsmount() {
      var arr = [];
      this.ruleform2.contractList.map((el) => {
        arr.push(el.contractAmount);
      });
      this.mounttoall = (0).adds(...arr);
    },
    textChange(val) {
      if (val < 0) {
        this.$message.error('请输入正确金额');
        return;
      }
      let param = {
        payableBillId: this.payableBillId,
        amount: val,
      };
      api.PayableBillFinanceCompute(param).then((res) => {
        let now = new Date(new Date(new Date().toLocaleDateString()).getTime());
        if (res.success) {
          this.financInfoFinance = res.datas.payableBillFinance;
          this.chargeInfo.platformServiceFee =
            res.datas.payableBillFinance.platformServiceFee;
          this.chargeInfo.payableAmount = this.ruleform.payableAmount;
          this.chargeInfo.financeYearInterest =
            res.datas.payableBillFinance.financeYearInterest;
          this.chargeInfo.preFinanceInterest =
            res.datas.payableBillFinance.preFinanceInterest;
          // this.chargeInfo.preVouncherTime = this.financInfo.promisePayTime - now + 172800

          var startTime = this.financInfo.promisePayTime - 172800000;
          this.chargeInfo.preVouncherTime = moment(startTime).diff(
            moment(now),
            'days'
          );
        } else {
          this.ruleform.payableAmount = '';
          this.$message.error(res.message);
        }
      });
    },
    getTypes(types) {
      if (types == 1) {
        return '平台服务费';
      } else if (types == 2) {
        return '信息费';
      } else if (types == 3) {
        return '支付手续费';
      }
    },
    serviceFeeBreakDown(amount, serviceFees, informationFees) {
      this.startLoading();
      // type 1保理模式 2票据模式
      api
        .serviceFeeBreakDown({ amount, serviceFees, informationFees })
        .then((res) => {
          if (res.success) {
            // types 1科技费,2信息费,3支付手续费
            res.datas.list.forEach((v) => {
              this.costInfo.push({
                openCorpName: v.openCorpName,
                receivedCorpName: v.receivedCorpName,
                payerFundAccount: v.payerFundAccount,
                beneficiaryFundAccount: v.beneficiaryFundAccount,
                amount: v.amount,
                types: v.types,
                costAccountId: v.costAccountId,
              });

              if (v.types == 1) {
                this.costInfo1 = v;
              } else if (v.types == 2) {
                this.costInfo2 = v;
              }
              this.endLoading();
            });
          } else {
            this.endLoading();
            this.$message.error(res.message);
          }
        });
    },
    //发起融资
    financingAction() {
      // this.$router.push({
      //   path: "/financingProjectManage/easypaySign/SignDetial",
      //   query: {
      //     payableBillFinanceId: this.payableBillFinance.payableBillFinanceId,
      //     payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
      //     financeCorpId: this.payableBillFinance.financeCorpId,
      //     isshowbtn: 2
      //   }
      // });
      if (!this.isPermitted) {
        this.$message.error(this.permitMsg);
        return;
      }
      if (!this.checked && this.financing != 'financing') {
        this.$message.warning('请先阅读并同意协议');
        return false;
      }
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          if (!this.ruleform.payableAmount) {
            this.$message.error('请输入融资金额');
            return;
          }
          //
          if (this.ruleform.payableAmount < 0) {
            this.$message.error('请输入正确金额');
            return;
          }
          // if (!this.ruleform.openBank) {
          //   this.$message.error('请选择企业开户行');
          //   return;
          // }
          // if (!this.ruleform.openBankNo) {
          //   this.$message.error('请选择企业账号');
          //   return;
          // }
          if (this.financInfo.informationList.length > 0) {
            for (
              let index = 0;
              index < this.financInfo.informationList.length;
              index++
            ) {
              if (this.financInfo.informationList[index].fileUrl == undefined) {
                var message = '请补充融资补件资料';
                let i = index + 1;
                var newMessage = message + i;
                this.$message.error(newMessage);
                return;
              }
            }
          }
          let payableBillFinanceFileList = [];
          for (
            let index = 0;
            index < this.financInfo.informationList.length;
            index++
          ) {
            let item = {};
            item.ext4 = this.financInfo.informationList[index].informationName;
            item.fileName = this.financInfo.informationList[index].fileName;
            item.fileUrl = this.financInfo.informationList[index].fileUrl;
            item.fileCode = this.financInfo.informationList[index].annexCode;
            payableBillFinanceFileList.push(item);
          }
          let payVoucherPath = [];
          // if (this.fileList.length > 0) {
          //   this.fileList.map((_) => {
          //     payVoucherPath.push(_.response.datas.uploadDate.message);
          //   });
          // } else {
          //   this.$message.error('请上传转账凭证！');
          //   return;
          // }
          var param = {
            amount: this.ruleform.payableAmount,
            financeUuid: this.financInfoFinance.financeUuid,
            publicAccountReceiver: this.ruleform.openBankNo,
            platformServiceFee: this.financInfoFinance.platformServiceFee,
            informationFee: this.financInfoFinance.informationFee,
            informationServiceInterest:
              this.financInfoFinance.informationServiceInterest,
            preIncomeAmount: this.financInfoFinance.preIncomeAmount,
            preFinanceInterest: this.financInfoFinance.preFinanceInterest,
            openBankReceiver: this.ruleform.openBank,
            payableBillFinanceFileList: payableBillFinanceFileList,
            promisePayTime: this.financInfo.promisePayTime,
            payVoucherPath: payVoucherPath.join(','),
          };
          this.param = param;
          //需要展示合同信息
          this.getFinanceContact();

          this.$confirm(
            '请仔细阅读《平台收费服务协议》相关条款，并确认签署协议及支付平台相关费用',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              // type: "warning"
            }
          )
            .then(() => {
              this.contractDialogVisible = false;
              let amount = this.ruleform.payableAmount;

              let serviceFees = this.financInfoFinance.platformServiceFee;
              let informationFees = this.financInfoFinance.informationFee;
              //  this.serviceFeeBreakDown(amount, serviceFees, informationFees);

              // this.dialogVisible = true;
              this.submitApproval();
            })
            .catch(() => {});
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 100);
          return false;
        }
      });
    },
    //同意 复核
    agreeAction() {
      this.agreeVisible = true;
    },
    // 关闭弹框清空备注信息
    close() {
      this.agreeForm.remark = '';
    },
    async commit() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      var isUkey = JSON.parse(sessionStorage.corpList).signType;

      var yfdMessageDto = {
        itemId: this.$route.query.payableBillFinanceId,
        itemType: 2,
        operatorAction: 1,
        operatorType: 21,
        phone: user.phone,
        payableBillFinanceId: this.$route.query.payableBillFinanceId,
      };

      if (isUkey == 2) {
        let ukeyRes = await ukey.LoadObj();
        if (!ukeyRes.result) {
          return;
        }
        let certContent = await ukey.GetCertInfoOnClick('CertContent');
        yfdMessageDto.x509Cert = certContent.result;
      }

      this.startLoading();

      if (isUkey == 2) {
        //不发短信
        yfdMessageDto.verifyCode = '';

        //signType=3 融资凭证
        var p1 = api
          .CheckSendMessage({ ...yfdMessageDto, signType: 3 })
          .then((res) => {
            return res;
          });

        var p2 = api
          .getSignHash({ ...yfdMessageDto, signType: 6 })
          .then((res) => {
            return res;
          });

        Promise.all([p1, p2])
          .then((values) => {
            if (!values[0].success) {
              this.$message.error(values[0].message);
              return false;
            }
            var pdfSealHash;
            var pdfSealHashXY;

            if (user.pdfSign == 1 && isUkey == 2) {
              pdfSealHash = ukey.SignHashOnClick(
                values[0].datas.data.pdfSealHash
              );
            }
            if (user.pdfSign == 1 && isUkey == 2) {
              pdfSealHashXY = ukey.SignHashOnClick(
                values[1].datas.data.pdfSealHash
              );
            }

            return Promise.all([pdfSealHash, pdfSealHashXY]).then((hash) => {
              var pdfobj = {};
              if (user.pdfSign == 1 && isUkey == 2) {
                return {
                  pdfId: values[0].datas.data.pdfId,
                  pdfSealHash: hash[0].result,
                  pdfIdXY: values[1].datas.data.pdfId,
                  pdfSealHashXY: hash[1].result,
                };
              } else {
                return pdfobj;
              }
            });
          })
          .then((pdfobj) => {
            if (!pdfobj) {
              return;
            }
            let param = {
              payableBillFinanceId:
                this.payableBillFinance.payableBillFinanceId,
              // payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
              payableBillId: this.payableBillFinance.payableBillId,
              approveNode: this.payableBillFinance.approveStatus,
              approveResult: 1,
              remark: this.agreeForm.remark,
            };
            var params = Object.assign(yfdMessageDto, param, pdfobj);
            this.startLoading();
            api.FinanceApprove(params).then((res) => {
              if (res.success) {
                this.endLoading();
                this.$alert('融资审批完成,请等待平台审查', '', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    history.go(-1);
                  },
                });
              } else {
                this.endLoading();
                this.$message.error(res.message);
              }
            });
          });
      } else {
        //发短信
        api.sendPhoneMessage(yfdMessageDto).then((res) => {
          if (res.success) {
            this.endLoading();
            let meessage =
              '提示信息：CFCA向您尾号为' +
              user.phone.slice(user.phone.length - 4) +
              '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
            this.$prompt(meessage, '请输入短信验证码', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
            }).then(({ value }) => {
              yfdMessageDto.verifyCode = value;

              //signType=3 融资凭证
              var p1 = api
                .CheckSendMessage({ ...yfdMessageDto, signType: 3 })
                .then((res) => {
                  return res;
                });

              var p2 = api
                .getSignHash({ ...yfdMessageDto, signType: 6 })
                .then((res) => {
                  return res;
                });

              Promise.all([p1, p2])
                .then((values) => {
                  if (!values[0].success) {
                    this.$message.error(values[0].message);
                    return false;
                  }
                  var pdfSealHash;
                  var pdfSealHashXY;

                  if (user.pdfSign == 1 && isUkey == 2) {
                    pdfSealHash = ukey.SignHashOnClick(
                      values[0].datas.data.pdfSealHash
                    );
                  }
                  if (user.pdfSign == 1 && isUkey == 2) {
                    pdfSealHashXY = ukey.SignHashOnClick(
                      values[1].datas.data.pdfSealHash
                    );
                  }

                  return Promise.all([pdfSealHash, pdfSealHashXY]).then(
                    (hash) => {
                      var pdfobj = {};
                      if (user.pdfSign == 1 && isUkey == 2) {
                        return {
                          pdfId: values[0].datas.data.pdfId,
                          pdfSealHash: hash[0].result,
                          pdfIdXY: values[1].datas.data.pdfId,
                          pdfSealHashXY: hash[1].result,
                        };
                      } else {
                        return pdfobj;
                      }
                    }
                  );
                })
                .then((pdfobj) => {
                  if (!pdfobj) {
                    return;
                  }
                  let param = {
                    payableBillFinanceId:
                      this.payableBillFinance.payableBillFinanceId,
                    // payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
                    payableBillId: this.payableBillFinance.payableBillId,
                    approveNode: this.payableBillFinance.approveStatus,
                    approveResult: 1,
                    remark: this.agreeForm.remark,
                  };
                  var params = Object.assign(yfdMessageDto, param, pdfobj);
                  this.startLoading();
                  api.FinanceApprove(params).then((res) => {
                    if (res.success) {
                      this.endLoading();
                      this.$alert('融资审批完成,请等待平台审查', '', {
                        confirmButtonText: '确定',
                        callback: (action) => {
                          history.go(-1);
                        },
                      });
                    } else {
                      this.endLoading();
                      this.$message.error(res.message);
                    }
                  });
                });
            });
          } else {
            this.endLoading();
            this.$message.error(res.message);
          }
        });
      }
    },
    //拒绝 复核
    reject() {
      this.dialogFormVisible = true;
      //查询拒绝原因
      api.getDicListByType({ type: 7 }).then((res) => {
        if (res.success) {
          this.rejectList = res.datas.list;
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // var user = JSON.parse(sessionStorage.getItem("user"));
          // var yfdMessageDto = {
          //   itemId: this.$route.query.payableBillFinanceId,
          //   itemType: 2,
          //   operatorAction: 2,
          //   operatorType: 21,
          //   phone: user.phone
          // };

          // api.sendPhoneMessage(yfdMessageDto).then(res => {
          //   if (res.success) {
          //     let meessage =
          //       "提示信息：CFCA向您尾号为" +
          //       user.phone +
          //       "的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码";
          //     this.$prompt(meessage, "请输入短信验证码", {
          //       confirmButtonText: "确定",
          //       cancelButtonText: "取消",closeOnClickModal:false
          //     }).then(({ value }) => {
          let param = {
            payableBillFinanceId: this.payableBillFinance.payableBillFinanceId,
            // payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
            payableBillId: this.payableBillFinance.payableBillId,
            approveNode: this.payableBillFinance.approveStatus,
            approveResult: 3,
            reson: this.form.reason,
            remark: this.form.remark,
          };

          //       yfdMessageDto.verifyCode = value;
          //       var params = Object.assign({ yfdMessageDto }, param);

          //       this.startLoading();
          api.FinanceApprove(param).then((res) => {
            if (res.success) {
              // this.endLoading();
              this.$alert('审批拒绝，融资申请失败', '', {
                confirmButtonText: '确定',
                callback: (action) => {
                  history.go(-1);
                },
              });
            } else {
              // this.endLoading();
              this.$message.error(res.message);
            }
          });
          //       });
          //     } else {
          //       this.$message.error(res.message);
          //     }
          //   });
        }
      });
    },
    reSubmit() {
      // let payVoucherPath = [];
      // if (this.fileList.length > 0 && !this.fileList.trueUrl) {
      //   this.fileList.map((_) => {
      //     if (!_.trueUrl) {
      //       payVoucherPath.push(_.response.datas.uploadDate.message);
      //     } else {
      //       payVoucherPath.push(_.trueUrl.split('=')[1]);
      //     }
      //   });
      // } else {
      //   this.$message.error('请上传转账凭证！');
      //   return false;
      // }
      let params = {
        payableBillFinanceId: this.$route.query.payableBillFinanceId,
        // payVoucherPath: payVoucherPath.join(','),
      };
      api
        .CommitPayableBillFinance(params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$router.go(-1);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    //发起融资
    submitApproval() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      var yfdMessageDto = {
        itemId: this.$route.query.id,
        itemType: 2,
        operatorAction: 1,
        operatorType: 20,
        phone: user.phone,
      };
      api.sendPhoneMessage(yfdMessageDto).then((res) => {
        if (res.success) {
          let meessage =
            '提示信息：CFCA向您尾号为' +
            user.phone.slice(user.phone.length - 4) +
            '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
          this.$prompt(meessage, '请输入短信验证码', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(({ value }) => {
            yfdMessageDto.verifyCode = value;
            let params = Object.assign({ yfdMessageDto }, this.param);
            params.payableBillPayList = this.costInfo;
            params.financeServiceAgreementNo = this.chargeInfo.no;
            this.startLoading();
            this.forbidden = true;
            api.AddPayableBillFinance(params).then((res) => {
              if (res.success) {
                // this.$alert("融资申请成功，等待审批");
                // this.$router.go(-1); //返回上一层
                this.endLoading();
                this.forbidden = false;
                if (this.settlementChannel == '0') {
                  this.$alert(
                    '融资申请成功，请等待内部审核。',
                    '',
                    {
                      dangerouslyUseHTMLString: true,
                      confirmButtonText: '确定',
                      callback: (action) => {
                        history.go(-1);
                      },
                    }
                  );
                } else {
                  this.$alert('融资申请成功，等待审批', '', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                      history.go(-1);
                    },
                  });
                }
              } else {
                this.endLoading();
                this.forbidden = false;
                this.$message.error(res.message);
              }
            });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    downloadContractFile() {
      //合同文件下载
    },
    downloadInvoiceFile() {
      //发票文件下载
    },
    downloadOtherFile() {
      //其他文件下载
    },
    download(row) {
      api
        .filesdownsingle({
          filePath: row.fileUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.fileName);
        })
        .catch((err) => {});
    },
    downloadModel(row) {
      api
        .filesdownsingle({
          filePath: row.annexUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.annexName);
        })
        .catch((err) => {});
    },
    linkCompanyManage() {
      var item = {
        name: '企业管理',
        path: '/Business/Bank_management?pageSize=10&pageNum=1',
      };
      sessionStorage.name = item.name;
      this.$router.push({ path: item.path });
    },
    preview(row) {
      this.row = row;
      // api
      //   .DownloadContract({ contractNo: this.financInfo.contractNo })
      //   .then(res => {
      //     var binaryData = [];
      //     binaryData.push(res);
      //     this.src = window.URL.createObjectURL(
      //       new Blob(binaryData, { type: "application/zip" })
      //     );
      //     this.centerDialogVisible = true;
      //   });
      window.open(api.getImgURL(this.financInfo.evidencePath));
    },
    easyDetail() {
      this.$router.push({
        name: '优企通流转详情',
        query: {
          id: this.payableBillId,
          isshowbtn: 2,
          detail: true,
        },
      });
    },
    handleupfn(response, file, fileList, index) {
      this.$message.success('上传成功');
      this.$set(
        this.financInfo.informationList[this.index],
        'fileName',
        file.name
      );
      this.$set(
        this.financInfo.informationList[this.index],
        'fileUrl',
        response.datas.uploadDate.message
      );
      this.fileImgList.push(file);
      console.log(file, 'recheckDetial.vue.zip');
    },
    upload(scope) {
      console.log(scope, 'scopescope');
      this.index = scope.$index;
      // this.fileTypes.payableBillFinanceId = scope.row.payableBillFinanceId
    },
    //上传前配置
    beforeUp(file) {
      // this.row.fileName = file.name;
      // let excelfileExtend = ".pdf"; //设置文件格式
      // let fileExtend = file.name
      //   .substring(file.name.lastIndexOf("."))
      //   .toLowerCase();
      // if (excelfileExtend.indexOf(fileExtend) <= -1) {
      //   this.$message.error("文件格式错误");
      //   return false;
      // }
    },
    deleteImg(row) {
      api.filesDelete({ fileUrl: row.fileUrl });
      this.getInfo();
    },
    handlePreview(file) {
      if (file.response) {
        window.open(api.getImgURL(file.response.datas.uploadDate.message));
      } else {
        // 回显的数据
        window.open(file.trueUrl);
      }
      // let url = file.response.datas.uploadDate.message;
      // let type = url.substring(url.lastIndexOf('.'));
      // if (!this.imgType.includes(type)) {
      //   api
      //     .filesdownsingle({
      //       filePath: url
      //     })
      //     .then((res) => {
      //       openDownloadDialog(res, file.name);
      //     })
      //     .catch((err) => {});
      //   return;
      // }
      // window.open(api.getImgURL(file.response.datas.uploadDate.message));
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      if (type) {
        if (type == '1') {
          this.$set(this.$data, 'fileType', '1');
          this.agreementDialog = true;
          // this.readCountDown();  // 单独打开协议时，不进行倒计时，不展示同意按钮
        } else if (type == '2') {
          this.$set(this.$data, 'fileType', '2');
          this.agreementDialog = true;
          // this.readCountDown();
        }
      } else {
        if (this.checked) {
          this.checked = false;
          this.$set(this.$data, 'fileType', '1'); // 将fileType重置为1，勾选同意，从第一个协议开始打开
          this.agreementDialog = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },

    refuseReasonfn(val) {
      var name = '';
      name = this.refuselist.find((el) => {
        return el.code == val;
      });
      return name && name.name;
    },

    // 同意
    hasRead() {
      if (this.showType) {
        if (this.showType == '1') {
          this.showType1 = true;
        } else if (this.showType == '2') {
          this.showType2 = true;
        }
        this.agreementDialog = false;
        this.readDisabled = true;
        if (this.showType1 && this.showType2) {
          this.checked = true;
          this.$set(this.$data, 'fileType', '1');
        }
      } else {
        if (this.fileType == '1') {
          this.$set(this.$data, 'fileType', '2');
          this.readCountDown();
          this.readDisabled = true;
          this.agreementDialog = true;
          var Id = document.getElementById('dialog2');
          Id.getElementsByClassName('el-dialog')[0].scrollTop = 0;
        } else if (this.fileType == '2') {
          this.$set(this.$data, 'fileType', '1');
          this.checked = true;
          // this.readCountDown();
          this.agreementDialog = false;
          this.readDisabled = false;
        }
      }
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    // 获取 融资服务协议内容
    getContractContent() {
      let param = {
        itemId: 1,
        itemType: 1,
      };
      api.getContractContent(param).then((res) => {
        if (res.success) {
          let content = res.datas.content;
          content.informationServiceInterest =
            this.chargeInfo.informationServiceInterest;
          this.chargeInfo = { ...this.chargeInfo, ...content };
        }
      });
    },
    getPermitt() {
      api.checkFinance({ payableBillId: this.payableBillId }).then((res) => {
        this.isPermitted = false;

        if (res.success) {
          this.isPermitted = true;
        } else {
          this.isPermitted = false;
          this.permitMsg = res.message;
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
@import '../../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
  .card-bd-row {
    line-height: 30px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .easy-detail-btn {
    padding: 9px 15px;
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 120px;
  }
  .card-line {
    height: 0px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .row {
    height: 60px !important;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
  .pr12 {
    padding-right: 12px;
  }
}
.img-btn {
  color: #595959;
}
// .upload-demo {

// }
.upload-list {
  .el-upload-list {
    display: none;
  }
}
.txbst3 {
  font-weight: bold;
  font-size: 16px;
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 17px;
    background-color: #d19f53;
    left: -18px;
    top: 0;
  }
}
.txbst2 {
  font-size: 12px;
  color: #7c7878;
}
.subtitle {
  margin-top: 20px;
  border-bottom: 1px solid #cfcfcf;
  padding: 0 40px;
  margin-bottom: 20px;
}
.upload-box {
  padding-left: 50px;
  .el-upload--picture-card {
    position: relative;
  }
  .upload-info {
    position: absolute;
    left: 39%;
    top: 32%;
  }
}
.info-box {
  .row {
    height: 50px !important;
  }
}
.account-content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 28px;
  .base-item {
    color: #262626;
    width: 33.3%;
    line-height: 40px;
  }
}
.picture-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .picture-box-item {
    margin-right: 20px;
    height: 100px;
    width: 100px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
.deleteImg {
  display: inline-block;
  padding: 8px;
  vertical-align: -10%;
  &:hover {
    color: #599ffb;
  }
}
.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 400px;
  }
}
</style>
