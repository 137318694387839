<template>
    <div class="common-page-dataList">
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">


            <data-list
                    :filters="filterList"
                    @data-refresh="handleRefresh"
                    :total="total"
                    ref="datalist"
                     @data-showAll="filterShowAll"
   
            >
                <!-- <el-button type="success" size='small' class="bladdbtn" @click="addrz">新增账款</el-button> -->
                <el-table
                        :data="listData"
                        :header-cell-style="headerStyle"
                        highlight-current-row
                        v-loading="listLoading"
                        style="width: 100%;"
                >
                    <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
                    <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
                    <el-table-column label="账款金额(元)" min-width="170">
                        <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.accountAmount) }}</template>
                    </el-table-column>
                    <el-table-column prop="accountSignDate" label="账款起始日期" min-width="170">
                        <template
                                slot-scope="scope"
                        >
                            {{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="170">
                        <template
                                slot-scope="scope"
                        >
                            {{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="approveStatusName" label="审批状态" width="150" align="left"></el-table-column>
                    <el-table-column label="作废状态" width="150" align="left">
                             <template
                                slot-scope="scope"
                        >
                            {{scope.row.accountBackStatus==3?'已作废':'未作废'}}
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
    </div>


</template>
<script>
    import api from "@/api/api";
    import moment from 'moment'

    export default {
        components: {},
        computed: {
           filterList() {
                return [

                    {
                        label: "应收账款编号",
                        type: "input",
                        field: "receivableAccountNo",
                        size: "small"
                    },
                    {
                        label: "到期日期:",
                        type: "daterange",
                        field: ["accountCloseDateStr", "accountCloseDateEnd"],
                        size: "small"
                    },
                    {
                        label: "债务企业",
                        type: "input",
                        field: "debtCorpName",
                        size: "small"
                    },
                    {
                        label: "审批状态",
                        type: "select",
                        field: "searchReturnApproveStatus",
                        size: "small",
                        options: this.approveStatuslist,
                    },
                    // {
                    //   label: "账款金额: ",
                    //   type: "inputrange",
                    //   field: "accountAmountStr",
                    //   size: "small",
                    //   isSeparated: 1
                    // },
                    // {
                    //   type: "inputrange",
                    //   field: "accountAmountEnd",
                    //   size: "small"
                    // },
                ]
            }
        },
        data() {
            return {
                breadcrumblist: ["应收账款管理"],


                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                handleEditShow: false,
                moment: moment,
                approveStatuslist: [],
                isShowAll:true

            };
        },
        created() {
            this.activeName = this.$route.params.state;
            this.getDicListByType()

        },
        methods: {
              filterShowAll() {
                this.isShowAll = !this.isShowAll;

                },

            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                this.getLists(filters);
            },
            getDicListByType() {
                api.getDicListByType({type: 14}).then(res => {

                    if (res.success) {
                        this.approveStatuslist = res.datas.list.map(el => {
                            return {label: el.name, value: el.code}
                        })

                    }

                });
            },
            //查询
            getLists(param) {

                api.refusereceivableAccountlist(param).then(res => {
                    console.log('res ===>', res);
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(data) {
                if (data.accountBackStatus == 3) {
                    this.$router.push({name: '应收账款详情', query: {id: data.receivableAccountId, isshowbtn: 2, showBack: true}})

                } else {
                    this.$router.push({
                        name: '应收账款退回',
                        query: {id: data.receivableAccountId, receivableAccountNo: data.receivableAccountNo,accountBackStatus:data.accountBackStatus}
                    })

                }
            },

            addrz() {
                this.$router.push({name: '应收账款'})
            }
        }
    };
</script>

<style lang="scss">

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }

    .bladdbtn {
        width: 74px;
        border-radius: 4px;
        margin-bottom: 20px;
             padding-left: 0;
        padding-right: 0;
    }

</style>
