<template>
  <div class="common-page-dataList">
    <!-- 可用额度页面。 -->
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          style="width: 100%;margin-top:20px"
        >
          <el-table-column prop="grantCreditNo" label="授信编号" min-width="80">
          </el-table-column>
          <el-table-column prop="grantCorpName" label="企业名称" min-width="80">
          </el-table-column>
          <el-table-column
            prop="availableAmount"
            label="授信额度（元）"
            min-width="80"
          >
          <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.availableAmount)}}</template>
          </el-table-column>
          <el-table-column
            prop="fundYearInterest"
            label="资金年利率（%）"
            min-width="80"
          >
          <template slot-scope="scope">{{ scope.row.fundYearInterest ? scope.row.fundYearInterest : '--'}}</template>
          </el-table-column>
          <el-table-column prop="usedAmount" label="用信额度" min-width="80">
          <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.usedAmount)}}</template>
          </el-table-column>
          <el-table-column prop="remainAmount" label="可用额度" min-width="80">
          <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.remainAmount)}}</template>
          </el-table-column>
          <el-table-column
            prop="creditDays"
            label="授信期限"
            min-width="80"
          >
          <template slot-scope="scope">
              {{ moment(scope.row.effectiveTime).format("YYYY-MM-DD") }} ~
              {{ moment(scope.row.closeDate).format("YYYY-MM-DD") }}</template
            >
          </el-table-column>
          <el-table-column prop="" label="审核状态" min-width="80">
            <template slot-scope="scope">
              {{ getCheckStatus(scope.row.grantCheck) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="申请时间"
            :formatter="
              (el) => moment(el.createTime).format('YYYY-MM-DD HH:MM:SS')
            "
            min-width="80"
          >
          </el-table-column>
          <el-table-column label="操作" min-width="80">
            <template slot-scope="scope">
              <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>

<script>
import api from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      optionsList: [],
      total: 0,
      activeName: "1",
      listData: [],
      listLoading: false,
    };
  },
  computed: {
    message() {
      return this.optionsList.map((el, index) => {
        return { label: el.corpName, value: el.corpId };
      });
    },

    filterList() {
      return [
        {
          label: "企业名称",
          type: "input",
          field: "grandCorpName",
          size: "small",
        },
        {
          label: "授信审核状态",
          type: "select",
          field: "grantCheck",
          size: "small",
          options: [
            { label: "授信待提交", value: 10 },
            { label: "资金方授信待复核", value: 20 },
            { label: "资金方复核通过", value: 25 },
            { label: "资金方复核回退", value: 26 },
            { label: "平台待初审", value: 40 },
            { label: "平台初审通过", value: 41 },
            { label: "平台初审回退", value: 42 },
            { label: "平台授信回退", value: 46 },
            { label: "授信通过", value: 99 },
            { label: "授信过期", value: 999 },
          ],
        },
        {
          label: "授信期限日",
          type: "daterange",
          field: ["startTime", "endTime"],
          size: "small",
        },
        {
          label: "申请日期",
          type: "daterange",
          field: ["applyDateStr", "applyDateEnd"],
          size: "small",
        },
      ];
    },
  },

  props: {
    // optionsList:{},
  },
  created() {},

  methods: {
    handleEdit(row) {
      this.$router.push({
        path: "/fund/credit/add/detail",
        query: {
          grantCheck: row.grantCheck,
          grantCreditId: row.grantCreditId,
          row,
        },
      });
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    getCheckStatus(v) {
      var state = "";
      switch (v) {
        case 10:
          state = "授信待提交";
          break;
        case 20:
          state = "资金方授信待复核";
          break;
        case 25:
          state = "资金方复核通过";
          break;
        case 26:
          state = "资金方复核回退";
          break;
          case 40:
          state = "平台待初审";
          break;
          case 41:
          state = "平台初审通过";
          break;
          case 42:
          state = "平台初审回退";
          break;
          case 43:
          state = "复审通过";
          break;
          case 44:
          state = "复审拒绝";
          break;
          case 46:
          state = "平台授信回退";
          break;
          case 99:
          state = "授信通过";
          break;
          case 999:
          state = "授信过期";
          break;
      }
      return state;
    },
    getFundApplyListByPage(filters) {
      let params = {
        ...JSON.parse(filters),
        approveStatus: 2,
      };
      api.getFundApplyListByPage(params).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    getLists(filters) {
      this.getFundApplyListByPage(JSON.stringify(filters));
    },

    handShow(row) {
      let msg = JSON.stringify(row);
      sessionStorage.setItem("authorizationDetailMsg", msg);
      this.$router.push({
        name: "资方授信管理详情",
        query: {},
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
