<!-- 票据详情 -->
<template>
    <div class="abs-sign-details">
        
            <div class="abs-title">绑定账款: </div>
              <div class="httotal">
          资产包共有  <span>{{datalist.accountNums}}</span>笔账款,
            
            账款总金额
            <span>{{$changeMoneyThousand(datalist.accountAllAmount)}}</span> 元。
         </div>

  
        <el-table
          :data="datalist.absAccountList"
            align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absAccountNo" label="账款编号" min-width="175"></el-table-column>



                    <el-table-column prop="claimsCorpName" label="债权企业" min-width="210">


          </el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>
          <el-table-column  label="签署日期"  min-width="160" >
                  <template slot-scope="scope">
                        {{scope.row.absAccountSignDate|formatDate}}


            </template>
          </el-table-column>
              <el-table-column  label="计划付款日"  min-width="160" >
                  <template slot-scope="scope">
                        {{scope.row.absAccountSignDate|formatDate}}


            </template>
          </el-table-column>

  
          <el-table-column label="保理商转让通知" width="220" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text"  v-if="scope.row.factoringTransferNotify != 2" >尚未通知</el-button>
              <el-button size="medium" type="text" @click="handleseetext(scope.row,6,1,1)" v-if="scope.row.factoringTransferNotify==2 && scope.row.uploadReceiptForFactoring != 2">上传回执</el-button>
              <!-- <el-button size="medium" type="text" @click="handleseetext(scope.row,6,1,1)" v-if="scope.row.factoringTransferNotify==2 && scope.row.uploadReceiptForFactoring == 3">上传回执</el-button> -->
              <el-button size="medium" type="text" @click="handleseetextInform(scope.row,6,1,1)" v-if="scope.row.factoringTransferNotify==2 && scope.row.uploadReceiptForFactoring==2">查看通知</el-button>
              <el-button size="medium" type="text" @click="handleseetextShow(scope.row,7)" v-if="scope.row.uploadReceiptForFactoring==2">查看回执</el-button>
            </template>
          </el-table-column>
          <el-table-column label="企业转让通知" width="220" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text"  v-if="scope.row.corpTransferNotify !=2" >尚未通知</el-button>
              <el-button size="medium" type="text" @click="handleseetext(scope.row,4,1)" v-if="scope.row.corpTransferNotify==2 && scope.row.uploadReceiptForCorp != 2">上传回执</el-button>
              <!-- <el-button size="medium" type="text" @click="handleseetext(scope.row,4,1)" v-if="scope.row.corpTransferNotify==2 && scope.row.uploadReceiptForCorp == 3">上传回执</el-button> -->
              <el-button size="medium" type="text" @click="handleseetextInform(scope.row,4,1)" v-if="scope.row.corpTransferNotify==2 && scope.row.uploadReceiptForCorp==2">查看通知</el-button>
              <el-button size="medium" type="text" @click="handleseetextShow(scope.row,5)" v-if="scope.row.uploadReceiptForCorp==2">查看回执</el-button>
            </template>
          </el-table-column>
            <el-table-column label="付款确认书" width="160" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleopen(scope.row)" v-if='scope.row.coreUploadPay==1'>上传付款确认书</el-button>

              <el-button size="medium" type="text" @click="handleseetext(scope.row,8)" v-if='scope.row.coreUploadPay==2'>查看确认书</el-button>
            </template>
          </el-table-column>
        </el-table>

  <el-dialog title='上传付款确认书' v-if='showAddDialog' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation">
              
        <el-upload
         class="upload-demo"
            :action="baseURL+'/web/file/uploadFile'"
            :headers='Authorization'
            list-type="picture"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
          

             drag   

        >
          <i class="el-icon-plus"></i>
         <div class="el-upload__text">上传付款确认书</div>
</el-upload>


          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取 消</el-button>

              <el-button @click="uploadfn">确 定</el-button>
          </div>
        </el-dialog>
       
        
       
    </div>
</template>
<script>
    import moment from 'moment'
    import api from "@/api/api";

import { baseURL } from '@/api/sys';



    export default {
        props: {
            datalist: {
                type: Object,
                default() {
                    return {}
                }
            },
             moneytotal: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        watch:{


        },
        data() {
            return {
                showAddDialog:false,
                imageUrl: '',
                Authorization:{Authorization:sessionStorage.token},
                baseURL,
                 uploadpic:{
                            absAccountId:'',
                            absPlanId:this.$route.query.id,
                            absAccountFileVoList:[]                       
                 }


              
            }
        },
        mounted() {
             
     
        },
        methods: {
            moment: moment,
            handleEditinfo(row){
                console.log(row)
                this.zclist=row.absPlanAccounts
                this.showAddDialog=true
            },
            getapproveStatus(data){
  
                var  str=''
                    if(data.absPledgeSignStatus==50&&data.absFactoringSignStatus==50){
                            str='双方已签约'
                    }else if(data.absPledgeSignStatus==41&&(data.absFactoringSignStatus==50||data.absFactoringSignStatus==41)){
                            str='资方已签约'
                    }else if(data.absFactoringSignStatus==41&&(data.absPledgeSignStatus==50||data.absPledgeSignStatus==41)){
                            str='资方已签约'
                    }else if(data.absPledgeSignStatus==31&&(data.absFactoringSignStatus==50||data.absFactoringSignStatus==31)){
                            str='企业已签约'

                    }else if(data.absFactoringSignStatus==31&&(data.absPledgeSignStatus==50||data.absPledgeSignStatus==31)){
                            str='企业已签约'

                    }else if (
                        data.absFactoringSignStatus == 40 ||
                        data.absPledgeSignStatus == 40
                      ) {
                        str = "资方签约中";
                      } else if (
                        data.absFactoringSignStatus == 30 ||
                        data.absPledgeSignStatus == 30
                      ) {
                        str = "企业签约中";
                      } else {
                        str = "双方待签约";
                      }
                    return str
            },
            handleopen(row){
                console.log(row,123)
                this.showAddDialog=true
                this.uploadpic.absAccountId=row.absAccountId;
                this.uploadpic.absAccountFileVoList=[]
            },
            handleAvatarSuccess(response, file) {
                  if(response.code=='100000'){
                               var  data=response.datas.uploadDate.message
                          
                             this.uploadpic.absAccountFileVoList.push({fileType:8,fileUrl:data,uid:file.uid,fileName:file.name})
                         }else{
                             

                         this.$message.error(file.name+'识别错误，请重新上传')

                         }

            },
            handleRemove(file){
          
                if(this.uploadpic.absAccountFileVoList.findIndex(el => el.uid == file.uid)!=-1){

                    this.uploadpic.absAccountFileVoList.splice(this.uploadpic.absAccountFileVoList.findIndex(el=>el.uid==file.uid),1)
            
     
                }
              

            },
            beforeAvatarUpload(file) {
                let index = file.name.lastIndexOf('.')
                let suffix = file.name.substr(index + 1)
                 if(!/jpg|gif|bmp|png|jpeg/i.test(suffix)){
                        this.$message.error('请上传png,jpg,jpeg格式的图片')
                        return false
                  }
            
            },
            uploadfn(){
         
                api.uploadAccountFile(this.uploadpic).then(res =>{ 
                                        if(res.success) {
                                            this.$message.success('上传成功')
                                            this.showAddDialog=false

                                            // //这里要及时的将首先跳出的弹框给关闭掉了。
                                            // this.showPact = false; 

                                             this.$emit('fatherMentod')
                        
                                        }else{
                                          this.$message.error(res.message)
                                        }
                       });
                    
                 console.log(this.uploadpic)

            },

            /**  点击查看通知按钮， 这里是两种情况，一种是保理转让通知，    一种是企业转让通知。 */
          handleseetextInform(row,type,noti,isbl){
                console.log(row,   type);   //
                var type=type,
                        ishz=''
                if(isbl==1){
                        ishz=row.uploadReceiptForFactoring==1&&noti==1?1:2
                }else{
                      ishz=row.uploadReceiptForCorp==1&&noti==1?1:2
                }
                
                var huizhi = '';
                if(type === 6){
                    huizhi = 6
                }else if(type === 4){
                    huizhi = 7
                }

                if(type == 6){
                    if(row.signManagementNotice == 1){
                        //保理线上 上传的内容，查看。
                        this.$router.push({
                            path: "/abs/AbswaisigngysDetail",
                            query: {
                              absAccountId:row.absAccountId,
                              signType: '4',
                              absPlanId: this.uploadpic.absPlanId,
                              // receivableAccountFinanceId:data.receivableAccountFinanceId,
                              // receivableAccountId:data.receivableAccountId,
                              isshowbtn: '2',
                              // fundCorpId: data.fundCorpId,
                              corpType:'2',
                            }
                        });
                    }else{
                        //保理附件查看的内容。
                        this.$router.push({
                            name:'abstranertext',
                            query:{
                              id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                              onLineShow:0, pageName: '保理转让通知'
                            }
                        })
                    }
                }else if(type == 4){
                    if(row.signEnterpriseNotice == 1){
                        //保理线上 上传的内容，查看。
                        this.$router.push({
                            path: "/abs/AbswaisigngysDetail",
                            query: {
                              absAccountId:row.absAccountId,
                              signType: '5',
                              absPlanId: this.uploadpic.absPlanId,
                              // receivableAccountFinanceId:data.receivableAccountFinanceId,
                              // receivableAccountId:data.receivableAccountId,
                              isshowbtn: '2',
                              // fundCorpId: data.fundCorpId,
                              corpType:'2',
                            }
                        });
                    }else{
                        //保理附件查看的内容。
                        this.$router.push({
                            name:'abstranertext',
                            query:{
                              id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                              onLineShow:0, pageName: '企业转让通知'
                            }
                        })
                    }
                }

          },


          handleseetextShow(row,type,noti,isbl){
              console.log(row,   type);   //
              var type=type,
                      ishz=''
              if(isbl==1){
                      ishz = row.uploadReceiptForFactoring==1&&noti==1?1:2
              }else{
                    ishz=row.uploadReceiptForCorp==1&&noti==1?1:2
              }

              


              var huizhi = '';
              if(type === 7){
                  huizhi = 6
              }else if(type === 5){
                  huizhi = 7
              }

              if(type == 7){
                  if(row.signManagementReceipt == 1){
                      //保理查看回执的合同页面。
                      this.$router.push({
                            path: "/abs/AbswaisigngysDetail",
                            query: {
                              absAccountId:row.absAccountId,
                              signType: '6',
                              absPlanId: this.uploadpic.absPlanId,
                              // receivableAccountFinanceId:data.receivableAccountFinanceId,
                              // receivableAccountId:data.receivableAccountId,
                              isshowbtn: '2',
                              // fundCorpId: data.fundCorpId,
                              corpType:'2',
                            }
                        });
                  }else{
                      type = 7
                      //保理回执的附件引入的页面。
                      this.$router.push({
                          name:'abstranertext',
                          query:{
                            id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                            onLineShow:0, pageName: '保理转让回执'
                          }
                      })
                  }
              }else if(type == 5){
                  if(row.signEnterpriseReceipt == 1){
                        console.log("企业回执线上签约");
                        //保理查看回执的合同页面。
                        this.$router.push({
                              path: "/abs/AbswaisigngysDetail",
                              query: {
                                absAccountId:row.absAccountId,
                                signType: '7',
                                absPlanId: this.uploadpic.absPlanId,
                                // receivableAccountFinanceId:data.receivableAccountFinanceId,
                                // receivableAccountId:data.receivableAccountId,
                                isshowbtn: '2',
                                // fundCorpId: data.fundCorpId,
                                corpType:'2',
                              }
                          });
                    }else{
                        //保理回执的附件引入的页面。
                        console.log("企业回执附件上传");
                        type = 5
                        console.log("这里是传进router的参数 type" + type);
                        this.$router.push({
                            name:'abstranertext',
                            query:{
                              id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                              onLineShow:0, pageName: '企业转让回执'
                            }
                        })
                    }
              }
          },

          handleseetext(row,type,noti,isbl){
                  ///abstranertext
                  var type=type,
                      ishz=''
                  if(isbl==1){
                        ishz = row.uploadReceiptForFactoring !=2 && noti==1 ? 1:2
                  }else{
                        // ishz = row.uploadReceiptForCorp !=2 && noti==1 ? 1:2
                        if(row.uploadReceiptForCorp != 2 && noti==1){
                          ishz = 1
                        }else{
                          ishz = 2
                        }
                  }
                  //判断是企业的查看回执 还是保理的回执。 保理回执是6    企业回执是 4
                  var huizhi = '';
                  if(type === 6){
                      huizhi = 6
                  }else if(type === 4){
                      huizhi = 7
                  }
                  if(type == 6){
                      if(row.signManagementNotice == 1){
                           var toPdfPage = 2;
                           if(row.uploadReceiptForFactoring == 3){
                              toPdfPage = 1
                           }
                          //这里这个参数是控制，查看保理通知的pdf的内容的参数。
                          var pdfType = 4;
                          this.$router.push({
                              name:'abstranertext',
                              query:{
                                id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                                onLineShow:1, pdfType:pdfType, toPdfPage:toPdfPage
                              }
                          })
                      }else{
                          var toPdfPage = 2;
                          if(row.uploadReceiptForCorp == 3){
                              toPdfPage = 1
                          }
                          this.$router.push({
                              name:'abstranertext',
                              query:{
                                id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                                onLineShow:0,toPdfPage:toPdfPage
                              }
                          })
                      }
                  } else if(type == 8) {
                      var toPdfPage = 2;
                        if(row.uploadReceiptForCorp == 3){
                            toPdfPage = 1
                        }
                        this.$router.push({
                            name:'abstranertext',
                            query:{
                                id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl,
                                onLineShow:0,toPdfPage:toPdfPage, pageName: '付款确认书'
                            }
                        })
                  } else{
                      if(row.signEnterpriseNotice == 1){
                            var toPdfPage = 2;
                            if(row.uploadReceiptForCorp == 3){
                                toPdfPage = 1
                            }
                            //这里这个参数是控制，查看企业通知的pdf的内容的参数。
                            var pdfType = 5;
                            this.$router.push({
                                name:'abstranertext',
                                query:{
                                  id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                                  onLineShow:1,pdfType:pdfType,toPdfPage:toPdfPage
                                }
                            })
                        }else{
                            var toPdfPage = 2;
                            if(row.uploadReceiptForCorp == 3){
                                toPdfPage = 1
                            }
                            this.$router.push({
                                name:'abstranertext',
                                query:{
                                  id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi,
                                  onLineShow:0,toPdfPage:toPdfPage
                                }
                            })
                        }
                  }
                  // console.log("第三步");

                  // this.$router.push({
                  //     name:'abstranertext',
                  //     query:{id:row.absAccountId,ishz:ishz,absPlanId:this.$route.query.id,type:type,isbl:isbl, huizhi:huizhi}
                  // })

          }
     
        }
    }
</script>
<style lang="scss">
  .abs-sign-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
       .initiation{
           a{
               text-decoration: none;
               color: #409EFF;
           }
       }
         .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload-dragger{
      height: 100px;
      width: 140px;
      .el-icon-plus{
          font-size: 30px;
          margin-top: 20px;
      }
    
  }
}

  
 
  
</style>