<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="200"></el-table-column>
          <el-table-column prop="preFinanceAmount" label="期望融资金额(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preFinanceAmount) }}</template>
          </el-table-column>
          <el-table-column  label="期望融资年化利率(％)" min-width="170">
          <template slot-scope="scope">{{scope.row.expectFinanceInterestMin}}％~{{scope.row.expectFinanceInterestMax}}％</template>
          </el-table-column>
          <el-table-column prop="relationReceivedAccountNums" label="应收账款数量" min-width="170"></el-table-column>
          <el-table-column prop="relationReceivedAccountAmounts" label="账款合计金额(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.relationReceivedAccountAmounts)}}</template>
          </el-table-column>
          <el-table-column prop="corpAck" label="能否确权" min-width="150">
              <template slot-scope="scope">{{ scope.row.corpAck == 1 ?'确权':'未确权' }}</template>
          </el-table-column>
          <el-table-column prop="approveStatusShow" label="项目状态" min-width="150">
          </el-table-column>
          <el-table-column prop="createTime" label="融资申请时间" min-width="180">
            <template slot-scope="scope">{{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm'): ''}}</template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button  size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
    import api from "../../api/api.js";
import moment from 'moment'

    export default {
        data() {
            return {
                isShowAll: false,
                isHedden: false,
                filterList: [
                    {
                        label: "融资项目编号:",
                        type: "input",
                        field: "receivableAccountFinanceNo",
                        size: "small"
                    },
                    {
                        label: "期望融资金额(元): ",
                        type: "inputrange",
                        field: "startPreFinanceAmount",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "endPreFinanceAmount",
                        size: "small"
                    },
                    {
                        label: "期望融资年化利率: ",
                        type: "inputrange",
                        field: "expectFinanceInterestMin",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "expectFinanceInterestMax",
                        size: "small"
                    },
                ],
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                pageNo: 1,
                pageSize: 10
            };
        },
        created() {
            this.activeName = this.$route.params.state;
        },
        methods: {
            moment: moment,
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                this.getLists(filters);
            },
            //查询
            getLists(param) {
                console.log("param ====>", param);
                param.financeCorpId = JSON.parse(sessionStorage.user).relationCorpId
                api.ToBeCombined(param).then(res => {
                    console.log("res ===>", res);
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                        console.log("list ===>", this.listData);
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(row) {
                this.$router.push({
                    path: "/factoring/FactoringProjectDetial",
                    query: {
                        receivableAccountFinanceId: row.receivableAccountFinanceId,
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
</style>
