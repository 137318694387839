<!--用户账户-->
<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList enterpriseInfo">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="tab-box">
        <el-tab-pane label="企业详情" name="first">
          <div class="module-box">
            <div class="module-title">基本信息</div>
            <div>企业logo：
              <img v-if="logoUrl" class="img-logo" :src="getImgURL(logoUrl)" preview="logo" preview-text="企业logo" title="企业logo"/>
              <span v-else>无</span>
            </div>
            <div class="clearfix baseInfo">
                <span class="fl">企业名称：{{corpDetail.corpName}}</span>
                <span class="fl">社会统一信用代码：{{corpDetail.creditCode}}</span>

                <span class="fl">证件有效期：{{corpDetail.identIssuDt !== "2200-01-01" ? moment(corpDetail.identIssuDt,"YYYY-MM-DD").format('YYYY年MM月DD日'): "长期"}}
               <span v-if="corpDetail.identIssuDt !== '2200-01-01' ">至{{corpDetail.exprtnDt !== "2200-01-01" ? moment(corpDetail.exprtnDt,"YYYY-MM-DD").format('YYYY年MM月DD日') : '年 月 日'}}</span> </span>
<!--                <span class="fl">资产总额：{{corpDetail.astTotAmt}}元人民币</span>-->
<!--                <span class="fl">营业收入：{{corpDetail.bsnIncmAmt}}元人民币</span>-->
<!--                <span class="fl">净资产：{{corpDetail.netAst}}元人民币</span>-->
                <span class="fl">是否上市公司：{{corpDetail.lstdCoFlg ? '是' : '否'}}</span>
                <span class="fl">企业出资人经济成分：{{ getEntpEcnmCmpt }}</span>
                <span class="fl">从业人数：{{ getNumberPeople(corpDetail.prctnrNum) }}</span>
                
                <span class="fl">企业类型：{{ corpDetail.corpTypeShow }}</span>
                 <span class="fl">注册资本：{{corpDetail.registerCapital ? ((Number(corpDetail.registerCapital) || Number(corpDetail.registerCapital) === 0) ? corpDetail.registerCapital + '万元人民币' : corpDetail.registerCapital) : '--'}}</span>
                <span class="fl">成立年份：{{corpDetail.foundData ? moment(corpDetail.foundData,"YYYY-MM-DD").format('YYYY') : ''}} 年</span>
                <span class="fl">法人姓名：{{corpDetail.legalName}}</span>
                <span class="fl">企业性质：{{corpDetail.corpNature}}</span>
                <span class="fl">专属邀请码：{{corpDetail.inviteCode}}</span>
                <span class="fl">电话：{{corpDetail.telephone||'--'}}</span>
                <span class="fl">传真：{{corpDetail.fax||'--'}}</span>
                <span class="fl">邮编：{{corpDetail.zipCode||'--'}}</span>
            </div>
            <div style="width: 90%">注册地址：{{corpDetail.registerAddr}}</div>
            <div style="width: 90%">经营地址：{{corpDetail.businessAddrProvinceIdShow?(corpDetail.businessAddrProvinceIdShow+corpDetail.businessAddrCityIdShow+corpDetail.businessAddrRegionIdShow+ corpDetail.businessAddr):'--' }}</div>
            <div style="width: 90%">所属行业：{{corpDetail.corpIndustryIdShow}}／{{corpDetail.corpIndustrySecondIdShow}}</div>
            <div style="width: 90%">企业介绍：{{corpDetail.corpDescribe||'--'}}</div>
            <div style="width: 90%">经营范围：{{corpDetail.businessScope}}</div>
          </div>
          <div class="module-box">
            <div class="module-title">注册信息</div>
            <div class="clearfix baseInfo">
              <span class="fl">注册手机号：{{corpDetail.phone}}</span>
              <span class="fl">注册类型：{{corpDetail.registerType == 1 ? '法人' : '经办人'}}</span>
              <span class="fl">注册时间：{{moment(corpDetail.createTime).format('YYYY-MM-DD HH:MM')}}</span>
            </div>
          </div>
          <div class="module-box">
            <div class="module-title">企业开票信息</div>
            <div class="clearfix baseInfo">
              <span class="fl">企业名称：{{corpDetail.corpName}}</span>
              <span class="fl">税号：{{corpDetail.taxNum|| '--'}}</span>
              <span class="fl">开户行：{{corpDetail.openBank|| '--'}}</span>
              <span class="fl">银行卡号：{{corpDetail.accountNo|| '--'}}</span>
            </div>
          </div>
          <div>
            <div class="module-title">企业影像材料</div>
            <div class="img-box" v-for="(item, index) in imgList" :key="index">
              <img class="pdf-class" v-if="item.fileUrl.substring(item.fileUrl.lastIndexOf('.')).toLowerCase() == '.pdf'" 
                src="../../assets/file.png" @click="previewPdf(item.fileUrl)"
              />
              <img v-else class="img-item" :src="getImgURL(item.fileUrl)" :data-img="getImgURL(item.fileUrl)" @click="$imgPreview" :preview-text="item.fileName"/>
              <div class="img-text">{{item.fileName}}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="认证信息" name="second">
          <div>认证审核时间：{{moment(createTime).format('YYYY-MM-DD HH:mm')}}</div>
          <div>
            <span class="photo-title">营业执照：</span>
            <img class="photo-box" v-for="(item, index) in imgList1" :key="index"  :src="getImgURL(item.fileUrl)" :data-img="getImgURL(item.fileUrl)" @click="$imgPreview" :preview-text="item.fileName"/>
          </div>
          <div>
            <span class="photo-title">法人身份证：</span>
            <img class="photo-box" v-for="(item, index) in imgList2" :key="index" :src="getImgURL(item.fileUrl)" :data-img="getImgURL(item.fileUrl)" @click="$imgPreview" :preview-text="item.fileName"/>
          </div>
          <div v-if="registerType == 2">
            <span class="photo-title">经办人身份证：</span>
            <img class="photo-box" v-for="(item, index) in imgList3" :key="index" :src="getImgURL(item.fileUrl)" :data-img="getImgURL(item.fileUrl)" @click="$imgPreview" :preview-text="item.fileName"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </layout-main-view>
</template>
<script>

import {  baseURL } from '../../api/sys';
import api from "@/api/api.js";
import moment from 'moment'
import entpEcnmCmpt from "../../const/entpEcnmCmpt.js"

  export default {
    data() {
        return {
            breadcrumblist: ['企业信息管理'],
            activeName: this.$route.query.active || 'first',
            corpId: JSON.parse(sessionStorage.getItem('user')).relationCorpId,
            corpDetail: { entpEcnmCmpt: '' },
            fileUrlByCorpList: [],
            logoUrl: '',
            imgList: [],
            imgList1: [],
            imgList2: [],
            imgList3: [],
            createTime: '',
            registerType: '',
            baseURL,
        };
    },
    computed: {
        getNumberPeople() {
          return function (num) {
            switch (num) {
              case 0:
                return '小于 20 人';
              case 1:
                return '20-50 人';
              case 2:
                return '50-100 人';
              case 3:
                return '100-300 人';
              case 4:
                return '300-500 人';
              case 5:
                return '500 人-1000 人';
              case 6:
                return '1000 人以上';
              default:
                return num
            }
          }
        },
        getEntpEcnmCmpt() {
            return entpEcnmCmpt.getOptionTextByValue(this.corpDetail.entpEcnmCmpt)
        },
    },
    created() {
      // 1法人 2企业
      this.registerType =JSON.parse( sessionStorage.getItem('corpList')).registerType
      this.getCorpTrialDetail(this.corpId)
      this.getTrialDetail(this.corpId)
    },
    methods: {
      previewPdf(item) {
      window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    },
      moment: moment,
      getImgURL(url) {
        return api.getImgURL(url)
      },
      handleClick() {

      },
      // 获取企业详情
      getCorpTrialDetail(corpId) {
        api.getCorpTrialDetail({corpId}).then(res => {
          if(res.success) {
            this.corpDetail = res.datas.corpDetailList[0]
            // if (this.corpDetail == null) {
            //   this.corpDetail.foundData = ''
            // }
            this.fileUrlByCorpList = res.datas.fileUrlByCorpList

            // let temp = this.fileUrlByCorpList.filter(v => v.fileCode == '1')
            // if(temp.length > 0) {
            //   this.logoUrl = temp[0].fileUrl
            // } else {
            //   this.logoUrl = false
            // }
            if(Boolean(sessionStorage.logoURL !== 'undefined')) {
             this.logoUrl = JSON.parse(sessionStorage.getItem("logoURL"));
           } else {
             this.logoUrl = false
           }

            this.imgList = this.fileUrlByCorpList.filter(v => +v.fileCode >= 7 && +v.fileCode <= 16)
            this.imgList1 = this.fileUrlByCorpList.filter(v => +v.fileCode == 6)
            this.imgList2 = this.fileUrlByCorpList.filter(v => +v.fileCode == 2 || +v.fileCode == 3)
            this.imgList3 = this.fileUrlByCorpList.filter(v => +v.fileCode == 4 || +v.fileCode == 5)
            this.$previewRefresh()
          }
        })
      },
      // 获取企业认证信息
      getTrialDetail(corpId) {
        api.getTrialDetail({corpId}).then(res => {
          if(res.success) {
            this.createTime = res.datas.createTime
          }
        })
      },
      getCorpType(v) {
        if(v == 1) {
          return '资产企业'
        } else if(v == 2) {
          return '核心企业'
        } else if(v == 3) {
          return '一般企业'
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.tab-box {
  line-height: 38px;
}
.module-box {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.module-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.85);
  padding-top: 20px;
  padding-bottom: 10px;
}
.fl {
  float: left;
}
.baseInfo {
  .fl {
    width: 33.3%;
  };
  .fl1 {
    width: 80%;
  }
}
.img-box {
  width: 200px;
  margin-top: 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  .img-item {
    width: 100%;
    border-radius: 4px;
    display: block;
    cursor: pointer;
  }
  .img-text {
    text-align: center;
  }
}
.photo-title {
  display: inline-block;
  width: 110px;
}
.photo-box {
  width: 200px;
  margin-top: 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;
}
.img-logo {
  width: 80px;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
}
.pdf-class{
  cursor: pointer;
}
</style>
<style lang="scss">
.enterpriseInfo {
  .tab-box {
    .el-tabs__nav {
      // left: 24px;
      &-wrap {
        &.is-top {
          background-color: #F1F1F1; // 不要背景色将该行注释掉
        }
      }
    }
    .el-tab-pane {
      padding: 0 24px 40px;
    }
  }
}
</style>
