import { Message, MessageBox } from "element-ui";
import nmCryptokit from "./nmCryptokit.Paperless.shjzgroup";
//下面是加载ukey证书
var CryptoKit;
const BrowserInfo = () => {
  var res = {
    name: "",
    version: "",
  };
  var reg;
  var userAgent = self.navigator.userAgent;
  if ((reg = /edge\/([\d\.]+)/i.exec(userAgent))) {
    res.name = "Edge";
    res.version = reg[1];
  } else if ((reg = /edg\/([\d\.]+)/i.exec(userAgent))) {
    res.name = "Edge(Chromium)";
    res.version = reg[1];
  } else if (/msie/i.test(userAgent)) {
    res.name = "Internet Explorer";
    res.version = /msie ([\d\.]+)/i.exec(userAgent)[1];
  } else if (/Trident/i.test(userAgent)) {
    res.name = "Internet Explorer";
    res.version = /rv:([\d\.]+)/i.exec(userAgent)[1];
  } else if (/chrome/i.test(userAgent)) {
    res.name = "Chrome";
    res.version = /chrome\/([\d\.]+)/i.exec(userAgent)[1];
  } else if (/safari/i.test(userAgent)) {
    res.name = "Safari";
    res.version = /version\/([\d\.]+)/i.exec(userAgent)[1];
  } else if (/firefox/i.test(userAgent)) {
    res.name = "Firefox";
    res.version = /firefox\/([\d\.]+)/i.exec(userAgent)[1];
  }
  return res;
};
const ShowErrorInfo = (response) => {
  console.log("[cfca-ukey]: ", response);
  if (null == response) {
    Message.error("Extension has been remove!");
  } else if (1 == response.errorcode) {
    // connection error
    //  Message.error(response.result);
  } else {
    // host error
    CryptoKit.GetLastErrorDesc().then(function (response) {
      Message.error(`【CFCA】${response.result}`);
    });
  }
};
const SelectCertificateOnClick = (CryptoKit) => {
  var subjectDNFilter = "";
  var issuerDNFilter = "";
  var serialNumFilter = "";
  var cspNameList = "";
  return new Promise((resolve, reject) => {
    CryptoKit.SelectCertificate(
      subjectDNFilter,
      issuerDNFilter,
      serialNumFilter,
      cspNameList
    )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (response) {
        ShowErrorInfo(response);
        reject(response);
      });
  });
};
const compareVersion = (version1, version2) => {
  var v1Arr = version1.split(".");
  var v2Arr = version2.split(".");
  var len = Math.min(v1Arr.length, v2Arr.length);
  for (var i = 0; i < len; i++) {
    if (parseInt(v1Arr[i], 10) > parseInt(v2Arr[i], 10)) return 1;

    if (parseInt(v1Arr[i], 10) < parseInt(v2Arr[i], 10)) return -1;
  }

  if (len < v1Arr.length) {
    for (var j = len; j < v1Arr.length; j++) {
      if (parseInt(v1Arr[j], 10) != 0) return 1;
    }
  }

  if (len < v2Arr.length) {
    for (var j = len; j < v2Arr.length; j++) {
      if (parseInt(v2Arr[j], 10) != 0) return -1;
    }
  }

  return 0;
};
const compareExtensionVersion = (version) => {
  var browser = BrowserInfo();
  if ("Edge" == browser.name) {
    return compareVersion(version, "3.4.0.1");
  } else if ("Chrome" == browser.name) {
    return compareVersion(version, "3.4.0.1");
  } else if ("Firefox" == browser.name) {
    return compareVersion(version, "3.4.0.1");
  }
};

const LoadObj = () => {
  try {
    var extensionVer;
    var hostVer;

    var browser = BrowserInfo();
    if (
      "Edge" == browser.name ||
      "Edge(Chromium)" == browser.name ||
      ("Chrome" == browser.name && parseInt(browser.version) > 41) ||
      ("Firefox" == browser.name && parseInt(browser.version) > 52)
    ) {
      CryptoKit = new nmCryptokit(browser.name);

      return CryptoKit.init()
        .then(function () {
          return CryptoKit.getExtensionVersion();
        })
        .then(function (response) {
          extensionVer = response.result;
          return CryptoKit.getHostVersion();
        })
        .then(function (response) {
          hostVer = response.result;

          var extensionLevel = compareExtensionVersion(extensionVer);
          var hostLevel = compareVersion(hostVer, "3.4.0.1");
          if (hostLevel < 0 && extensionLevel < 0) {
            Message.error("Please install host");
          } else if (hostLevel < 0) {
            Message.error("Please install host");
          } else {
          }
          return SelectCertificateOnClick(CryptoKit);
        })
        .catch(async function (response) {
          if (response.result === "Extension does not exist!") {
            if (
              await MessageBox.alert(
                "检测到您未成功安装UKey插件，是否跳转去安装？",
                "提示",
                {
                  showCancelButton: true,
                  type: "info",
                }
              ).catch(() => false)
            ) {
              window.open(
                "https://microsoftedge.microsoft.com/addons/detail/cfca-cryptokitshjzgroup-/pknoaccenmmdfpphcldcdbplnjckgokn"
              );
            }
          } else {
            console.log("[cfca-ukey]: ", response);
            Message.error("UKey加载失败，请联系管理员。");
          }
        });
    } else {
      Message.error("请使用chrome浏览器");
    }
  } catch (e) {
    Message.error(e);
    return;
  }
};
///上述是加载ukey证书

//下面是获取证书信息

function GetCertInfoOnClick(val) {
  // "SubjectDN";

  return new Promise((resolve, reject) => {
    CryptoKit.GetSignCertInfo(val)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (response) {
        ShowErrorInfo(response);
        reject(response);
      });
  });
}

//下面是弹出密码框

function SignHashOnClick(val) {
  return new Promise((resolve, reject) => {
    CryptoKit.SignHashMsgPKCS7Detached(val, "SHA-1")
      .then(resolve)
      .catch(function (response) {
        ShowErrorInfo(response);
        reject(response);
      });
  });
}
export default {
  LoadObj,
  SignHashOnClick,
  GetCertInfoOnClick,
};
