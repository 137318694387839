<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="billNo" label="票据编号" min-width="170"></el-table-column>
          <el-table-column prop="payableBillAmount" label="票据类型" min-width="120"  >
              <template slot-scope="scope">
              {{scope.row.billType|billType}}
            </template>
          </el-table-column>
          <el-table-column label="票面金额(元)" align='right' min-width="160">
              <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.billAmount) }}</template>
          </el-table-column>
          <el-table-column prop="delistingCorpName" label="意向企业" min-width="170"></el-table-column>
          <el-table-column  label="扣费总额(元)" align='right' min-width="140" :formatter='el=>$changeMoneyThousand(el.totalDeduction)'></el-table-column>
          <el-table-column  label="平台服务费(元)" align='right' min-width="150" :formatter='el=>$changeMoneyThousand(el.platformServiceFee)'></el-table-column>
          <el-table-column  label="成交金额(元)" align='right' min-width="160" :formatter='el=>$changeMoneyThousand(el.dealAmount)'></el-table-column>
                     <!-- <el-table-column label="有无瑕疵" min-width="110">
            <template slot-scope="scope">{{scope.row.flawStatus==1?'无':'有'}}</template>
          </el-table-column> -->
          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看合同</el-button>


            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [

        {
          label: "票据金额(元): ",
          type: "inputrange",
          field: "billAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "billAmountMax",
          size: "small"
        },

        {
          label: "意向企业: ",
          type: "input",
          field: "delistingCorpName",
          size: "small",
        },
           {
          label: "票据类型: ",
          type: "select",
          field: "billType",
          size: "small",
          options:[{label:'商票',value:1},{label:'国股',value:2},{label:'城商',value:3},{label:'三农',value:4},{label:'财票',value:5}]

        },
                {
          label: "成交金额(元): ",
          type: "inputrange",
          field: "dealAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "dealAmountMax",
          size: "small"
        },



      ],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      changeMoneyThousand:changeMoneyThousand
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
        param.review=2
        param.source=1
      api.getBillSignListPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
      });
    },
    handleEdit(row,val) {
         this.$router.push({
        name: "票据待签约",
        query: {billId:row.billId}
      });


    },
       handleEditinfo(row){
           this.$router.push({
         path: "/bill/bill-approvaled-details",
                    query: { id: row.billId,isshowbtn:3}
      });
    }

  }
};
</script>


