<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <div style="text-align: right; margin-bottom: 10px">
          <el-button v-if="!pageModel.isAudit" type="primary" @click="add"
            >新增</el-button
          >
        </div>
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="scmCorpName"
            label="供应商名称"
            min-width="150"
          ></el-table-column>
          <el-table-column label="供应商额度" min-width="150">
            <template slot-scope="scope">
              {{ $changeMoneyThousand(scope.row.availableAmount) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createUser"
            label="创建人"
            min-width="150"
          ></el-table-column>
          <el-table-column prop="approvalNode" label="审批节点" min-width="150">
            <template #default="scope">
              {{ scope.row.approvalNode | parseDict(approvalNode) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="approvalStatus"
            label="审批状态"
            min-width="150"
          >
            <template #default="scope">
              {{ scope.row.approvalStatus | parseDict(approvalStatus) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="pageModel.handleVisible"
            label="操作"
            min-width="120"
            align="left"
            fixed="right"
          >
            <template slot-scope="scope">
              <template v-if="pageModel.isAudit">
                <el-button
                  size="medium"
                  type="text"
                  :disabled="scope.row.approvalStatus !== 0"
                  @click="audit(scope.row)"
                  >审批</el-button
                >
              </template>
              <el-button
                v-else
                size="medium"
                type="text"
                :disabled="scope.row.approvalStatus !== -1"
                @click="edit(scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>

    <SupplierQuotaEdit
      ref="SupplierQuotaEdit"
      @success="refresh"
    ></SupplierQuotaEdit>
  </div>
</template>
<script>
import api from "../../api/api.js";
import SupplierQuotaEdit from "./supplier-quota-edit.vue";

export default {
  components: { SupplierQuotaEdit },
  props: {
    type: {
      type: String,
      default: "0",
    },
  },
  data() {
    const approvalNode = [
      {
        label: "企业初审",
        value: "10",
      },
      {
        label: "企业复审",
        value: "20",
      },
      {
        label: "平台初审",
        value: "30",
      },
      {
        label: "平台复审",
        value: "40",
      },
    ];
    const approvalStatus = [
      {
        label: "已退回",
        value: "-1",
      },
      {
        label: "待审批",
        value: "0",
      },
      {
        label: "待审批",
        value: "1",
      },
    ];
    return {
      isShowAll: false,
      isHedden: false,
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      filterList: [
        {
          label: "供应商名称:",
          type: "input",
          field: "scmCorpName",
          size: "small",
        },
        {
          label: "审批节点:",
          type: "select",
          field: "approvalNode",
          size: "small",
          options: approvalNode,
        },
      ],
      approvalNode,
      approvalStatus,
    };
  },
  computed: {
    pageModel() {
      const isAudit = /-audit/.test(this.$route.path);

      let handleVisible = false;
      if (isAudit) {
        handleVisible = this.type === "0";
      } else {
        handleVisible = this.type === "-1";
      }
      return {
        isAudit,
        handleVisible,
      };
    },
  },
  filters: {
    parseDict(val, dictData) {
      if (Array.isArray(dictData) && dictData.length) {
        const item = dictData.find((item) => item.value === `${val}`);
        if (item) {
          return item.label;
        }
      }
      return "--";
    },
  },
  methods: {
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(params) {
      let request = api.getSupplierQuotaList;
      request({
        ...params,
        checkLevel: this.pageModel.isAudit ? "1" : "0",
        approvalStatus: this.type,
      }).then((res) => {
        if (res.success) {
          this.list = [];
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    refresh() {
      this.$refs.datalist.refresh();
    },
    audit(row) {
      this.$refs.SupplierQuotaEdit.show(row);
    },
    add() {
      this.$refs.SupplierQuotaEdit.show();
    },
    edit(row) {
      this.$refs.SupplierQuotaEdit.show(row);
    },
  },
};
</script>
