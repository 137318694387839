<template>
  <div class="re_step">
    <el-steps class="step-content" finish-status="success" :active="step" :align-center="center" >
      <el-step class="step-item " title="创建账户"></el-step>
      <el-step class="step-item " title="密码设置"></el-step>
      <el-step class="step-item " title="企业认证"></el-step>
      <el-step class="step-item " title="注册成功"></el-step>
    </el-steps>
  </div>
</template>
<script>
export default {
  props: {
    stepValue: Number
  },
  data() {
    return {
      step: this.stepValue,
      center: true
    };
  },
  mounted() {}
};
</script>
<style lang='scss' >
@import '../../styles/vars.scss';
.re_step {
  margin: 0 auto;
  margin-top: 40px;
  .el-step__line {
    top: 25px !important;
    background-color: #D9D9D9 ;
  }
  .el-step__icon {
    width: 32px;
    height: 32px;
    // background-color: #d9d9d9;
    border: 0px;
    margin-top: 10px;
    border:2px solid #d9d9d9;
    .el-step__icon-inner {
      // color: #bfbfbf;
    }
    
  }
  // .el-step__title {
  //   color: #8c8c8c !important
  // }
  .el-step__title.is-wait{
    color:#8C8C8C;
  }
  .is-success {
    .el-step__icon {
      border-color: $base-color1;
      .el-step__icon-inner {
        
      }
    }
    .is-text{
      color: $base-color1;
    }
    .el-step__line-inner{
      border-color: $base-color1;
    }
  }
  .el-step__title.is-process{
    color: #262626;
  }
  .el-step__title.is-success{
    color: #595959;
  }
  .is-process{
    .el-step__icon {
      border-color: $base-color1;
      background-color: $base-color1;
    }
    .is-text{
      color: #fff;
    }
  }
  .step-content {
    background-color: white;
    .step-item {
    
    }
  }
}
.el-step__icon {
  width: 50px;
  height: 50px;
}

</style>


