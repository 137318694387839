<template>
  <div class="upload-evidence-box">
    <el-upload
      class="upload-demo"
      ref="upload"
      action=""
      accept="image/jpeg,image/png,image/jpg"
      :before-upload="onBeforeUploadImage"
      :http-request="UploadImage"
      :show-file-list='false'
      :file-list="fileList"> 
      <el-button size="small" type="primary" :disabled="processing">上传凭证</el-button>
    </el-upload>
  </div>
</template>
<script>
import api from '@/api/api.js'
export default {
  props: {
    receivableAccountFinanceId: {}
  },
  data () {
    return {
      uploadTip:'上传凭证',
      processing: false,
      fileList: [],
    }
  },
  mounted() { },
  methods: {
    onBeforeUploadImage(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/jpg' || 'image/png' || 'image/gif'
      const isLt3M = file.size / 1024 / 1024 < 3
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
      }
      if (!isLt3M) {
        this.$message.error('上传文件大小不能超过 3MB!')
      }
      return isIMAGE && isLt3M
    },
    UploadImage(param){
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('receivableAccountFinanceId', this.receivableAccountFinanceId)
      api.uploadProof(formData).then(res => {
        if(res.success) {
          this.$alert(res.message, "", {
            confirmButtonText: "确定",
            callback: action => {
              
            }
          });
          let data = {
            pageSize: this.$route.query.pageSize,
            pageNum: this.$route.query.pageNum
          }
          this.$emit('getLists', data)
        } else {
          this.$alert('操作失败，请重新上传', "", {
            confirmButtonText: "确定",
            callback: action => {
            }
          });
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.upload-evidence-box {
  .el-button--primary {
    background-color: transparent;
    border: none;
    color: #409EFF;
    font-size: 14px;
    padding-left: 0;
    cursor: pointer;
  }
}
</style>


