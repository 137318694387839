<!-- 账款详情 -->
<template>
  <div class="absmaininfo">
    <layout-main-view :breadcrumb='breadcrumblist'>
      <detailInfo></detailInfo>
    </layout-main-view>
  </div>
</template>
<script>
  import api from "@/api/api";
  import  detailInfo from './detailInfo' 
  export default {
    components: {
      detailInfo
    },
    data() {
      return {
        breadcrumblist:['资产账款详情'],
      }
    },
    created() { },
    methods: { },
  }
</script>
<style lang="scss" scoped>
.absmaininfo{
  .content-container-inner{
    background: #ffffff;
    padding-top: 20px;
  }
}
</style>