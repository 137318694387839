<!-- 票据偏好设置 -->
<template>
    <layout-main-view :breadcrumb='breadcrumblist'>
        <div class="bill-preference">
            <el-form ref="settingForm" :model="settingForm" :rules="settingRules">
                <div v-if="settingForm.corpType == 1" class="bill-preference-item">
                    <div class="bill-preference-item-title">票据类型偏好</div>
                    <div class="bill-preference-item-tips"><i class="el-icon-warning"></i>系统会按照票据类型推送符合您投资偏好习惯的票据。</div>
                    <el-form-item label="票据类型：" prop="billTypes">
                        <el-select v-model="settingForm.billTypes" placeholder="请选择" multiple  style="min-width: 500px">
                            <el-option
                            v-for="item in billTypeList"
                       
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div v-if="settingForm.corpType == 1" class="bill-preference-item">
                    <div class="bill-preference-item-title">票据金额偏好</div>
                    <div class="bill-preference-item-tips"><i class="el-icon-warning"></i>系统会按照意向资产金额区间内的票据进行推送。</div>
                    <el-form-item label="意向资产金额：" required>
                        <el-form-item prop="intentionAmountMin" style="display:inline-block;">
                            <el-input v-model.number="settingForm.intentionAmountMin" class="form-item-input"></el-input>
                        </el-form-item>
                        <span class="connect-line">-</span>
                        <el-form-item prop="intentionAmountMax" style="display:inline-block;">
                            <el-input v-model.number="settingForm.intentionAmountMax" class="form-item-input"></el-input>
                        </el-form-item>
                    </el-form-item>
                </div>
                <div v-if="settingForm.corpType == 1" class="bill-preference-item">
                    <div class="bill-preference-item-title">票据期限偏好</div>
                    <div class="bill-preference-item-tips"><i class="el-icon-warning"></i>系统会按照偏好账款期限内的票据进行推送</div>
                    <el-form-item label="意向期限：" prop="assertExpire">
                        <el-checkbox-group v-model="settingForm.assertExpire">
                            <el-checkbox label="1">小于30天</el-checkbox>
                            <el-checkbox  label="2">30~60天</el-checkbox>
                            <el-checkbox label="3">60~90天</el-checkbox>
                            <el-checkbox label="4">90~180天</el-checkbox>
                            <el-checkbox label="5">180~365天</el-checkbox>
                            <el-checkbox label="6">365天以上</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </div>
                <div class="bill-preference-item">
                    <div class="bill-preference-item-title">
                        <template>{{settingForm.corpType == 1? '偏好企业' : '白名单企业' }}</template>
                    </div>
                    <div class="bill-preference-item-tips">
                        <i class="el-icon-warning"></i>
                        关注企业总共 {{followTotal}} 家， <template>{{settingForm.corpType == 1? ' 这些企业如果发布票据信息后会自动推送至待撮合列表中' : '这些企业能在票据中心快捷的找到您发布的票据' }}</template>

                    </div>
                    <div class="bill-preference-item-input">
                        <el-autocomplete
                            class="inline-input"
                            v-model="companyName"
                            :fetch-suggestions="queryCompany"
                            placeholder="请输入内容"
                            :trigger-on-focus="false"
                            @select="handleSelect"
                        >
                        </el-autocomplete>
                        <el-button type="primary" icon="el-icon-plus" @click="followCompany()">关注企业</el-button>
                    </div>
                    <div class="follow-list">
                        <div v-for="(item,index) in settingForm.intentionCorpIds " :key="index" class="follow-list-item">
                            {{item.value}}
                            <i class="el-icon-error" @click="deleteCompany(index)"></i>
                        </div>
                    </div>
                </div>
                <div class="footer-btn">
                    <el-button>取消</el-button>
                    <el-button type="primary" @click="submit">确定</el-button>
                </div>
            </el-form>
        </div>
    </layout-main-view>
</template>
<script>
    import api from "@/api/api";
    export default {
        data() {
            return {
                breadcrumblist: ['票据偏好管理'],
                billCorpIntentionId: '',
                editType: 1, // 1：新增 2：编辑
                settingForm: {
                    corpType: JSON.parse(sessionStorage.user).corpType == 1?1:2,
                    billTypes: [],
                    intentionAmountMin: 0,
                    intentionAmountMax: 0,
                    assertExpire: [],
                    intentionCorpIds: [],
                },
                settingRules: {
                    billTypes: [{required: true, message: '请选择票据类型', trigger: 'change'}],
                    intentionAmountMin: [
                        {required: true, message: '意向票据金额最小额不能为空',trigger: 'blur'},
                        {type: 'number',message: '请输入数字'},
                    ],
                    intentionAmountMax: [
                        {required: true, message: '意向票据金额最大额不能为空', trigger: 'blur'},
                        {type: 'number',message: '请输入数字'},
                    ],
                    assertExpire: [{required: true, message: '请选择意向期限', trigger: 'change'}]
                },
                billTypeList: [
                    {
                        label: '商票',
                        value: 1,
                    },
                    {
                        label: '国股',
                        value: 2,
                    },
                    {
                        label: '城商',
                        value: 3,
                    },
                    {
                        label: '三农',
                        value: 4,
                    },
                    {
                        label: '财票',
                        value: 5,
                    },
                ],
                checkList: [],
                followTotal: 0,
                companyName: '',
                followCompanyList: [],
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                api.InitBillSetting({corpType: this.settingForm.corpType}).then(res => {
                    if(res.success) {
                        if(res.datas.billCorpIntentionDto.billCorpIntentionId) {
                            this.editType = 2  
                            this.settingForm = res.datas.billCorpIntentionDto;
                            this.settingForm.billTypes =this.settingForm.billTypes&&JSON.parse(this.settingForm.billTypes)
                            this.settingForm.assertExpire =JSON.parse(this.settingForm.assertExpire)
                          
                     this.settingForm.intentionCorpIds = JSON.parse(this.settingForm.intentionCorpIds);
                        this.followTotal= this.settingForm.intentionCorpIds.length

                        }
                    }
                })  
            },
            // 企业列表查询
            queryCompany(queryString,cb) {
                // 判断 如果是资金企业（1）的话就只能查询到核心企业和一般企业的数据否则查询资金企业数据
                let apiObj = this.settingForm.corpType === 1 ? api.GetCompanyList({'corpName': this.companyName}) : api.GetCpjList({'corpName': this.companyName}) ;
                apiObj.then(res => {
                    if(res.code == 100000) {
                        var result = [];
                        var companyList = res.datas.corpList||[];
                        var results = queryString ? companyList.filter(this.createStateFilter(queryString)) : companyList;
                        console.log(results,'sss')
                 
                        if(results.length>0){
                      
                                  for(let i of results){
                                        i.value = i.corpName
                                }
                        }
                      
                        cb(results);
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 公司名过滤
            createStateFilter(queryString) {
                return (state) => {
                    return (state.corpName.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                }
            },
            // 获取选中公司
            handleSelect(item) {
                if(item){
                         this.chooseObj = item  
                }
           

                console.log(this.chooseObj,'this.chooseObj')
            },
            // 添加关注企业
            followCompany() {
                   
                let result = []
                if(this.settingForm.intentionCorpIds.length !== 0) {
                    let obj = {}
                       if(this.chooseObj){
                          this.settingForm.intentionCorpIds.push(this.chooseObj);
                    }
                   
                    let arr = this.settingForm.intentionCorpIds
                 
                    for(var i =0; i<arr.length; i++){
                        if(!obj[arr[i].value]){
                            obj[arr[i].value] = true;
                            result.push(arr[i]);
                        }else {
                            this.$message.warning('关注的企业已存在！');
                        }
                    }
                }else {
                    if(this.chooseObj){
                          result.push(this.chooseObj);
                    }
                  
                }
             //  this.settingForm.intentionCorpIds = [{"value": "超人链属", "corpId": 10, "corpName": "超人链属"}, {"value": "核心企业-测试勿动", "corpId": 4, "corpName": "核心企业-测试勿动"}, {"value": "YQ核心企业测试", "corpId": 5, "corpName": "YQ核心企业测试"}, {"value": "YQ一般企业-测试", "corpId": 6, "corpName": "YQ一般企业-测试"}, {"value": "正义联盟核心", "corpId": 8, "corpName": "正义联盟核心"}, {"value": "核心企业-测试勿动", "corpId": 4, "corpName": "核心企业-测试勿动"}, {"value": "核心企业-测试勿动", "corpId": 4, "corpName": "核心企业-测试勿动"}, {"value": "正义联盟核心", "corpId": 8, "visible": false, "corpName": "正义联盟核心"}, {"value": "链属企业", "corpId": 14, "corpName": "链属企业"}, {"value": "YQ一般企业-测试", "corpId": 6, "corpName": "YQ一般企业-测试"}]
             //   this.settingForm.intentionCorpIds.push(this.chooseObj);
           this.settingForm.intentionCorpIds=result
          //  this.settingForm.intentionCorpIds =[]
                this.companyName = '';
                this.chooseObj = null;
                console.log( this.settingForm.intentionCorpIds,' this.settingForm.intentionCorpIds')
            
            },
            // 删除关注企业
            deleteCompany(index) {
                this.settingForm.intentionCorpIds.splice(index,1);
            },
            // 提交
            submit() {
                this.$refs.settingForm.validate((valid) => {
                
                    if (valid) {
                        this.settingForm.billTypes= JSON.stringify(this.settingForm.billTypes)
                        let obj = Object.assign(
                            {},
                            this.settingForm,
                            {assertExpire: JSON.stringify(this.settingForm.assertExpire)},
                            {intentionCorpIds: JSON.stringify(this.settingForm.intentionCorpIds)},
                        )
                        if(this.editType == 1) {
                            api.BillSettingAdd(obj).then(res => {
                                if(res.success) {
                                    this.$message.success(`${res.message}`);
                                    this.init();
                                }
                            })
                        }else {
                            api.BillSettingUpdate(obj).then(res => {
                                if(res.success) {
                                    this.$message.success(`${res.message}`);
                                    this.init();
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
<style lang="scss">
    .bill-preference {
        background: #fff;
        padding: 33px 25px;
        &-item {
            border-bottom: 1px solid #eee;
            &-title {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                margin: 15px 0;
            }
            &-tips {
                height: 40px;
                line-height: 40px;
                padding: 0 10px;
                background: #EDF4FF;
                font-size: 12px;
                margin-bottom: 15px;
                color: #1890FF;
                .el-icon-warning {
                    display: inline-block;
                    margin-right: 10px;
                    color: #078FEA;
                }
            }
            &-input {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .input-name {
                    padding-right: 15px;
                }
                .input-group {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    .connect-line {
                        padding: 0 10px;
                    }
                    .el-checkbox {
                        +.el-checkbox {
                            margin-left: 20px;
                        }
                    }
                }
                button {
                    margin-left: 20px;
                }
            }
            .follow-list {
                display: flex;
                margin: 10px 0;
                &-item {
                    position: relative;
                    padding: 8px 16px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                    &+.follow-list-item {
                        margin-left: 20px;
                    }
                    .el-icon-error {
                        position: absolute;
                        top: -6px;
                        right: -6px;
                        opacity: 0;
                        transition:  all 0.5s ease-in-out; 
                    }
                    &:hover {
                        .el-icon-error {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .footer-btn {
            margin-top: 30px;
            text-align: center;
            button {
                width: 100px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                &+button {
                    margin-left: 30px;
                }
            }
        }
    }
</style>