<template>
  <div class="account-result3">
    <div class="result-tips">
      <i class="el-icon-info"></i>
      温馨提示：亲爱的用户，遗憾的通知您，贵司开户没有成功。请仔细确认失败的理由重新提交相关材料并重新发起开户申请，谢谢您的合作和谅解，对给您带来的不便深表歉意。
    </div>
    <div class="result-box">
      <img src="../assets/login/rz_dengdai.png" alt="" class="result-icon">
      系统检测到贵司开户状态为：
      <div class="result-text">开户失败</div>
      
      <div class="result-info">
        <div>
          失败原因：
          <div class="result-info1">{{remark}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },
  props: {
    remark: {}
  },
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.result-tips {
  background: rgba(235, 244, 255, 1);
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(204, 224, 255, 1);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  i {
    color: #1989fa;
  }
}
.result-box {
  color: #999;
  text-align: center;
  .result-icon {
    display: block;
    margin: 16px auto;
  }
  .result-text {
    color: #000;
    font-size: 24px;
    margin-top: 10px;
  }
}
.result-info {
  width: 70%;
  margin: 20px auto;
  padding: 16px 24px;
  background-color: rgba(200, 200, 200, .2);
  border-radius: 4px;
  text-align: left;
  &>div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .result-info1 {
    color: #000;
    font-size: 16px;
  }
}
</style>
