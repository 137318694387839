<template>
    <div class="billyxjg">
         <div class="bill-form-box bill-details">
            <div class="bill-title">拟签约企业</div>
              <el-table
                ref="singleTable"
                :data="tableData"
                highlight-current-row

                style="width: 100%">
                <el-table-column
                prop="corpName"
                label="意向企业"
                min-width="120">
                </el-table-column>
                <el-table-column
                prop='creditCode'
                label="社会统一信用代码"
                width="200">
                </el-table-column>
                <el-table-column
                prop="dealRate"
 min-width="180"
                label="意向利率(%)" >
                </el-table-column>
                  <el-table-column
                prop="chargeAmount"
                align='right'
     min-width="200"
                label="意向每十万扣费(元)">

            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.dealDeduction )}}</template>

                </el-table-column>
                  <el-table-column
                prop="address"
                align='right'
     min-width="180"

                label="预计扣费总额(元)">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.totalDeduction)}}</template>
                </el-table-column>
                  <el-table-column
     min-width="180"

                align='right'

                label="预计成交金额(元)">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.dealAmount)}}</template>

                </el-table-column>

            </el-table>
    </div>
    </div>



</template>
<script>
    import api from "@/api/api.js";


    export default {
        components: {

        },
              props:{
           tableData:Array
        },
        data() {
            return {

            }
        },
        created() {

        },
        methods: {


        }
    };
</script>


<style lang="scss">
   .bill-details {
        position: relative;
        background: #fff;
        padding: 33px 25px;
        .upload-box {
            margin-bottom: 20px;
            &-item {
                border: 1px solid #eee;
                background: #fff;
                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    padding: 0 20px;
                    background: #eee;
                }
                &-cont {
                    position: relative;
                    height: 300px;
                    overflow: auto;
                    .upload-img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        // max-width: 100%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .label {
            display: inline-block;
            width: 130px;
            height: 40px;
            line-height: 40px;
           
            margin-right: 20px;
        }
        .value {
            display: inline-block;
            height: 40px;
            line-height: 40px;
        }
        .flaw-list {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
            }
        }
        .connect-line {
            padding: 0 20px;
        }
    }

</style>
