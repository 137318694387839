<!--优企通开单协议-->
<template>
    <div>
        <p style="font-size:14pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; widows:0"><span
                style="font-family:微软雅黑; font-size:14pt">协议编号：</span><span
                style="font-family:微软雅黑; font-size:14pt">【{{signAgreementDetail&&signAgreementDetail.no ? signAgreementDetail.no :'' }}】</span></p>
        <p style="font-size:18pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:18pt">经证供应链平台优企通</span><span
                style="font-family:微软雅黑; font-size:18pt">开单协议</span></p>
        <p style="font-size:18pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:18pt">     </span><span
                style="font-family:微软雅黑; font-size:14pt"> 版本：v1.0</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人：{{signAgreementDetail&&signAgreementDetail.openCorpName ? signAgreementDetail.openCorpName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人：{{signAgreementDetail&&signAgreementDetail.openUserName ? signAgreementDetail.openUserName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系地址：{{signAgreementDetail&&signAgreementDetail.openAddress ? signAgreementDetail.openAddress :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系电话：{{signAgreementDetail&&signAgreementDetail.openPhone? signAgreementDetail.openPhone :'' }}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">原始持单人：{{signAgreementDetail&&signAgreementDetail.receiveCorpName ? signAgreementDetail.receiveCorpName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人：{{signAgreementDetail&&signAgreementDetail.receiveUserName ? signAgreementDetail.receiveUserName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系地址：{{signAgreementDetail&&signAgreementDetail.receiveAddress ? signAgreementDetail.receiveAddress :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系电话：{{signAgreementDetail&&signAgreementDetail.receivePhone? signAgreementDetail.receivePhone :'' }}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">鉴于：</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">因原始持单人向开单人提供货物、服务或设施，开单人承担相应付款责任，开单人保证总计人民币（大写）</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">   {{amount ? amount :signAgreementDetail&&signAgreementDetail.amountUpper ? signAgreementDetail.amountUpper : ''}}   </span><span
                style="font-family:微软雅黑; font-size:12pt">的应付账款（对应优企通编号：{{signAgreementDetail&&signAgreementDetail.payableBillNo ? signAgreementDetail.payableBillNo :''}}</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">         </span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">）</span><span
                style="font-family:微软雅黑; font-size:12pt">在</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">     </span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">   </span><span
                style="font-family:微软雅黑; font-size:12pt"></span><span  
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline"> {{promisePayTime? moment(promisePayTime).format('YYYY年MM月DD日') :signAgreementDetail&&signAgreementDetail.promisePayTime ? signAgreementDetail.promisePayTime : '' }}  </span><span
                style="font-family:微软雅黑; font-size:12pt">（以下简称“优企通到期日”）前，按照开单人在经证供应链平台接收到的清分明细表所列明的收款方（以下简称“最终持单人”）的收款信息付款。
            </span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">基于此，</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人、原始持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">（“</span><span
                style="font-family:微软雅黑; font-size:12pt">双方</span><span
                style="font-family:微软雅黑; font-size:12pt">”）</span><span
                style="font-family:微软雅黑; font-size:12pt">在平等自愿、友好协商的基础上，根据《中华人民共和国民法典》及相关法律规定，在认可并</span><span
                style="font-family:微软雅黑; font-size:12pt">签署</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证业务服务协议（适用优企通产品）</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">的前提下，</span><span
                style="font-family:微软雅黑; font-size:12pt">达成《</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单协议》，本协议在</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">签署：</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">在本协议中，除非上下文另有解释或文义另作说明，本协议中的词语或简称以及所述的解释规则与</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证</span><span
                style="font-family:微软雅黑; font-size:12pt">业务服务协议</span><span
                style="font-family:微软雅黑; font-size:12pt">（适用优企通产品）</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">中所定义的词语以及所列示的解释规则，具有相同的含义。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">一</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单概述</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">开单人根据</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">的业务规则和指引申请开立</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">，申请开立的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">为载有一定金额的、作为基础合同的应收账款电子付款债务凭证，并</span><span
                style="font-family:微软雅黑; font-size:12pt">承诺</span><span
                style="font-family:微软雅黑; font-size:12pt">在一定的期限内凭</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">兑付，开单人作为应收账款债务人，持单人作为应收账款债权人。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立成功，开单人作为负有</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">偿付义务的债务人，持单人作为享有</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">项</span><span
                style="font-family:微软雅黑; font-size:12pt">下到期款项的权利人。成功开立的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">，可作为本平台其他服务的交易基础和承诺支付凭证。同时，开单人理解并同意，</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">无义务保证开单人在提出</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">交易申请后，能够实际达成交易。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">提请注意：仅有</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">认证用户适用于</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单服务</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">二</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单流程</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单申请</span><span
                style="font-family:微软雅黑; font-size:12pt">及审核</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.1开单人提出</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单申请的，应通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">，根据</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">规则</span><span
                style="font-family:微软雅黑; font-size:12pt">，填写完毕</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单申请资料，上传完毕全部</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单申请文件，并勾选本协议。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1</span><span
                style="font-family:微软雅黑; font-size:12pt">.2</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人勾选本协议，视为同意本协议全部内容。开单人承诺保证按照</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">所载内容履行付款义务，并愿意承担逾期付款的违约责任。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1</span><span
                style="font-family:微软雅黑; font-size:12pt">.3</span><span style="font-family:微软雅黑; font-size:12pt">
            </span><span style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单后，须由平台审核通过</span><span
                style="font-family:微软雅黑; font-size:12pt">后，流转至</span><span
                style="font-family:微软雅黑; font-size:12pt">原始</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.4</span><span style="font-family:微软雅黑; font-size:12pt">
            </span><span style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立成功</span><span
                style="font-family:微软雅黑; font-size:12pt">后，</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">自动生成</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单记录，双方可通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">查询功能进行查询，该记录为本协议的组成部分。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单签收</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2.1</span><span
                style="font-family:微软雅黑; font-size:12pt">原始持单人收到开单人</span><span
                style="font-family:微软雅黑; font-size:12pt">开立的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">后，应通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">，根据</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的规则</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">对开单人开立</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">签收“同意”或“拒绝”；原始持单人签收“同意”</span><span
                style="font-family:微软雅黑; font-size:12pt">后，</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立成功。</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立成功，视为开单人与原始持单人共同确认双方的债权债务关系，原始持单人为应收账款债权人，开单人为应收账款债务人，</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">所记载的信息为开单人与原始持单人在基础合同项下的债务信息</span><span
                style="font-family:微软雅黑; font-size:12pt">。如</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">记载的到期日与基础合同到期日不一致的，视为开单人与原始持单人协商一致将合同到期日变更为</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">记载的到期日。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span
                style="font-family:微软雅黑; font-size:12pt">.</span><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span style="font-family:微软雅黑; font-size:12pt">
            </span><span style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">签收</span><span
                style="font-family:微软雅黑; font-size:12pt">同意</span><span
                style="font-family:微软雅黑; font-size:12pt">后，</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">自动生成</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开单</span><span
                style="font-family:微软雅黑; font-size:12pt">及</span><span
                style="font-family:微软雅黑; font-size:12pt">签收记录，双方可通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">查询功能进行查询。该记录为本协议的组成部分，</span><span
                style="font-family:微软雅黑; font-size:12pt">与本协议</span><span
                style="font-family:微软雅黑; font-size:12pt">具有同等法律效力</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2.3 开单人在原始持单人签收</span><span
                style="font-family:微软雅黑; font-size:12pt">同意</span><span
                style="font-family:微软雅黑; font-size:12pt">前，可随时撤回</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立申请，开单人撤回</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立申请，或原始持单人拒绝签收</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的，</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开立失败。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">业务</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">，开单人及原始持单人均勾选了本协议，视为双方同意本协议的条款</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">三</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">的使用</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、使用</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">应</span><span
                style="font-family:微软雅黑; font-size:12pt">注册为平台的会员且会员只能在平台上使用</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的开单人、持单人均同意</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">及其对应债权可以转让，并认可持单人可将其持有的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">拆分使用。拆分后的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">金额之和等于拆分前的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">金额。</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的流转使用不限次数，各次流转分别独立、不可追索。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">可以作为到期付款承诺使用，开单人应基于真实的贸易背景，将其所开立的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开具</span><span
                style="font-family:微软雅黑; font-size:12pt">给其债权人即原始持单人，作为原始持单人到期要求开单人付款的凭证。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">4、</span><span
                style="font-family:微软雅黑; font-size:12pt">原始</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人/持单人可以将持有的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">全部或部分通过平台转让给其他用户，</span><span
                style="font-family:微软雅黑; font-size:12pt">原</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">有义务</span><span
                style="font-family:微软雅黑; font-size:12pt">通过</span><span
                style="font-family:微软雅黑; font-size:12pt">平台</span><span
                style="font-family:微软雅黑; font-size:12pt">系统消息</span><span
                style="font-family:微软雅黑; font-size:12pt">/邮件/短信</span><span
                style="font-family:微软雅黑; font-size:12pt">向开单人</span><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">原始债务人</span><span
                style="font-family:微软雅黑; font-size:12pt">）</span><span
                style="font-family:微软雅黑; font-size:12pt">发送《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">》，</span><span
                style="font-family:微软雅黑; font-size:12pt">转让方应</span><span
                style="font-family:微软雅黑; font-size:12pt">通过</span><span
                style="font-family:微软雅黑; font-size:12pt">平台</span><span
                style="font-family:微软雅黑; font-size:12pt">系统消息/邮件/短信等任一种方式向</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的开单人发送</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">该通</span><span
                style="font-family:微软雅黑; font-size:12pt">知</span><span
                style="font-family:微软雅黑; font-size:12pt">发送至开单人</span><span
                style="font-family:微软雅黑; font-size:12pt">在平台的账户</span><span
                style="font-family:微软雅黑; font-size:12pt">或联系邮箱或联系电话</span><span
                style="font-family:微软雅黑; font-size:12pt">（以任一较早</span><span
                style="font-family:微软雅黑; font-size:12pt">发送</span><span
                style="font-family:微软雅黑; font-size:12pt">为准）</span><span
                style="font-family:微软雅黑; font-size:12pt">即视为债权转让通知已经送达。但是，在平台限定的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">到期前的一定期限内，持单人须持有</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">到期，等待开单人的资金偿付。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">5、</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人可以向资金方申请融资，具体手续依照平台相关业务规则所规定的流程办理。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">如果保理公司向</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人提供保理融资，则保理公司亦可向银行申请再保理融资。银行受理持单人或保理公司的融资时，银行将作为</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">最终持单人，</span><span
                style="font-family:微软雅黑; font-size:12pt">有权</span><span
                style="font-family:微软雅黑; font-size:12pt">委托平台通过系统消息</span><span
                style="font-family:微软雅黑; font-size:12pt">或邮件或短信</span><span
                style="font-family:微软雅黑; font-size:12pt">向开单人发送《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">，该通知到达持单人对应的直接债务人在平台的账户</span><span
                style="font-family:微软雅黑; font-size:12pt">或联系邮箱或联系电话</span><span
                style="font-family:微软雅黑; font-size:12pt">即视为债权转让通知已经送达。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">6</span><span
                style="font-family:微软雅黑; font-size:12pt">、持单人可以持有</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">直至到期兑付。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">7</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">无责任或义务将</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">兑换为任意额度现金或有价证券，亦不为</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">承担任何形式的担保或偿还责任。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">四</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">的管理及到期支付</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">提供</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">管理服务，具体包括：</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">1</span><span
                style="font-family:微软雅黑; font-size:12pt">）记录每一笔</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">的使用情况；</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span
                style="font-family:微软雅黑; font-size:12pt">）向开单人提供最终还款明细表查询服务；</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">）向开单人发出付款到期提醒；</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">4</span><span
                style="font-family:微软雅黑; font-size:12pt">）向会员提供电子法律文本的备份、查看、核对</span><span
                style="font-family:微软雅黑; font-size:12pt">、下载服务</span><span
                style="font-family:微软雅黑; font-size:12pt">；</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">5</span><span
                style="font-family:微软雅黑; font-size:12pt">）</span><span
                style="font-family:微软雅黑; font-size:12pt">有权</span><span
                style="font-family:微软雅黑; font-size:12pt">通过系统消息</span><span
                style="font-family:微软雅黑; font-size:12pt">/</span><span
                style="font-family:微软雅黑; font-size:12pt">邮件</span><span
                style="font-family:微软雅黑; font-size:12pt">/</span><span
                style="font-family:微软雅黑; font-size:12pt">短信</span><span
                style="font-family:微软雅黑; font-size:12pt">等</span><span
                style="font-family:微软雅黑; font-size:12pt">任一种方式向开单人发送《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">》。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、平台系统所记录的数据和信息是确定交易具体内容的依据，各方对此予以确认并不持异议。负责填写的各方承诺并确保其数据和信息完全真实合法，不</span><span
                style="font-family:微软雅黑; font-size:12pt">存在虚假、隐瞒信息的情形。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人应在</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通到期日</span><span
                style="font-family:微软雅黑; font-size:12pt">之前将相等于承诺付款金额的资金转入还款账户，并在此不可撤销地向合作银行发出还款指令，由合作银行将转入还款账户的资金向持单人支付。由于银行间系统结算等原因，持单人实际收到款项的时间可能会晚于</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通到期日</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">4、商务合同项下的纠纷不影响</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">与</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">流转的效力，开单人仍须按</span><span
                style="font-family:微软雅黑; font-size:12pt">照到期付款承诺函的约定偿还款项，持单人不得要求将已流转的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">退还给开单人或前手持单人，如需就商务合同进行退款、赔偿等，由相关方另行协商解决。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">5、如果开单人未按照到期付款承诺函约定的日期偿还款项，应承担相应的违约责任，具体详</span><span
                style="font-family:微软雅黑; font-size:12pt">见《付款承诺书》。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">五</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、承诺与保证</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、本协议开单人、原始持单人相互承诺和保证如下：</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.1</span><span
                style="font-family:微软雅黑; font-size:12pt">双方均为</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">认证用户，双方承诺遵守</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">。双方各自在</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">项下的陈述和保证为本协议的一部分，其一旦违反将需承担</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">和本协议项下的违约责任。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.2</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><span
                style="font-family:微软雅黑; font-size:12pt">双方各自具有签署本协议和履行本协议所规定各项义务的完整的合法资格，并取得了签署和履行本协议的所有内部授权及批准，本协议经签署后对双方是合法、有效、有约束力并可强制执行的。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.3</span><span
                style="font-family:微软雅黑; font-size:12pt">双方签署和履行本协议并不违反其所适用的任何法律、法规、规范性文件或政府机构的许可或批准，亦不违反任何对其有约束力的组织文件或其与任何第三方签订的任何合同或协议（或者已取得第三方的同意或认可）。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1.4双方承诺，开立和签收的</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">有真实的贸易背景，</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">项下基础交易所对应的基础合同已妥善签署、合法、有效。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、开单人承诺</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2.1</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">充分了解并承诺，不同于普通的债权转让，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">具有应收账款高效流转的特殊性质，核心企业开具</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">电子凭证的行为本身自动视为</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">完全且无条件地认可因不断流转而产生的一切新债权人、新受让人、新持单人身份及任一笔应收账款转让事实的存在，无论该主体的企业名称、企业性质、资产规模、股东背景、企业文化、信用背景、商业行为等，也无论该转让基于融资、贸易活动等，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">负有及时接收</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">流转信息的义务，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">不得以任何理由（包括且不限于未收到通知、拒收、不认可新持单人身份）对抗平台以及因</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">转让而持有</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">的用户或影响其对</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">持有人的偿付。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2.2开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">承诺，对于平台发送的</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">《</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">》</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">有义务及时登陆账户并进行完整阅读</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">特别是收款人账户信息</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">，</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">未及时接收或阅读或未及时登陆账户的，不影响通知效力。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2.3</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">开单人承诺作为应收账款的债务人，因其与原始持单人在</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">项下的基础交易而依法或根据合同约定取得的任何抗辩权，不得用于对抗资金方以及因</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">转让而持有</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">的</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">任意用户。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2.</span><span
                style="font-family:微软雅黑; font-size:12pt">4</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人承诺在</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">载明的</span><span
                style="font-family:微软雅黑; font-size:12pt">到期日</span><span
                style="font-family:微软雅黑; font-size:12pt">按时足额向持单人支付款项赎回</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2.</span><span
                style="font-family:微软雅黑; font-size:12pt">5</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人在开立</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">前/时及以后，不曾亦保证不会就</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">项下应收账款的全部或任何部分，通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">重复开立</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">给第三方或通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">外的途径转让或质押给任何第三方。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2.</span><span
                style="font-family:微软雅黑; font-size:12pt">6</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人承诺、保证并同意</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">开具</span><span
                style="font-family:微软雅黑; font-size:12pt">后，原始持单人对</span><span
                style="font-family:微软雅黑; font-size:12pt">优企通</span><span
                style="font-family:微软雅黑; font-size:12pt">享有完全的权利，包括但不限于到期收到本金、利息权益、违约金权益、损害赔偿权益、担保权益以及再转让权等。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">六</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、税费</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">持单人、开单人按照中国法律、行政法规、规章的规定承担各自因履行本协议而产生的相应税负，或承担履行代扣代缴义务。持单人和开单人因履行本协议过程中所产生的税负，除非本协议另有约定，应由原始持单人和开单人依法各自承担。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">七</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、保密</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">为保证本协议的全面履行，双方承诺就对方提供的所有资料中所包含的商业秘密和非公开信息严格保密，未经对方同意不得向第三方披露。无论本协议是否终止或解除，本条款在保密期限内持续有效。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">八</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、违约责任</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、开单人违反</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议</span><span
                style="font-family:微软雅黑; font-size:12pt">第</span><span
                style="font-family:微软雅黑; font-size:12pt">五</span><span
                style="font-family:微软雅黑; font-size:12pt">条“承诺和保证”中的任一事项，或违反本协议其他约定义务的事项的，构成开单人</span><span
                style="font-family:微软雅黑; font-size:12pt">违约</span><span
                style="font-family:微软雅黑; font-size:12pt">，已支付的平台费用不予退还，并承担由此给平台造成的一切损失。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、原始</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">违反</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议</span><span
                style="font-family:微软雅黑; font-size:12pt">第</span><span
                style="font-family:微软雅黑; font-size:12pt">五</span><span
                style="font-family:微软雅黑; font-size:12pt">条“承诺和保证”中的任一事项，或违反本协议其他约定义务的事项的，构成原始</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人违约</span><span
                style="font-family:微软雅黑; font-size:12pt">，已支付的平台费用不予退还，并承担由此给平台造成的一切损失。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、守约方有权要求违约方承担根据法律、法规的规定承担违约责任的权利，但该其他违约责任的承担将与本条的约定相抵触的情况除外。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">九</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、适用法律及争议的解决</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span
                style="font-family:微软雅黑; font-size:12pt">、</span><span
                style="font-family:微软雅黑; font-size:12pt">因履行本协议发生的争议应通过协商解决，协商不成的，任何一方均有权将争议提交上海国际经济贸易仲裁委员会，按照提交仲裁时该会现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对各方均有约束力。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">3、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">当产生任何争议及任何争议正按前条规定进行解决时，除争议事项外，双方应继续行使本协议项下的其他权利，履行本协议项下的其他义务。如本协议中的任何条款被认定为全部或部分不合法、无效或不可强制执行，并不影响其他条款的有效和执行。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">十、其他</span></p>
        <p style="background-color:#ffffff; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">除本协议另有约定外，本协议列明的联系地址及联系电话为开单人/</span><span
                style="font-family:微软雅黑; font-size:12pt">原始持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">有效送达地址；除此之外，其他有效送达地址及送达方式应以</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">第十二条第一款为准。</span></p>
        <p style="background-color:#ffffff; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议自</span><span
                style="font-family:微软雅黑; font-size:12pt">开单人及</span><span
                style="font-family:微软雅黑; font-size:12pt">原始持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">双方加盖公章（包括电子签章形式）</span><span
                style="font-family:微软雅黑; font-size:12pt">之日起成立并生效</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">自此对双方均具有约束力</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">、本协议未尽之事宜，如</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子</span><span
                style="font-family:微软雅黑; font-size:12pt">凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">已有约定的，适用</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的约定</span><span
                style="font-family:微软雅黑; font-size:12pt">；</span><span
                style="font-family:微软雅黑; font-size:12pt">如</span><span
                style="font-family:微软雅黑; font-size:12pt">与</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用优企通产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">不一致或有冲突的，应以本协议为准</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">4</span><span
                style="font-family:微软雅黑; font-size:12pt">、双方应秉承诚实信用之原则，采取一切必要的行为以确保本协议之生效及获完全执行，并避免采取任何与本协议条款不一致的行为。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">5</span><span
                style="font-family:微软雅黑; font-size:12pt">、除非本协议另有约定，本协议生效后，任何一方不得单方面变更或解除本协议；对本协议的任何修改或变更必须经协议各方协商一致，并达成书面协议。</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">6</span><span
                style="font-family:微软雅黑; font-size:12pt">、</span><span
                style="font-family:微软雅黑; font-size:12pt">如</span><span
                style="font-family:微软雅黑; font-size:12pt">通过</span><span
                style="font-family:微软雅黑; font-size:12pt">经证供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">以电子文本形式制成</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">，可以有一份或者多份并且每一份具有同等法律效力，双方均认可该形式的协议效力。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">7、</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议各方签署日期以电子签章实际所携带的落款签名时间为准。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">本协议签署日期以最后一方电子签章实际所携带的落款签名时间为准。</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">以下无正文</span><span
                style="font-family:微软雅黑; font-size:12pt">）</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="color:#222222; font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:24pt"><span
                style="color:#222222; font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:42pt"><span
                style="font-family:微软雅黑; font-size:12pt">开单人：  {{signAgreementDetail&&signAgreementDetail.openCorpName? signAgreementDetail.openCorpName :'' }}                      原始持单人：</span><span style="font-size:12pt">{{signAgreementDetail&&signAgreementDetail.receiveCorpName? signAgreementDetail.receiveCorpName :'' }}</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt"> （盖章）                      </span><span :style="stylelist" style="font-size:12pt">（盖章）</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt;line-height:23pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
    </div>
</template>

<script>
import moment from 'moment'
export default {
        data(){
                return {
                        margin:'80px'
                }
        },
        props:{
                signAgreementDetail:Object,
                amount:String,
                promisePayTime: String || Date
                
        },
        computed: {
                stylelist() {
                        if(this.signAgreementDetail&&this.signAgreementDetail.openCorpName) {
                        return {'margin-left':this.margin}
                        }
                }
        },
        methods:{
                moment
        }
}
</script>
