<template>
  <div class="content">
    <div class="card-hd infotitle">
      应收账款保理签约
      <el-button class="page-head-back" size="small" @click="goBack">返回</el-button>
    </div>

    <!-- <steps :stepList="stepList" :current="current"></steps> -->

    <section class="info-card-A" style="padding-top: 32px;">
      <div class="card-hd sub-title">
        <span class="txbst">应收账款保理融资合同</span>
      </div>
      <div class="card-bd card-bd-row">
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">甲方:</span>
            <span class="info-value">{{fundCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{fundCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{fundCorp.legalName}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">乙方:</span>
            <span class="info-value index">{{financeCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{financeCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{financeCorp.legalName}}</span>
          </el-col>
        </el-row>
        <div class="card-line"></div>
      </div>
      <div class="card-hd sub-title">
        <span class="txbst">合同信息</span>
      </div>
      <div class="maincontin">
        <pdf v-if="payableBillFinanceFile.fileUrl"
          class="pdf"
          :src="api.getImgURL(payableBillFinanceFile.fileUrl)"
          :page="currentPage"
          @num-pages="pageCount=$event"
          @page-loaded="currentPage=$event"
          @loaded="loadPdfHandler"
        ></pdf>
      </div>
      <el-row>
        <el-col :span="10" class="lb_body">
          <span class="info-key"></span>
          <span class="info-value"></span>
        </el-col>
        <el-col :span="8" class="lb_body">
          <p>
            <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">上一页</span>
            {{currentPage}} / {{pageCount}}
            <span
              @click="changePdfPage(1)"
              class="turn"
              :class="{grey: currentPage==pageCount}"
            >下一页</span>
          </p>
        </el-col>
        <el-col :span="6" class="lb_body">
          <span class="info-key"></span>
          <span class="info-value"></span>
        </el-col>
      </el-row>
      <div class="card-hd" style="height: 150px;" v-if="$route.query.isshowbtn==1">
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <el-checkbox v-model="checked">我已阅读并完全了解合同的所有义务和权利</el-checkbox>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
          <el-col :span="8" :offset="1" class="lb_body">
            <el-button
              type="primary"
              style="width:238px;margin-top: 10px; "
              @click.native="submit"
            >电子签章签约</el-button>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/api";
import { baseURL } from "@/api/sys";
import pdf from "vue-pdf";
import steps from "@/views/base/steps.vue";

export default {
  components: {
    pdf,
    steps
  },
  data() {
    return {
      financInfo: {}, //基本信息
      financInfoFinance: {},
      baseURL: baseURL,
      api: api,
      payableBillFinanceId: "",
      financeCorp: {},
      fundCorp: {},
      payableBillFinanceFile: {
          fileUrl:''
      },
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "", // pdf文件地址
      checked: false,
      payableBillFinanceNo: "", //融资编号
      financeCorpId: "", //融资方Id
      checkCode: "", //验证码
      stepList: [
        {
          name: "融资申请",
          realName: "",
          createTime: ""
        },
        {
          name: "融资审核",
          realName: "",
          createTime: ""
        },
        {
          name: "平台审核",
          realName: "",
          createTime: ""
        },
        {
          name: "资方审核",
          realName: "",
          createTime: ""
        },
        {
          name: "融资签约",
          realName: "",
          createTime: ""
        },
        {
          name: "融资放款",
          realName: "",
          createTime: ""
        }
      ],
      current: "",
      
    };
  },
  created() {
    //有id 才是查看合同 没有 就是获取合同模版
    // this.current = 5;
    // if (
    //   this.$route.query.payableBillFinanceId &&
    //   this.$route.query.payableBillFinanceNo &&
    //   this.$route.query.financeCorpId
    // ) {
    //   this.payableBillFinanceId = this.$route.query.payableBillFinanceId;
    //   this.payableBillFinanceNo = this.$route.query.payableBillFinanceNo;
    //   this.financeCorpId = this.$route.query.financeCorpId;

    //   console.log("111111");
    // } else {
    //   console.log("222222");
    // }
      this.getSigninfo();
    //融资
    if (this.$route.query.financePayStatus === 3) {
      //融资申请－融资审核－平台审核－资方审核－融资签约－融资放款
      this.current = 6;
    } else if (this.$route.query.financeSignStatus === 50) {
      //融资申请－融资审核－平台审核－资方审核－融资签约
      this.current = 5;
    } else if (
      this.$route.query.financeSignStatus >= 20 ||
      this.$route.query.approveStatus >= 55
    ) {
      //融资申请－融资审核－平台审核－资方审核
      this.current = 4;
    } else if (this.$route.query.approveStatus > 43) {
      //融资申请－融资审核－平台审核
      this.current = 3;
    } else if (this.$route.query.approveStatus > 22) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus > 20) {
      //融资申请
      this.current = 1;
    }
  },
  mounted() {},
  methods: {
    moment: moment,

    getSigninfo() {
      var  a=1
        if(JSON.parse(sessionStorage.user).corpType==1){
                a=2
              }
      api
        .billgetSignContract({
          billId: this.$route.query.billId,
          signSource:a
        })
        .then(res => {
          console.log("res ===>", res.datas);
          if (res.success) {
            this.financeCorp = res.datas.financeCorp;
            this.fundCorp = res.datas.fundCorp;
            this.payableBillFinanceFile = res.datas.financeFile
            // this.src = baseURL + this.payableBillFinanceFile.fileUrl;

            //获取文件流
          }
        });
    },
    getFiles() {},
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },

    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },

    submit() {
      if (this.checked) {
        //电子签约
        
              let param = {
                billId: this.$route.query.billId,
                signSource: 1, //1 融资企业  2  资方
              };
              if(JSON.parse(sessionStorage.user).corpType==1){
                  param.signSource=2
              }
              api.billfinanceSig(param).then(res => {
                if (res.success) {
                    this.$alert(res.message, '', {
                    confirmButtonText: '确定',
                    callback: action => {
                          history.go(-1);
                    }
                  });   
                } else {
                  this.$message.error(res.message);
                }
              });
     
      } else {
        this.$message.error("请勾选协议");
      }
    }
  }
};
</script>

<style lang='scss' scoped>

.content {
  width: 100%;
  height: 100%;
  background-color: white;

  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  .img-slide {
    background: #4390ee;
    width: 192px;
    height: 144px;
    margin-left: 20px;
  }
  .swiper-slide {
    line-height: 144px;
    color: #fff;
    font-size: 36px;
    text-align: center;
  }
  .row {
    height: 45px;
    .el-input {
      width: 90%;
    }
  }
  .pdf {
    display: block !important;
    max-width: 1000px !important;
    margin: auto !important;
  }
}
</style>
