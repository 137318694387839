<template>
  <div>
      <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <guaranteeapprovalfirst v-if="showtab==1"></guaranteeapprovalfirst>
            <guaranteeapprovalsecond v-if="showtab==2"></guaranteeapprovalsecond>

            
    
    </layout-main-view>


  </div>
</template>
<script>
  import api from "@/api/api";
import guaranteeapprovalfirst from "./guaranteeapprovalfirst";
import guaranteeapprovalsecond from "./guaranteeapprovalsecond";





export default {
    components: {
    guaranteeapprovalfirst,
    guaranteeapprovalsecond

  },
  data() {
    return{
      breadcrumblist: ["优企通担保审批"],
          tabOptions: [
        { label: "待担保", value: "1", path: "/gteasypay/guaranteeapprovalmain/1" },
        { label: "已担保", value: "2", path: "/gteasypay/guaranteeapprovalmain/2" },
      ],
            showtab:1
    }
  },
  created() {
    console.log(this.$route)
    this.showtab=this.$route.params.state
  },
  methods: {
     tabclick(val) {
  this.showtab=val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">

</style>
