export function LeftNavfn(type, isChainCorp) {
  // 1资金机构 2.核心企业 3.一般企业  6.担保企业'
  // isChainCorp 1:核心+链属 0: 其他
  // adminRole [] 必须添加字段 默认为[ *_admin]
  /**
   * admin: 管理员  first: 审批员   agent: 经办人
   * adminRole: ['core_admin','core_first','core_agent' ] 核心企业权限
   * adminRole: ['general_admin','general_first','general_agent' ] 链属企业权限
   *
   * admin: 管理员  first: 初审审员   second: 复审员   third：终审员
   * adminRole: ['fund_admin','fund_first','fund_second', 'fund_third' ] 资金端权限
   *
   * admin: 管理员  audit: 审核人员  agent: 经办人
   * adminRole: [' general_core_admin','general_core_audit','general_core_agent'] 核心+链属权限
   *
   */
  if (isChainCorp === 0) {
    if (type == 1) {
      return [
        {
          name: "工作台",
          path: "/workbench",
          index: 1,
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
        },
        {
          name: "优企通",
          path: "/",
          index: 1,
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
          children: [
            {
              name: "授信申请管理",
              iconCls: "fa fa-pencil-square",
              adminRole: ["fund_admin", "fund_first"],
              children: [
                {
                  name: "授信申请",
                  path: "/fund/credit/apply",
                  iconCls: "fa fa-magic",
                  adminRole: ["fund_admin"],
                },
                {
                  name: "授信复核",
                  path: "/fund/credit/review/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["fund_admin", "fund_first"],
                },
                {
                  name: "授信查看",
                  path: "/fund/business/authorizationManage/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["fund_admin"],
                },
              ],
            },
            //此处是新增业务内容列表2020年1月4日。
            // { name: '优企通授信管理',path: '/fund/business/authorizationManage/1?pageSize=10&pageNum=1',iconCls: 'fa fa-magic', adminRole: ['fund_admin','fund_first','fund_second', 'fund_third' ] },
            {
              name: "融资项目管理",
              adminRole: ["fund_admin"],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "融资项目信息",
                  path: "/fund/easypaySign/financingSign/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["fund_admin"],
                },
                {
                  name: "融资签约管理",
                  path: "/fund/easypaySign/financingProject?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["fund_admin"],
                },
              ],
            },
            {
              name: "优企通开立审批",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "开立初审",
                  path: "/fund/easypay/openapproval/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_first"],
                },
                {
                  name: "开立复审",
                  path: "/fund/easypay/openapproval/second/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_second"],
                },
                {
                  name: "开立终审",
                  path: "/fund/easypay/openapproval/three/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_third"],
                },
              ],
            },
            {
              name: "优企通融资审批",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "融资初审",
                  path: "/fund/easypayFinancing/financingApprove/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_first"],
                },
                {
                  name: "融资复审",
                  path: "/fund/easypayFinancing/financingApprove/second/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_second"],
                },
                {
                  name: "融资终审",
                  path: "/fund/easypayFinancing/financingApprove/three/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_third"],
                },
              ],
            },
            {
              name: "优企通清分管理",
              iconCls: "fa fa-pencil-square",
              adminRole: ["fund_admin", "fund_first"],
              children: [
                {
                  name: "清分审批",
                  path: "/easypay/distinguishingapprovalfix/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin", "fund_first"],
                },
                {
                  name: "应收清分",
                  path: "/fund/easypay/clearCloseManage/1?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin"],
                },
              ],
            },
            //此处是新增业务2020年2月11日
            {
              name: "对账单管理",
              path: "/easypay/statementManage/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-magic",
              adminRole: ["fund_admin"],
            },
            {
              name: "合同管理",
              adminRole: ["fund_admin"],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "融资合同管理",
                  path: "/easypay/contractAgreementManage?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin"],
                },
                {
                  name: "承诺付款协议",
                  path: "/easypay/cloudCreditAgreementManage?pageSize=10&pageNum=1",
                  adminRole: ["fund_admin"],
                },
              ],
            },
            //此处是新增业务2020年1月7日
            {
              name: "企业目录",
              path: "/fund/businessDirectory/businessMange/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-magic",
              adminRole: ["fund_admin"],
            },
            {
              name: "上链信息",
              path: "/easypay/onChainInformation",
              iconCls: "fa fa-magic",
              adminRole: ["fund_admin"],
            },
          ],
        },
        {
          name: "易保理",
          path: "/",
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
          children: [
            {
              name: "保理融资管理",
              path: "/fund/factoring/FundFactoringManageIndex/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            // { name: '保理融资审批', path: '/fund/factoring/FundFinancingApproveIndex/1?pageSize=10&pageNum=1', iconCls: 'fa fa-home' },
            {
              name: "融资补件审批",
              path: "/factoring/approvalsupplementsmain/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "融资放款管理",
              path: "/fund/factoring/FundFinancingLoan/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
          ],
        },
        {
          name: "易票据",
          path: "/",
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
          children: [
            {
              name: "业务统计",
              path: "/bill/bill-statistics",
              iconCls: "fa fa-home",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "票据中心",
              path: "/bill/billcenterzj",
              iconCls: "fa fa-sign-language",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "我的票据",
              path: "/bill/billmanagezj/1",
              iconCls: "fa fa-magic",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "票据审批",
              path: "/bill/pjcheckmanage/1",
              iconCls: "fa fa-bicycle",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "票据偏好设置",
              path: "/bill/bill-preference",
              iconCls: "fa fa-home",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
          ],
        },
        {
          name: "企业管理",
          path: "/",
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
          iconCls: "fa fa-pencil-square",
          children: [
            {
              name: "企业用户管理",
              path: "/Business/user_management?pageSize=10&pageNum=1",
              adminRole: ["fund_admin"],
            },
            {
              name: "银行账户管理",
              path: "/Business/Bank_management?pageSize=10&pageNum=1",
              adminRole: ["fund_admin"],
            },
            {
              name: "融资资料配置",
              path: "/Business/Data_config",
              adminRole: ["fund_admin"],
            },
            {
              name: "资金账户管理",
              path: "/Business/accountManagement",
              adminRole: ["fund_admin"],
            },
            {
              name: "站内信管理",
              path: "/Business/message",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "企业信息管理",
              path: "/Business/enterpriseInfo",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
          ],
        },
        {
          name: "E-ABS",
          path: "/",
          adminRole: ["fund_admin", "fund_first", "fund_second", "fund_third"],
          children: [
            {
              name: "业务统计",
              path: "/abs/absbusiness",
              iconCls: "fa fa-map-o",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },
            {
              name: "专项计划管理",
              path: "/abs/absplan/1",
              iconCls: "fa fa-puzzle-piece",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
            },

            {
              name: "资产审批管理",
              adminRole: [
                "fund_admin",
                "fund_first",
                "fund_second",
                "fund_third",
              ],
              iconCls: "fa fa-home",
              children: [
                {
                  name: "资产初审",
                  path: "/fund/abs/assetsCheck/check/1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "fund_admin",
                    "fund_first",
                    "fund_second",
                    "fund_third",
                  ],
                },
                {
                  name: "资产复审",
                  path: "/fund/abs/assetsCheck/recheck/1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "fund_admin",
                    "fund_first",
                    "fund_second",
                    "fund_third",
                  ],
                },
                {
                  name: "资产终审",
                  path: "/fund/abs/assetsCheck/finalCheck/1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "fund_admin",
                    "fund_first",
                    "fund_second",
                    "fund_third",
                  ],
                },
              ],
            },
          ],
        },
      ];
    } else if (type == 2) {
      return [
        {
          name: "工作台",
          path: "/workbench",
          index: 1,
          adminRole: ["core_admin", "core_first", "core_agent"],
        },
        {
          name: "优企通",
          path: "/workbench2",
          index: 1,
          adminRole: ["core_admin", "core_first", "core_agent"],
          children: [
            {
              name: "额度管理",
              path: "/easypay/amountmange/1?pageNum=1&pageSize=10",
              iconCls: "fa fa-money",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "供应商额度",
              iconCls: "fa fa-money",
              adminRole: ["core_admin", "core_first", "core_agent"],
              children: [
                {
                  name: "额度记录",
                  path: "/easypay/supplier-quota/1?pageNum=1&pageSize=10",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "额度复审",
                  path: "/easypay/supplier-quota-audit/1?pageNum=1&pageSize=10",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
              ],
            },
            {
              name: "优企通管理",
              path: "/easypay/mange/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-credit-card-alt",
              adminRole: ["core_admin", "core_agent"],
            },
            {
              name: "优企通开立",
              path: "/easypay/toopen",
              iconCls: "fa fa-clipboard",
              adminRole: ["core_admin", "core_agent"],
            },
            {
              name: "优企通审批",
              iconCls: "fa fa-pencil-square",
              adminRole: ["core_admin", "core_first"],
              children: [
                {
                  name: "开立审批",
                  path: "/easypay/openapproval/1?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_first"],
                },
                {
                  name: "邀请开单审批",
                  path: "/easypay/openrequestapproval/1?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_first"],
                },
                // { name: '融资审批', path: '/easypayApproveManage/easypayFinancing/financingApprove/1?pageSize=10&pageNum=1',adminRole: ['core_admin','core_first' ]},
              ],
            },
            // { name: '融资项目管理', path: '/financingProjectManage/easypaySign/financingSign/1?pageSize=10&pageNum=1', iconCls: 'fa fa-magic', adminRole: ['core_admin','core_first', 'core_agent' ] },
            {
              name: "优企通清分",
              iconCls: "fa fa-pencil-square",
              adminRole: ["core_admin", "core_first", "core_agent"],
              children: [
                {
                  name: "清分审批",
                  path: "/easypay/distinguishingapprovalfix/1?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_first"],
                },
                {
                  name: "清分管理",
                  path: "/easypay/distinguishingmangenew/1?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_agent"],
                },
                {
                  name: "清分查询",
                  path: "/easypay/clearsearch/1?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_agent"],
                },
                // { name: '应收清分', path: '/easypay/lsdistinguishingmange/1?pageSize=10&pageNum=1' }
                //  { name: '持有清分审批', path: '/easypay/distinguishingapproval/1?pageSize=10&pageNum=1' },
                //  { name: '资金清分审批', path: '/easypay/fundDistinguishingapproval/1?pageSize=10&pageNum=1' },
                // { name: '应付持有清分', path: '/easypay/distinguishingmange/1?pageSize=10&pageNum=1' },
                // { name: '应付资金清分', path: '/easypay/zjdistinguishingmange/1?pageSize=10&pageNum=1' },
              ],
            },
            //此处是新增业务2020年2月11日
            {
              name: "对账单管理",
              path: "/easypay/statementManage/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-magic",
              adminRole: ["core_admin", "core_agent"],
            },
            {
              name: "合同管理",
              iconCls: "fa fa-pencil-square",
              adminRole: ["core_admin", "core_agent"],
              children: [
                {
                  name: "应收账款转让通知书",
                  path: "/easypay/couldAcountTransferInfo?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_agent", "core_first"],
                },
                {
                  name: "承诺付款协议",
                  path: "/easypay/cloudCreditAgreementManage?pageSize=10&pageNum=1",
                  adminRole: ["core_admin", "core_agent"],
                },
                {
                  name: "开单协议",
                  path: "/easypay/openAgreement?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["core_admin", "core_agent"],
                },
                {
                  name: "转让协议",
                  path: "/easypay/transferAgreement?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["core_admin", "core_agent"],
                },
              ],
            },
            {
              name: "上链信息",
              path: "/easypay/onChainInformation",
              iconCls: "fa fa-magic",
              adminRole: ["core_admin", "core_agent"],
            },
          ],
        },
        {
          name: "企业管理",
          path: "/",
          iconCls: "fa fa-pencil-square",
          adminRole: ["core_admin", "core_first", "core_agent"],
          children: [
            {
              name: "链属企业管理",
              path: "/Business/Business_management?pageSize=10&pageNum=1",
              adminRole: ["core_admin", "core_agent"],
            },
            {
              name: "银行账户管理",
              path: "/Business/Bank_management?pageSize=10&pageNum=1",
              adminRole: ["core_admin"],
            },
            {
              name: "用户管理",
              path: "/Business/user_management?pageSize=10&pageNum=1",
              adminRole: ["core_admin"],
            },
            {
              name: "企业信息管理",
              path: "/Business/enterpriseInfo",
              adminRole: ["core_admin", "core_agent"],
            },
            {
              name: "资金账户管理",
              path: "/Business/accountManagement",
              adminRole: ["core_admin"],
            },
            {
              name: "站内信管理",
              path: "/Business/message",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "邮寄地址管理",
              path: "/Business/address?pageSize=10&pageNum=1",
              adminRole: ["core_admin", "core_agent"],
            },
          ],
        },
        {
          name: "易保理",
          path: "/",
          adminRole: ["core_admin", "core_first", "core_agent"],
          children: [
            // { name: '首页', path: '/factoring/home', iconCls: 'fa fa-home' },
            {
              name: "应收账款管理",
              path: "/factoring/accountsreceivablemanage/1?pageNum=1&pageSize=10",
              iconCls: "fa fa-sign-language",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "保理融资申请",
              path: "/factoring/createfinancing",
              iconCls: "fa fa-money",
            },
            {
              name: "保理融资管理",
              path: "/factoring/FactoringManageIndex/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "审批管理",
              iconCls: "fa fa-sitemap",
              adminRole: ["core_admin", "core_first", "core_agent"],
              children: [
                {
                  name: "应收账款审批",
                  path: "/factoring/ReceivableIndexIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "融资补件审批",
                  path: "/factoring/approvalsupplementsmain/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "债务方审批",
                  path: "/factoring/CreditorsRightIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "保理融资审批",
                  path: "/factoring/FinacingApprovalIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "融资提现审批",
                  path: "/factoring/cashCheck/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "优企通转换审批",
                  path: "/factoring/transCheck/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
              ],
            },
            {
              name: "融资确权管理",
              path: "/factoring/AffirmcreditorRightIndex/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
          ],
        },
        {
          name: "易票据",
          path: "/",
          adminRole: ["core_admin", "core_first", "core_agent"],
          children: [
            {
              name: "业务统计",
              path: "/bill/bill-statistics",
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "票据中心",
              path: "/bill/billcenter",
              iconCls: "fa fa-sign-language",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "票据管理",

              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
              children: [
                {
                  name: "我的票据",
                  path: "/bill/billmanage/1",
                  iconCls: "fa fa-magic",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "发布票据",
                  path: "/bill/release-bill",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
              ],
            },
            {
              name: "票据审批管理",
              path: "/bill/bill-manage/1",
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "票据白名单设置",
              path: "/bill/bill-preference",
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
          ],
        },
        {
          name: "E-ABS",
          path: "/",
          adminRole: ["core_admin", "core_first", "core_agent"],
          children: [
            {
              name: "业务统计",
              path: "/abs/absbusiness",
              iconCls: "fa fa-map-o",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "专项计划管理",
              path: "/abs/absplan/1",
              iconCls: "fa fa-puzzle-piece",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "账款管理",
              path: "/abs/accountsManage",
              iconCls: "fa fa-puzzle-piece",
              adminRole: ["core_admin", "core_first", "core_agent"],
            },
            {
              name: "账款审批管理",
              // path: '/#/',
              iconCls: "fa fa-home",
              adminRole: ["core_admin", "core_first", "core_agent"],
              children: [
                {
                  name: "自身账款审批",
                  path: "/abs/accountsCheck/publisher/1",
                  iconCls: "fa fa-magic",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
                {
                  name: "外部账款审批",
                  path: "/abs/accountsCheck/acceptor/1",
                  iconCls: "fa fa-home",
                  adminRole: ["core_admin", "core_first", "core_agent"],
                },
              ],
            },
          ],
        },
      ];
    } else if (type == 3) {
      return [
        {
          name: "工作台",
          path: "/workbench",
          index: 1,
          adminRole: ["general_admin", "general_first", "general_agent"],
        },
        {
          name: "优企通",
          path: "/workbench2",
          index: 1,
          adminRole: ["general_admin", "general_first", "general_agent"],
          children: [
            // { name: '首页', path: '/easypay/easypaymessage', iconCls: 'fa fa-home' },
            {
              name: "优企通管理",
              path: "/easypay/mange/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-credit-card-alt",
              adminRole: ["general_admin", "general_agent"],
            },
            {
              name: "优企通邀请开单",
              iconCls: "fa fa-street-view",
              parentId: 0,
              id: 2,
              adminRole: ["general_admin", "general_agent"],
              children: [
                {
                  name: "邀请开单",
                  path: "/easypay/lseasypaitoopen",
                  iconCls: "fa fa-street-view",
                  adminRole: ["general_admin", "general_agent"],
                },
                {
                  name: "邀请开单管理",
                  path: "/easypay/lseasypaitoopenmange/1?pageSize=10&pageNum=1",
                  adminRole: ["general_admin", "general_agent"],
                },
              ],
            },
            {
              name: "优企通审批",
              iconCls: "fa fa-pencil-square",
              parentId: 0,
              id: 2,
              adminRole: ["general_admin", "general_first"],
              children: [
                {
                  name: "优企通转让审批",
                  path: "/easypay/openapproval/1?pageSize=10&pageNum=1",
                  adminRole: ["general_admin", "general_first"],
                },
                {
                  name: "优企通融资审批",
                  path: "/easypayApproveManage/easypayFinancing/financingApprove/1?pageSize=10&pageNum=1",
                  adminRole: ["general_admin", "general_first"],
                },
              ],
            },
            {
              name: "融资项目管理",
              path: "/financingProjectManage/easypaySign/financingSign/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-magic",
              adminRole: ["general_admin", "general_agent"],
            },
            {
              name: "清分管理",
              adminRole: ["general_admin", "general_first", "general_agent"],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "应收清分",
                  path: "/easypay/lsdistinguishingmange/1?pageSize=10&pageNum=1",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
              ],
            },

            //此处是新增业务2020年2月11日
            {
              name: "对账单管理",
              path: "/easypay/statementManage/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-magic",
              adminRole: ["general_admin", "general_agent"],
            },
            //此处是新增业务2020年2月11日
            {
              name: "合同管理",
              adminRole: ["general_admin", "general_agent"],
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "融资合同管理",
                  path: "/easypay/contractAgreementManage?pageSize=10&pageNum=1",
                  adminRole: ["general_admin", "general_agent"],
                },
                {
                  name: "承诺付款协议",
                  path: "/easypay/cloudCreditAgreementManage?pageSize=10&pageNum=1",
                  adminRole: ["general_admin"],
                },
                {
                  name: "收费协议管理",
                  path: "/easypay/agreementManage?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["general_admin", "general_agent"],
                },
                {
                  name: "转让通知书协议",
                  path: "/easypay/transfercontractlist?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["general_admin", "general_agent"],
                },
                {
                  name: "开单协议",
                  path: "/easypay/openAgreement?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["general_admin", "general_agent"],
                },
                {
                  name: "转让协议",
                  path: "/easypay/transferAgreement?pageSize=10&pageNum=1",
                  iconCls: "fa fa-magic",
                  adminRole: ["general_admin", "general_agent"],
                },
              ],
            },
            {
              name: "上链信息",
              path: "/easypay/onChainInformation",
              iconCls: "fa fa-magic",
              adminRole: ["general_admin"],
            },
          ],
        },
        {
          name: "易保理",
          path: "/",
          adminRole: ["general_admin", "general_first", "general_agent"],
          children: [
            // { name: '首页', path: '/factoring/home', iconCls: 'fa fa-home' },
            // {name:'应收账款管理',path:'/factoring/accountsreceivablemanage/1?pageNum=1&pageSize=10',iconCls:'fa fa-sign-language'},
            {
              name: "应收账款管理",
              path: "/factoring/accountsreceivablemanage/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-sign-language",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "保理融资管理",
              path: "/factoring/FactoringManageIndex/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "保理融资申请",
              path: "/factoring/createfinancing",
              iconCls: "fa fa-money",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "审批管理",
              adminRole: ["general_admin", "general_first", "general_agent"],
              iconCls: "fa fa-sitemap",
              children: [
                {
                  name: "应收账款审批",
                  path: "/factoring/ReceivableIndexIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "融资补件审批",
                  path: "/factoring/approvalsupplementsmain/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "债务方审批",
                  path: "/factoring/CreditorsRightIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "保理融资审批",
                  path: "/factoring/FinacingApprovalIndex/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "融资提现审批",
                  path: "/factoring/cashCheck/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "优企通转换审批",
                  path: "/factoring/transCheck/1?pageSize=10&pageNum=1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
              ],
            },
            {
              name: "融资确权管理",
              path: "/factoring/AffirmcreditorRightIndex/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-home",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
          ],
        },
        {
          name: "企业管理",
          path: "/",
          iconCls: "fa fa-pencil-square",
          adminRole: ["general_admin", "general_agent"],
          children: [
            {
              name: "链属企业管理",
              path: "/Business/Business_management?pageSize=10&pageNum=1",
              adminRole: ["general_admin", "general_agent"],
            },
            {
              name: "银行账户管理",
              path: "/Business/Bank_management?pageSize=10&pageNum=1",
              adminRole: ["general_admin"],
            },
            {
              name: "用户管理",
              path: "/Business/user_management?pageSize=10&pageNum=1",
              adminRole: ["general_admin"],
            },
            {
              name: "企业信息管理",
              path: "/Business/enterpriseInfo",
              adminRole: ["general_admin", "general_agent"],
            },
            {
              name: "资金账户管理",
              path: "/Business/accountManagement",
              adminRole: ["general_admin"],
            },
            {
              name: "站内信管理",
              path: "/Business/message",
              adminRole: ["general_admin", "general_agent"],
            },
            {
              name: "邮寄地址管理",
              path: "/Business/address?pageSize=10&pageNum=1",
              adminRole: ["general_admin", "general_agent"],
            },
          ],
        },
        {
          name: "易票据",
          path: "/",
          adminRole: ["general_admin", "general_first", "general_agent"],
          children: [
            {
              name: "业务统计",
              path: "/bill/bill-statistics",
              iconCls: "fa fa-home",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "票据中心",
              path: "/bill/billcenter",
              iconCls: "fa fa-sign-language",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "票据管理",
              adminRole: ["general_admin", "general_first", "general_agent"],
              iconCls: "fa fa-home",
              children: [
                {
                  name: "我的票据",
                  path: "/bill/billmanage/1",
                  iconCls: "fa fa-magic",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "发布票据",
                  path: "/bill/release-bill",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
              ],
            },
            {
              name: "票据审批管理",
              path: "/bill/bill-manage/1",
              iconCls: "fa fa-home",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "票据白名单设置",
              path: "/bill/bill-preference",
              iconCls: "fa fa-home",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
          ],
        },
        {
          name: "E-ABS",
          path: "/",
          adminRole: ["general_admin", "general_first", "general_agent"],
          children: [
            {
              name: "业务统计",
              path: "/abs/absbusiness",
              iconCls: "fa fa-map-o",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },

            {
              name: "专项计划管理",
              path: "/abs/absplan/1",
              iconCls: "fa fa-puzzle-piece",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "账款管理",
              path: "/abs/accountsManage",
              iconCls: "fa fa-puzzle-piece",
              adminRole: ["general_admin", "general_first", "general_agent"],
            },
            {
              name: "账款审批管理",
              adminRole: ["general_admin", "general_first", "general_agent"],
              iconCls: "fa fa-home",
              children: [
                {
                  name: "自身账款审批",
                  path: "/abs/accountsCheck/publisher/1",
                  iconCls: "fa fa-magic",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
                {
                  name: "外部账款审批",
                  path: "/abs/accountsCheck/acceptor/1",
                  iconCls: "fa fa-home",
                  adminRole: [
                    "general_admin",
                    "general_first",
                    "general_agent",
                  ],
                },
              ],
            },
          ],
        },
      ];
    } else if (type == 6) {
      return [
        { name: "工作台", path: "/workbench", index: 1 },
        {
          name: "优企通",
          path: "/workbench2",
          index: 1,
          children: [
            {
              name: "担保优企通管理",
              path: "/dbeasypay/mange/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-american-sign-language-interpreting",
            },
            {
              name: "担保额度管理",
              path: "/gteasypay/amountmange/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-assistive-listening-systems",
            },
            {
              name: "优企通担保审核",
              path: "/gteasypay/guaranteeapprovalmain/1?pageSize=10&pageNum=1",
              iconCls: "fa fa-diamond",
            },
            {
              name: "直接清分管理",
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "应付持有清分",
                  path: "/gteasypay/gudistinguishingmange/1?pageSize=10&pageNum=1",
                },
                {
                  name: "应付资金清分",
                  path: "/gteasypay/guzjdistinguishingmange/1?pageSize=10&pageNum=1",
                },
              ],
            },
            {
              name: "逐级清分管理",
              iconCls: "fa fa-pencil-square",
              children: [
                {
                  name: "应付清分",
                  path: "/gteasypay/cascadeClearing/gudistinguishingmange/1?pageSize=10&pageNum=1",
                },
              ],
            },
          ],
        },
        {
          name: "企业管理",
          path: "/",
          iconCls: "fa fa-pencil-square",
          children: [
            {
              name: "渠道账户管理",
              path: "/Business/accountManagement",
              adminRole: true,
            },
            {
              name: "账务管理",
              path: "/Business/financialManagement",
              adminRole: true,
            },
            { name: "站内信管理", path: "/Business/message" },
            {
              name: "邮寄地址管理",
              path: "/Business/address?pageSize=10&pageNum=1",
            },
          ],
        },
        {
          name: "易票据",
          path: "/",
          children: [
            {
              name: "业务统计",
              path: "/bill/bill-statistics",
              iconCls: "fa fa-home",
            },
            {
              name: "票据中心",
              path: "/bill/billcenter",
              iconCls: "fa fa-sign-language",
            },
            {
              name: "票据管理",
              iconCls: "fa fa-home",
              children: [
                {
                  name: "我的票据",
                  path: "/bill/billmanage/1",
                  iconCls: "fa fa-magic",
                },
                {
                  name: "发布票据",
                  path: "/bill/release-bill",
                  iconCls: "fa fa-home",
                },
              ],
            },
            {
              name: "票据审批管理",
              path: "/bill/bill-manage/1",
              iconCls: "fa fa-home",
            },
            {
              name: "票据白名单设置",
              path: "/bill/bill-preference",
              iconCls: "fa fa-home",
            },
          ],
        },
      ];
    }
  } else {
    return [
      {
        name: "工作台",
        path: "/workbench",
        index: 1,
        adminRole: [
          "general_core_admin",
          "general_core_audit",
          "general_core_agent",
        ],
      },
      {
        name: "优企通",
        path: "/workbench2",
        index: 1,
        adminRole: [
          "general_core_admin",
          "general_core_audit",
          "general_core_agent",
        ],
        children: [
          {
            name: "额度管理",
            path: "/easypay/amountmange/1?pageNum=1&pageSize=10",
            iconCls: "fa fa-money",
            adminRole: [
              "general_core_admin",
              "general_core_audit",
              "general_core_agent",
            ],
          },
          {
            name: "优企通管理",
            path: "/easypay/mange/1?pageSize=10&pageNum=1",
            iconCls: "fa fa-credit-card-alt",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
          {
            name: "优企通邀请开单",
            iconCls: "fa fa-street-view",
            parentId: 0,
            id: 2,
            adminRole: ["general_core_admin", "general_core_agent"],
            children: [
              {
                name: "邀请开单",
                path: "/easypay/lseasypaitoopen",
                iconCls: "fa fa-street-view",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              {
                name: "邀请开单管理",
                path: "/easypay/lseasypaitoopenmange/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
            ],
          },
          {
            name: "优企通开立",
            path: "/easypay/toopen",
            iconCls: "fa fa-clipboard",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
          {
            name: "优企通审批",
            iconCls: "fa fa-pencil-square",
            adminRole: ["general_core_admin", "general_core_audit"],
            children: [
              {
                name: "优企通审批",
                path: "/easypay/openapproval/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_audit"],
              },
              {
                name: "邀请开单审批",
                path: "/easypay/openrequestapproval/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_audit"],
              },
              {
                name: "优企通融资审批",
                path: "/easypayApproveManage/easypayFinancing/financingApprove/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_audit"],
              },
              // { name: '优企通转让审批', path: '/easypay/openapproval1/1?pageSize=10&pageNum=1', adminRole: ['general_core_admin','general_core_audit'] },
            ],
          },
          {
            name: "融资管理",
            path: "/financingProjectManage/easypaySign/financingSign/1?pageSize=10&pageNum=1",
            iconCls: "fa fa-magic",
            adminRole: [
              "general_core_admin",
              "general_core_audit",
              "general_core_agent",
            ],
          },
          {
            name: "优企通清分",
            iconCls: "fa fa-pencil-square",
            adminRole: [
              "general_core_admin",
              "general_core_audit",
              "general_core_agent",
            ],
            children: [
              {
                name: "清分审批",
                path: "/easypay/distinguishingapprovalfix/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_audit"],
              },
              {
                name: "清分管理",
                path: "/easypay/distinguishingmangenew/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              // { name: '应收清分', path: '/easypay/lsdistinguishingmange/1?pageSize=10&pageNum=1', adminRole: ['general_core_admin','general_core_audit','general_core_agent'] },
              {
                name: "清分查询",
                path: "/easypay/clearsearch/1?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
            ],
          },
          //此处是新增业务2020年2月11日
          {
            name: "对账单管理",
            path: "/easypay/statementManage/1?pageSize=10&pageNum=1",
            iconCls: "fa fa-magic",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
          {
            name: "合同管理",
            iconCls: "fa fa-pencil-square",
            adminRole: ["general_core_admin", "general_core_agent"],
            children: [
              {
                name: "融资合同管理",
                path: "/easypay/contractAgreementManage?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              {
                name: "承诺付款协议",
                path: "/easypay/cloudCreditAgreementManage?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin"],
              },
              {
                name: "收费协议管理",
                path: "/easypay/agreementManage?pageSize=10&pageNum=1",
                iconCls: "fa fa-magic",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              {
                name: "应收账款转让通知书",
                path: "/easypay/couldAcountTransferInfo?pageSize=10&pageNum=1",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              {
                name: "转让通知书协议",
                path: "/easypay/transfercontractlist?pageSize=10&pageNum=1",
                iconCls: "fa fa-magic",
                adminRole: ["general_core_admin", "general_agent"],
              },
              {
                name: "开单协议",
                path: "/easypay/openAgreement?pageSize=10&pageNum=1",
                iconCls: "fa fa-magic",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
              {
                name: "转让协议",
                path: "/easypay/transferAgreement?pageSize=10&pageNum=1",
                iconCls: "fa fa-magic",
                adminRole: ["general_core_admin", "general_core_agent"],
              },
            ],
          },
          {
            name: "上链信息",
            path: "/easypay/onChainInformation",
            iconCls: "fa fa-magic",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
        ],
      },
      {
        name: "企业管理",
        path: "/",
        iconCls: "fa fa-pencil-square",
        adminRole: [
          "general_core_admin",
          "general_core_audit",
          "general_core_agent",
        ],
        children: [
          {
            name: "链属企业管理",
            path: "/Business/Business_management?pageSize=10&pageNum=1",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
          {
            name: "银行账户管理",
            path: "/Business/Bank_management?pageSize=10&pageNum=1",
            adminRole: ["general_core_admin"],
          },
          {
            name: "用户管理",
            path: "/Business/user_management?pageSize=10&pageNum=1",
            adminRole: ["general_core_admin"],
          },
          {
            name: "企业信息管理",
            path: "/Business/enterpriseInfo",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
          {
            name: "资金账户管理",
            path: "/Business/accountManagement",
            adminRole: ["general_core_admin"],
          },
          {
            name: "站内信管理",
            path: "/Business/message",
            adminRole: [
              "general_core_admin",
              "general_core_audit",
              "general_core_agent",
            ],
          },
          {
            name: "邮寄地址管理",
            path: "/Business/address?pageSize=10&pageNum=1",
            adminRole: ["general_core_admin", "general_core_agent"],
          },
        ],
      },
    ];
  }
}
