<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
     align='center'
          highlight-current-row

          style="width: 100%;"
        >
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="170"></el-table-column>
          <el-table-column prop="absPlanName" label="计划名称" min-width="170"  >
    
          </el-table-column>
          <el-table-column  label="计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'></el-table-column>
          <el-table-column prop="delistingCorpName" label="汇集起止日" min-width="210">
              <template slot-scope="scope">
  {{moment(scope.row.collectStartDate).format("YYYY-MM-DD")+'~'+moment(scope.row.collectEndDate).format("YYYY-MM-DD")}}
             
            </template>
          </el-table-column>
          <el-table-column  label="预计发行利率区间(%)"  min-width="190">
              <template slot-scope="scope">

  {{scope.row.preProfitInterestMin+'-'+scope.row.preProfitInterestMax}}
           </template>
          </el-table-column>
    

          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看签约信息</el-button>
              <el-button size="medium" type="text" @click="downloadpack(scope.row)" v-if="isbl">下载资产包</el-button>

            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'
function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
function downloadabs(url){

 var aLink = document.createElement("a");
    aLink.href = url
    
     var event = new MouseEvent("click");
    aLink.dispatchEvent(event)
}

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
            {
          label: "汇集起止日:",
          type: "daterange",
          field: ["collectStartDate", "collectEndDate"],
          size: "small"
        },

        {
          label: "项目名称: ",
          type: "input",
          field: "absPlanName",
          size: "small",
        },
      ],
      total: 0,
      listData: [],
      isbl:false
    };
  },
  created() {
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false
  },
  methods: {
    moment: moment,

    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
  param.absAccountSign=1
  param.leaderCorpType=this.isbl?1:2
      api.getAbsSignListPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        
        }
      });
    },
   handleEditinfo(row){
  
       

           this.$router.push({
         name: "abswaitsigngysinfo",
                    query: { id: row.absPlanId,absAccountSign:0,leaderCorpType:this.isbl?1:2,issign:1}
      });
    },
    downloadpack(row){
          api.assetPackageDownLoad({
           absPlanId: row.absPlanId,

         }).then(res => {
            if(res.success){
                var row=res.datas.absAssetFile
                if(!res.datas.acquireDataFlag){
                    this.$message.error(res.message)
                    return
                }
              downloadabs(api.downBrowser(row))
            }else{
              this.$message.error(res.message)
            }
         }).catch((err) => {
      })

    }

  }
};
</script>


