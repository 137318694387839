<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>
        <div class="absaccountpost trantext" v-if="this.$route.query.onLineShow == 1">
           
            <div class="maincontin">
                <pdf
                class="pdf"
                :src="api.getImgURL(payableBillFinanceFile.fileUrl)"
                :page="currentPage"
                @num-pages="pageCount=$event"
                @page-loaded="currentPage=$event"
                @loaded="loadPdfHandler"
                ></pdf>
            </div>
            <el-row>
                <el-col :span="10" class="lb_body">
                <span class="info-key"></span>
                <span class="info-value"></span>
                </el-col>
                <el-col :span="8" class="lb_body">
                <p>
                    <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">上一页</span>
                    {{currentPage}} / {{pageCount}}
                    <span
                    @click="changePdfPage(1)"
                    class="turn"
                    :class="{grey: currentPage==pageCount}"
                    >下一页</span>
                </p>
                </el-col>
                <el-col :span="6" class="lb_body">
                <span class="info-key"></span>
                <span class="info-value"></span>
                </el-col>
            </el-row>
        </div>
        <div class="absaccountpost trantext" v-if="this.$route.query.onLineShow === 0">
          
                <ul>
                    <li  v-for="(item,key) in datalist" :key='key'>
                        <img  :src="api.getImgURL(item.fileUrl)" alt="转让通知书" preview-text="文件"
                              :preview="key">
                        <div>{{item.fileName}}</div>
                    </li>
                </ul>
              
 
        </div>
        <div class="absstartbtn card-bottom">

      <div class="btnmar">
                <el-button class="card-btn"  @click="goback">返回</el-button>
        
                <el-button class="card-btn" @click="handleopen" type="primary" v-if="$route.query.ishz==1 && $route.query.toPdfPage == 2">确认并上传通知回执</el-button>

                <el-button class="card-btn" @click="showHandleopen" type="primary" v-if="$route.query.ishz==1 && $route.query.toPdfPage == 1">确认并上传通知回执</el-button>
      </div>
 

    
      </div>
        </layout-main-view>
 <el-dialog title='上传通知回执' v-if='showAddDialog' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='1200px' class="initiation">
              
        <el-upload
         class="upload-demo"
            :action="baseURL+'/web/file/uploadFile'"
            :headers='Authorization'
            list-type="picture"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
          

             drag   

        >
          <i class="el-icon-plus"></i>
         <div class="el-upload__text">上传通知回执</div>
</el-upload>


          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取 消</el-button>

              <el-button @click="uploadfn" type="primary">确 定</el-button>
          </div>
        </el-dialog>



        <el-dialog :visible.sync="showPact" :close-on-click-modal="false" :show-close="true" center  width="50%" class="img-dialog">
            <el-radio v-model="radio" label="1">上传回执通知书</el-radio>
            <!-- <el-radio v-model="radio" label="2">上传待签约合同</el-radio> -->
            <el-radio v-model="radio" label="3">线上签署回执通知书</el-radio>

            <div v-if="radio == 1" class="uploadfont wxtsk uploadContract">
                温馨提醒：确定已完成线下签约，请上传已签约合同附件。
            <br />
            </div>

            <div v-if="radio == 3" class="uploadfont wxtsk uploadContract">
                温馨提醒：将跳转到线上回执通知书签约页面，请观看注意事项并进行签约操作。合同附件格式PDF
            <br />
            </div>

            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button @click="showPact = false">取消</el-button>
                <el-button type="primary" @click="subMitPact">确定</el-button>
            </span>
        </el-dialog>





    </div>
</template>
<script>
    import api from "@/api/api";

    import  absyexthead from '@/views/absbasesign/absyexthead' 
    import { baseURL } from "@/api/sys";
    import pdf from "vue-pdf";
    

    export default {
        // components: {
        //     pdf
        // },
        data() {
            return {
                breadcrumblist:['资产转让通知'],
                fundCorp:{},
                financeCorp:{},
                datalist:[],//供应商,核心
                api,
                showAddDialog:false,
                baseURL,
                uploadpic:{
                            absAccountId:'',
                            absPlanId:this.$route.query.absPlanId,
                            absAccountFileVoList:[]                       
                 },
                Authorization:{Authorization:sessionStorage.token},

                //控制弹框的出现隐藏
                showPact:false,
                radio:"1",
                //这个参数 是点击了上传转让通知书 之后， 将会将下边的这个值 赋值如下
                showPactMsg:{}, 
                
                //pdf对应的页面需要的参数以及值。
                payableBillFinanceFile: {},
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                fileType: "pdf", // 文件类型
                src: "", // pdf文件地址
                
            }
        },

        created() {

            if(this.$route.query.huizhi == '4'){
                if(this.$route.query.onLineShow == '0') {
                    this.breadcrumblist =  ["付款确认书"];
                } else {
                    this.breadcrumblist =  ["保理转让通知"];
                }
            }else if(this.$route.query.huizhi == '5'){
                if(this.$route.query.onLineShow == '0') {
                    this.breadcrumblist =  ["付款确认书"];
                } else {
                    this.breadcrumblist =  ["企业转让通知"]
                }
            }else if(this.$route.query.huizhi == '6'){
                this.breadcrumblist =  ["保理转让通知回执"];
            }else if(this.$route.query.huizhi == '7'){
                this.breadcrumblist =  ["企业转让通知回执"];
            }
            // 不清楚判断条件，直接传过来了
            if(this.$route.query.pageName) {
                this.breadcrumblist =  [this.$route.query.pageName];
            }

            if(this.$route.query.onLineShow == 1){
                this.getSigninfo();
            }else{
                this.getImgList();
            }
        },
        methods: {
            // 当线上上传的时候，这个时候需要 直接进入到对应的那个pdf页面，这个时候，需要做一个操作。可以直接查看页面。
            showHandleopen(){
                
                console.log("这里是直接走了 线上合同签约的模式，这里需要注意以下、")

                var signType = ''
                if(this.$route.query.huizhi == 6){
                    signType = 6
                }else if(this.$route.query.huizhi == 7){
                    signType = 7
                }
                //这里是控制当有 toPdfPage的参数时，这里有一个跳转的操作，且让它有这个signType的参数。
                this.$router.push({
                  path: "/abs/AbswaisigngysDetail",
                  query: {
                    absAccountId:this.$route.query.id,
                    absPlanId: this.uploadpic.absPlanId,
                    signType: signType,
                    // receivableAccountFinanceId:data.receivableAccountFinanceId,
                    // receivableAccountId:data.receivableAccountId,
                    isshowbtn: '1',
                    // fundCorpId: data.fundCorpId,
                    corpType:'2',
                  }
              });
            },


            getSigninfo() {
                api
                    .getAbsOnLineSignContract({
                        absAccountId: this.$route.query.id,
                        signType:this.$route.query.pdfType,
                    //   signSource: this.signSource
                    })
                    .then(res => {
                    console.log("res ===>", res.datas);
                    if (res.success) {
                        this.financeCorp = res.datas.financeCorp;
                        this.fundCorp = res.datas.fundCorp;
                        // this.payableBillFinanceFile = res.datas.payableBillFinanceFile;
                        this.payableBillFinanceFile = res.datas.financeFile;
                        

                        // console.log(res.datas.payableBillFinanceFile.fileUrl);
                        this.src = baseURL + this.payableBillFinanceFile.fileUrl;
                        console.log(
                        "url ===>",
                        this.baseURL + "/" + this.payableBillFinanceFile.fileUrl
                        );
                        //获取文件流
                    }else{
                        this.$alert(res.message, '警告', {
                            confirmButtonText: '确定',
                            callback: action => {
                               this.$router.go(-1);
                            }
                         });
                    }
                    });
                },
            // pdf加载时
            loadPdfHandler(e) {
            this.currentPage = 1; // 加载的时候先加载第一页
            },
            // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
            changePdfPage(val) {
            // console.log(val)
            if (val === 0 && this.currentPage > 1) {
                this.currentPage--;
                // console.log(this.currentPage)
            }
            if (val === 1 && this.currentPage < this.pageCount) {
                this.currentPage++;
                // console.log(this.currentPage)
            }
            },


            getImgList(){
                var type=+this.$route.query.type
                    api.getUploadFile({absAccountId: this.$route.query.id,fileType:type}).then(res =>{ //审批结果查询
                    if(res.success) {
                            this.datalist=res.datas.absAccountFileList;
                            console.log(this.datalist);
                            this.$previewRefresh()

                    }else{
                        this.$message.error(res.message)
                    }
                });
            },


            //  handleopen(row){
            //     console.log(row,123)
            //     this.showAddDialog=true
            //     this.uploadpic.absAccountId=this.$route.query.id;
            //     this.uploadpic.absAccountFileVoList=[]
            // },
            handleopen(row){
                console.log(row);
                this.showPactMsg = row;
                console.log(row,123)
                // this.showAddDialog=true;  此处将会触发将按钮弹框弹出来。
                this.showPact = true;  //这里将会出现一个弹框，弹框中有两种 情况一种线上， 一种弹框。
                // this.uploadpic.absAccountId=row.absAccountId;
                this.uploadpic.absAccountId = this.$route.query.id
                // this.uploadpic.absAccountFileVoList=[]

          },

          /*  这里是链属企业  E-ABS 专项计划页面中 待签约  应收账款中 ，点击了上传转让通知书按钮之后的 弹框样式是这样的。 */
          subMitPact(){
              if(this.radio == '1'){
                  // this.handleNew(this.showPactMsg);
                  this.showAddDialog=true;
                    //   this.uploadpic.absAccountId=this.showPactMsg.absAccountId;
                    this.uploadpic.absAccountId = this.$route.query.id
                    this.uploadpic.absAccountFileVoList=[];

              }else if(this.radio == '3'){
                    4
                  this.toSignDetail(this.showPactMsg);
              }
          },
          /**  当状态为3的时候，将会跳转到线上签约的模式  跳转路由页面的写法如下所示：  */
          toSignDetail(data){
             var signType = ''
             if(this.$route.query.huizhi == 6){
                 signType = 6
             }else if(this.$route.query.huizhi == 7){
                 signType = 7
             }

              console.log(data);
              this.$router.push({
                  path: "/abs/AbswaisigngysDetail",
                  query: {
                    absAccountId:this.uploadpic.absAccountId,
                    absPlanId: this.uploadpic.absPlanId,
                    signType: signType,
                    // receivableAccountFinanceId:data.receivableAccountFinanceId,
                    // receivableAccountId:data.receivableAccountId,
                    isshowbtn: '1',
                    // fundCorpId: data.fundCorpId,
                    corpType:'2',
                  }
              });
          },


            goback(){
                history.go(-1)
            },
            packfn(){
                console.log(this.$refs.pack,'pack') 
            },

           

            handleAvatarSuccess(response, file) {
                  if(response.code=='100000'){
                               var  data=response.datas.uploadDate.message,
                                    fileType=5
                            if(this.$route.query.isbl==1){
                                fileType=7
                            }else{
                                    fileType=5
                            }
                             this.uploadpic.absAccountFileVoList.push({fileType:fileType,fileUrl:data,uid:file.uid,fileName:file.name})
                         }else{
                             

                         this.$message.error(file.name+'识别错误，请重新上传')

                         }

            },
            handleRemove(file){
    
                if(this.uploadpic.absAccountFileVoList.findIndex(el => el.uid == file.uid)!=-1){

                    this.uploadpic.absAccountFileVoList.splice(this.uploadpic.absAccountFileVoList.findIndex(el=>el.uid==file.uid),1)
            
     
                }
              

            },
            beforeAvatarUpload(file) {
                let index = file.name.lastIndexOf('.')
                let suffix = file.name.substr(index + 1)
                 if(!/jpg|gif|bmp|png|jpeg/i.test(suffix)){
                        this.$message.error('请上传png,jpg,jpeg格式的图片')
                        return false
                  }
            
            },
            uploadfn(){
                
                         api.uploadAccountFile(this.uploadpic).then(res =>{ 
                                        if(res.success) {
                                            this.$message.success('上传成功')
                                            this.showAddDialog=false

                                          history.go(-1)
                        
                                        }else{
                                            this.$message.error(res.message)
                                        }
                       });
                    
                 console.log(this.uploadpic)

            },
    
        },
        components: {
            absyexthead,
            pdf

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }
       .trantext{
           h3{
               text-align: center;
           }
           li{
               width: 24%;
               img{
                   width: 100%;
                   height: 200px;
               }
              
           }
       }
         .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload-dragger{
      height: 100px;
      width: 140px;
      .el-icon-plus{
          font-size: 30px;
          margin-top: 20px;
      }
    
  }

   }
</style>