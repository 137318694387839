<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="billNo" label="票据编号" min-width="160"></el-table-column>
          <el-table-column prop="payableBillAmount" label="票据类型" min-width="120"  >
              <template slot-scope="scope">
              {{scope.row.billType|billType}}
            </template>
          </el-table-column>
          <el-table-column  label="票面金额(元)" align='right' min-width="160" :formatter='el=>$changeMoneyThousand(el.billAmount)'></el-table-column>
          <el-table-column prop="acceptor" label="承兑人" min-width="110"></el-table-column>
          <el-table-column prop="offerPriceYearInterest" label="报价利率" min-width="120"></el-table-column>
          <el-table-column  label="报价每十万元扣费(元)" align='right' min-width="200" :formatter='el=>$changeMoneyThousand(el.chargeAmount)'></el-table-column>
          <el-table-column prop="approveTime" label="报价时间" min-width="160">
                      <template slot-scope="scope">
              {{scope.row.approveTime|formatDate}}
            </template>
            
          </el-table-column>
          <el-table-column prop="acceptor" label="有效时间" min-width="160">
                      <template slot-scope="scope">
              {{scope.row.quoteTimeLimit|quoteLimit}}
            </template>
          </el-table-column>
          <el-table-column label="结束原因" min-width="160" prop="approveNodeValus">
          </el-table-column>
      



          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
        filterList: [

        {
          label: "票据金额(元): ",
          type: "inputrange",
          field: "billAmountStr",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "billAmountEnd",
          size: "small"
        },

        {
          label: "承兑人: ",
          type: "input",
          field: "acceptor",
          size: "small",
        },
           {
          label: "票据类型: ",
          type: "select",
          field: "billType",
          size: "small",
          options:[{label:'商票',value:1},{label:'国股',value:2},{label:'城商',value:3},{label:'三农',value:4},{label:'财票',value:5}]

        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
  
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      api.getBillQuoteOverdueList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
      });
    },
    handleEdit(row) {
              this.$router.push({
                    path: "/bill/bill-approvaled-details",
                    query: { id: row.billId,isshowback:1,billFundStatus:2,billQuoteApproveId:row.billQuoteApproveId}
                });
    },

  }
};
</script>


