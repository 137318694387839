<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby bl">
        <div class="yfdtwo">
          <div class="yfdsize rback">退回原因</div>
          <div class="card-bd card-bd-row refuseback">
            <el-row>
              <el-col :span="16" class="lb_body">
                <img
                  src="../../assets/cuowu.png"
                  alt=""
                  style="margin-left: -27px; vertical-align: middle"
                />
                <span class="info-key">被退回原因:</span>
                <span class="info-value index">{{ refusereason.reason }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="lb_body">
                <span class="info-key">备注:</span>
                <span class="info-value index">{{ refusereason.remark }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="yfdsize">基本信息</div>
          <div class="formstyle">
            <el-form
              :model="PayableBillAddRequest"
              :rules="rules"
              ref="PayableBillAddRequest"
              label-width="198px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="债务企业: " prop="fundCorpName">
                    <el-select
                      v-model="PayableBillAddRequest.fundCorpName"
                      placeholder="请选择资金机构"
                      value-key="corpId"
                      filterable
                      @change="getcreditCodefn"
                    >
                      <el-option
                        v-for="(item, index) in qyallname"
                        :key="index"
                        :label="item.corpName"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="统一社会信用代码: ">
                    <el-input
                      v-model="PayableBillAddRequest.deptCorpCreditNo"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="贸易合同编号: " prop="contractNo">
                    <el-input
                      v-model="PayableBillAddRequest.contractNo"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="账款起止时间: " prop="zktime">
                    <el-date-picker
                      v-model="PayableBillAddRequest.zktime"
                      type="daterange"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      @change="zktimefn"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="应收账款金额: " prop="accountAmount">
                    <el-input
                      v-model="PayableBillAddRequest.accountAmount"
                      @blur="tocapital"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大写: ">
                    <el-input
                      v-model="PayableBillAddRequest.accountAmountCpt"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <p class="wxts httotal">
              温馨提示：债务企业必须是已经入驻平台的企业。
            </p>
          </div>
        </div>
        <div class="yfdtwo">
          <div class="yfdsize">合同信息</div>
          <div class="formstyle">
            <div class="htbox" :class="{ active: ruleform2.isactive }">
              <div class="htactive">
                <span>交易合同 </span>
              </div>
              <div class="htpad">
                <el-form
                  :model="ruleform2"
                  :rules="ruleform2.rules"
                  label-position="left"
                  ref="ruleform2"
                  class="demo-ruleForm"
                >
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关合同: "
                      prop="files"
                      class="upload-text-requird"
                    >
                      <!-- <el-input v-model="item.files[0]" v-show="false" type='file'></el-input>                          -->
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP格式。上传的合同必须加盖骑缝章，按照合同页数进行排列。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        multiple
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupfn(a, b, c)"
                        :on-remove="(a, b) => dragremovefn(a, b)"
                        :headers="Authorization"
                        :file-list="ruleform2.filelist"
                        accept="image/png,image/jpeg,application/zip,application/rar"
                        :before-upload="beforeupfnfile"
                        drag
                        list-type="picture"
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传图片</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关发票: "
                      prop="img"
                      class="upload-text-requird"
                    >
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG格式。上传的发票必须真实不能重复。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadAccountInvoice'"
                        :on-success="(a, b, c) => handleupfn(a, b, c, item)"
                        :on-remove="(a, b) => handleRemove(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.filelistfp"
                        :headers="Authorization"
                        accept="image/png,image/jpeg,image/jpg"
                        :before-upload="beforeupfn"
                        list-type="picture"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传图片</div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-table
                        :data="ruleform2.invoiceList"
                        @cell-mouse-enter="cellhover"
                        @cell-mouse-leave="cellleave"
                        style="width: 100%; top: 20px"
                      >
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column
                          label="购买方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceBuyer"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="销售方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceSeller"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票代码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票号码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceNo"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="检验码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceCheckCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开票日期"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceOpenTime"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="不含税开具金额（元）"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceAmountWithoutTax"
                              class="tabright"
                              
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开具金额（元）"
                          align="right"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceAmount"
                              class="tabright"
                              @blur="blurfptotal(scope.row, scope.$index, item)"
                            />
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <div class="ljje">
                      累计发票金额:
                      <span>
                        {{ changeMoneyThousand(ruleform2.fptotal) }}
                      </span>
                      元
                    </div>
                  </div>
                  <div class="uploadlist">
                    <el-form-item label="上传其他资料: ">
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP格式。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupotherfn(a, b, c)"
                        :on-remove="(a, b) => dragremoveotherfn(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.wuLiuListfp"
                        :headers="Authorization"
                        accept="image/png,image/jpeg,image/jpg"
                        :before-upload="beforeupfn"
                        list-type="picture"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传物流单据</div>
                      </el-upload>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupotherfn2(a, b, c)"
                        :on-remove="(a, b) => dragremoveotherfn2(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.bankListfp"
                        list-type="picture"
                        accept="image/png,image/jpeg,image/jpg"
                        :before-upload="beforeupfn"
                        :headers="Authorization"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传银行流水</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                  <!-- <el-button @click="delDomain(index)">删除</el-button> -->
                </el-form>
              </div>
            </div>
            <!-- <el-button @click="addDomain">新增域名</el-button> -->
          </div>
        </div>
        <div
          class="card-hd"
          style="height: 150px"
          v-if="$route.query.accountBackStatus != 3"
        >
          <el-row style="padding-top: 40px">
            <el-col :span="7" class="lb_body" :offset="7">
              <el-button
                style="width: 160px; height: 40px; font-size: 16px"
                size="small"
                @click="openAbolishdiag"
                >此单作废</el-button
              >
            </el-col>
            <el-col :span="8" class="lb_body">
              <el-button
                style="
                  width: 160px;
                  height: 40px;
                  background: #2937a6;
                  color: #ffffff;
                  font-size: 16px;
                "
                size="small"
                @click="nextbtn"
                >变更应收账款</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div
          class="card-hd"
          style="height: 150px"
          v-if="$route.query.accountBackStatus == 3"
        >
          <el-row style="padding-top: 40px">
            <el-col :span="8" class="lb_body" :offset="10">
              <el-button
                style="
                  width: 160px;
                  height: 40px;
                  background: #2937a6;
                  color: #ffffff;
                  font-size: 16px;
                "
                size="small"
                @click="goback"
                >返回</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog
      title="拒绝原因"
      :visible.sync="showAddDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="refusedetail" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="reason">
          <el-select v-model="refusedetail.reason" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="refusedetail.remark"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAddForm2('addform')"
          :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from '@/api/api';
import { convertCurrency, adds } from '@/util/common.js';
import { baseURL } from '@/api/sys';
import moment from 'moment';
import { changeMoneyThousand } from '@/util/common';

Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};

export default {
  data() {
    var checkmaxmount = (rule, value, callback) => {
      if (!/^\d{1,12}(\.\d{1,2})?$/.test(value)) {
        callback(new Error('请输入12位以内的数字,小数点后最多2位'));
      } else {
        if (value > this.PayableBillAddRequest.grantableAmount) {
          callback(new Error('优企通金额必须小于等于可用授信额度'));
        } else {
          callback();
        }
      }
    };

    return {
      checked: true,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ['应收账款退回'],
      activebtn: 0,
      rules: {
        fundCorpName: [
          { required: true, message: '请选择债务企业', trigger: 'change' },
        ],

        contractNo: [
          { required: true, message: '请输入合同编号' },
          { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
        ],
        accountAmount: [
          { required: true, message: '请输入应收账款金额' },
          {
            pattern: /^\d{1,12}(\.\d{1,2})?$/,
            message: '请输入12位以内的数字,小数点后最多2位',
          },
        ],

        zktime: [
          { required: true, message: '请选择账款时限', trigger: 'change' },
        ],
      },
      rules2: {
        payableAmount: [
          { validator: checkmaxmount },
          { required: true, message: '请输入优企通金额' },
        ],
        promisePayTime: [{ required: true, message: '请选择承诺付款日' }],
      },
      bclick: false,
      PayableBillAddRequest: {
        debtCorpId: '',
        debtCorpName: '',
        deptCorpCreditNo: '',
        contractNo: '',
        accountAmount: '',
        accountAmountCpt: '',
        accountSignDate: '',
        accountCloseDate: '',
        zktime: '',
        fundCorpName: '',
      },
      zjlist: [],
      sxbhlist: [],
      qyallname: [],

      ruleform2: {
        receivableAccountFileDtoList: [],
        invoiceList: [],
        fptotal: 0,
        wuLiuList: [],
        bankList: [],
        filelist: [],
        filelistfp: [],
        wuLiuListfp: [],
        bankListfp: [],
      },

      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return (
            time.getTime() >
            new Date(this.PayableBillAddRequest.grantCloseTime).getTime()
          );
        }.bind(this),
      },
      listfile: [],
      convertCurrency: convertCurrency,
      mounttoall: 0,
      Authorization: { Authorization: sessionStorage.token },
      changeMoneyThousand: changeMoneyThousand,
      showAddDialog2: false,
      refusedetail: {
        receivableAccountId: this.$route.query.id,
        approveResult: 3,
        remark: '',
        approveNode: this.$route.query.node,
        reason: '',
        ackSuccess: 1,
      },
      refuselist: [],
      refusereason: {},
    };
  },

  created() {
    this.changefundCorpName();
    this.receivableAccountdetail();
    this.getrefuselist(); //获取拒绝原因
  },
  methods: {
    goback() {
      history.go(-1);
    },
    receivableAccountdetail() {
      //获取详情
      api
        .receivableAccountdetail({ receivableAccountId: this.$route.query.id })
        .then((res) => {
          if (res.code == 100000) {
            var data = res.datas.ReceivableAccountDto;
            this.PayableBillAddRequest.fundCorpName = data.debtCorpName;
            this.PayableBillAddRequest.debtCorpId = data.debtCorpId;
            this.PayableBillAddRequest.deptCorpCreditNo = data.deptCorpCreditNo;
            this.PayableBillAddRequest.contractNo = data.contractNo;
            this.PayableBillAddRequest.zktime = [
              moment(data.accountSignDate).format('YYYY-MM-DD'),
              moment(data.accountCloseDate).format('YYYY-MM-DD'),
            ];
            this.PayableBillAddRequest.accountSignDate = moment(
              data.accountSignDate
            ).format('YYYY-MM-DD');
            this.PayableBillAddRequest.accountCloseDate = moment(
              data.accountCloseDate
            ).format('YYYY-MM-DD');
            this.PayableBillAddRequest.accountAmount = data.accountAmount;
            this.PayableBillAddRequest.accountAmountCpt = data.accountAmountCpt;
            this.refusereason = res.datas.approve;

            //合同
            data.receivableAccountFileDtoList.map((ele) => {
              this.ruleform2.filelist.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.receivableAccountFileDtoList.push({
                fileCode: 14,
                fileUrl: ele.fileUrl,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });
            //发票
            data.invoiceList.map((ele) => {
              this.ruleform2.filelistfp.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.invoiceList.push({
                receivableAccountFileId: ele.receivableAccountFileId,
                receivableAccountId: ele.receivableAccountId,
                invoiceBuyer: ele.invoiceBuyer,
                invoiceSeller: ele.invoiceSeller,
                invoiceCode: ele.invoiceCode,
                invoiceNo: ele.invoiceNo,
                invoiceOpenTime: ele.invoiceOpenTime,
                invoiceAmount: ele.invoiceAmount,
                fileCode: 15,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });

            //其他附件
            //物流
            data.wuLiuList.map((ele) => {
              this.ruleform2.wuLiuListfp.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.wuLiuList.push({
                fileCode: 16,
                name: ele.fileName,
                fileUrl: ele.fileUrl,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });
            //银行
            data.bankList.map((ele) => {
              this.ruleform2.bankListfp.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.bankList.push({
                fileCode: 17,
                name: ele.fileName,
                fileUrl: ele.fileUrl,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });

            //发票总金额

            var arr = [];
            data.invoiceList.map((el) => {
              arr.push(el.invoiceAmount);
            });

            this.ruleform2.fptotal = (0).adds(...arr);
          } else {
          }
        })
        .catch((err) => {
          this.bclick = false;
        });
    },
    tocapital() {
      this.PayableBillAddRequest.accountAmountCpt = this.convertCurrency(
        this.PayableBillAddRequest.accountAmount
      );
    },

    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #ccc';
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #fff';
    },

    nextbtn() {
      this.$refs['PayableBillAddRequest'].validate((valid) => {
        if (valid) {
          var amount = 0,
            issome = false,
            amountarr = [];
          this.ruleform2.invoiceList.map((ele) => {
            amountarr.push(Number(ele.invoiceAmount));
            // Object.keys(ele).forEach((els) => {
            //   if (ele[els] == '') {
            //     issome = true;
            //   }
            // });
          });
          amount = (0).adds(...amountarr);
          if (this.ruleform2.receivableAccountFileDtoList.length == 0) {
            this.$message.error('请上传合同');
            return;
          }
          if (this.ruleform2.invoiceList.length == 0) {
            this.$message.error('请上传发票');
            return;
          }

          if (issome) {
            this.$message.error('发票信息不全');
            return;
          }

          this.$refs['ruleform2'].validate((valid) => {
            if (valid) {
              let invoiceInfoList = this.ruleform2.invoiceList;
              let tempArr = [];
              let flag = true;

              // 发票验重
              invoiceInfoList.forEach((v) => {
                v.unique = v.invoiceCode + v.invoiceNo;
                if (tempArr.indexOf(v.unique) == -1) {
                  tempArr.push(v.unique);
                } else {
                  flag = false;
                  this.$alert(
                    '发票代码：' + v.invoiceCode + ' 发票号码：' + v.invoiceNo,
                    '发票信息重复',
                    {
                      confirmButtonText: '确定',
                      callback: (action) => {},
                    }
                  );
                  return false;
                }
              });
              if (!flag) {
                return false;
              }

              var a = Object.assign(
                {
                  receivableAccountId: this.$route.query.id,
                  receivableAccountNo: this.$route.query.receivableAccountNo,
                },
                this.ruleform2,
                this.PayableBillAddRequest
              );

              this.$confirm('该应收账款将变更, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
              })
                .then(() => {
                  const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                  });
                  if (this.bclick) {
                    return;
                  }
                  this.bclick = true;
                  api
                    .updateReceiveAccount(a)
                    .then((res) => {
                      loading.close();

                      if (res.code == '100000') {
                        this.$alert('变更成功，提交内部审核', '', {
                          confirmButtonText: '确定',
                          callback: (action) => {
                            history.go(-1);
                          },
                        });
                      } else {
                        this.bclick = false;
                        this.$message.error(res.message);
                      }
                    })
                    .catch((err) => {
                      loading.close();
                      this.bclick = false;
                    });
                })
                .catch(() => {
                  loading.close();

                  this.bclick = false;
                });

              // const loading = this.$loading({
              //    lock: true,
              //    text: 'Loading',
              //    spinner: 'el-icon-loading',
              //    background: 'rgba(0, 0, 0, 0.7)'
              //    });
              //    console.log(a)
              //    if(this.bclick){
              //                return
              //    }
              //    this.bclick=true;
              api
                .updateReceiveAccount(a)
                .then((res) => {
                  loading.close();
                  if (res.code == 100000) {
                    this.$alert('变更成功，提交内部审核', '', {
                      confirmButtonText: '确定',
                      callback: (action) => {
                        history.go(-1);
                      },
                    });
                  } else {
                    this.bclick = false;
                    this.$message.error(res.message);
                  }
                })
                .catch((err) => {
                  loading.close();

                  this.bclick = false;
                });
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        }
      });
    },
    prevbtn() {
      this.activebtn--;
    },
    dragupfn(response, file, fileList, index) {
      //上传的合同
      console.log(file, 123);

      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.filelist[this.ruleform2.filelist.length] = file;
        this.ruleform2.receivableAccountFileDtoList.push({
          fileCode: 14,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.ruleform2.filelist = this.ruleform2.filelist;
        this.$forceUpdate();

        this.$message.error(file.name + '发票识别错误，请重新上传');
      }
    },
    dragremovefn(file, fileList, index) {
      //移除合同
      if (
        this.ruleform2.receivableAccountFileDtoList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.receivableAccountFileDtoList.splice(
          this.ruleform2.receivableAccountFileDtoList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        this.ruleform2.filelist.splice(
          this.ruleform2.filelist.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },

    //上传发票
    handleupfn(response, file, fileList, item) {
      var data = response.message.datas.receivableAccountFile;
      item.filelistfp[item.filelistfp.length] = file;
      item.filelistfp.forEach((el, index) => {
        el.name = index + 1;
      });
      item.invoiceList.push({
        ...data,
        fileCode: 15,
        uid: file.uid,
        fileName: file.name,
      });
      item.fptotal = (+item.fptotal).adds(data.invoiceAmount);

      if (response.code === -1) {
        this.$message.error('发票识别错误，请删除后重新上传或手动填写更新。');
      } else {
        this.$message.success(response.message);
      }

      // let uid = file.uid; // 关键作用代码，去除文件列表失败文件
      // let idx = this.$refs.uploadFile1[0].uploadFiles.findIndex(
      //   (item) => item.uid === uid
      // ); // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
      // this.$refs.uploadFile1[0].uploadFiles.splice(idx, 1); // 关键作用代码，去除文件列表失败文件
    },
    handleRemove(file, fileList) {
      //移除发票
      console.log(file, fileList);
      if (
        this.ruleform2.invoiceList.findIndex((el) => el.uid == file.uid) != -1
      ) {
        this.ruleform2.filelistfp.splice(
          this.ruleform2.filelistfp.findIndex((el) => el.uid == file.uid),
          1
        );
        this.ruleform2.filelistfp.forEach((el, index) => {
          el.name = index + 1;
        });
        this.ruleform2.invoiceList.splice(
          this.ruleform2.invoiceList.findIndex((el) => el.uid == file.uid),
          1
        );

        var arr = [];
        this.ruleform2.invoiceList.map((el) => {
          arr.push(Number(el.invoiceAmount));
        });
        this.ruleform2.fptotal = (0).adds(...arr);
      }
    },
    dragupotherfn(response, file, fileList, item, index) {
      //上传物流

      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.wuLiuListfp[this.ruleform2.wuLiuListfp.length] = file;
        this.ruleform2.wuLiuList.push({
          fileCode: 16,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + '发票识别错误，请重新上传');
      }
    },
    dragremoveotherfn(file, fileList, item, index) {
      //移除其他的附件
      if (
        this.ruleform2.wuLiuList.findIndex((el) => el.uid == file.uid) != -1
      ) {
        this.ruleform2.wuLiuList.splice(
          this.ruleform2.wuLiuList.findIndex((el) => el.uid == file.uid),
          1
        );
        this.ruleform2.wuLiuListfp.splice(
          this.ruleform2.wuLiuListfp.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    dragupotherfn2(response, file, fileList, item, index) {
      //上传的其他附件

      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.bankListfp[this.ruleform2.bankListfp.length] = file;
        this.ruleform2.bankList.push({
          fileCode: 17,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + '发票识别错误，请重新上传');
      }
    },
    dragremoveotherfn2(file, fileList, item, index) {
      //移除其他的附件
      if (
        this.ruleform2.bankListfp.findIndex((el) => el.uid == file.uid) != -1
      ) {
        this.ruleform2.bankListfp.splice(
          this.ruleform2.bankListfp.findIndex((el) => el.uid == file.uid),
          1
        );
        this.ruleform2.bankList.splice(
          this.ruleform2.bankList.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    erruploadfp(err) {
      this.$message.error('服务器错误');
    },

    changefundCorpName(val) {
      //获取授信编号
      api.getSuperiorList().then((res) => {
        if (res.code == 100000) {
          this.qyallname = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getcreditCodefn(val) {
      this.PayableBillAddRequest.debtCorpId = val.corpId;
      this.PayableBillAddRequest.debtCorpName = val.corpName;
      this.PayableBillAddRequest.deptCorpCreditNo = val.creditCode;
    },
    zktimefn(val) {
      this.PayableBillAddRequest.accountSignDate = val[0];
      this.PayableBillAddRequest.accountCloseDate = val[1];
      console.log(val);
    },
    blurfptotal(val, index, item) {
      var arr = [];
      this.ruleform2.invoiceList.map((el) => {
        arr.push(Number(el.invoiceAmount));
      });
      this.ruleform2.fptotal = (0).adds(...arr);
    },
    beforeupfn(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (file.name.substr(0, index).length > 40) {
        this.$message.error('上传文件名长度不得超过40！');
        return false;
      }
      if (!/jpg|gif|bmp|png|jpeg/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg格式的图片');
        return false;
      }
      if (file.size > 1048576) {
        this.$message.error('请上传1M以内的图片');
        return false;
      }
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (!/jpg|gif|bmp|png|jpeg|zip|rar/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg,zip,rar格式的文件');
        return false;
      }
    },
    getrefuselist() {
      api.getDicListByType({ type: 15 }).then((res) => {
        if (res.code == '100000') {
          this.refuselist = res.datas.list;
        }
      });
    },
    openAbolishdiag() {
      // this.showAddDialog2=true
      //         this.refusedetail.remark=''
      // this.refusedetail.reason=this.refuselist[0].name
      // this.bclick=false
      this.$confirm('该应收账款将作废, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          if (this.bclick) {
            return;
          }
          this.bclick = true;
          api
            .submitReceivableAccountResult({
              receivableAccountId: this.$route.query.id,
              approveResult: 3,
            })
            .then((res) => {
              loading.close();

              if (res.code == '100000') {
                this.$alert('废除成功', '', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    history.go(-1);
                  },
                });
              } else {
                this.bclick = false;
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              loading.close();

              this.bclick = false;
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消废除',
          });
        });
    },
    submitAddForm2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      if (this.bclick) {
        return;
      }
      this.bclick = true;
      api
        .submitReceivableAccountResult(this.refusedetail)
        .then((res) => {
          loading.close();
          if (res.code == '100000') {
            this.showAddDialog2 = false;
            history.go(-1);
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch((err) => {
          loading.close();
          this.bclick = false;
        });
    },
    refuseReasonfn(val) {
      var name = '';
      name = this.refuselist.find((el) => {
        return el.code == val;
      });
      return name && name.name;
    },
  },
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
}
.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type='text'] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 300px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937a6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16 {
  position: relative;
  top: -16px;
}
.easypayby.bl {
  margin-top: 20px;
}
</style>
