<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby goback yfdtwo">
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form
            :model="payableBillAddRequest"
            :rules="rules"
            ref="payableBillAddRequest"
            label-width="160px"
            class="demo-ruleForm"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应收账款能否确权: " prop="corpAck">
                  <el-radio-group v-model="payableBillAddRequest.corpAck">
                    <el-radio :label="1">能</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="期望融资金额(元): " prop="preFinanceAmount">
                  <el-input
                    v-model="payableBillAddRequest.preFinanceAmount"
                    placeholder="请输入期望融资金额"
                    @change="tocapital"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望融资金额(大写): " class="minsizer">
                  <el-input v-model="payableBillAddRequest.remark" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="inlinelv">
              <el-col :span="9" class="firlabwid">
                <el-form-item label="期望融资年化利率(%): " prop="expectFinanceInterestMin">
                  <el-input
                    v-model="payableBillAddRequest.expectFinanceInterestMin"
                    placeholder="请输入最小值"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="perwidth">
                <em>~</em>
              </el-col>
              <el-col :span="9" class="nolabwid">
                <el-form-item label=" " prop="expectFinanceInterestMax">
                  <el-input
                    v-model="payableBillAddRequest.expectFinanceInterestMax"
                    placeholder="请输入最大值"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款开户行: " prop="payeeOpenBank">
                  <el-select
                    v-model="payableBillAddRequest.payeeOpenBank"
                    placeholder="请选择"
                    @change="getOpenBankNo"
                  >
                    <el-option
                      v-for="item in corpAccountPublicList"
                      :key="item.openBank"
                      :label="item.openBank"
                      :value="item.openBank"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="收款账户: " class="minsizer" prop="payeePublicAccountNo">
                  <el-select v-model="payableBillAddRequest.payeePublicAccountNo" placeholder="请选择">
                    <el-option
                      v-for="item in corpAccountPublicNoList"
                      :key="item.accountNo"
                      :label="item.accountNo"
                      :value="item.accountNo"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="温馨提示: ">
                  <span class="info-value">还没有绑定卡，</span>
                  <a
                    class="downsize"
                    style="color: #2937A6; text-decoration: underline; cursor: pointer"
                    size="small"
                    @click="linkCompanyManage"
                  >点击此处</a>
                  <span class="info-value">完成对公账户绑定。</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定应收账款</div>
        <div class="httotal">
          已添加应收账款
          <span>{{multipleSelection.length}}</span> 笔，账款总计金额
          <span>{{ $changeMoneyThousand(mounttoall)}}</span>
          元。
        </div>
        <div>
          <el-table
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="65"></el-table-column>
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额（元）" min-width="180">
              <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="160">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="160">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-hd" style="height: 150px;">
            <el-row style="padding-top: 40px;">
              <el-col :span="24"  class="lb_body" style="text-align:center">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="approvalApply"
                >发起融资申请</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";

Number.prototype["adds"] = function(...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach(value => {
    try {
      r1 = result.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};

export default {
  data() {
    var expectFinanceInterestMin = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入利率最小值"));
      } else if (!/^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/g.test(value)) {
        callback(new Error("请输入100以内最多2位小数的自然数"));
      } else {
        if (this.payableBillAddRequest.expectFinanceInterestMin !== "") {
          this.$refs.payableBillAddRequest.validateField(
            "expectFinanceInterestMax"
          );
        }
        callback();
      }
    };
    var expectFinanceInterestMax = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入利率最大值"));
      } else if (
        parseFloat(value) <=
        parseFloat(this.payableBillAddRequest.expectFinanceInterestMin)
      ) {
        callback(new Error("最大值要比最小值大"));
      } else if (!/^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/g.test(value)) {
        callback(new Error("请输入100以内最多2位小数的自然数"));
      } else {
        callback();
      }
    };
    return {
      moment: moment,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["保理融资"],
      rules: {
        corpAck: [{ required: true }],
        receivedCorpCreditCode: [{ required: true }],
        preFinanceAmount: [
          {
            // pattern: /^\d{1,12}(\.\d{1,2})?$/,
            pattern: /^[1-9]\d{0,11}(\.\d{1,2})?$/,
            message: "请输入12位以内的数字,小数点后最多2位"
          },
          { required: true, message: "请输入期望融资金额" }
        ],
        expectFinanceInterestMin: [
          {
            required: true,
            validator: expectFinanceInterestMin,
            trigger: "change"
          }
        ],
        expectFinanceInterestMax: [
          { validator: expectFinanceInterestMax, trigger: "change" }
        ],
        payeeOpenBank: [{ required: true, message: "请选择收款开户行" }],
        payeePublicAccountNo: [{ required: true, message: "请选择收款账号" }]
      },

      payableBillAddRequest: {
        corpAck: 1,
        preFinanceAmount: "",
        expectFinanceInterestMin: "",
        expectFinanceInterestMax: "",
        payeeOpenBank: "",
        payeePublicAccountNo: "",
        options: [],
        remark: ""
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return (
            time.getTime() >
            new Date(this.payableBillAddRequest.grantCloseTime).getTime()
          );
        }.bind(this)
      },
      tableList: [],
      convertCurrency: convertCurrency,
      mounttoall: 0,
      Authorization: { Authorization: sessionStorage.token },
      changeMoneyThousand: changeMoneyThousand,
      value: "",
      moment: moment,
      corpAccountPublicList: [], //开户行
      corpAccountPublicNoList: [], // 开户账号
      multipleSelection: [] //选中的表格
    };
  },
  watch: {
    multipleSelection(newName, oldName) {
      this.jsmount(newName);
    }
  },
  created() {
    this.getList();
    this.getBindAccountList(); //获取开户行
  },
  methods: {
    getList() {
      let claimsCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      api
        .GetRelationReceivedAccountsById({ claimsCorpId: claimsCorpId })
        .then(res => {
          if (res.success) {
            this.tableList = res.datas.list;
          }
        });
    },
    getBindAccountList() {
      api.GetBindAccountList("").then(res => {
        if (res.success) {
          this.corpAccountPublicList = res.datas.corpAccountPublicList;
        }
      });
    },
    getOpenBankNo() {
      // 获取开户账号
      console.log("openBank ===>", this.payableBillAddRequest.payeeOpenBank);
      this.payableBillAddRequest.payeePublicAccountNo = "";
      this.corpAccountPublicNoList = [];
      api
        .GetBindAccountList(this.payableBillAddRequest.payeeOpenBank)
        .then(res => {
          if (res.success) {
            this.corpAccountPublicNoList = res.datas.corpAccountPublicList;
          }
        });
    },
    tocapital() {
      this.payableBillAddRequest.remark = this.convertCurrency(
        this.payableBillAddRequest.preFinanceAmount
      );
    },
    jsmount(newName) {
      var arr = [];
      newName.map(el => {
        arr.push(el.accountAmount);
      });
      this.mounttoall = (0).adds(...arr);
      console.log(arr);
      console.log(this.mounttoall);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(data) {
      this.$router.push({
        name: "应收账款详情",
        query: { id: data.receivableAccountId, isshowbtn: 2 }
      });
    },
    linkCompanyManage() {
      var item = {
        name: "企业管理",
        path: "/Business/Bank_management?pageSize=10&pageNum=1"
      };
      sessionStorage.name = item.name;
      this.$router.push({ path: item.path });
    },
    //发起融资申请
    approvalApply() {
      // 1 先判断有没有勾选列表
      // 2判断一下金额 期望融资金额 > 选择的应收账款总额

      if (this.multipleSelection.length > 0) {
        if (
          parseFloat(this.payableBillAddRequest.preFinanceAmount) >
          parseFloat(this.mounttoall)
        ) {
          this.$message.error("期望融资金额不能大于选择的应收账款总额");
          return;
        }
      }else {
          this.$message.error("请选择应收账款");
          return;
      }

      // console.log("relationReceivedAccountIds ===>", this.multipleSelection);
      let relationReceivedAccountIds = [];
      let relationReceivedAccountAmounts = 0;
      this.multipleSelection.forEach(ele => {
        // relationReceivedAccountIds += ele.receivableAccountId + ",";
        relationReceivedAccountIds.push(ele.receivableAccountId);
        relationReceivedAccountAmounts += ele.accountAmount;
      });
      let str = JSON.stringify(relationReceivedAccountIds);

      this.$refs.payableBillAddRequest.validate(valid => {
        if (valid) {
          let param = {
            corpAck: this.payableBillAddRequest.corpAck,
            preFinanceAmount: this.payableBillAddRequest.preFinanceAmount,
            expectFinanceInterestMin: this.payableBillAddRequest
              .expectFinanceInterestMin,
            expectFinanceInterestMax: this.payableBillAddRequest
              .expectFinanceInterestMax,
            payeeOpenBank: this.payableBillAddRequest.payeeOpenBank,
            payeePublicAccountNo: this.payableBillAddRequest
              .payeePublicAccountNo,
            relationReceivedAccountIds: str,
            relationReceivedAccountAmounts: this.mounttoall
          };
          api.ReceivableAccountFinanceAdd(param).then(res => {
            
            if (res.success) {
              this.$alert("融资申请完成，请等待融资审核");
              this.$router.push({
                path: "/factoring/FactoringManageIndex/1?pageSize=10&pageNum=1"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  padding-bottom: 10px;
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
</style>
