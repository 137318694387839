<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column prop="fundCorpName" label="资金机构" min-width="170"></el-table-column>
          <el-table-column label="签约额度(元)" min-width="170">
            <template slot-scope="scope">{{  $changeMoneyThousand(scope.row.financeAmount) }}</template>
          </el-table-column>
          <el-table-column prop="withdrawStatus" label="提现状态" min-width="150">
            <template slot-scope="scope">{{withdrawStatusfn(scope.row.withdrawStatus) }}</template>
          </el-table-column>
          <el-table-column  label="转换状态" min-width="150">
            <template slot-scope="scope">{{financeTransferStatusfn(scope.row.financeTransferStatus) }}</template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="medium" type="text" v-if="(scope.row.withdrawStatus == 1 || scope.row.withdrawStatus == 4) && (scope.row.financeTransferStatus == 1 || scope.row.financeTransferStatus == 3)" @click="handleCash(scope.row)">提现</el-button>
              <el-button size="medium" type="text" v-if="scope.row.financeTransferStatus == 1 && (scope.row.withdrawStatus == 1 || scope.row.withdrawStatus == 4)" @click="handleTrans(scope.row)">转优企通</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)" style="margin-left: 0;">查看详情</el-button>
              <el-button size="medium" type="text" @click="sign(scope.row)">查看合同</el-button>
              <el-button size="medium" type="text" v-if="scope.row.withdrawStatus == 2" style="margin-left: 0;" class="preview-box">
                <div>查看凭证</div>
                <img class="preview-img" @mouseenter="handleView(scope.row)" :src="fileUrl" preview="evidence" preview-text="放款凭证"/>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "receivableAccountFinanceNo",
          size: "small"
        },
        {
          label: "资金机构:",
          type: "input",
          field: "fundCorpName",
          size: "small"
        },
        {
          label: "签约额度(元): ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      fileUrl: ''
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      api.GetEnterpriseSignResultListPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      var isshowbtn;
      if (row.sign == 2) {
        isshowbtn = 1;
      } else {
        isshowbtn = 2;
      }

       this.$router.push({
        path: "/factoring/FactoringQuoteDetial",
        query: {
          receivableAccountFinanceId:row.receivableAccountFinanceId,
          type:'2',
        }
      });
      // this.$router.push({
      //   path: "/financingProjectManage/easypaySign/SignDetial",
      //   query: {
      //     payableBillFinanceId: row.payableBillFinanceId,
      //     payableBillFinanceNo: row.payableBillFinanceNo,
      //     financeCorpId: row.financeCorpId,
      //     isshowbtn: isshowbtn
      //   }
      // });
    },
    sign(row) {
      this.$router.push({
        path: "/factoring/FactoringSignDetial",
        query: {
          receivableAccountFinanceId: row.receivableAccountFinanceId,
          isshowbtn: "2",
          fundCorpId: row.fundCorpId,
          corpType: "2"
        }
      });
    },
    // 提现
    handleCash(row) {
      this.$confirm("是否确认提现?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",closeOnClickModal:false,
        type: "warning"
      }).then(() => {
         // 提现
         this.withdraw(row.receivableAccountFinanceId)
      }).catch(() => {});
    },
    withdraw(receivableAccountFinanceId) {
      api.withdraw({receivableAccountFinanceId}).then(res => {
        if (res.success) {
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.getLists({pageSize: this.pageSize, pageNum: this.pageNo })
            }
          });
        } else {
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {

            }
          });
        }
      });
    },
    // 转优企通
    handleTrans(row) {
      this.$confirm("是否确认转优企通?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",closeOnClickModal:false,
        type: "warning"
      }).then(() => {
         // 转优企通
         this.financingTurnQuota(row.receivableAccountFinanceId)
      }).catch(() => {});
    },
    financingTurnQuota(receivableAccountFinanceId) {
      api.financingTurnQuota({receivableAccountFinanceId}).then(res => {
        if (res.success) {
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.getLists({pageSize: this.pageSize, pageNum: this.pageNo })
            }
          });
        } else {
          this.$alert(res.message, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {

            }
          });
        }
      });
    },
    // 查看凭证
    handleView(row) {
      api.viewWithdrawalVoucher({receivableAccountFinanceId: row.receivableAccountFinanceId}).then(res => {
        if(res.success) {
          let imgUrl = res.datas.list[0].fileUrl
          this.fileUrl = api.getImgURL(imgUrl)
        }
      })
    },
    financeTransferStatusfn(val){
      var name=''
      switch (val) {
        case 1:
          name='未转换'
          break;
        case 2:
          name='已转换'
          break;
        case 3:
          name='退回'
          break;
        case 4:
          name='转换中'
          break;
      }
      return name
    },
    withdrawStatusfn(val) {
      var name=''
      switch (val) {
        case 1:
          name='未提现'
          break;
        case 2:
          name='已提现'
          break;
        case 3:
          name='提现中'
          break;
        case 4:
          name='退回'
          break;
      }
      return name
    }
  }
};
</script>
<style lang="scss" scoped>
.preview-box {
  position: relative;
  div {
    color: #409EFF;
  }
  .preview-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
