<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%"
        >
          <el-table-column
            prop="payableBillNo"
            label="优企通编号"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="originalName"
            label="核心企业"
            min-width="200"
          ></el-table-column>

          <el-table-column prop="originalName" label="清分类型" min-width="160">
            <template slot-scope="scope">
              {{ scope.row.liquidationType == 1 ? '企业债权' : '资方债权' }}
            </template>
          </el-table-column>

          <el-table-column
            prop="payableBillAmount"
            label="开立金额(元)"
            min-width="170"
            :formatter="(el) => $changeMoneyThousand(el.payableBillAmount)"
          ></el-table-column>

          <el-table-column
            prop="promisePayTime"
            label="承诺付款日"
            min-width="150"
            :formatter="(el) => moment(el.promisePayTime).format('YYYY-MM-DD')"
          />
          <el-table-column
            prop="payableBillFinanceAmount"
            label="已融资金额(元)"
            min-width="170"
            :formatter="
              (el) => $changeMoneyThousand(el.payableBillFinanceAmount)
            "
          ></el-table-column>
          <el-table-column
            prop="payableBillClearAmount"
            label="需清分金额(元)"
            min-width="170"
            :formatter="(el) => $changeMoneyThousand(el.payableBillClearAmount)"
          />
          <el-table-column
            prop="beClearNum"
            label="需清分企业数"
            min-width="120"
          />
          <el-table-column prop="originalName" label="逾期状态" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.isOverdue == '是' ? '逾期清分' : '正常清分' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="settlementChannelStr"
            label="结算渠道"
            min-width="110"
          />

          <el-table-column label="操作" width="120" align="left" fixed="right">
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.settlementChannel"
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >查看详情</el-button
              >
              <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >清分支付</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from '@/api/api.js';
import { changeMoneyThousand } from '@/util/common';
import moment from 'moment';

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: '优企通编号: ',
          type: 'input',
          size: 'small',
          field: 'payableBillNo',
        },
        {
          label: '承诺付款日:',
          type: 'daterange',
          field: ['startPromisePayTime', 'endPromisePayTime'],
          size: 'small',
        },
        {
          label: '清分类型:',
          type: 'select',
          field: 'type',
          options: [
            { label: '全部', value: '' },
            { label: '企业债权', value: '1' },
            { label: '资方债权', value: '2' },
          ],
          size: 'small',
        },
        {
          label: '逾期状态:',
          type: 'select',
          field: 'overduestatue',
          options: [
            { label: '全部', value: '' },
            { label: '逾期清分', value: '是' },
            { label: '正常清分', value: '否' },
          ],
          size: 'small',
        },
      ],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      changeMoneyThousand: changeMoneyThousand,
    };
  },
  created() {},
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.openCorpId = JSON.parse(sessionStorage.user).relationCorpId;
      api.getPayableBillLiquidationHxLsNew(param).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        name: '清分结算持有企业详情',
        query: {
          payableBillNo: row.payableBillNo,
          payableBillId: row.payableBillId,
          isshowbtn: 1,
          settlementChannel: row.settlementChannel,
          type: row.liquidationType,
          isOverdue: row.isOverdue,
          row,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
