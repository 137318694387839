<!-- 票据业务统计 -->
<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="bill-statistics ">
      <div class="section-second">
        <div class="bill-box">
                  <div class="bill-title">ABS业务概况</div>
        </div>

      
       <div class="section-second-content">
        <div class="card-item card-bgImg1">
          <div class="card-money card-money1">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{ $changeMoneyThousand(totalListingBillAmount) }}</span>
            
            </div>
          </div>
          <div class="card-title">进行中项目总额(元)</div>
        </div>
        <div class="card-item card-bgImg2">
          <div class="card-money card-money2">
            <div class="money-item-title" >
              <span
                class="title-color money-bold"
                style="font-size: 20px;"
              >{{$changeMoneyThousand(totalDelistingBillAmount) }}</span>
      
            </div>
          </div>
          <div class="card-title">已结束项目总额(元)</div>
        </div>
    
      </div>
      </div>
 
      <div class="bill-box">
        <div class="bill-title">ABS业务总览</div>
        <div class="bill-cont">
          <el-row :gutter="30">
            <el-col :span="12" class="position-relative">
                 <div >
                <el-select v-model="value1" @change="changeFn">
                  <el-option
                    :value="item.value"
                    :label="item.label"
                    v-for="(item, index) in selOption"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div id="main" class="echarts-box"></div>
           
            </el-col>
            <el-col :span="12" class="position-relative">
                  <div >
                <el-select v-model="value2" @change="changeFn2">
                  <el-option
                    :value="item.value"
                    :label="item.label"
                    v-for="(item, index) in selOption"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div id="main2" class="echarts-box"></div>
          
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import echarts from "echarts";
import api from "@/api/api";

export default {
  data() {
    return {
      breadcrumblist: ["业务统计"],
      totalListingBillAmount: 0,
      totalDelistingBillAmount: 0,
      selOption: [
        { value: 1, label: "月视图" },
        { value: 2, label: "年视图" }
      ],
      value1: 1,
      value2: 1,
      selOption1: [],
      selOption2: [],
      corp: {},
      days:[],
      days2:[],
      legends:[],
      legends2:[],
      leaderCorpType:1
    };
  },
  created() {
    this.leaderCorpType=JSON.parse(sessionStorage.user).corpType==1?1:2

  },
  mounted() {

      this.getdays()
      this.getdays2()
      this.init1(this.value1)
      this.init2(this.value2)
   

  },
  methods: {
      changeFn(val){
          this.value1=val
          this.init1(val)

          this.getdays()
      },
      changeFn2(val){
        this.value2=val
          this.getdays2()
          this.init2(val)

      },
      getdays(){
        this.days=[]
        if(this.value1==1){
             var date=new Date()
        var year =date.getFullYear(),
            month =new Date().getMonth();
          for(var i=1;i<=new Date(year,month+1,0).getDate();i++){
            this.days.push(i+'号')
          }
        }else{
            this.days=  [
                          "一月",
                          "二月",
                          "三月",
                          "四月",
                          "五月",
                          "六月",
                          "七月",
                          "八月",
                          "九月",
                          "十月",
                          "十一月",
                          "十二月"
                ]
        }
         
      

      },
       getdays2(){
        this.days2=[]
        if(this.value2==1){
             var date=new Date()
        var year =date.getFullYear(),
            month =new Date().getMonth();
          for(var i=1;i<=new Date(year,month+1,0).getDate();i++){
            this.days2.push(i+'号')
          }
        }else{
            this.days2=  [
                          "一月",
                          "二月",
                          "三月",
                          "四月",
                          "五月",
                          "六月",
                          "七月",
                          "八月",
                          "九月",
                          "十月",
                          "十一月",
                          "十二月"
                ]
        }
         
      

      },
      init1(type){
      
          api
        .homeStatistics({ status:1,type:type,leaderCorpType:this.leaderCorpType })
        .then(res => {
          if (res.success) {
            this.totalListingBillAmount=res.datas.ongoingTotalAmount
            this.totalDelistingBillAmount=res.datas.overTotalAmount

            var mainarr=this.value1==1?res.datas.ongoingMonthAmount:res.datas.ongoingYearAmount;
            mainarr.map(el=>{
                this.legends.push(el.absPlanName)
               
              
            })

            this.legends=Array.from(new Set(this.legends));   //整合legends 
            var obj={};    //整合后台数据
            for(var i=0;i<this.legends.length;i++){
                  obj[this.legends[i]]={amount:[],absPlanName:[]}
            }
            //给amout补位置
              Object.keys(obj).map(el=>{
                 this.days.map((num,i)=>{
                        obj[el].amount.push(0)
                      })
            })
            mainarr.map(el=>{
               Object.keys(obj).map(ele=>{                  
                      if(el.absPlanName==ele){
                          obj[ele].absPlanName.push(el.absPlanName);
                       //  console.log( el.time.split('-')[2]-1,'ddd')   //获取当前日期在哪个位置
                          if(this.value1==1){   //往获取的位置赋值
                             obj[ele].amount[el.time.split('-')[2]-1]=el.amount   

                          }else{
                             obj[ele].amount[el.time.split('-')[1]-1]=el.amount   
                            
                          }
                      }

               })
            })
        console.log(obj,';kkkkkk')
            //整合series
            var series=[]
            Object.keys(obj).map(el=>{
                series.push({name:el,type:'bar',stack: '广告',data:obj[el].amount})
            })
     
               var myChart = echarts.init(document.getElementById('main'));
            

              var option = {
                  tooltip : {
                      trigger: 'axis',
                      axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                          type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                      }
                  },
                  legend: {
                      data:this.legends,
                      top:"8%",
                       type: 'scroll',
                  },
                  title:{
                    text:'收集中ABS项目'
                  },
                  grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                 
                      containLabel: true
                  },
                  xAxis : [
                      {
                          type : 'category',
                          data : this.days
                      }
                  ],
                  yAxis : [
                      {
                          type : 'value'
                      }
                  ],
                  series :series
              };

              myChart.setOption(option);
          }else{
            this.$message.error(res.$message)
          }
        });


    
    

     
      },
      init2(type){
               api
        .homeStatistics({ status:2,type:type,leaderCorpType:this.leaderCorpType })
        .then(res => {
          if (res.success) {
            this.totalListingBillAmount=res.datas.ongoingTotalAmount
            this.totalDelistingBillAmount=res.datas.overTotalAmount

            var mainarr=this.value2==1?res.datas.overMonthAmount:res.datas.overYearAmount;
    
            mainarr.map(el=>{
                this.legends2.push(el.absPlanName)
               
               
            })

            this.legends2=Array.from(new Set(this.legends2));   //整合legends 
            var obj={};    //整合后台数据
            for(var i=0;i<this.legends2.length;i++){
                  obj[this.legends2[i]]={amount:[],absPlanName:[]}
            }
            //给amout补位置
              Object.keys(obj).map(el=>{
                 this.days2.map((num,i)=>{
                        obj[el].amount.push(0)
                      })
            })
            mainarr.map(el=>{
               Object.keys(obj).map(ele=>{                  
                      if(el.absPlanName==ele){
                          obj[ele].absPlanName.push(el.absPlanName);
                       //  console.log( el.time.split('-')[2]-1,'ddd')   //获取当前日期在哪个位置
                          if(this.value2==1){   //往获取的位置赋值
                             obj[ele].amount[el.time.split('-')[2]-1]=el.amount   

                          }else{
                             obj[ele].amount[el.time.split('-')[1]-1]=el.amount   
                            
                          }
                      }

               })
            })
            console.log(obj)
            //整合series
            var series=[]
            Object.keys(obj).map(el=>{
                series.push({name:el,type:'bar',stack: '广告',data:obj[el].amount})
            })
            console.log(obj,series,this.days2,'day2')
               var myChart2 = echarts.init(document.getElementById('main2'));
            

              var option = {
                  tooltip : {
                      trigger: 'axis',
                      axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                          type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                      }
                  },
                   legend: {
                      data:this.legends2,
                      top:"8%",
                       type: 'scroll',
                  },
                  grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                  },
                    title:{
                    text:'已结束ABS项目'
                  },
                  xAxis : [
                      {
                          type : 'category',
                          data : this.days2
                      }
                  ],
                  yAxis : [
                      {
                          type : 'value'
                      }
                  ],
                  series :series
              };

              myChart2.setOption(option);
          }else{
            this.$message.error(res.$message)
          }
        });
      } 
 
  }
};
</script>
<style lang="scss" scoped>
.bill-statistics {
    .section-second-content {
    height: 75%;
    background-color: white;
    .card-item {
      width: 24%;
      height: 100px;
      /*margin-left: 24px;*/
      margin-top: 30px;
      /*float: left;*/
      .card-title {
        margin-left: 0.8%;
        /*margin-top: 24px;*/
        font-size: 14px;
        padding-left: 40px;
        /*color: #fff;*/
      }
      .card-money1 {
        background-image: url("../../assets/zsx.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }
      .card-money2 {
        background-image: url("../../assets/zkl.png");
        background-repeat: no-repeat;
        background-position: 0% 35%;
        padding-left: 40px;
      }

      .card-money {
        font-size: 30px;
        color: #262626;
        line-height: 45px;
        margin-left: 14px;
        margin-top: 14px;
        .money-bold {
          font-family: HelveticaNeue-Medium;
        }
      }
    }
  }
  .section-second-content {
    height: 75%;
    background-color: white;
    overflow: hidden;
    .card-item {
      width: 22%;
    
      margin-left: 24px;
      margin-top: 30px;
      float: left;
      .card-title {
        margin-left: 8px;
        /*margin-top: 24px;*/
        font-size: 14px;
        color: #fff;
      }
      .card-money {
        margin-left: 8px;
        margin-top: 14px;
        font-size: 14px;
        color: #fff;
        .money-bold {
          font-family: HelveticaNeue-Bold;
        }
      }
    }
  }
  .card-bgImg1 {
  background-image: url("../../assets/workbench/zsxeu.png");
  background-size: 100% 100%;
}
.card-bgImg2 {
  background-image: url("../../assets/workbench/zjded.png");
  background-size: 100% 100%;
}
  .section-second{
    margin: 0;
    width: 100%;
  }
  .bill-box {
    background: #fff;
    border-radius: 2px;
 
    .bill-title {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      // padding-left: 12px;
      // margin-bottom: 24px;
      padding: 16px 12px 16px 28px;
      border-bottom: 1px solid #e6e6e6;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -7px;
        width: 3px;
        height: 14px;
        background: #cb8e51;
      }
    }
    .bill-cont {
      padding: 24px;
      .bill-card {
        display: flex;
        align-items: center;
        padding: 32px;
        &.bg {
          width: 100%;
          height: 122px;
          background: url("../../assets/bg_pjywgk.png") no-repeat;
          background-size: 100% 100%;
          border-radius: 2px;
        }
        &-item {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
          &-title {
            margin-bottom: 4px;
          }
          &-total {
            span {
              font-size: 24px;
              color: #fff;
              line-height: 32px;
              font-weight: 600;
            }
          }
          &.bill-card-item {
            margin-left: 80px;
          }
        }
      }
      .echarts-box {
        height: 400px;
      }
    }
  }
  .position-relative {
    position: relative;
    .select-wrap {
      position: absolute;
      right: 60px;
      top: 0;
    }
  }
}
</style>
