<!-- 票据详情 -->
<template>
	<div class="bill-approvaled-details">
		<layout-main-view :breadcrumb='breadcrumblist'>
			<bill-details :detailsList='detailsList'></bill-details>
			<billzjjg ref="tabprice"></billzjjg>
      <div style="text-align:center;padding: 30px 0;background-color: #fff;" class="xytext">
          <el-checkbox v-model="checked" @change="showDialog(checked)">我已阅读并同意</el-checkbox>
          <a href="javascript:;" @click="showDialog(checked,'2')">平台收费服务协议</a>
        </div>
			<div class="footer-btn">
				<el-button type="primary" @click="reject">取消</el-button>
				<el-button type="primary" @click="agreepricefn">接受报价</el-button>
			</div>
		</layout-main-view>
    <el-dialog id="dialog" class="agreement-dialog" :visible.sync="agreementDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="50%" center>
      <div class="agreement-container">
      <charge-agreement v-if="fileType == '2'" :chargeInfo="chargeInfo"></charge-agreement>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="!showType && !readDisabled" @click="hasRead()">同意</el-button>
        <el-button type="primary" v-if="!showType && readDisabled" :disabled="readDisabled">阅读并同意 {{readCount}}秒</el-button>
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>
		<el-dialog title="缴纳平台服务费" :visible.sync="dialogVisible" width="70%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<div class="dialog-info-title">尊敬的用户，接受资方企业报价之前需向平台缴纳相应的服务费用</div>
			<el-alert title="平台服务费用按照融资金额的0.3%进行计算" type="warning" show-icon :closable="false"></el-alert>
			<ul class="base-info-ul">
				<li>收费类型：{{getTypes(costInfo1.types)}}</li>
				<li>收费企业：{{costInfo1.receivedCorpName}}</li>

				<li>金额：{{$changeMoneyThousand(costInfo1.amount)}} 元人民币</li>
<!--				<li>收费类型：{{getTypes(costInfo2.types)}}</li>-->
<!--				<li>收费企业：{{costInfo2.receivedCorpName}}</li>-->

<!--				<li>金额：{{$changeMoneyThousand(costInfo2.amount)}} 元人民币</li>-->
				<li>总计：</li>
				<li>总额：{{$changeMoneyThousand(0..add(...[costInfo1.amount, costInfo2.amount]))}} 元人民币</li>
			</ul>
			<div style="text-align: center;margin-top: 20px;">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="confirmPaymentServiceFee" style="margin-left: 40px;" :disabled="forbidden">缴费</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import api from "@/api/api";
import moment from "moment";
import billDetails from "@/components/billDetails";
import billzjjg from "../pjbase/billzjjg";
import chargeAgreement from "@/components/agreementList/charge-agreement";

export default {
  data() {
    return {
      breadcrumblist: ["票据审批详情"],
      detailsList: {},
      approvalVisible: false,
      regionList: [],
      approvalForm: {
        billId: this.$route.query.id,
        approveResult: "",
        reason: "",
        refuseReason: "",
        remark: "",
        approveNode: ""
      },
      active: 0,
			approvalDetailsList: {},
			dialogVisible: false,
      forbidden: false,
      costInfo: [],
      costInfo1: {},
      costInfo2: {},
			payStatus: '',
      loading: "",
      checked: false,
      agreementDialog: false,
      fileType: "2",
      showType: '',
      readDisabled: true,
      readCount: "",
      chargeInfo: {
        no: "", // 合同编号
        financeCorpName: "", // 融资申请人/账户名
        legalName: "", // 法定代表人
        creditCode: "", // 统一社会信用代码
        telephone: "", // 联系方式
        registerAddr: "", // 联系地址
        openBankReceiver: "", // 开户行
        publicAccountReceiver: "", // 账号
      },
    };
  },
  created() {
    this.init();
    api.GetApproveListByBillId({ billId: this.$route.query.id }).then(res => {
      //审批结果查询
      if (res.success) {
        this.approvalDetailsList = res.datas.billApproveDtos[0];
      }
    });
    this.getContractContent()
  },
  filters: {
    formatDate: value => {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  methods: {
		startLoading: function(){
			this.loading = this.$loading({
				lock: true,
				text: '请等待',
				background: 'rgba(0, 0, 0, 0.5)',
			})
		},
		endLoading: function(){
			this.loading.close()
		},
    init() {
      let id = this.$route.query.id;
      api.BillDetails({ billId: id }).then(res => {
				this.payStatus = res.datas.billDto.payStatus // 缴费状态 1待支付 2支付中 3支付成功 4支付失败
        this.detailsList = res.datas.billDto;
        this.approvalForm.approveNode = this.detailsList.billApproveStatus;
        this.detailsList.billFile.forEach((item, index) => {
          if (item.fileType == 2) {
            this.detailsList.billFileBack = api.getImgURL(item.fileUrl);
          } else {
            this.detailsList.billFileFront = api.getImgURL(item.fileUrl);
          }
        });
        this.detailsList.billFlaw = this.detailsList.billFlaw.split(",");
        this.detailsList.hideStatus == 1
          ? (this.detailsList.hideStatus = false)
          : (this.detailsList.hideStatus = true);
      });
    },
    reject() {
      history.go(-1);
    },
    agreepricefn() {
      if (!this.checked) {
        this.$message.warning("请先阅读并同意协议");
        return false;
      }
      if (!this.$refs.tabprice.radio) {
        this.$message.error("请选择报价企业");
			} else {
				if(this.payStatus == 1 || this.payStatus == 4) {
					// let amount = this.$refs.tabprice.tableData.filter(v => v.billFundQuoteId == this.$refs.tabprice.radio)[0].dealAmount
					let amount = this.detailsList.billAmount
					this.serviceFeeBreakDown(amount)
          this.dialogVisible = true
        } else if(this.payStatus == 3) {
          this.acceptFundQuote()
        }
			}
		},
    serviceFeeBreakDown(amount) {
			this.startLoading()
      // type 1保理模式 2票据模式
      api.serviceFeeBreakDown({amount, type: 2}).then(res => {
        if(res.success) {
          // types 1科技费,2信息费,3支付手续费
          res.datas.list.forEach(v => {
            this.costInfo.push({
              billId: this.$route.query.id,
              openCorpName: v.openCorpName,
              receivedCorpName: v.receivedCorpName,
              payerFundAccount: v.payerFundAccount,
              beneficiaryFundAccount: v.beneficiaryFundAccount,
              amount: v.amount,
              types: v.types,
            })

            if(v.types == 1) {
              this.costInfo1 = v
            } else if(v.types == 2) {
              this.costInfo2 = v
            }
					})
					this.endLoading()
        } else {
					this.endLoading()
					this.$message.error(res.message);
				}
      })
    },
    getTypes(types) {
      if(types == 1) {
        return '平台服务费'
      } else if(types == 2) {
        return '信息费'
      } else if(types == 3) {
        return '支付手续费'
      } 
    },
    // 缴纳服务费
    confirmPaymentServiceFee() {
      this.startLoading()
      this.forbidden = true
      api.confirmPaymentServiceFee({payableBillPayList: this.costInfo}).then(res => {
        if(res.code == 'SUCCESS') {
          this.endLoading()
          this.forbidden = false
          this.$alert('缴费成功，请接受报价', "", {
            confirmButtonText: "确定",
            callback: action => {
              this.acceptFundQuote()
            }
          });
        } else {
          this.endLoading()
          this.forbidden = false
          this.$alert(res.msg, "", {
            confirmButtonText: "确定",
            callback: action => {
              this.dialogVisible = false
            }
          });
        }
      })
    },
    // 接受报价
    acceptFundQuote() {
			 api.acceptFundQuote({
          billId: this.$route.query.id,
          billFundQuoteId: this.$refs.tabprice.radio
        })
        .then(res => {
          if (res.success) {
						this.dialogVisible = false
            this.$alert("接受报价成功", "", {
              confirmButtonText: "确定",
              callback: action => {
                history.go(-1);
              }
            });
          } else {
						this.$message.error(res.message);
					}
        });
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      if(type) {
        if (type == "2") {
          this.$set(this.$data, "fileType", "2");
          this.agreementDialog = true;
          this.readCountDown();
        }
      } else {
        if (this.checked) {
          this.checked = false;
          this.agreementDialog = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      if (!this.showType) {
        this.readCountDown();
      }
      this.checked = true;
      this.agreementDialog = false;
      this.readDisabled = false;
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    // 获取 融资服务协议内容
    getContractContent() {
      let param = {
        itemId: 1,
        itemType: 1
      }
      api.getContractContent(param).then(res => {
        if(res.success) {
          this.chargeInfo = {...this.chargeInfo, ...res.datas.content}
        }
      })
    }
  },
  components: {
    "bill-details": billDetails,
    billzjjg: billzjjg,
    chargeAgreement
  }
};
</script>
<style lang="scss">
.bill-approvaled-details {
  .footer-btn {
    text-align: center;
    margin-top: 30px;
    .el-button {
      & + .el-button {
        margin-left: 40px;
      }
    }
  }
  .approval-result {
    padding: 33px 25px 0 25px;
    background: #fff;
    .approval-title {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      padding-left: 12px;
      margin-bottom: 24px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        width: 3px;
        height: 14px;
        background: #cb8e51;
      }
    }
    .approval-cont {
      display: flex;
      justify-content: space-between;
      i {
        color: #09bb07;
      }
    }
  }
  .success {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 100px 0;
    .center-cont {
      text-align: center;
      .approval-people,
      .approval-time {
        font-size: 16px;
        color: #666;
        margin-bottom: 15px;
      }
    }
    background: #fff;
    .el-steps {
      justify-content: center;
      .el-step__title {
        &.is-success {
          color: #2937a6;
        }
      }
    }
    .el-icon-circle-check {
      font-size: 100px;
      color: #09bb07;
    }
    p {
      font-size: 20px;
      font-weight: 600;
      margin: 20px 0;
    }
    .el-button {
      width: 120px;
      height: 40px;
      line-height: 40px;
      padding: 0;
      margin-top: 30px;
      text-align: center;
      & + .el-button {
        margin-left: 50px;
      }
    }
  }
}
</style>