<!-- 票据详情 -->
<template>
    <div class="abs-xmgk-details">
      
     
            <el-row >
                <el-col :span="24">
                    <span class="label">账款编号:</span>
                    <span class="value">{{fundCorp.absPlanName}}</span>
                </el-col>
          
          <el-col :span="8" class="lb_body">
            <span class="info-key">甲方:</span>
            <span class="info-value">{{fundCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{fundCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{fundCorp.legalName}}</span>
          </el-col>
      
          <el-col :span="8" class="lb_body">
            <span class="info-key">乙方:</span>
            <span class="info-value index">{{financeCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{financeCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{financeCorp.legalName}}</span>
          </el-col>
  
          

          
            
            </el-row>
     
         
       
        
       
    </div>
</template>
<script>
    import moment from 'moment'


    export default {
        props: {
            fundCorp: {
                type: Object,
                default() {
                    return {
                    
                    }
                }
            },
             financeCorp: {
                type: Object,
                default() {
                    return {
                    
                    }
                }
            }
            
        },

        data() {
            return {
              
            }
        },
        mounted() {
 
     
        },
        methods: {
            moment: moment,
     
        }
    }
</script>
<style lang="scss">
     .titlemsgabs{
         width: 300px;
         font-size: 13px;


     } 
  .abs-xmgk-details{
      background: #ffffff;
      margin-bottom: 20px;

 
          .el-row{
              text-indent: 1em;
              .el-col{
                  margin-bottom: 14px;
              }
          }
        .abs-title{
            position: relative;
         font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
      
            margin-bottom: 24px;
        
        }

  
  }
  
</style>