<!-- 发布票据 -->
<template>
 
      

        <div  class="release-success">
            <div><i class="el-icon-error"></i></div>
            <p>专项计划已经退回。</p>
            <div>
                <el-button type="primary" @click="goBillManage">我知道了</el-button>
  
            </div>
        </div>
 
</template>
<script>
    import api from "@/api/api";
    import upload from "../../api/upload";
    import {convertCurrency} from '@/util/common'
    import billAgreement from "@/components/agreementList/bill-agreement";
    export default {
        data() {
  
            return {
          
            }
        },
        created() {
              
                             
        },
        methods: {
            goBillManage(){
                     this.$router.push({path:'/abs/absplan/1?pageNum=1&pageSize=10'})
                      
            }
      
        },
        components: {
            billAgreement,
        }
    }
</script>
<style lang="scss">
.release-success{
    .el-icon-error{
        color: #E84444;
    }
}
  
</style>