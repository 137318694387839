<template>
  <div>
    <!--<Step class="m-top" qiv-bind:step-value="stepValues"></Step>-->
    <div class="main-contentTop1 active">
    </div>
      <div class="main-TopContentBottom">
        <div class="steps">
          <el-steps :active="1" align-center >
            <el-step title="个人信息认证"></el-step>
            <el-step title="用户人脸识别"></el-step>
            <el-step title="个人认证成功"></el-step>
          </el-steps>
        </div>
      </div>

    <div class="register_main">
      <el-form
        :model="form"
        :rules="rules"
        ref="registerForm"
        label-position="right"
        label-width="140px"
        class="demo-ruleForm registerDemo login-container"
      >
        <div class="first_step">
          <h2>人员资料完善</h2>
          <el-form-item
            label="上传本人身份证："
            class="company1 upload-text-requird"
          >
            <div class="certificates">
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">人像面</span>
                <div
                  @click="getFileType('idCard')"
                  :class="{imgClass:!isEmpty(legalInfoIdCardFrontFileUrl)}"
                >
                  <el-upload
                  ref="uploadFile"
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="idCardUpload()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="uploadIdCardCallBack"
                    :on-remove="IDLegalInfojustRemove"
                    :file-list="fileList1"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(form.legalInfo.idCardFrontFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">国徽面</span>
                <div
                  @click="getFileType('idCardReverse')"
                  :class="{imgClass:!isEmpty(legalInfoIdCardBackFileUrl)}"
                >
                  <el-upload
                  ref="uploadFile2"
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="idCardUpload2()"
                    list-type="picture-card"
                    :data="fileType"
                    :before-upload="beforeAvatarUpload"
                    :on-success="idCardhandleAvatarSuccessCallback"
                    :on-remove="IDLegalInfoIdReverseRemove"
                    :file-list="fileList2"
                    :headers="Authorization"

                  >
                    <i v-if="isEmpty(legalInfoIdCardBackFileUrl)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
            </div>
            <label
              style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;"
            >仅支持JPG、PNG格式，文件小于5M。请尽量使用高清质量图片。</label>
          </el-form-item>
          <el-form-item label="本人姓名：" class="company1" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="本人身份证号码：" class="company1" prop="idCard">
            <el-input v-model="form.idCard"></el-input>
          </el-form-item>
     
   
          <el-form-item class="item-btn">
            <el-button
              type="primary"
              size="small"
              @click.native="back()"
              style="width: 160px;height: 40px;background: white;color: black;margin-right: 32px"
            >上一步</el-button>
            <el-button
              type="primary"
              size="small"
              @click.native="nextStep()"
              style="width: 160px;height: 40px;background: #2937A6;"
            >下一步</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import Step from "./Step";
import areaJson from "../../const/area.json";
import goverArea from "../../const/government.json";
import upload from "../../api/upload";
import _ from "lodash";
export default {
  data() {
    return {
      userId:JSON.parse(sessionStorage.getItem('userId')),
      Authorization: {Authorization: sessionStorage.token},
      form: {
          name:'',
          idCard:"",
          idCardFront:'',
          idCardReverse:'',
          userId:'',
          legalInfo:{}
      },
      corpId: "",
      stepValues: 2,
      fileType: {
        type: "",
        registerCode: ""
      },
      registerForm: {
        name: "",
        enpsLicense: "",
        provinceAddress: "",
        cityAddress: "",
        legalAddress: "",
        controller: 1,
        fillPersion: 1,
        idCardFrontFileUrl: "" // 法人身份证正面
      },

      legalInfoIdCardFrontFileUrl: "", //法人身份证正面
      legalInfoIdCardBackFileUrl: "", //法人国徽页
      fileList1: [], //回显法人身份证正面
      fileList2: [], //回显法人身份证反面
      rules: {
          name: [
            { required: true, message: "请输个人姓名", trigger: "blur" }
          ],
          idCard:[
            { required: true, message: "请输个人身份证号", trigger: "blur" },
            { pattern: /^\d{17}[\dx]$/i, message: '请输入18位身份证号码' },
            


          ]


      },
      loading: {},
    };
  },
  components: {
    Step
  },
  mounted() {
    // this.getProvince();
    this.form.userId = this.$route.query.corpId;
   this.getInfo();
  },
  computed: {
    supName() {
      return this.$store.getters.supName;
    }
  },
  methods: {
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    // 国徽页图片上传
    idCardUpload2() {
      return upload.eqptImgSrc();
    },
    // 国徽页图片上传
    iAgentIdCardUpload2() {
      return upload.eqptImgSrc();
    },
    //身份证正面图片上传
    idCardUpload() {
      return upload.uploadIDCard();
    },
    iAgentIdCardUpload() {
      return upload.uploadIDCard();
    },
        getFileType(val) {
      this.fileType = {
        type: val,
        registerCode: sessionStorage.getItem("code")
      };
    },

    //回显数据
    getInfo() {
      api.getPersonalAuthInfo({userId:this.form.userId}).then(res=>{
        if(res.success) {
          this.form.name = res.datas.data.name
          this.form.idCard = res.datas.data.idCard;
          this.fileList1.push({
                name: "人像面",
                url: api.getImgURL(res.datas.data.idCardFront)
              });
          this.legalInfoIdCardFrontFileUrl = res.datas.data.idCardFront
          this.form.legalInfo.idCardFrontFileUrl = res.datas.data.idCardFront
              this.fileList2.push({
                name: "国徽面",
                url: api.getImgURL(res.datas.data.idCardReverse)
              });
              this.legalInfoIdCardBackFileUrl = res.datas.data.idCardReverse
        }else {
          this.$message.error(res.message)
        }
      }).catch(err=>{})
    },

    //上传法人身份证正面图片上传之后的回显
    uploadIdCardCallBack(res,file) {
      if (res.code == 100000) {
        this.form.name = res.datas.ocrResult.Name;
        this.form.idCard = res.datas.ocrResult.IdentificationNumber;
        this.form.idCardFront = res.datas.uploadResult
        this.form.legalInfo.idCardFrontFileUrl = res.datas.uploadResult
          // res.datas.uploadResult.fullPath;
        this.legalInfoIdCardFrontFileUrl = res.datas.uploadResult;
        console.log(this.legalInfoIdCardFrontFileUrl, 5656565);
        this.$message.success('上传成功');
        this.loading.close();

      } else {
        this.$message.error(res.message);
        this.loading.close();
        let uid = file.uid // 关键作用代码，去除文件列表失败文件
          let idx = this.$refs.uploadFile.fileList.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
          console.log(idx,"=======",this.$refs.uploadFile.fileList);
          this.$refs.uploadFile.fileList.splice(0) // 关键作用代码，去除文件列表失败文件
      }
    },
    //法人国徽页
    idCardhandleAvatarSuccessCallback(res) {
      if (res.code == 100000) {
        this.form.idCardReverse =
          res.datas.uploadDate.message;
        this.legalInfoIdCardBackFileUrl = res.datas.uploadDate.message;
        console.log(this.legalInfoIdCardBackFileUrl, 89898989);
        this.$message.success('上传成功');
        this.loading.close();
      } else {
        this.$message.error(res.message);
        this.loading.close();
        let uid = file.uid // 关键作用代码，去除文件列表失败文件
          let idx = this.$refs.uploadFile2.fileList.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
          console.log(idx,"=======",this.$refs.uploadFile2.fileList);
          this.$refs.uploadFile2.fileList.splice(0) // 关键作用代码，去除文件列表失败文件
      }
    },

    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.$message.success('上传成功');
        if (this.fileType.type === "license") {
          this.licenseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "legalFacade") {
          this.idCardFrontFileUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "legalReverse") {
          this.idCardBackFileUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "agentFacade") {
          this.agentJustUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "agentReverse") {
          this.agentReverseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === "entrustTable") {
          this.entrustUrl = URL.createObjectURL(file.raw);
          return;
        }
      }
    },
    // 法人身份证正面
    IDLegalInfojustRemove(file) {
      this.form.legalInfo.idCardFrontFileUrl = "";
      this.legalInfoIdCardFrontFileUrl=''
        console.log(this.legalInfoIdCardFrontFileUrl, 5656565);


    },
    // 法人身份证反面
    IDLegalInfoIdReverseRemove(file) {
      this.form.legalInfo.idCardBackFileUrl = "";
      this.legalInfoIdCardBackFileUrl = "";
    },

    beforeAvatarUpload(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("您上传的文件格式有误，请重新上传");
        return false;
      }
      //判断文件大小 2M 以内
      const isLt2M = file.size / 1024 / 1024 < 5
      console.log('file =======>',file);
      if(!isLt2M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        console.log('文件大于两兆')
        return false;
      }

      const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        this.loading = loading;
    },
    nextStep(val) {

        if (_.isEmpty(this.legalInfoIdCardFrontFileUrl)) {
          this.$message({
            message: "请上传个人身份证头像页",
            type: "error"
          });
          return;
        }
        if (_.isEmpty(this.legalInfoIdCardBackFileUrl)) {
          this.$message({
            message: "请上传个人身份证国徽页",
            type: "error"
          });
          return;
        }


      this.$refs.registerForm.validate(valid => {
        if (valid) {
          let para = { ...this.form };
    console.log(para,'para')
         
          api.savePersonalInformationAuth(para).then(res => {
        
            if (res.code === "100000") {

               api.InformationCheck({userId:+this.form.userId}).then(res => {
  
                      if (res.code === "100000") {
                        
                        this.$router.push({
                          path: "/customervideo",
                          query: { corpId: this.form.userId}
                        });
                      } else {
                        this.$message({
                          message: res.message,
                          type: "error"
                        });
                      }
                    });
              // this.$router.push({
              //   name: "CompanyConfirm",
              //   query: { corpId: this.form.corpId}
              // });
            } else {
              this.$message({
                message: res.message,
                type: "error"
              });
            }
          });
        }
      });
    },
    back() {
      // this.$router.go(-1); //返回上一层
      this.$router.push({
        path: "/login",

      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.el-form-item__content {
  line-height: 30px !important;
}

.required:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}
.required1:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 20px;
}
.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss" >
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1.active{
  height: 239px;
  /*background-color: red;*/
  background: url("../../assets/workbench/header.png") center;
  background-size: cover

}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top:0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon{
    width: 32px;
    height: 32px;
  }
}
</style>
