<template>
  <el-dialog
    :title="dialogModel.modelLabel"
    :visible.sync="dialogVisible"
    width="50%"
    @close="handleClose"
  >
    <el-form ref="form" :model="dataForm" label-width="auto" :rules="rules">
      <el-form-item label="供应商企业" prop="scmCorpId">
        <el-select
          v-if="!dialogModel.isAudit"
          v-model="dataForm.scmCorpId"
          placeholder="请选择供应商企业"
          filterable
          @change="changeSupplier"
        >
          <el-option
            v-for="item in corpList"
            :key="item.corpId"
            :label="item.corpName"
            :value="item.corpId"
          >
          </el-option>
        </el-select>
        <span v-else>{{ dataForm.scmCorpName }}</span>
      </el-form-item>
      <el-form-item label="供应商额度(元)" prop="availableAmount">
        <el-input
          v-if="!dialogModel.isAudit"
          v-model="dataForm.availableAmount"
        ></el-input>
        <span v-else>{{ $changeMoneyThousand(dataForm.availableAmount) }}</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <template v-if="dialogModel.isAudit">
        <el-button type="primary" :loading="loadingAudit" @click="pass"
          >提交审批</el-button
        >
        <el-button :loading="loadingAudit" @click="auditReturn">退回</el-button>
      </template>
      <template v-else>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loadingSubmit" @click="submit"
          >确 定</el-button
        >
      </template>
    </span>

    <SupplierQuotaAudit
      ref="SupplierQuotaAudit"
      @success="(params) => audit(true, params)"
    ></SupplierQuotaAudit>
  </el-dialog>
</template>

<script>
import api from "@/api/api";
import SupplierQuotaAudit from "./supplier-quota-audit.vue";

const createDataForm = () => ({
  corpAmountId: null,
  scmCorpId: null,
  scmCorpName: null,
  availableAmount: null,
});

export default {
  components: { SupplierQuotaAudit },
  emits: ["success"],
  data() {
    return {
      dialogVisible: false,
      /** 提交loading */
      loadingSubmit: false,
      /** 审核通过 */
      loadingAudit: false,
      dataForm: createDataForm(),
      corpList: [],
    };
  },
  computed: {
    rules() {
      if (this.dialogModel.isAudit) {
        return {};
      }
      return {
        scmCorpId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        availableAmount: [
          { required: true, message: "请输入供应商额度", trigger: "change" },
          {
            pattern: /^\d{1,12}(\.\d{1,2})?$/,
            message: "请输入12位以内的数字,小数点后最多2位",
            trigger: "change",
          },
        ],
      };
    },
    dialogModel() {
      const isAudit = /-audit/.test(this.$route.path);
      const isEdit = !!this.dataForm?.corpAmountId;
      const label = "额度";
      let model = "新增";
      if (isAudit) {
        model = "审批";
      } else if (isEdit) {
        model = "编辑";
      }
      const modelLabel = `${model}${label}`;
      return {
        modelLabel,
        label,
        model,
        isAudit,
        isEdit,
      };
    },
    corpName() {
      return this.$store.state.corpName;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    this.querySupplierList();
  },
  methods: {
    show(form = {}) {
      Object.assign(this.dataForm, form);
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dataForm = createDataForm();
    },
    /** 查询供应商列表 */
    async querySupplierList() {
      const res = await api.getSupplierList({
        pageNum: 1,
        pageSize: 999,
      });
      if (res.code === "100000") {
        this.corpList = res.datas?.list || [];
      }
    },
    /** 更换供应商名字 */
    changeSupplier(corpId) {
      const findItem = this.corpList.find((item) => item.corpId === corpId);
      if (findItem) {
        this.dataForm.scmCorpName = findItem.corpName;
      }
    },
    async submit() {
      if (
        (await this.$refs.form.validate()) &&
        (await this.$confirm(
          `是否确认${this.dialogModel.modelLabel}`,
          this.dialogModel.modelLabel
        ).catch(() => false))
      ) {
        this.loadingSubmit = true;
        let request = api.addSupplierQuota;
        if (this.dialogModel.isEdit) {
          request = api.updateSupplierQuota;
        }
        const res = await request({
          ...this.dataForm,
          coreCorpId: this.userInfo?.relationCorpId,
          coreCorpName: this.corpName,
        });
        if (res.success) {
          this.$message.success(`${this.dialogModel.modelLabel}成功`);
          this.dialogVisible = false;
          this.$emit("success");
        } else {
          this.$message.error(res.message);
        }
        this.loadingSubmit = false;
      }
    },
    async pass() {
      if (
        await this.$confirm(
          `是否确认审核通过${this.dialogModel.label}`,
          `审核通过${this.dialogModel.label}`
        ).catch(() => false)
      ) {
        this.audit();
      }
    },
    async auditReturn() {
      this.$refs.SupplierQuotaAudit.show();
    },
    async audit(isReject = false, params) {
      this.loadingAudit = true;
      let request = isReject ? api.returnSupplierQuota : api.passSupplierQuota;
      const res = await request({
        checkLevel: 0,
        corpAmountId: this.dataForm.corpAmountId,
        coreCorpId: this.userInfo?.relationCorpId,
        ...params,
      });
      if (res.success) {
        this.$message.success(`审核通过成功`);
        this.dialogVisible = false;
        this.$emit("success");
      } else {
        this.$message.error(res.message);
      }
      this.loadingAudit = false;
    },
  },
};
</script>

<style lang="scss">
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
