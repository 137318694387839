<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%"
        >
          <el-table-column
            prop="payableBillNo"
            label="优企通编号"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="financePayableBillNo"
            label="融资子优企通编号"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="payableBillFinanceNo"
            label="融资项目编号"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="fundCorpName"
            label="资金机构"
            min-width="170"
          ></el-table-column>
          <el-table-column label="融资总额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.amount)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="financeYearInterest"
            label="融资年化利率(％)"
            min-width="150"
          ></el-table-column>
          <el-table-column label="融资利息(元)" min-width="170">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.preFinanceInterest)
            }}</template>
          </el-table-column>
          <el-table-column label="预计融资金额(元)" min-width="170">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.preIncomeAmount)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="financePayStatus"
            label="融资状态"
            min-width="130"
          >
            <template slot-scope="scope">
              <span>{{ getFinancePayStatus(scope.row.financePayStatus) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="优企通状态" width="100" align="left"></el-table-column> -->
          <el-table-column label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <!-- <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >查看合同</el-button
              >
              <el-button
                size="medium"
                type="text"
                @click="handleDownload(scope.row)"
                >下载融资合同</el-button
              > -->
              <!-- <div v-if="scope.row.financePayStatus != 1"> -->
              <!-- <el-upload v-if="scope.row.financePayStatus == 1"
                    class="upload-demo upload-list"
                    :action="uploadFile()"
                    :before-upload="beforeUp"
                    :on-success="(a)=>uploadOk(a)"
                    :data="fileTypes"
                    :headers="Authorization"
                    >
                    <el-button size="medium" type="text" @click="loan(scope.row)">上传放款凭证</el-button>
                  </el-upload> -->
              <!-- <div v-if="scope.row.financePayStatus == 4">
                  <el-button
                    size="medium"
                    type="text"
                    @click="checkDetial(scope.row)"
                    >审核放款凭证</el-button
                  >
                </div>
                <div v-else>
                  <el-button
                    size="medium"
                    type="text"
                    @click="loanDetial(scope.row)"
                    >查看放款凭证</el-button
                  >
                </div>
              </div> -->
              <el-button
                size="medium"
                type="text"
                @click="handleDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog
      title
      :visible.sync="imgDialogVisible"
      width="80%"
      center
      class="img-dialog"
    >
      <!-- <img
                :src="api.getImgURL(file.fileUrl)"
                alt="图片丢失"
                width="30%"
                height="30%"
                :preview-text="file.fileName"
                :preview="1"
                /> -->
      <div class="img-box">
        <div class="img-box-item" v-for="item in fileDatas" :key="item.fileUrl">
          <img
            v-if="
              item.fileUrl
                .substring(item.fileUrl.lastIndexOf('.'))
                .toLowerCase() == '.pdf'
            "
            :src="pdfImg"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.fileUrl)"
          />
          <img
            v-else
            :src="api.getImgURL(item.fileUrl)"
            alt="图片丢失"
            width="30%"
            height="30%"
            :preview-text="item.fileName"
            :preview="1"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title
      :visible.sync="imgCheckDialogVisible"
      width="80%"
      center
      class="img-dialog"
    >
      <!-- <img :src="api.getImgURL(file.fileUrl)"
        alt="图片丢失"
        width="30%"
        height="30%"
        :preview-text="file.fileName"
        :preview="1" /> -->
      <div class="img-box">
        <div class="img-box-item" v-for="item in fileDatas" :key="item.fileUrl">
          <img
            v-if="
              item.fileUrl
                .substring(item.fileUrl.lastIndexOf('.'))
                .toLowerCase() == '.pdf'
            "
            :src="pdfImg"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.fileUrl)"
          />
          <img
            v-else
            :src="api.getImgURL(item.fileUrl)"
            alt="图片丢失"
            width="30%"
            height="30%"
            :preview-text="item.fileName"
            :preview="1"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkBtn(2)">退 回</el-button>
        <el-button @click="checkBtn(1)">同 意</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="审核意见"
      :visible.sync="showDialog1"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="checkInfo" label-width="60px" ref="addform">
        <el-form-item label="备注：">
          <el-input
            v-model="checkInfo.remark"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog1 = false">取 消</el-button>
        <el-button type="primary" @click="beConfirmation(1)" :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="拒绝原因"
      :visible.sync="showDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="checkInfo" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="reson">
          <el-select v-model="checkInfo.reson" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="checkInfo.remark"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog2 = false">取 消</el-button>
        <el-button type="primary" @click="beConfirmation(2)" :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "@/api/upload.js";
import api from "@/api/api";
import moment from "moment";
import pdfImg from "@/assets/file.png";
import { baseURL } from "@/api/sys";

function openDownloadDialog(url, saveName) {
  // for ie 10 and later
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  data() {
    return {
      pdfImg,
      baseURL,
      fileDatas: [],
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "payableBillFinanceNo",
          size: "small",
        },
        {
          label: "优企通编号:",
          type: "input",
          field: "payableBillNo",
          size: "small",
        },
        {
          label: "资金机构:",
          type: "input",
          field: "fundCorpName",
          size: "small",
        },
        {
          label: "融资金额: ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1,
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small",
        },
      ],
      api: api,
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      fileTypes: {
        payableBillFinanceId: "",
      },
      Authorization: { Authorization: sessionStorage.token },
      payableBillFinanceFileList: [],
      file: {},
      imgDialogVisible: false,
      imgCheckDialogVisible: false,
      rowInfo: {},
      showDialog1: false,
      showDialog2: false,
      checkInfo: {
        reson: "",
        remark: "",
      },
      bclick: false,
      refuselist: [],
    };
  },
  created() {
    this.activeName = this.$route.params.state;
    this.getrefuselist(); //获取拒绝原因
  },
  methods: {
    previewPdf(item) {
      window.open(this.baseURL + "/web/file/downFile?filePath=" + item);
    },
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      (filters.review = 1),
        (filters.source = 1),
        (this.filters = filters),
        this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);

      api.GetSignApproveList(param).then((res) => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/financingProjectManage/easypaySign/SignDetial",
        query: {
          payableBillFinanceId: row.payableBillFinanceId,
          payableBillFinanceNo: row.payableBillFinanceNo,
          financeCorpId: row.financeCorpId,
          isshowbtn: 2,
          financePayStatus: row.financePayStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus,
        },
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: "/easypayApproveManage/easypayFinancing/recheckDetial",
        query: {
          id: row.payableBillId,
          type: "2",
          payableBillFinanceId: row.payableBillFinanceId,
          isshowbtn: 2,
          financePayStatus: row.financePayStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus,
          alrealyFlag: true,
        },
      });
    },
    // 下载融资合同
    handleDownload(row) {
      api
        .filesdownsingle({ filePath: row.financeSignUrl })
        .then((res) => {
          openDownloadDialog(res, "融资合同");
        })
        .catch((err) => {});
    },
    uploadFile() {
      return upload.uploadProof();
    },
    uploadOk(response) {
      console.log("res ==>", response);
      if (response.code == "100000") {
        this.$message.success("上传成功");
        this.signDialogVisible = false;
        this.handleRefresh(this.filters);
      } else {
        this.$message.error("上传失败");
      }
    },
    // loan(row) {
    //   this.fileTypes.payableBillFinanceId = row.payableBillFinanceId;
    // },
    //上传前配置
    beforeUp(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    loanDetial(row) {
      api
        .GetPayProof({ payableBillFinanceId: row.payableBillFinanceId })
        .then((res) => {
          if (res.success) {
            this.payableBillFinanceFileList =
              res.datas.payableBillFinanceFileList;
            this.fileDatas = this.payableBillFinanceFileList;
            if (this.payableBillFinanceFileList.length > 0) {
              this.file = this.payableBillFinanceFileList[0];
              this.imgDialogVisible = true;
              this.$previewRefresh();
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 审核放款凭证
    checkDetial(row) {
      this.rowInfo = row;
      api
        .GetPayProof({ payableBillFinanceId: row.payableBillFinanceId })
        .then((res) => {
          if (res.success) {
            this.payableBillFinanceFileList =
              res.datas.payableBillFinanceFileList;
            this.fileDatas = this.payableBillFinanceFileList;
            if (this.payableBillFinanceFileList.length > 0) {
              this.file = this.payableBillFinanceFileList[0];
              this.imgCheckDialogVisible = true;
              this.$previewRefresh();
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    checkBtn(approveResult) {
      this.checkInfo = {
        reson: "",
        remark: "",
      };
      if (approveResult == 1) {
        this.showDialog1 = true;
      } else if (approveResult == 2) {
        this.showDialog2 = true;
        this.checkInfo.reson = this.refuselist[0].name;
      }
    },
    // 审核放款凭证
    beConfirmation(approveResult) {
      let param = {
        payableBillFinanceId: this.rowInfo.payableBillFinanceId,
        approveResult: approveResult, // 1 同意 2 退回
        type: 1, // 1 企业端 2平台
        remark: this.checkInfo.remark,
      };
      if (approveResult == 2) {
        param.reson = this.checkInfo.reson;
      }
      api.beConfirmation(param).then((res) => {
        if (res.success) {
          this.$alert(res.message, "", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.imgCheckDialogVisible = false;
              if (approveResult == 1) {
                this.showDialog1 = false;
              } else {
                this.showDialog2 = false;
              }
              this.handleRefresh(this.filters);
            },
          });
        } else {
          this.$alert(res.message, "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.imgCheckDialogVisible = false;
              if (approveResult == 1) {
                this.showDialog1 = false;
              } else {
                this.showDialog2 = false;
              }
            },
          });
        }
      });
    },
    // 获取拒绝原因
    getrefuselist() {
      api.getDicListByType({ type: 31 }).then((res) => {
        if (res.success) {
          this.refuselist = res.datas.list;
        }
      });
    },
    getFinancePayStatus(status) {
      if (status == -1) {
        return "放款失败";
      } else if (status == 1) {
        return "未放款";
      } else if (status == 2) {
        return "放款中";
      } else if (status == 4) {
        return "企业待确认";
      } else if (status == 5) {
        return "企业退回";
      } else if (status == 6) {
        return "平台待确认";
      } else if (status == 7) {
        return "平台退回";
      } else if (status == 3) {
        return "已放款";
      }
    },
  },
};
</script>

<style lang="scss" scope>
@import "../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.img-dialog {
  .el-dialog__body {
    text-align: center;
  }
}
.img-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .img-box-item {
    margin-right: 20px;
    height: 200px;
    width: 200px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
