<template>
  <div>
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <distinguishingapprovalfirst v-if="showtab==1"></distinguishingapprovalfirst>
      <distinguishingapprovalsecond v-if="showtab==2"></distinguishingapprovalsecond>
    </layout-main-view>
  </div>
</template>
<script>
import api from "@/api/api";
import distinguishingapprovalfirst from "./distinguishingapprovalfirst";
import distinguishingapprovalsecond from "./distinguishingapprovalsecond";
export default {
    components: {
    'distinguishingapprovalfirst':distinguishingapprovalfirst,
    'distinguishingapprovalsecond':distinguishingapprovalsecond
  },
  data() {
    return{
      breadcrumblist: ["清分审批"],
          tabOptions: [
        { label: "待审批", value: "1", path: "/fund/easypay/distinguishingapproval/1" },
        { label: "已审批", value: "2", path: "/fund/easypay/distinguishingapproval/2" },
        ],
            showtab:1,

    }
  },
  created() {
    console.log(this.$route)
    this.showtab=this.$route.params.state;
  },
  methods: {
     tabclick(val) {
    this.showtab=val;
      this.handleEditShow = false;
    },
  },

};
</script>

<style lang="scss">

</style>