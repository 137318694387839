<!--银行账户-->
<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list
          :isShowAll="isShowAll"
          :isHedden="isHedden"
          @data-showAll="filterShowAll"
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist"
        >
          <el-button type="primary" class="btn-doFilter" @click="openDialog"
            >+ 新建企业账户</el-button
          >
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%"
          >
            <el-table-column
              prop="accountNo"
              label="企业账号"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="accountName"
              label="企业户名"
              min-width="150"
            ></el-table-column>
            <!-- <el-table-column prop="bankNumber" label="联行号" min-width="150"></el-table-column> -->
            <el-table-column
              prop="openBank"
              label="企业开户行"
              min-width="150"
            ></el-table-column>
            <el-table-column
              prop="bankAddr"
              label="开户行省份"
              min-width="130"
            ></el-table-column>
            <el-table-column
              prop="bankCity"
              label="开户行城市"
              min-width="130"
            ></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="170">
              <template slot-scope="scope">{{
                scope.row.createTime
                  ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm')
                  : ''
              }}</template>
            </el-table-column>
            <el-table-column
              prop="bankVerifyShow"
              label="验证状态"
              min-width="130"
              :formatter="dateFormatter"
            ></el-table-column>
            <el-table-column
              prop="bindBankShow"
              label="绑定状态"
              min-width="120"
            >
              <template slot-scope="scope">{{
                scope.row.bindBank === 3 ? '绑定' : '未绑定'
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <el-button
                  class="clear-button"
                  type="text"
                  :disabled="
                    scope.row.bindBank === 3 || scope.row.bindBank === 20
                  "
                  @click="handleApply(scope.row)"
                  >申请</el-button
                >
                <el-button
                  class="clear-button"
                  type="text"
                  :disabled="
                    scope.row.bindBank === 3 || scope.row.bindBank === 20
                  "
                  @click="Verification(scope.row)"
                  >验证</el-button
                >
                <el-button
                  class="clear-button"
                  v-if="scope.row.bindBank != 3"
                  :disabled="scope.row.bankVerify != 20"
                  type="text"
                  @click="binding(scope.row)"
                  >绑定</el-button
                >
                <el-button
                  class="clear-button"
                  v-if="scope.row.bindBank == 3"
                  type="text"
                  @click="Untying(scope.row)"
                  >解绑</el-button
                >
                <el-button
                  class="clear-button"
                  :disabled="scope.row.bindBank === 3"
                  type="text"
                  @click="handleEdit(scope.row)"
                  >删除</el-button
                >
                <!--:disabled="scope.row.bankVerify !== 10"-->
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
      <el-dialog
        title="添加企业账户"
        :visible.sync="dialogVisible"
        width="600px"
      >
        <p class="message">
          平台将向您提供的企业账户转账一笔金额，请在收到一笔转账金额后及时完成企业账户验证。只有完成验证的银行才能正常使用！
        </p>
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
          :rules="rules"
        >
          <el-form-item label="企业户名">
            <el-input
              placeholder="请输入企业户名"
              v-model="ruleForm.accountName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="企业账号" prop="accountNo">
            <el-input
              placeholder="请输入企业账号"
              v-model="ruleForm.accountNo"
            ></el-input>
          </el-form-item>
          <el-form-item label="联行号" prop="bankNumber">
            <el-input
              placeholder="请输入联行号"
              v-model="ruleForm.bankNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业开户行" prop="openBank">
            <el-select
              v-model="ruleForm.openBank"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in option"
                :key="item.bankId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行省份" prop="bankAddr">
            <el-select v-model="ruleForm.bankAddr" placeholder="请选择" filterable>
              <el-option
                v-for="item in options"
                :key="item.areaId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行城市" prop="bankCity">
            <el-select v-model="ruleForm.bankCity" placeholder="请选择" filterable>
              <el-option
                v-for="item in address"
                :key="item.areaId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="fr">
          <el-button @click="deletes">取 消</el-button>
          <el-button type="primary" @click="submitForm">保存</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="企业账户验证"
        :visible.sync="VerificationVisible"
        width="500px"
      >
        <el-alert
          :title="
            '平台已向您提供的尾号为' +
            tailNumber +
            '的对公账户内转账一笔金额。请在收到转帐金额后尽快完成验证。验证失败请重新申请转帐验证。'
          "
          type="warning"
          show-icon
          :closable="false"
        ></el-alert>
        <!--<p class="message">（测试金额请输入0.01）</p>-->
        <el-form
          :model="ruleForm2"
          status-icon
          ref="ruleForm2"
          label-width="50px"
          class="demo-ruleForm"
        >
          <el-form-item>
            <el-input
              placeholder="请输入该企业账户的金额"
              style="width: 350px"
              type="number"
              v-model="mount"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="fr">
          <el-button @click="deletesVer">取 消</el-button>
          <el-button type="primary" @click="submitCheck" :loading="isLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
import api from '../../api/api.js';
import * as weapon from '@/util/weapon.js';
import moment from 'moment';

export default {
  data() {
    return {
      weapon,
      tempRow: {},
      isLoading: false,
      ruleForm: {
        accountName: JSON.parse(sessionStorage.corpList).corpName,
        accountNo: '',
        bankId: '',
        bankAddr: '',
        bankCity: '',
        openBank: '',
        bankNumber: '',
      },
      rules: {
        accountName: [
          { required: true, message: '请输入企业户名', trigger: 'blur' },
        ],
        accountNo: [
          { required: true, message: '请输入企业账户', trigger: 'blur' },
        ],
        bankNumber: [
          { required: true, message: '请输入联行号', trigger: 'blur' },
        ],
        openBank: [
          { required: true, message: '请输入企业开户行', trigger: 'change' },
        ],
        bankAddr: [
          { required: true, message: '请输入分支行省份', trigger: 'change' },
        ],
        bankCity: [
          { required: true, message: '请输入分支行城市', trigger: 'change' },
        ],
      },
      mount: '',
      ruleForm2: {},
      rules2: {},
      option: [],
      options: [],
      address: [],
      value: '',
      disabled: false,
      breadcrumblist: ['银行账户管理'],
      dialogVisible: false,
      VerificationVisible: false,
      isShowAll: false,
      isHedden: false,
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      filters: '',
      tailNumber: '',
    };
  },
  created() {
    this.getOpenBank();
    this.getProvinceList();
  },
  watch: {
    'ruleForm.bankAddr': {
      handler: function () {
        this.getCityList();
        this.ruleForm.bankCity = '';
        console.log(222);
        //do something
      },
    },
  },
  methods: {
    moment: moment,
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(
            this.option.find((val) => val.name === this.ruleForm.openBank)
          );
          this.ruleForm.bankId = this.option.find(
            (val) => val.name === this.ruleForm.openBank
          ).bankId;
          api.AddCorpAccountPublic(this.ruleForm).then((res) => {
            if (res.success) {
              this.$alert(res.message);
              this.dialogVisible = false;
              this.getLists(this.filters);
            } else {
              this.$message.error(res.message);
            }
          });
          console.log(this.ruleForm);
          // this.dialogVisible = false
        }
      });
    },
    // 申请
    handleApply(row) {
      this.$confirm('确认申请？').then((_) => {
        api
          .CorpPayApplication({ corpAccountPublicId: row.corpAccountPublicId })
          .then((res) => {
            if (res.success) {
              this.$alert(
                '申请打款验证成功，已向你的对公账户转入一笔金额，请登录网银查看打款金额进行验证！'
              );
              this.getLists(this.filters);
            } else {
              this.$message.error(res.message);
            }
          });
      });
    },
    // 验证
    submitCheck(row) {
      if (!this.mount || this.mount <= 0) {
        this.$message.error('请输入大于零的有效金额');
        return;
      }
      this.isLoading = true;
      const data = {
        mount: this.mount,
        corpAccountPublicId: this.tempRow.corpAccountPublicId,
      };
      api
        .CorpPayVerify(data)
        .then((res) => {
          this.isLoading = false;
          if (res.success) {
            this.$alert(res.message);
            this.getLists(this.filters);
            this.VerificationVisible = false;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getOpenBank() {
      api.GetOpenBank().then((res) => {
        if (res.success) {
          this.option = res.datas.corpBankList;
        }
      });
    },
    getProvinceList() {
      api.GetCorpNature({ action: 1 }).then((res) => {
        if (res.success) {
          this.options = res.datas.sysAreaList;
        }
      });
    },
    getCityList() {
      const areaId = this.options.find(
        (val) => val.name === this.ruleForm.bankAddr
      ).areaId;
      api.GetCorpNature({ action: 2, areaId }).then((res) => {
        if (res.success) {
          this.address = res.datas.sysAreaList;
        }
      });
    },
    openDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    dateFormatter(row) {
      let val = row.bankVerify;
      if (val == 1) {
        return '待申请';
      } else if (val == 10) {
        return '待验证';
      } else if (val == 20) {
        return '验证成功';
      } else if (val == 30) {
        return '验证失败';
      }
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log('filterShowAll  -----', this.isShowAll);
    },
    deletes() {
      this.dialogVisible = false;
      this.option.value = '';
      this.options.value = '';
      this.address.value = '';
    },
    deletesVer() {
      this.VerificationVisible = false;
      this.option.value = '';
      this.options.value = '';
      this.address.value = '';
    },
    Verification(row) {
      this.mount = '';
      this.VerificationVisible = true;
      this.tempRow = row;
      this.tailNumber = row.accountNo.substring(row.accountNo.length - 4);
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    handleRefresh(filters) {
      this.filters = filters;
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log('param ====>', param);
      // param.corpId = '1';
      api.GetCorpAccountPublicListPage().then((res) => {
        console.log('res ===>', res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log('list ===>', this.listData);
        }
        this.listLoading = false;
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    Untying(row) {
      this.$confirm('确认解绑?')
        .then(() => {
          api
            .Unbind({
              corpAccountPublicId: row.corpAccountPublicId,
              bindBank: 2,
            })
            .then((res) => {
              if (res.success) {
                this.$alert(res.message);
                this.getLists(this.filters);
              } else {
                this.$message.error(res.message);
              }
            });
        })
        .catch((_) => {});
    },
    binding(row) {
      this.$confirm('确认绑定?')
        .then(() => {
          api
            .Unbind({
              corpAccountPublicId: row.corpAccountPublicId,
              bindBank: 3,
            })
            .then((res) => {
              if (res.success) {
                this.$alert(res.message);
                this.getLists(this.filters);
              } else {
                this.$message.error(res.message);
              }
            });
        })
        .catch((_) => {});
    },

    handleEdit(row) {
      this.$confirm('确认删除？')
        .then(() => {
          api
            .DeleteCorpAccountPublic({
              corpAccountPublicId: row.corpAccountPublicId,
            })
            .then((res) => {
              if (res.success) {
                this.$alert(res.message);
                this.getLists(this.filters);
              } else {
                this.$message.error(res.message);
              }
            });
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/moreDetail/moudleDetail.scss';

.path {
  margin-left: 20px;
}
.fr {
  position: absolute;
  right: 40px;
  bottom: 20px;
}
.el-table__header-wrapper {
  margin-top: 30px;
}
.message {
  color: red;
  // text-indent:2em;
  // width: 85%;
}
.el-dialog__body {
  padding-top: 10px !important;
}

.el-button {
  line-height: 32px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 20px;
}
.el-input {
  width: 400px;
}
.demo-ruleForm {
  margin-bottom: 80px;
  margin-top: 20px;
  /*padding-bottom: 20px;*/
  /*margin-bottom: 20px;*/
  /*padding-left: 30px;*/
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.clear-button {
  margin-top: 0px;
}
</style>
