<template>
  <div>
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <approvalsupplementsfirst  v-if="showtab == 1"></approvalsupplementsfirst>
      <approvalsupplementssecond  v-if="showtab == 2"></approvalsupplementssecond>
      <approvalsupplementsthree  v-if="showtab == 3"></approvalsupplementsthree>

    </layout-main-view>
  </div>
</template>
<script>
import approvalsupplementsfirst from "./approvalsupplementsfirst";
import approvalsupplementssecond from "./approvalsupplementssecond";
import approvalsupplementsthree from "./approvalsupplementsthree";


export default {
  components: {
    "approvalsupplementsfirst": approvalsupplementsfirst,
    "approvalsupplementssecond": approvalsupplementssecond,
    'approvalsupplementsthree':approvalsupplementsthree
  },
  data() {
    return {
      breadcrumblist: ["保理融资补件审批"],
      showtab: 1,
      tabOptions: [
        // { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
        // { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },
        // { label: "已退回", value: "3", path: "/factoring/approvalsupplementsmain/3" },
     

        
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      user:JSON.parse(sessionStorage.user).corpType,
      isshowuser:1
    };
  },
    mounted(){
        this.showtab = this.$route.params.state;
        console.log('this.showtab ===>',this.showtab);
    },
    created(){
this.getuser()
    },
  methods: {
    tabclick(val) {
      this.showtab = val;
    },
    getuser(){
      if(JSON.parse(sessionStorage.user).corpType==1){
              switch (JSON.parse(sessionStorage.user).roleCodes[0]) {
                case 'fund_first':
         this.tabOptions=[        { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
              { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },
              { label: "已退回", value: "3", path: "/factoring/approvalsupplementsmain/3" },]
                break;
                  case 'fund_second':
  
                this.tabOptions=[     { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
              { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },]
                break;
                  case 'fund_third':

                this.tabOptions=[     { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
              { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },]
                break;
                default:
   
          this.tabOptions=[        { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
              { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },
              { label: "已退回", value: "3", path: "/factoring/approvalsupplementsmain/3" },]
                break;

            }
      }else{
                  this.tabOptions=[     { label: "待审批", value: "1", path: "/factoring/approvalsupplementsmain/1" },
              { label: "已审批", value: "2", path: "/factoring/approvalsupplementsmain/2" },]
      }

    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>