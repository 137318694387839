<!--会员服务-->
<template>
 <div>
        <p style="font-size:15pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:15pt; font-weight:bold">经证供应链平台会员服务协议</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">版本：V1.0</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">发布时间：  </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">2022</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">年 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">6</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 月 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">22</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 日</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">生效时间：</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">  {{ date.year }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">年 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">{{ date.month }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 月 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">{{ date.day }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 日</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify"><span
                style="font-family:宋体; font-size:12pt">尊敬的用户：</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">欢迎阅读经证供应链服务协议（</span><span
                style="font-family:宋体; font-size:12pt">下称“本协议”</span><span
                style="font-family:宋体; font-size:12pt">）</span><span
                style="font-family:宋体; font-size:12pt">，您应当在使用服务之前认真阅读本协议全部内容</span><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">特别是含有加粗字的条款</span><span
                style="font-family:宋体; font-size:12pt">）</span><span
                style="font-family:宋体; font-size:12pt">。《服务协议》以及相关管理规则、《隐私协议》</span><a name="_Hlk15914698"><span
                    style="font-family:宋体; font-size:12pt">、《免责声明》</span></a><span
                style="font-family:宋体; font-size:12pt">等管理规则均为本协议的组成部分，如果您对本协议任何条款表示异议，您可以选择不使用经证供应链平台的服务。</span><span
                style="color:#ff0000; font-family:宋体; font-size:12pt; font-weight:bold">点击</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">“</span><span
                style="color:#ff0000; font-family:宋体; font-size:12pt; font-weight:bold">同意</span><span
                style="font-family:宋体; font-size:12pt">”按钮，则意味着</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">您已经全面、准确的理解了本协议内容，认同并自愿遵守本协议。</span><span
                style="font-family:宋体; font-size:12pt">本协议阐述之条款和条件适用于用户使用经证供应链平台所提供的</span><span
                style="font-family:宋体; font-size:12pt">供应链金融的工具、服务和衍生的线下服务。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">一、定义</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1.经证供应链平台：是专业的供应链科技服务平台</span><span
                style="font-family:宋体; font-size:12pt">（所涉域为*.scm.sh-jztech.com的中文网站及其客户端）</span><span
                style="font-family:宋体; font-size:12pt">。经证供应链平台网站的所有权及运营权归上海经证科技有限公司所有。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.用户</span><span
                style="font-family:宋体; font-size:12pt">/会员</span><span
                style="font-family:宋体; font-size:12pt">：</span><span
                style="font-family:宋体; font-size:12pt">指同意并接受本协议，根据经证供应链的业务规则和指引注册并通过平台或其委托机构的认证审核，并依法具备民事权利能力和民事行为能力的自然人、法人或者其他组织。</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">不具备民事行为能力或者缺乏特定许可资质的用户与经证供应链平台签署的协议无效</span><span
                style="font-family:宋体; font-size:12pt">。仅有通过认证审核的用户可使用平台提供的服务。若用户不符合资格，经证供应链有权随时中止或终止注册、认证程序。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3.
                业务规则和指引：指经证供应链平台发布的届时有效的各项业务规则、操作流程、通知通告、使用说明和指引等文件，以及其不时被更新、替换、补充、修订的版本。经证供应链平台发布的届时有效的各项业务规则、操作流程、通知通告、使用说明和指引等文件为本协议不可分割的部分，与本协议具有同等的法律效力。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4.《应收账款电子凭证业务服务协议》：指已注册成为经证供应链平台用户，并选择在本平台开立和使用应收账款电子凭证功能时勾选、认可并签署的《经证供应链平台应收账款电子凭证业务服务协议》以及不时被更新、替换、补充、修订的版本。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">5.平台协议：包含本协议在内，用户在使用经证供应链平台服务中，与经证供应链平台签署的以及利用经证供应链平台订立的各项协议。包括但不限于《经证供应链平台应收账款电子凭证业务服务协议》（适用不同产品）、《经证供应链平台应收账款电子凭证开单协议》、《经证供应链平台应收账款电子凭证转让协议》、《经证供应链平台收费服务协议》等其他相关协议以及其任何有效的修改或补充等。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">6.应收账款电子凭证：指用户成功开具的、显示商务合同项下买卖双方之间债权债务关系的一种可流转、可融资、可拆分、可兑付的电子付款承诺凭证（到期支付款项的承诺函）。成功开具的应收账款电子凭证，可作为经证供应链平台服务的交易基础。本平台根据客户需求提供多类型的应收账款电子凭证产品，包括且不限于“优企通”、“普惠通”。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">7.开单人：指根据本协议，在经证供应链平台申请开立应收账款电子凭证并负有应收账款电子凭证偿付义务的债务人。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">8.持单人：指应收账款电子凭证的持有人，根据本协议签收应收账款电子凭证并享有应收账款电子凭证项下到期款项债权的权利人。持单人包括原始持单人以及其他根据本协议受让应收账款电子凭证的经证供应链平台认证用户。</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">持单人同意并认可，开单人有权委托经证供应链平台或其他机构将应收账款电子凭证项下金额支付至持单人的结算账户；开单人或其委托的机构依据经证供应链平台的清分指令向持单人支付应收账款电子凭证项下到期的应付账款后，即开单人已履行完毕应收账款电子凭证项下的付款义务。持单人同意并认可，由于银行间系统结算等原因，持单人实际收到款项的时间可能会晚于开单人承诺付款日，因此造成的延迟不构成违约，应收账款电子凭证持单人不得向开单人、经证供应链平台或其他机构追究责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">9.原始持单人：指根据本协议，在本平台确认签收开单人开立的应收账款电子凭证的用户，为基础合同的卖方或服务提供方，也是应收账款电子凭证的第一手持有人。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">10.基础交易：指应收账款电子凭证对应的原始持单人和开单人之间因销售商品、提供服务或其他贸易活动产生的债权债务关系。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">11.基础合同/商务合同：指由原始持单人与开单人基于基础交易而签订的货物销售合同、服务合同或其他交易文件，亦包括其补充协议和构成销售/服务合同组成的附件。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">12.资金方：指根据法律法规及本协议约定，为用户提供融资服务的商业资金方、银行或其他金融机构。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">13.核心企业：即经证供应链平台应收账款电子凭证的开单人，其为产业链中被公认的优势企业，该企业为了巩固其产业链发展，优化产业、提升产业的竞争力等，承诺到期准时兑付在平台上流转的应收账款电子凭证的直接债务人。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">14.应收账款电子凭证到期日/承诺付款日：指应收账款电子凭证记载的到期日，即开单人与原始持单人就基础合同项下的付款义务的履行协商一致约定的应收账款的到期日。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1</span><span
                style="font-family:宋体; font-size:12pt">5.担保人：为核心企业及其成员企业开立的应收账款电子凭证项下兑付义务提供担保，承担连带付款责任的自然人、法人或其他组织。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">16.开单额度：指资金方评估核心企业及其成员企业的资信状况，为核心企业及其成员企业核定的开单最大限额（不得高于授信额度）、并进行开单额度动态管理，该额度在有效期内可循环使用。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1</span><span
                style="font-family:宋体; font-size:12pt">7</span><span
                style="font-family:宋体; font-size:12pt">.</span><span
                style="font-family:宋体; font-size:12pt">担保额度：指担保人为核心企业及其成员企业核定的，对其开立的应收账款电子凭证承担连带付款责任（还款保证责任）的最大限额，该额度在有效期内可循环使用。如担保额度无法覆盖担保范围的，应以担保范围确认的金额为准。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1</span><span
                style="font-family:宋体; font-size:12pt">8</span><span
                style="font-family:宋体; font-size:12pt">.</span><span
                style="font-family:宋体; font-size:12pt">担保范围：指担保人为核心企业及其成员企业核定的，对其开立的应收账款电子凭证承担连带付款责任（还款保证责任）的担保范围，包括且不限于开单人应收账款电子凭证应偿付款项及持单人为实现现实债权的一切费用（迟延支付利息、公证费、违约金、损害赔偿金、诉讼费、仲裁费、律师费等）。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">19.电子认证服务机构：指具有认证资格，接受经证供应链平台的委托，协助经证供应链平台办理用户认证并为认证通过的用户签发电子签名认证证书的服务机构，即中金金融认证中心有限公司（简称“CFCA”）。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">20.电子签名：指《中华人民共和国电子签名法》所规定的电子签名，是指数据电文中以电子形式所含、所附用于识别签名人身份并标明签名人认可其中内容的数据。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">21.电子签名认证证书、CFCA证书、数字证书：指《中华人民共和国电子签名法》、《电子认证服务管理办法》以及其他法律法规规定的电子签名认证证书。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">22.工作日：指除国家法定节假日和休息日以外的中国大陆地区商业银行正常营业日。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">22.日：指自然日。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">二、用户认证</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1. </span><span
                style="font-family:宋体; font-size:12pt">用户认证申请</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">1</span><span
                style="font-family:宋体; font-size:12pt">）</span><span style="font-family:宋体; font-size:12pt">
            </span><span
                style="font-family:宋体; font-size:12pt">用户申请认证，应根据经证供应链平台的业务规则和指引，填写用户认证申请资料，上传用户认证申请文件。具体认证申请文件以用户申请认证时平台界面要求为准。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">2</span><span
                style="font-family:宋体; font-size:12pt">）</span><span style="font-family:宋体; font-size:12pt">
            </span><span style="font-family:宋体; font-size:12pt">用户在平台上成功提交认证申请信息后，用户应妥善保管提交的资料，供平台备查。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">3</span><span
                style="font-family:宋体; font-size:12pt">）</span><span style="font-family:宋体; font-size:12pt">
            </span><span
                style="font-family:宋体; font-size:12pt">用户承诺，其向平台提交的认证申请资料与文件均合法、真实、准确、完整和有效，因认证申请资料与文件不合法、不真实、不准确、不完整、无效或因用户的其它原因而产生的差错，由用户承担责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">4</span><span
                style="font-family:宋体; font-size:12pt">）</span><span style="font-family:宋体; font-size:12pt">
            </span><span style="font-family:宋体; font-size:12pt">用户登录平台后可以查询用户认证申请审核结果。审核不通过的即用户认证失败，用户有权再次申请认证。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.</span><span
                style="font-family:宋体; font-size:12pt">用户信息变更</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">用户注册资料发生变动后，应及时更新。如涉及用户名称、营业执照注册号、组织机构代码证编号、税务登记证编号、开户许可证编号、银行账户信息、法定代表人与联系人姓名及其身份证号码等重要信息变更的，须经平台审核后生效。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3.</span><span
                style="font-family:宋体; font-size:12pt">电子签名认证证书</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1）用户同意并授权经证供应链平台委托电子认证服务机构为认证通过的用户办理电子签名认证证书。用户应根据平台业务规则、指引以及电子认证服务机构的要求获取电子签名认证证书，并按照正确的方法使用电子签名认证证书。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（2）用户了解并同意，其有责任保证电子签名认证证书的安全，不对其他任何人出借、泄露电子签名认证证书的相关信息。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（3）用户了解并同意，为提供认证服务所需，电子认证服务机构有权使用用户的相关信息并做必要留存。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（4）用户了解并确认，已认真、完整</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">阅读并同意接受《CFCA数字证书服务协议》</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">全部条款内容</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">可点击链接查看：</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold; text-decoration:underline">https://www.cfca.com.cn</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">），</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">认真履行该协议关于自身的</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">权利义务</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">的内容。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（5）用户了解并确认，已认真、完整</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">阅读并同意接受</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">电子认证服务机构制定或修订的</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">《数字证书使用安全提示》（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">可点击链接查看：</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold; text-decoration:underline">https://www.cfca.com.cn</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）或类似的材料</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">，已及时阅读并获知相关信息。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">三、用户的权利和义务</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1.用户在遵守本协议的前提下，依法享有经证供应链平台提供的服务，包含融资</span><span
                style="font-family:宋体; font-size:12pt">申请</span><span
                style="font-family:宋体; font-size:12pt">等，有权对经证供应链平台的服务进行监督、批评和指导，本平台欢迎用户提出服务改进建议，并会以勤勉态度为用户提供服务。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">签章会员保证只能将电子签章用于授权的和合法的目的，保证不得利用电子签章从事危害国家安全等刑事犯罪，或利用电子印章进行经济诈骗等活动</span><span
                style="font-family:宋体; font-size:12pt">。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3.用户应当妥善保管电子签章，不得泄漏或交付他人。若</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">因持章人故意或过失行为导致他人知道、盗用、冒用安全电子签章给电子印章用户或电子签章依赖方造成的损失，经证供应链平台不承担任何经济赔偿责任及其他民事及刑事责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">四、经证供应链平台的权利义务</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">1.经证供应链平台在提供本平台服务时，可能会对部分服务收取一定的</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">费用，在此情况下，经证供应链平台会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能享有相关的服务。</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">经证供应链平台保留在根据本协议第九条第一款变更协议后，收取或不收取“服务”费用的权利。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.经证供应链平台将对用户发布的内容进行审核，</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">该审查为形式性审核，无法保证用户发布内容的真实性、准确性、有效性和安全性。</span><span
                style="font-family:宋体; font-size:12pt">但在本平台实施管理活动以及第三方对用户发布信息进行投诉时，本平台会对用户提供的信息进行复核，并有权要求用户提供相应证据来证明信息的真实性、准确性、完整性及合法性，在用户无法提供有效证明时，本平台有权删除相关信息或终止服务。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3.</span><span
                style="font-family:宋体; font-size:12pt">为了更好的服务用户，经证供应链平台及其关联机构对用户提供的资料（</span><span
                style="font-family:宋体; font-size:12pt">包括但不限于文字、图片、视频</span><span
                style="font-family:宋体; font-size:12pt">）</span><span
                style="font-family:宋体; font-size:12pt">拥有合理使用权。用户将前述资料提供、上传经证供应链平台后，经证供应链平台将持续的享有对用户所提供的信息和资料的使用及保存权。为服务用户之目的，用户同意，经证供应链平台亦有权将用户所提供、上传的相关信息和资料与经证供应链平台合作方和/或联营方以及该合作方/联营方所运营的互联网平台共享。若用户发现</span><span
                style="font-family:宋体; font-size:12pt">经证供应链平台对用户资料的使用不当时，请及时提出，经证供应链平台将尽快改正。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4.经证供应链平台有权随时删除含有任何危害国家安全、淫秽色情、虚假、侮辱、诽谤、恐吓或骚扰、侵犯他人知识产权或人身权或其他合法权益等违法或有违社会公序良俗的内容或指向这些内容链接的信息，并有权终止对发布前述信息的用户的服务。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">5.经证供应链平台可能会提供与其他互联网网站或资源进行链接；用户还可能通过经证供应链平台进行互动</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">包括但不限于进行相关商品或服务之付款及交付</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">，</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">对于前述网站或资源是否可以利用或用户与前述推广商的自主互动而遭受的任何性质的损失或损害，经证供应链平台不承担任何责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">6.用户所从事的经营业务不得对经证供应链平台利益构成任何现实或潜在的损害或冲突，否则经证供应链平台有权通知用户立即终止服务，而无需承担其他法律责任和费用补偿。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">7.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">对于系统发生故障影响到本服务的正常运行，经证供应链平台承诺及时处理进行修复。但用户因此而产生的经济和精神损失，经证供应链平台不承担任何直接或间接的责任。</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">对于经证供应链平台有计划的系统维修、保养、升级，经证供应链平台将会以网站公告的方式事先通知用户，升级期间，经证供应链平台服务将不同程度受到影响，</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户因此而产生的损失，经证供应链平台不承担任何直接或间接的责任。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">8.用户完全理解并同意，通过经证供应链平台可能会收到推广信息及服务信息，</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">凡因接收相应推广信息而与信息发布者或推广商发生交易行为而遭受的任何性质的损失或损害，经证供应链平台均不予负责。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">9.经证供应链平台拥有对本协议条款的修改权、更新权、解释权。用户对服务之任何部分或本协议条款的任何部分之意见及建议可通过平台公共邮箱</span><span
                style="color:#ff0000; font-family:宋体; font-size:12pt; font-weight:bold; text-decoration:underline">s</span><span
                style="color:#ff0000; font-family:宋体; font-size:12pt; font-weight:bold; text-decoration:underline">ervice@sh-jzgroup.com</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">与经证供应链平台联系。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">0</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">.经证供应链平台有权与其他第三方机构合作，调整平台的服务内容和服务模式，并在平台上公告，无需另行单独通知用户。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">11.
                用户不可撤销同意经证供应链平台基于业务管理的需要，有权直接或通过与经证供应链平台存在业务合作的银行或其他金融机构处对乙方的日常经营及合作业务情况进行查询并获取相应材料，并且用户同意授权经证供应链平台合作银行或其他金融机构(含下属分支机构)按经证供应链平台要求发送乙方在经证供应链平台业务具体相关信息，包括且不限于融资信息、业务申请信息、融资款放款、融资款还款、银行流水记录、相关账户的资金收取、资金支付、资金余额、开立或销户等信息。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">12.经证供应链平台享有法律法规以及平台协议约定的其他权利。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">五、 陈述和保证</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">在本协议签署之日，用户做出如下陈述和保证，且下述陈述和保证在本协议有效期内始终有效：</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">
                用户承诺，其为合法成立并有效存续的主体，已为签署本协议履行了必要的批准、核准或备案手续，并能够履行其在本协议项下的义务。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户承诺，其有责任保证电子签名认证证书的安全，不对其他任何人出借、泄露电子签名认证证书的相关信息。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户保证其操作员利用电子签名认证证书进行一切行为前均已获得用户的授权，为用户的真实意思表示，用户将对其或其操作员利用该电子签名认证证书所进行的一切行为的法律后果负完全的责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">4.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户确认并同意，经证供应链平台仅通过用户的电子签名认证证书识别用户的指令。用户确认，用户使用电子签名认证证书操作所产生的电子信息记录均为用户行为的有效凭据，并由用户承担由此产生的全部责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">5.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">应收账款电子凭证开单人、
                转让人、融资申请人共同承诺并保证：应收账款电子凭证项下的应收账款依法可以转让，不存在任何限制应收账款转让的承诺、合同或其他法律文件；截至应收账款电子凭证开单日及</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">/或</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">转让日，应收账款电子凭证项下应收账款不存在任何尚未处理完毕的违约事件或争议，未涉及任何保全、诉讼、仲裁、破产或执行程序，不存在任何应收账款电子凭证开单人和/或转让人和/或融资申请人提出的减免或抵销应收账款债权的任何事由。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">6.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户确认并同意，平台资金方有权将受让的的应收账款电子凭证项下的应收账款再转让给其他方或银行、基金公司、证券公司等其他金融机构/平台，或证券公司、资产管理公司、基金公司、保险公司等资产管理机构设立的资产管理计划等特殊目的载体。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">7.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">
                用户承诺，其向经证供应链平台提交的所有资料与信息均合法、真实、准确、完整和有效，因资料与信息不合法、不真实、不准确、不完整、无效或用户的其他原因而产生的责任，由用户自行承担。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">8.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户承诺，其在经证供应链平台上因持有应收账款电子凭证而获得的应收账款债权，仅可通过经证供应链平台进行线上或线下转让或融资，受让方应为通过平台认证的用户。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">9.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户保证，如将其持有的应收账款电子凭证项下应收账款通过经证供应链平台以外的其他线上或线下方式、或者与不存在真实贸易背景的任何人进行任何转让、融资、质押或以其他方式处置的，其同意接受平台资金方或经证供应链平台对其采取任何限制措施。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">10.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">
                用户承诺，绝不为任何非法目的或以任何非法方式使用经证供应链平台，并承诺遵守中国相关法律、法规及一切使用互联网之国际惯例，遵守所有与平台服务有关的网络协议、规则和程序。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">11.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户承诺，由于用户违反本协议，或由于用户使用平台服务违反了任何法律法规或侵犯了第三方的合法权利，用户将承担相应的法律责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">12.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户知悉，经证供应链平台并非银行机构或第三方支付机构，平台协议所涉及资金转移均通过银行或第三方支付机构实现，用户理解并同意其资金在银行体系间流转需要合理时间。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">13.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户理解并同意，通过平台查询的任何信息仅作为参考，不作为相关操作或交易的证据或依据；如该等信息与经证供应链平台记录存在任何不一致，应以经证供应链平台提供的书面记录为准。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">14.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户同意并保证，不得利用经证供应链平台服务从事侵害他人权益或违法之行为，若有违反者应负所有法律责任。前述行为包括但不限于：</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）
                反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）
                侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权、其他知识产权及其他权益；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">） 违反依法律或合约所应负之保密义务；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">4</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">） 冒用他人名义使用平台服务；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">5</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">） 从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件或其他违禁物；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（6） 提供赌博资讯或以任何方式引诱他人参与赌博；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（7） 涉嫌洗钱、套现或进行传销活动的；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（8） 从事任何可能含有电脑病毒或是可能侵害平台服务系统、资料等行为；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">9</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">） 利用平台服务系统进行可能对互联网或移动网正常运转造成不利影响之行为；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">0</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）侵犯经证供应链平台或平台资金方的利益；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）利用平台服务上传、展示或传播虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）
                使用平台提供的服务或其他电子邮件转发服务发出垃圾邮件或其他可能违反本协议的内容；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">） 其他经证供应链平台有正当理由认为不适当之行为。</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:24.1pt; widows:0">
            <span style="font-family:宋体; font-size:12pt; font-weight:bold">15. 用户承诺，对于平台发送的任何通知或任何</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">其他的协议、告示或其他关于用户使用账户及服务的通知，用户有义务及时登陆账户并进行完整阅读，平台对用户的通知义务及有效送达适用本协议第十四条第一款之约定，用户不可撤销地确认对该送达规定已完全知悉且认可其送达方式的法律后果，用户未及时接收或阅读或未及时登陆账户的，不影响通知效力。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">16．持单人承诺，不得将已确认签收的应收账款电子凭证退还给开单人或前手持单人，如需就商务合同进行退款、赔偿等，由相关方另行协商解决。</span>
        </p>
        <p style="font-size:10.5pt; line-height:150%; margin:7.8pt 0pt; text-align:justify"><span
                style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">六、 账户安全与管理</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1.除特别声明外，本协议以及其他在经证供应链平台或通过经证供应链平台交易需签订的合同采用电子合同方式。</span><span
                style="font-family:宋体; font-size:12pt">用户了解并同意，确保其在平台上的用户账户及密码（包括</span><span
                style="font-family:宋体; font-size:12pt">CFCA证书密码或验证码，下同）的机密安全是用户的责任。用户将对利用该用户账户及密码所进行的一切行动及言论，负完全的责任，并同意以下事项：</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（1）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold"> </span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户不对其他任何人泄露账户或密码，亦不可使用其他任何人的账户或密码。因黑客、病毒或用户的保管疏忽等非经证供应链平台原因导致用户的账户或密码遭他人非法使用的，平台不承担任何责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（2）经证供应链平台通过用户的账户及密码来识别用户的指令，用户确认，使用用户账户和密码登录后在平台的一切行为均代表用户本人。用户账户操作所产生的电子信息记录均为用户行为的有效凭据，并由用户本人承担由此产生的全部责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（3）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold"> </span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">冒用他人账户及密码的，经证供应链平台及其合法授权主体将保留追究实际使用人法律责任的权利。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户账户和密码仅限于用户自身使用，用户不得以任何形式转让或出借、出租给他人使用，如果发现同一账户和密码在同一时间内被多人同时登录使用，经证供应链平台有权暂停、终止或限制该用户访问直至取消该用户的用户资格，并不予任何赔偿。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户同意，如用户未能根据平台要求完成身份认证，经证供应链平台无需进行事先通知即有权终止提供用户服务，并可能立即暂停、关闭或删除用户账号及该用户账号中所有相关资料及档案。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">4.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户同意，基于用户账户发生的任何交易（无论是否被其他第三方使用）已经被达成的，都视为用户基于其真实意愿而发生的交易行为，用户应对该交易行为承担相应的权利义务并承担责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">5.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户可更改密码，但任何更改均须经经证供应链平台接受，方为有效。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">6.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户因忘记密码向经证供应链平台重置密码时，必须提供完全、正确的注册信息，否则经证供应链平台有权本着为用户保密的原则不予告知。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">7.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户如发现有第三人冒用或盗用用户账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知经证供应链平台，要求经证供应链平台暂停相关服务，否则由此产生的一切责任由用户自身承担。同时，用户理解经证供应链平台对用户的请求采取行动需要合理期限，在此之前，经证供应链平台对第三人使用该服务所导致的损失不承担任何责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">8.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">平台有权基于单方独立判断，在其有正当理由认为可能发生危害交易安全等情形时，不经通知而先行暂停、中断或终止向用户提供本协议项下的全部或部分用户服务，并将注册资料移除或删除，且无需对用户或任何第三方承担任何责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">10.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户同意，用户账户的暂停、中断或终止不代表用户责任的终止，用户仍应对使用平台服务期间的行为承担可能的违约或损害赔偿责任，同时经证供应链平台仍可保留用户的相关信息。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">七、隐私权保护</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">经证供应链平台将严格依照《隐私协议》及国家法律法规给予用户隐私权保护。详见《隐私协议》条文。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">八、知识产权声明</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">经证供应链平台独立拥有或与相关内容提供者共同拥有本平台内相关内容（</span><span
                style="font-family:宋体; font-size:12pt">包括但不限于网络域名、文字、图片、音频、视频资料、设计、商标、标识、网站构架、网站版面的安排、网页设计、软件和全部专有数据库等</span><span
                style="font-family:宋体; font-size:12pt">）</span><span
                style="font-family:宋体; font-size:12pt">的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等。未经经证供应链平台书面许可，任何人不得擅自使用。否则，经证供应链平台将依法追究侵权人法律责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">九、协议的变更、解除和终止</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1.变更</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">经证供应链平台可以根据客观情况的变化随时进行业务性调整或产品性调整，并变更本协议，变更后的本协议将会在经证供应链平台上重新公布。用户可以选择继续使用调整后的业务或产品，但是如果用户对变更后的本协议持有不同意见，可以选择退出经证供应链平台，但经证供应链平台对此不承担任何法律责任和费用补偿。如果用户继续选择享用经证供应链平台，则视为用户已经完全接受本协议及其修改。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2.解除和终止</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">1）</span><span
                style="font-family:宋体; font-size:12pt">如发生下列任何一种情形，经证供应链平台有权随时中断或终止向用户提供服务而无需通知用户：</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户提供的个人资料不真实</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">、不准确、不完整</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户违反法律或者本协议及其修改文本的规定；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">按照主管部门的要求；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">4</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户注册后连续12个月未登录其在</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">经证供应链平台的帐号；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">5</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）经证供应链平台发现用户恶意注册帐号；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">6</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">经证供应链平台收到第三方投诉；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">7</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">同行业者通过注册账号等行为，获取</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">资金方</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">或项目方联系方式</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">等</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">窃取商业机密</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">的</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">；</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">8</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">其他经证供应链平台认为是</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">不</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">符合整体服务需求的情形。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">2）如因适用法律或政策发生变更导致本协议项下的业务部分或全部违反法律或外部监管政策，平台有权修改或终止本协议。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3、</span><span
                style="font-family:宋体; font-size:12pt">用户</span><span
                style="font-family:宋体; font-size:12pt">主动退出本协议后所产生的任何损失、纠纷均与平台无关，相应责任由</span><span
                style="font-family:宋体; font-size:12pt">用户</span><span
                style="font-family:宋体; font-size:12pt">自行承担。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4、在</span><span
                style="font-family:宋体; font-size:12pt">用户</span><span
                style="font-family:宋体; font-size:12pt">违反了本协议或相关业务规则，或在相关法律法规、政府部门的要求下，平台有权单方面解除本协议、关闭</span><span
                style="font-family:宋体; font-size:12pt">用户</span><span
                style="font-family:宋体; font-size:12pt">的账户或者限制会员使用平台。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">十、平台运营方的责任限制及免责条款</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">1.经证供应链平台不保证平台用户上传信息的真实性、充分性、可靠性、准确性、完整性和有效性，用户应依赖于其独立判断进行交易，并对其做出的判断承担全部责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2.经证供应链平台不对用户能否达成应收账款电子凭证开具、转让、融资等交易出具任何承诺或保证，亦不对已达成的交易的任何风险出具任何承诺或保证或承担任何的责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">3.用户在使用平台服务的过程中，经证供应链平台对应收账款电子凭证项下应收账款、商务合同、平台协议等的真实性、合法性不承担审查义务。用户应当自行审查核实交易对手、应收账款、商务合同及平台协议等的真实性、合法性，并承担因此产生的纠纷或损失。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">4.用户因应收账款电子凭证开具、转让、融资等交易产生的任何纠纷或损失，自行承担相应的责任及法律后果。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">5.对于用户在任何情况下，使用平台过程中涉及由第三方（包括电子认证服务机构）提供相关服务的责任由该第三方承担。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">6.对于因用户未按照本协议或平台的业务规则和指引进行操作等自身原因导致的任何损失或责任，自行承担相应的责任及法律后果。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">7.经证供应链平台的内容可能涉及由第三方所有、控制或者运营的其他网站（“第三方网站”）。如平台设置进入第三方网站的链接，用户应按照第三方网站的使用协议确定相关权利义务，经证供应链平台不承担因用户使用该等链接而引起的任何责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">8.由于应收账款电子凭证项下的应收账款通过除经证供应链平台以外的其他线上或线下方式、或者与不存在真实贸易背景的任何人进行任何转让、融资、质押或进行其他处置，导致核心企业、供应商、担保人、资金方、其他用户以及其他主体之间的任何纠纷或损失，经证供应链平台不承担任何形式的责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">9.如因用户资金登记簿、结算账户信息有误，或用户资金登记簿、结算账户信息发生变更后未及时通知经证供应链平台或未按照平台的业务规则和指引进行修改，导致该用户在应收账款电子凭证融资等业务项下资金入账延迟或无法入账的，由此产生的损失由该用户自行承担。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">10.不可抗力</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）</span><span
                style="font-family:宋体; font-size:12pt">不可抗力是指协议双方不能合理控制、不可预见或即使预见亦无法避免的事件，该事件使任何一方根据本协议履行其全部或部分义务已不可能。该事件包括但不限于政府行为、地震、台风、洪水、火灾或其它天灾、战争或任何其它类似事件。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">（</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">2</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">鉴于互联网之特殊性质，不可抗力亦包括下列影响互联网正常运行之情形：</span><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">1</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">电信部门技术调整导致之重大影响；</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">2）由于黑客攻击、网络供应商技术调整或故障、平台升级、银行或政府有权机构的问题等原因而造成的平台服务中断或延迟；3）平台系统停机维护期间；4）电信设备出现故障不能进行数据传输；5）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">病毒侵袭等。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">（3）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">经证供应链平台如遭受不可抗力事件，有权暂停或终止服务，不视为违约。在不可抗力事件影响结束后，应当继续按本协议履行其义务。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">十一、费用承担</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1.用户因使用经证供应链平台提供的相关服务所产生的所有费用，包括但不限于融资利息、平台服务费，由用户自行承担，用户应按照平台业务规则和指引明确规定及相关协议支付相关费用，否则平台有权不提供相关服务。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.平台拥有制定、收取及调整服务费用的权利，具体服务费用以用户使用平台服务时平台上所公告的收费方式或用户与平台达成的其他协议为准。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3.用户在使用平台服务过程中与第三方机构签订协议或达成一致时，第三方机构有权按照约定用用户收取费用，用户可能需要向第三方机构（如平台资金方或其他第三方服务机构）支付一定的费用，具体收费标准以用户使用平台服务时，平台所公告的收费方式或用户与第三方机构达成的其他协议为准，详见第三方机构相关页面或平台的提示以及协议。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4.用户因进行交易而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由用户自行承担。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">十二、违约责任</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1</span><span
                style="font-family:宋体; font-size:12pt">.</span><span
                style="font-family:宋体; font-size:12pt">在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，侵犯了</span><span
                style="font-family:宋体; font-size:12pt">经证供应链平台、平台运营方</span><span
                style="font-family:宋体; font-size:12pt">、平台资金方、平台其他用户或其他第三方合法权利或造成</span><span
                style="font-family:宋体; font-size:12pt">前</span><span
                style="font-family:宋体; font-size:12pt">述主体的损失的，该用户应当依法承担赔偿责任。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.</span><span
                style="font-family:宋体; font-size:12pt">在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，</span><span
                style="font-family:宋体; font-size:12pt">经证供应链平台</span><span
                style="font-family:宋体; font-size:12pt">有权根据该用户的违约情况，对其采取限制或暂停使用平台服务、暂停或关闭用户账户等措施，有权向有关监管机构汇报该用户的违约情况。</span><span
                style="font-family:宋体; font-size:12pt">经证供应链平台</span><span
                style="font-family:宋体; font-size:12pt">采取上述措施，并不排除</span><span
                style="font-family:宋体; font-size:12pt">经证供应链平台</span><span
                style="font-family:宋体; font-size:12pt">依法追究该用户违约责任的权利。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">十三、法律适用及争议解决</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:宋体; font-size:12pt; line-height:150%; list-style-position:inside; margin:7.8pt 0pt; text-align:justify; text-indent:21pt">
                <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                    style="font-family:宋体; font-size:12pt">本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</span></li>
        </ol>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.因履行本协议发生的争议应通过协商解决，协商不成的，任何一方均有权将争议提交上海国际经济贸易仲裁委员会，按照提交仲裁时该会现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对各方均有约束力。</span>
        </p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:宋体; font-size:12pt">3.</span><span
                style="font-family:宋体; font-size:12pt">当产生任何争议及任何争议正按前条规定进行解决时，除争议事项外，双方应继续行使本协议项下的其他权利，履行本协议项下的其他义务。如本协议中的任何条款被认定为全部或部分不合法、无效或不可强制执行，并不影响其他条款的有效和执行。</span>
        </p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">十四、其他</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1.本协议适用的通知方式。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">（1）</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">
                用户平台账号（含站内信、系统消息及即时信息等）、向平台提供的企业联系地址、企业联系电话、企业电子邮件地址，向平台提供的法人/经办人联系电话、电子邮箱，任一均视为用户的有效送达地址。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（2）用户应保证所提供的上述送达地址是准确、有效的，并进行实时更新。对于送达地址的变更，用户有义务及时更新相关信息，并保持可被联系的状态。因提供信息不正确或未及时变更导致通知无法及时接收的，由用户自行承担可能产生的法律后果。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（3）平台将向用户的上述有效送达地址的任一或若干发送各类通知，而此类通知的内容可能对用户的权利义务产生重大有利或不利影响，用户务必及时关注。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（4）经证供应链平台对于用户的通知及任何其他的协议、告示或其他关于用户使用账户及服务的通知，以及人民法院、仲裁机构或调解机构向用户送达与应收账款电子凭证项下所有交易相关的法律文书（包括但不限于诉讼文书、仲裁文书、调解文书等），用户均不可撤销地同意并确认，平台及/或法院等相关机构可通过平台公告、站内信息、系统消息、电子邮件、手机短信或电子邮件地址或无线通讯装置等电子方式或常规的信件传递等方式发送至用户的任一有效送达地址，该等通知于发送之日视为已送达用户，用户拒收/未及时接收/未及时阅读/未及时登陆账户的，不影响送达的效力，用户如需变更送达地址，应当在信息变更后3个工作日内将变更后的送达地址通知平台及/或人民法院等机构，用户未按本条约定变更送达地址的，用户提供的原联系方式仍为有效送达地址。信息传输等原因导致用户未在前述通知发出当日收到该等通知的，平台不承担责任。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">（5）用户对平台的通知应通过平台对外正式公布的通信地址或电子邮件地址等联系地址进行送达。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.</span><span
                style="font-family:宋体; font-size:12pt">本平台</span><span
                style="font-family:宋体; font-size:12pt">未行使或执行</span><span
                style="font-family:宋体; font-size:12pt">本服务协议任何权利或规定，不构成对前述权利或权益之放弃。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3.如</span><span
                style="font-family:宋体; font-size:12pt">本服务协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务协议的其余条款仍应有效并且有约束力。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">免责声明：网站所展示的</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">用户相关</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">信息由会员自行提供，内容的真实性、准确性和合法性由发布</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">者自行</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">负责。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:normal">5.</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">友情提醒：注意融资风险，建议您在投融资时务必对他人公司的资质、实力进行详细调查了解，特别警惕各类前期费用！</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">十五、本协议一经用户点击“</span><span
                style="color:#ff0000; font-family:宋体; font-size:12pt; font-weight:bold">同意</span><span
                style="font-family:宋体; font-size:12pt">”按钮后，立即生效，为各方同意和认可，对各方均具有法律约束力。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
    </div>
</template>
<script>
export default {
  data() {
    const date = new Date();
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  }
}
</script>