<template>
    <div class="boxBusiness">
        <!-- 企业工商信息内容。 -->
        <div class="lineBorder"></div>

        <div class="boxContent">
            <el-row>
                <el-col :span="12">企业名称：{{msg.corpName}}</el-col>
                <el-col :span="12">统一社会信用代码：{{msg.creditCode}}</el-col>

                <!-- <el-col :span="8">证件编号：</el-col> -->

                <el-col :span="12">企业类型：{{getCorpType(msg.corpType)}}</el-col>
                <el-col :span="12">注册地址： {{msg.registerAddr}}</el-col>
                <el-col :span="12">注册资本：{{msg.registerCapital}}</el-col>
                <el-col :span="12">成立日期： {{msg.foundData}}</el-col>
                <!-- <el-col :span="12">营业期限： {{msg.businessPeriod}}</el-col> -->
            </el-row>
            <el-row>
                <div class="scope">
                    经营范围：
                </div>
                <div class="scopeContent">
                    {{msg.businessScope}}
                </div>
            </el-row>
        </div>
        <div class="imgContent">
            <el-row>
                <el-col :span="1.5">影像资料：</el-col>
                <el-col :span="22.5">
                    <div  v-for="(item,index) in imgList1" :key="index">
                         <img class="photo-box" :src="getImgURL(item.fileUrl)" preview="imgList" :preview-text="item.fileName"/>
                    </div>
                </el-col>
            </el-row>
        </div>



    </div>
</template>

<script>
import api from '@/api/api' 
import moment from 'moment'
export default {
    data(){
        return{
            
        }
    },
    props:{
        msg:{},
        fileList:{},
    },  
    computed:{
        imgList1() {
            return this.fileList.filter(v => +v.fileCode == 6)
        },
        
    },  


    methods:{
        
        moment,
        getCorpType(v) {
            if(v == 1) {
                return '资金方'
            } else if(v == 2) {
                return '核心企业'
            } else if(v == 3) {
                return '一般企业'
            } 
        },

        getImgURL(url) {
            return api.getImgURL(url)
        },
    }
}
</script>

<style lang='scss'>
    .lineBorder{
        width: 100%;
        border-bottom: 1px solid #dcdfe6;
    }
    .boxBusiness{
        padding: 10px 20px 10px 20px;
        // margin-top: 20px;
        .boxContent{
            .el-row{
                #col-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                .el-col{
                    padding: 15px 0px 15px 0px;
                }
                /** 此处是特殊样式内容。 */
                .scope{
                    padding: 10px 0px 15px 0px;
                    width: 70px;
                    display: inline-block;
                    vertical-align: top;
                    line-height: 30px;
                }
                .scopeContent{
                    padding: 10px 0px 15px 0px;
                    width: calc(100% - 100px);
                    display: inline-block;
                    vertical-align: top;
                    line-height: 30px;
                }



            }
        };

        .imgContent{
            .el-row{
                #col-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                .el-col{
                    padding: 10px 0px 10px 0px;
                }
            }
        }
    }

</style>