<!-- 票据详情 -->
<template>
    <div class="bill-details">
        <layout-main-view :breadcrumb='breadcrumblist'>
            <bill-details v-if="!success" :detailsList='detailsList'></bill-details>
            <div v-if="!success" class="footer-btn">
                <el-button @click="refuse">返回</el-button>
                <el-button type="primary" @click="agree">同意</el-button>
            </div>
            <!-- <div class="intention-orgs">
                <div class="bill-title">
                    <span>有意向资金机构</span>
                    <div class="intention-tips">有<span>{{detailsList.intentionalQuotation}}</span>个意向报价，最低意向利率<span>{{detailsList.minRate}}</span>，最高预计成交金额<span>{{detailsList.maxMoney}}人命币</span></div>
                </div>
            </div>
            <data-list
                ref="datalist"
            >
                <el-table
                    ref="orglist"
                    :data="dataList"
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="类型"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="releaseTime"
                        label="发布时间"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="acceptor"
                        label="承兑人"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="amount"
                        label="票面金额(元)"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="expireDate"
                        label="到期日"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="rate"
                        label="年化利率"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="charge"
                        label="每十万扣款(元)"
                        >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="80"
                        >
                        <template slot-scope="scope">
                            <span class="table-btn"  @click="handleOffer(scope.row)">报价</span>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
            <div class="bill-submit">
                <el-button>取消</el-button>
                <el-button type="primary" @click="hasRead">接受报价</el-button>
            </div> -->
            <el-dialog
                title="票据发布审批"
                :visible.sync="approvalVisible"
                width="30%"
                >
                <div class="approval-body">
                    <el-form ref="approvalForm" :model="approvalForm" label-width="120px" label-position="left">
                        <el-form-item v-if="approvalForm.approveResult == 2" label="退回原因">
                            <el-select 
                                v-model="approvalForm.reason" 
                                placeholder="请选择退回原因">
                                <el-option
                                    v-for="(item,index) in regionList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="approvalForm.approveResult == 2" label="拒绝原因">
                            <el-input type="textarea" v-model="approvalForm.refuseReason"></el-input>
                        </el-form-item>
                        <el-form-item label="备注信息">
                            <el-input type="textarea" v-model="approvalForm.remark"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="approvalVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sure">确 定</el-button>
                </span>
            </el-dialog>
            <div v-if="success" class="success">
                <el-steps :space="200" :active="1" finish-status="success" align-center>
                    <el-step title="内部审批"></el-step>
                    <el-step title="平台审批"></el-step>
                </el-steps>
                <div class="center-cont">
                    <div class="approval-people">
                        <span>审批人：</span>
                        <span>{{}}</span>
                    </div>
                    <div class="approval-time">
                        <span>审批时间：</span>
                        <span>{{}}</span>
                    </div>
                    <div><i class="el-icon-circle-check"></i></div>
                    <p>票据信息审批已通过。</p>
                    <p>等待平台审批。</p>
                    <div>
                        <el-button type="primary" @click="getknow">我知道了</el-button>
                    </div>
                </div>
            </div>
        </layout-main-view>
    </div>
</template>
<script>
    import api from "@/api/api";
    import billDetails from '@/components/billDetails' 
    export default {
        data() {
            return {
                breadcrumblist:['票据审批详情'],
                success: false,
                detailsList: {},
                approvalVisible: false,
                regionList: [],
                approvalForm: {
                    billId: this.$route.query.id,
                    approveResult: '',
                    reason: '',
                    refuseReason: '',
                    remark: '',
                    approveNode: '',
                },
                active: 0,
            }
        },
        created() {
            this.init();
            api.getDicListByType({type: '23'}).then((res) => { // 拒绝原因
                if(res.success) {
                    this.regionList = res.datas.list;
                }
            })
        },
        methods: {
            init() {
                let id =  this.$route.query.id
                api.BillDetails({billId: id}).then(res => {
                    if(res.success) {
                        this.detailsList = res.datas.billDto
                        this.approvalForm.approveNode = this.detailsList.billApproveStatus
                        this.detailsList.billFile[0].fileUrl = api.getImgURL(this.detailsList.billFile[0].fileUrl)
                        this.detailsList.billFlaw = this.detailsList.billFlaw.split(',');
                        this.detailsList.hideStatus == 1?this.detailsList.hideStatus=false:this.detailsList.hideStatus=true
                    }
                }) 
            },
            refuse() {
                this.approvalVisible = true;
                this.approvalForm.approveResult = 2;
            },
            agree() {
                this.approvalVisible = true;
                this.approvalForm.approveResult = 1;
            },
            sure() {
                api.SubmitBillResult(this.approvalForm).then(res => {
                    if(res.success) {
                        this.approvalVisible = false;
                        if(this.approvalForm.approveResult == 1) {
                            this.success = true;
                        }else {
                            this.$router.push('/bill/bill-manage/1');
                        }
                    }
                })
            },
            getknow() {
                this.$router.push('/bill/bill-manage/2');
            }
        },
        components: {
            'bill-details': billDetails
        }
    }
</script>
<style lang="scss">
    .bill-details {
        .data-list {
            padding-bottom: 120px;
        }
        .table-btn {
            font-size: 14px;
            color: #2937A6;
            cursor: pointer;
        }
        .intention-orgs {
            margin-top: 20px;
        }
        .bill-title {
            position: relative;
            font-size:16px;
            font-weight: 600;
            color:rgba(0,0,0,0.85);
            padding-left: 12px;
            margin-bottom: 24px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -7px;
                width: 3px;
                height: 14px;
                background: #CB8E51;
            }
            .intention-tips {
                display: inline-block;
                font-size: 14px;
                color: #000;
                font-weight: 500;
                margin-left: 16px;
                span {
                    color: #F49831;
                    padding: 0 3px;
                    &:first-child {
                        color: #2937A6;
                    }
                }
            }
        }
        .bill-submit {
            position: fixed;
            left: 230px;
            bottom: 0;
            right: 0;
            width: 100%;
            background: #fff;
            z-index: 10;
            box-shadow:0px -1px 4px 0px rgba(230,230,230,1),0px 1px 0px 0px rgba(230,230,230,1);
            padding: 12px;
            text-align: center;
            button {
                width: 160px;
                &+button {
                    margin-left: 24px;
                }
            }
        }
        .footer-btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
                &+.el-button {
                    margin-left: 40px;
                }
            }
        }
        .success {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 100px 0;
            .center-cont {
                text-align: center;
                .approval-people,.approval-time {
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 15px;
                }
            }
            background: #fff;
            .el-steps {
                justify-content: center;
                .el-step__title {
                    &.is-success {
                        color: #2937A6;
                    }
                }
            }
            .el-icon-circle-check{
                font-size: 100px;
                color: #09BB07;
            }
            p {
                font-size: 20px;
                font-weight: 600;
                margin: 20px 0;
            }
            .el-button {
                width: 120px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin-top: 30px;
                text-align: center;
                &+.el-button {
                    margin-left: 50px;
                }
            }
        }
    }
</style>