<template>
	<div id="app">
		<!-- <div class="logo_main" v-if="isTitle">
			<div class="logo_img"><img src="./assets/11.png" alt="" style="width:150px;"></div>
			<div class="logo_title">欢迎登录</div>
			<div class="logo_tag">
				<div class="logo_name">核心企业登录页</div>
				<div class="logo_login" v-if="isUse!= '/login'">已有账号,<router-link to="/login">请登录</router-link></div>
			</div>
		</div> -->
		<transition name="fade" mode="out-in">
			<router-view>2</router-view>

		</transition>
	</div>
</template>

<script>
import * as types from '@/store/mutation-types'
import toPath from './util/toPath'
export default {
	name: 'app',
	data () {
		return {
			isTitle: false,
			isUse: '/login'
		}
	},
	watch : {
		$route (to, from) {
			this.isUse = to.path
		}
	},
	created () {
		// this.getDics()
		if (toPath(this.$route.path)===2) {
			this.isTitle = true
		} else {
			this.isTitle = false
		}
		this.getUserInfoFromLocal()
	},
	mounted(){
	},
	methods:{
		//获取所有的字典
		getDics () {
			var dict = sessionStorage.getItem('dict')
			if(dict && dict != 'null' && dict != 'undefined'){
				dict = JSON.parse(dict)
				this.$store.commit(types.UPDATE_dict, dict)
			}else{
				this.$store.dispatch(types.GET_dict)
			}
		},
		getUserInfoFromLocal(){
			let userInfo = sessionStorage.getItem('user')
			userInfo = (_.trim(userInfo) && userInfo!="undefined" && userInfo!='null') ? JSON.parse(userInfo) : {}
			this.$store.commit(types.UPDATE_userInfo, userInfo)
		}
	}
}
</script>

<style lang="scss">
@import './styles/app.scss';

</style>