<template>
  <div>
       <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <openrequestapprovalfirst v-if="showtab==1"></openrequestapprovalfirst>
            <openrequestapprovalsecond v-if="showtab==2"></openrequestapprovalsecond>
    </layout-main-view>


  </div>
</template>
<script>
  import api from "@/api/api";
import openrequestapprovalfirst from "./openrequestapprovalfirst";
import openrequestapprovalsecond from "./openrequestapprovalsecond";




export default {
    components: {
    'openrequestapprovalfirst':openrequestapprovalfirst,
    'openrequestapprovalsecond':openrequestapprovalsecond
  },
  data() {
    return{
      breadcrumblist: ["邀请开单审批"],
          tabOptions: [
        { label: "待审批", value: "1", path: "/easypay/openrequestapproval/1" },
        { label: "已审批", value: "2", path: "/easypay/openrequestapproval/2" },
      ],
            showtab:1
    }
  },
  created() {
    this.showtab=this.$route.params.state

  },
  methods: {
     tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">

</style>
