<template>
   
         <el-row :gutter="20">
                <el-col :span="8">
                    <span class="label">报价利率(%): </span>
                    <span class="value">{{detailsList.offerPriceYearInterest}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">报价每十万扣费(元): </span>
                    <span class="value">{{$changeMoneyThousand(detailsList.chargeAmount)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">预计扣费总额(元): </span>
                    <span class="value">{{$changeMoneyThousand(detailsList.allChargeAmount)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">平台服务费(元): </span>
                    <span class="value">{{$changeMoneyThousand(detailsList.platformServiceFee)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">预计成交金额(元): </span>
                    <span class="value">{{$changeMoneyThousand(detailsList.dealAmount)}}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">确认报价时间: </span>
                    <span class="value">{{detailsList.quoteTimeLimit|quoteLimit}}</span>
                </el-col>
          
            </el-row>
 


  
</template>
<script>
    import api from "@/api/api.js";


    export default {
        components: {
     
        },
              props:{
           tableData:Array
        },
        data() {
            return {
                    detailsList:{},
                    reasons:{}
            }
        },
        created() {
            this.getinfo()
        },
        methods: {
                    getinfo(){
                        if(this.$route.query.isjk!=1){
                              var param={
                                                billId:this.$route.query.id,
                                                billQuoteApproveId:this.$route.query.billQuoteApproveId
                                                
                                        }
                                        api.billApproveDetail(param).then(res => {
                                                if(res.success){
                                                  
                                                   this.reasons=this.detailsList=res.datas.data
                                                   this.$emit('aaa',this.reasons)
                                                    if(res.datas.data.billFundQuotes){
                                                     this.detailsList=JSON.parse(res.datas.data.billFundQuotes)
                                                        
                                                    }else{
                                                     this.detailsList=res.datas.data

                                                    }
                                                     this.detailsList.platformServiceFee=res.datas.data.platformServiceFee
                                                }
                                    }) 
                        }else{
                                    api.pricebillDetail({billId: this.$route.query.id}).then(res => {
                                                if(res.success){                                               
                                
                                                     this.detailsList=res.datas.data
                                   
                                                }
                                    }) 
                        }
                           
                       

                  
                                      
                                 
                            
                      
             }
        
        }
    };
</script>


<style lang="scss">
   .bill-details {
        position: relative;
        background: #fff;
        padding: 33px 25px;
        .upload-box {
            margin-bottom: 20px;
            &-item {
                border: 1px solid #eee;
                background: #fff;
                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    padding: 0 20px;
                    background: #eee;
                }
                &-cont {
                    position: relative;
                    height: 300px;
                    overflow: auto;
                    .upload-img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        // max-width: 100%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .label {
            display: inline-block;
            width: 130px;
            height: 40px;
            line-height: 40px;
      
            margin-right: 20px;
        }
        .value {
            display: inline-block;
            height: 40px;
            line-height: 40px;
        }
        .flaw-list {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
            }
        }
        .connect-line {
            padding: 0 20px;
        }
    }

</style>
