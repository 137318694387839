<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="el-tabs__content pj">
        <el-form ref="form" :model="form" label-width="80px" class="pjform" label-position="left">
          <el-row>
            <el-col :span="12">
              <el-form-item label="票据类型:">
                <el-button
                  type="text"
                  :class="{active:item.active}"
                  size="small"
                  v-for="(item,index) in billtype"
                  :key="index"
                  @click="billtypefn(item,index)"
                >{{item.name}}</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="剩余期限:">
                <el-button
                  type="text"
                  :class="{active:item.active}"
                  size="small"
                  v-for="(item,index) in billdate"
                  :key="index"
                  @click="billtypefn(item,index)"
                >{{item.name}}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="票面金额:">
                <el-button
                  type="text"
                  :class="{active:item.active}"
                  size="small"
                  v-for="(item,index) in billprice"
                  :key="index"
                  @click="billtypefn(item,index)"
                >{{item.name}}</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="瑕疵:">
                <el-button
                  type="text"
                  :class="{active:item.active}"
                  size="small"
                  v-for="(item,index) in flaw"
                  :key="index"
                  @click="billtyperadiofn(item,index)"
                >{{item.name}}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="票据发布:">
                <el-checkbox-group v-model="form.isPublishedBill">
                  <el-checkbox label="我发布的" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="pjgjss" v-show="isshowlable">
            <el-row>
              <el-col :span="6">
                <el-form-item label="高级搜索:">
                  <el-input
                    v-model="form.acceptorSearch"
                    placeholder="请输入承兑人"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.billAmountStr"
                    placeholder="最低金额(万)"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5" class="pjblx">
                <div>~</div>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.billAmountEnd"
                    placeholder="最高金额(万)"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.minOfDays"
                    placeholder="最少天数"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5" class="pjblx">
                <div>~</div>
              </el-col>
              <el-col :span="3" class="pjdouable">
                <el-form-item>
                  <el-input
                    v-model="form.maxOfDays"
                    placeholder="最多天数"
                    size="small"
                    :clearable="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" class="pjsearchbtn">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="pjsearch">
          <span @click="ishowlablefn">
            {{!isshowlable?'高级搜索':'点击收起'}}
            <i class="el-submenu__icon-arrow el-icon-arrow-down" :class="{isacitve:isshowlable}"></i>
          </span>
        </div>
        <el-table :data="listData" highlight-current-row style="width: 100%;">
          <el-table-column label="类型" width="100">
            <template slot-scope="scope">{{ scope.row.billType|billType}}</template>
          </el-table-column>
          <el-table-column label="发布时间" min-width="170">
            <template slot-scope="scope">{{ scope.row.createTime|formatDate }}</template>
          </el-table-column>
          <el-table-column prop="acceptor" label="承兑人" min-width="150"></el-table-column>
          <el-table-column label="票面金额(元)" min-width="150" align="right">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.billAmount) }}</template>
          </el-table-column>
          <el-table-column label="到期日" min-width="150">
            <template slot-scope="scope">{{ scope.row.expireTime|formatDate }}</template>
          </el-table-column>
          <el-table-column label="年化利率" min-width="150">
            <template slot-scope="scope">{{scope.row.sellRateStr+'~'+scope.row.sellRateEnd}}</template>
          </el-table-column>
          <el-table-column label="每十万元扣费(元)" min-width="160">
            <template
              slot-scope="scope"
            >{{$changeMoneyThousand(scope.row.deductionStr)+'~'+$changeMoneyThousand(scope.row.deductionEnd)}}</template>
          </el-table-column>

          <el-table-column label="瑕疵" width="80">
            <template slot-scope="scope">{{scope.row.flawStatus==1?'无':'有'}}</template>
          </el-table-column>
        </el-table>
        <div class="data-list">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="pageSize"
            :page-size="pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="票据详情"
      :visible.sync="dialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
      class="billinfoprice"
    >
      <billinfopirce ref="chi"></billinfopirce>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="surebtn">确 定</el-button>
      </span>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api.js";
import billinfopirce from "../pjbase/billinfopirce.vue";

export default {
  components: {
    billinfopirce: billinfopirce
  },
  data() {
    return {
      dialogVisible: false,
      breadcrumblist: ["票据中心"],
      pageSizes: 10,
      pageNum: 1,
      form: {
        billTypes: [], //票据类型
        billExpires: [], //剩余期限,
        billAmounts: [], //票面金额,
        isPublishedBill: false, //我发布的票据,
        flawStatus: "",
        acceptorSearch: "", //承兑人,
        billAmountStr: "", //最低金额,
        billAmountEnd: "", //最高金额,
        minOfDays: "", //最少天数,
        maxOfDays: "" //最多天数
      },
      billtype: [
        { name: "商票", active: false, value: 1 },
        { name: "国股", active: false, value: 2 },
        { name: "城商", active: false, value: 3 },
        { name: "三农", active: false, value: 4 },
        { name: "财票", active: false, value: 5 }
      ],
      billprice: [
        { name: "10万以下", active: false, value: 1 },
        { name: "10-100万", active: false, value: 2 },
        { name: "100万以上", active: false, value: 3 }
      ],
      billdate: [
        { name: "90天以内", active: false, value: 1 },
        { name: "90-180天", active: false, value: 2 },
        { name: "180-360天", active: false, value: 3 }
      ],
      flaw: [
        { name: "有瑕疵", active: false, value: 2 },
        { name: "无瑕疵", active: false, value: 1 }
      ],
      listData: [],
      pageSize: [5, 10, 20, 30],
      total: 0,
      isshowlable: false,
      showlable: "高级搜索"
    };
  },
  created() {
    this.getlist({ pageNum: 1, pageSize: 10, isPublishedBill: false });
  },
  methods: {
    onSubmit() {
         this.form.billTypes=[]
      this.form.billExpires=[]
      this.form.billAmounts=[]
      this.billtype.map(el => {
        el.active && this.form.billTypes.push(el.value);
      });
      this.billdate.map(el => {
        el.active && this.form.billExpires.push(el.value);
      });
      this.billprice.map(el => {
        el.active && this.form.billAmounts.push(el.value);
      });
      if (this.flaw.some(el => el.active)) {
        this.flaw.map(el => {
          el.active && (this.form.flawStatus = el.value);
        });
      } else {
        this.form.flawStatus = "";
      }

      this.getlist(this.form);
    },
    resetForm(formName) {
      console.log(this.$refs[formName].resetFields);
      this.billtype.map(el => (el.active = false));
      this.$refs[formName].resetFields();
    },
    billtypefn(item, index) {
      item.active = !item.active;
    },
    billtyperadiofn(item, index) {
      if (item.active) {
        item.active = false;
      } else {
        this.flaw.map(el => {
          el.active = false;
        });
        item.active = true;
      }
    },
    handleSizeChange(val) {
      this.pageSizes = val;

      this.$router.replace({
        path: "/bill/billcenter",
        query: { pageSize: this.pageSizes, pageNum: 1 }
      });
      var params = Object.assign(this.form, { pageNum: 1, pageSize: val });
      this.getlist(params);

      console.log(`每页 ${val} 条`, this.pageNum);
    },
    handleCurrentChange(val) {
      console.log(val, 999);
      this.pageNum = val;
      this.$router.replace({
        path: "/bill/billcenter",
        query: { pageSize: this.pageSizes, pageNum: this.pageNum }
      });
      var params = Object.assign(this.form, {
        pageNum: val,
        pageSize: this.pageSizes
      });
      this.getlist(params);
      console.log(`当前页: ${val}`);
    },
    getlist(params) {
      if (this.$route.query.pageNum) {
        this.pageNum = +this.$route.query.pageNum;
        this.pageSizes = +this.$route.query.pageSize;
      }
      //      console.log(params,22)
      this.$router.push({
        path: "/bill/billcenter",
        query: { pageSize: this.pageSizes, pageNum: this.pageNum }
      });
      api.billHall(params).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    ishowlablefn() {
      this.isshowlable = !this.isshowlable;
    },
    surebtn() {
      console.log(this.$refs.chi);
      this.$refs.chi.$refs["formchild"].validate(valid => {
        if (valid) {
          console.log(this.$refs.chi.formchild);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>


<style lang="scss">
.el-tabs__content.pj {
  padding: 6px 24px 0 24px;
}
.pjform {
  margin-top: 10px;
  background: rgba(246, 247, 250, 1);
  border-radius: 2px;
  padding: 24px 25px;
  .el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(136, 136, 136, 1);
  }
  .el-button--text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding: 2px 8px;
  }
  .el-button--text.active {
    background: rgba(41, 55, 166, 1);
    border-radius: 4px;
    color: #ffffff;
    padding: 2px 8px;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .pjdouable {
    .el-form-item__content {
      margin-left: 0 !important;
    }
    margin-left: 20px;
  }
  .pjblx {
    text-align: right;
    width: 30px;
    position: relative;
    top: 10px;
  }
  .pjgjss {
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    padding-top: 16px;
  }
  .pjsearchbtn {
    width: 65px;
    height: 32px;
    line-height: 0;
  }
}
.pjsearch {
  .el-submenu__icon-arrow {
    position: inherit;
    color: rgba(0, 0, 0, 0.85) !important;
    margin-left: 8px;
  }
  span {
    background: rgba(246, 247, 250, 1);
    display: inline-block;
    padding: 1px 16px;
    border-radius: 2px;
    cursor: pointer;
  }
  .isacitve.el-submenu__icon-arrow {
    transform: rotateZ(180deg);
  }
  text-align: center;
  margin-bottom: 24px;
}
</style>
