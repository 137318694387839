<template>
  <div>
    <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
      <fundDstinguishingapprovalfirst v-if="showtab==1"></fundDstinguishingapprovalfirst>
      <fundDstinguishingapprovalsecond v-if="showtab==2"></fundDstinguishingapprovalsecond>
    </layout-main-view>
  </div>
</template>
<script>
import api from "@/api/api";
import fundDstinguishingapprovalfirst from "./fundDstinguishingapprovalfirst";
import fundDstinguishingapprovalsecond from "./fundDstinguishingapprovalsecond";
export default {
    components: {
    'fundDstinguishingapprovalfirst':fundDstinguishingapprovalfirst,
    'fundDstinguishingapprovalsecond':fundDstinguishingapprovalsecond
  },
  data() {
    return{
      breadcrumblist: ["资金清分审批"],
          tabOptions: [
        { label: "待审批", value: "1", path: "/easypay/fundDistinguishingapproval/1" },
        { label: "已审批", value: "2", path: "/easypay/fundDistinguishingapproval/2" },
        ],
            showtab:1,

    }
  },
  created() {
    console.log(this.$route)
    this.showtab=this.$route.params.state;
  },
  methods: {
     tabclick(val) {
    this.showtab=val;
      this.handleEditShow = false;
    },
  },

};
</script>

<style lang="scss">

</style>