<template>
    <div class="box">
        <div class="boxComtent">
            <el-row>
                <el-col id="col-title" :span="24">
                    <span>{{msg.corpName}}</span>
                </el-col>

                <el-col :span="8">企业法人：{{msg.legalName}}</el-col>
                <el-col :span="8">统一信用代码： {{msg.creditCode}}</el-col>
                <el-col :span="8">注册账号：{{msg.phone}}</el-col>
                <el-col :span="8">注册时间：{{msg.createTime ? moment(msg.createTime).format('YYYY-MM-DD HH:mm'): ''}}</el-col>
                <el-col :span="8">注册类型： {{msg.registerType==1 ? '法人认证' : '经办人认证'}}</el-col>
                <el-col :span="8">认证时间：{{msg.identificationTime ? moment(msg.identificationTime).format('YYYY-MM-DD HH:mm'): ''}}</el-col>
                
                
                <el-col :span="8">经办人姓名：{{msg.agentName}}</el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data(){
        return{

        }
    },
    props:{
        msg:{},
    },
    created() {
        console.log(this.msg);
        
    },
    methods:{
        moment,
        
    }
}
</script>

<style  lang='scss' scoped>
    .box{
        padding: 10px 20px 10px 20px;
        border: 1px dashed black;
        margin-top: 20px;
        .boxComtent{
            .el-row{
                #col-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                .el-col{
                    padding: 10px 0px 10px 0px;
                }
            }
        }
    }
</style>