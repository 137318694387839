<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick">
      <waitdistinguishing  v-if="showtab==1&&zjlist.length" :message='zjlist'></waitdistinguishing>
      <readlydistinguishing v-if="showtab==2&&zjlist.length" :message='zjlist'> </readlydistinguishing>
      <overduedistinguishing   v-if="showtab==3&&zjlist.length" :message='zjlist'></overduedistinguishing>
    </layout-main-view>
  </div>
</template>
<script>
import waitdistinguishing from "./zjwaitdistinguishing.vue";
import readlydistinguishing from "./zjreadlydistinguishing";
import overduedistinguishing from "./zjoverduedistinguishing";
import api from "@/api/api";
import moment from "moment";

export default {
  components: {
    waitdistinguishing: waitdistinguishing,
    readlydistinguishing: readlydistinguishing,
    overduedistinguishing: overduedistinguishing
  },
  computed: {
    zjlist() {
      return this.showlist;
    }
  },
  data() {
    return {
      breadcrumblist: ["应付资金清分"],
      showtab: 1,
      tabOptions: [
        {
          label: "待清分",
          value: "1",
          path: "/easypay/zjdistinguishingmange/1"
        },
        {
          label: "已清分",
          value: "2",
          path: "/easypay/zjdistinguishingmange/2"
        },
        {
          label: "逾期未清分",
          value: "3",
          path: "/easypay/zjdistinguishingmange/3"
        }
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      showlist: []
    };
  },
  created() {
    this.getGrantCreditCorpList();
  },
  mounted() {
    this.showtab = this.$route.params.state;
  },
  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
    getGrantCreditCorpList() {
      //调取资金机构数据
      api.getGrantCreditCorpList().then(res => {
        if (res.code == 100000) {
          this.showlist = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>