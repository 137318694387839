import interceptors from './interceptors.js';
var _axios = interceptors._axios;
import { baseURL, baseQRCodeURL } from './sys.js';
interceptors.interceptor();
export default {
  // 快速登录/login/quickLogin
  quickLogin: function (params) {
    return _axios.get(`/login/quickLogin`, { params: params });
    // return _axios.post(`/login/quickLogin?corpId=${data.corpId}&password=${data.password}`, data, { params: params });
  },
  getImgURL(url) {
    return baseURL + `/web/file/downFile?filePath=${url}`;
  },
  getImgUrl() {
    return baseURL + `/web/file/downFile?filePath=`;
  },
  // 文件回显
  downFile() {
    return `${baseURL}` + '/web/file/downFile';
  },
  //合同文件打包下载
  filesdownLoadFile(params) {
    return (
      baseURL +
      `/web/finance/downLoadFile?payableBillId=${params.payableBillId}&payableBillContractId=${params.payableBillContractId}&fileName=${params.contractName}`
    );
  },
  //abs资产包下载 第一步
  assetPackageDownLoad(data) {
    return _axios.get('/web/abs/account/file/assetPackageDownLoad', {
      params: data,
    });
  },
  //第二步
  downBrowser(data) {
    return (
      baseURL +
      '/web/file/downBrowser?filePath=' +
      data.fileUrl +
      '&fileName=' +
      data.fileName
    );
    //  return _axios.get('/web/file/downBrowser', { params: data, responseType: 'blob' });
  },

  // 上链信息列表
  blockChainList: function (data, params) {
    return _axios.post('/web/blockChain/list', data, { params: params });
  },
  // 上链信息详情
  blockChainDetail: function (data, params) {
    return _axios.post('/web/blockChain/detail', data, { params: params });
  },
  // 承诺付款协议列表
  getPayableBillContractList: function (data, params) {
    return _axios.post('/web/contract/getPayableBillContractList', data, {
      params: params,
    });
  },
  // 融资合同列表
  getFinanceContractList: function (data, params) {
    return _axios.post('/web/contract/getFinanceContractList', data, {
      params: params,
    });
  },
  htwjdownLoadFile: function (data) {
    return _axios.get('/web/finance/downLoadFile', {
      params: data,
      responseType: 'blob',
    });
  },
  // 易票据合同发票一键下载
  downLoadFile: function (data) {
    return _axios.get('/billbase/downLoadFile', {
      params: data,
      responseType: 'blob',
    });
  },
  //文件单个下载
  filesdownsingle(data) {
    return _axios.get('/web/file/previewFile', {
      params: data,
      responseType: 'blob',
    });
  },
  //文件删除
  filesDelete(data) {
    return _axios.post('/web/file/deleteFile', data);
  },
  //sokit
  websocket() {
    return baseURL + `/websocket/` + sessionStorage.token;
  },
  // 优企通合同模版
  GetFinanceContact: function (params) {
    return _axios.get('/web/finance/getFinanceContact', { params: params });
  },

  //查询数据字典 拒绝原因
  getDicListByType: function (params) {
    return _axios.get('/admin/dic/getDicListByType', { params: params });
  },
  //登录
  Login: function (data, params) {
    return _axios.post('/login/sysUserToLogin', data, { params: params });
  },
  //登录 获取企业
  getCorpIdByUserName: function (params) {
    return _axios.get('/login/getCorpIdByUserName', { params: params });
  },
  //登录个人身份证认证
  InformationCheck: function (params) {
    return _axios.get('/login/InformationCheck', { params: params });
  },

  toLogout: function (params) {
    return _axios.get('/login/toLogout', { params: params });
  },
  //修改密码
  toResetPassword: function (data) {
    return _axios.post('/login/resetPassword', data);
  },
  //重置密码
  resetPassword: function (data) {
    return _axios.post('/login/updatePassword', data);
  },
  //菜单信息添加/修改／停用
  addOrUpdateMenu: function (data, params) {
    return _axios.post('/sysMenu/addOrUpdateMenu', data, { params: params });
  },
  //
  getPersonalAuthInfo: function (params) {
    return _axios.get('/login/getPersonalAuthInfo', { params: params });
  },
  //优企通管理
  // 是否阅读优企通平台服务协议
  readAgreement: function () {
    return _axios.get('/admin/corp/agreedConsent');
  },
  //已转让列表
  AssignList: function (data, params) {
    return _axios.post('/web/enterprise/manager/assign/list', data, {
      params: params,
    });
  },
  //已融资列表
  FinanceList: function (data, params) {
    return _axios.post('/web/enterprise/manager/finance/list', data, {
      params: params,
    });
  },
  //持有中列表
  HoldList: function (data, params) {
    return _axios.post('/web/enterprise/manager/hold/list', data, {
      params: params,
    });
  },
  //持有中 融资
  ToPayableBillFinance: function (params) {
    return _axios.get('/web/finance/toPayableBillFinance', { params: params });
  },
  //持有中 融资
  getPayableBillLiquidationFileByPayableBillNo: function (params) {
    return _axios.get(
      '/bill/liquidation/file/getPayableBillLiquidationFileByPayableBillNo',
      { params: params }
    );
  },
  //新增优企通融资
  AddPayableBillFinance: function (data, params) {
    return _axios.post('/web/finance/addPayableBillFinance', data, {
      params: params,
    });
  },
  //融资退回重新提交凭证
  CommitPayableBillFinance: function (params) {
    return _axios.post(
      '/web/finance/return/CommitPayableBillFinance' +
        '?payableBillFinanceId=' +
        `${params.payableBillFinanceId}` +
        '&payVoucherPath=' +
        `${params.payVoucherPath}`
    );
  },
  // 优企通融资 根据入驻校验是否符合准入规则
  checkFinance: function (params) {
    return _axios.get('/web/finance/checkFinance', { params: params });
  },
  //已开立列表
  OpenList: function (data, params) {
    return _axios.post('/web/enterprise/manager/open/list', data, {
      params: params,
    });
  },
  //待接收列表
  ReceiveList: function (data, params) {
    return _axios.post('/web/enterprise/manager/receive/list', data, {
      params: params,
    });
  },
  //复审详情 审批
  FinanceApprove: function (data, params) {
    return _axios.post('/web/financeApprove/financeApprove', data, {
      params: params,
    });
  },
  //废除优企通
  easypaydel: function (params) {
    return _axios.get('/web/enterprise/manager/delete', { params: params });
  },

  // /anxinSign/downloadContract 预览

  DownloadContract: function (params) {
    return _axios.get('/anxinSign/downloadContract', {
      params: params,
      responseType: 'blob',
    });
  },

  //已清分列表-核心/一般
  getClearingListByPage: function (data, params) {
    return _axios.post('/web/bill/getClearingListByPage', data, {
      params: params,
    });
  },
  //已清分列表-资方
  getFundClearingListByPage: function (data, params) {
    return _axios.post('/web/bill/getFundClearingListByPage', data, {
      params: params,
    });
  },
  //清分审核记录
  getPayableBillLiquidationApproveList: function (data, params) {
    return _axios.post(
      '/bill/liquidation/approve/getPayableBillLiquidationApproveList',
      data,
      { params: params }
    );
  },

  //优企通融资详情详情 审批
  ///web/finance
  FinanceDetial: function (params) {
    return _axios.get('/web/finance', { params: params });
  },
  PayableBillFinanceCompute: function (data, params) {
    return _axios.post('/web/finance/payableBillFinanceCompute', data, {
      params: params,
    });
  },
  //已退回列表
  ReturnList: function (data, params) {
    return _axios.post('/web/enterprise/manager/return/list', data, {
      params: params,
    });
  },
  //优企通审批 - 融资审批

  GetCorpFinanceApproveList: function (params) {
    return _axios.get('/web/finance/corp/getCorpFinanceApproveList', {
      params: params,
    });
  },

  // 查看额度详情
  getGrantCreditDetail: (params) => {
    return _axios.get(`/admin/credit/getGrantCreditDetail`, { params: params });
  },

  //融资项目管理
  GetSignApproveList: function (params) {
    return _axios.get('/web/financeManager/getCorpFinanceApprove', {
      params: params,
    });
  },
  signConfirmWait: function (data) {
    return _axios.post('/web/financeApprove/signConfirmWait', data);
  },

  //

  //签约前 发送短信验证
  SignSendMessage: function (params) {
    return _axios.get('/web/financeSign/signSendMessage', { params: params });
  },
  //校验短信验证
  CheckSendMessage: function (data) {
    return _axios.post('/web/phone/verifyOperatorMessage', data);
  },
  //开立校验
  CheckverifyOperatorMessage: function (data) {
    return _axios.post('/cfca/sign/getPdfData', data);
  },

  //签约hash
  getSignHash: function (data) {
    return _axios.post('/cfca/sign/getPdfData', data);
  },

  //短信验证
  sendPhoneMessage: function (data, params) {
    return _axios.post('/web/phone/sendPhoneMessage', data, { params: params });
  },
  //通知联系人
  popMessage: function (params) {
    return _axios.get('/web/phone/popMessage', { params: params });
  },
  //新建个人 短信验证
  getPhoneVerifyCode: function (data, params) {
    return _axios.post('/web/phone/getPhoneVerifyCode', data, {
      params: params,
    });
  },
  //发起签约
  FinanceSign: function (params) {
    return _axios.get('/web/financeSign/financeSign', { params: params });
  },
  //签约详情
  GetSignContract: function (params) {
    return _axios.get('/web/financeSign/getSignContract', { params: params });
  },

  // /web/account/public/getBindAccountList
  //融资项目绑定账户查询 不
  // GetBindAccountList: function( params) {
  //   return _axios.get("/web/account/public/getBindAccountList?openBank="+params);
  // },

  GetBindAccountList: function (params) {
    return _axios.get(
      '/web/account/public/getBindAccountList?openBank=' + params
    );
  },

  //图形验证码接口GET /verifycode/{uuid}
  verifycode: function (params) {
    return baseURL + '/verifycode/' + params;
  },
  //注册POST /admin/register
  register: function (data, params) {
    return _axios.post('/admin/register', data, { params: params });
  },
  //  资料完善信息POST /web/file/uploadFile企业图片上传接口
  uploadFile: function (data, params) {
    return _axios.post('/web/file/uploadFile', data, { params: params });
  },
  //  资料完善信息营业执照上传接口POST /admin/uploadBusinessLicense
  uploadBusinessLicense: function (data, params) {
    return _axios.post('/admin/uploadBusinessLicense', data, {
      params: params,
    });
  },

  //资料完善信息企业性质传接口 GET /admin/dic/getCorpNature
  getCorpNature: function (data, params) {
    return _axios.get('/admin/dic/getCorpNature', data, { params: params });
  },
  //资料完善信息企业性质传接口 GET /admin/dic/getCorpNature
  personalDetailAdd: function (data, params) {
    return _axios.post('/admin/personalDetailAdd', data, { params: params });
  },

  //资料完善信息企业性质传接口 GET /admin/dic/getCorpNature
  corpDetailAdd: function (data, params) {
    return _axios.post('/admin/corpDetailAdd ', data, { params: params });
  },
  //资料完善信息所属行业一级传接口 /admin/industry/getFirstIndustry
  getFirstIndustry: function (data, params) {
    return _axios.get('/admin/industry/getFirstIndustry', data, {
      params: params,
    });
  },
  //资料完善信息所属行业二级传接口
  getSecondIndustry: function (params) {
    return _axios.get(
      '/admin/industry/getSecondIndustry?corpIndustryId=' + params
    );
  },
  //菜单信息添加/修改／停用
  addOrUpdateMenu: function (data, params) {
    return _axios.post('/sysMenu/addOrUpdateMenu', data, { params: params });
  },
  //创建优企通
  addPayableBill: function (data) {
    return _axios.post('/web/bill/addPayableBill', data);
  },
  //反向开立优企通
  reversePayableBill: function (data) {
    return _axios.post('/web/bill/reversePayableBill', data);
  },
  //反向开立优企通
  changePayableBill: function (data) {
    return _axios.post('/web/enterprise/manager/updatet', data);
  },

  //转让优企通
  transferPayableBill: function (data) {
    return _axios.post('/web/bill/transferPayableBill', data);
  },

  //生成应收款转让通知书模板
  createTransferNotification: function (data) {
    return _axios.post('/cfca/sign/createTransferNotification', data);
  },
  //转让pdf文件签章
  transferpdfEsign: function (data) {
    return _axios.post('/cfca/sign/pdfEsign', data);
  },
  //转让通知书列表
  getTransferContractList: function (data) {
    return _axios.post('/web/contract/getTransferContractList', data);
  },
  //转让通知书列表
  getTransferContractList1: function (data) {
    return _axios.post('/web/contract/getTransferContractList1', data);
  },
  //开单协议
  getOpenAgreementList: function (data) {
    return _axios.post('/web/contract/getOpenAgreementList', data);
  },
  //转让协议
  getTransferAgreementList: function (data) {
    return _axios.post('/web/contract/getTransferAgreementList', data);
  },

  detailAdd: function (data, params) {
    return _axios.post('/admin/detailAdd', data, { params: params });
  },
  //优企通管理流转详情
  circulation: function (params) {
    return _axios.get('/web/enterprise/manager/circulation', {
      params: params,
    });
  },
  //优企通管理已清分流转详情
  getCashFlow: function (params) {
    return _axios.get('/web/bill/getCashFlow', { params: params });
  },
  // 判断企业是否开户（优企通开立时）
  doOpenAccount: function (params) {
    return _axios.get('/admin/corp/doOpenAccount', { params: params });
  },
  /***************保理业务**************/
  //创建应收账款
  addReceiveAccount: function (data, params) {
    return _axios.post('/web/receivableAccount/addReceiveAccount', data, {
      params: params,
    });
  },
  //变更应收账款
  updateReceiveAccount: function (data, params) {
    return _axios.post('/web/receivableAccount/updateReceiveAccount', data, {
      params: params,
    });
  },

  //应收账款列表 通过
  receivableAccountlist: function (data, params) {
    return _axios.post('/web/receivableAccount/list', data, { params: params });
  },
  //应收账款列表 拒绝
  refusereceivableAccountlist: function (data, params) {
    return _axios.post('/web/receivableAccount/update/list', data, {
      params: params,
    });
  },

  //应收账款详情
  receivableAccountdetail: function (data) {
    return _axios.get('/web/receivableAccount/detail', { params: data });
  },
  //应收账款内审提交
  submitReceivableAccountResult: function (data) {
    return _axios.post(
      '/web/account/approve/submitReceivableAccountResult',
      data
    );
  },

  //融资管理 待补件列表
  getFinanceAddDocumentsListPage: function (data) {
    return _axios.post(
      '/receivableAccountFinance/getFinanceAddDocumentsListPage',
      data
    );
  },
  //待补件详情
  getAddDocumentsDetail: function (data) {
    return _axios.get('/receivableAccountFinance/getAddDocumentsDetail', {
      params: data,
    });
  },
  //提交单个补件信息
  submitAddDocument: function (data) {
    return _axios.post('/receivableAccountFinance/submitAddDocument', data);
  },
  //提交总补件信息
  submitAddDocumentResult: function (data) {
    return _axios.post(
      '/receivableAccountFinance/submitAddDocumentResult',
      data
    );
  },
  //提交确权
  submitConfirmationApply: function (data) {
    return _axios.post(
      '/receivableAccountFinance/submitConfirmationApply',
      data
    );
  },
  //提交补件审批
  submitAddDocumentApply: function (data) {
    return _axios.post(
      '/receivableAccountFinance/submitAddDocumentApply',
      data
    );
  },
  //确权列表
  getAckListPage: function (data) {
    return _axios.get('/web/receivableAccountMatch/getAckListPage', {
      params: data,
    });
  },
  //确权列表详情
  getAckListInfo: function (data) {
    return _axios.get('/web/receivableAccountMatch/getAckListInfo', {
      params: data,
    });
  },

  //提交确权资料
  uploadAckFile: function (data) {
    return _axios.post('/web/receivableAccountMatch/uploadAckFile', data);
  },
  //提交确权
  coreCorpAck: function (data) {
    return _axios.get('/web/receivableAccountMatch/coreCorpAck', {
      params: data,
    });
  },
  //补件审批列表
  getFinanceSupplyFileListPage: function (data) {
    return _axios.get(
      '/web/receivableAccountMatch/getFinanceSupplyFileListPage',
      { params: data }
    );
  },
  //补件审批
  checkFinanceSupplyFile: function (data) {
    return _axios.post(
      '/web/receivableAccountMatch/checkFinanceSupplyFile',
      data
    );
  },
  //补件审批退回列表
  getSupplyReturnListPage: function (data) {
    return _axios.get('/web/receivableAccountMatch/getSupplyReturnListPage', {
      params: data,
    });
  },

  //资金机构菜单 传值1
  //获取资金机构
  getGrantCreditCorpList: function (params) {
    return _axios.get('/admin/corp/getGrantCreditCorpList', { params: params });
  },
  // 接收企业全称 传值3   5链属企业+核心企业
  getCorpList: function (params) {
    return _axios.get('/admin/corp/getCorpList', { params: params });
  },
  // 根据id查询白名单账户（机构还款账户）
  // getAccountWhitelistListByCorpId = params => { return _axios.get(`${root}` + '/admin/accountWhitelist/getAccountWhitelistListByCorpId', { params: params }).then(res => res) },
  selectCreditCorp: function (params) {
    return _axios.get('/customer/corp/manage/selectCreditCorp', {
      params: params,
    });
  },
  //反向开立 接收企业
  getSuperiorList: function (data) {
    return _axios.post('/web/enterprise/white/getSuperiorList ', data);
  },
  //查询授信编号
  getGrantCreditListByCorp: function (data) {
    return _axios.post('/admin/credit/getGrantCreditListByCorp', data);
  },
  //开立审批列表 待复核
  getFirstTrialList: function (data) {
    return _axios.post('/web/approve/getFirstTrialList', data);
  },
  //开立审批 已复核
  getFirstTrialResultList: function (data) {
    return _axios.post('/web/approve/getFirstTrialResultList', data);
  },
  //开立请求审批列表 待确认
  getOuterFirstTrialList: function (data) {
    return _axios.post('/web/approve/getOuterFirstTrialList', data);
  },
  //开立请求审批列表 已确认
  getOuterFirstTrialResultList: function (data) {
    return _axios.post('/web/approve/getOuterFirstTrialResultList', data);
  },
  //开立审批 详情
  createfirstdetail: function (data) {
    return _axios.get('/web/enterprise/manager/detail', { params: data });
  },
  //查看优企通转让详情
  getPayableBillById: function (data) {
    return _axios.get('/web/bill/getPayableBillById', { params: data });
  },
  //复核提交审批
  submitPayableBillResult: function (data) {
    return _axios.post('/web/approve/submitPayableBillResult', data);
  },
  //查看企业认证状态
  GetCorpByCorpId: function (params) {
    return _axios.get('/login/getCorpByCorpId', { params: params });
  },
  //查询cfca认证是否通过 更新认证状态
  getVerifyFaceIdentity({ userId }) {
    return _axios.post('/living-certificate/verifyFaceIdentity',{ userId})
  },
  //查看个人认证状态
  getPersonalInformationAuth: function (params) {
    return _axios.get('/login/getPersonalInformationAuth', { params: params });
  },
  //企业在CFCA 创建签约账户
  CorpSignCreateUser: function (data, params) {
    return _axios.post('/web/financeSign/corpSignCreateUser', data, {
      params: params,
    });
  },
  CorpSignCreate: function () {
    return _axios.post('/web/financeSign/corpSignCreateUser');
  },
  //企业对公账户
  GetCorpAccountPublicListPage: function () {
    return _axios.get('web/account/public/getCorpAccountPublicListPage');
  },
  //企业对公账户
  AddCorpAccountPublic: function (data) {
    return _axios.post('web/account/public/addCorpAccountPublic', data);
  },
  //企业链属上下游关系同步
  addToShBank: function (data) {
    return _axios.post('web/enterprise/white/addToShBank', data);
  },
  //企业链属白名单管理列表查询
  GetWhiteLits: function (data) {
    return _axios.post('web/enterprise/white/list', data);
  },
  //企业链属白名单添加
  AddWhiteLits: function (data) {
    return _axios.post('web/enterprise/white/add', data);
  },
  //企业链属白名单批量导入
  submitUploadChain: function (data, params) {
    return _axios.post('/web/enterprise/white/submitUploadChain', data, {
      params: params,
    });
  },
  //企业链属白名单导入模版下载
  downTemplate: function (params) {
    return _axios.get('/web/file/downTemplate', {
      params: params,
      responseType: 'blob',
    });
  },
  //获取开户行 列表查询
  GetOpenBank: function () {
    return _axios.get('web/account/public/getOpenBank');
  },
  //获取省市区
  GetCorpNature: function (params) {
    return _axios.get('admin/area/getAreaList', { params });
  },
  //删除企业对公账户
  DeleteCorpAccountPublic: function (params) {
    return _axios.delete('web/account/public/deleteCorpAccountPublic', {
      params,
    });
  },
  //企业申请打款验证
  CorpPayVerify: function (data) {
    return _axios.post('web/account/public/corpPayVerify', data);
  },
  //企业申请打款申请
  CorpPayApplication: function (data) {
    return _axios.post('web/account/public/corpPayApplication', data);
  },
  //解绑
  Unbind: function (data) {
    return _axios.post('web/account/public/unBind', data);
  },
  //请求开立审批待确认
  getReverseAddList: function (data) {
    return _axios.post('/web/approve/getReverseAddList', data);
  },
  //请求开立审批已确认
  getReverseAddResultList: function (data) {
    return _axios.post('/web/approve/getReverseAddResultList', data);
  },

  //反向开立拒绝审核
  reversePayableBillFailResult: function (data) {
    return _axios.post('/web/approve/reversePayableBillFailResult', data);
  },
  //反向开立接受审核
  reversePayableBillPassResult: function (data) {
    return _axios.post('/web/approve/reversePayableBillPassResult', data);
  },
  //查询转让接收企业
  getSubordinateList: function (data) {
    return _axios.post('/web/enterprise/white/getSubordinateList', data);
  },
  //优企通额度管理历史额度列表查询
  HistoryList: function (data) {
    return _axios.post('web/enterprise/credit/history/list', data);
  },
  //优企通额度管理可用额度列表查询
  AvailableList: function (data) {
    return _axios.post('web/enterprise/credit/available/list', data);
  },
  //优企通业务总揽
  GetCensus: function (params) {
    return _axios.get('web/enterprise/statistics/census', { params });
  },
  //核心数据统计(优企通接收)
  ReceiveCensus: function (data) {
    return _axios.post('web/enterprise/statistics/received/census', data);
  },
  //核心数据统计(优企通开立)
  OpenCensus: function (data) {
    return _axios.post('web/enterprise/statistics/open/census', data);
  },
  //判断承诺付款日是否为工作日
  checkPromisePayTime: function (params) {
    return _axios.get('/web/bill/checkPromisePayTime', { params });
  },
  //核心数据统计(优企通融资总额统计)
  FinaceCensus: function (data) {
    return _axios.post('web/enterprise/statistics/finance/census', data);
  },
  //优企通核心清分结算
  /*****持有企业 ****/
  //待清分
  getPayableBillLiquidationHxLs: function (data) {
    return _axios.post('/bill/liquidation/getPayableBillLiquidationHxLs', data);
  },
  //已清分
  getReadyHxLs: function (data) {
    return _axios.post('/bill/liquidation/getReadyHxLs', data);
  },
  /*****资金机构 ****/
  //待清分
  getPayableBillLiquidationHxZj: function (data) {
    return _axios.post('/bill/liquidation/getPayableBillLiquidationHxZj', data);
  },
  //已清分
  getReadyHxZf: function (data) {
    return _axios.post('/bill/liquidation/getReadyHxZf', data);
  },
  //新的待清分
  getPayableBillLiquidationHxLsNew: function (data) {
    return _axios.post('/bill/liquidation/getUnclearedAndOverdue', data);
  },
  //新的已清分
  getReadyHxZfNew: function (data) {
    return _axios.post('/bill/liquidation/getHaveClarify', data);
  },

  //已审批清分详情
  getPayableBillLiquidationDetail: function (params) {
    return _axios.get('/bill/liquidation/getPayableBillLiquidationDetail', {
      params,
    });
  },
  // 待审批清分详情
  getPayableBillLiquidationApproveDetailZj: function (params) {
    return _axios.get(
      '/bill/liquidation/getPayableBillLiquidationApproveDetailZj',
      { params }
    );
  },

  //清分结算详情(付款页面)
  getPayableBillLiquidationDetailByPay: function (params) {
    return _axios.get(
      '/bill/liquidation/getPayableBillLiquidationDetailByPay',
      { params }
    );
  },
  // 付款指令确认列表
  paymentConfirmationList: function (data) {
    return _axios.post('/bill/liquidation/paymentConfirmationList', data);
  },
  // 确认付款
  confirmPayment: function (data) {
    return _axios.post('/bill/liquidation/confirmPayment', data);
  },
  // 上传清分付款凭证
  uploadVoucher: function (data) {
    return _axios.post('/bill/liquidation/file/uploadVouchers', data);
  },
  // 上传清分付款凭证
  commitPayableBillLiquidation: function (params) {
    return _axios.get('/bill/liquidation/commitPayableBillLiquidation', {
      params,
    });
  },
  // 废除优企通单子
  delete: function (params) {
    return _axios.get('/web/enterprise/manager/delete', { params });
  },
  // 导出对账单
  exportBillPay: function (data) {
    return _axios.post('/bill/liquidation/export', data, {
      responseType: 'blob',
    });
  },
  // 查询电子转账凭证
  selectVoucher: function (params) {
    return _axios.get('/bill/liquidation/selectVoucher', { params });
  },
  //逾期未清分
  //链属
  getOverdueUnliquidatedLs: function (data) {
    return _axios.post('/bill/liquidation/getOverdueUnliquidatedLs', data);
  },
  //持有企业

  getOverdueUnliquidatedHxLs: function (data) {
    return _axios.post('/bill/liquidation/getOverdueUnliquidatedHxLs', data);
  },
  //资金企业
  getOverdueUnliquidatedHxZf: function (data) {
    return _axios.post('/bill/liquidation/getOverdueUnliquidatedHxZf', data);
  },

  /*****链属 ****/
  //待清分
  getPayableBillLiquidationLs: function (data) {
    return _axios.post('/bill/liquidation/getPayableBillLiquidationLs', data);
  },
  //已清分
  getReadyLs: function (data) {
    return _axios.post('/bill/liquidation/getReadyLs', data);
  },
  //修改确认状态
  updateStatusConfirm: function (data) {
    return _axios.put('/bill/liquidation/updateStatusConfirm', data, {
      params: data,
    });
  },
  //修改挂起状态
  updateStatusHangup: function (data) {
    return _axios.put('/bill/liquidation/updateStatusHangup', data, {
      params: data,
    });
  },
  //上传凭证
  uploadVouchers: function (data) {
    return _axios.post('/bill/liquidation/uploadVouchers', data);
  },
  //清分审批列表
  //待审批
  getPayableBillLiquidationList: function (data) {
    return _axios.post('/bill/liquidation/getPayableBillLiquidationList', data);
  },
  //已审批
  getPayableBillLiquidationListApproved: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationListApproved',
      data
    );
  },
  //清分审批列表
  //待审批
  getPayableBillLiquidationListNew: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationListNew',
      data
    );
  },
  //已审批
  getPayableBillLiquidationListApprovedNew: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationListApprovedNew',
      data
    );
  },

  //清分审批提交
  commitAndApprove: function (data) {
    return _axios.post('/bill/liquidation/commitAndApprove', data);
  },
  // 担保企业--优企通清分结算(持有企业)
  getGuaranteeOverdueLs: function (data) {
    return _axios.post('/bill/liquidation/getGuaranteeOverdueLs', data);
  },
  // 担保企业--优企通清分结算(资金机构)
  getGuaranteeOverdueZj: function (data) {
    return _axios.post('/bill/liquidation/getGuaranteeOverdueZj', data);
  },
  /***********************************资方机构 接口************************************ */
  //资金方授信申请列表
  getFundApplyListByPage: function (data) {
    return _axios.post('/web/fund/getFundApplyListByPage', data);
  },
  //新增编辑授信
  addGrantCredit: function (data, params) {
    return _axios.post('/web/fund/addGrantCredit', data, { params: params });
  },
  //提交授信 /web/fund/submitGrantCredit
  submitGrantCredit: function (data, params) {
    return _axios.post('/web/fund/submitGrantCredit', data, { params: params });
  },
  //查看授信详情
  getGrantCreditDetail: function (params) {
    return _axios.get('/web/fund/getGrantCreditDetail', { params: params });
  },
  //  授信复核待审核/已审核列表 /web/fund/submitFundGrantCreditReviewApprove
  getFundApplyListByPage: function (data, params) {
    return _axios.post('/web/fund/getFundApplyListByPage', data, {
      params: params,
    });
  },
  //  授信复核 审核接口
  submitFundGrantCreditReviewApprove: function (data, params) {
    return _axios.post('/web/fund/submitFundGrantCreditReviewApprove', data, {
      params: params,
    });
  },
  //  授信利率调整
  updateFinanceInterest: function (data) {
    return _axios.post('/financeInterestUpdate/updateFinanceInterest', data);
  },
  //获取调整记录
  getFinanceInterestUpdateList: function (params) {
    return _axios.get('/financeInterestUpdate/getFinanceInterestUpdateList', {
      params: params,
    });
  },
  //收款账户
  getCorpAccountPublicListByCorpId: function (params) {
    return _axios.get('/web/account/public/getCorpAccountPublicListByCorpId', {
      params: params,
    });
  },
  //开单协议数据填充
  getSignAgreement: function (params) {
    return _axios.get('/web/bill/getSignAgreement', { params: params });
  },
  //开单协议数据填充
  getSignAgreementByCorp: function (params) {
    return _axios.get('/web/bill/getSignAgreementByCorp', { params: params });
  },
  // /web/approve/getFundSideFinalReviewApproveList
  //资金方终审审核列表
  GetFundSideFinalReviewApproveList: function (data, params) {
    return _axios.post('/web/approve/getFundSideFinalReviewApproveList', data, {
      params: params,
    });
  },
  // /web/approve/getFundSideFinalReviewApproveResultList
  //资金方终审已审核列表
  GetFundSideFinalReviewApproveResultList: function (data, params) {
    return _axios.post(
      '/web/approve/getFundSideFinalReviewApproveResultList',
      data,
      { params: params }
    );
  },

  // /web/approve/getFundSideFirstApproveList
  //资金方初审审核列表
  GetFundSideFirstApproveList: function (data, params) {
    return _axios.post('/web/approve/getFundSideFirstApproveList', data, {
      params: params,
    });
  },

  // /web/approve/getFundSideFirstApproveResultList
  //资金方初审已审核列表
  GetFundSideFirstApproveResultList: function (data, params) {
    return _axios.post('/web/approve/getFundSideFirstApproveResultList', data, {
      params: params,
    });
  },

  // /web/approve/getFundSideReviewApproveList
  //资金方复审审核列表
  GetFundSideReviewApproveList: function (data, params) {
    return _axios.post('/web/approve/getFundSideReviewApproveList', data, {
      params: params,
    });
  },
  // /web/approve/getFundSideReviewApproveResultList
  //资金方复审已审核列表
  GetFundSideReviewApproveResultList: function (data, params) {
    return _axios.post(
      '/web/approve/getFundSideReviewApproveResultList',
      data,
      { params: params }
    );
  },

  //融资审批
  GetFundFinanceApproveList: function (params) {
    return _axios.get('/web/finance/fund/getFundFinanceApproveList', {
      params: params,
    });
  },

  // 清分结算待清分列表查询（资方企业)
  GetPayableBillLiquidationZf: function (data, params) {
    return _axios.post('/bill/liquidation/getPayableBillLiquidationZf', data, {
      params: params,
    });
  },
  // /bill/liquidation/getReadyZf  清分结算已清分列表查询（资方企业）
  GetReadyZf: function (data, params) {
    return _axios.post('/bill/liquidation/getReadyZf', data, {
      params: params,
    });
  },
  // /bill/liquidation/getOverdueUnliquidatedZf 清分结算逾期未清分列表查询(资方企业)
  GetOverdueUnliquidatedZf: function (data, params) {
    return _axios.post('/bill/liquidation/getOverdueUnliquidatedZf', data, {
      params: params,
    });
  },
  //清分查询
  //应付
  getClearingQueryList: function (data, params) {
    return _axios.post('/bill/liquidation/getClearingQueryList', data, {
      params: params,
    });
  },
  //应收
  getReceivableList: function (data, params) {
    return _axios.post('/bill/liquidation/getReceivableList', data, {
      params: params,
    });
  },

  //资方查看凭证
  GetUploadVouchersZf: function (params) {
    return _axios.get('/bill/liquidation/getUploadVouchersZf', {
      params: params,
    });
  },

  // 链属查看凭证
  GetUploadVouchersLs: function (params) {
    return _axios.get('/bill/liquidation/getUploadVouchersLs', {
      params: params,
    });
  },
  // 直接清分，链属和资方各3个列表的详情
  getPayableBillLiquidationDetails: function (params) {
    return _axios.get('/bill/liquidation/getPayableBillLiquidationDetails', {
      params: params,
    });
  },

  // admin/user/getSysUserListPage

  //用户列表 列表分页查询
  GetSysUserListPage: function (data) {
    return _axios.post('admin/user/getSysUserListPage', data);
  },
  //新增用户
  AddSysUser: function (data) {
    return _axios.post('admin/user/addSysUser', data);
  },
  //用户更新密码
  UpdatePassword: function (data) {
    return _axios.post('admin/user/updatePassword', data);
  },
  //修改密码
  modifyPassword: function (data) {
    return _axios.post('login/updatePassword', data);
  },
  //启用 禁用 用户
  StartSysUser: function (data) {
    return _axios.get('/admin/user/startSysUser', data);
  },
  //根据企业类型获取角色CODE
  GetSysRole: function (data, params) {
    return _axios.post('/admin/user/getSysRole', data, { params: params });
  },
  //编辑 用户
  EditSysUser: function (data, params) {
    return _axios.post('admin/user/editSysUser', data, { params: params });
  },

  // 应收账款转让通知书
  queryCirculationNotice: function (data) {
    return _axios.post('/web/finance/queryCirculationNotice', data);
  },
  // 企业管理-企业信息-企业详情
  getCorpTrialDetail: function (data) {
    return _axios.get('/admin/corp/getCorpTrialDetail', { params: data });
  },
  // 企业管理-企业信息-企业认证信息
  getTrialDetail: function (data) {
    return _axios.get('/admin/corp/getTrialDetail', { params: data });
  },

  // 对账单管理
  // 对账单列表(链属企业/核心企业)
  getCheckAccountListPage: function (params) {
    return _axios.get('/web/check/account/getCheckAccountListPage', {
      params: params,
    });
  },
  // 对账单列表(资金企业)
  getFundCheckAccountListPage: function (params) {
    return _axios.get('/web/check/account/getFundCheckAccountListPage', {
      params: params,
    });
  },
  // 对账单详情
  getCheckAccountDetailListPage: function (params) {
    return _axios.get('/web/check/account/getCheckAccountDetailListPage', {
      params: params,
    });
  },
  // 新增错误反馈信息
  addFeedback: function (data, params) {
    return _axios.post('/web/check/account/addFeedback', data, {
      params: params,
    });
  },
  // 查询错误反馈信息
  getFeedback: function (params) {
    return _axios.get('/web/check/account/getFeedback', { params: params });
  },
  // 确认对账单
  checkAccountAck: function (data, params) {
    return _axios.put('/web/check/account/checkAccountAck', data, {
      params: params,
    });
  },
  // 导出对账单
  downloadAccount: function (params) {
    return _axios.get('/web/check/account/downloadAccount', {
      params: params,
      responseType: 'blob',
    });
  },

  /******************************企业端收费协议管理*********************** */
  // 收费协议管理列表
  getFeeAgreementManagementListPage: function (params) {
    return _axios.get('/web/fee/agreement/getFeeAgreementManagementListPage', {
      params: params,
    });
  },
  // 查询申请开票上方回显信息
  ApplyInvoice: function (params) {
    return _axios.get('/web/fee/agreement/applyInvoice', { params: params });
  },
  // 申请开票
  ackApplyInvoice: function (data, params) {
    return _axios.put('/web/fee/agreement/ackApplyInvoice', data, {
      params: params,
    });
  },
  // 查询录入发票信息
  getEnterInvoice: function (params) {
    return _axios.get('/web/fee/agreement/getEnterInvoice', { params: params });
  },
  // 下载收费协议
  downloadFeeAgreement: function (params) {
    return _axios.get('/web/fee/agreement/downloadFeeAgreement', {
      params: params,
    });
  },

  /******************************企业端邮寄地址管理*********************** */
  // 地址管理  列表查询
  getCorpAddressList: function (data, params) {
    return _axios.post('/web/address/getCorpAddressList', data, {
      params: params,
    });
  },
  // 地址管理  添加/修改
  addCorpAddress: function (data, params) {
    return _axios.post('/web/address/addCorpAddress', data, { params: params });
  },
  // 查询录入快递信息
  getEnterExpressInfo: function (params) {
    return _axios.get('/web/fee/agreement/getEnterExpressInfo', {
      params: params,
    });
  },

  /******************************易保理业务*********************** */
  // 融资申请  新增
  ReceivableAccountFinanceAdd: function (data, params) {
    return _axios.post('/receivableAccountFinance/add', data, {
      params: params,
    });
  },
  // 融资申请  新增
  ReceivableAccountFinanceupdate: function (data, params) {
    return _axios.put('/receivableAccountFinance/update', data, {
      params: params,
    });
  },
  // /receivableAccountFinance/getRelationReceivedAccountsById  根据债权方企业ID查询应收账款
  GetRelationReceivedAccountsById: function (data, params) {
    return _axios.post(
      '/receivableAccountFinance/getRelationReceivedAccountsById',
      data,
      { params: params }
    );
  },
  // /web/account/approve/getFirstTrialList 应收账款内部待审核列表
  ApproveGetFirstTrialList: function (data, params) {
    return _axios.post('/web/account/approve/getFirstTrialList', data, {
      params: params,
    });
  },
  //应收账款合同批量下载
  yszkdownLoadFile: function (data) {
    return _axios.get('/web/receivableAccount/downLoadFile', {
      params: data,
      responseType: 'blob',
    });
  },

  ApproveGetFirstTrialResultList: function (data, params) {
    return _axios.post('/web/account/approve/getFirstTrialResultList', data, {
      params: params,
    });
  },
  //待撮合
  ToBeCombined: function (data, params) {
    return _axios.post('/receivableAccountFinance/toBeCombined', data, {
      params: params,
    });
  },
  //待审批
  ToBeApprove: function (data, params) {
    return _axios.post('/receivableAccountFinance/toBeApprove', data, {
      params: params,
    });
  },
  //已审批
  ToBeApproved: function (data, params) {
    return _axios.post('/receivableAccountFinance/toBeApproved', data, {
      params: params,
    });
  },
  //已退回
  ToBeReturned: function (data, params) {
    return _axios.post('/receivableAccountFinance/toBeReturned', data, {
      params: params,
    });
  },
  ///receivableAccountFinance/financingProjectDetails //融资项目详情
  FinancingProjectDetails: function (data) {
    return _axios.get('/receivableAccountFinance/financingProjectDetails', {
      params: data,
    });
  },

  // /receivableAccountFinance/financingProjectDetailsStatus 融资项目详情(同意/退回)
  FinancingProjectDetailsStatus: function (data) {
    return _axios.get(
      '/receivableAccountFinance/financingProjectDetailsStatus',
      { params: data }
    );
  },
  // /receivableAccountFinance/toCombined
  ToCombined: function (data, parma) {
    return _axios.post('/receivableAccountFinance/toCombined', data, {
      params: parma,
    });
  },
  // /receivableAccountFinance/assetDetails 资产详情(退回可编辑)
  AssetDetails: function (data) {
    return _axios.get('/receivableAccountFinance/assetDetails', {
      params: data,
    });
  },

  // /receivableAccountFinance/projectVoid 项目作废
  ProjectVoid: function (data) {
    return _axios.get('/receivableAccountFinance/projectVoid', {
      params: data,
    });
  },

  /**********************资方 保理业务  ********************* */
  GetFinanceMatchListPage: function (data) {
    return _axios.get('/web/receivableAccountMatch/getFinanceMatchListPage', {
      params: data,
    });
  },
  // /admin/receivableAccountMatch/generateQuoteRecord
  GenerateQuoteRecord: function (data, parma) {
    return _axios.post(
      '/web/receivableAccountMatch/generateQuoteRecord',
      data,
      { params: parma }
    );
  },
  //应收账款债务人待审
  zkgetOuterFirstTrialList: function (data, params) {
    return _axios.post('/web/account/approve/getOuterFirstTrialList', data, {
      params: params,
    });
  },
  //应收账款债务人已审
  zkgetOuterFirstTrialResultList: function (data, params) {
    return _axios.post(
      '/web/account/approve/getOuterFirstTrialResultList',
      data,
      { params: params }
    );
  },

  // /receivableAccountFinance/getFinanceQuotedListPage 融资列表-已报价列表
  GetFinanceQuotedListPage: function (data, params) {
    return _axios.post(
      '/receivableAccountFinance/getFinanceQuotedListPage',
      data,
      { params: params }
    );
  },

  // /receivableAccountFinance/getFinanceQuotedDetail 融资列表-已报价详情
  GetFinanceQuotedDetail: function (data) {
    return _axios.get('/receivableAccountFinance/getFinanceQuotedDetail', {
      params: data,
    });
  },
  // /admin/receivableAccountMatch/getQuoteListPage //前台 资方已报价列表
  GetQuoteListPage: function (data) {
    return _axios.get('/web/receivableAccountMatch/getQuoteListPage', {
      params: data,
    });
  },

  // /receivableAccountFinance/submitQuoteResult 融资列表-提交选择报价的资金方
  SubmitQuoteResult: function (data, params) {
    return _axios.post('/receivableAccountFinance/submitQuoteResult', data, {
      params: params,
    });
  },

  ///receivableAccountSigning/getEnterpriseSignListPage 融资列表-融资企业签约列表
  GetEnterpriseSignListPage: function (data, params) {
    return _axios.post(
      '/receivableAccountSigning/getEnterpriseSignListPage',
      data,
      { params: params }
    );
  },
  // /receivableAccountSigning/getEmployerSignListPage 融资列表-资方签约列表
  GetEmployerSignListPage: function (data, params) {
    return _axios.post(
      '/receivableAccountSigning/getEmployerSignListPage',
      data,
      { params: params }
    );
  },

  // /receivableAccountSigning/getSignContract 保理融资项目 获取合同及合同信息
  FactoringGetSignContract: function (params) {
    return _axios.get('/receivableAccountSigning/getSignContract', {
      params: params,
    });
  },

  // /receivableAccountSigning/accountFinanceSign  保理融资签约 发起签约
  AccountFinanceSign: function (params) {
    return _axios.get('/receivableAccountSigning/accountFinanceSign', {
      params: params,
    });
  },

  ///receivableAccountSigning/getEnterpriseSignResultListPage 融资列表-融资企业已签约列表
  GetEnterpriseSignResultListPage: function (data, params) {
    return _axios.post(
      '/receivableAccountSigning/getEnterpriseSignResultListPage',
      data,
      { params: params }
    );
  },

  // /receivableAccountSigning/getEmployerSignListPage 融资列表-资方已签约列表
  GetEmployerSignResultListPage: function (data, params) {
    return _axios.post(
      '/receivableAccountSigning/getEmployerSignResultListPage',
      data,
      { params: params }
    );
  },
  //融资中
  getFinancingList: function (data, params) {
    return _axios.post('/web/financeManager/getFinancingList', data, {
      params: params,
    });
  },
  //融资拒绝
  getFinanceReturnList: function (data, params) {
    return _axios.post('/web/financeManager/getFinanceReturnList', data, {
      params: params,
    });
  },

  // /web/receivableAccountMatch/fundIsQuote  //前台 判断当前资方是否已经报价
  FundIsQuote: function (params) {
    return _axios.get('/web/receivableAccountMatch/fundIsQuote', {
      params: params,
    });
  },

  /**********************投资偏好设置  ********************* */
  // 查询企业列表
  GetCompanyList: function (params) {
    return _axios.get('/fundCorpIntention/getCorpByName', { params: params });
  },
  GetCpjList: function (params) {
    return _axios.get('/fundCorpIntention/getFundCorpByName', {
      params: params,
    });
  },

  // 新增投资偏好设置
  SubmitInvestSetting: function (data) {
    return _axios.post('/fundCorpIntention/addOrUpdate', data);
  },

  // 查询投资偏好设置
  InitInvestSetting: function (params) {
    return _axios.get('/fundCorpIntention/getFundCorpIntention', {
      params,
      params,
    });
  },

  /**********************融资资料设置  ********************* */
  // 查询资料列表GET
  GetFinanceInformationList: function () {
    return _axios.get('/web/fund/information/getFinanceInformationList');
  },

  // 新增，保存
  AddFinanceInformation: function (data) {
    return _axios.post('/web/fund/information/addFinanceInformation', data);
  },
  // /admin/area/getProvince
  getProvince: function (params) {
    return _axios.get('/admin/area/getProvince', { params, params });
  },

  // /admin/area/getProvince
  getCity: function (params) {
    return _axios.get('/admin/area/getCity', { params, params });
  },

  // /admin/area/getRegion
  getRegion: function (params) {
    return _axios.get('/admin/area/getRegion', { params, params });
  },

  //*******************************担保 */
  //担保方已开立列表
  guaranteeopenlist: function (data) {
    return _axios.post('/web/enterprise/manager/guarantee/open/list', data);
  },
  //担保方已作废列表
  guaranteevoidlist: function (data) {
    return _axios.post('/web/enterprise/manager/guarantee/delete/list', data);
  },
  //担保方待审核列表
  getGuaranteeTrialList: function (data) {
    return _axios.post('/web/approve/getGuaranteeTrialList', data);
  },
  //担保方已审核列表
  getGuaranteeTrialResultList: function (data) {
    return _axios.post('/web/approve/getGuaranteeTrialResultList', data);
  },
  //二维码生成移动端页面
  getzxl: function (params) {
    return _axios.get('/web/zxl', { params, params });
  },
  //二维码生成移动端页面(资金方查看)
  getFinanceBlockChainInfo: function (params) {
    return _axios.get('/web/zxl/getFinanceBlockChainInfo', { params, params });
  },
  //担保方已审核列表
  updateReverse: function (data) {
    return _axios.post('/web/enterprise/manager/updateReverse', data);
  },

  /*******************************工作台 */
  // 融资概况
  financingSummary: function (params) {
    return _axios.get('/customer/corp/manage/financingSummary', {
      params,
      params,
    });
  },
  todoItem: function (params) {
    return _axios.get('/customer/corp/manage/todoItem', { params, params });
  },
  enterpriseTodoItem: function (params) {
    return _axios.get('/customer/corp/manage/enterpriseTodoItem', {
      params,
      params,
    });
  },
  fundTodoItem: function (params) {
    return _axios.get('/customer/corp/manage/fundTodoItem', { params, params });
  },
  //担保方反向开立列表
  getApplyList: function (data) {
    return _axios.post('/web/approve/getApplyList', data);
  },
  //反向开立退回列表
  //担保方反向开立列表
  getApplyReturnList: function (data) {
    return _axios.post('/web/approve/getApplyReturnList', data);
  },

  /**********************融资资料配置  ********************* */
  // 初始化查询
  GetFinanceInformationList: function () {
    return _axios.get('/web/fund/information/getFinanceInformationList');
  },

  // 新增，修改
  AddFinanceInformation: function (data) {
    return _axios.post('/web/fund/information/addFinanceInformation', data);
  },

  // 删除
  DeleteFinanceInformation: function (params) {
    return _axios.delete('/web/fund/information/deleteFinanceInformation', {
      params,
      params,
    });
  },

  // /web/financeSign/getPayProof
  GetPayProof: function (params) {
    return _axios.get('/web/financeSign/getPayProof', { params: params });
  },
  /*******************************站内信 */
  // 消息列表
  messageCenterMessage: function (data) {
    return _axios.post('/customer/corp/manage/messageCenterMessage', data);
  },
  // 消息总条数
  messageCenterMessageTotal: function (data) {
    return _axios.post('/customer/corp/manage/messageCenterMessageTotal', data);
  },
  // 置为已读
  setToRead: function (data) {
    return _axios.put('/customer/corp/manage/setToRead', data, {
      params: data,
    });
  },
  // 删除消息
  deleteMessage: function (data) {
    return _axios.put('/customer/corp/manage/deleteMessage', data, {
      params: data,
    });
  },
  /********************************** */
  // 邀请入驻
  inviteSettledIn: function (params) {
    return _axios.get('/admin/corp/invite', { params: params });
  },

  // /admin/getPhoneVerification
  GetPhoneVerification: function (data) {
    return _axios.post('/admin/getPhoneVerification', data);
  },

  /**********************票据**********************/
  // 发布票据
  AddBill: function (data) {
    return _axios.post('/billbase/addBill', data);
  },
  // 已审核
  GetFirstTrialResultList: function (data) {
    return _axios.post('/billbase/getFirstTrialResultList', data);
  },
  // 待审核
  GetFirstTrialList: function (data) {
    return _axios.post('/billbase/getFirstTrialList', data);
  },
  // 查看详情
  BillDetails: function (params) {
    return _axios.get('/billbase/detail', { params: params });
  },
  // 审批通过
  SubmitBillResult: function (data) {
    return _axios.post('/billbase/submitBillResult', data);
  },
  // 票据审批详情
  GetApproveListByBillId: function (params) {
    return _axios.get('/bill/base/approve/getApproveListByBillId', {
      params: params,
    });
  },
  // 票据合同发票补充详情
  supplementDetail: function (params) {
    return _axios.get('/billbase/supplementDetail', { params: params });
  },
  // 已退回票据重新提交
  UpdateBill: function (data) {
    return _axios.post('/billbase/updateBill', data);
  },
  // /web/financeSign/checkProjectCode
  CheckProjectCode: function (params) {
    return _axios.get('/web/financeSign/checkProjectCode', { params: params });
  },

  // /admin/corp/identify
  changeIdentify: function (params) {
    return _axios.get('/admin/corp/identify', { params: params });
  },
  // 个人认证手动通过
  getPersonalInformationAuth: function (params) {
    return _axios.get('/login/getPersonalInformationAuth', { params: params });
  },
  //*************************票据 */

  //票据中心
  billHall: function (data) {
    return _axios.post('/billbase/billHall', data);
  },
  //票据中心 摘牌
  billHallZj: function (data) {
    return _axios.post('/billbase/billHallZj', data);
  },

  //我的票据未发布 挂牌
  unpublishedList: function (data) {
    return _axios.post('/billbase/unpublishedList', data);
  },
  //我的票据待撮合 挂牌
  toDoList: function (data) {
    return _axios.post('/billbase/toDoList', data);
  },
  //我的票据已报价 挂牌
  quotedList: function (data) {
    return _axios.post('/billbase/quotedList', data);
  },

  ///
  // 票据偏好设置
  BillSettingAdd: function (data) {
    // 新增
    return _axios.post('/billintention/add', data);
  },
  BillSettingUpdate: function (data) {
    // 修改
    return _axios.put('/billintention/update', data);
  },
  InitBillSetting: function (params) {
    //查询
    return _axios.get('/billintention/getBillCorpIntention', {
      params: params,
    });
  },
  //我的票据 签约
  getBillSignListPage: function (params) {
    return _axios.get('/billsign/getBillSignListPage', { params: params });
  },
  //我的票据 签约详情
  billgetSignContract: function (params) {
    return _axios.get('/billsign/getSignContract', { params: params });
  },
  //我的票据 发起签约
  billfinanceSig: function (params) {
    return _axios.get('/billsign/financeSign', { params: params });
  },
  //我要报价
  fundSideQuote: function (data) {
    return _axios.post('/bill/base/quote/fundSideQuote', data);
  },
  //我的票据已退回列表
  getCorpReturnList: function (data) {
    return _axios.post('/billbase/getCorpReturnList', data);
  },
  //我的票据已退回列表（资方）
  getBillQuoteReturnList: function (data) {
    return _axios.post('/bill/base/quote/getBillQuoteReturnList', data);
  },

  /****************************保理转优企通***************************/
  // 融资转优企通额度申请
  financingTurnQuota: function (params) {
    return _axios.get('/receivableAccountFinance/financingTurnQuota', {
      params: params,
    });
  },
  // 融资转优企通额度内部待审核列表
  getFinancingTurnQuotaListPage: function (data) {
    return _axios.post(
      '/receivableAccountFinanceApprove/getFinancingTurnQuotaListPage',
      data
    );
  },
  // 融资转优企通额度内部已审核列表
  getFinancingTurnQuotaResultListPage: function (data) {
    return _axios.post(
      '/receivableAccountFinanceApprove/getFinancingTurnQuotaResultListPage',
      data
    );
  },
  // 融资转优企通额度提交审核结果
  submitReceivableAccountFinanceResult: function (data) {
    return _axios.post(
      '/receivableAccountFinanceApprove/submitReceivableAccountFinanceResult',
      data
    );
  },

  /****************************保理提现***************************/
  // 保理提现
  withdraw: function (data) {
    return _axios.put('/receivableAccountFinance/withdraw', data, {
      params: data,
    });
  },
  // 提现待审批
  withdrawPengingApprove: function (data) {
    return _axios.post(
      '/receivableAccountFinance/withdrawPengingApprove',
      data
    );
  },
  // 提现已审批
  withdrawPengedApprove: function (data) {
    return _axios.post('/receivableAccountFinance/withdrawPengedApprove', data);
  },
  // 提现审批
  withdrawApprove: function (data) {
    return _axios.post('/receivableAccountFinance/withdrawApprove', data);
  },
  // 已审批详情-获取审批结果
  approveDetail: function (params) {
    return _axios.get('/receivableAccountFinance/approveDetail', {
      params: params,
    });
  },
  // 资方待提现/已提现列表
  fundWithdrawPenging: function (data) {
    return _axios.post('/receivableAccountFinance/fundWithdrawPenging', data);
  },
  // 资方上传提现凭证
  uploadProof: function (data) {
    return _axios.post('/receivableAccountFinance/uploadProof', data);
  },
  //
  uploadProo: function (data) {
    return _axios.post('/web/financeSign/uploadProof', data);
  },
  // 查看提现凭证
  viewWithdrawalVoucher: function (params) {
    return _axios.get('/receivableAccountFinance/viewWithdrawalVoucher', {
      params: params,
    });
  },

  //获取平台服务费和杂费
  getProductFee: function (params) {
    return _axios.get('/admin/product/cost/getProductFee', { params: params });
  },
  //资方待审批列表
  pendingApproval: function (data) {
    return _axios.post('/bill/base/quote/pendingApproval', data);
  },
  //资方待审批
  quoteApprove: function (data) {
    return _axios.post('/bill/base/quote/quoteApprove', data);
  },
  //资方已审批
  pendingApprovaled: function (data) {
    return _axios.post('/bill/base/quote/pendingApprovaled', data);
  },

  //已报价详情里 查询报价列表
  getBillQuoteListByBillId: function (params) {
    return _axios.get('/billbase/getBillQuoteListByBillId', { params: params });
  },
  //接收资金方报价
  acceptFundQuote: function (data) {
    return _axios.post('/bill/base/quote/acceptFundQuote', data);
  },
  //报价审批详情
  billApproveDetail: function (data) {
    return _axios.post('/bill/base/quote/billApproveDetail', data);
  },

  // 活体认证拉取默认数据
  // getLivingCertificate(data) {
  //     return _axios.post("/api/livingCertificate/getParams", data);
  // },
  getLivingCertificate(data, corpId, userId, operType) {
    return _axios({
      url: `/api/livingCertificate/getParams/${corpId}/${userId}/${operType}`,
      method: 'post',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  //活体后台回调地址
  actionBackUrl() {
    return baseURL + '/api/livingCertificate/callBack2314';
  },
  //个人活体认证回调方法
  actioncuBackUrl() {
    return baseURL + '/api/livingCertificate/callBack2314forUser';
  },
  //活体认证前端返回页面路径
  actionFrontUrlUrl() {
    return baseURL + '/#/awaitResult';
  },
  //个人
  actionFrontUrlUrlcus() {
    return baseURL + '/#/awaitcuresult';
  },
  // 活体认证二维码认证地址
  livingQRCodeUrl(corpId, name, identificationNumber) {
    return baseQRCodeURL + '/#/livingCertification?corpId=' + corpId;
  },
  getFaceldentityURL({ userId, returnUrl }) {
    return _axios.post('/living-certificate/getFaceIdentityURL', { userId, returnUrl })
  },
  //个人
  livingQRCodeUrlcus(corpId, userId, name, identificationNumber) {
    return (
      baseQRCodeURL +
      '/#/livingCertificationcus?corpId=' +
      corpId +
      '&userId=' +
      userId
    );
  },
  getProductFeeFn: function (corpId) {
    return _axios.get(`/admin/corp/info/${corpId}`);
  },
  // 票据总金额统计业务总揽(挂牌企业)
  getListingBillCensus(params) {
    return _axios.get('/billbase/getListingBillCensus', { params: params });
  },
  // 企业端  :  /getListingBillCensus    /getListingBillCensusOverview    /getListingBillDealCensusOverview
  getListingBillCensusOverviewFn(params) {
    // 挂牌票据额度统计总揽
    return _axios.get('/billbase/getListingBillCensusOverview', {
      params: params,
    });
  },
  getListingBillDealCensusOverviewFn(params) {
    // 被摘牌票据额度统计总揽
    return _axios.get('/billbase/getListingBillDealCensusOverview', {
      params: params,
    });
  },

  // 票据总金额统计业务总揽(资金端)
  getDelistingBillCensus(params) {
    return _axios.get('/billbase/getDelistingBillCensus', { params: params });
  },
  // 企业端  :  /getListingBillCensus    /getListingBillCensusOverview    /getListingBillDealCensusOverview
  getDelistingBillCensusDealOverview(params) {
    // 挂牌票据额度统计总揽
    return _axios.get('/billbase/getDelistingBillCensusDealOverview', {
      params: params,
    });
  },
  getDelistingQuoteBillCensusOverview(params) {
    // 被摘牌票据额度统计总揽
    return _axios.get('/bill/base/quote/getDelistingQuoteBillCensusOverview', {
      params: params,
    });
  },

  //资方退回详情
  getFundQuoteById: function (data) {
    return _axios.post('/bill/base/quote/getFundQuoteById', data);
  },
  //回显金额
  echoAmount: function (data) {
    return _axios.post('/bill/base/quote/echoAmount', data);
  },
  //根据票据ID查询报价列表统计信息
  getBillQuoteCensusByBillId: function (params) {
    return _axios.get('/billbase/getBillQuoteCensusByBillId', {
      params: params,
    });
  },

  //已报价列表  核心
  quotedListCorp: function (data) {
    return _axios.post('/billbase/quotedListCorp', data);
  },
  //资方已结束列表

  getBillQuoteOverdueList: function (data) {
    return _axios.post('/bill/base/quote/getBillQuoteOverdueList', data);
  },
  //手动报价结束
  quoteApproveManualEnd: function (params) {
    return _axios.get('/bill/base/quote/quoteApproveManualEnd', {
      params: params,
    });
  },
  // 报价待审批 资方待审批详情
  pricebillDetail: function (data) {
    return _axios.post('/bill/base/quote/billDetail', data);
  },
  //效验是否下架
  quoteEndCheck: function (params) {
    return _axios.get('/bill/base/quote/quoteEndCheck', { params: params });
  },
  // 报价待审批 资方待审批详情
  savePersonalInformationAuth: function (data) {
    return _axios.post('/login/savePersonalInformationAuth', data);
  },
  //abs 待接收/已接收列表  企业端
  getEnterpriseAbsPlanList: function (data) {
    return _axios.post('/web/abs/getEnterpriseAbsPlanList', data);
  },
  //abs 待接收/已接收列表  资金端
  getFundAbsPlanList: function (data) {
    return _axios.post('/web/abs/getFundAbsPlanList', data);
  },
  //abs 置换列表
  getReplacementAbsPlanList: function (data) {
    return _axios.post('/web/abs/getReplacementAbsPlanList', data);
  },
  //abs 置换详情
  getReplacementDetail: function (params) {
    return _axios.get('/web/abs/getReplacementDetail', { params: params });
  },
  //abs 查询未绑定应收账款
  getUnboundAbsAccountList: function (data) {
    return _axios.post('/web/abs/getUnboundAbsAccountList', data);
  },

  //abs 计划详情
  getEnterprisePlanDetail: function (params) {
    return _axios.get('/web/abs/getEnterprisePlanDetail', { params: params });
  },
  //abs 计划提交
  receiveEnterpriseResult: function (data) {
    return _axios.post('/web/abs/receiveEnterpriseResult', data);
  },
  //abs  发起集资
  initiateFundraising: function (params) {
    return _axios.get('/web/abs/initiateFundraising', { params: params });
  },
  //abs 资产汇集
  getPlanAccountListPage: function (params) {
    return _axios.get('/web/abs/getPlanAccountListPage', { params: params });
  },
  //abs 提交打包审核
  submitAccountApply: function (data) {
    return _axios.post('/web/abs/submitAccountApply', data);
  },
  //abs 查看资产详情
  getAccountListByCorp: function (params) {
    return _axios.get('/web/abs/getAccountListByCorp', { params: params });
  },
  //abs 资产审核-查询资产包列表
  getAccountListByCorpOverride: function (params) {
    return _axios.get('/web/abs/getAccountListByCorpOverride', {
      params: params,
    });
  },
  // 账款/资产包（已审核）
  approvedDetail: function (data) {
    return _axios.post('/admin/abs/approvedDetail', data);
  },

  // abs账款管理列表
  getAbsAccountListPage: function (params) {
    return _axios.get('/web/abs/account/getAbsAccountListPage', {
      params: params,
    });
  },
  // 新增账款
  addAccount: function (data) {
    return _axios.post('/web/abs/account/addAccount', data);
  },
  // 选择账款债权/债务方
  getCoreCorpListExcludeSelf: function (params) {
    return _axios.get('/admin/corp/getCoreCorpListExcludeSelf', {
      params: params,
    });
  },
  // 查看abs账款详情
  getAccountInfo: function (params) {
    return _axios.get('/web/abs/account/getAccountInfo', { params: params });
  },
  // 查看abs退回账款详情
  getReturnAccountInfo: function (params) {
    return _axios.get('/web/abs/account/getReturnAccountInfo', {
      params: params,
    });
  },
  // abs账款一键下载
  absDownLoadFile: function (data) {
    return _axios.get('/web/abs/account/file/downLoadFile', {
      params: data,
      responseType: 'blob',
    });
  },
  // abs账款审批列表
  getAccountCheckListPage: function (params) {
    return _axios.get('/web/abs/account/getAccountCheckListPage', {
      params: params,
    });
  },
  // abs账款审核
  accountCheck: function (data) {
    return _axios.post('/web/abs/account/accountCheck', data);
  },
  // abs编辑账款
  updateAccount: function (data) {
    return _axios.put('/web/abs/account/updateAccount', data, { params: data });
  },
  // abs账款作废
  cancelAccount: function (data) {
    return _axios.put('/web/abs/account/cancelAccount', data, { params: data });
  },
  //  abs保理商-资产待审批列表
  assetPendingListWeb: function (data) {
    return _axios.post('/admin/abs/assetPendingListWeb', data);
  },
  //  abs保理商-资产已审批列表
  assetPendedListWeb: function (data) {
    return _axios.post('/admin/abs/assetPendedListWeb', data);
  },
  // abs资产审查-计划详情
  getAbsPlanDetail: function (params) {
    return _axios.get('/admin/abs/getAbsPlanDetail', { params: params });
  },
  // abs资产审查-账款列表
  packagedAssets: function (data) {
    return _axios.post('/admin/abs/packagedAssets', data);
  },
  // abs账款审核
  assetsApprove: function (data) {
    return _axios.post('/admin/abs/assetsApprove', data);
  },
  // abs资产审核
  absProjectApprove: function (data) {
    return _axios.post('/admin/abs/absProjectApprove', data);
  },
  // 校验是否签约
  checkSigning: function (params) {
    return _axios.get('/admin/abs/checkSigning', { params: params });
  },
  // 修改计划已结束
  updatePlanOver: function (params) {
    return _axios.get('/admin/abs/updatePlanOver', { params: params });
  },
  // 账款取消锁定
  unlock: function (data) {
    return _axios.post('/admin/abs/unlock', data);
  },

  //abs 封包
  submitPacket: function (data) {
    return _axios.post('/web/abs/submitPacket', data);
  },
  //核心 资产详情
  getSubmittedDetail: function (params) {
    return _axios.get('/web/abs/getSubmittedDetail', { params: params });
  },
  //abs 待签约 已签约列表
  getAbsSignListPage: function (params) {
    return _axios.get('/web/abs/account/sign/getAbsSignListPage', {
      params: params,
    });
  },
  // abs 待签约 已签约详情
  getSignInfo: function (params) {
    return _axios.get('/web/abs/account/sign/getSignInfo', { params: params });
  },
  // abs 上传
  uploadAccountFile: function (data) {
    return _axios.post('/web/abs/account/sign/uploadAccountFile', data);
  },
  // abs 查看文件
  getUploadFile: function (params) {
    return _axios.get('/web/abs/account/sign/getUploadFile', {
      params: params,
    });
  },
  //abs 获取合同
  getAbsSignContract: function (params) {
    return _axios.get('/web/abs/account/sign/getAbsSignContract', {
      params: params,
    });
  },
  //abs 发起签约
  postabsSign: function (params) {
    return _axios.get('/web/abs/account/sign/absSign', { params: params });
  },
  //abs 置换 提交的资产
  submitReplacementAccount: function (data) {
    return _axios.post('/web/abs/submitReplacementAccount', data);
  },
  //abs 已结束
  endedList: function (data) {
    return _axios.post('/admin/abs/endedList', data);
  },
  //abs 已退回
  getReturnAbsPlanList: function (data) {
    return _axios.post('/web/abs/getReturnAbsPlanList', data);
  },
  //abs 已退回详情
  getReturnAbsPlanDetail: function (params) {
    return _axios.get('/web/abs/getReturnAbsPlanDetail', { params: params });
  },
  //abs 首页统计
  homeStatistics: function (params) {
    return _axios.get('/admin/abs/homeStatistics', { params: params });
  },
  // 放款凭证审核
  beConfirmation: function (data) {
    return _axios.post('/web/financeSign/beConfirmation', data);
  },
  // 放款凭证审核记录
  approveRecord: function (data) {
    return _axios.post('/web/finance/admin/approveRecord', data);
  },
  // 查询企业是否开户
  checkOpenAccount: function (params) {
    return _axios.get('/web/fundAccount/checkOpenAccount', { params: params });
  },
  // 企业开户
  getOpenAccountStatus: function (params) {
    return _axios.get('/web/fundAccount/getOpenAccountStatus', {
      params: params,
    });
  },
  // 企业资金账户管理
  fundAccount: function (params) {
    return _axios.get('/web/fundAccount', { params: params });
  },
  // 根据实际业务查询支持银行列表
  suppBank: function (params) {
    return _axios.get('/acs/suppBank/' + params);
  },
  // 查询全国省份列表
  province: function (params) {
    return _axios.get('/acs/province', { params: params });
  },
  // 查询某个省下面的全部城市信息
  city: function (params) {
    return _axios.get('/acs/city/' + params);
  },
  // 查询网点信息
  payBank: function (params) {
    return _axios.get('/acs/payBank', { params: params });
  },
  // 提交绑卡信息绑卡
  submitTiedCard: function (data) {
    return _axios.post('/web/fundAccount/submitTiedCard', data);
  },
  // 绑卡信息
  bindCardInfo: function (params) {
    return _axios.get('/web/fundAccount/bindCardInfo', { params: params });
  },
  // 提交校验金额
  submitAmount: function (data, params) {
    return _axios.post('/web/fundAccount/submitAmount', data, {
      params: params,
    });
  },
  // 充值（入金）申请
  inMoneyApply: function (data) {
    return _axios.post('/web/fundAccount/inMoneyApply', data);
  },
  // 提现（出金）申请
  outMoneyApply: function (data) {
    return _axios.post('/web/fundAccount/outMoneyApply', data);
  },
  // 充值/提现回调方法
  rechargeBackUrl() {
    return baseURL + '/web/fundAccount/ascReceiveNotice';
  },
  // 充值/提现前端返回页面路径
  rechargeFrontUrl() {
    return baseURL + '/#/Business/accountManagement';
  },
  // 资金账户流水信息（操作日志）
  cashFlow: function (params) {
    return _axios.get('/web/fundAccount/cashFlow', { params: params });
  },
  // 渠道账户管理-列表
  channelAccountManagement: function (data) {
    return _axios.post('/admin/corp/channelAccountManagement', data);
  },
  // 缴纳平台服务费
  serviceFeeBreakDown: function (params) {
    return _axios.get('/receivableAccountFinance/serviceFeeBreakDown', {
      params: params,
    });
  },
  // 确认支付平台服务费
  confirmPaymentServiceFee: function (data) {
    return _axios.post('/receivableAccountFinance/confirmPayment', data);
  },
  // 资金方融资项目管理查询
  getFundFinanceListByPage: function (data) {
    return _axios.post('/web/fund/getFundFinanceListByPage', data);
  },
  // 签约合同下载(资方融资签约管理页)
  fundDownLoadFile: function (params) {
    return _axios.get('/web/fund/downLoadFile', {
      params: params,
      responseType: 'blob',
    });
  },
  // 资金端融资概况
  fundFinancingSummary: function (params) {
    return _axios.get('/customer/corp/manage/fundFinancingSummary', {
      params: params,
    });
  },
  // 获取 融资服务协议内容
  getContractContent: function (params) {
    return _axios.get('/web/bill/getContractContent', { params: params });
  },
  // 账务管理-列表
  financialManagement: function (data) {
    return _axios.post('/admin/corp/financialManagement', data);
  },
  /***********************************逐级清分结算*********************************/
  // 逐级清分待清分列表查询(核心)
  // 应付传 openCorpId,应收传receivedCorpId
  getPayableBillLiquidationByStep: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationByStep',
      data
    );
  },
  // 逐级清分已清分列表查询(核心)
  getPayableBillLiquidationReadyByStep: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationReadyByStep',
      data
    );
  },
  // 逐级清分逾期未清分列表查询(核心)
  getOverdueUnliquidatedByStep: function (data) {
    return _axios.post('/bill/liquidation/getOverdueUnliquidatedByStep', data);
  },
  // 逐级清分正常清分详情
  getPayableBillLiquidationDetailByStep: function (params) {
    return _axios.get(
      '/bill/liquidation/getPayableBillLiquidationDetailByStep',
      { params: params }
    );
  },
  // 逐级清分逾期清分详情
  getOverdueUnliquidatedDetailByStep: function (params) {
    return _axios.get('/bill/liquidation/getOverdueUnliquidatedDetailByStep', {
      params: params,
    });
  },
  // 付款指令确认(逐级清分)
  paymentConfirmationByStep: function (params) {
    return _axios.get('/bill/liquidation/paymentConfirmationByStep', {
      params: params,
    });
  },
  // 逐级清分支付
  payByStep: function (data) {
    return _axios.post('/bill/liquidation/payByStep', data);
  },
  // 校验企业资金账户余额是否充足
  checkFundAccountBalance: function (params) {
    return _axios.get('/bill/liquidation/checkFundAccountBalance', {
      params: params,
    });
  },
  // 逐级清分待清分列表查询（资金机构）
  getPayableBillLiquidationByStepZj: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationByStepZj',
      data
    );
  },
  // 逐级清分已清分列表查询（资金机构）
  getLiquidationReadyByStepZj: function (data) {
    return _axios.post('/bill/liquidation/getLiquidationReadyByStepZj', data);
  },
  // 逐级清分逾期未清分列表查询（资金机构）
  getOverdueUnliquidatedByStepZj: function (data) {
    return _axios.post(
      '/bill/liquidation/getOverdueUnliquidatedByStepZj',
      data
    );
  },
  // 逐级清分待清分列表查询（链属企业）
  getPayableBillLiquidationByStepLs: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationByStepLs',
      data
    );
  },
  // 逐级清分已清分列表查询（链属企业）
  getPayableBillLiquidationReadyByStepLs: function (data) {
    return _axios.post(
      '/bill/liquidation/getPayableBillLiquidationReadyByStepLs',
      data
    );
  },
  // 逐级清分逾期未清分列表查询（链属企业）
  getOverdueUnliquidatedByStepLs: function (data) {
    return _axios.post(
      '/bill/liquidation/getOverdueUnliquidatedByStepLs',
      data
    );
  },
  // 逐级已清分列表查询(担保)
  getReadyByStep: function (data) {
    return _axios.post('/bill/liquidation/getReadyByStep', data);
  },

  /***********************************逐级清分审批*********************************/

  // 检查同步审批状态
  checkApproveStatus: function (params) {
    return _axios.get('/bill/liquidation/checkApproveStatus', {
      params: params,
    });
  },
  // 逐级清分结算详情(待审批)
  getPayableBillLiquidationDetailZj: function (params) {
    return _axios.get('/bill/liquidation/getPayableBillLiquidationDetailZj', {
      params: params,
    });
  },
  // 逐级清分结算详情(已审批)
  getPayableBillLiquidationDetailZj1: function (params) {
    return _axios.get('/bill/liquidation/getPayableBillLiquidationDetailZj1', {
      params: params,
    });
  },
  // 清分审批记录(审批通过有异议时)
  getPayableBillLiquidationApprove: function (params) {
    return _axios.get('/bill/liquidation/getPayableBillLiquidationApprove', {
      params: params,
    });
  },
  // 逐级清分-下载明细表
  exportByStep: function (data) {
    return _axios.post('/bill/liquidation/exportByStep', data, {
      responseType: 'blob',
    });
  },
  /*********************************邮寄地址管理*********************************/

  // 企业端地址添加/修改
  addCorpAddress: function (data) {
    return _axios.post('/web/address/addCorpAddress', data);
  },
  // 企业端地址管理列表查询
  getCorpAddressList: function (data) {
    return _axios.post('/web/address/getCorpAddressList', data);
  },
  // 删除企业端地址
  deleteCorpAddress: function (params) {
    return _axios.get('/web/address/deleteCorpAddress', { params: params });
  },
  // 取设置、消为默认地址
  setDefault: function (params) {
    return _axios.get('/web/address/setDefault', { params: params });
  },
  /****************************************从原factoring.js文件迁移过来的接口-开始*********************************************/

  // 易保理 融资列表-资方待补件列表
  getManageCompletedListPage: function (data) {
    return _axios.post(
      '/receivableAccountFinance/getManageCompletedListPage',
      data
    );
  },
  // 保理融资项目 获取确权合同及合同信息
  getRightSignContract: function (params) {
    return _axios.get('/receivableAccountFinance/getRightSignContract', {
      params: params,
    });
  },
  // 保理融资签约 发起签约 融资企业和资方
  signDetailSubmit: function (params) {
    return _axios.get('/receivableAccountFinance/accountFinanceSign', {
      params: params,
    });
  },
  // E-ABS 页面跳转到 线上签署文件的页面的时候， 这里需要做一个操作。 这里有一个获取详情的操作以及一个 点击按钮的操作 即点击按钮的操作
  //这里是获取合同信息以及详情页面的内容接口。
  // 获取ABS 通知和回执合同
  getAbsOnLineSignContract: function (params) {
    return _axios.get('/web/abs/account/sign/getAbsOnLineSignContract', {
      params: params,
    });
  },
  // ABS 通知回执发起线上签约
  absOnLineSign: function (params) {
    return _axios.get('/web/abs/account/sign/absOnLineSign', {
      params: params,
    });
  },
  //优企通授信管理  资金方授信额度管理列表
  getFundListByPage: function (data, params) {
    return _axios.post('/web/fund/getFundListByPage', data, { params: params });
  },
  // 资金方授信额度管理列表-该资金方下的授信企业
  getFundList: function (data, params) {
    return _axios.post('/web/fund/getFundList', data, { params: params });
  },
  // 资金方企业客户管理
  getFundEnterpriseListByPage: function (data, params) {
    return _axios.post('/web/fund/getFundEnterpriseListByPage', data, {
      params: params,
    });
  },
  /****************************************从原factoring.js文件迁移过来的接口-结束*********************************************/
  /****************************************上海银行开户-开始*********************************************/
  // 上海银行获取币种
  getShBankCurrencyList: function (params) {
    return _axios.get('/web/sh/getShBankCurrencyList', { params: params });
  },
  // 上海银行获取行业代码
  getShIndustryList: function (params) {
    return _axios.get('/web/sh/getShIndustryList', { params: params });
  },
  // 上海银行获取清算行
  getShBankList: function (params) {
    return _axios.get('/web/sh/getShBankList', { params: params });
  },
  // 上海银行获取国籍
  getShBankCountryList: function (params) {
    return _axios.get('/web/sh/getShBankCountryList', { params: params });
  },
  // 上海银行省市区选择
  getShAreaList: function (params) {
    return _axios.get('/web/sh/getShAreaList', { params: params });
  },
  // 上海银行发送短信-开户
  sendShSms: function (params) {
    return _axios.get('/web/sh/sendShSms', { params: params });
  },
  // 上海银行开户/变更操作员
  openAccount: function (data, params) {
    return _axios.post('/web/sh/openAccount', data, { params: params });
  },
  // 绑定账号修改申请(变更账号)
  accountUpdateApply: function (params) {
    return _axios.get('/web/sh/accountUpdateApply', { params: params });
  },
  // 绑定账号修改申请激活(变更账号激活)
  accountUpdateApplyActivation: function (params) {
    return _axios.get('/web/sh/accountUpdateApplyActivation', {
      params: params,
    });
  },
  // 开户激活
  openAccountSuccess: function (params) {
    return _axios.get('/web/sh/openAccountSuccess', { params: params });
  },
  // 未激活绑定账号修改申请
  unAccountUpdateApply: function (params) {
    return _axios.get('/web/sh/unAccountUpdateApply', { params: params });
  },
  // 操作员信息修改申请激活
  operatorInformationModificationSuccess: function (params) {
    return _axios.get('/web/sh/operatorInformationModificationSuccess', {
      params: params,
    });
  },
  // 上海银行发送短信
  sendSMSOperator: function (params) {
    return _axios.get('/web/sh/sendSMSOperator', { params: params });
  },
  // 上海银行-下载电子对账单
  rlectronicReceipt: function (params) {
    return _axios.get('/web/sh/rlectronicReceipt', { params: params });
  },
  // 上海银行-下载电子回单
  printReceipt: function (data, params) {
    return _axios.post('/web/sh/printReceipt', data, { params: params });
  },
  // 上海银行提现
  shWithdraw: function (data, params) {
    return _axios.post('/web/sh/shWithdraw', data, { params: params });
  },
  // 资金账户往来账户流水信息（上海银行-账户交易明细表/中金-交易记录）
  loadFlow: function (params) {
    return _axios.get('/web/fundAccount/loadFlow', { params: params });
  },
  // 申请中的绑卡信息
  applyCardInfo: function (params) {
    return _axios.get('/web/sh/applyCardInfo', { params: params });
  },
  // 开户提示
  openPrompt: function (params) {
    return _axios.get('/web/sh/openPrompt', { params: params });
  },
  // 开户提交数据返回详情
  openAccountResult: function (params) {
    return _axios.get('/web/sh/openAccountResult', { params: params });
  },
  //修改链属企业数据
  updateCorpWhite: function (data) {
    return _axios.post('/web/enterprise/white/editCorpWhite', data, {
      params: data,
    });
  },
  // 删除链属企业信息
  deleteCorpWhite: function (params) {
    return _axios.delete('/web/enterprise/white/deleteCorpWhite', { params });
  },

  /** 查询供应商列表 */
  getSupplierList(params){
    return _axios.post('/web/enterprise/amount/getScmCorpList', params)
  },
  /** 供应商额度 */
  addSupplierQuota(params){
    return _axios.post('/web/enterprise/amount/addCorpAmount', params)
  },
  /** 供应商额度 */
  updateSupplierQuota(params){
    return _axios.post('/web/enterprise/amount/updateCorpAmount', params)
  },
  /** 供应商额度列表 */
  getSupplierQuotaList(params){
    return _axios.post('/web/enterprise/amount/record/list', params)
  },
  /** 供应商额度审批列表 */
  getSupplierQuotaAuditList(params){
    return _axios.post('/web/enterprise/amount/getCoreCropAmountFirstTrialApproveList', params)
  },
  /** 供应商额度审核通过 */
  passSupplierQuota(params){
    return _axios.post('/web/enterprise/amount/approval', params)
  },
  /** 供应商额度审核退回 */
  returnSupplierQuota(params){
    return _axios.post('/web/enterprise/amount/fallback', params)
  },
  /** 获取开立额度 */
  getFinancingMountMaximum(params) {
    return _axios.post('/admin/credit/getFinancingMountMaximum', params)
  },

  /****************************************上海银行开户-结束*********************************************/

  /****************************************资讯公告*********************************************/
  getNoticeList: (params) => _axios.post('/admin/notice/getNoticeList', params),
  noticeDetail: (params) =>
    _axios.get('/admin/notice/getNoticeById', { params: params }),

  /**
   * 获取服务费账户信息
   */
  getServiceAmountAccountInfo(payableBillId) {
    return _axios.get(`/web/finance/return/AccountOpen/${payableBillId}`)
  }
};
