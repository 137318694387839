<template>
  <div>
    <!--<Step class="m-top" v-bind:step-value="stepValues"></Step>-->
    <div class="main-contentTop1"></div>
    <div class="main-TopContentBottom">
      <div class="steps">
        <el-steps :active="2" align-center>
          <el-step title="企业信息认证"></el-step>
          <el-step title="个人信息认证"></el-step>
          <el-step title="企业资料补充"></el-step>
          <el-step title="用户人脸识别"></el-step>
          <el-step title="注册申请成功"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="register_main">
      <el-form
        :model="registerForm"
        :rules="rules"
        ref="registerForm"
        label-position="right"
        label-width="160px"
        class="demo-ruleForm registerDemo login-container"
      >
        <div class="first_step">
          <h2>企业资料完善  <span style="font-size:12px">(仅支持JPG、PNG、PDF格式)</span></h2>
          <el-row>
            <el-col :span="10">
              <el-form-item label="法人代表证明书：" class="company1 required1">
                <a :href="href1" download class="download_template">下载模板</a>
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('legalFacade')"
                      :class="{imgClass:!isEmpty(legalFileUrl)}"
                    >
                      <el-upload
                        :file-list="fileList1"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="legalFileUrlSuccess"
                        :on-remove="IDLegalFileUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(legalFileUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                label="经办人授权文件："
                class="company1"
                :class="registerType == 2 ? 'upload-text-requird' : ''"
              >
                <a :href="href2" download class="download_template">下载模板</a>
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('authorization')"
                      :class="{imgClass:!isEmpty(agentFileUrl)}"
                    >
                      <el-upload
                        :file-list="fileList2"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="agentFileUrlSuccess"
                        :on-remove="IDAgentFileUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(agentFileUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="特殊行业经营许可证：" class="company1">
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('special')"
                      :class="{imgClass:!isEmpty(specialIndustryLicenseUrl)}"
                    >
                      <el-upload
                        :file-list="fileList3"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="specialIndustryLicenseUrlSuccess"
                        :on-remove="IDSpecialIndustryLicenseUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(specialIndustryLicenseUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="环保批文：" class="company1">
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('environmental')"
                      :class="{imgClass:!isEmpty(environmentalApprovalUrl)}"
                    >
                      <el-upload
                        :file-list="fileList4"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="environmentalApprovalUrlSuccess"
                        :on-remove="IDEnvironmentalApprovalUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(environmentalApprovalUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="近三年审计报告：" class="company1">
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('Three')"
                      :class="{imgClass:!isEmpty(auditReportForPastThreeYearsUrl)}"
                    >
                      <el-upload
                        :file-list="fileList5"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="auditReportForPastThreeYearsUrlSuccess"
                        :on-remove="IDAuditReportForPastThreeYearsUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(auditReportForPastThreeYearsUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="近一年财务报表：" class="company1">
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('oneYears')"
                      :class="{imgClass:!isEmpty(nearlyOneYearFinancialStatementUrl)}"
                    >
                      <el-upload
                        :file-list="fileList6"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="nearlyOneYearFinancialStatementUrlSuccess"
                        :on-remove="IDNearlyOneYearFinancialStatementUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(nearlyOneYearFinancialStatementUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="公司章程：" class="company1">
                <div class="certificates">
                  <div class="certificates_item">
                    <div
                      @click="getFileType('Constitution')"
                      :class="{imgClass:!isEmpty(companyPolicyUrl)}"
                    >
                      <el-upload
                        :file-list="fileList7"
                        :limit="1"
                        :action="imgUpload()"
                        list-type="picture-card"
                        :data="fileType"
                        :before-upload="beforeAvatarUpload"
                        :on-success="companyPolicyUrlSuccess"
                        :on-remove="IDCompanyPolicyUrljustRemove"
                        :headers="Authorization"
                      >
                        <i v-if="isEmpty(companyPolicyUrl)" class="el-icon-plus"></i>
                        <span v-else>
                          <i class="el-icon-loading"></i>
                          <span>上传中</span>
                        </span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item class="item-btn">
            <el-button
              type="primary"
              size="small"
              @click.native="back()"
              style="width: 160px;height: 40px;background: white;color: black;margin-right: 32px"
            >上一步</el-button>
            <el-button
              type="primary"
              size="small"
              @click.native="nextStep()"
              style="width: 160px;height: 40px;background: #2937A6;"
            >下一步</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import api from '../../api/api';
import Step from './Step';
import areaJson from '../../const/area.json';
import goverArea from '../../const/government.json';
import upload from '../../api/upload';
import _ from 'lodash';
import { baseURL } from '@/api/sys.js';
import pdfimg from '@/assets/zip.png';

export default {
  data() {
    var checkEnpsLicense = (rule, value, callback) => {
      let reg = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]{0,18}$/;
      if (value.length < 16) {
        if (value.length !== 15) {
          callback(new Error('营业执照注册号15位数字'));
        } else {
          callback();
        }
      }
      if (value.length >= 16) {
        if (reg.test(value) !== true && value.length !== 18) {
          callback(new Error('统一社会信用代码18位大写字母加数字!'));
        } else {
          callback();
        }
      }
    };
    var checkSupName = (rule, value, callback) => {
      if (_.isEmpty(this.supName)) {
        if (!value) {
          callback(new Error('请输入企业名!'));
        } else if (value.length > 50) {
          callback(new Error('企业名最多为50个汉字!'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      Authorization: { Authorization: sessionStorage.token },
      form: {
        agentFileUrl: '', //经办人授权文件url ,
        auditReportForPastThreeYearsUrl: '', //近三年审计报告
        companyPolicyUrl: '', //公司章程url ,
        corpId: '',
        environmentalApprovalUrl: '', //环保批文url ,
        legalFileUrl: '', //法人代表证明书url ,
        nearlyOneYearFinancialStatementUrl: '', //近一年财务报表
        specialIndustryLicenseUrl: '' //特殊行业经营许可证url
      },
      stepValues: 2,
      fileType: {
        type: '',
        registerCode: ''
      },
      registerForm: {
        name: '',
        enpsLicense: '',
        provinceAddress: '',
        cityAddress: '',
        legalAddress: '',
        controller: 1,
        fillPersion: 1
      },
      corpType: '',
      registerType: '', // 注册类型 1法人 2经办人
      provinceList: [],
      cityList: [],
      licenseUrl: '', // 营业执照
      legalFileUrl: '', // 法人代表证明书
      companyPolicyUrl: '', //公司章程
      nearlyOneYearFinancialStatementUrl: '', //近一年财务报表
      auditReportForPastThreeYearsUrl: '', //近三年审计报告
      environmentalApprovalUrl: '', //环保批文
      specialIndustryLicenseUrl: '', //特殊行业经营许可证
      agentFileUrl: '', //经办人授权文件
      IDReverseUrl: '', // 法人身份证反面
      agentJustUrl: '', // 代理人正面
      agentReverseUrl: '', // 代理人反面
      entrustUrl: '', // 委托代理书
      fileList1: [], //回显图片
      fileList2: [], //回显图片
      fileList3: [], //回显图片
      fileList4: [], //回显图片
      fileList5: [], //回显图片
      fileList6: [], //回显图片
      fileList7: [], //回显图片
      rules: {
        name: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        name1: [
          { required: true, message: '请输入经办人姓名', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        companyname: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入8位以上且必须为英文大小写字母加数字组合的密码',
            trigger: 'blur'
          },
          {
            min: 8,
            max: 8,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/,
            message: '请输入8位以上且必须为英文大小写字母加数字组合的密码',
            trigger: 'blur'
          }
        ],
        pass: [
          {
            required: true,
            message: '请输入8位以上且必须为英文大小写字母加数字组合的密码',
            trigger: 'blur'
          },
          {
            min: 8,
            max: 8,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/,
            message: '请输入8位以上且必须为英文大小写字母加数字组合的密码',
            trigger: 'blur'
          }
        ],
        agentPhone: [
          { required: true, message: '请输入正确的验证码', trigger: 'blur' },
          {
            min: 8,
            max: 8,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/,
            message: '请输入8位以上且必须为英文大小写字母加数字组合的密码',
            trigger: 'blur'
          }
        ]
      },
      href1: '/法人代表证明书模板.docx',
      href2: '/经办人授权文件模板.docx'
    };
  },
  components: {
    Step
  },
  mounted() {
    if (baseURL == 'http://106.53.104.245:8080') {
      this.href1 = '/yirong-web/法人代表证明书模板.docx';
      this.href2 = '/yirong-web/经办人授权文件模板.docx';
    }
    this.form.corpId = this.$route.query.corpId;
    this.getInfo();
  },
  computed: {
    supName() {
      return this.$store.getters.supName;
    }
  },
  methods: {
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    // 图片上传
    imgUpload() {
      return upload.eqptImgSrc();
    },
    getPersion(val) {
      if (val === 1) {
        this.agentJustUrl = '';
        this.agentReverseUrl = '';
        this.entrustUrl = '';
      }
    },
    getFileType(val) {
      this.fileType = {
        type: val,
        registerCode: sessionStorage.getItem('code')
      };
    },

    //回显数据
    getInfo() {
      console.log('this.corpId ====>', this.corpId);
      api.getCorpTrialDetail({ corpId: this.form.corpId }).then((res) => {
        let fileurl = 'customerprefect.vue';
        if (res.success) {
          res.datas.fileUrlByCorpList.forEach((el) => {
            if (/PDF|ZIP/gi.test(el.fileUrl)) {
              fileurl = pdfimg;
            } else {
              fileurl = api.getImgURL(el.fileUrl);
            }
            if (el.fileName == '法人代表证明书') {
              this.fileList1.push({
                name: el.fileName,
                url: fileurl
              });
              this.legalFileUrl = el.fileUrl;
              this.form.legalFileUrl = el.fileUrl;
            } else if (el.fileName == '经办人授权文件') {
              this.fileList2.push({
                name: el.fileName,
                url: fileurl
              });
              this.agentFileUrl = el.fileUrl;
              this.form.agentFileUrl = el.fileUrl;
            } else if (el.fileName == '特殊行业经营许可证') {
              this.fileList3.push({
                name: el.fileName,
                url: fileurl
              });
              this.specialIndustryLicenseUrl = el.fileUrl;
              this.form.specialIndustryLicenseUrl = el.fileUrl;
            } else if (el.fileName == '环保批文') {
              this.fileList4.push({
                name: el.fileName,
                url: fileurl
              });
              this.environmentalApprovalUrl = el.fileUrl;
              // this.form.agentInfoIdCardFrontFileUrl = el.fileUrl;
              this.form.environmentalApprovalUrl = el.fileUrl;
            } else if (el.fileName == '近三年审计报告') {
              this.fileList5.push({
                name: el.fileName,
                url: fileurl
              });
              this.auditReportForPastThreeYearsUrl = el.fileUrl;
              // this.form.agentInfoIdCardBackFileUrl = el.logoUrl;
              this.form.auditReportForPastThreeYearsUrl = el.fileUrl;
            } else if (el.fileName == '近一年财务报表') {
              this.fileList6.push({
                name: el.fileName,
                url: fileurl
              });
              this.nearlyOneYearFinancialStatementUrl = el.fileUrl;
              // this.form.agentInfoIdCardBackFileUrl = el.logoUrl;
              this.form.nearlyOneYearFinancialStatementUrl = el.fileUrl;
            } else if (el.fileName == '公司章程') {
              this.fileList7.push({
                name: el.fileName,
                url: fileurl
              });
              this.companyPolicyUrl = el.fileUrl;
              // this.form.agentInfoIdCardBackFileUrl = el.logoUrl;
              this.form.companyPolicyUrl = el.fileUrl;
            }
          });
          this.registerType = res.datas.corpDetailList[0].registerType;
        }
      });
    },

    //法人代表证明书
    legalFileUrlSuccess(res, file) {
      if (res.code == 100000) {
        if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList1[this.fileList1.length] = file;
        }
        this.legalFileUrl = res.datas.uploadDate.message;

        this.form.legalFileUrl = res.datas.uploadDate.message;
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //经办人授权文件
    agentFileUrlSuccess(res,file) {
      if (res.code == 100000) {
             if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList2[this.fileList2.length] = file;
        }
        this.form.agentFileUrl = res.datas.uploadDate.message;
        this.agentFileUrl = res.datas.uploadDate.message;
        console.log(this.form.agentFileUrl, 222222);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //特殊行业经营许可证
    specialIndustryLicenseUrlSuccess(res,file) {
      if (res.code == 100000) {
                  if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList3[this.fileList3.length] = file;
        }
        this.form.specialIndustryLicenseUrl = res.datas.uploadDate.message;
        this.specialIndustryLicenseUrl = res.datas.uploadDate.message;
        console.log(this.form.specialIndustryLicenseUrl, 333333);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //环保批文
    environmentalApprovalUrlSuccess(res,file) {
      if (res.code == 100000) {
                  if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList4[this.fileList4.length] = file;
        }
        this.form.environmentalApprovalUrl = res.datas.uploadDate.message;
        this.environmentalApprovalUrl = res.datas.uploadDate.message;
        console.log(this.form.environmentalApprovalUrl, 444444);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //近三年审计报告
    auditReportForPastThreeYearsUrlSuccess(res,file) {
      if (res.code == 100000) {
                  if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList5[this.fileList5.length] = file;
        }
        this.form.auditReportForPastThreeYearsUrl =
          res.datas.uploadDate.message;
        this.auditReportForPastThreeYearsUrl = res.datas.uploadDate.message;
        console.log(this.form.auditReportForPastThreeYearsUrl, 555555);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //近一年审计报告
    nearlyOneYearFinancialStatementUrlSuccess(res,file) {
      if (res.code == 100000) {
                  if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList6[this.fileList6.length] = file;
        }
        this.form.nearlyOneYearFinancialStatementUrl =
          res.datas.uploadDate.message;
        this.nearlyOneYearFinancialStatementUrl = res.datas.uploadDate.message;
        console.log(this.form.legalFileUrl, 66666);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },
    //公司章程
    companyPolicyUrlSuccess(res,file) {
      if (res.code == 100000) {
                  if (/PDF|ZIP/gi.test(res.datas.uploadDate.message)) {
          file.url = pdfimg;
          this.fileList7[this.fileList7.length] = file;
        }
        this.form.companyPolicyUrl = res.datas.uploadDate.message;
        this.companyPolicyUrl = res.datas.uploadDate.message;
        console.log(this.form.companyPolicyUrl, 777777);
        this.$message.success('上传成功');
      } else {
        this.$message.error(res.message);
      }
    },

    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.$message.success('上传成功');
        if (this.fileType.type === 'license') {
          this.licenseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === 'legalFacade') {
          this.IDjustUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === 'legalReverse') {
          this.IDReverseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === 'agentFacade') {
          this.agentJustUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === 'agentReverse') {
          this.agentReverseUrl = URL.createObjectURL(file.raw);
          return;
        }
        if (this.fileType.type === 'entrustTable') {
          this.entrustUrl = URL.createObjectURL(file.raw);
          return;
        }
      }
    },

    //删除图片
    IDLegalFileUrljustRemove() {
      this.legalFileUrl = '';
      this.form.legalFileUrl = '';
    },
    IDAgentFileUrljustRemove() {
      this.agentFileUrl = '';
      this.form.agentFileUrl = '';
    },
    IDSpecialIndustryLicenseUrljustRemove() {
      this.specialIndustryLicenseUrl = '';
      this.form.specialIndustryLicenseUrl = '';
    },
    IDEnvironmentalApprovalUrljustRemove() {
      this.environmentalApprovalUrl = '';
      this.form.environmentalApprovalUrl = '';
    },
    IDNearlyOneYearFinancialStatementUrljustRemove() {
      this.nearlyOneYearFinancialStatementUrl = '';
      this.form.nearlyOneYearFinancialStatementUrl = '';
    },
    IDAuditReportForPastThreeYearsUrljustRemove() {
      this.auditReportForPastThreeYearsUrl = '';
      this.form.auditReportForPastThreeYearsUrl = '';
    },
    IDCompanyPolicyUrljustRemove() {
      this.companyPolicyUrl = '';
      this.form.companyPolicyUrl = '';
    },
    beforeAvatarUpload(file) {
      let excelfileExtend = '.jpg,.png,.jpeg,.bmg,.pdf'; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error('您上传的文件格式有误，请重新上传');
        return false;
      }
      //上传前配置
      if (this.fileType.type === 'license') {
        this.licenseUrl = '1';
        return;
      }
      if (this.fileType.type === 'legalFacade') {
        this.IDjustUrl = '1';
        return;
      }
      if (this.fileType.type === 'legalReverse') {
        this.IDReverseUrl = '1';
        return;
      }
      if (this.fileType.type === 'agentFacade') {
        this.agentJustUrl = '1';
        return;
      }
      if (this.fileType.type === 'agentReverse') {
        this.agentReverseUrl = '1';
        return;
      }
      if (this.fileType.type === 'entrustTable') {
        this.entrustUrl = '1';
        return;
      }
    },
    nextStep(val) {
      if (_.isEmpty(this.legalFileUrl)) {
        this.$message({
          message: '请上传法人代表证明书',
          type: 'error'
        });
        return;
      }
      if (this.registerType == 2 && _.isEmpty(this.agentFileUrl)) {
        this.$message({
          message: '请上传经办人授权文件',
          type: 'error'
        });
        return;
      }
      // if (_.isEmpty(this.idCardFrontFileUrl)) {
      //   this.$message({
      //     message: "请上传经办人个人信息页",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardBackFileUrl)) {
      //     this.$message({
      //         message: "请上传经办人国徽页",
      //         type: "error"
      //     });
      //     return;
      // }
      // const initData = {
      //     IdentificationNumber:"",
      //     Name:"",
      //     idCardFrontFileUrl:"",//经办人国徽页
      //     idCardBackFileUrl:"",//经办人身份证正面
      //     corpId:this.corpId
      //     }

      let para = { ...this.form };
      api.detailAdd(para).then((res) => {
        if (res.code === '100000') {
          // this.$router.push({path : "/CompanyResult", query: {
          //   corpId: this.$route.query.corpId}
          // });
          this.$router.push({
            path: '/CompanyVideo',
            query: {
              corpId: this.$route.query.corpId,
              name: this.$route.query.name,
              identificationNumber: this.$route.query.identificationNumber
            }
          });
          // this.$router.push({ path: "/workbench" });
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          });
        }
      });
      // if (_.isEmpty(this.licenseUrl)) {
      //   this.$message({
      //     message: "请上传营业执照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardFrontFileUrl)) {
      //   this.$message({
      //     message: "请上法人身份证个人信息照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.idCardBackFileUrl)) {
      //   this.$message({
      //     message: "请上法人身份证国徽照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.agentJustUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传代理人个人信息照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.agentReverseUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传代理人国徽照",
      //     type: "error"
      //   });
      //   return;
      // }
      // if (_.isEmpty(this.entrustUrl) && this.registerForm.fillPersion == 2) {
      //   this.$message({
      //     message: "请上传委托书照片",
      //     type: "error"
      //   });
      //   return;
      // }
      // areaJson.RECORDS.forEach(item => {
      //   if (this.registerForm.provinceAddress == item.id) {
      //     this.registerForm.provinceAddress = item.name;
      //   }
      //   if (this.registerForm.cityAddress == item.id) {
      //     this.registerForm.cityAddress = item.name;
      //   }
      // });
      // console.log('registerForm==>',this.registerForm)
      // this.$refs.registerForm.validate(valid => {
      //   if (valid) {
      //     let para = {
      //       registerCode: sessionStorage.getItem("code"),
      //       registerUserInfoEntity: {
      //         name:
      //           _.isEmpty(this.supName) == false
      //             ? this.supName
      //             : this.registerForm.name,
      //         enterpriseType: sessionStorage.getItem("enterpriseType"),
      //         registerCode: sessionStorage.getItem("code"),
      //         enpsLicense: this.registerForm.enpsLicense,
      //         legalAddress:
      //           this.registerForm.provinceAddress +
      //           "" +
      //           this.registerForm.cityAddress,
      //         controller: this.registerForm.controller,
      //         fillPersion: this.registerForm.fillPersion
      //       }
      //     };
      //     CompanyBaseInfo(para).then(res => {
      //       if (res.code === 0) {
      //         this.$router.push("/register/perfect");
      //       } else {
      //         this.$message({
      //           message: res.message,
      //           type: "error"
      //         });
      //       }
      //     });
      //   }
      // });
      // this.$router.push("/CompanyConfirm");
    },
    back() {
      // this.$router.go(-1);//返回上一层
      this.$router.push({
        path: '/CompanyPerfect',
        query: {
          corpId: this.form.corpId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../styles/register/company.scss';
.el-form-item__content {
  line-height: 30px !important;
}
.register_main {
  padding-bottom: 20px;
}
.required:before {
  content: '*';
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}
.required1:before {
  content: '*';
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 0px;
}
.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss" >
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1 {
  height: 239px;
  /*background-color: red;*/
  background: url('../../assets/login/cusrz.jpg') center;
  background-size: cover;
}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top: 0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon {
    width: 32px;
    height: 32px;
  }
}
.download_template {
  padding: 6px 18px;
  margin-left: 10px;
  border: 1px solid #2937a6;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  background-color: #2937a6;
}
</style>
