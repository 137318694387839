<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="itemNo" label="优企通编号" min-width="220"></el-table-column>
         <el-table-column prop="checkAccountDateCn" label="时间" min-width="130">
            <template slot-scope="scope">
              {{getMonth(scope.row.checkAccountDateCn)}}
            </template>
          </el-table-column>
          <el-table-column prop="openCorpName" label="开立企业" min-width="170"></el-table-column>
          <el-table-column prop="receiveCorpName" label="接收企业" min-width="170"></el-table-column>
          <el-table-column
            prop="checkAccountAmount"
            label="优企通金额(元)"
            min-width="150"
            :formatter="el=>changeMoneyThousand(el.checkAccountAmount)"
          ></el-table-column>
          <el-table-column
            prop="promisePayTime"
            label="承诺付款日"
            min-width="120"
            :formatter="el=>moment(el.promisePayTime).format('YYYY-MM-DD')"
          ></el-table-column>
        </el-table>
        <!-- 下侧按钮 -->
        <div class="subButton">
            <el-button @click="backToUpPage">返回</el-button>
            <el-button type="primary" v-if="statementData.corpAckStatus!==2" @click="handelConfirm">确认无误</el-button>
        </div>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";

export default {
  props: [ 'statementData', 'updateStatementData'],
  data() {
    return {
      filterList: [],
      total: 0,
      listLoading: false,
      listData: [],
      moment: moment,
      changeMoneyThousand: changeMoneyThousand,
      // statementData: {}
    };
  },
  created() {
    // this.statementData = JSON.parse(sessionStorage.getItem('statementData'))
    // this.getLists()
  },
  methods: {
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    getLists(filters) {
      // this.listLoading = true;
      let param = Object.assign({
          checkAccountType: '3',
          payableBillCheckAccountId: this.statementData.payableBillCheckAccountId,
          accountCorpType: this.statementData.accountCorpType,
          }, filters)
      api.getCheckAccountDetailListPage(param).then(res => {
        console.log(res.datas.list, '----------res.datas.list1');
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          // this.listLoading = false;
        }
      });
    },
    handelConfirm() {
      let params = {
        payableBillCheckAccountId: this.statementData.payableBillCheckAccountId
      }
      api.checkAccountAck(params).then(res => {
        if (res.success) {
          console.log(res, '----------res')
          this.updateStatementData()
          this.$message.success('操作成功')
        }else {
          this.$message.error('操作失败')
        }
      })
    },
    backToUpPage(){
      // this.$router.go(-1);
      this.$router.push('/easypay/statementManage/1?pageSize=10&pageNum=1')
    },
    getMonth(month) {
      return month.slice(0, 7)
    },
    // updateStatementData() {
    //   api.getCheckAccountListPage().then(res => {
    //     if (res.success) {
    //       let temp = res.datas.list.filter(el => {
    //         if(el.payableBillCheckAccountId === this.statementData.payableBillCheckAccountId) {
    //           return el
    //         }
    //       })
    //       this.statementData.corpAckStatus = temp[0].corpAckStatus
    //       console.log(this.statementData, '---------更新this.statementData')
    //     }
    //   })
    // }
  }
};
</script>

<style lang="scss">
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.el-tabs__content {
  margin-top: 30px;
}
.subButton {
    text-align: center;
    // position: absolute;
    // top: 25px;
    // right: 16px;
    .el-button {
        margin: 20px 50px 0;
        width: 100px;
    }
}
</style>
