<template>
  <div class="content">
    <div class="card-hd infotitle">
      清分详情
      <!-- <el-button class="page-head-back" size="small" @click="goBack">返回</el-button> -->
    </div>

    <section class="info-card-A">
      <div class="card-hd sub-title">
        <span class="txbst">基本信息</span>
        <el-button
          type="text"
          class="page-head-back"
          style="color: #2937A6;"
          size="small" @click="previewEasypay"
        >优企通编号:{{financePayableBillNo}}预览</el-button>
      </div>
      <div class="card-bd card-bd-row">
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">开单企业:</span>
            <span class="info-value">{{originalName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">原始优企通额度:</span>
            <span class="info-value">{{$changeMoneyThousand(financInfo.originalAmount)}}元人民币</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">承诺付款日:</span>
            <span
              class="info-value"
            >{{moment(financInfo.promisePayTime||null).format('YYYY-MM-DD')}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">已融资金额:</span>
            <span class="info-value">{{$changeMoneyThousand(financInfo.payableBillFinanceAmount)}}元人民币</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">需清分金额:</span>
            <span class="info-value">{{$changeMoneyThousand(financInfo.payableBillClearAmount)}}元人民币</span>
          </el-col>
        </el-row>
        <el-row v-if="isOverdue == 1">
    
          <el-col :span="8" class="lb_body">
            <span class="info-key">已逾期天数:</span>
            <span class="info-value">{{financInfo.overdueDays}}</span>
          </el-col>

        </el-row>
        <div class="card-line"></div>
      </div>
      <div v-if="financInfo.uploadVouchersZf">
        <div class="card-hd sub-title">
          <span class="txbst">清分凭证</span>
        </div>
        <div style="margin-top:20px;">
          <img
            :src="api.getImgURL(financInfo.uploadVouchersZf)"
            alt="凭证图片丢失"
            width="19%"
            height="144px"
            preview-text="合同"
            :preview="1"
          />
        </div>
      </div>

      <div class="card-footer">
        <el-row>
          <el-col :span="8" class="lb_body" :offset="8">
            <el-button
              style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;"
              size="small"
              @click="goback"
            >返回</el-button>
          </el-col>
        </el-row>
      </div>
    </section>
    <!-- 弹窗 -->
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import pdf from "vue-pdf";
import api from "@/api/api";
import { baseURL } from "@/api/sys";

export default {
  components: {
    pdf
  },
  data() {
    return {
      moment: moment,
      financInfo: {},
      datalist: [],
      Authorization: { Authorization: sessionStorage.token },
      baseURL: baseURL,
      api: api,
      originalName: "",
      centerDialogVisible: false,
      src: "",
      isOverdue: this.$route.query.isOverdue,  // 1 逾期
      isshowbtn: this.$route.query.isshowbtn,  // 1未清分 2已清分
      payableBillNo: this.$route.query.payableBillNo,
      payableBillFinanceId: this.$route.query.payableBillFinanceId,
      financePayableBillNo: this.$route.query.financePayableBillNo  // 优企通编号
    };
  },
  created() {
    this.getDetail();
    this.getCompanyName({ payableBillNo: this.payableBillNo });
  },
  mounted() {},
  methods: {
    getDetail() {
      //  corpType  1链属2资方  
      // typeStr1正常清分/已清分 2逾期清分 
      let param = {
        payableBillNo: this.payableBillNo,
        payableBillFinanceId: this.payableBillFinanceId,
        corpType: 2,
        typeStr: this.isshowbtn == 1 && this.isOverdue == 1 ? 2 : 1
      }
      api.getPayableBillLiquidationDetails(param).then(res => {
        if (res.code == "100000") {
          this.financInfo = res.datas.single;
          this.datalist = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCompanyName(id) {
      api.GetUploadVouchersZf(id).then(res => {
        if (res.code == "100000") {
          this.originalName = res.datas.result.originalName;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    goback() {
      history.go(-1);
    },
    previewEasypay() {
      window.open(api.getImgURL(this.financInfo.evidencePath))
      // api
      //   .DownloadContract({ contractNo: this.financInfo.contractNo })
      //   .then(res => {
      //     let binaryData = [];
      //     binaryData.push(res);
      //     this.src = window.URL.createObjectURL(
      //       new Blob(binaryData, { type: "application/zip" })
      //     );
      //     this.centerDialogVisible = true;
      //   });
    },
  }
};
</script>

<style lang='scss' >
@import "../../../styles/moreDetail/moudleDetail.scss";

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
</style>
