<template>
<div class="page-login">
  <div class="ly-retrieve">
    <div class="main-content">
     <!--<div class="logo">-->
          <!--<img src="../../assets/login/logo_login.png" alt="">-->
          <!--<div class="logo-title">供应链金融系统</div>-->
        <!--</div>-->

   <el-form
        :model="editPswForm"
        :rules="editPswRules"
        ref="editPswForm"
        label-position="left"
        label-width="120px"
        class="demo-ruleForm login-container"
      >
      <h3 style="margin-bottom:25px;margin-top:0;">修改密码</h3>

        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="editPswForm.oldPassword"
            placeholder="旧密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="输入新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="editPswForm.newPassword"
            placeholder="8位以上且必须为英文大小写字母加数字组合"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="newPassword1">
          <el-input
            type="password"
            v-model="editPswForm.newPassword1"
            placeholder="再次输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="verifyCode">
          <el-row>
            <el-col :span="12">
              <el-input
                v-model="editPswForm.verifyCode"
                placeholder="输入图形验证码"
              ></el-input>
            </el-col>
            <el-col :span="12" class="captcha">
              <img
                :src="editPswForm.captchaPath"
                @click="getCaptcha1()"
                alt
                width="100"
                height="50"
              />
            </el-col>
          </el-row>
        </el-form-item>
           <el-form-item style="width:100%;" class="form-btn">
        <el-button @click.native="getlogin">取消</el-button>
        <el-button type="primary" @click.native="submit">确认修改</el-button>
      </el-form-item>
 
      </el-form>
   


    </div>
  </div>
  <layout-footer></layout-footer> 
</div>
</template>
<script>
import LayoutFooter from "@/components/layout-footer";
import api from "../../api/api";
import { getUUID } from "../../util/index.js";
export default {
  components: {
    "layout-footer": LayoutFooter
  },
  data () {
    var checkPassword  = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.retrieveForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {


       editPswForm: {
        uuid: "",
        captchaPath: "",
        oldPassword: "",
        newPassword: "",
        verifyCode: "",
        newPassword1: "",
      },
       editPswRules: {
        verifyCode: [
          { required: true, message: "图形验证码不能为空", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 8,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{9,}$/,
            message: "密码需大于8位以上且必须为英文大小写字母加数字组合",
            trigger: "blur",
          },
        ],
        newPassword1: [
          { required: true, message: "请输入密码" },
          { validator: checkPassword, trigger: "blur" },
        ],
      },
    }
  },
  created() {
      this.getCaptcha1();
  },
  methods: {
    getlogin(){
      this.$router.push({
        path:'/login'
      })
    },
    getMessage () {
      var regu = /^1\d{10}$/;
      var re = new RegExp(regu);
      if (re.test(this.retrieveForm.userName)) {
        this.sendCode = false;
        this.sendTime = 60;
        if (this.sendCode == false) {
          this.getCode();
        }
        var auth_timetimer = setInterval(() => {
          this.sendTime--;
          if (this.sendTime <= 0) {
            this.sendCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      } else {
        this.$message.error("手机号格式错误");
        return;
      }
    },
    getCode() {
      let param = {
        uuid: this.retrieveForm.uuid,
        phone: this.retrieveForm.userName,
        verifyCode: this.retrieveForm.verifyCode,
        channelCode: 'YRY', // 渠道
        smsType: 2,
      };
      api.GetPhoneVerification(param).then(res => {
        if (res.success) {
          this.$message.success("发送成功");
        }else {
          this.$message.error(res.message);
          this.sendCode = true;
        }
      });
    },
    // 获取验证码
  getCaptcha1() {
    this.$nextTick(() => {
      this.$refs.editPswForm.clearValidate()
    })
      this.editPswForm.uuid = getUUID();
      this.editPswForm.captchaPath = api.verifycode(this.editPswForm.uuid);
    },
    // 提交
     //修改密码
    submit() {
      this.$refs.editPswForm.validate((valid) => {
        if (valid) {
          let params = {
            oldPassword: this.editPswForm.oldPassword,
            newPassword: this.editPswForm.newPassword,
            newPassword1: this.editPswForm.newPassword1,
            verifyCode: this.editPswForm.verifyCode,
            uuid: this.editPswForm.uuid,
            userId:this.userId
          };
          api.UpdatePassword(params).then((res) => {
            if (res.success) {
              this.$alert("修改成功", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  sessionStorage.clear();
                  this.$router.push("/login");
                },
              });
            } else {
              this.$message.error(`${res.message}`);
            }
          });
        }
      });
    },
    cancel() {
      this.$router.push('/login');
    },
    getcompname(){
      this.options=[];
      this.retrieveForm.relationCorpId=''
      api.getCorpIdByUserName({userName:this.retrieveForm.userName}).then(res => {               
        if (res.code == '100000') {
          this.options=res.datas.list
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .login-container{
    -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 30px auto;
  width: 100%;
  padding: 35px 35px 35px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  }
  .login-container {
    .title {
      margin: 0px auto 40px auto;
      text-align: center;
      color: #505458;
    }
    .remember {
      margin: 0px 0px 35px 0px;
    }
    h3{
      width: 100%;
      text-align: center;
    }
    .el-form-item__content{
      margin-left: 90px !important;
  }
  }
  .ly-retrieve{
    width: 100%;
    height: 80%;
    background-image: url("../../assets/login/login_bg.jpg");
    background-size: 100% 100%;
    flex: 1;
    position: relative;
    
  }
  .item-input{
    //width:300px;
    .el-input {
      width: 100%;
    }
  }
  .page-login {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin-top: -30px;
}
.logo {
  text-align: center;
  .logo-title {
    margin-top: 20px;
    font-size: 24px;
  }
}
.form-btn {
  .el-button {
    width: 100px;
    &+.el-button {
      margin-left: 20px;
    }
  }
}
.captcha {
  overflow: hidden;
  img {
    display: block;
    float: right;
    height: 40px;
  }
}
.hide {
  position: absolute;
  top: 0;
  width: 0;
  opacity: 0
}
</style>
<style lang="scss">
.btn-s{
  .el-input-group__append{
  width:100px !important;
  padding-left:5px;
  padding-right:25px;
}
}

</style>



