<template>
  <div>
      <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <receivablemanagefirst v-if="showtab==1" ></receivablemanagefirst>

            <receivablemanagesecond v-if="showtab==2" ></receivablemanagesecond>
    </layout-main-view>


  </div>
</template>
<script>
  import api from "@/api/api";
import receivablemanagefirst from "./Receivablemanagefirst";
 import receivablemanagesecond from "./Receivablemanagesecond";




export default {
    components: {
    'receivablemanagefirst':receivablemanagefirst,
     'receivablemanagesecond':receivablemanagesecond
  },
  data() {
    return{
      breadcrumblist: ["应收账款管理"],
          tabOptions: [
        { label: "已新增", value: "1", path: "/factoring/accountsreceivablemanage/1?pageNum=1&pageSize=10" },
        { label: "已退回", value: "2", path: "/factoring/accountsreceivablemanage/2?pageNum=1&pageSize=10" },
        ],
            showtab:1,
            showlist:[],

    }
  },
  computed:{

  },
  created() {
    console.log(this.$route)
    this.showtab=this.$route.params.state;

  },
  methods: {
     tabclick(val) {
    this.showtab=val;
      this.handleEditShow = false;
    },

  },

};
</script>

<style lang="scss">

</style>
