const options = [{
    label: '公有控股经济',
    value: 'A',
    options: [{
        label:'国有控股',
        value: 'A01',
        options: [{
            label: '国有相对控股',
            value: 'A0101'
        },
        {
            label: '国有绝对控股',
            value: 'A0102'
        }]
    },
    {
        label:'集体控股',
        value: 'A02',
        options: [{
            label: '集体相对控股',
            value: 'A0201'
        },
        {
            label: '集体绝对控股',
            value: 'A0202'
        }]
    }]
}, {
label: '非公有控股经济',
value: 'B',
options: [{
        label:'私人控股',
        value: 'B01',
        options: [{
            label: '私人相对控股',
            value: 'B0101'
        },
        {
            label: '私人绝对控股',
            value: 'B0102'
        }]
    },
    {
        label:'港澳台控股',
        value: 'B02',
        options: [{
            label: '港澳台相对控股',
            value: 'B0201'
        },
        {
            label: '港澳台绝对控股',
            value: 'B0202'
        }]
    },
    {
        label:'外商控股',
        value: 'B03',
        options: [{
            label: '外商相对控股',
            value: 'B0301'
        },
        {
            label: '外商绝对控股',
            value: 'B0302'
        }]
    }]
}];

export default {
    getOptions() {
        return options;
    },
    getOptionTextByValue(param) {
       let arr = [];
       options.forEach(item => {
           item.options.forEach(sub => {
               arr.push(sub.options)
               arr = [...arr, ...sub.options]
           })
       })
       let find = arr.find(item => item.value == param);
       return (find && find.label) || ''
    } 
}