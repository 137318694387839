<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <absbindaccountspack ref="pack" :planCorpType='planCorpType'></absbindaccountspack>
        </div>
              <div class="absstartbtn card-bottom">
                  <div class="btnmar">
                        <el-button class="card-btn" @click="goback">返回</el-button>
                       <el-button class="card-btn" type="primary" @click="packfn">资产封包</el-button>
                  </div>

       
      </div>
        </layout-main-view>

    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  absbindaccountspack from '@/views/absbase/absbindaccountspack' 


 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划简介'],
                absPlanDto:{},
                absPlanDetailDto:{},
                datalist:[]
     
            }
        },
        created() {
             api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
                    this.planCorpType=res.datas.planDetail.planCorpType
   
                }else{
                    this.$message.error(res.message)
                }
            });
     
        },
        methods: {
            goback(){
                history.go(-1)
            },
            packfn(){
                console.log(this.$refs.pack,'pack')
                    if(!this.$refs.pack.chooselist.length){
                                this.$message.error('请选择资产包')
                                return

                    }
                 this.$confirm('资产封包以后即进入签约流程，封包后资产将不可再进行置换操作.', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        dangerouslyUseHTMLString: true
                        }).then(() => {
                            var param={
                                absPlanId: this.$route.query.id,
                                list:[],
                                corpList:[],
                                noCorpList:[]

                            }
                            this.$refs.pack.chooselist.map(el=>{
                                    if(el.absPlanAccounts.length){
                                        
                                          el.absPlanAccounts.map(ele=>{
                                                if(ele.ischecked){
                                                    param.list.push(ele.absAccountId)
                                                }
                                          })
                                          if(el.absPlanAccounts.some(ele=>ele.ischecked)){
                                              param.corpList.push(el.absPlanCorp)
                                          }
                                          
                                    }
                            })
                                console.log(param.corpList,'cop')
                           this.$refs.pack.datalist.map(el=>{
                                    if(param.corpList.length){
                                        if(param.corpList.every(ele=>ele!=el.absPlanCorp)){
                                                param.noCorpList.push(el.absPlanCorp)
                                        }
                                    }else{
                                        param.noCorpList.push(el.absPlanCorp)
                                    }

                           })
                    

                            if(!param.list.length){
                                this.$message.error('请选择账款')
                                          return
                            }
                  
                     
                               api.submitPacket(param).then(res =>{ //封包
                                        if(res.success) {
                                                this.$alert(res.message, '', {
                                                    confirmButtonText: '确定',
                                                    callback: action => {
                                                            history.go(-1);
                                                    }
                                                });
                        
                                        }else{
                                            this.$message.error(res.message)
                                        }
                                    });
                        }).catch(() => {
                          
                        });
                
            }
    
        },
        components: {
           absaccountxmgk,
           absbindaccountspack

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }

   }
</style>