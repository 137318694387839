<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick">
      <uncheckedList  v-if="showtab==1" checkStep="2"></uncheckedList> 
      <checkedList  v-if="showtab==2" checkStep="2"></checkedList> 
    </layout-main-view>
  </div>
</template>
<script>
import api from "@/api/api";
import moment from "moment";
import uncheckedList from "../uncheckedList";
import checkedList from "../checkedList";
export default {
  components: {
    uncheckedList,
    checkedList
  },
  computed: {},
  data() {
    return {
      breadcrumblist: ["资产复审"],
      showtab: 1,
      tabOptions: [
        { label: "待审批", value: "1", path: "/fund/abs/assetsCheck/recheck/1" },
        { label: "已审批", value: "2", path: "/fund/abs/assetsCheck/recheck/2" },
      ],
      activeName: "first",
      handleEditShow: false,
    };
  },
  created() { },
  mounted() {
    this.showtab = this.$route.params.state;
  },
  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
  }
};
</script>

