<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      
      >
        <el-table
          v-if="isbl"
          :data="listData"
     align='center'
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="170"></el-table-column>
          <el-table-column prop="absPlanName" label="计划名称" min-width="170"  >
    
          </el-table-column>
          <el-table-column  label="计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'></el-table-column>

          <el-table-column prop="delistingCorpName" label="汇集起止日" min-width="210">
              <template slot-scope="scope">
  {{moment(scope.row.collectStartDate).format("YYYY-MM-DD")+'~'+moment(scope.row.collectEndDate).format("YYYY-MM-DD")}}
             
            </template>
          </el-table-column>
  

          <el-table-column  label="计划发起人"  prop='planSponsor' min-width="160"  ></el-table-column>
          <el-table-column  label="资产状态"  prop='planStatusShow' min-width="110" ></el-table-column>


          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>

              <el-button size="medium" type="text" @click="initiateopen(scope.row)" v-if="scope.row.leaderCorpType==1&&scope.row.assetsStatus==31" >发起收集</el-button>
              <el-button size="medium" type="text" @click="absviewassetsfn(scope.row)" v-if="scope.row.leaderCorpType==1&&scope.row.assetsStatus!=31" >查看资产</el-button>
              <el-button size="medium" type="text" @click="fundabsviewassetsfn(scope.row)" v-if="scope.row.leaderCorpType!=1&&scope.row.assetsStatus!=31" >查看资产</el-button>

              <el-button size="medium" type="text" @click="initiatepack(scope.row)" v-if="scope.row.leaderCorpType==1&&scope.row.assetsStatus==36" >资产封包</el-button>
              <el-button size="medium" type="text" @click="fundinitiatepack(scope.row)" v-if="scope.row.leaderCorpType!=1&&scope.row.assetsStatus==36" >资产封包</el-button>

            </template>
          </el-table-column>
        </el-table>
<!-- 下面是核心企业的  稍后区分-->
              <el-table
              v-if="!isbl"
          :data="listData"
     align='center'
          highlight-current-row
          style="width: 100%;"
        >
          <el-table-column prop="absPlanNo" label="专项计划编号" min-width="170"></el-table-column>
          <el-table-column prop="absPlanName" label="计划名称" min-width="170"  >
    
          </el-table-column>
          <el-table-column  label="计划规模(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.preAssertScaleAmount)'></el-table-column>

          <el-table-column prop="delistingCorpName" label="汇集起止日" min-width="210">
              <template slot-scope="scope">
  {{moment(scope.row.collectStartDate).format("YYYY-MM-DD")+'~'+moment(scope.row.collectEndDate).format("YYYY-MM-DD")}}
             
            </template>
          </el-table-column>
          <el-table-column  label="预计发行利率区间(%)"  min-width="190">
              <template slot-scope="scope">

  {{scope.row.preProfitInterestMin+'-'+scope.row.preProfitInterestMax}}
           </template>
          </el-table-column>
               <el-table-column  label="预计资产折扣区间(%)"  min-width="190">
              <template slot-scope="scope">

  {{scope.row.preDiscountInterestMin+'-'+scope.row.preDiscountInterestMax}}
           </template>
          </el-table-column>

          <el-table-column  label="计划发起人"  prop='planSponsor' min-width="160"  ></el-table-column>



          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
              <el-button size="medium" type="text" @click="assetpool(scope.row)" v-if="scope.row.leaderCorpType!=1&&scope.row.remark==28" >资产汇集</el-button>

              <el-button size="medium" type="text" @click="fundabsviewassetsfn(scope.row)" v-if="scope.row.leaderCorpType!=1&&scope.row.remark!=28" >查看资产</el-button>



            </template>
          </el-table-column>
        </el-table>
      </data-list>

    </el-tabs>

             <el-dialog title='发起集资' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='800px' class="initiation">
                    <p>
                        <i class="el-icon-bell"></i>
                        <span class="ipttext">重要提示: </span>
                    </p>

                    <p>
                      本次计划计划资产收集时间截止日:<span class="collectEndDate">{{collectEndDate|formatDate}}</span>,资产实际收集人为如下企业:  
                    </p>
                             <el-row class="active">
                        <el-col :span="24">
                      
                     <span class="label" style="float:left;width:120px;text-indent:1em;" >供应商名称:</span>          
                         
                               <div  style="margin-left:120px" >
                                    <span class="value"  v-for="item in  actualCollectSupplierCorpIds" :key="item.corpId">{{item.corpName}}</span>
                               </div>
                           
                        </el-col>
                    </el-row>
  <el-row class="active">
                        <el-col :span="24">
                      
                     <span class="label" style="float:left;width:120px;text-indent:1em;" >核心企业名称:</span>          
                         
                               <div  style="margin-left:120px" >
                                    <span class="value"  v-for="item in actualCollectCoreCorpIds" :key="item.corpId">{{item.corpName}}</span>
                               </div>
                           
                        </el-col>
                    </el-row>
        <p>点击发送,上述企业将收到资产收集任务.请及时和企业保持联系以免企业未及时获取收集任务</p>
          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog=false">取 消</el-button>
              <el-button type="primary" @click="submitAddForm()" >发送</el-button>
          </div>
        </el-dialog>

            <el-dialog title='资产封包' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false'  width='800px' class="initiation">
              
          <div slot="footer" class="dialog-footer">
              <el-button @click="showAddDialog2=false">取 消</el-button>
              <el-button type="primary" @click="submitAddForm2()" >发送</el-button>
          </div>
        </el-dialog>
  </div>
</template>
<script>
import api from "../../api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'


export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
            {
          label: "汇集起止日:",
          type: "daterange",
          field: ["collectStartDate", "collectEndDate"],
          size: "small"
        },

        {
          label: "项目名称: ",
          type: "input",
          field: "absPlanName",
          size: "small",
        },
      ],
      total: 0,
      listData: [],
      showAddDialog:false,
      actualCollectCoreCorpIds:[],
      actualCollectSupplierCorpIds:[],
      collectEndDate:'',
      absPlanId:'',
      isbl:false,
      showAddDialog2:false
    };
  },
  created() {
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false
        
  },
  methods: {
    moment: moment,

    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
  param.receiveStatus=2
   var temp=''
    if(this.isbl){
      temp=api.getFundAbsPlanList(param)
    }else{
     temp= api.getEnterpriseAbsPlanList(param)
    }
      temp.then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        
        }
      });
    },
   handleEditinfo(row){
           this.$router.push({
         name: "专项计划简介",
                    query: { id: row.absPlanId,isshowbtn:2}
      });
    },
    handleEdit(row){

        this.$router.push({
         name: "资产账款详情",
                    query: { id: row.absPlanId,isshowbtn:1}
      });

    },
    initiateopen(data){
            this.showAddDialog=true
    this.collectEndDate=data.collectEndDate
    this.absPlanId=data.absPlanId
               if(data.actualCollectCoreCorpIds.length>0){
                            this.actualCollectCoreCorpIds=JSON.parse(data.actualCollectCoreCorpIds)
        


                    }
                    if(data.actualCollectSupplierCorpIds.length>0){
                            this.actualCollectSupplierCorpIds=JSON.parse(data.actualCollectSupplierCorpIds)
                     

                          
                    }

        
    },
    submitAddForm(){
             const loading = this.$loading({
                                    lock: true,
                                    text: "Loading",
                                    spinner: "el-icon-loading",
                                    background: "rgba(0, 0, 0, 0.7)"
                            });

                api.initiateFundraising({absPlanId:this.absPlanId}).then(res => {
                    loading.close()
                                            if(res.code=='100000'){
                                                     this.$router.push({path:this.$route.path})
                                                     this.showAddDialog=false
                                                     this.$message.success('发起成功')

                                            }

                                }).catch(err => {
                                              loading.close();
                     });
    },
    submitAddForm2(){

    },
    absviewassetsfn(row){
        this.$router.push({name:'absviewassetsinfo',query:{id:row.absPlanId}})

    },
    fundabsviewassetsfn(row){
        this.$router.push({name:'absviewfundassetsinfo',query:{id:row.absPlanId}})

    },
    assetpool(row){
        this.$router.push({name:'absviewassetscheckinfo',query:{id:row.absPlanId}})

        
    },
    initiatepack(row){
        this.$router.push({name:'absviewassetspackinfo',query:{id:row.absPlanId}})

    },
    assetpoolinfo(row){
        this.$router.push({name:'absviewassetscoreinfo',query:{id:row.absPlanId}})


    },
    fundinitiatepack(row){
            this.$confirm('资产封包以后即进入签约流程，封包后资产将不可再进行置换操作.', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        dangerouslyUseHTMLString: true
                        }).then(() => {
                            var param={
                                absPlanId: row.absPlanId,
                          
                            }
                            
                               api.submitPacket(param).then(res =>{ 
                                        if(res.success) {
                                            this.$message.success('封包完毕');
                                            this.$router.push({path:this.$route.path})
                        
                                        }else{
                                          this.$message.error(res.message)
                            

                                        }
                                    });
                        }).catch(() => {
                          
                        });
    }

  }
};
</script>
<style lang="scss">
.initiation{
        .el-row{
              text-indent: 1em;
              &.active{
                  text-indent: 0;
                div  div span{
                      display: inline-block;
                      margin-right: 10px;
                  }
              }
              .el-col{
                  margin-bottom: 14px;
              }
          }
          .collectEndDate{
              color: #DE2525;
          }
    .el-dialog__body{
            padding-top: 0px;
    }
    .el-icon-bell{
        font-size: 24px;
        color: #E6DF2B;
        font-weight: bold;
    }
    .ipttext{
        font-size: 18px;
    }
}
</style>
 


