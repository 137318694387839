<!--投资偏好设置-->
<template>
    <div class="invest-setting">
        <layout-main-view
        :breadcrumb="breadcrumblist"
        >
            <el-form ref="investForm" :model="investForm" :rules="investRules" class="invest-form">
                <div class="form-item">
                    <div class="form-item-header">
                        <span class="form-item-header-title">资产确权偏好</span>
                        <span class="form-item-header-tips">系统会按照确权状态推送符合您投资偏好习惯的资产。</span>
                    </div>
                    <el-form-item label="是否需要核心企业确权：" prop="coreAck">
                        <el-radio-group v-model="investForm.coreAck">
                            <el-radio :label="1">需要确权</el-radio>
                            <el-radio :label="2">无需确认</el-radio>
                            <el-radio :label="3">都可以</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="form-item">
                    <div class="form-item-header">
                        <span class="form-item-header-title">资产金额偏好</span>
                        <span class="form-item-header-tips">系统会按照意向资产金额区间内的资产进行推送。</span>
                    </div>
                    <el-form-item label="意向资产金额：" required>
                        <el-form-item prop="intentionAmountMin" style="display:inline-block;">
                            <el-input v-model.number="investForm.intentionAmountMin" class="form-item-input">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                        <span class="form-line">-</span>
                        <el-form-item prop="intentionAmountMax" style="display:inline-block;">
                            <el-input v-model.number="investForm.intentionAmountMax" class="form-item-input">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-form-item>
                </div>
                <div class="form-item">
                    <div class="form-item-header">
                        <span class="form-item-header-title">融资年化利率偏好</span>
                        <span class="form-item-header-tips">系统会按照意向融资年化利率区间内的进行推送。</span>
                        <el-tooltip effect="dark" placement="right">
                            <div slot="content" class="tooltips">
                                1、填写最低利率：<br>
                                系统自动在最小利率的基础上增加8%作为最高利率的默认值，用户可自行增大，不能减小。<br>
                                例：最低利率填入10%，最高利率自动填写为18%。用户可以手动修改为大于18的数字，但不能小于18。<br>
                                2、填写最高利率：<br>
                                系统自动在最大利率的基础上减少8%作为最低利率的默认值，用户可自行减小，不能增大。<br>
                                例：最高利率填入10%，最低利率自动填写为2%。用户可以手动修改为小于2的数字但不能等于或小于0，也不可大于2
                            </div>
                            <span class="form-item-header-rules"><i class="el-icon-question"></i>规则</span>
                        </el-tooltip>
                    </div>
                    <el-form-item label="意向融资年化利率：" required>
                        <el-form-item prop="intentionInterestMin" style="display:inline-block;">
                            <el-input v-model.number="investForm.intentionInterestMin" class="form-item-input">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <span class="form-line">-</span>
                        <el-form-item prop="intentionInterestMax" style="display:inline-block;">
                            <el-input v-model.number="investForm.intentionInterestMax" class="form-item-input">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-form-item>
                </div>
                <div class="form-item">
                    <div class="form-item-header">
                        <span class="form-item-header-title">应收账款期限偏好</span>
                        <span class="form-item-header-tips">系统会按照偏好账款期限内的资产进行推送。</span>
                    </div>
                    <el-form-item label="意向期限：" prop="assertExpire">
                        <el-checkbox-group v-model="investForm.assertExpire">
                            <el-checkbox :label="1">小于30天</el-checkbox>
                            <el-checkbox :label="2">30~60天</el-checkbox>
                            <el-checkbox :label="3">60~90天</el-checkbox>
                            <el-checkbox :label="4">90~180天</el-checkbox>
                            <el-checkbox :label="5">180~365天</el-checkbox>
                            <el-checkbox :label="6">365天以上</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </div>
                <div class="form-item">
                    <div class="form-item-header">
                        <span class="form-item-header-title">偏好企业</span>
                        <span class="form-item-header-tips">关注企业总共 <span class="total-company">{{investForm.intentionCorpId.length}}</span> 家，这些企业如果发布资产信息后会自动推送至保理投资列表中。</span>
                    </div>
                    <el-form-item>
                        <el-autocomplete
                            v-model="investForm.findCorpName"
                            class="form-item-input"
                            :fetch-suggestions="queryCompany"
                            placeholder="输入企业名称"
                            @select="handleSelect"
                            >
                        </el-autocomplete>
                        <el-button icon="el-icon-plus" class="add-focus" @click="addFocusCompany">关注企业</el-button>
                    </el-form-item>
                    <div class="company-list">
                        <div class="company-list-item" v-for="(item,index) in investForm.intentionCorpId" :key="index">
                            <el-popover
                                placement="top"
                                width="150"
                                trigger="hover"
                                v-model="item.visible">
                                <el-button class="popover-cancel" size="mini" type="text" @click="cancelFocus(item,index)">取消关注</el-button>
                                <i slot="reference" class="el-icon-error"></i>
                            </el-popover>
                            <el-tooltip class="item" effect="dark" :content="item.corpName" placement="bottom">
                                <span>{{item.corpName}}</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <el-form-item class="form-btn">
                    <el-button class="submit-btn" type="primary" @click="onSubmit">保 存</el-button>
                </el-form-item>
            </el-form>
        </layout-main-view>
    </div>
</template>
<script>
import api from "../../api/api.js";
export default {
    data() {
        return {
            breadcrumblist: ["投资偏好设置"],
            investForm: {
                coreAck: '',
                intentionAmountMin: '',
                intentionAmountMax: '',
                intentionInterestMin: '',
                intentionInterestMax: '',
                assertExpire: [],
                findCorpName: '',
                intentionCorpId: [],
                // intentionCorpIdCount: 0,
            },
            investRules: {
                coreAck: [{required: true, message: '请选择是否需确权', trigger: 'change'}],
                intentionAmountMin: [
                    {required: true, message: '意向资金最小额不能为空',trigger: 'blur'},
                    {type: 'number',message: '请输入数字'},
                ],
                intentionAmountMax: [
                    {required: true, message: '意向资金最大额不能为空', trigger: 'blur'},
                    {type: 'number',message: '请输入数字'},
                ],
                intentionInterestMin: [
                    {required: true, message: '意向融资年化利率最小额不能为空', trigger: 'blur'},
                    {type: 'number',message: '请输入数字'},
                ],
                intentionInterestMax: [
                    {required: true, message: '意向融资年化利率最大额不能为空', trigger: 'blur'},
                    {type: 'number',message: '请输入数字'},
                ],
                assertExpire: [{required: true, message: '请选择意向期限', trigger: 'change'}]
            },
            visible: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        // 初始化设置
        init() {
            api.InitInvestSetting().then(res => {
                if(res.code == 100000) {
                    if(res.datas.fundCorpIntentionDto) {
                        this.investForm = res.datas.fundCorpIntentionDto;
                        this.investForm.assertExpire = JSON.parse(this.investForm.assertExpire);
                        this.investForm.intentionCorpId = JSON.parse(this.investForm.intentionCorpId);
                        this.investForm.findCorpName = ''
                    }
                }
            })
        },
        // 获取公司列表
        queryCompany(queryString,cb) {
            api.GetCompanyList({'corpName':this.investForm.findCorpName}).then(res => {
                if(res.code == 100000) {
                    var result = [];
                    var companyList = res.datas.corpList;
                    var results = queryString ? companyList.filter(this.createStateFilter(queryString)) : companyList;
                    for(let i of results){
                        i.value = i.corpName
                    }
                    cb(results);
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        // 公司名过滤
        createStateFilter(queryString) {
            return (state) => {
                return (state.corpName.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
            }
        },
        // 添加关注企业
        addFocusCompany() {
            if(this.selectCompany) {
                this.investForm.findCorpName = ''
                this.investForm.intentionCorpId.push( this.selectCompany);
                this.selectCompany = null;
            }
        },
        // 取消关注
        cancelFocus(item,index) {
            item.visible = false
            this.investForm.intentionCorpId.splice(index,1)
        },
        // 保存
        onSubmit() {
            this.$refs.investForm.validate((valid) => {
                if (valid) {
                    api.SubmitInvestSetting({
                        coreAck: this.investForm.coreAck,
                        intentionAmountMin: this.investForm.intentionAmountMin,
                        intentionAmountMax: this.investForm.intentionAmountMax,
                        intentionInterestMin: this.investForm.intentionInterestMin,
                        intentionInterestMax: this.investForm.intentionInterestMax,
                        assertExpire: JSON.stringify(this.investForm.assertExpire),
                        intentionCorpId: JSON.stringify(this.investForm.intentionCorpId),
                    }).then(res => {
                        if(res.code == 100000) {
                            this.$alert(res.message)
                            this.init();
                        }else{
                            this.$message.error(res.message)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleSelect(item) {
            this.selectCompany = item
        }
    },
}
</script>
<style lang="scss">
    $commonColor: #2937A6;
    .invest-setting {
    }
    .company-list-item{
        .el-popover {
        width: 80px !important;
        min-width: 80px !important;
        padding: 5px 0;
        text-align: center;
    }
    .popover-cancel {
        font-size: 14px;
        color: #262626;
        &:hover {
            color: #262626;
        }
    }
    }
    .invest-form {
        background: #fff;
        padding: 0 32px;
    }
    .form-item {
        padding: 42px 0 40px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 0;
        &-header {
            position: relative;
            margin-bottom: 24px;
            &-title {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                padding-left: 11px;
                padding-right: 16px;
            }
            &-tips {
                font-size: 14px;
                color: rgba(0,0,0,.5);
            }
            &-rules {
                font-size: 14px;
                color: $commonColor;
                cursor: pointer;
            }
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 3px;
                height: 14px;
                margin-top: -7px;
                border-left: 3px solid #D19F53;
            }
            .el-icon-question {
                font-size: 16px;
                color: $commonColor;
                padding-right: 5px;
            }
        }
        .el-form-item{
            &.is-success {
                .el-input__inner {
                    border-color: #dcdfe6
                }
            }
            &__label {
               font-size: 14px;
            color: #595959;
            line-height: 32px;
            }
            &__content {
                line-height: 32px;
                .el-radio {
                    font-size: 14px;
                    color: #262626;
                }
                .form-item-input {
                    width: 240px;
                    height: 32px;
                    line-height: 32px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                }
                .form-line {
                    padding: 0 6px;
                }
            }
        }
        .el-radio__input.is-checked .el-radio__inner,.el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: $commonColor;
            border-color: $commonColor;
        }
        .el-radio__input.is-checked+.el-radio__label,.el-checkbox__input.is-checked+.el-checkbox__label {
            color: $commonColor;
        }
        .total-company {
            color: #00253D;
        }
        .company-list {
            margin-top: 32px;
            padding: 32px 24px;
            background: #F8F8F8;
            &-item{
                display: inline-block;
                width: 240px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                font-size: 14px;
                color: #262626;
                line-height: 22px;
                .el-icon-error {
                    color: #ccc;
                    padding-right: 8px;
                }
                &+.company-list-item {
                    margin-left: 32px;
                }
            }
        }
    }
    .form-btn {
        text-align: center;
        margin-bottom: 0;
        .submit-btn {
            width: 160px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            background: $commonColor;
            margin: 32px 0 40px;
        }
    }
    .add-focus {
        width: 100px;
        height: 32px;
        font-size: 14px;
        color: #fff;
        background: $commonColor;
        padding: 0;
        &:hover,&:focus {
            color: #fff;
            border-color: $commonColor;
            background-color: $commonColor;
        }
    }
</style>
