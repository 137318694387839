<template>
    <layout-main-view :breadcrumb="breadcrumblist">
        <div class="common-page-dataList blrzstep" >
          <steps :stepList="stepList" :current="current"></steps>

            <div class="easypayby goback yfdtwo">
                <div v-if="$route.query.showresult==2">


                    <div class="yfdsize">审批结果</div>
                    <el-form
                            :model="info"
                            ref="info"
                            class="demo-ruleForm"
                    >
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="结果: ">
                                    <span>{{receinfo.financeSupplyApproveStatusShow||'无'}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="审批人: ">
                                    <span>{{receinfo.approvePerson}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="审批时间: ">
                                    <span>{{moment(receinfo.approveCreateTime).format("YYYY-MM-DD")}}</span>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="8" v-if='info.financeBackStatus!=1'>
                                <el-form-item label="退回理由: ">
                                    <span>{{receinfo.reason||'无'}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="备注: ">
                                    <span>{{receinfo.approveRemark||'无'}}</span>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-form>
                </div>
                <div class="yfdsize">基本信息</div>
                <div class="formstyle">
                    <el-form
                            :model="info"
                            ref="info"
                            class="demo-ruleForm"
                    >

                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="融资项目编号: ">
                                    <span>{{info.receivableAccountFinanceNo}}</span>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="资金机构: ">
                                    <span>{{info.fundCorpName}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="融资年化利率(%): ">
                                    <span>{{info.financeInterest}}%</span>
                                </el-form-item>
                            </el-col>

                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="融资金额(元): ">
                                    <span>{{$changeMoneyThousand(info.financeAmount)}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="大写: ">
                                    <span>{{convertCurrency(info.financeAmount)}}</span>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-form>
                </div>

                <div class="yfdbaseline"></div>
                <div class="yfdsize">绑定应收账款</div>
                <div class="httotal">
                    应收账款
                    <span>{{tableList.length}}</span> 笔，账款总计金额
                    <span>{{$changeMoneyThousand(totalcount)}}</span> 元。
                </div>
                <div>
                    <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%"
                    >

                        <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
                        <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>

                        <el-table-column prop="accountAmount" label="账款金额(元)" min-width="120">
                            <template slot-scope="scope">{{$changeMoneyThousand(scope.row.accountAmount)}}</template>

                        </el-table-column>
                        <el-table-column prop="quoteTimeLimit" label="账款起始日期" min-width="120">
                            <template slot-scope="scope">{{ scope.row.accountSignDate | formatDate }}</template>

                        </el-table-column>

                        <el-table-column prop="quoteTimeLimit" label="账款到期日期" min-width="120">
                            <template slot-scope="scope">{{ scope.row.accountCloseDate | formatDate }}</template>

                        </el-table-column>

                        <el-table-column prop="quoteTimeLimit" label="操作" min-width="120">
                            <template slot-scope="scope">
                                <el-button size="medium" type="text" @click="handleNew(scope.row)">查看</el-button>


                            </template>

                        </el-table-column>
                    </el-table>

                </div>
                <div class="card-hd" style="height: 150px;" v-if="$route.query.isshowbtn!=2">
                    <el-row style="padding-top: 40px;">
                        <el-col :span="7" class="lb_body" :offset="7">
                            <el-button
                                    style="width: 160px;height: 40px;font-size:16px;"
                                    size="small"
                                    @click="refusefn"
                            >退回
                            </el-button>
                        </el-col>
                        <el-col :span="8" class="lb_body">
                            <el-button
                                    style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                                    size="small"
                                    @click="agreefn"
                            >同意
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="card-hd" style="height: 150px;" v-else>
                    <el-row style="padding-top: 40px;">
                        <el-col :span="7" class="lb_body" :offset="10">
                            <el-button
                                    style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;font-size:16px;"
                                    size="small"
                                    @click="gobanckfn"
                            >返回
                            </el-button>
                        </el-col>

                    </el-row>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <!-- 弹窗 -->
        <el-dialog title='审核意见' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'
                   width='40%'>
            <el-form :model="agreedetail" label-width="60px" ref="addform">
                <el-form-item label="备注：" prop="content">
                    <el-input v-model="agreedetail.remark" type="textarea" :rows="6" maxlength="200">

                    </el-input>
                </el-form-item>


            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="showAddDialog=false">取 消</el-button>
                <el-button type="primary" @click="submitAddForm" :disabled="bclick">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title='拒绝原因' :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close='false'
                   width='40%'>
            <el-form :model="refusedetail" label-width="100px" ref="addform">
                <el-form-item label="拒绝原因：" prop="denialReason">
                    <el-select v-model="refusedetail.reason" placeholder="请选择">
                        <el-option
                                v-for="item in refuselist"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="content">
                    <el-input v-model="refusedetail.remark" type="textarea" :rows="6" maxlength="200">

                    </el-input>
                </el-form-item>


            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="showAddDialog2=false">取 消</el-button>
                <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
            </div>
        </el-dialog>

    </layout-main-view>
</template>
<script>
    import api from "@/api/api";
    import {convertCurrency, adds} from "@/util/common.js";
    import {baseURL} from "@/api/sys";
    import moment from "moment";
    import {changeMoneyThousand} from "@/util/common";
import steps from "@/views/base/steps.vue";


    export default {
        components: {
            steps
        },
        data() {
            return {
                convertCurrency,
                baseURL: baseURL,
                loading: false,
                breadcrumblist: ["待补件审核详情"],
                tableList: [],
                info: {
                    financeAmount: 0,
                },

                moment: moment,
                showAddDialog: false,
                showAddDialog2: false,
                refusedetail: {
                    approveNode: this.$route.query.approveNode,
                    receivableAccountFinanceId: this.$route.query.id,
                    approveResult: 2,
                    remark: '',
                    reason: "",
                },
                agreedetail: {
                    approveNode: this.$route.query.approveNode,
                    receivableAccountFinanceId: this.$route.query.id,
                    approveResult: 1,
                    remark: '',


                },


                bclick: false,

                refuselist: [],
                totalcount: 0,
                filelist: [],
                receinfo: {},
                 stepList: [
                                    {
                                    name: "发起融资",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "项目内审",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "平台撮合",
                                    realName: "",
                                    createTime: ""
                                    },
                                       {
                                    name: "机构报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "选择报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "补件确权",
                                    realName: "",
                                    createTime: ""
                                    },
                                     {
                                    name: "等待签约",
                                    realName: "",
                                    createTime: ""
                                    },
                                      {
                                    name: "签约完成",
                                    realName: "",
                                    createTime: ""
                                    },


                                ],
                            current:1


            }
        },

        created() {

            this.FinancingProjectDetails();

            this.receinfo = JSON.parse(this.$route.query.receinfo)
        },
        methods: {
            FinancingProjectDetails() {
                api
                    .FinancingProjectDetails({
                        receivableAccountFinanceId: this.$route.query.id
                    })
                    .then(res => {
                        if (res.success) {
                            var arr = []
                            this.info = res.datas.data;
                            this.tableList = res.datas.list;
                            this.tableList.map(el => {
                                arr.push(el.accountAmount)
                            })
                            this.totalcount = 0..adds(...arr)
                            api.getDicListByType({type: 16}).then(res => {
                                if (res.code == '100000') {
                                    this.refuselist = res.datas.list;
                                    this.refuselist.map(el => {
                                        if (el.code == this.info.reason) {
                                            this.info.reasonmark = el.name
                                        }
                                    })


                                }

                            })
                            this.getstatus()
                        }
                    });
            },
            submitAddForm() {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                api.checkFinanceSupplyFile(this.agreedetail).then(res => {
                    if (res.success) {
                        var messages='补件审批成功'
                        if(JSON.parse(sessionStorage.user).corpType==1){
                               switch (JSON.parse(sessionStorage.user).roleCodes[0]) {
                                        case 'fund_first':

                                        messages='补件审批成功，等待复审'
                                        break;
                                            case 'fund_second':

                                        messages='补件审批成功，等待终审'


                                        break;
                                            case 'fund_third':

                                        messages='补件审批成功，等待确权/签约'

                                        break;
                                                    default:
                                        messages='补件审批成功，等待复审'


                                        break;

                                    }
                        }else if(JSON.parse(sessionStorage.user).corpType==3){
                                        messages='补件审批通过，等待平台审查'

                        }


                        this.$alert(messages, "", {
                            confirmButtonText: "确定",
                            callback: action => {
                                loading.close()
                                history.go(-1);
                                this.showAddDialog = false
                            }
                        });
                        // this.$alert('操作成功')
                        //     loading.close()
                        //     history.go(-1)
                        //   this.showAddDialog=false
                    } else {
                        this.$message.error(res.message);
                        loading.close()

                    }
                }).catch((err) => {
                    loading.close()
                })


            },
            submitAddForm2() {

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                api.checkFinanceSupplyFile(this.refusedetail).then(res => {
                    if (res.success) {

                        this.$alert("操作成功", "", {
                            confirmButtonText: "确定",
                            callback: action => {
                                loading.close()
                                this.showAddDialog2 = false
                                history.go(-1);
                            }
                        })


                    } else {
                        this.$message.error(res.message);
                        loading.close()

                    }
                }).catch((err) => {
                    loading.close()
                })


            },
            handleNew(data) {
                this.$router.push({
                    name: "应收账款详情",
                    query: {id: data.receivableAccountId, isshowbtn: 2}
                });
            },

            refusefn() {
                this.showAddDialog2 = true;
                this.refusedetail.reason = this.refuselist[0].name;
            },
            agreefn() {
                this.showAddDialog = true

            },
            gobanckfn() {
                history.go(-1)
            },
              getstatus(){
          if(this.info.state == 'D'){
                // 作废
                this.current=-1
                }else{
                if(this.info.financeSignStatus == 50){
                  this.current=8
                // 签约完成
                }else if(this.info.financeSignStatus >= 20 && this.info.financeSignStatus < 50){
                  this.current=6
                // 等待签约
                }else if(this.info.financeSignStatus < 20 && this.info.financeSupplyApproveStatus >= 20 && this.info.financeSupplyApproveStatus <= 57 && this.info.corpAckStatus > 1 && this.info.corpAckStatus <= 6){
                // 补件确权
                this.current=5
                }else if(this.info.financeQuoteStatus == 3 && this.info.financeSupplyApproveStatus < 20 ){
                  this.current=4
                // 选择报价
                }else if(this.info.financeQuoteStatus == 2){
                  this.current=3
                // 机构报价
                }else if(this.info.approveStatus >= 41 && this.info.approveStatus <= 49){
                  this.current=2
                // 平台撮合
                }else if(this.info.approveStatus > 20 && this.info.approveStatus <= 23){
                // 项目内审
                this.current=1
                }else if(this.info.approveStatus == 20){
                  this.current=1
                // 发起融资
                }else{
                  this.current=6

                // 等待签约
                }
                }


    },
        }
    };
</script>
<style lang="scss">
    .demo-ruleForm {
        margin-left: 30px
    }

    .boltdetial {
        .upload-demo.upload-contentr .el-upload-list__item {
            width: 30%;
        }


    }

    .yfdbaseline {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 18px;
        margin-bottom: 37px;
    }

    .easypayby {
        padding-bottom: 40px;

        &.goback {
            padding-top: 32px;
        }
    }

    .yfdtwo {
        padding: 0 42px;

        .el-input.is-disabled .el-input__inner {
            color: rgb(96, 98, 102);
        }
    }

    .httotal {
        height: 35px;
        background: #F0F7FF;
        border-radius: 4px;
        line-height: 35px;
        padding-left: 17px;
        margin-bottom: 18px;
        color: #828282;

        span {
            color: #da2b2b;
            line-height: 22px;
        }
    }

    .formstyle {
        & > div {
            margin-bottom: 20px;
        }

        .el-select,
        .el-date-editor {
            width: 100%;
        }

        .elspan {
            .el-input__inner {
                border-radius: 4px 0 0 4px;
            }

            .el-input {
                width: 80%;
            }

            .el-form-item__content {
                span {
                    width: 20%;
                    display: inline-block;
                    height: 40px;
                    background: #f7f7f7;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px 0 0 4px;
                    border-radius: 0 4px 4px 0;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 40px;
                    border-left: none;
                    position: relative;
                    top: 1px;
                    text-align: center;
                }
            }
        }

        .pec.elspan {
            .el-input {
                width: 90%;
            }

            .el-form-item__content {
                span {
                    width: 10%;
                }
            }
        }

        .el-table__body-wrapper {
            input[type="text"] {
                border: 1px solid #fff;
            }

            .el-table__row {
                input {
                    padding: 6px 10px;
                }
            }
        }

        .el-table--enable-row-hover .el-table__body tr:hover > td {
            background-color: #fff !important;
        }

        .htbox {
            border-radius: 4px;
            border: 1px solid #e6e6e6;

            .htpad {
                padding: 0 17px;

                form {
                    overflow: hidden;
                }
            }

            .htactive {
                height: 43px;
                overflow: hidden;
                background: #f1f1f1;
                line-height: 43px;

                span {
                    display: inline-block;
                    width: 100px;
                    margin-left: 17px;
                    float: left;
                    cursor: pointer;
                }
            }

            .htno {
                height: 90px;
                border-bottom: 1px solid #e6e6e6;
                padding-top: 25px;
            }

            .uploadfont {
                color: rgba(0, 0, 0, 0.45);
                line-height: 42px;

                span {
                    text-indent: 8em;
                    display: inline-block;
                }
            }

            .uploadlist {
                padding: 30px 16px 0 16px;

                .upload-demo {
                    margin-left: 8em;
                }

                .el-form-item {
                    margin-bottom: 40px;
                }

                .el-form-item__label {
                    font-size: 16px;
                    color: #000000;
                }

                .el-upload-dragger {
                    width: 236px;
                    height: 92px;
                }

                .el-upload-dragger .el-upload__text,
                .el-icon-plus {
                    color: rgba(0, 0, 0, 0.45);
                }

                .el-icon-plus {
                    font-size: 30px;
                }
            }

            .el-icon-delete {
                float: right;
                padding: 12px 20px 0 0;
                cursor: pointer;
                font-size: 18px;
            }

            .ljje {
                text-align: right;
                margin-bottom: 70px;
                color: #595959;

                span {
                    color: #f5212d;
                }
            }
        }
    }

    .easyformbtn {
        text-align: center;

        button {
            width: 160px;
            height: 40px;
            font-size: 16px;

            text-align: center;
            line-height: 18px;

            span {
                color: #ffffff;
            }
        }

        button:nth-last-child(1) {
            background-color: #2937A6;
        }

        button:nth-last-child(2) {
            span {
                color: #595959;
            }
        }

        button:hover {
            background-color: #edf5ff;

            span {
                color: #67a9fd;
            }
        }
    }

    .formstyle {
        .active {
            height: 133px;
            overflow: hidden;
        }

        .iconactive {
            transform: scaleY(-1);
        }
    }

    .addbtn {
        border: 1px dashed #cfcfcf;
        height: 44px;
        text-align: center;
        border-radius: 4px;
        line-height: 44px;
        font-size: 14px;
        color: #828282;
        cursor: pointer;

        i {
            margin-right: 6px;
        }
    }

    .yfdsize {
        text-indent: 0px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        margin-bottom: 29px;
    }

    .upload-contentr {
        .el-upload-dragger {
            line-height: 26px;
        }
    }

    .pt16 {
        position: relative;
        top: -16px;
    }

    .inlinelv {
        .firlabwid {
            width: calc(25% + 80px);
        }

        .el-form-item {
            width: calc(100% - 20px);
        }

        .perwidth {
            width: 20px;
            padding-left: 0 !important;
            position: relative;
            top: 15px;
        }

        .nolabwid {
            width: calc(25% - 80px);

            .el-form-item__content {
                margin-left: 0 !important;
            }

            .el-form-item__label {
                width: 0 !important;
            }
        }
    }

    .textindl {
        text-indent: 160px;
    }

    .fr {
        float: right;
    }

    // thead {
    //     .el-table-column--selection {
    //       .cell {
    //         display: none;
    //       }
    //     }
    //   }
    .otherfileform {
        .el-upload-dragger {
            width: 236px;
            height: 92px;
            padding-top: 14px;
        }

        .el-form-item__label {
            font-size: 16px;
            color: #000;
        }

        .uploadline {
            margin-bottom: 20px;
        }
    }


</style>
