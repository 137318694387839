<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <abssubstitutiondetail ref='substitution' :datalist='datalist' :moneyfix='moneyfix' :planCorpType='planCorpType' v-if="datalist.length&&planCorpType"></abssubstitutiondetail>
        </div>
     <div class="card-hd card-bottom" >
        <el-row >
          <el-col class="lb_body btnmar">
            <el-button class="card-btn"  size="small" @click="goBack">返回</el-button>
            <el-button class="card-btn" size="small" type="primary" @click="subbtnfn">置换完毕提交审核</el-button>

          </el-col>

        </el-row>
      </div>

        </layout-main-view>

    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  abssubstitutiondetail from '@/views/absbase/abssubstitutiondetail' 


 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划简介'],
                datalist:[],
                moneyfix:{},
                absPlanDto:{},
                absPlanDetailDto:{},
                planCorpType:''

     
            }
        },
        created() {
             api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
                        this.planCorpType=res.datas.planDetail.planCorpType
    
                }else{
                    this.$message.error(res.message)
                }
            });
               api.getReplacementDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                
                        this.datalist=res.datas.list
                        this.moneyfix=res.datas

   
                }else{
                    this.$message.error(res.message)

                }
            })
        },
        methods: {
                goBack(){
                    history.go(-1)
                },
                subbtnfn(){
                    if(!this.$refs.substitution.isadd){
                            this.$message.error('请替换资产')
                    }else{
                        if(!this.$refs.substitution.arrlist.length){
                            this.$message.error('请选择替换的资产')
                            return
                        }
                        this.$refs.substitution.countlist.list = [];
                        this.$refs.substitution.arrlist.map(el=>{
                              this.$refs.substitution.countlist.list.push(el.absAccountId)
                        })

                        console.log(this.$refs.substitution.countlist.list)
                    
                         api.submitReplacementAccount(this.$refs.substitution.countlist).then(res =>{ //审批结果查询
                                if(res.success) {
                                
                                         this.$alert('替换成功', '', {
                                                confirmButtonText: '确定',
                                                callback: action => {
                                                        history.go(-1);
                                                }
                                            });

                
                                }else{
                                    this.$message.error(res.message)

                                }
                            })
                    }
                    console.log(this.$refs.substitution)
                }
        },
        components: {
           absaccountxmgk,
           abssubstitutiondetail

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }

   }
</style>