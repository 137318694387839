<!-- 票据详情 -->
<template>
    <div class="bill-unapprovaled-details">
        <layout-main-view :breadcrumb='breadcrumblist'>
            <bill-details v-if="!success" :detailsList='detailsList'></bill-details>
            <div v-if="!success" class="footer-btn">
                <el-button @click="$router.go(-1);">返回</el-button>
                <el-button @click="refuse">退回</el-button>
                <el-button type="primary" @click="agree">同意</el-button>
            </div>
            <el-dialog
                title="票据发布审批"
                :visible.sync="approvalVisible"
                width="50%"
                >
                <div class="approval-body">
                    <el-form ref="approvalForm" :model="approvalForm" label-width="120px" label-position="left">
                        <el-form-item v-if="approvalForm.approveResult == 2" label="退回原因">
                            <el-select 
                                v-model="approvalForm.reason" 
                                placeholder="请选择退回原因">
                                <el-option
                                    v-for="(item,index) in regionList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item v-if="approvalForm.approveResult == 2" label="填写拒绝原因">
                            <el-input type="textarea" v-model="approvalForm.refuseReason"></el-input>
                        </el-form-item> -->
                        <el-form-item label="备注信息">
                            <el-input type="textarea" v-model="approvalForm.remark"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="approvalVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sure">确 定</el-button>
                </span>
            </el-dialog>
            <div v-if="success" class="success">
                <el-steps :space="200" :active="1" finish-status="success" align-center>
                    <el-step :title="approvalDeatilsList.approveNodeValus"></el-step>
                    <el-step title="平台审批"></el-step>
                </el-steps>
                <div class="center-cont">
                    <div class="approval-people">
                        <span>审批人：</span>
                        <span>{{approvalDeatilsList.operatorName}}</span>
                    </div>
                    <div class="approval-time">
                        <span>审批时间：</span>
                        <span>{{approvalDeatilsList.createTime | formatDate}}</span>
                    </div>
                    <div><i class="el-icon-circle-check"></i></div>
                    <p>票据信息审批已通过。</p>
                    <p>等待平台审批。</p>
                    <div>
                        <el-button type="primary" @click="getknow">我知道了</el-button>
                    </div>
                </div>
            </div>
        </layout-main-view>
    </div>
</template>
<script>
    import api from "@/api/api";
    import billDetails from '@/components/billDetails' 
    export default {
        data() {
            return {
                breadcrumblist:['票据审批详情'],
                success: false,
                detailsList: {},
                approvalVisible: false,
                regionList: [],
                approvalForm: {
                    billId: this.$route.query.id,
                    approveResult: '',
                    reason: '',
                    remark: '',
                    approveNode: '',
                },
                active: 0,
                approvalDeatilsList: {}
            }
        },
        created() {
            this.init();
            api.getDicListByType({type: '23'}).then((res) => { // 拒绝原因
                if(res.success) {
                    this.regionList = res.datas.list;
                }
            })
        },
        filters: {
            formatDate: value => {
                return moment(value).format('YYYY-MM-DD');     
            }
        },
        methods: {
            init() {
                let id =  this.$route.query.id
                api.BillDetails({billId: id}).then(res => {
                    if(res.success) {
                        this.detailsList = res.datas.billDto
                        this.approvalForm.approveNode = this.detailsList.billApproveStatus
                        this.detailsList.billFile.forEach((item,index) => {
                            if(item.fileType == 2) {
                                this.detailsList.billFileBack = api.getImgURL(item.fileUrl)
                            }else {
                                this.detailsList.billFileFront = api.getImgURL(item.fileUrl)
                            }
                        })
                        this.detailsList.billFlaw = this.detailsList.billFlaw.split(',');
                        this.detailsList.hideStatus == 1?this.detailsList.hideStatus=false:this.detailsList.hideStatus=true
                    }
                }) 
            },
            refuse() {
                this.approvalVisible = true;
                this.approvalForm.approveResult = 2;
            },
            agree() {
                this.approvalVisible = true;
                this.approvalForm.approveResult = 1;
            },
            sure() {
         
                api.SubmitBillResult(this.approvalForm).then(res => {
                    if(res.success) {
                        this.approvalVisible = false;
                        if(this.approvalForm.approveResult == 1) {
                            api.GetApproveListByBillId({billId: this.$route.query.id}).then(res =>{
                                if(res.success) {
                                    this.approvalDeatilsList = res.datas.billApproveDtos[0]
                                }else {
                                    this.$message.warning(`${res.message}`);
                                }
                           

                            })
                            this.success = true;
                        }else {
                               this.$alert('操作成功，退回重新修改', '', {
                                    confirmButtonText: '确定',
                                    callback: action => {
                                          this.$router.push('/bill/bill-manage/1');
                                    }
                                });
                            
                        
                        }
                    }
                })
            },
            getknow() {
                this.$router.push('/bill/bill-manage/2');
            }
        },
        components: {
            'bill-details': billDetails
        }
    }
</script>
<style lang="scss">
    .bill-unapprovaled-details {
        .footer-btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
                &+.el-button {
                    margin-left: 40px;
                }
            }
        }
        .success {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 100px 0;
            .center-cont {
                text-align: center;
                .approval-people,.approval-time {
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 15px;
                }
            }
            background: #fff;
            .el-steps {
                justify-content: center;
                .el-step__title {
                    &.is-success {
                        color: #2937A6;
                    }
                }
            }
            .el-icon-circle-check{
                font-size: 100px;
                color: #09BB07;
            }
            p {
                font-size: 20px;
                font-weight: 600;
                margin: 20px 0;
            }
            .el-button {
                width: 120px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin-top: 30px;
                text-align: center;
                &+.el-button {
                    margin-left: 50px;
                }
            }
        }
    }
</style>