<template>
    <layout-main-view :breadcrumb="breadcrumblist">
    <div class="content easypay-manager">
        <el-button type="primary" size="small" id="backBtn" @click="backBtn">返回</el-button>
        <div class="content-inner">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="tab-box">
                    <el-tab-pane label="企业客户详情" name="first">
                        <!-- 这里是查看 企业资料详情内容。 -->
                        <div class="card-hd sub-title">
                            <span class="txbstTitle" >注册信息</span>
                        </div>
                        <userLogonMsg :msg="corpDatial"></userLogonMsg>
                        
                        <div class="card-hd sub-title bussinessTitle">
                            <span class="txbstTitle">企业工商信息</span>
                        </div>
                        <businessIndustry :msg="corpDatial" :fileList="fileUrlByCorpList"></businessIndustry>

                        
                        <div v-if="corpDatial.registerType == 1" class="card-hd sub-title bussinessTitle">
                            <span class="txbstTitle">企业法人信息</span>
                        </div>
                        <businessLegal v-if="corpDatial.registerType == 1" :msg="corpDatial" :fileList="fileUrlByCorpList"></businessLegal>


                        
                        <div v-if="corpDatial.registerType == 2" class="card-hd sub-title bussinessTitle">
                            <span class="txbstTitle">企业经办人信息</span>
                        </div>
                        <businessOperator v-if="corpDatial.registerType == 2" :msg="corpDatial" :fileList="fileUrlByCorpList"></businessOperator>
                        


                        <!-- <div class="card-hd sub-title bussinessTitle">
                            <span class="txbstTitle">企业帐户信息</span>
                        </div>
                        <businessAccount :msg="corpDatial"></businessAccount> -->

                    </el-tab-pane>
                    <el-tab-pane class="twoEltabpane" label="企业认证信息" name="second">
                        <div>认证审核时间：{{moment(createTime).format('YYYY-MM-DD HH:mm')}}</div>
                        <div>
                          <span class="photo-title">营业执照：</span>
                          <img class="photo-box" v-for="(item, index) in imgList1" :key="index" :src="getImgURL(item.fileUrl)" preview="imgList" :preview-text="item.fileName"/>
                        </div>
                        <div v-if="corpDatial.registerType == 1">
                          <span class="photo-title">法人身份证：</span>
                          <img class="photo-box" v-for="(item, index) in imgList2" :key="index" :src="getImgURL(item.fileUrl)" preview="imgList" :preview-text="item.fileName"/>
                        </div>
                        <div v-if="corpDatial.registerType == 2">
                          <span class="photo-title">经办人身份证：</span>
                          <img class="photo-box" v-for="(item, index) in imgList3" :key="index" :src="getImgURL(item.fileUrl)" preview="imgList" :preview-text="item.fileName"/>
                        </div>
                    </el-tab-pane>
            </el-tabs>
   

        </div>
    </div>
    </layout-main-view>
</template>

<script>
import userLogonMsg from "./components/userLogonMsg.vue"; //注册信息
import businessIndustry from "./components/businessIndustry";   //企业工商信息
import businessLegal from './components/businessLegal';     //企业法人信息
import businessOperator from './components/businessOperator';   //经办人信息
import businessAccount from './components/businessAccount';     //企业帐户信息
import moment from 'moment'
import api from '@/api/api';

export default {
    data(){
        return{
            breadcrumblist: ["企业客户详情"],
            activeName: this.$route.query.active || 'first',


            createTime: '',
            imgList1: [],
            imgList2: [],
            imgList3: [],

            corpDatial:{},
            fileUrlByCorpList:[],
        }
        
    },
    components:{
        userLogonMsg,businessIndustry,businessLegal,businessOperator,businessAccount
    },
    created(){
      this.getEnterpriseAccountInformatio();
      this.getTrialDetail(this.$route.query.corpId)
    },
    methods:{
      moment:moment,
      handleClick() {},
      backBtn(){
        console.log("你好，后退了么")
        this.$router.go(-1);
      },
      getEnterpriseAccountInformatio(){
          // this.$route.query.corpId
          let para = {
              corpId:this.$route.query.corpId
          }
          api.getCorpTrialDetail(para).then((res)=>{
              console.log(res);
              if(res.success){
                  this.corpDatial = res.datas.corpDetailList[0];

                  this.fileUrlByCorpList = res.datas.fileUrlByCorpList;

                  this.imgList1 = this.fileUrlByCorpList.filter(v => +v.fileCode == 6)
                  this.imgList2 = this.fileUrlByCorpList.filter(v => +v.fileCode == 2 || +v.fileCode == 3)
                  this.imgList3 = this.fileUrlByCorpList.filter(v => +v.fileCode == 4 || +v.fileCode == 5)

                  //该函数触发， 将图片全屏变大展示的效果。
                  this.$previewRefresh()

              }else{

              }
          })
      },
      getImgURL(url) {
        return api.getImgURL(url)
      },


      getTrialDetail(corpId) {
        api.getTrialDetail({corpId}).then(res => {
          if(res.success) {
            this.createTime = res.datas.createTime
          }
        })
      },

    }
}
</script>

<style lang='scss' >
@import "../../styles/moreDetail/moudleDetail.scss";

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    font-weight:800;
    padding: 0;
    .txbstTitle{
        position: relative;
        left: 20px;
    }
    .txbst {
      position: relative;
      left: 100px;
    }
  }
  .bussinessTitle{
      margin: 20px 0px 0px 0px;
  }


  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .row {
    height: 40px;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
    .companyName {
    .el-form-item__label{
      width: 80px !important;
    }
    .el-form-item__content {
      margin-left: 80px !important;
    }
  }
}
.upload-list {
  .el-upload-list {
    display: none;
  }
}


.twoEltabpane div{
  padding: 10px 0px 10px 30px;
}

.photo-title {
  display: inline-block;
  width: 110px;
}
.photo-box {
  width: 200px;
  margin-top: 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;
}

#backBtn{
    position: absolute;
    right: 50px;
    z-index: 1000
}
</style>