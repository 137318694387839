import { render, staticRenderFns } from "./stayMantching.vue?vue&type=template&id=d062540e"
import script from "./stayMantching.vue?vue&type=script&lang=js"
export * from "./stayMantching.vue?vue&type=script&lang=js"
import style0 from "./stayMantching.vue?vue&type=style&index=0&id=d062540e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports