<!--用户账户-->
<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList address-management">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist"
        >
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%;"
          >
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="subNo" label="结算渠道账号" min-width="200"></el-table-column>
            <el-table-column prop="accountType" label="结算渠道" min-width="120" :formatter="statusfn"></el-table-column>
            <el-table-column prop="bkAccAccNo" label="已绑定银行卡" min-width="260"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="170">
              <template slot-scope="scope">{{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm'): ''}}</template>
            </el-table-column>
            <!-- <el-table-column prop="state" label="状态" min-width="100">
              <template slot-scope="scope">{{scope.row.state  == 'A' ? '正常' : '不可用'}}</template>
            </el-table-column> -->
            <el-table-column label="操作" min-width="110">
              <template slot-scope="scope">
                <el-button  
                    type="text" 
                    class="clear-button"
                    @click="handleDetail(scope.row)">
                    账户管理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
    </div>
  </layout-main-view>
</template>
<script>
  import api from "@/api/api.js";
  import moment from 'moment'

  export default {
    data() {
      return {
        breadcrumblist: ['资金账户管理'],
        activeName: "1",
        total: 0,
        listLoading: false,
        listData: [],
        pageNo: 1,
        pageSize: 10,
        filters: '',
        toDetail: this.$route.query.toDetail, // 1 跳到详情页
        openAccountId: this.$route.query.openAccountId, // 1 跳到详情页
        accountType: this.$route.query.accountType, // 1 跳到详情页
      };
    },
    computed: {
        isCoreCompany() {
            return JSON.parse(sessionStorage.getItem('user')).corpType === 2
        }
    },
    created() {
      if(this.toDetail == 1) {
        this.$router.push({name: '渠道账户详情', query: {openAccountId: this.openAccountId, accountType: this.accountType}})
      }
    },
    methods: {
      moment: moment,
      headerStyle({row, rowIndex}) {
        return {background: "#FAFAFA"};
      },
      handleRefresh(filters) {
        this.filters = filters
        this.getLists(filters);
      },
      getLists(param) {
        param.corpId = JSON.parse(sessionStorage.user).relationCorpId
        api.channelAccountManagement(param).then(res => {
          if (res.success) {
            this.listData = res.datas.list;
            this.total = res.datas.total;
          }
          this.listLoading = false;
        });
      },
      statusfn(val){
        let state=''
        switch (val.accountType) {
          case 1:
            state='中金支付'
            break;
          case 2:
            state='上海银行'
            break;
        }
        return state
      },
      handleDetail(row) {
        this.$router.push({
          path: "/Business/accountManagement/detail",
          query: {
            openAccountId: row.openAccountId,
            accountType: row.accountType,
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "../../styles/moreDetail/moudleDetail.scss";
</style>
