<template>
  <div class="common-page-dataList">
    <!-- 可用额度页面。 -->
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="grantCreditNo" label="授信编号" min-width="140"></el-table-column>
          <el-table-column prop="grantCorpName" label="企业名称" min-width="210" />
          <!-- <el-table-column prop="payableBillAmount" label="优企通金额(元)" min-width="170" align='right'></el-table-column> -->
          <el-table-column prop="availableAmount" label="授信金额(元)" min-width="140">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.availableAmount)}}</template>
          </el-table-column>
          <el-table-column label="授信期限" min-width="220">
            <template
              slot-scope="scope"
            >{{moment(scope.row.effectiveTime).format('YYYY-MM-DD')}} ~ {{moment(scope.row.closeDate).format('YYYY-MM-DD')}}</template>
          </el-table-column>

          <el-table-column prop="financeYearInterest" label="融资利率(%/年)" min-width="120">
            <template slot-scope="scope">{{scope.row.financeYearInterest}}%</template>
          </el-table-column>
          <el-table-column prop="usedAmount" label="用信额度(元)" min-width="140">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.usedAmount)}}</template>
          </el-table-column>
          <el-table-column prop="remainAmount" label="可用额度（元）" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.remainAmount)}}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="170" fixed="right">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handShowInterest(scope.row)">利率调整</el-button>

              <el-button size="medium" type="text" @click="handShow(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog title="利率调整设置" :visible.sync="changeInterestDialog" width="800px">
      <el-form
        :model="interestForm"
        ref="interestForm"
        label-width="180px"
        class="demo-ruleForm"
        :rules="rules"
        label-position="left"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="预估融资利率(%/年化):">{{financeYearInterest }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="新预估融资利率(%/年化):" prop="financeYearInterestNew">
              <el-input v-model="interestForm.financeYearInterestNew" placeholder="请输入融资利率"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" label-width="65px">
              <el-input type="textarea" v-model="interestForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p style="color:red; text-align:left">注:确认调整后,预估融资利率即可生效</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changeInterestDialog = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="changeInterestFn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api';
import moment from 'moment';

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      optionsList: [],

      total: 0,
      activeName: '1',

      listData: [],
      listLoading: false,
      changeInterestDialog: false,
      financeYearInterest: '',
      interestForm: {
        financeYearInterestNew: '',
        grantCreditId: '',
        remark: ''
      },
      rules: {
        financeYearInterestNew: [
          { required: true, message: '请输入融资利率', trigger: 'blur' },
          {
            pattern: /(^([1-9]{1}\d*)(\.\d{1,2})?$)|(^0\.\d{1,2}?$)/,
            message: '请输入大于0,两位小数',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    message() {
      return this.optionsList.map((el, index) => {
        return { label: el.corpName, value: el.corpId };
      });
    },

    filterList() {
      return [
        {
          label: '授信企业:',
          type: 'selectSearch',
          field: 'grandCorpId',
          size: 'small',
          options: this.message
        },
        {
          label: '授信期限',
          type: 'daterange',
          field: ['startTime', 'endTime'],
          size: 'small'
        }
      ];
    }
  },

  props: {
    // optionsList:{},
  },
  created() {
    this.getCorpList();
  },

  methods: {
    getCorpList() {
      let para = {
        tableType: 1
      };
      api.getFundList(para).then((res) => {
        if (res.success) {
          this.optionsList = res.datas.list;
          console.log(this.optionsList, 'optionsList');
        }
      });
    },
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    getLists(param) {
      let data = Object.assign(
        {
          tableType: 1
        },
        param
      );

      api.getFundListByPage(data).then((res) => {
        this.listLoading = true;
        console.log(res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          this.listLoading = false;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          });
        }
      });
    },

    handShow(row) {
        if (row.grantCheck == 10 || row.grantCheck == 26) {
        this.$router.push({
          path: "/fund/credit/add",
          query: {
            grantCheck:row.grantCheck,
            grantCreditId: row.grantCreditId,
            row,
          },
        });
      } else {
        this.$router.push({
          path: "/fund/credit/add/detail",
          query: {
            grantCreditId: row.grantCreditId,
          },
        });
      }
    },
    handShowInterest(row) {
      this.changeInterestDialog = true;
      this.interestForm.financeYearInterestNew = '';
      this.interestForm.remark = '';
     

      this.financeYearInterest = row.financeYearInterest;
      this.interestForm.grantCreditId = row.grantCreditId;
    },
    changeInterestFn() {
      if(this.interestForm.financeYearInterestNew>100){
        this.$message.error('请输入小于100最多2位小数')
return
      }
      this.$refs['interestForm'].validate((valid) => {
        if (valid) {
      
          api
            .updateFinanceInterest(this.interestForm)
            .then((res) => {
              this.listLoading = true;
              if (res.success) {
                this.$message.success('操作成功');
                this.listLoading = false;
                this.changeInterestDialog = false;
                this.$router.push({
                  path: this.$route.query.path
                });
              } else {
                this.$message({
                  message: res.message,
                  type: 'error'
                });
              }
            })
            .catch((e) => {
              this.listLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/moreDetail/moudleDetail.scss';
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>