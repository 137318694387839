<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">

      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="200"></el-table-column>
          <el-table-column prop="fundCorpName" label="资金机构" min-width="170"></el-table-column>
          <el-table-column  label="申请日期" min-width="120">
                        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>

          </el-table-column>
          <el-table-column prop="relationReceivedAccountNums" label="应收账款数量" min-width="170"></el-table-column>
           <el-table-column  label="补件信息" min-width="120" >
                        <template slot-scope="scope">{{scope.row.supplyFileSet.length>0?scope.row.supplyFileSet.join(','):'不需要' }}</template>

          </el-table-column>
          <!-- <el-table-column  label="确权状态" min-width="120">
                        <template slot-scope="scope">{{ scope.row.corpAckStatus | corpAckStatusTranlateName }}</template>


          </el-table-column> -->
            <el-table-column  label="审批结果" min-width="120">
              <template slot-scope="scope">
              {{scope.row.approveResult == 1? '通过' : '退回'}}
              </template>
            </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">

              <el-button  size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
     filterList: [
       {
         label: "融资项目编号:",
         type: "input",
         field: "receivableAccountFinanceNo",
         size: "small"
       },
             {
          label: "申请日期:",
          type: "daterange",
          field: ["financeStartTime", "financeEndTime"],
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {

      this.getLists(filters);
    },
    //查询
    getLists(param) {
         switch (JSON.parse(sessionStorage.user).roleCodes[0]) {
        case 'fund_admin':
           param.checkLevel=1;
          break;
           case 'fund_first':
           param.checkLevel=1;
          break;
             case 'fund_second':
           param.checkLevel=2;
          break;
             case 'fund_third':
           param.checkLevel=3;
          break;
                  default:
           param.checkLevel=1;

          break;
      }
      param.review = 2;



      api.getFinanceSupplyFileListPage(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      this.$router.push({
        name: "待补件审核详情",
        query: {id: row.receivableAccountFinanceId,approveNode:row.financeSupplyApproveStatus,isshowbtn:2,showresult:2,receinfo:JSON.stringify(row)
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
