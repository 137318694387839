<!-- 票据详情 -->
<template>
    <div class="abs-details">
        <div class="abs-form-box">
            <absrefuse v-if="$route.query.isshowreason==1" :backinfo='backinfo'></absrefuse>
            <absxmgk :detailsList='absPlanDto' :corp='corp'></absxmgk>
            <div class="abs-title">项目计划详细信息: </div>
        
            <el-row >
      
                <el-col :span="8">
                    <span class="label">资产类型:</span>
                    <span class="value">{{absPlanDetailDto.assetsType}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">底层资产分类:</span>
                    <span class="value">{{absPlanDetailDto.underAssetsClassify}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">基础资产分类:</span>
                    <span class="value">{{absPlanDetailDto.baseAssetsClassify}}</span>
                </el-col>
          
           
            </el-row>
            <el-row>
                 <el-col :span="8">
                    <span class="label">资产支持证券面值:</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.assetsSupportBondAmount)}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">资产支持证券类别:</span>
             <span class="value">{{assetsSupportBondTypefn(absPlanDetailDto.assetsSupportBondType)}}</span>
                </el-col>
            </el-row>
               <el-row >
      
                <el-col :span="8">
                    <span class="label">优先级评级:</span>
                    <span class="value">{{absPlanDetailDto.priorityLevel}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">次优级评级:</span>
                    <span class="value">{{absPlanDetailDto.subPriorityLevel}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">劣后级评级:</span>
                    <span class="value">{{absPlanDetailDto.badPriorityLevel}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">优先级募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.priorityLevelScale)}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">次优级募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.subPriorityLevelScale)}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">劣后级募集规模(元):</span>
                    <span class="value">{{$changeMoneyThousand(absPlanDetailDto.badPriorityLevelScale)}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">原始权益人:</span>
                    <span class="value">{{absPlanDetailDto.oriProfitPeople}}</span>
                </el-col>
                   <el-col :span="8">
                    <span class="label">计划管理人:</span>
                    <span class="value">{{absPlanDetailDto.planManagerPeople}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">托管人:</span>
                    <span class="value">{{absPlanDetailDto.trustee}}</span>
                </el-col>
          
           
            </el-row>
               <el-row>
                 <el-col :span="8">
                    <span class="label">主承销商:</span>
                    <span class="value">{{absPlanDetailDto.leadUnderwriter}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">交易场所:</span>
                    <span class="value">{{absPlanDetailDto.tradingPlace}}</span>
                </el-col>
            </el-row>
              <el-row>
                 <el-col :span="8">
                    <span class="label">评级机构:</span>
                    <span class="value">{{absPlanDetailDto.ratingAgencies}}</span>
                </el-col>
                 <el-col :span="8">
                    <span class="label">监管机构:</span>
                    <span class="value">{{absPlanDetailDto.regulator}}</span>
                </el-col>
            </el-row>
    
              <el-row>
                 <el-col :span="24">
                    <span class="label">专项计划推广对象:</span>
                    <span class="value">{{absPlanDetailDto.programPromotionTarget}}</span>
                </el-col>
             
            </el-row>
            <div v-if="isbl">
            <div class="abs-title" >资产实际收集人: </div>
                    <el-row class="active">
                        <el-col :span="24">
                      
                     <span class="label" style="float:left;width:120px;text-indent:1em;" >供应商名称:</span>          
                         
                               <div  style="margin-left:120px" >
                                    <span class="value"  v-for="item in  actualCollectSupplierCorpIds" :key="item.corpId">{{item.corpName}}</span>
                               </div>
                           
                        </el-col>
                    </el-row>
  <el-row class="active">
                        <el-col :span="24">
                      
                     <span class="label" style="float:left;width:120px;text-indent:1em;" >核心企业名称:</span>          
                         
                               <div  style="margin-left:120px" >
                                    <span class="value"  v-for="item in actualCollectCoreCorpIds" :key="item.corpId">{{item.corpName}}</span>
                               </div>
                           
                        </el-col>
                    </el-row>
            </div>
            <div class="abs-title">增信信息: </div>
                <el-row>
                    <el-col :span="24">
                        <span class="label">内部增信</span>
       
                    </el-col>
                    <el-col :span="8">
                        <span class="label">超额覆盖率(%):</span>
                        <span class="value">{{absPlanDetailDto.excessCoverage}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="label">非次级覆盖率(%):</span>
                        <span class="value">{{absPlanDetailDto.badNoExcessCoverage}}</span>
                    </el-col>
                      <el-col :span="8">
                        <span class="label">保证金(元):</span>
                        <span class="value">{{$changeMoneyThousand(absPlanDetailDto.earnestMoney)}}</span>
                    </el-col>
                      <el-col :span="24">
                        <span class="label">外部增信</span>

                    </el-col>
                        <el-col :span="8">
                        <span class="label">赎回(元):</span>
                        <span class="value">{{$changeMoneyThousand(absPlanDetailDto.redeemMoney)}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="label">差额支付(元):</span>
                        <span class="value">{{$changeMoneyThousand(absPlanDetailDto.deficiencyPaymentMoney)}}</span>
                       
                    </el-col>
                    <el-col :span="8">
                        <span class="label">第三方担保(元):</span>
                        <span class="value">{{$changeMoneyThousand(absPlanDetailDto.thirdPartyGuaranteeMoney)}}</span>
                      
                    </el-col>
                        <el-col :span="8">
                        <span class="label">流动性支持(元):</span>
                        <span class="value">{{$changeMoneyThousand(absPlanDetailDto.liquiditySupportMoney)}}</span>
                                    
                    </el-col>
                </el-row>
            <div class="abs-title">资产审核信息: </div>
             <el-row>
                    <el-col :span="8">
                        <span class="label">资料平台审核:</span>
                        <span class="value">{{absPlanDto.platformCheck==1?'是':'否'}}</span>
                    </el-col>
            
                </el-row>
        
        </div>
    </div>
</template>
<script>
    import moment from 'moment'

    import  absxmgk from '@/views/absbase/absxmgk' 
    import  absrefuse from '@/views/absbase/absrefuse' 




    export default {
           components: {
                absxmgk,
                absrefuse
            
        },
        props: {
            absPlanDto:{
                type: Object,
                default() {
                    return {
                    }
                }
            },
           absPlanDetailDto:{
                type: Object,
                default() {
                    return {
              
                    }
                }
            },
               actualCollectCoreCorpIds:{
                type: Array,
                default() {
                    return []
                }
            },
               actualCollectSupplierCorpIds:{
                type: Array,
                default() {
                    return []
                }
            },
            backinfo:{
                type:Object,
                 default() {
                    return {
                    }
                }

            },
             corp:{
                type:Object,
                 default() {
                    return {
                       corpName:'' 
                    }
                }

            },
    

            
            
        },
        watch:{
  

        },
        data() {
            return {
                 tableData:[],
                 isbl:false
            }
        },
        mounted() {
 
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false

 
     
        },
        methods: {
            moment: moment,
            assetsSupportBondTypefn(data){
                if(data){
                    return data.replace(1,'优先级').replace(2,'次优级').replace(3,'劣后级')
                }else{
                    return '无'
                }
            }
      
        }
    }
</script>
<style lang="scss">
  .abs-details{
      background: #ffffff;
      .abs-form-box{
          padding: 0 20px;
          .el-row{
              text-indent: 1em;
              &.active{
                  text-indent: 0;
                div  div span{
                      display: inline-block;
                      margin-right: 10px;
                  }
              }
              .el-col{
                  margin-bottom: 14px;
              }
          }

        .abs-title{
            position: relative;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 12px;
            margin-bottom: 24px;
            &:before{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -7px;
                width: 3px;
                height: 14px;
                background: #CB8E51;
            }
        }
      }
  
  }
  
</style>