<template>
  <div class="content easypay-manager">
    <div class="content-inner">
      <section class="info-card-A" style="height: calc(100vh - 150px)">
        <div class="card-number">
          <span class="card-number-title">融资优企通编号:</span>
          <span class="card-number-value">{{
            payableBillFinance.financePayableBillNo
          }}</span>
          <el-button
            class="card-number-btn"
            type="primary"
            size="small"
            icon="el-icon-zoom-in"
            @click="preview"
            >预览</el-button
          >
        </div>
        <div class="card-hd sub-title">
          <span class="txbst">基本信息</span>
        </div>
        <div class="card-bd card-bd-row">
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="150px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资企业:</span>
                <span class="info-value index">{{
                  payableBillFinance.financeCorpName
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">优企通金额（元）:</span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.amount)
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">金额大写:</span>
                <span class="info-value index">{{
                  convertCurrency(payableBillFinance.amount)
                }}</span>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">承诺付款日:</span>
                <span class="info-value index">{{
                  financInfo.promisePayTime | formatDate
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资利率:</span>
                <span class="info-value index"
                  >{{ payableBillFinance.financeYearInterest }}％</span
                >
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资金额（元）:</span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.amount)
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">金额大写:</span>
                <span class="info-value index">{{
                  convertCurrency(payableBillFinance.amount)
                }}</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="card-line"></div>
        <div class="card-hd sub-title">
          <span class="txbst">合同信息</span>
        </div>
        <div class="httotal" style="margin-top: 20px">
          已添加合同
          <span>{{
            financInfo.financInfoContract &&
            financInfo.financInfoContract.length
          }}</span>
          笔，合同总计金额
          <span>{{ $changeMoneyThousand(financInfo.contractAmount) }}</span>
          元。
        </div>
        <div
          class="maincontin"
          v-for="(item, index) in financInfo.financInfoContract"
          :key="index"
        >
          <span class="mainht">
            <span class="info-key">合同编号:</span>
            <span class="info-value">{{ item.contractNo }}</span>
            <a
              class="page-head-back downsize"
              style="color: #2937a6"
              size="small"
              :href="api.filesdownLoadFile(item)"
              >一键下载</a
            >
          </span>
          <div class="htmsg1">
            <el-row>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同编号:</span>
                <span class="info-value">{{ item.contractNo }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同名称:</span>
                <span class="info-value">{{ item.contractName }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同金额:</span>
                <span class="info-value"
                  >{{ $changeMoneyThousand(item.contractAmount) }}元</span
                >
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同签署时间:</span>
                <span class="info-value">{{
                  item.contractTime
                    ? moment(item.contractTime).format('YYYY-MM-DD')
                    : '--'
                }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="card-hd sub-title" style="margin-bottom: 24px">
            相关合同
          </div>
          <div
            class="card-bd marrimg"
            style="padding-left: 30px; margin-top: -20px"
          >
            <transition
              name="fade"
              v-if="
                item.payableBillContractList &&
                item.payableBillContractList.length &&
                item.payableBillContractList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillContractList"></picfile>
              </el-row>
            </transition>
            <div>
              <div>
                共有文件:
                <span class="numcolor">{{ item.htlist.length }}</span> 个
              </div>
            </div>
          </div>
          <div class="card-hd sub-title">相关发票</div>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <transition
              name="fade"
              v-if="item.fplist && item.fplist.length && item.fplist.length > 0"
            >
              <el-row>
                <picfile :picfilelist="item.fplist"></picfile>
              </el-row>
            </transition>
            <div style="padding: 0px 0px 30px 0px">
              共有文件:
              <span class="numcolor">{{ item.fplist.length }}</span> 个
              <span style="margin-left: 32px">累计发票金额：</span>
              <span class="numcolor">{{
                $changeMoneyThousand(item.invoiceAllAmount)
              }}</span>
              元
            </div>
            <div>
              <el-table
                :data="item.payableBillInvoiceList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                  prop="invoiceBuyer"
                  label="购买方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceBuyer }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceSeller"
                  label="销售方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceSellerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceSeller }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCode"
                  label="发票代码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceNo"
                  label="发票号码"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceNo }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCheckCode"
                  label="校验码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCheckCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCheckCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceOpenTime"
                  label="开票日期"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceOpenTimeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceOpenTime }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  label="开具金额(元)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmount)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmountWithoutTax"
                  label="开具金额(不含税)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountWithoutTaxChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmountWithoutTax)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-hd sub-title">其他附件</div>
          <div
            class="card-bd marrimg"
            style="padding-bottom: 51px; padding-left: 30px"
          >
            <transition
              name="fade"
              v-if="
                item.payableBillWuliuList &&
                item.payableBillWuliuList.length &&
                item.payableBillWuliuList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillWuliuList"></picfile>
              </el-row>
            </transition>
            <div>
              共有文件:
              <span class="numcolor">{{
                item.wuliulist.length + item.banklist.length
              }}</span>
              个
            </div>
          </div>
        </div>
        <div class="maincontin">
          <span class="mainht">
            <span class="info-key">融资补件资料</span>
          </span>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <!-- <span class="info-key">请根据资金需求补充完善融资资料:</span> -->
            <div>
              <el-table
                :data="modelList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  prop="informationName"
                  label="资料名称"
                  min-width="170"
                ></el-table-column>
                <el-table-column
                  prop="claimRemark"
                  label="说明备注"
                  min-width="200"
                ></el-table-column>
                <el-table-column prop="fileName" label="附件" min-width="120">
                  <template slot-scope="scope">
                    <img
                      v-if="scope.row.fileType == '.jpg,.png,.jpeg,.bmg'"
                      :src="api.getImgURL(scope.row.fileUrl)"
                      alt="图片丢失"
                      width="50%"
                      height="50%"
                      :preview-text="scope.row.fileName"
                      :preview="1"
                    />
                    <div v-else>
                      <span>{{ scope.row.fileName }}</span>
                      <el-button type="text" @click="download(scope.row)"
                        >点击下载</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </section>
      <div class="card-footer">
        <el-button class="card-btn" type="primary" @click="goBack"
          >返回</el-button
        >
      </div>
    </div>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import pdf from 'vue-pdf';
import picfile from '@/components/picfile';
import api from '@/api/api';
import { convertCurrency } from '@/util/common.js';
function openDownloadDialog(url, saveName) {
  // for ie 10 and later
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  } else {
    // 谷歌浏览器 创建a标签 添加download属性下载
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  components: {
    pdf,
    picfile,
  },
  data() {
    return {
      ruleform: {},
      financInfo: {},
      payableBillFinance: {},
      listLoading: false,
      financInfo: {}, //基本信息
      api: api,
      convertCurrency: convertCurrency,
      payableBillId: this.$route.query.payableBillId,
      payableBillFinanceId: this.$route.query.payableBillFinanceId,
      centerDialogVisible: false,
      src: '',
      modelList: [],
    };
  },
  created() {
    this.getInfo(); //获取基本信息
  },
  mounted() {},
  methods: {
    moment: moment,
    //返回
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    //获取详情
    getInfo() {
      api
        .FinanceDetial({
          payableBillId: this.payableBillId,
          payableBillFinanceId: this.payableBillFinanceId,
        })
        .then((res) => {
          if (res.success) {
            this.financInfo = res.datas.payableBill;
            this.financInfo.financInfoContract = res.datas.payableBillFileList;
            this.payableBillFinance = res.datas.payableBillFinance;

            for (
              let index = 0;
              index < res.datas.informationList.length;
              index++
            ) {
              const element = res.datas.informationList[index];
              for (
                let i = 0;
                i <
                res.datas.payableBillFinance.payableBillFinanceFileList.length;
                i++
              ) {
                const ele =
                  res.datas.payableBillFinance.payableBillFinanceFileList[i];
                if (element.annexCode == ele.fileCode) {
                  var fileTypeList = ele.fileName.split('.');
                  this.modelList.push({
                    informationName: element.informationName,
                    claimRemark: element.claimRemark,
                    fileName: ele.fileName,
                    fileUrl: ele.fileUrl,
                    fileType: _.last(fileTypeList),
                  });
                }
              }
            }
            this.financInfo.financInfoContract.forEach((el) => {
              el.htlist = [];
              el.fplist = [];
              el.otherlist = [];
              el.htlist = el.payableBillContractList;
              el.fplist = el.payableBillInvoiceList;
              el.wuliulist = el.payableBillWuliuList;
              el.banklist = el.payableBillBankList;
            });
          }
        });
    },
    download(row) {
      api
        .filesdownsingle({
          filePath: row.fileUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.fileName);
        })
        .catch((err) => {});
    },
    preview() {
      window.open(api.getImgURL(this.payableBillFinance.evidencePath));
      // api
      //   .DownloadContract({ contractNo: this.payableBillFinance.contractNo })
      //   .then(res => {
      //     var binaryData = [];
      //     binaryData.push(res);
      //     this.src = window.URL.createObjectURL(
      //       new Blob(binaryData, { type: "application/zip" })
      //     );
      //     this.centerDialogVisible = true;
      //   });
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .row {
    height: 40px;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
</style>
