<!-- 发布票据 -->
<template>
 
      

        <div  class="release-success">
            <div><i class="el-icon-circle-check"></i></div>
            <p>挂牌方票据已经下架，审批中止</p>
            <div>
                <el-button type="primary" @click="goBillManage">我知道了</el-button>
  
            </div>
        </div>
 
</template>
<script>
    import api from "@/api/api";
    import upload from "../../api/upload";
    import {convertCurrency} from '@/util/common'
    import billAgreement from "@/components/agreementList/bill-agreement";
    export default {
        data() {
  
            return {
                breadcrumblist:[]
            }
        },
        created() {
                    api.quoteApproveManualEnd({billId:this.$route.query.id})
                             
        },
        methods: {
            goBillManage(){
                        this.$router.push({path:'/bill/billmanagezj/5',query:{pageNum:1,pageSize:10}})   
            }
      
        },
        components: {
            billAgreement,
        }
    }
</script>
<style lang="scss">
    .release-bill {
        background: #fff;
        padding: 33px 25px;
        .upload-box {
            margin-bottom: 40px;
            &-item {
                border: 1px solid #eee;
                background: #fff;
                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 16px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #222222;
                    background: #EBEBEB;
                    .bill-example {
                        display: inline-block;
                        font-size: 12px;
                        color: #2937A6;
                        cursor: pointer;
                    }
                    .hide-payee {
                        margin-left: 24px;
                    }
                }
                &-cont {
                    height: 300px;
                    background: #fafafa;
                    .el-upload {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        .el-icon-plus {
                            font-size: 50px;
                            color: #c0c4cc;
                            margin: 100px 0 10px;
                        }
                        .upload-img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            //max-width: 100%;
                        }
                    }
                }
            }
        }
        .push-setting {
            margin-top: 40px;
            .push-title {
                padding-left: 40px;
            }
        }
        .border {
            padding-bottom: 40px;
            border-bottom: 1px solid #E6E6E6;
            &.bill-form-box {
                padding-bottom: 18px;
            }
        }
        .bill-title {
            position: relative;
            font-size:16px;
            font-weight: 600;
            color:rgba(0,0,0,0.85);
            padding-left: 12px;
            margin-bottom: 24px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -7px;
                width: 3px;
                height: 14px;
                background: #CB8E51;
            }
        }
        .bill {
            &-form-box {
                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    padding: 0 20px;
                    background: #eee;
                }
                .el-form {
                    padding: 20px 0;
                }
                &+.bill-form {
                    margin-top: 20px;
                }
                .connect-line {
                    text-align: center;
                }
                .el-date-editor {
                    width: 100% !important;
                }
                .el-select {
                    width: 100%;
                }
            }
            &-add {
                text-align: center;
                border: 1px solid #eee;
                padding: 10px 20px;
                margin-top: 20px;
                .add-icon {
                    display: inline-block;
                    margin-right: 20px;
                }
                .add-bill-btn {
                    font-size: 18px;
                    color: #6e6e6e;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            &-notice {
                margin: 24px 0;
            }
            &-submit {
                margin-top: 20px;
                padding: 20px;
                text-align: center;
                button {
                    width: 160px;
                    &+button {
                        margin-left: 32px;
                    }
                }
            }
        }
        .el-dialog {
            &__body {
                #codeBox {
                    text-align: center;
                }
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .release-success {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 100px 0;
        text-align: center;
        background: #fff;
        i {
            font-size: 100px;
            color: #09BB07;
        }
        p {
            font-size: 20px;
            font-weight: 600;
            margin: 20px 0;
        }
        .el-button {
            width: 120px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            margin-top: 30px;
            text-align: center;
            &+.el-button {
                margin-left: 50px;
            }
        }
    }
</style>