<template>
  <div class="common-page-dataList">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
                  <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >

          <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
              <el-table-column prop="payableBillNo" label="优企通编号" min-width="220"></el-table-column>
              <el-table-column prop="receivedCorpName" label="接收企业" min-width="170"></el-table-column>
              <el-table-column prop="receivedCorpIndustry" label="所属行业" min-width="150"></el-table-column>
              <el-table-column prop="payableAmount" label="优企通金额(元)" min-width="150" :formatter='el=>changeMoneyThousand(el.payableAmount)'></el-table-column>
              <el-table-column prop="createTime" label="开立日" min-width="130">
                  <template
                          slot-scope="scope"
                  >
                      {{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD'): ''}}
                  </template>
              </el-table-column>
              <el-table-column prop="promisePayTime" label="承诺付款日" min-width="130">
                  <template
                          slot-scope="scope"
                  >
                      {{scope.row.promisePayTime ? moment(scope.row.promisePayTime).format('YYYY-MM-DD'): ''}}
                  </template>
              </el-table-column>
              <el-table-column prop="approveStatusName" label="审批状态" width="130" align="left"></el-table-column>
              <el-table-column label="操作" width="150" >
            <template slot-scope="scope">
                <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
      </el-tabs>
                        <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import api from "@/api/api";
  import moment from 'moment'
import {changeMoneyThousand} from '@/util/common'

import pdf from "vue-pdf";



export default {
      components: {
    pdf
  },
  data() {
    return {

          filterList: [

              {
                  label: "优企通编号",
                  type: "input",
                  field: "payableBillNo",
                  size: "small"
              },
              {
                  label: "接收企业名称",
                  type: "input",
                  field: "receivedCorpName",
                  size: "small"
              },
              {
                  label: "承诺付款日:",
                  type: "daterange",
                  field: ["promisePayTimeStr", "promisePayTimeEnd"],
                  size: "small"
              },
              {
                  label: "优企通金额: ",
                  type: "inputrange",
                  field: "startAmount",
                  size: "small",
                  isSeparated: 1
              },
              {
                  type: "inputrange",
                  field: "endAmount",
                  size: "small"
              }
      ],
      activeName: "1",


      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      moment:moment,
      changeMoneyThousand:changeMoneyThousand,
      centerDialogVisible:false,
      src:'',

    };
  },
  created() {
    this.activeName = this.$route.params.state;
    sessionStorage.name='优企通'
  },
  methods: {

      headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
   handleRefresh(filters) {
            this.getLists(filters);

    },
    //查询
    getLists(param) {

      api.getFirstTrialResultList(param).then(res => {
        console.log('res ===>',res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(data){
       var isparent=1
        if(data.payableBillNo.indexOf('-')>0){
            isparent=2
        }
      this.$router.push({name:'开立初审详情',query: { id:data.payableBillId,isshowbtn:2,isparent}})
    },
     preview(row) {
      window.open(api.getImgURL(row.evidencePath))
    }
  }
};
</script>

<style lang="scss">

.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
