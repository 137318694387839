<template>
  <div>
  <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick" >
      <stay-financing-approve  v-if="showtab == 1"></stay-financing-approve>
      <already-financing-approve  v-if="showtab == 2"></already-financing-approve>

    <!-- <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="待复核" name="first">
          <div class="common-page-dataList">
            <stay-financing-approve ></stay-financing-approve>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已复核" name="second">
          <div class="common-page-dataList">
             <already-financing-approve ></already-financing-approve>
          </div>
        </el-tab-pane>
      </el-tabs> -->
    </layout-main-view>
  </div>
</template>
<script>
import StayFinancingApprove from './stayFinancingApprove.vue'
import AlreadyFinancingApprove from './alreadyFinancingApprove.vue'

export default {
    components: {
      'stay-financing-approve': StayFinancingApprove,
      'already-financing-approve': AlreadyFinancingApprove
  },
  data() {
    return {
      breadcrumblist: ["融资审批"],
      showtab: 1,
      tabOptions: [
            { label: "待审核", value: "1", path: "/fund/easypayFinancing/financingApprove/second/1" },
            { label: "已审核", value: "2", path: "/fund/easypayFinancing/financingApprove/second/2" },
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
    };
  },
  created() {
  },
  mounted(){
      this.showtab = this.$route.params.state;
      console.log('this.showtab ===>',this.showtab);
  },
  methods: {
      tabclick(val) {
      this.showtab = val;
    },
  }
};
</script>

<style lang="scss">
@import "../../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
