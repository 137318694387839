<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby goback yfdtwo">
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form :model="info" ref="info" label-width="160px" class="demo-ruleForm">
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="债权企业全称: ">
                  <span>{{info.financeCorpName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="社会统一信用代码: ">
                  <span>{{info.creditCode}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <!--<el-col :span="8">-->
                <!--<el-form-item label="申请人: ">-->
                  <!--<span>{{info.financeCorpName}}</span>-->
                <!--</el-form-item>-->
              <!--</el-col>-->
              <el-col :span="8">
                <el-form-item label="申请日期: ">
                  <span>{{info.ackApplyTime ? moment(info.ackApplyTime).format('YYYY-MM-DD'): ''}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="融资资金机构: ">
                  <span>{{info.fundCorpName}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">需确权应收账款</div>
        <div class="httotal">
          应收账款
          <span>{{tableList.length}}</span> 笔，账款总计金额
          <span>{{totalcount|formatMoney}}</span> 元。
        </div>
        <div>
          <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="deptCorpName" label="债务企业" min-width="170"></el-table-column>

            <el-table-column prop="accountSignDate" label="账款金额(元)" min-width="150">
              <template
                slot-scope="scope"
              >{{ scope.row.receivableAccountDto.accountAmount | formatMoney }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="账款起始日期" min-width="120">
              <template
                      slot-scope="scope"
              >{{ scope.row.receivableAccountDto.createTime | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="账款到期日期" min-width="120">
              <template
                slot-scope="scope"
              >{{ scope.row.receivableAccountDto.accountCloseDate | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="上传状态" min-width="120">
              <template slot-scope="scope">{{ scope.row.supplySuccess==1?'未上传':'已上传' }}</template>
            </el-table-column>
            <el-table-column prop="quoteTimeLimit" label="操作" min-width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleNew(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-hd" style="height: 150px;">
          <el-row style="padding-top: 40px;">
            <el-col :span="7" class="lb_body" :offset="10">
              <el-button
                style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;font-size:16px;"
                size="small"
                @click="reject"
              >确定</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="确权资料"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="false"
      width="1200px"
      class="otherfileform"
    >
      <el-form :model="formupload" label-width="120px" ref="addform" class="boltdetial">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="债权企业: ">
              <span>{{info.financeCorpName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账款编号: ">
              <span>{{formupload.receivableAccountNo}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="账款金额(元): ">
              <span>{{formupload.accountAmount|formatMoney}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期日期: ">
              <span>{{formupload.accountCloseDate ? moment(formupload.accountCloseDate).format('YYYY-MM-DD'): ''}}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="确权函: ">
          <div style="overflow:hidden">
            <div v-for="(items,key) in  subsingle.ackFileList" :key="key" class="htleft">
              <img
                :src="api.getImgURL(items.fileUrl)"
                alt
                width="100%"
                height="144px"
                preview-text="凭证"
                :preview="'ht-'+key"
                v-show="/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
              />
            </div>
          </div>
          <!-- <div
            v-for="(items,key) in  subsingle.ackFileList"
            :key="key"
            class="htleft files"
            v-show="!/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
          >
            <div>
              <a :href="api.filesdownsingle({filePath:items.fileUrl})" download>{{items.fileName}}</a>
              <p>
                <i class="fa fa-file-text-o"></i>
              </p>
            </div>
          </div> -->
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog=false">确定</el-button>
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";

export default {
  data() {
    return {
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["已确权详情"],
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {
        financeAmount: 0
      },
      multipleSelection: [], //选中的表格
      moment: moment,
      showAddDialog: false,
      formupload: {},
      Authorization: { Authorization: sessionStorage.token },
      bclick: false,
      uploadfilelist: [],
      subsingle: {
        ackFileList: [],
        receivableAccountFinanceId: this.$route.query.id,
        receivableAccountId: "",
        receivableAccountSupplyFileId: ""
      },
      allupload: {},
      totalcount: 0,
      filelist: [],
      api: api
    };
  },

  created() {
    this.getAckListInfo();
  },
  methods: {
    getAckListInfo() {
      api
        .getAckListInfo({
          receivableAccountFinanceId: this.$route.query.id
        })
        .then(res => {
          if (res.success) {
            var arr = [];
            this.info = res.datas.receivableAccountFinance;
            this.tableList = res.datas.receivableAccountSupplyFileList;
            this.tableList.map(el => {
              arr.push(el.receivableAccountDto.accountAmount);
            });
            this.totalcount = (0).adds(...arr);
          }
        });
    },

    agree() {
      if (
        this.tableList.some(el => {
          return el.supplySuccess == 1;
        })
      ) {
        this.$message.error("请完成所有确权资料上传");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        api
          .coreCorpAck({ receivableAccountFinanceId: this.$route.query.id })
          .then(res => {
            if (res.success) {
              this.$alert("确权成功");
              history.go(-1);
              loading.close();
            } else {
              this.$message.error(res.message);
              loading.close();
            }
          })
          .catch(err => {
            loading.close();
          });
      }
    },
    //拒绝
    reject() {
      this.$router.go(-1);
    },

    corpAckStatusfn(val) {
      var str = "";
      switch (val) {
        case 1:
          str = "初始状态";
          break;
        case 2:
          str = "待确权";
          break;
        case 3:
          str = "已确权";
          break;
        case 4:
          str = "无需确权";
          break;
        case 5:
          str = "部分确权";
          break;
        case 6:
          str = "确权申请审核中";
          break;
      }

      return str;
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.')
      let suffix = file.name.substr(index + 1)
      if (!/jpg|bmp|png|jpeg|zip|rar|pdf/.test(suffix)) {
        this.$message.error("请上传png,jpg,jpeg,pdf格式的文件");
        return false;
      }
    },
    dragupotherfn(response, file, fileList, item, index) {
      //上传的其他附件
      console.log(response, file, fileList, item, index);
      if (response.code == "100000") {
        var data = response.datas.uploadDate.message;
        this.subsingle.ackFileList.push({
          fileUrl: data,
          uid: file.uid,
          fileName: file.name
        });

        console.log(this.subsingle.ackFileList, 99999);
      } else {
        this.$message.error(file.name + "发票识别错误，请重新上传");
      }
    },
    dragremoveotherfn(file, fileList, item, index) {
      //移除其他的附件
      if (
        this.subsingle.ackFileList.findIndex(el => el.uid == file.uid) != -1
      ) {
        this.subsingle.ackFileList.splice(
          this.subsingle.ackFileList.findIndex(el => el.uid == file.uid),
          1
        );
      }
    },
    erruploadfp(err, file) {
      this.$message.error("服务器错误");
    },
    submitAddForm() {
      console.log(this.subsingle, "subsingle");

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      api
        .uploadAckFile(this.subsingle)
        .then(res => {
          if (res.success) {
            this.$message.success('上传成功');
            loading.close();
            this.getAckListInfo();
            this.showAddDialog = false;
          } else {
            this.$message.error(res.message);
            loading.close();
          }
        })
        .catch(err => {
          loading.close();
        });
    },
    handleNew(data) {
      this.subsingle.ackFileList = [];
      this.showAddDialog = true;
      this.formupload = data.receivableAccountDto;
      this.filelist = [];
      console.log(data.accountFileList, 123);

      if (data.accountFileList.length > 0) {
        data.accountFileList.map(item => {
          this.subsingle.ackFileList.push({
            fileUrl: item.fileUrl,
            uid: item.uid,
            fileName: item.fileName
          });
          this.filelist.push({
            url: api.getImgURL(item.fileUrl),
            name: item.fileName,
            uid: item.uid
          });
        });
      }
      console.log(this.subsingle.fileDtoList, 767);
      this.subsingle.receivableAccountId = data.receivableAccountId;
      this.subsingle.receivableAccountSupplyFileId =
        data.receivableAccountSupplyFileId;
    }
  }
};
</script>
<style lang="scss">
.boltdetial {
  .upload-demo.upload-contentr .el-upload-list__item {
    width: 30%;
  }
}
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
.fr {
  float: right;
}

// thead {
//   .el-table-column--selection {
//     .cell {
//       display: none;
//     }
//   }
// }
.otherfileform {
  .el-upload-dragger {
    width: 236px;
    height: 92px;
    padding-top: 14px;
  }
  .el-form-item__label {
    font-size: 16px;
    color: #000;
  }
  .uploadline {
    margin-bottom: 20px;
  }
}
</style>
