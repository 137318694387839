<template>
  <div class="content easypay-manager distingduishing-detail">
    <div class="content-inner">
      <el-tabs v-model="activetab">
        <el-tab-pane label="清分详情" name="first" class="info-card-A">
          <div v-if="isshowbtn==2 || flag == 2">
            <!-- <div class="yfdsize rback">审批结果</div> -->
            <div class="card-bd card-bd-row refuseback" :class="approveInfo.approveResult == 1 ? 'successRes' : 'refuseRes'">
              <el-row>
                <el-col :span="8" class="lb_body">
                  <img v-if="approveInfo.approveResult == 1" src="../../assets/login/rz_cg.png" alt style="width: 24px;margin-left: -27px;  vertical-align: middle;"/>
                  <img v-else src="../../assets/cuowu.png" alt style="width: 24px;margin-left: -27px;  vertical-align: middle;"/>
                  <span class="info-key">审批节点:</span>
                  <span class="info-value index">{{getApproveType(approveInfo.approveType)}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">结果:</span>
                  <span class="info-value index">{{approveInfo.approveResult == 1 ? '确认无误' : '提出异议'}}</span>
                </el-col>
                <el-col :span="8" class="lb_body" v-if="approveInfo.approveResult == 2">
                  <span class="info-key">退回理由:</span>
                  <span class="info-value index">{{approveInfo.reson}}</span>
                </el-col>
              </el-row>
              <el-row style="margin: 20px 0;">
                <el-col :span="8" class="lb_body">
                  <span class="info-key">备注:</span>
                  <span class="info-value index">{{approveInfo.remark}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">审批人:</span>
                  <span class="info-value index">{{approveInfo.approveOperatorName}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">审批时间:</span>
                  <span class="info-value index">{{moment(approveInfo.createTime).format('YYYY-MM-DD HH:mm')}}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="card-hd sub-title">
            <span class="txbst">基本信息</span>
            <!-- <el-button type="text" class="page-head-back" style="color: #2937A6;" size="small" @click="previewEasypay">优企通编号:{{financInfo.payableBillNo}}预览</el-button> -->
          </div>
          <div class="card-bd card-bd-row">
            <el-row>
              <el-col :span="8" class="lb_body">
                <span class="info-key">资金机构:</span>
                <span class="info-value">{{financInfo.fundCorpName}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">授信编号:</span>
                <span class="info-value">{{financInfo.grantCreditNo}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">授信截止日期:</span>
                <span class="info-value">{{moment(financInfo.grantCloseTime).format('YYYY-MM-DD')}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" class="lb_body">
                <span class="info-key">原始优企通额度（元）:</span>
                <span class="info-value">{{$changeMoneyThousand(financInfo.payableBillAmount)}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">承诺付款日:</span>
                <span class="info-value">{{moment(financInfo.promisePayTime).format('YYYY-MM-DD')}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">已融资金额（元）:</span>
                <span class="info-value">{{$changeMoneyThousand(financInfo.payableBillFinanceAmount)}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" class="lb_body">
                <span class="info-key">需清分金额（元）:</span>
                <span class="info-value" v-if="checkType == 1">{{$changeMoneyThousand(financInfo.payableBillClearAmount)}}</span>
                <span class="info-value" v-if="checkType == 2">{{$changeMoneyThousand(financInfo.payableBillFinanceAmount)}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">待清分企业数:</span>
                <span class="info-value">{{financInfo.beClearNum}} 家</span>
              </el-col>
            </el-row>
            <div class="card-line"></div>
          </div>

          <div class="card-hd sub-title">
            <span class="txbst">最终付款明细表</span>
            <el-button class="page-head-back" size="small" @click="exportBillPay">下载明细表</el-button>
          </div>
          <div class="card-bd card-bd-row">
            <el-table :data="datalist" style="width: 100%">
              <el-table-column prop="payableBillNo" label="子优企通号" width="240">
              </el-table-column>
              <el-table-column prop="originalName" label="开单企业" min-width="200"></el-table-column>
              <el-table-column prop="receivedCorpName" label="收款企业" width="200">
              </el-table-column>
              <el-table-column prop="receivedCorpCreditCode" label="社会统一信用代码" width="200">
              </el-table-column>
              <el-table-column prop="bkNo" label="收款账号" min-width="150" >
              </el-table-column>
              <el-table-column prop="bkNm" label="收款银行" min-width="150" >
              </el-table-column>
              <el-table-column prop="accountName" label="收款户名信息" min-width="150" >
              </el-table-column>
              <el-table-column prop="payableBillAmount"  label="优企通金额(元)" min-width="150" >
              </el-table-column>
              <el-table-column prop="payableBillFinanceAmount"  label="已融资总额(元)" min-width="150">
              </el-table-column>
              <el-table-column prop="payableBillClearAmount"  label="待清分总额(元)" min-width="150">
              </el-table-column>
              <!-- <el-table-column v-if="checkType == 1" prop="payableBillClearAmount" align='right' label="待清分总额(元)">
              </el-table-column>
              <el-table-column v-if="checkType == 2" prop="payableBillFinanceAmount" align='right' label="待清分总额(元)">
              </el-table-column> -->
            </el-table>
          </div>

    <div class="card-hd sub-title">
            <span class="txbst">审核记录</span>

          </div>
          <div>
              <el-table :data="tableData2" style="width: 100%">
                <el-table-column type="index" width="100" label="序号" />
    <el-table-column prop="date" label="审批时间" width="180" >
      <template slot-scope="scope">
          {{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD'): ''}}

      </template>

    </el-table-column>
    <el-table-column prop="approveOperatorName" label="审批人" width="180" />
    <el-table-column prop="approveNodeValus" label="审批节点" />
    <el-table-column prop="approveNodeValus" label="审批结果" >
            <template slot-scope="scope">
          {{scope.row.approveResult==1?'确认无误':'拒绝' }}

      </template>
      

    </el-table-column>


    <el-table-column prop="remark" label="备注" />

  </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="流转详情" name="second" class="info-card-A">
          <div class="text-center">
            <vue2-org-tree
              name="test"
              :data="lzdata"
              :horizontal="horizontal"
              :collapsable="collapsable"
              :render-content="renderContent"
              labelWidth="320px"
              labelClassName="label-box"
              @on-node-click="orgclickfn"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="card-footer">
        <el-row>
          <el-col :span='14' class="lb_body" :offset="5" v-if="$route.query.isshowbtn==1">
            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="goback" >返回</el-button>

            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="subbtn" >确认无误</el-button>
          </el-col>
          <el-col :span='8' class="lb_body" :offset="8" v-if="$route.query.isshowbtn==2">
            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="goback" >返回</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    
    <!-- 弹窗 -->
    <el-dialog title='确认无误' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  width='40%'>
      <el-form :model="agreeDetail" label-width="100px"  ref="addform" >
        <el-form-item label="备注：" prop="remark" >
          <el-input v-model="agreeDetail.remark" type="textarea" :rows="6" maxlength="200" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog=false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm('addform')" :disabled="bclick">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提出争议" :visible.sync="showAddDialog2" :close-on-click-modal="false" :show-close="false" width="40%">
      <el-form :model="refuseDetail" label-width="100px" ref="addform">
        <el-form-item label="争议理由：" prop="reson">
          <el-select v-model="refuseDetail.reson" placeholder="请选择">
            <el-option
              v-for="item in refuseList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="refuseDetail.remark" type="textarea" :rows="6" maxlength="200"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2=false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm2('addform')" :disabled="bclick">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="融资列表" :visible.sync="showAddDialog3" :close-on-click-modal="false" :show-close="false" width="1000px">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="payableBillFinanceNo" label="融资项目编号" width="180"></el-table-column>
        <el-table-column prop="financePayableBillNo" label="融资优企通编号" width="180"></el-table-column>
        <el-table-column prop="fundCorpName" label="资金机构" width="180"></el-table-column>
        <el-table-column label="融资金额">
          <template slot-scope="scope">{{scope.row.amount|formatMoney}}</template>
        </el-table-column>
        <el-table-column label="融资完成时间">
          <template slot-scope="scope">{{scope.row.financeTime|formatDate}}</template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddDialog3=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/api";
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  },
  data() {
    return {
      moment: moment,
      financInfo: {},
      datalist: [],
      bclick: false,
      showAddDialog2: false,
      showAddDialog: false,
      checkType: this.$route.query.checkType, // 1持有企业 2资金机构
      agreeDetail: {
        payableBillLiquidationId: this.$route.query.payableBillLiquidationId,
        payableBillNo: this.$route.query.id,
        type: this.$route.query.checkType, // 1持有企业 2资金机构
        typeStr: 3, // 1核心企业审批 2平台审批 3资方审批
        // approveNode: 1,
        approveResult: 1, // 1通过 2不通过
        remark: ""
      },
      refuseDetail: {
        payableBillLiquidationId: this.$route.query.payableBillLiquidationId,
        payableBillNo: this.$route.query.id,
        type: this.$route.query.checkType, // 1持有企业 2资金机构
        typeStr: 3, // 1核心企业审批 2平台审批 3资方审批
        // approveNode: 1,
        approveResult: 2, // 1通过 2不通过
        remark: "",
        reson: ""
      },
      refuseList: [],
      approveStatus: this.$route.query.approveStatus,
      forbidden: false,
      billPayList: [],
      horizontal: false,
      collapsable: false,
      lzdata: {
        payableBillFinanceList:[]
      },
      activetab: "first",
      centerDialogVisible: false,
      src: "",
      showAddDialog3:false,
      tableData:[],
      tableData2:[],
      payableBillLiquidationApproveId: this.$route.query.payableBillLiquidationApproveId,
      approveInfo: {}, // 审批信息
      isshowbtn: this.$route.query.isshowbtn,
      flag: this.$route.query.flag, // 同步审批状态 1确认无误/待审批 2提出异议
    };
  },
  created() {
    this.getRefuselist();
    if(this.isshowbtn == 1) {
      this.getDetail({ payableBillNo: this.$route.query.id, type: this.checkType, flag: this.flag, payableBillLiquidationId: this.$route.query.payableBillLiquidationId });
    } else {
      this.getDetail1({ payableBillNo: this.$route.query.id, type: this.checkType, payableBillLiquidationApproveId: this.payableBillLiquidationApproveId });
    }
    this.circulation()
    if (this.approveStatus == "22") {
      // 已经提出争议
      this.forbidden = true;
    }
  },
  mounted() {
          api.getPayableBillLiquidationApproveList({ payableBillNo: this.$route.query.id ,approveId: this.$route.query.data.liquidationType}).then(res => {
        if (res.code == "100000") {
          this.tableData2 = res.datas.payableBillLiquidationApproveList;
        }
      });
  },
  methods: {
    // 获取争议理由
    getRefuselist() {
      api.getDicListByType({ type: 32 }).then(res => {
        if (res.code == "100000") {
          this.refuseList = res.datas.list;
        }
      });
    },
    // 待审批详情
    getDetail(param) {
      api.getPayableBillLiquidationApproveDetailZj(param).then(res => {
        if (res.code == "100000") {
          this.financInfo = res.datas.single;
          this.datalist = res.datas.list;
          this.approveInfo = res.datas.approveResult
          res.datas.list.forEach(v => {
            this.billPayList.push({
              payableBillNo: v.payableBillNo,
              originalName: v.originalName,
              receivedCorpName: v.receivedCorpName,
              receivedCorpCreditCode: v.receivedCorpCreditCode,
              accountName:v.accountName,
              bkNo:v.bkNo,
              bkNm:v.bkNm,
              payableBillAmount: v.payableBillAmount,
              payableBillFinanceAmount: v.payableBillFinanceAmount,
              payableBillClearAmount: v.payableBillClearAmount,
            })
          })
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 已审批详情
    getDetail1(param) {
      api.getPayableBillLiquidationDetail(param).then(res => {
        if (res.code == "100000") {
          this.financInfo = res.datas.single;
          this.datalist = res.datas.list;
          this.approveInfo = res.datas.approveResult
          res.datas.list.forEach(v => {
            this.billPayList.push({
              payableBillNo: v.payableBillNo,
              originalName: v.originalName,
              receivedCorpName: v.receivedCorpName,
              receivedCorpCreditCode: v.receivedCorpCreditCode,
              accountName:v.accountName,
              bkNo:v.bkNo,
              bkNm:v.bkNm,
              payableBillAmount: v.payableBillAmount,
              payableBillFinanceAmount: v.payableBillFinanceAmount,
              payableBillClearAmount: v.payableBillClearAmount,
            })
          })
        } else {
          this.$message.error(res.message);
        }
      });
    },
    goback() {
      history.go(-1);
    },
    subbtn() {
      this.checkApproveStatus().then(res => {
        this.showAddDialog = true;
        this.bclick = false;
      })
    },
    subbtn2() {
      this.checkApproveStatus().then(res => {
        this.showAddDialog2 = true;
        this.refuseDetail.reson = this.refuseList[0].name;
        this.refuseDetail.remark = "";
        this.bclick = false;
      })
    },
    submitAddForm() {
      if (this.bclick) {
        return;
      }
      this.bclick = true;
      api
        .commitAndApprove(this.agreeDetail)
        .then(res => {
          if (res.code == "100000") {
            this.$alert("提交成功", "", {
              confirmButtonText: "确定",
              callback: action => {
                this.showAddDialog = false;
                history.go(-1);
              }
            });
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch(err => {
          this.bclick = false;
        });
    },
    submitAddForm2() {
      if (this.bclick) {
        return;
      }
      this.bclick = true;
      api
        .commitAndApprove(this.refuseDetail)
        .then(res => {
          if (res.code == "100000") {
            this.$alert("提交成功", "", {
              confirmButtonText: "确定",
              callback: action => {
                this.showAddDialog2 = false;
                history.go(-1);
              }
            });
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch(err => {
          this.bclick = false;
        });
    },
    // 下载明细表
    exportBillPay() {
      api.exportBillPay({billPayList: this.billPayList}).then(res => {
        const content = res
        const blob = new Blob([content])
        const fileName = '对账单.xlsx'
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    circulation() {
      api
        .circulation({ payableBillId: this.$route.query.payableBillId })
        .then(res => {
          if (res.code == "100000") {
            this.lzdata = res.datas.payableBillDetail[0];
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(err => {});
    },
    renderContent(h, data) {
      if (!data.hild) {
        let currentData = "";
        if (this.$route.query.payableBillId == data.payableBillId) {
          currentData = "flow-style active";
        } else {
          currentData = "flow-style";
        }
        let content = [
          h("div", {}, "优企通编号:" + data.payableBillNo),
          h("div", {}, "原始开立方:" + data.originalName),
          data.openCorpName ? h("div", {}, "转让方:" + data.openCorpName) : "",
          h("div", {}, "持有方:" + data.receiveName),
          h(
            "div",
            {},
            "金额：" + this.$changeMoneyThousand(data.payableBillAmount) + " 元"
          ),
          h(
            "div",
            {},
            "承诺付款日：" +
              (data.paymentDate
                ? moment(data.paymentDate).format("YYYY-MM-DD")
                : "")
          ),
          h(
            "div",
            {},
            "是否已融资：" +
              (data.payableBillFinanceList.length > 0 ? "是(点击查看)" : "否")
          )
        ];
        return h("div", { domProps: { className: currentData } }, content);
      } else {
        return "多次流转";
      }
    },
    orgclickfn(val, data) {
      if (data.payableBillFinanceList.length > 0) {
        this.showAddDialog3 = true;
        this.tableData = data.payableBillFinanceList;
      }
    },
    previewEasypay() {
      api
        .DownloadContract({ contractNo: this.financInfo.contractNo })
        .then(res => {
          let binaryData = [];
          binaryData.push(res);
          this.src = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/zip" })
          );
          this.centerDialogVisible = true;
        });
    },
    // 检查同步审批状态
    checkApproveStatus() {
      return new Promise((resolve, reject) => {
        // type 1 持有企业清分 2资金机构清分
        // typeStr 1核心企业审批 2平台审批 3资方审批
        api.checkApproveStatus({payableBillLiquidationId: this.$route.query.payableBillLiquidationId, type: this.checkType, typeStr: 3}).then(res => {
          if(res.datas.data) { // true 可审批 false 不可审批
            resolve()
          } else {
            this.$router.push({name: '清分结算审批有异议', query: {type: this.checkType, payableBillLiquidationId: this.$route.query.payableBillLiquidationId}})
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 审批节点
    getApproveType(type) {
      if(type == 1) {
        return '企业审批'
      } else if(type == 2) {
        return '平台审批'
      } else if(type == 3) {
        return '资方审批'
      }
    }
  }
};
</script>

<style lang='scss' >
@import "../../styles/moreDetail/moudleDetail.scss";

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}

</style>
<style lang='scss' scoped>
.distingduishing-detail {
  .text-center {
    min-height: calc(100vh - 300px);
  }
  .content .info-card-A {
    min-height: calc(100vh - 324px);
  }
  .successRes {
    font-size: 14px;
    padding: 0px; 
    background: #f2fff4;
    border:1px solid #ccffcd;
  }
  .refuseRes {
    font-size: 14px;
    padding: 0px; 
    background: #FFF2F4;
    border:1px solid #FFCCCD;
  }
}
.lb_body {
  .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
    color: #fff !important;
    border-color: #ebeef5 !important;
    background-color: #ebeef5 !important;
  }
}
</style>
