// var cfg = {
//   dev_root: 'http://stage.scm-admin.sh-jztech.com/api', //内网

//   prod_root: 'http://prod.scm-admin.sh-jztech.com/api', //pro

//   pre_root: 'http://preview.scm.sh-jztech.com/api', //pre

//   verify: 'http://test.cpcn.com.cn/InstitutionSimulatorDA/Tx0001.jsp',
// };
// let root = '';
// switch (process.env.VUE_APP_HOST) {
//   case 'dev':
//     root = cfg.dev_root;
//     break;
//   case 'prod':
//     root = cfg.prod_root;
//     break;
//   case 'pre':
//     root = cfg.pre_root;
//     break;
// }
export const baseURL = process.env.VUE_APP_BASE_URL;
export const baseVerifyURL = process.env.VUE_APP_VERIFY_URL;
export const baseQRCodeURL = location.protocol + '//' + location.host;
