<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="financePayableBillNo" label="融资子优企通编号" min-width="200"></el-table-column>
          <el-table-column prop="payableBillFinanceNo" label="融资项目编号" min-width="200"></el-table-column>
          <el-table-column prop="fundCorpName" label="资金机构" min-width="170"></el-table-column>
          <el-table-column label="融资总额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount) }}</template>
          </el-table-column>
          <el-table-column prop="financeYearInterest" label="融资年化利率(％)" min-width="150"></el-table-column>
          <el-table-column label="预计融资利息(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preFinanceInterest) }}</template>
          </el-table-column>
          <el-table-column label="预计融资金额(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preIncomeAmount) }}</template>
          </el-table-column>
          <el-table-column label="申请日期" min-width="170">
            <template slot-scope="scope">{{moment(scope.row.createTime).format('YYYY-MM-DD')}}</template>
          </el-table-column>
          <el-table-column prop="financeSignStatusShow" label="融资状态" min-width="150"></el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleSure(scope.row,1)">确认签约</el-button>
              <el-button size="medium" type="text" @click="handleSure(scope.row)">取消签约</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog
      title="签约确认"
      :visible.sync="imgDialogVisible"
      width="800px"
      class="datainfo"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-row>
        <el-col :span="8">
          <span>资金机构:</span>
          <em>{{dataInfo.fundCorpName}}</em>
        </el-col>
        <el-col :span="8">
          <span>融资总额(元):</span>
          <em>{{$changeMoneyThousand(dataInfo.amount)}}</em>
        </el-col>
        <el-col :span="8">
          <span>融资利率(%/年):</span>
          <em>{{dataInfo.financeYearInterest}}</em>
        </el-col>
        <el-col :span="8">
          <span>融资利息(元):</span>
          <em>{{$changeMoneyThousand(dataInfo.preFinanceInterest)}}</em>
        </el-col>
        <el-col :span="8">
          <span>平台服务费(元):</span>
          <em>{{$changeMoneyThousand(dataInfo.platformServiceFee)}}</em>
        </el-col>
        <el-col :span="8">
          <span>融资金额:</span>
          <em>{{$changeMoneyThousand(dataInfo.preIncomeAmount)}}</em>
        </el-col>
      </el-row>
      <div style="text-align:center" class="xytext">
        <el-checkbox v-model="checked" @change="showDialog(checked)">我已阅读并同意</el-checkbox>
        <a
          href="javascript:;"
          style="display: inline-block;margin: 0 5px;"
          @click="showDialog(checked,'1')"
        >《应收账款电子凭证转让通知书》</a>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">关 闭</el-button>
        <el-button @click="submitSure" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="取消签约"
      :visible.sync="imgDialogVisible2"
      width="800px"
      class="datainfo"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>请再次确认需要取消当前融资编号为{{dataInfo.payableBillFinanceNo}},融资额度为{{$changeMoneyThousand(dataInfo.amount) }}的融资申请!</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible2 = false">关 闭</el-button>
        <el-button @click="submitSure2" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="agreement-dialog"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="1000px"
      center
    >
      <div class="agreement-container">
        <pdf class="pdf" :src="api.getImgURL(transferbook)"></pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="!showType && !readDisabled" @click="hasRead()">同意</el-button>
        <el-button
          type="primary"
          v-if="!showType && readDisabled"
          :disabled="readDisabled"
        >阅读并同意 {{readCount}}秒</el-button>
        <el-button type="primary" @click="agreementDialog=false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../../../api/api.js';
import upload from '@/api/upload.js';
import moment from 'moment';
import pdf from 'vue-pdf';
import ukey from '@/util/ukey.js';

export default {
  components: {
    pdf
  },
  data() {
    return {
      pdf,
      api,
      transferbook: '',
      checked: false,
      agreementDialog: false,
      readCount: '',
      showType: '',
      readDisabled: true,
      isShowAll: false,
      isHedden: false,
      imgDialogVisible: false,
      imgDialogVisible2: false,
      dataInfo: {},
      filterList: [
        {
          label: '融资项目编号:',
          type: 'input',
          field: 'payableBillFinanceNo',
          size: 'small'
        },
        {
          label: '优企通编号:',
          type: 'input',
          field: 'payableBillNo',
          size: 'small'
        },
        {
          label: '资金机构:',
          type: 'input',
          field: 'fundCorpName',
          size: 'small'
        },
        {
          label: '融资金额: ',
          type: 'inputrange',
          field: 'financeAmountMin',
          size: 'small',
          isSeparated: 1
        },
        {
          type: 'inputrange',
          field: 'financeAmountMax',
          size: 'small'
        }
      ],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      fileTypes: {
        payableBillFinanceId: ''
      }
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log('filterShowAll  -----', this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    handleRefresh(filters) {
      console.log('filters<', filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      (param.review = 3),
        (param.source = 1),
        // param.financePayStatus = 1,
        api.GetSignApproveList(param).then((res) => {
          console.log('res ===>', res);
          if (res.success) {
            this.listData = res.datas.list;
            this.total = res.datas.total;
            console.log('list ===>', this.listData);
          }
          this.listLoading = false;
        });
    },
    handleSure(row, val) {
      if (val) {
        this.imgDialogVisible = true;
        this.checked=false
      } else {
        this.imgDialogVisible2 = true;
      }
      this.dataInfo = row;
    },
    showDialog(isRead, type) {
      var obj = {
        payableBillFinanceId: this.dataInfo.payableBillFinanceId,
        type: 3
      };
      api.createTransferNotification(obj).then((res) => {
        if (res.code == 100000) {
          //  window.open(api.getImgURL(res.datas.data));
          this.transferbook = res.datas.data;
          this.agreementDialog = true;
        } else {
          this.$message.error(res.message);
        }
      });
      this.showType = type;
      if (type) {
        if (type == '1') {
          this.agreementDialog = true;
        }
      } else {
        if (this.checked) {
          this.checked = false;
          this.agreementDialog = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
   async hasRead() {
      this.agreementDialog = false;
      this.readDisabled = false;
      this.checked = true;
      var isUkey = JSON.parse(sessionStorage.corpList).signType;
      var user = JSON.parse(sessionStorage.getItem('user'));

      var certContent = {
        result: ''
      };
      if (isUkey == 2) {
        let ukeyRes = await ukey.LoadObj();
        if (!ukeyRes.result) {
          return;
        }
        certContent = await ukey.GetCertInfoOnClick('CertContent');
      }

      var p1 = await api
        .getSignHash({
          signType: 5,
          payableBillFinanceId: this.dataInfo.payableBillFinanceId,
          x509Cert: certContent.result
        })
        .then((res) => {
          return res;
        });

      Promise.all([p1])
        .then((values) => {
          if (!values[0].success) {
            this.$message.error(values[0].message);
            return false;
          }
          var pdfSealHash;

          if (user.pdfSign == 1 && isUkey == 2) {
            pdfSealHash = ukey.SignHashOnClick(
              values[0].datas.data.pdfSealHash
            );
          }

          return Promise.all([pdfSealHash]).then((hash) => {
            if (user.pdfSign == 1 && isUkey == 2) {
              return {
                pdfId: values[0].datas.data.pdfId,
                pdfHash: hash[0].result
              };
            } else {
              return {};
            }
          });
        })
        .then((hash) => {
          let param = {
            signType: 5, //1 融资企业  2  资方
          payableBillFinanceId: this.dataInfo.payableBillFinanceId,
           
            ...hash
          };
          api.transferpdfEsign(param).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
            } else {
              this.$message.error(res.message);
            }
          });
        });
    },
    submitSure() {
      if(!this.checked){
        this.$message.error('请阅读并同意应收账款电子凭证转让通知书')
        return

      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      api
        .signConfirmWait({
          payableBillFinanceId: this.dataInfo.payableBillFinanceId,
          approveResult: 1
        })
        .then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.imgDialogVisible = false;
            this.$router.push({ path: this.$route.query.path });
          }
          loading.close();
        });
    },
    submitSure2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api
        .signConfirmWait({
          payableBillFinanceId: this.dataInfo.payableBillFinanceId,
          approveResult: 2
        })
        .then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.imgDialogVisible2 = false;
            this.$router.push({ path: this.$route.query.path });
          } else {
            this.$message.error(res.message);
          }
          loading.close();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/moreDetail/moudleDetail.scss';
.datainfo {
  .el-col {
    margin-bottom: 20px;
  }
}
.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.agreement-container{
  display: block;
}
</style>
