<template>
    <div class="common-page-dataList">
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                    :filters="filterList"
                    :isShowAll="isShowAll"
                    :isHedden="isHedden"
                    @data-showAll="filterShowAll"
                    @data-refresh="handleRefresh"
                    :total="total"
                    ref="datalist"
            >
                <el-table
                        :data="listData"
                        :header-cell-style="headerStyle"
                        highlight-current-row
                        v-loading="listLoading"
                        style="width: 100%;"
                >
                    <el-table-column prop="receivableAccountFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
                    <el-table-column prop="financeCorpName" label="债权企业" min-width="200"></el-table-column>
                    <el-table-column label="融资金额（元)" min-width="160">
                        <template slot-scope="scope">{{$changeMoneyThousand(scope.row.financeAmount)}}</template>
                    </el-table-column>
                    <el-table-column prop="financeInterest" label="融资年化利率(％)" min-width="180"></el-table-column>
                    <el-table-column prop="relationReceivedAccountNums" label="应收账款数量"
                                     min-width="150"></el-table-column>
                    <el-table-column prop="financeSignStatusName" label="签署状态" min-width="150"></el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
                            <el-button v-if="scope.row.financeSignStatus == 41" size="medium" type="text" @click="contractDetial(scope.row)">查看合同</el-button>
                            <el-button v-else size="medium" type="text" @click="sign(scope.row)">签约</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
    </div>
</template>
<script>
    import api from "@/api/api.js";
import moment from 'moment'

    export default {
        data() {
            return {
                isShowAll: false,
                isHedden: false,
                filterList: [
                    {
                        label: "融资项目编号:",
                        type: "input",
                        field: "receivableAccountFinanceNo",
                        size: "small"
                    },
                    {
                        label: "债权企业:",
                        type: "input",
                        field: "financeCorpName",
                        size: "small"
                    },
                    {
                        label: "融资金额(元): ",
                        type: "inputrange",
                        field: "financeAmountMin",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "financeAmountMax",
                        size: "small"
                    }
                ],
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                pageNo: 1,
                pageSize: 10
            };
        },
        created() {
            this.activeName = this.$route.params.state;
        },
        methods: {
            moment: moment,
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                this.getLists(filters);
            },
            //查询
            getLists(param) {
                console.log("param ====>", param);
                api.GetEmployerSignListPage(param).then(res => {
                    console.log("res ===>", res);
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                        console.log("list ===>", this.listData);
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(row) {
                this.$router.push({
                    path: "/factoring/FactoringQuoteDetial",
                    query: {
                        receivableAccountFinanceId: row.receivableAccountFinanceId,
                        type: '2',
                    }
                });
            },
            sign(row) {
                this.$router.push({
                    path: "/factoring/FactoringSignDetial",
                    query: {
                        receivableAccountFinanceId: row.receivableAccountFinanceId,
                        isshowbtn: '1',
                        fundCorpId: row.fundCorpId,
                        corpType: '1',
                    }
                });
            },
            contractDetial(row){
                this.$router.push({
                    path: "/factoring/FactoringSignDetial",
                    query: {
                    receivableAccountFinanceId: row.receivableAccountFinanceId,
                    isshowbtn: "2",
                    fundCorpId: row.fundCorpId,
                    corpType: "2"
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
</style>
