<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card"  class="n-tabs">
      <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist">
        <el-table :data="listData" align='center' highlight-current-row style="width: 100%;">
          <el-table-column prop="absAccountNo" label="账款编号" min-width="120">
          </el-table-column>
          <!-- <el-table-column prop="contractNo" label="合同编号" min-width="100"  >
          </el-table-column> -->
          <el-table-column prop="debtCorpName" label="债务企业" min-width="120"  >
          </el-table-column>
          <el-table-column prop="claimsCorpName" label="债权企业" min-width="120"  >
          </el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'>
          </el-table-column>
          <el-table-column prop="absAccountCloseDate" label="计划付款日" min-width="100">
            <template slot-scope="scope">
              {{moment(scope.row.absAccountCloseDate).format("YYYY-MM-DD")}}
            </template>
          </el-table-column>
          <el-table-column  label="账款类型"  prop='absAccountTypeShow' min-width="100" >
          </el-table-column>
          <!-- <el-table-column  label="账单状态"  prop='approveStatusShow' min-width="100" >
          </el-table-column> -->
          <el-table-column label="操作" width="120" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEditinfo(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api.js";
import {changeMoneyThousand} from '@/util/common'
import moment from 'moment'
export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "计划付款日:",
          type: "daterange",
          field: ["absAccountCloseDateStart", "absAccountCloseDateEnd"],
          size: "small"
        },
        {
          label: "账款金额(元): ",
          type: "inputrange",
          field: "absAccountMinAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "absAccountMaxAmount",
          size: "small"
        },
      ],
      total: 0,
      listData: [],
    };
  },
  props: {
    accountSource: {}
  },
  created() { },
  methods: {
    moment: moment,
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(filters) {
      let param = {
        accountSource: this.accountSource,   // 1发布方 2接收方
        checkType: 1 // 1待审核  2已审核
      }
      Object.assign(param , filters)
      api.getAccountCheckListPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
      });
    },
    handleEditinfo(row){
      let queryParams = { id: row.absAccountId,isshowbtn:1, accountSource: this.accountSource, approveStatus: row.approveStatus}
      this.$router.push({
        name: "账款详情",
        query: queryParams
      });
    },
  }
};
</script>


