<!-- 票据审批管理 -->
<template>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
        <bill-unapprovaled v-if="showtab == 1"></bill-unapprovaled>
        <bill-approvaled v-if="showtab == 2"></bill-approvaled>
    </layout-main-view>
</template>
<script>
    import billApprovaled from './bill-approvaled'
    import billUnapprovaled from './bill-unapprovaled'
    export default {
        data() {
            return {
                breadcrumblist: ["票据发布审批"],
                showtab: 1,
                tabOptions: [
                    { label: "待审批", value: "1", path: "/bill/bill-manage/1" },
                    { label: "已审批", value: "2", path: "/bill/bill-manage/2" },
                ],
            }
        },
        mounted(){
            this.showtab = this.$route.params.state;
        },
        methods: {
            tabclick(val) {
                this.showtab = val;
            },
        },
        components: {
            billApprovaled,
            billUnapprovaled
        }
    }
</script>
<style lang="scss">
    
</style>