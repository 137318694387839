<!--资金账户管理-->
<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList">
      <div class="account-number">结算渠道资金账号： <span style="color: #2937A6;">{{form.subNo}}</span> </div>
      <div class="account-manage">
        <div class="manage-info manage-base-info">
          <div class="manage-title">企业基本信息</div>
          <ul class="manage-info-ul">
            <li>企业名称：<span class="info-style">{{form.cltNm}}</span></li>
            <li>社会统一信用代码：<span class="info-style">{{form.creditCode}}</span></li>
            <li>企业法人：<span class="info-style">{{form.legalName}}</span></li>
            <li>联系电话：<span class="info-style">{{form.phone}}</span></li>
            
            <li>营业执照：
              <img v-if="corpFile && corpFile.fileUrl" class="license" :src="api.getImgURL(corpFile.fileUrl)" alt="营业执照" preview="license">
            </li>
          </ul>
        </div>
        <div class="manage-info manage-account-info">
          <div class="manage-title">账户信息
            <!-- <div class="log-box" v-if="accountType == 1" @click="showLog">操作日志</div>
            <div class="log-box" v-if="accountType == 2" @click="showTradingRecord">账户交易记录</div> -->
          </div>
          <ul class="manage-info-ul" v-if="accountType == 1">
            <li>结算渠道：<span class="info-style">中金支付</span></li>
            <li>资金总额：<span class="info-style"><span class="amount-style">{{$changeMoneyThousand(totalMoney)}}</span> 元人民币</span></li>
            <li>资金余额：<span class="info-style">{{$changeMoneyThousand(form.money)}} 元人民币</span></li>
            <li>提现余额：<span class="info-style">{{$changeMoneyThousand(form.outMoney)}} 元人民币</span></li>
            <li>冻结金额：<span class="info-style">{{$changeMoneyThousand(form.freezeMoney)}} 元人民币</span></li>
            <li>绑定银行：<span class="info-style">{{form.bankName}}</span></li>
            <li>绑定对公账户：<span class="info-style">{{form.bankCardNo}}</span></li>
            <li>绑卡状态：
              <span class="info-style">{{form.isTiedCard == 2 ? '已绑卡' : '未绑卡'}}</span>
              <el-button size="small" type="primary" v-if="form.isTiedCard == 2" @click="submitTiedCard(3)" style="margin-left: 30px">解绑</el-button>
            </li>
          </ul>
          <ul class="manage-info-ul" v-if="accountType == 2">
            <li>结算渠道：<span class="info-style">上海银行</span></li>
            <li>客户号：<span class="info-style">{{form.cltNo}}</span></li>
            <!-- <li>所属支行号码：<span class="info-style">{{form.openBkCd}}</span></li>-->
            <li>所属支行：<span class="info-style">{{form.openBkNm}}</span></li> 
            <li>账户余额：<span class="info-style">{{$changeMoneyThousand(form.money)}} 元人民币</span></li>
            <li>对公账户银行：<span class="info-style">{{form.bankName}}</span></li>
            <li>对公账户卡号：<span class="info-style">{{form.bankCardNo}}</span></li>
            <li>账户状态：<span class="info-style">{{form.isTiedCard == 1 ? '未激活' : '已激活'}}</span></li>
            <li v-if="accountType == 2">操作员：<span class="info-style">{{form.operatorName || '无操作员'}} {{showOperatorStatus(form.operatorStatus)}}</span></li>
            <!-- isTiedCard 1开户中 2/3已开户 (1绑定中 2绑卡 3解绑) -->
          </ul>
          <!-- <div v-if="accountType == 1" class="btn-box">
            <el-button type="primary" v-if="accountType == 1 && form.isTiedCard == 1" @click="tiedCard">绑定对公账户</el-button>
            <el-button type="primary" v-if="accountType == 1 && form.isTiedCard == 2" @click="showCardInfo">对公账户信息</el-button>
            <el-button type="primary" v-if="accountType == 1 && form.isTiedCard == 2" @click="recharge" class="small-btn">充 值</el-button>
            <el-button v-if="accountType == 1 && form.isTiedCard == 2" @click="withdraw1" :disabled="!(+form.outMoney>0)" class="small-btn">提 现</el-button>
          </div> -->
          <!-- <div v-if="accountType == 2" class="btn-box">
            <el-button type="primary" v-if="accountType == 2 && form.isTiedCard == 2" @click="changeOperator" :disabled=" form.operatorStatus == 1">变更操作员</el-button> -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.operatorStatus == 1" @click="activateOperator">操作员激活</el-button> -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.isTiedCard == 1" @click="activateAccount1">激活账号</el-button> -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.isTiedCard == 1" @click="changeAccount(1)">变更对公账号</el-button>  从未激活状态下变更 -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.isTiedCard == 2" @click="changeAccount(2)">变更对公账号</el-button>  正常变更 -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.isTiedCard == 2 && form.cardFlag == 1" @click="activateAccount2">激活变更账号</el-button> -->
            <!-- <el-button type="primary" v-if="accountType == 2 && form.isTiedCard != 1" @click="printBill">下载对账单</el-button> -->
            <!-- <el-button v-if="accountType == 2 && form.isTiedCard == 2" class="small-btn" @click="withdraw2" :disabled="!(+form.money>0)">提 现</el-button> -->
          <!-- </div> -->
        </div>
      </div>
      <!-- 中金-绑卡 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="120px" class="demo-ruleForm" :rules="rules">
          <el-form-item label="对公账户户名" prop="accountName">
            <el-input   placeholder="请输入对公账户户名" v-model="ruleForm.accountName" disabled></el-input>
          </el-form-item>
          <el-form-item label="对公账户账号" prop="bkAccAccNo">
            <el-input   placeholder="请输入对公账户账号" v-model="ruleForm.bkAccAccNo" :disabled="isReadonly"></el-input>
          </el-form-item>
          <el-form-item label="对公账户开户行" prop="ubankid">
            <el-select v-model="ruleForm.bankInfo"  placeholder="请选择" @change="changeBank" filterable value-key="ubankid" :disabled="isReadonly">
              <el-option
                v-for="(item, index) in suppBankList"
                :key="index"
                :label="item.bankname"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行省份" prop="provcode">
            <el-select v-model="ruleForm.provInfo"  placeholder="请选择" @change="city(ruleForm.provInfo.provid)" filterable value-key="provcode" :disabled="isReadonly">
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.provname"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行城市" prop="citycode">
            <el-select v-model="ruleForm.cityInfo"  placeholder="请选择" @change="payBank(ruleForm.bankInfo.bankid, ruleForm.cityInfo.citycode)" filterable value-key="citycode" :disabled="isReadonly">
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.cityname"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户网点" prop="payBankCode">
            <el-select v-model="ruleForm.payBankInfo"  placeholder="请选择" @change="changePayBank" filterable value-key="payBankCode" :disabled="isReadonly">
              <el-option
                v-for="(item, index) in payBankList"
                :key="index"
                :label="item.payBankName"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-alert v-if="dialogType == 1" title="平台将向您提供的对公账户转账一笔金额，请在收到转账金额后及时完成对公账户验证。只有完成验证的对公账户才能正常使用！" type="warning" show-icon :closable="false"></el-alert>
        <div style="text-align: right;margin-top: 20px;" v-if="dialogType == 1">
          <el-button @click="cancelBtn">取 消</el-button>
          <el-button type="primary" @click="submitTiedCard(1)" :disabled="forbidden1">申请验证</el-button>
        </div>
        <div style="text-align: right;margin-top: 20px;" v-if="dialogType == 2">
          <el-button @click="cancelBtn">关 闭</el-button>
        </div>
      </el-dialog>
      <!-- 中金-绑卡验证 -->
      <el-dialog title="对公账户验证" :visible.sync="dialogVisible2" width="500px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-alert :title="'平台已向您提供的尾号为' + tailNumber + '的对公账户内转账一笔金额。该转帐金额将在24小时后失效，请在收到转帐金额后尽快完成验证。验证失败请重新申请转帐验证。'" type="warning" show-icon :closable="false"></el-alert>
        <!-- <el-alert :title="'平台已向您提供的尾号为' + tailNumber + '的对公账户内转账一笔0.1元以下的金额，流水号为 【' + serialNum + '】 请点击下方‘查看打款金额’，输入流水号，获取打款金额。' +  '请在下方输入框中输入该转账金额完成绑卡打款验证'" type="warning" show-icon :closable="false"></el-alert> -->
        <!-- <a :href="baseVerifyURL" target="_blank" class="link-style">查看打款金额</a> -->
        <el-form :model="ruleForm2" status-icon ref="ruleForm2" label-width="0px" class="demo-ruleForm ruleForm2-style" :rules="rules2">
          <el-form-item prop="amount">
            <el-input placeholder="请输入绑卡打款金额" type="number" v-model="ruleForm2.amount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button @click="cancelBtn2">取 消</el-button>
          <el-button type="primary" @click="submitAmount(ruleForm2.amount)" :disabled="forbidden2">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 中金-充值 -->
      <el-dialog title="资金充值" :visible.sync="dialogVisible3" width="50%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="dialog-info-title">充值基本信息</div>
        <ul class="base-info-ul">
          <li>账户名称：{{form.cltNm}}</li>
          <li>资金账号：{{form.subNo}}</li>
          <li>资金总额：{{$changeMoneyThousand(totalMoney)}} 元人民币</li>
          <li>资金余额：{{$changeMoneyThousand(form.money)}} 元人民币</li>
          <li>提现银行：{{form.bankName}}</li>
          <li>银行账号：{{form.bankCardNo}}</li>
        </ul>
        <el-form :model="ruleForm3" status-icon ref="ruleForm3" label-width="90px" class="demo-ruleForm ruleForm-box" :rules="rules3">
          <el-form-item label="充值金额" prop="inMoney">
            <el-input placeholder="请输入充值金额" v-model="ruleForm3.inMoney">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注信息" prop="usage">
            <el-input placeholder="请输入备注信息" v-model="ruleForm3.usage"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;margin-top: 20px;">
          <el-button @click="cancelBtn3">取 消</el-button>
          <el-button type="primary" @click="inMoneyApply" style="margin-left: 40px;" :disabled="forbidden3">提交</el-button>
        </div>
      </el-dialog>
      <form :action='saveFormData3.actionUrl' id="fromData3" style="display:none" method="POST">
        <input :name="saveFormData3.key1" :value="saveFormData3.val1" id='key1' />
        <input :name="saveFormData3.key2" :value="saveFormData3.val2" id='key2' />
        <input type="submit" value="提交" id="submitBtn3"  ref="submitBtn3" />
      </form>
      <!-- 中金-提现 -->
      <el-dialog title="资金提现" :visible.sync="dialogVisible4" width="50%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="dialog-info-title">提现基本信息</div>
        <ul class="base-info-ul">
          <li>账户名称：{{form.cltNm}}</li>
          <li>资金账号：{{form.subNo}}</li>
          <li>资金总额：{{$changeMoneyThousand(totalMoney)}} 元人民币</li>
          <li>可提现金额：{{$changeMoneyThousand(form.outMoney)}} 元人民币</li>
          <li>提现银行：{{form.bankName}}</li>
          <li>银行账号：{{form.bankCardNo}}</li>
        </ul>
        <el-form :model="ruleForm4" status-icon ref="ruleForm4" label-width="120px" class="demo-ruleForm ruleForm-box" :rules="rules4">
          <el-form-item label="提现金额" prop="inMoney">
            <el-input :placeholder="'最大提现' + $changeMoneyThousand(form.outMoney) + '元'" v-model="ruleForm4.inMoney">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注信息" prop="usage">
            <el-input placeholder="请输入备注信息" v-model="ruleForm4.usage"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;margin-top: 20px;">
          <el-button @click="cancelBtn4">取 消</el-button>
          <el-button type="primary" @click="outMoneyApply" style="margin-left: 40px;" :disabled="forbidden4">提交</el-button>
        </div>
      </el-dialog>
      <!-- 中金-操作日志 -->
      <el-dialog title="操作日志" :visible.sync="dialogVisible5" width="90%" class="add-account log-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="充值日志" name="1">
            <data-list
              :filters="filterList1"
              @data-refresh="handleRefresh1"
              :total="total1"
              ref="datalist">
              <el-table :data="listData1" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;" v-loading="listLoading1">
                <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
                <el-table-column prop="serialNo" label="流水号" min-width="220"></el-table-column>
                <el-table-column prop="createTime" label="充值时间" min-width="200">
                  <template slot-scope="scope">
                    {{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'): ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="amountYuan" label="充值金额(元)" min-width="120">
                  <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amountYuan)}}</template>
                </el-table-column>
                <el-table-column prop="busStatusStr" label="充值状态" min-width="100"></el-table-column>
              </el-table>
            </data-list>
          </el-tab-pane>
          <el-tab-pane label="提现日志" name="2">
            <data-list
              :filters="filterList2"
              @data-refresh="handleRefresh2"
              :total="total2"
              ref="datalist">
              <el-table :data="listData2" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;" v-loading="listLoading2">
                <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
                <el-table-column prop="serialNo" label="流水号" min-width="250"></el-table-column>
                <el-table-column prop="createTime" label="提现时间" min-width="200">
                  <template slot-scope="scope" >
                    {{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'): ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="amountYuan" label="提现金额(元)" min-width="150">
                  <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amountYuan)}}</template>
                </el-table-column>
                <el-table-column prop="busStatusStr" label="提现状态" min-width="150"></el-table-column>
              </el-table>
            </data-list>
          </el-tab-pane>
          <el-tab-pane label="交易记录" name="3">
            <data-list
              :filters="filterList3"
              @data-refresh="handleRefresh3"
              :total="total3"
              ref="datalist">
              <el-table :data="listData3" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;" v-loading="listLoading3">
                <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
                <el-table-column prop="serialNo" label="流水号" min-width="130"></el-table-column>
                <el-table-column prop="amt" label="交易金额(元)" min-width="100">
                  <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amt)}}</template>
                </el-table-column>
                <el-table-column prop="type" label="交易类型" min-width="80"></el-table-column>
                <el-table-column prop="dataTime" label="交易时间" min-width="170">
                  <template slot-scope="scope">
                    {{scope.row.dataTime ? moment(scope.row.dataTime).format('YYYY-MM-DD HH:mm:ss'): ''}}
                  </template>
                </el-table-column>
                <el-table-column prop="toAccountName" label="对方户名" min-width="150"></el-table-column>
                <el-table-column prop="toAccountNo" label="对方户号码" min-width="130"></el-table-column>
                <el-table-column prop="desc" label="交易摘要" min-width="120"></el-table-column>
              </el-table>
            </data-list>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
      <!-- 上海银行-账户交易记录 -->
      <el-dialog title="账户交易记录" :visible.sync="dialogVisibleSh0" width="90%" class="add-account log-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
        <data-list
        :filters="filterList3"
          @data-refresh="handleRefresh3"
          :total="total3"
          ref="datalist">
          <el-table :data="listData3" :header-cell-style="headerStyle" highlight-current-row style="width: 100%;" v-loading="listLoading3">
            <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
            <el-table-column prop="serialNo" label="流水号" min-width="130"></el-table-column>
            <el-table-column prop="amt" label="交易金额(元)" min-width="100">
              <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amt)}}</template>
            </el-table-column>
            <el-table-column prop="type" label="交易类型" min-width="80"></el-table-column>
            <el-table-column prop="dataTime" label="交易时间" min-width="170">
              <template slot-scope="scope">
                {{scope.row.dataTime ? moment(scope.row.dataTime).format('YYYY-MM-DD HH:mm:ss'): ''}}
              </template>
            </el-table-column>
            <el-table-column prop="toAccountName" label="对方户名" min-width="150"></el-table-column>
            <el-table-column prop="toAccountNo" label="对方户号码" min-width="130"></el-table-column>
            <el-table-column prop="desc" label="交易摘要" min-width="120"></el-table-column>
            <el-table-column label="操作" min-width="110">
              <template slot-scope="scope">
                <el-button  class="clear-button" type="text" @click="printReceipt(scope.row)">下载回单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-dialog>
      <!-- 预览营业执照 -->
      <el-dialog title :visible.sync="imgDialogVisible" width="80%" center class="img-dialog">
        <img :src="fileUrl" alt="图片丢失" width="30%" height="30%" preview-text="凭证" :preview="1"/>
        <span slot="footer" class="dialog-footer">
          <el-button @click="imgDialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!-- 上海银行-变更对公账户 -->
      <el-dialog title="变更对公账号" :visible.sync="dialogVisibleSh1" v-if="dialogVisibleSh1" width="600px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-alert title="温馨提示：请仔细确认清算行和对公账号，正确填写发送的校验码后完成账号绑定" type="warning" show-icon :closable="false"></el-alert>
        <el-form :model="ruleFormSh1" status-icon ref="ruleFormSh1" label-width="160px" class="demo-ruleForm  ruleForm2-style" :rules="rulesSh1">
          <el-form-item label="绑定卡清算行行号：" prop='acctBankObj'>
            <el-select v-model="ruleFormSh1.acctBankObj" filterable style="width: 100%;" value-key="code">
              <el-option  v-for="(item,index) in bankList" :key="index" :label="item.name" :value="item" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定银行对公账号：" prop="account">
            <el-input v-model="ruleFormSh1.account">
            </el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button @click="cancelBtnSh1">取 消</el-button>
          <el-button type="primary" @click="submitChange1" :disabled="forbiddenSh1">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-变更对公账户/提现/变更操作员-输入校验码 -->
      <el-dialog title="输入校验码" :visible.sync="dialogVisibleSh2" width="600px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <!-- 变更对公账户-验证码 -->
        <el-alert v-if="diaType == 1" :title="'您已经向上海银行申请提交绑卡变更信息，上海银行会向您尾号为' + form.phone.substring(form.phone.length-4) + '的手机发送动态验证码。请在下面的输入框中输入您收到的验证码，并点击提交。'" type="warning" show-icon :closable="false"></el-alert>
        <!-- 提现-验证码 -->
        <el-alert v-if="diaType == 2" :title="'您已经向上海银行申请提现，上海银行会向您尾号为' + form.phone.substring(form.phone.length-4) + '的手机发送动态验证码。请在下面的输入框中输入您收到的验证码，并点击提交。'" type="warning" show-icon :closable="false"></el-alert>
        <!-- 变更操作员-验证码 -->
        <el-alert v-if="diaType == 3" :title="'您已经向上海银行申请操作员变更信息，上海银行会向您尾号为' + mobile.substring(mobile.length-4) + '的手机发送动态验证码。请在下面的输入框中输入您收到的验证码，并点击提交。'" type="warning" show-icon :closable="false"></el-alert>
        
        <el-form :model="ruleFormSh2" status-icon ref="ruleFormSh2" label-width="160px" class="demo-ruleForm  ruleForm2-style" :rules="rulesSh2">
          <el-form-item label="验证码：" prop="dynamicCode">
            <el-input v-model="ruleFormSh2.dynamicCode">
            </el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button @click="cancelBtnSh2">取 消</el-button>
          <el-button type="primary" v-if="diaType == 3" @click="resend2" :disabled="forbiddenSh3">{{ forbiddenSh3 ? count + '秒后可重新发送' : '重新发送' }}</el-button>
          <el-button type="primary" v-else @click="resend" :disabled="forbiddenSh3">{{ forbiddenSh3 ? count + '秒后可重新发送' : '重新发送' }}</el-button>
          <!-- 变更对公账户 -->
          <el-button v-if="diaType == 1" type="primary" @click="submitChange2" :disabled="forbiddenSh2">确 定</el-button>
          <!-- 提现 -->
          <el-button v-if="diaType == 2" type="primary" @click="shWithdraw2" :disabled="forbiddenSh2">确 定</el-button>
          <!-- 变更操作员 -->
          <el-button v-if="diaType == 3" type="primary" @click="changeOperator2" :disabled="forbiddenSh2">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-变更对公账户/变更操作员-成功后提醒激活 -->
      <el-dialog title="等待激活" :visible.sync="dialogVisibleSh3" width="600px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-alert v-if="diaType == 1" :title="'请于' + actiDeadline + '日前，向您尾号为' + ruleFormSh1.account.substring(ruleFormSh1.account.length-4) + '的上海银行账户中转账' + verifyAmount + '元完成激活准备，并在转账到帐以后，点击页面中的【激活账号】功能'" type="warning" show-icon :closable="false"></el-alert>
        <!-- <el-alert v-if="diaType == 3" :title="'请于' + actiDeadline + '日前，向您尾号为' + ruleFormSh1.account.substring(ruleFormSh1.account.length-4) + '的上海银行账户中转账' + verifyAmount + '元完成激活准备，并在转账到帐以后，点击页面中的【操作员激活】功能'" type="warning" show-icon :closable="false"></el-alert> -->
        <div style="text-align: right; margin-top: 20px;">
          <el-button type="primary" @click="dialogVisibleSh3 = false">我知道了</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-下载对账单 -->
      <el-dialog title="下载对账单" :visible.sync="dialogVisibleSh4" width="600px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="ruleFormSh4" status-icon ref="ruleFormSh4" label-width="160px" class="demo-ruleForm  ruleForm2-style" :rules="rulesSh4">
          <el-form-item label="对公在线业务账号：">
            <span>{{form.subNo}}</span>
          </el-form-item>
          <el-form-item label="对账单期数：" prop="date">
            <el-date-picker v-model="ruleFormSh4.date" type="month" placeholder="选择期数" value-format="yyyyMM"></el-date-picker>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button @click="dialogVisibleSh4 = false">取 消</el-button>
          <el-button type="primary" @click="rlectronicReceipt" :disabled="forbiddenSh4">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-激活账号/操作员激活-结果 -->
      <el-dialog :title="activateTitle" :visible.sync="dialogVisibleSh5" width="600px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <!-- 激活账号 -->
        <div v-if="activateType == 1">
          <div v-if="activateResult">账号已激活，可以正常使用</div>
          <div v-else>
            <div>账号激活失败</div>
            {{activateResultText}}
          </div>
        </div>
        <!-- 操作员激活 -->
        <div v-if="activateType == 2">
          <div v-if="activateResult">操作员已激活，可以正常使用</div>
          <div v-else>
            <div>操作员激活失败</div>
            {{activateResultText}}
          </div>
        </div>
        <div style="text-align: right;">
          <el-button type="primary" @click="dialogVisibleSh5 = false">我知道了</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-提现 -->
      <el-dialog title="提现" :visible.sync="dialogVisibleSh6" width="50%" class="add-account" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-alert title="温馨提示：1、转出金额100万（含）以下7*24小时实时受理并到账；
        2、转出金额100万-1000万（含），若收款行支持城商行汇路（详情请咨询收款行），7*24小时实时受理并到账；否则，T-1日（即法定工作日前一日）20:30至T日（即法定工作日当日）17:15实时受理并到账；
        3、转出金额1000万以上T-1日（即法定工作日前一日）20:30至T日（即法定工作日当日）17:15实时受理并到账。" type="warning" show-icon :closable="false"></el-alert>
        <el-form :model="ruleFormSh6" status-icon ref="ruleFormSh6" label-width="140px" class="demo-ruleForm ruleForm-box" :rules="rulesSh6">
          <el-form-item label="对公在线业务账号：">
            <span>{{form.subNo}}</span>
          </el-form-item>
          <el-form-item label="提现金额：" prop="amount">
            <el-input :placeholder="'最大提现' + $changeMoneyThousand(form.money) + '元'" v-model="ruleFormSh6.amount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="用途：" prop="usage">
            <el-input placeholder="请输入备注信息" v-model="ruleFormSh6.usage"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;margin-top: 20px;">
          <el-button @click="cancelBtnSh6">取 消</el-button>
          <el-button type="primary" @click="shWithdraw1" style="margin-left: 40px;" :disabled="forbiddenSh6">提交</el-button>
        </div>
      </el-dialog>
      <!-- 上海银行-变更操作员 -->
      <el-dialog title="变更操作员" :visible.sync="dialogVisibleSh7" v-if="dialogVisibleSh7" width="800px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="ruleFormSh7" status-icon ref="ruleFormSh7" label-width="160px" class="demo-ruleForm  ruleForm2-style" :rules="rulesSh7">
          <el-form-item label="操作员姓名：" prop="name">
            <el-input v-model="ruleFormSh7.name"></el-input>
          </el-form-item>
          <el-form-item label="证件类型：" prop='idType'>
            <el-select v-model="ruleFormSh7.idType" filterable style="width: 100%;">
              <el-option  v-for="(item,index) in idTypeList" :key="index" :label="item.name" :value="item.code" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码：" prop="idCard">
            <el-input v-model="ruleFormSh7.idCard"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="mobile">
            <el-input v-model="ruleFormSh7.mobile"></el-input>
          </el-form-item>
          <el-form-item label="上传法人身份证：" :prop='ruleFormSh7.operatorBackImg ? "operatorFrontImg" : "operatorBackImg"' class="company1 registerDemo">
            <div class="certificates">
              <div class="certificates_item">
                <span style="color:#ccc;font-size:14px">人像面</span>
                <div @click="getFileType('back')" :class="{imgClass:!isEmpty(ruleFormSh7.operatorBackImg)}">
                  <el-upload
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="uploadFileSftp()"
                    list-type="picture-card"
                    :data="videoTypeObj1"
                    :before-upload="beforeUpload"
                    :on-success="uploadSuccess1"
                    :on-remove="removeFile1"
                    :headers="Authorization">
                    <i v-if="isEmpty(ruleFormSh7.operatorBackImg)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
              <div class="certificates_item"> 
                <span style="color:#ccc;font-size:14px">国徽面</span>
                <div @click="getFileType('front')" :class="{imgClass:!isEmpty(ruleFormSh7.operatorFrontImg)}">
                  <el-upload
                    :limit="1"
                    :on-exceed=" () => {$message.info('超过上传限制')}"
                    :action="uploadFileSftp()"
                    list-type="picture-card"
                    :data="videoTypeObj2"
                    :before-upload="beforeUpload"
                    :on-success="uploadSuccess2"
                    :on-remove="removeFile2"
                    :headers="Authorization">
                    <i v-if="isEmpty(ruleFormSh7.operatorFrontImg)" class="el-icon-plus"></i>
                    <span v-else>
                      <i class="el-icon-loading"></i>
                      <span>上传中</span>
                    </span>
                  </el-upload>
                </div>
              </div>
            </div>
            <label style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;">仅支持JPG、PNG格式，文件小于1M。请尽量使用高清质量图片。</label>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button @click="cancelBtnSh7">取 消</el-button>
          <el-button type="primary" @click="changeOperator1" :disabled="forbiddenSh7">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api.js";
import upload from "@/api/upload";
import { baseVerifyURL } from "@/api/sys.js"
import moment from 'moment'

export default {
  data() {
    return {
      breadcrumblist: ['资金账户详情'],
      moment: moment,
      api: api,
      baseVerifyURL: baseVerifyURL,
      form: {},
      corpFile: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      ruleForm: {
        bankInfo: {},
        provInfo: {},
        cityInfo: {},
        payBankInfo: {},
        bkAccAccNo: '',
        ubankid: '',
        provcode: '',
        citycode: '',
        payBankCode: '',
      },
      ruleForm2: {
        amount: ''
      },
      ruleForm3: {
        clientNoticeUrl: api.rechargeFrontUrl(),  //页面通知URL
        serviceNoticeUrl: api.rechargeBackUrl(), //后台通知URL
        inMoney: '',  //充值金额
        payFee: 0, //手续费
        usage: '',  //备注
      },
      ruleForm4: {
        clientNoticeUrl: api.rechargeFrontUrl(),  //页面通知URL
        serviceNoticeUrl: api.rechargeBackUrl(), //后台通知URL
        inMoney: '',  //提现金额
        payFee: 0, //手续费
        usage: '',  //备注
      },
      suppBankList: [],
      provinceList: [],
      cityList: [],
      payBankList: [],
      rules: {
        bkAccAccNo: [{ required: true, message: '请输入对公账户账号', trigger: 'blur' }],
        ubankid: [{ required: true, message: '请选择对公账户开户行', trigger: 'change' }],
        provcode: [{ required: true, message: '请选择分支行省份', trigger: 'change' }],
        citycode: [{ required: true, message: '请选择分支行城市', trigger: 'change' }],
        payBankCode: [{ required: true, message: '请选择开户网点', trigger: 'blur' }],
      },
      rules2: {
        amount: [
          { required: true, message: '请输入绑卡打款金额', trigger: 'blur' },
          { max: 18, pattern: /^\d+(\.{0,1}\d+){0,1}$/, message: '请输入合法的数字', trigger: 'blur'}
        ],
      },
      rules3: {
        inMoney: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { max: 18, pattern: /^\d+(\.{0,1}\d+){0,1}$/, message: '请输入合法的数字', trigger: 'blur'},
          { validator: this.checkAmount1, message: '充值金额不能小于0.01元', trigger: 'blur'}
        ],
        usage: [
          { required: false, message: '请输入备注信息', trigger: 'blur' },
          { min: 0, max: 10, message: "最多输入10个字", trigger: "blur" }
        ]
      },
      rules4: {
        inMoney: [
          { required: true, message: '请输入提现金额', trigger: 'blur' },
          { max: 18, pattern: /^\d+(\.{0,1}\d+){0,1}$/, message: '请输入合法的数字', trigger: 'blur'},
          { validator: this.checkAmount2, message: '提现金额不能小于0.01元且不能超过最大可提现金额', trigger: 'blur'}
        ],
        usage: [
          { required: false, message: '请输入备注信息', trigger: 'blur' },
          { min: 0, max: 10, message: "最多输入10个字", trigger: "blur" }
        ]
      },
      totalMoney: '',
      serialNum: '',
      saveFormData3: {},
      forbidden1: false,
      forbidden2: false,
      forbidden3: false,
      forbidden4: false,
      activeName: '1',
      listData1: [],
      listData2: [],
      listData3: [],
      filterList1: [],
      filterList2: [],
      minDate: '',
      maxDate: '',
      filterList3: [
        {
          label: "交易时间:（查询当天数据需置空此项）",
          type: "daterange",
          field: ["startDate", "endDate"],
          size: "small",
          pickerOptions: {
            onPick: ({maxDate, minDate}) => {
              this.minDate = minDate && minDate.getTime()
              this.maxDate = maxDate && maxDate.getTime()
            },
						disabledDate: function disabledDate(time) {
              const times =  86400000 * 90	// 选择时间跨度不能大于90天
              let current = new Date().getTime() - 86400000 // 不能选择当天
              let start = current - times
              if(this.$route.query.accountType == 1) {  // 中金限制查询时间
                if(this.minDate) {
                  return (
                    time.getTime() < this.minDate - times || time.getTime() > (this.minDate + times > current ? current: this.minDate + times)
                  )
                }
                return (
                  time.getTime() < start || time.getTime() > current
                )
              } else {
                return (
                  time.getTime() > current
                )
              }
						}.bind(this)
					},
        },
      ],
      total1: 0,
      total2: 0,
      total3: 0,
      loading: "",
      listLoading1: false,
      listLoading2: false,
      listLoading3: false,
      tailNumber: '',
      dialogTitle: '添加对公账户',
      isReadonly: false,
      dialogType: '',
      openAccountId: this.$route.query.openAccountId,
      accountType: this.$route.query.accountType, // 1中金支付 2上海银行
      imgDialogVisible: false,
      fileUrl: '',
      bankList: [], // 清算行集合
      ruleFormSh1: {
        acctBankObj: '',
        account: '',  
      },
      ruleFormSh2: {
        dynamicCode: '', // 验证码
      },
      ruleFormSh4: {
        date: ''
      },
      ruleFormSh6: {
        amount: '',
        usage: ''
      },
      ruleFormSh7: {
        name: '',  
        idType: '',
        idCard: '',
        mobile: '',
        operatorBackImg: "", //操作员身份证反面（人像面）
        operatorBackImgUrl: "", //操作员身份证反面（人像面）Url
        operatorFrontImg: "", //操作员身份证正面（国徽页）
        operatorFrontImgUrl: "", //操作员身份证正面（国徽页）Url
      },
      rulesSh1: {
        acctBankObj: [
          { required: true, message: '请选择绑定卡清算行行号', trigger: 'change' },
        ],
        account: [
          { required: true, message: '请输入绑定银行对公账号', trigger: 'blur' },
          { pattern: /^\d{12,}$/, message: "请输入正确的对公账号", trigger: "blur"}
        ],
      },
      rulesSh2: {
        dynamicCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ]
      },
      rulesSh4: {
        date: [
          { required: true, message: '请选择对账单期数', trigger: 'change' },
        ],
      },
      rulesSh6: {
        amount: [
          { required: true, message: '请输入提现金额', trigger: 'blur' },
          { max: 18, pattern: /^\d+(\.{0,1}\d+){0,1}$/, message: '请输入合法的数字', trigger: 'blur'},
          { validator: this.checkAmount3, message: '提现金额不能小于0.01元且不能超过最大可提现金额', trigger: 'blur'}
        ],
        usage: [
          { required: false, message: '请输入用途', trigger: 'blur' },
        ]
      },
      rulesSh7: {
        name: [
          { required: true, message: '请输入操作员姓名', trigger: 'blur' },
        ],
        idType: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        idCard: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}

        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: "请输入正确的手机号码", trigger: "blur"}
        ],
        operatorBackImg: [
          { required: true, message: "请上传操作员身份证人像面", trigger: "blur" }
        ],   
        operatorFrontImg: [
          { required: true, message: "请上传操作员身份证国徽面", trigger: "blur" }
        ], 
      },
      dialogVisibleSh0: false,
      dialogVisibleSh1: false,
      dialogVisibleSh2: false,
      dialogVisibleSh3: false,
      dialogVisibleSh4: false,
      dialogVisibleSh5: false,
      dialogVisibleSh6: false,
      dialogVisibleSh7: false,
      forbiddenSh1: false,
      forbiddenSh2: false,
      forbiddenSh3: false,
      forbiddenSh4: false,
      forbiddenSh6: false,
      forbiddenSh7: false,
      actiDeadline: '',
      verifyAmount: '',
      activateType: '',  // 激活类型 1激活账户 2操作员激活
      activateTitle: '',  // 激活弹框标题
      activateResult: '',  // 激活账户/操作员激活-结果
      activateResultText: '', // 激活账户/操作员激活-失败原因
      diaType: '',  // 验证码类型 1变更对公账号 2提现
      changeType: '', // 变更类型 1从未激活状态下变更 2正常变更
      count: 0, // 倒计时读秒
      idTypeList: [], // 证件类型集合
      filters3: {}, // 账户交易记录查询条件
      material: {},
      mobile: '',
      corpList: JSON.parse(sessionStorage.getItem('corpList')),  // 企业认证信息
      videoTypeObj2: {
        fileName: ''
      },
      videoTypeObj1: {
        fileName: ''
      },
      Authorization: { Authorization: sessionStorage.token },
    }
  },
  created() {
    this.fundAccount()
    if(this.accountType == 1) {
      this.suppBank()
      this.province()
    } else if(this.accountType == 2) {
      this.getShBankList()
    }
  },
  methods: {
    startLoading: function(){
      this.loading = this.$loading({
      lock: true,
      text: 'loading...',
      background: 'rgba(0, 0, 0, 0.3)',
      })
    },
    endLoading: function(){
      this.loading.close()
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    // 中金-查询支持银行列表
    suppBank() {
      api.suppBank('udk').then(res => {
        if(res.success) {
          this.suppBankList = res.datas.data
        }
      })
    },
    // 中金-开户银行变动
    changeBank() {
      this.ruleForm.ubankid = this.ruleForm.bankInfo.ubankid
      this.ruleForm.provInfo = this.ruleForm.provInfo.provcode ? {} : this.ruleForm.provInfo
      this.ruleForm.cityInfo = this.ruleForm.cityInfo.citycode ? {} : this.ruleForm.cityInfo
      this.ruleForm.payBankInfo = this.ruleForm.payBankInfo.payBankCode ? {} : this.ruleForm.payBankInfo
    },
    // 中金-查询全国省份列表
    province() {
      api.province().then(res => {
        if(res.success) {
          this.provinceList = res.datas.data
        }
      })
    },
    // 中金-查询某个省下面的全部城市信息
    city(params) {
      this.ruleForm.provcode = this.ruleForm.provInfo.provcode
      this.ruleForm.cityInfo = this.ruleForm.cityInfo.citycode ? {} : this.ruleForm.cityInfo
      this.ruleForm.payBankInfo = this.ruleForm.payBankInfo.payBankCode ? {} : this.ruleForm.payBankInfo
      api.city(params).then(res => {
        if(res.success) {
          this.cityList = res.datas.data
        }
      })
    },
    // 中金-查询网点信息
    payBank(bankCode,cityCode ) {
      this.ruleForm.citycode = this.ruleForm.cityInfo.citycode
      this.ruleForm.payBankInfo = this.ruleForm.payBankInfo.payBankCode ? {} : this.ruleForm.payBankInfo
      api.payBank({bankCode, cityCode}).then(res => {
        if(res.success) {
          this.payBankList = res.datas.data
        }
      })
    },
    // 中金-改变开户网点
    changePayBank() {
      this.ruleForm.payBankCode = this.ruleForm.payBankInfo.payBankCode
    },
    // 中金-绑卡
    tiedCard() {
      api.bindCardInfo().then(res => {
        if(res.success) {
          if(res.datas.serialNum) { // serialNum为null代表输入的对公账户信息已过期，需展示添加对公账户信息的弹框，否则直接进入验证
            this.dialogVisible2 = true
          } else {
            this.dialogVisible = true
            this.dialogType = 1
            if(res.datas.cardInfo) {
              let bankid = this.suppBankList.find(val => {
                return val.ubankid == res.datas.cardInfo.bkAccBkId
              }).bankid // 用于查询开户网点

              let provid = this.provinceList.find(val => {
                return val.provcode == res.datas.cardInfo.bkAccPrcCd
              }).provid // 用于查询城市

              this.city(provid)
              this.payBank(bankid, res.datas.cardInfo.bkAccCityCd)

              this.ruleForm = {
                accountName: res.datas.cardInfo.accountName,
                bkAccAccNo: res.datas.cardInfo.bkAccAccNo,
                bankInfo: {
                  ubankid: res.datas.cardInfo.bkAccBkId, 
                  bankname: res.datas.cardInfo.bkNm, 
                },
                provInfo: {
                  provcode: res.datas.cardInfo.bkAccPrcCd,
                  provname: res.datas.cardInfo.bkAccPrcNm,
                },
                cityInfo: {
                  citycode: res.datas.cardInfo.bkAccCityCd,
                  cityname: res.datas.cardInfo.bkAccCityNm,
                },
                payBankInfo: {  
                  payBankCode: res.datas.cardInfo.bkAccOpenCd,
                  payBankName: res.datas.cardInfo.bkAccOpenNm
                },
                ubankid: res.datas.cardInfo.bkAccBkId,
                provcode: res.datas.cardInfo.bkAccPrcCd,
                citycode: res.datas.cardInfo.bkAccCityCd,
                payBankCode: res.datas.cardInfo.bkAccOpenCd,
              }
            }
          }
        } else {
          this.$message.error(res.message)
        }
      }).catch(error => {
        reject(error)
      })
    },
    resetRuleForm() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm.accountName = this.form.cltNm
      this.ruleForm.bkAccAccNo = ''
      this.ruleForm.bankInfo = {}
      this.ruleForm.provInfo = {}
      this.ruleForm.cityInfo = {}
      this.ruleForm.payBankInfo = {}    
      this.ruleForm.ubankid = ''
      this.ruleForm.provcode = ''
      this.ruleForm.citycode = ''
      this.ruleForm.payBankCode = ''
    },
    cancelBtn() {
      this.resetRuleForm()
      this.dialogVisible = false
    },
    cancelBtn2() {
      this.$refs.ruleForm2.resetFields()
      this.dialogVisible2 = false
    },
    cancelBtn3() {
      this.$refs.ruleForm3.resetFields()
      this.dialogVisible3 = false
    },
    cancelBtn4() {
      this.$refs.ruleForm4.resetFields()
      this.dialogVisible4 = false
    },
    // 中金-提交绑卡
    submitTiedCard(fcFlg) {
      // fcFlg 1绑卡 2变更 3解绑
      if(fcFlg == 1) {
        let tiedCardRequest = {
          bkAccAccNo: this.ruleForm.bkAccAccNo,
          bkAccBkId: this.ruleForm.bankInfo.ubankid, 
          bkNm: this.ruleForm.bankInfo.bankname, 
          bkAccPrcCd: this.ruleForm.provInfo.provcode,
          bkAccPrcNm: this.ruleForm.provInfo.provname,
          bkAccCityCd: this.ruleForm.cityInfo.citycode,
          bkAccCityNm: this.ruleForm.cityInfo.cityname,
          bkAccOpenCd: this.ruleForm.payBankInfo.payBankCode,
          bkAccOpenNm: this.ruleForm.payBankInfo.payBankName,
          fcFlg
        }
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.forbidden1 = true
            api.submitTiedCard(tiedCardRequest).then(res => {
              if(res.success) {
                this.forbidden1 = false
                this.serialNum = res.datas.serialNum
                this.$alert("申请验证成功，请输入转帐金额完成验证。", "", {
                  confirmButtonText: "确定",
                  callback: action => {
                    this.tailNumber = this.ruleForm.bkAccAccNo.substring(this.ruleForm.bkAccAccNo.length - 4)
                    this.dialogVisible2 = true
                    this.dialogVisible = false
                    this.resetRuleForm()
                  }
                });
              } else {
                this.forbidden1 = false
                this.$message.error(res.message)
              }
            })
          }
        })
      } else if(fcFlg == 3) {
        let tiedCardRequest = {
          bkAccAccNo: this.form.bankCardNo,
          bkAccBkId: this.form.bkAccBkId,
          bkAccCityCd: this.form.bkAccCityCd,
          bkAccCityNm: this.form.bkAccCityNm,
          bkAccOpenCd: this.form.bkAccOpenCd,
          bkAccOpenNm: this.form.bkAccOpenNm,
          bkAccPrcCd: this.form.bkAccPrcCd,
          bkAccPrcNm: this.form.bkAccPrcNm,
          bkNm: this.form.bankName,
          sourAccNo: this.form.bankCardNo,
          fcFlg
        }
        this.$confirm('您确定要解绑对公账户吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          api.submitTiedCard(tiedCardRequest).then(res => {
            if(res.success) {
              this.$alert(res.message, "", {
                confirmButtonText: "确定",
                callback: action => {
                  this.fundAccount()
                }
              });
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => { });
        
      }
      
    },
    // 中金-提交校验金额
    submitAmount(amount) {
      this.$refs.ruleForm2.validate(valid => {
        if (valid) {
          this.forbidden2 = true
          api.submitAmount({}, {amount}).then(res => {
            if(res.success) {
              this.dialogVisible2 = false
              this.forbidden2 = false
              this.$alert(res.message, "", {
                confirmButtonText: "确定",
                callback: action => {
                  this.fundAccount()
                }
              });
            } else {
              this.forbidden2 = false
              this.dialogVisible2 = false
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 中金-充值弹框
    recharge() {
      this.dialogVisible3 = true
      this.forbidden3 = false
    },
    // 中金-充值
    inMoneyApply() {
      this.$refs.ruleForm3.validate(valid => {
        if (valid) {
          this.forbidden3 = true
          this.startLoading()
          api.inMoneyApply(this.ruleForm3).then(res => {
            if(res.success) {
              this.forbidden3 = false
              this.saveFormData3 = Object.assign({}, this.saveFormData3, res.datas.data)
              setTimeout(()=>{
                this.endLoading()
                this.$refs.submitBtn3.click()
              },10)
            } else {
              this.forbidden3 = false
              this.endLoading()
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 中金-提现弹框
    withdraw1() {
      this.dialogVisible4 = true
      this.forbidden4 = false
    },
    // 中金-校验充值金额
    checkAmount1(rule,value,callback) {
			if(+value < 0.01) {
        callback(new Error());
      }else{
				callback();
			}
    },
    // 中金-校验提现金额
    checkAmount2(rule,value,callback){
      let withdrawAmount = +this.form.outMoney
			if(+value > withdrawAmount){
				callback(new Error());
			} else if(+value < 0.01) {
        callback(new Error());
      }else{
				callback();
			}
		},
    // 中金-提现
    outMoneyApply() {
      this.$refs.ruleForm4.validate(valid => {
        if (valid) {
          this.forbidden4 = true
          this.startLoading()
          api.outMoneyApply(this.ruleForm4).then(res => {
            if(res.success) {
              this.forbidden4 = false
              this.dialogVisible4 = false
              this.endLoading()
              this.$alert(res.message + " 资金将会在24小时内到账", '', 
                {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.fundAccount()
                    this.handleRefresh2()
                    this.$refs.ruleForm4.resetFields()
                  }
                })
            } else {
              this.forbidden4 = false
              this.endLoading()
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 中金-操作日志弹框
    showLog() {
      this.dialogVisible5 = true
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh1(filters) {
      this.cashFlow(filters, 1);
    },
    handleRefresh2(filters) {
      this.cashFlow(filters, 2);
    },
    // 中金-获取操作日志
    cashFlow(filters, operationType) {
      let listData
      let total
      if(operationType == 1) {
        this.listLoading1 = true
      } else if(operationType == 2) {
        this.listLoading2 = true
      }
      let para = {
        operationType: operationType  // 1充值 2提现
      }
      Object.assign(para , filters)
      api.cashFlow(para).then(res => {
        if(res.success) {
          if(operationType == 1) {
            this.listLoading1 = false
            this.listData1 = res.datas.list
            this.total1 = res.datas.total
          } else if(operationType == 2) {
            this.listLoading2 = false
            this.listData2 = res.datas.list
            this.total2 = res.datas.total
          }
        } else {
          this.listLoading1 = false
          this.listLoading2 = false
          this.$message.error(res.message)
        }
      })
    },
    // 中金-对公账户信息
    showCardInfo() {
      this.isReadonly = true
      this.dialogType = 2
      let bankid = this.suppBankList.find(val => {
        return val.ubankid == this.form.bkAccBkId
      }).bankid // 用于查询开户网点
      
      let provid = this.provinceList.find(val => {
        return val.provcode == this.form.bkAccPrcCd
      }).provid // 用于查询城市
      this.city(provid)
      this.payBank(bankid, this.form.bkAccCityCd)
      this.dialogTitle = '对公账户信息'
      this.ruleForm = {
        accountName: this.form.cltNm,
        bkAccAccNo: this.form.bankCardNo,
        bankInfo: {
          ubankid: this.form.bkAccBkId, 
          bankname: this.form.bankName, 
        },
        provInfo: {
          provcode: this.form.bkAccPrcCd,
          provname: this.form.bkAccPrcNm,
        },
        cityInfo: {
          citycode: this.form.bkAccCityCd,
          cityname: this.form.bkAccCityNm,
        },
        payBankInfo: {  
          payBankCode: this.form.bkAccOpenCd,
          payBankName: this.form.bkAccOpenNm
        }
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    handleRefresh3(filters) {
      this.filters3 = filters
      let params = {
        accountType: this.accountType,
      }
      Object.assign(params , filters)
      api.loadFlow(params).then(res => {
        if(res.success) {
          this.listData3 = res.datas.list
          this.total3 = res.datas.total
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    // 账户明细
    fundAccount(filters) {
      let params = {
        openAccountId: this.openAccountId,
        accountType: this.accountType,
      }
      Object.assign(params , filters)
      api.fundAccount(params).then(res => {
        if(res.success) {
          this.form = {...res.datas.funAccountInfo, ...res.datas.corpInfo}
          this.corpFile = res.datas.corpFile
          this.totalMoney = 0..add(...[res.datas.funAccountInfo.money, res.datas.funAccountInfo.freezeMoney])
          this.ruleForm.accountName = res.datas.funAccountInfo.cltNm
          this.$previewRefresh()
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },

    // 上海银行-账户交易记录-弹框
    showTradingRecord() {
      this.dialogVisibleSh0 = true
    },
    // 上海银行-激活账号
    activateAccount1() {
      this.activateType = 1
      this.activateTitle = '激活账号'
      let params = {
        eAcctNo: this.form.subNo
      }
      api.openAccountSuccess(params).then(res => {
        if(res.success) {
          this.activateResult = true
          this.fundAccount()
        } else {
          this.activateResult = false
          this.activateResultText = res.message 
        }
        this.dialogVisibleSh5 = true
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    // 上海银行-获取清算行 
    getShBankList() {
      api.getShBankList().then(res => {
        if (res.success) {
          this.bankList = res.datas.bankList
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      });
    },
    // 上海银行-变更对公账号-弹框
    changeAccount(type) {
      // 获取申请中的绑卡信息
      // 1未激活  2已激活
      api.applyCardInfo({openAccountId: this.openAccountId, type}).then(res => {
        if (res.success) {
          let temp = res.datas.data
          this.ruleFormSh1.account = temp.bkAccAccNo 
          if(temp.bkAccBkId) {
            this.ruleFormSh1.acctBankObj = {}
            this.ruleFormSh1.acctBankObj.code = temp.bkAccBkId
            this.ruleFormSh1.acctBankObj.name = temp.bkNm
          }
          this.changeType = type
          this.dialogVisibleSh1 = true
          this.$nextTick(() => {
            this.$refs.ruleFormSh1.resetFields()
          })
          this.forbiddenSh1 = false
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      });
    },
    // 上海银行-变更对公账号-输入验证码
    submitChange1() {
      this.$refs.ruleFormSh1.validate(valid => {
        if (valid) {
          if(this.changeType == 1) {
            // 1从未激活状态下变更 无需发送验证码
            this.forbiddenSh1 = true

            let params = this.ruleFormSh1
            params.openAccountId = this.openAccountId
            params.operatorId = this.form.operator
            params.acctBank = this.ruleFormSh1.acctBankObj.code
            params.bkNm = this.ruleFormSh1.acctBankObj.name
            params.eAcctNo = this.form.subNo

            api.unAccountUpdateApply(params).then(res => {
              if(res.success) {
                if(res.success) {
                  this.dialogVisibleSh1 = false
                  this.$message.success(res.message)
                  this.fundAccount()
                } else {
                  this.forbiddenSh2 = false
                  this.$message.error(res.message);
                }
              } else {
                this.$message.error(res.message);
              }
            }).catch((err) => {
              this.$message.error('操作失败');
            })
          } else if (this.changeType == 2) {
            // 2正常变更 发送验证码
            this.dialogVisibleSh1 = false
            this.dialogVisibleSh2 = true
            this.diaType = 1
            this.forbiddenSh3 = true
            api.sendSMSOperator({operatorId: this.form.operator}).then(res => {
              if (res.success) {
                this.countDown()
              } else {
                this.countDown()
                this.$message.error(res.message);
              }
            }).catch((err) => {
              this.$message.error('操作失败');
            })
          }
        }
      })
    },
    // 上海银行-变更对公账号-提交
    submitChange2() {
      this.$refs.ruleFormSh2.validate(valid => {
        if (valid) {
          this.forbiddenSh2 = true
          let params = {...this.ruleFormSh1, ...this.ruleFormSh2}
          params.openAccountId = this.openAccountId
          params.operatorId = this.form.operator
          params.acctBank = this.ruleFormSh1.acctBankObj.code
          params.bkNm = this.ruleFormSh1.acctBankObj.name

          if(this.changeType == 1) {
            // 1从未激活状态下变更 
          } else if(this.changeType == 2) {
            // 2正常变更 
            api.accountUpdateApply(params).then(res => {
              if(res.success) {
                if(res.datas.data.resultCode == '00000000') {
                  this.$refs.ruleFormSh2.resetFields()
                  this.dialogVisibleSh2 = false
                  this.forbiddenSh2 = false
                  this.dialogVisibleSh3 = true
                  this.actiDeadline = res.datas.data.data.actiDeadline
                  this.verifyAmount = res.datas.data.data.amount
                  this.fundAccount()
                } else {
                  this.forbiddenSh2 = false
                  this.$message.error(res.datas.data.resultDesc);
                }
              } else {
                this.$message.error(res.message);
              }
            }).catch((err) => {
              this.$message.error('操作失败');
            })
          }
        }
      })
    },
    // 上海银行-重新发送验证码
    resend() {
      api.sendSMSOperator({operatorId: this.form.operator}).then(res => {
        if (res.success) {
          this.forbiddenSh3 = true
          this.countDown()
        } else {
          this.countDown()
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    resend2() {
      api.sendShSms({mobile: this.mobile}).then(res => {
        if (res.success) {
          this.forbiddenSh3 = true
          this.countDown()
        } else {
          this.countDown()
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    cancelBtnSh1() {
      this.$refs.ruleFormSh1.resetFields()
      this.dialogVisibleSh1 = false
    },
    cancelBtnSh2() {
      this.$refs.ruleFormSh2.resetFields()
      this.dialogVisibleSh2 = false
      clearInterval(this.countTimer);
    },
    // 上海银行-倒计时c
    countDown() {
      this.count = 60;
      this.countTimer = setInterval(() => {
        this.count --;
        if (this.count <= 0) {
          this.forbiddenSh3 = false
          clearInterval(this.countTimer);
        }
      }, 1000);
    },
    // 上海银行-激活变更账号
    activateAccount2() {
      this.activateType = 1
      this.activateTitle = '激活账号'
      let params = {
        operatorId: this.form.operator,
        openAccountId: this.openAccountId
      }
      api.accountUpdateApplyActivation(params).then(res => {
        if(res.success) {
          this.activateResult = true
          this.fundAccount()
        } else {
          this.activateResult = false
          this.activateResultText = res.message 
        }
        this.dialogVisibleSh5 = true
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    // 上海银行-校验提现金额
    checkAmount3(rule,value,callback){
      let withdrawAmount = +this.form.money
			if(+value > withdrawAmount){
				callback(new Error());
			} else if(+value < 0.01) {
        callback(new Error());
      }else{
				callback();
			}
		},
    // 上海银行-提现-弹框
    withdraw2() {
      this.dialogVisibleSh6 = true
      this.$nextTick(() => {
        this.$refs.ruleFormSh6.resetFields()
      })
      this.ruleFormSh6.eAcctNo = this.form.subNo
      this.forbiddenSh6 = false
    },
    // 上海银行-提现-输入验证码
    shWithdraw1() {
      this.$refs.ruleFormSh6.validate(valid => {
        if (valid) {
          // 发送验证码
          this.dialogVisibleSh6 = false
          this.dialogVisibleSh2 = true
          this.diaType = 2
          this.forbiddenSh3 = true
          api.sendSMSOperator({operatorId: this.form.operator}).then(res => {
            if (res.success) {
              this.countDown()
            } else {
              this.countDown()
              this.$message.error(res.message);
            }
          }).catch((err) => {
            this.$message.error('操作失败');
          })
        }
      })
    },
    // 上海银行-提现-提交
    shWithdraw2() {
      this.$refs.ruleFormSh2.validate(valid => {
        if (valid) {
          this.forbiddenSh2 = true
          let params = {...this.ruleFormSh6, ...this.ruleFormSh2}
          params.acctBank = this.form.bkAccBkId
          
          api.shWithdraw(params).then(res => {
            if(res.success) {
              this.$refs.ruleFormSh2.resetFields()
              this.dialogVisibleSh2 = false
              this.forbiddenSh2 = false
              this.$message.success(res.message);
              this.fundAccount()
            } else {
              this.forbiddenSh2 = false
              this.$message.error(res.message);
            }
          }).catch((err) => {
            this.$message.error('操作失败');
          })
        }
      })
    },
    cancelBtnSh6() {
      this.$refs.ruleFormSh6.resetFields()
      this.dialogVisibleSh6 = false
    },
    // 上海银行-变更操作员-弹框
    changeOperator() {
      this.dialogVisibleSh7 = true
      this.$nextTick(() => {
        this.ruleFormSh7 = {
          name: '',  
          idType: '',
          idCard: '',
          mobile: '',
          operatorBackImg: "", //操作员身份证反面（人像面）
          operatorBackImgUrl: "", //操作员身份证反面（人像面）Url
          operatorFrontImg: "", //操作员身份证正面（国徽页）
          operatorFrontImgUrl: "", //操作员身份证正面（国徽页）Url
        }
      })
      this.forbiddenSh7 = false
      // 获取证件类型 参数type为37
      api.getDicListByType({type: 37}).then(res => {
        if (res.success) {
          this.idTypeList = res.datas.list
          this.ruleFormSh7.idType = res.datas.list.find(v => v.name == '身份证').code  // 默认身份证
        } else {
          this.$message.error(res.message);
        }
      });

      // 获取开户数据
      api.openAccountResult({openAccountId: this.openAccountId}).then(res => {
        if(res.success) {
          if(res.datas.detail.material) {
            this.material = JSON.parse(res.datas.detail.material)
            this.material.tranType = 3 // 变更操作员
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      });
    },
    getFileType(type) {
      // type  front操作员身份证正面图片 back操作员身份证反面图片
      let fileType  // 3法人身份证正面图片 4 法人身份证反面图片 5经办人身份证正面图片 6经办人身份证反面图片 
      if(this.material.appApplyType == 1) {
        // appApplyType1 法人亲办
        if(type == 'front') {
          fileType = 3
        } else if(type == 'back') {
          fileType = 4
        }
      } else {
        // appApplyType2 授权经办
        if(type == 'front') {
          fileType = 5
        } else if(type == 'back') {
          fileType = 6
        }
      }
      let fileName = moment(new Date()).format('YYYYMMDD') + '-' + this.corpList.creditCode + '-' + fileType
      if(type == 'front') {
        this.videoTypeObj2.fileName = fileName + '.jpg'
      } else if(type == 'back') {
        this.videoTypeObj1.fileName = fileName + '.jpg'
      }
    },
    // 上海银行-文件上传
    uploadFileSftp() {
      return upload.uploadFileSftp();
    },
    beforeUpload(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      //判断文件大小 1M 以内
      const isLimitSize = file.size / 1024 / 1024 < 1
      if(!isLimitSize) {
        this.$message.error("上传文件大小不能超过 1MB!");
        return false;
      }
    },
    // 操作员身份证反面-成功回调
    uploadSuccess1(res) {
      if(res.success) {
        this.ruleFormSh7.operatorBackImg = res.datas.fileName;
        this.ruleFormSh7.operatorBackImgUrl = res.datas.uploadDate.message;
        this.$message.success('上传成功');
        this.$refs.ruleFormSh7.validateField('operatorBackImg');
      } else {
        this.$message.error(res.message);
      }
    },
    // 操作员身份证正面-成功回调
    uploadSuccess2(res) {
      if(res.success) {
        this.ruleFormSh7.operatorFrontImg = res.datas.fileName;
        this.ruleFormSh7.operatorFrontImgUrl = res.datas.uploadDate.message;
        this.$message.success('上传成功');
        this.$refs.ruleFormSh7.validateField('operatorFrontImg');
      } else {
        this.$message.error(res.message);
      }
    },
    // 操作员身份证反面-删除回调
    removeFile1(file) {
      this.ruleFormSh7.operatorBackImg = "";
      this.ruleFormSh7.operatorBackImgUrl = "";
    },
    // 操作员身份证正面-删除回调
    removeFile2(file) {
      this.ruleFormSh7.operatorFrontImg = "";
      this.ruleFormSh7.operatorFrontImgUrl = "";
    },
    // 上海银行-变更操作员-输入验证码
    changeOperator1() {
      this.$refs.ruleFormSh7.validate(valid => {
        if (valid) {
          this.dialogVisibleSh7 = false
          this.dialogVisibleSh2 = true
          this.diaType = 3
          this.forbiddenSh3 = true
          if(this.material.appApplyType == 1) {
            // appApplyType1 法人亲办
            this.mobile = this.ruleFormSh7.mobile
          } else if(this.material.appApplyType == 2) {
            // appApplyType2 授权经办
            this.mobile = this.material.corpMobile
          }
          // 发送验证码
          api.sendShSms({mobile: this.mobile}).then(res => {
            if (res.success) {
              this.countDown()
            } else {
              this.countDown()
              this.$message.error(res.message);
            }
          }).catch((err) => {
            this.$message.error('操作失败');
          })
        }
      })
    },
    // 上海银行-变更操作员-提交
    changeOperator2() {
      this.$refs.ruleFormSh2.validate(valid => {
        if (valid) {
          this.forbiddenSh2 = true
          if(this.material.appApplyType == 1) {
            // appApplyType1 法人亲办
            this.material.corpName = this.ruleFormSh7.name  
            this.material.legPerIdType = this.ruleFormSh7.idType
            this.material.legPerId = this.ruleFormSh7.idCard
            this.material.corpMobile = this.ruleFormSh7.mobile
            this.material.corpIdBackImg = this.ruleFormSh7.operatorBackImg //法人身份证反面（人像面）
            this.material.corpIdBackImgUrl = this.ruleFormSh7.operatorBackImgUrl //法人身份证反面（人像面）Url
            this.material.corpIdFrontImg = this.ruleFormSh7.operatorFrontImg//法人身份证正面（国徽页）
            this.material.corpIdFrontImgUrl = this.ruleFormSh7.operatorFrontImgUrl //法人身份证正面（国徽页）Url
          } else {
            // appApplyType2 授权经办
            this.material.checkerName = this.ruleFormSh7.name  
            this.material.checkerIdType = this.ruleFormSh7.idType
            this.material.checkerIdCard = this.ruleFormSh7.idCard
            this.material.checkerMobile = this.ruleFormSh7.mobile
            this.material.checkerIdBackImg = this.ruleFormSh7.operatorBackImg // 经办人身份证反面图片名称
            this.material.checkerIdBackImgUrl = this.ruleFormSh7.operatorBackImgUrl // 经办人身份证反面图片名称Url
            this.material.checkerIdFrontImg = this.ruleFormSh7.operatorFrontImg // 经办人身份证正面图片名称
            this.material.checkerIdFrontImgUrl = this.ruleFormSh7.operatorFrontImgUrl // 经办人身份证正面图片名称Url
          }
          let params = this.material
          params.dynamicCode = this.ruleFormSh2.dynamicCode
          api.openAccount(params).then(res => {
            if (res.success) {
              this.$refs.ruleFormSh2.resetFields()
              this.dialogVisibleSh2 = false
              this.forbiddenSh2 = false
              // this.dialogVisibleSh3 = true
              // this.actiDeadline = res.datas.data.data.actiDeadline
              // this.verifyAmount = res.datas.data.data.amount
              this.$message.success(res.message);
              this.fundAccount()
            } else {
              this.forbiddenSh2 = false
              this.$message.error(res.datas.obj.resultDesc);
            }
          }).catch((err) => {
            this.$message.error('操作失败');
          })
        }
      })
    },
    cancelBtnSh7() {
      this.ruleFormSh7 = {
        name: '',  
        idType: '',
        idCard: '',
        mobile: '',
        operatorBackImg: "", //操作员身份证反面（人像面）
        operatorBackImgUrl: "", //操作员身份证反面（人像面）Url
        operatorFrontImg: "", //操作员身份证正面（国徽页）
        operatorFrontImgUrl: "", //操作员身份证正面（国徽页）Url
      }
      this.dialogVisibleSh7 = false
    },
    // 上海银行-操作员激活-弹框
    activateOperator() {
      this.activateType = 2
      this.activateTitle = '操作员激活'
      let params = {
        operatorId: this.form.operator,
        openAccountId: this.openAccountId
      }
      api.operatorInformationModificationSuccess(params).then(res => {
        if(res.success) {
          this.activateResult = true
          this.fundAccount()
        } else {
          this.activateResult = false
          this.activateResultText = res.message 
        }
        this.dialogVisibleSh5 = true
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    // 上海银行-下载对账单 打开弹框
    printBill() {
      this.dialogVisibleSh4 = true
    },
    // 上海银行-下载对账单
    rlectronicReceipt() {
      this.$refs.ruleFormSh4.validate(valid => {
        if (valid) {
          this.forbiddenSh4 = true
          this.ruleFormSh4.eAcctNo = this.form.subNo
          api.rlectronicReceipt(this.ruleFormSh4).then(res => {
            if(res.success) {
              this.forbiddenSh4 = false
              if(res.datas.data) { // 有数据
                let URL = this.dataURLtoBlob(res.datas.data);
                let reader = new FileReader();
                reader.readAsDataURL(URL);
                reader.onload = function (e) {
                  // 兼容IE
                  if (window.navigator.msSaveOrOpenBlob) {
                    let bstr = atob(e.target.result.split(",")[1]);
                    let n = bstr.length;
                    let u8arr = new Uint8Array(n);
                    while (n--) {
                      u8arr[n] = bstr.charCodeAt(n);
                    }
                    let blob = new Blob([u8arr]);
                    window.navigator.msSaveOrOpenBlob(blob,'对账单.pdf');
                  } else {
                    // 转换完成，创建一个a标签用于下载
                    let a = document.createElement('a');
                    a.download = '对账单.pdf';
                    a.href = e.target.result;
                    a.setAttribute("id", "export")
                    a.click();
                    document.getElementById("export").remove();
                  }
                }
              } 
            } else {
              this.forbiddenSh4 = false
              this.$message.error(res.message);
            }
          }).catch((err) => {
            this.forbiddenSh4 = false
            this.$message.error('操作失败');
          })
        }
      })
    },
    // 上海银行-下载电子回单
    printReceipt(row) {
      let currentDate = moment(new Date().getTime()).format('YYYYMMDD')
      let params = {
        eAcctNo: this.form.subNo,
        stmt: row.stmt, // 核心分录号
        startDate: (this.filters3.startDate && this.filters3.startDate.replace(/-/g, "")) || currentDate,
        endDate: (this.filters3.endDate && this.filters3.endDate.replace(/-/g, "")) || currentDate,
      }
      api.printReceipt(params).then(res => {
        if(res.success) {
          if(res.datas.data) { // 有数据
            let URL = this.dataURLtoBlob(res.datas.data);
            let reader = new FileReader();
            reader.readAsDataURL(URL);
            reader.onload = function (e) {
              // 兼容IE
              if (window.navigator.msSaveOrOpenBlob) {
                let bstr = atob(e.target.result.split(",")[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                let blob = new Blob([u8arr]);
                window.navigator.msSaveOrOpenBlob(blob,'回单.pdf');
              } else {
                // 转换完成，创建一个a标签用于下载
                let a = document.createElement('a');
                a.download = '回单.pdf';
                a.href = e.target.result;
                a.setAttribute("id", "export")
                a.click();
                document.getElementById("export").remove();
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        this.$message.error('操作失败');
      })
    },
    //base64 转换 blob
    dataURLtoBlob(dataurl) {
      let bstr = atob(dataurl)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
    　while (n--) {
  　　　u8arr[n] = bstr.charCodeAt(n);
    　}
    　return new Blob([u8arr], { type: 'pdf' });
    },
    showOperatorStatus(status) {
      if(status) {
        if(status == 1) {
          return '（变更中）'
        } else if(status == 2) {
          // 变更成功
        } else if(status == 3) {
           return '（变更失败）'
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.account-manage {
  margin: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  .manage-info {
    position: relative;
    width: 50%;
    padding: 30px 30px 100px;
  }
  .manage-base-info {
    background-color: #D9D9D9;
  }
  .manage-title {
    font-weight: bold;
    font-size: 14px;
    color: #222;
    margin-bottom: 20px;
    position: relative;
  }
  .log-box {
    position: absolute;
    top: 0;
    right: 0;
    color: #2937A6;
    cursor: pointer;
  }
  .manage-info-ul {
    color: #555;
    line-height: 40px;
    padding-left: 0;
    .info-style {
      color: #000;
    }
    .amount-style {
      font-size: 36px;
      font-weight: bold;
    }
  }
  .license {
    width: 120px;
    vertical-align: top;
    cursor: pointer;
  }
  .btn-box {
    position: absolute;
    width: 100%;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 50px;
    .el-button {
      padding: 12px;
    }
    .small-btn {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
.account-number {
  text-align: right;
  font-weight: bold;
  padding: 20px 20px 0;
}
.link-style {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
  display: block;
}
.ruleForm2-style {
  margin-left: 0px;
  margin-top: 20px;
}
.dialog-info-title {
  font-weight: bold;
}
.base-info-ul {
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  li {
    display: inline-block;
    width: 49%;
    line-height: 36px;
  }
}
.ruleForm-box {
  width: 80%;
  margin: 30px auto;
}
</style>
<style lang="scss">
  .add-account {
    .el-select {
      width: 100%;
    }
    .el-table td, .el-table th {
      padding: 6px 0;
    }
    .el-tabs__header {
      margin: 0;
    }
    &.log-dialog {
      .el-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
        height: 90%;
        overflow-y: auto;
      }
    }
    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }
    .el-tabs--card>.el-tabs__header .el-tabs__item:first-child {
      border-left: 1px solid #e4e7ed;
    }
  }
  .certificates{
    display: flex;
    flex-flow: row;
    .certificates_item{
      display: flex;
      flex-flow: column;
      margin: 0 10px;
    }
  }
</style>


