<template>
  <div :class="{'limit-one' : limit === readFileList.length, 'readonly' : readonly}">
    <el-upload
    :limit="limit"
        :headers="headers"
        :action="fileUploadUrl()"
        :multiple="multiple"
        :list-type="listType"
        :show-file-list="showFileList"
        :file-list="fileList"
        :disabled="readonly"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :before-upload="handleBeforeUpload">
            <slot>
                <i class="el-icon-plus"></i>
            </slot>
        </el-upload>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import upload from "@/api/upload";
import api from "@/api/api";

export default {
    name: 'ImgUpload',
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number
        },
        multiple: {
            type: Boolean,
            default: false
        },
        imgTypeList: {
            type: Array,
            default: () => ['.jpg', '.png', '.jpeg', '.bmp']
        },
        listType: {
            type: String,
            default: 'picture-card'
        },
        showFileList: {
            type: Boolean,
            default: true
        },
        fileCode: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            headers: {
                Authorization: sessionStorage.getItem('token')
            },
            fileList: [],
            readFileList: [],
            dialogImageUrl: '',
            dialogVisible: false
        }
    },
    methods: {
        fileUploadUrl() {
            return upload.uploadFileSftp();
        },
        handleSuccess(res, file, fileList) {
            this.readFileList = this.setReadFileList(fileList)
            this.$emit('input', this.readFileList)
            this.$emit('success', res, file, this.readFileList)
        },
        handleRemove(file, fileList) {
            this.readFileList = this.setReadFileList(fileList)
            this.$emit('input', this.readFileList)
            this.$emit('remove', fileList, this.readFileList)
        },
        handleExceed() {
            this.$message.warning('当前限制选择' + this.limit + '个文件')
        },
        handleBeforeUpload(file) {
            const suffixReg = /\.[^\.]+$/
            const fileSuffix = suffixReg.exec(file.name)[0].toLowerCase()
            if (!_.includes(this.imgTypeList, fileSuffix)) {
                this.$message.error('文件格式错误')
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
        },
        handlePictureCardPreview(file) {
            let url
            if (this.listType === 'picture-card') {
                url = file.url
            } else if (this.listType === 'text') {
                url = upload.downFile() + '?filePath=' + file.response.datas.uploadDate.message + '&fileName=img'
            }
            this.dialogImageUrl = url
            this.dialogVisible = true
        },
        setReadFileList(fileList) {
            if (!_.isEmpty(fileList)) {
                return _.map(fileList, item => {
                    if (!_.isEmpty(item.response)) {
                        return {
                            fileName: item.name,
                            // url: upload.downFile() + '?filePath=' + item.response.datas.uploadDate.message + '&fileName=img'
                            fileUrl: item.response.datas.uploadDate.message,
                            fileCode: this.fileCode
                        }
                    }
                    return {
                        fileName: item.name,
                        fileUrl: item.url,
                        fileCode: this.fileCode
                    }
                })
            }
            return []
        },
        setFileList(arr) {
            const fileList = _.map(arr, item => {
                return {
                    name: item.fileName,
                    url: api.getImgURL(item.fileUrl)
                }
            })
            this.fileList = fileList
            this.readFileList = arr
            this.$emit('input', arr)
        },
        getFileList() {
            return this.readFileList
        }
    }
}
</script>

<style lang="less">
.limit-one .el-upload.el-upload--picture-card, .readonly .el-upload.el-upload--picture-card{
    display: none;
}

</style>

