<!--用户账户-->
<template>
  <layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList address-management">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list :filters="filterList" @data-refresh="handleRefresh" :total="total" ref="datalist">
          <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row v-loading="listLoading" style="width: 100%;">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="serialNo" label="流水号" min-width="180"></el-table-column>
            <el-table-column prop="type" label="交易类型" min-width="120" :formatter="typefn"></el-table-column>
            <el-table-column prop="accountType" label="结算渠道商" min-width="120" :formatter="statusfn"></el-table-column>
            <el-table-column prop="orderNo" label="业务编号" min-width="150"></el-table-column>
                    <el-table-column prop="amount" label="付款金额" min-width="150">
              <template slot-scope="scope">
                {{$changeMoneyThousand(scope.row.amount)}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="付款时间" min-width="170">
              <template slot-scope="scope">{{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountNoNm" label="付款企业" min-width="180"></el-table-column>
            <el-table-column prop="accountNo" label="付款账号" min-width="220"></el-table-column>

            <el-table-column prop="accountNoTwoNm" label="收款企业" min-width="180"></el-table-column>
            <el-table-column prop="accountNoTwo" label="收款账号" min-width="220"></el-table-column>
            <!-- <el-table-column prop="" label="交易状态" min-width="200"></el-table-column> -->
            <el-table-column prop="address" label="备注" min-width="150"></el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
    </div>
  </layout-main-view>
</template>
<script>
  import api from "@/api/api.js";
  import moment from 'moment'

  export default {
    data() {
      return {
        breadcrumblist: ['账务管理'],
        activeName: "1",
        filterList: [
          {
            label:'流水号：',
            type:'input',
            field:'serialNo',
            // value: 'xxx'
            size: "small"
          },
          {
            label:'业务编号：',
            type:'input',
            field:'orderNo',
            // value: 'xxx'
            size: "small",
          },
        ],
        total: 0,
        listLoading: false,
        listData: [],
        pageNo: 1,
        pageSize: 10,
        filters: '',
      };
    },
    created() {
    },
    methods: {
      moment: moment,
      headerStyle({row, rowIndex}) {
        return {background: "#FAFAFA"};
      },
      handleRefresh(filters) {
        this.filters = filters
        this.getLists(filters);
      },
      getLists(param) {
        param.corpId = JSON.parse(sessionStorage.user).relationCorpId
        api.financialManagement(param).then(res => {
          if (res.success) {
            this.listData = res.datas.list;
            this.total = res.datas.total;
          }
          this.listLoading = false;
        });
      },
      typefn(val) {
        let state=''
        switch (val.type) {
          case '1':
            state='收入'
            break;
          case '2':
            state='支出'
            break;
        }
        return state
      },
      statusfn(val){
        let state=''
        switch (val.accountType) {
          case 1:
            state='中金支付'
            break;
          case 2:
            state='上海银行'
            break;
        }
        return state
      },
    }
  };
</script>

<style lang="scss" scoped>
@import "../../styles/moreDetail/moudleDetail.scss";
</style>
