<template>
  <div>
      授信详情1
  </div>
</template>

<script>
export default {
    name:"fundCreditDetail"
}
</script>

<style>

</style>