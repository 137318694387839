<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <absbindaccountsno :datalist='datalist' :moneytotal='moneytotal'></absbindaccountsno>
        </div>
        </layout-main-view>

    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  absbindaccountsno from '@/views/absbase/absbindaccountsno' 


 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划简介'],
                datalist:[],
                moneytotal:0,
                absPlanDto:{},
                absPlanDetailDto:{}

     
            }
        },
        created() {
             api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
   
                }
            });
               api.getSubmittedDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                        this.datalist=res.datas.detail.list;
                        this.moneytotal=res.datas.detail.totalAmount


   
                }
            })
        },
        methods: {
    
        },
        components: {
           absaccountxmgk,
           absbindaccountsno

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }

   }
</style>