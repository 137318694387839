<!-- 票据详情 -->
<template>
    <div class="abs-check-details">
        
            <div class="abs-title">绑定账款: </div>
              <div class="httotal">
            已添加账款
            <span>{{total}}</span> 笔，账款总计金额
            <span>{{$changeMoneyThousand(totalmoney)}}</span> 元。
         </div>

  
        <el-table
          :data="listData"
            align='center'
          highlight-current-row
@selection-change="handleSelectionChange"
          style="width: 100%;"
        >
            <el-table-column
      type="selection"
      width="80">
    </el-table-column>
          <el-table-column prop="absAccountNo" label="资产账款编号" min-width="180"></el-table-column>
                      <el-table-column prop="claimsCorpName" label="债权企业" min-width="210" v-if="planCorpType==2" ></el-table-column>
                   <el-table-column prop="debtCorpName" label="债务企业" min-width="210" v-if="planCorpType==3"></el-table-column>
          <el-table-column  label="账款金额(元)"  min-width="160" :formatter='el=>$changeMoneyThousand(el.absAccountAmount)'></el-table-column>
          <el-table-column prop="delistingCorpName" label="签署日期" min-width="210">
              <template slot-scope="scope">
  {{moment(scope.row.absAccountSignDate).format("YYYY-MM-DD")}}
             
            </template>
          </el-table-column>
          <el-table-column  label="计划付款日"  min-width="190">
              <template slot-scope="scope">
  {{moment(scope.row.absAccountCloseDate).format("YYYY-MM-DD")}}


           </template>
          </el-table-column>


          <el-table-column label="操作" width="260" align="left">
            <template slot-scope="scope">
                <router-link  target="_blank" :to="{path:'/abs/abscountinfo',query:{id:scope.row.absAccountId,infoNode:1}}" class="initiation">查看详情</router-link>
              
            </template>
          </el-table-column>
        </el-table>

       
        
       
    </div>
</template>
<script>
    import moment from 'moment'
import api from "@/api/api.js";




    export default {
      
           props: {
            planCorpType: {
                type: Number,
                default() {
                    return 0
                }
            },

            
        },
        data() {
            return {
                listData:[],
                total:0,
                totalmoney:0,
                chooselist:[]
              
            }
        },
        created() {
            this.getlist()
     
        },
        methods: {
            moment: moment,
            getlist(){
                var param={absPlanId:this.$route.query.id,pageSize:100,pageNum:1}
                        api.getPlanAccountListPage(param).then(res => {
                            if (res.success) {
                            this.listData = res.datas.list;
      
                            
                            }
                        });


            },
            handleSelectionChange(row){
                this.total=row.length;
                this.chooselist=row
                var arr=[]
                row.map(el=>arr.push(el.absAccountAmount))
                this.totalmoney=0..add(...arr)
                    console.log(row,'ssss',this.totalmoney)
            },
        
     
        }
    }
</script>
<style lang="scss">
  .abs-check-details{
       .abs-title{
           font-size: 16px;
       }   
       .httotal{
           margin: 20px 0 40px 0;
       }
  }

  
 
  
</style>