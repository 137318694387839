<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">

      
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column prop="financePayableBillNo" label="融资子优企通编号" min-width="220"></el-table-column>
          <el-table-column prop="financeCorpName" label="融资企业" min-width="170"></el-table-column>
          <el-table-column prop="amount" label="融资总额（元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
          </el-table-column>
          <el-table-column prop="financeYearInterest" label="融资年化利率（％）" min-width="150"></el-table-column>
          <el-table-column prop="preFinanceInterest" label="融资利息(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preFinanceInterest)}}</template>
          </el-table-column>
          <el-table-column prop="preIncomeAmount" label="预计融资金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preIncomeAmount)}}</template>
          </el-table-column>
          <el-table-column prop="financeSignStatusShow" label="签署状态" min-width="150"></el-table-column>
          <!-- <el-table-column prop="status" label="优企通状态" width="100" align="left"></el-table-column> -->
          <el-table-column label="操作" width="120">
            <!-- <template slot-scope="scope">
                          <el-button
                            v-if="scope.row.sign == 2"
                            size="medium"
                            type="text"
                            @click="handleEdit(scope.row)"
                          >签约</el-button>
                          <el-button v-else size="medium" type="text" @click="checkDetial(scope.row)">查看详情</el-button>
            </template>-->

            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
              <div v-if="scope.row.sign == 2" style="display: inline-block;">
                <div v-if="scope.row.financeSignType == null">
                  <el-button size="medium" type="text" @click="handleEdit(scope.row)"  :disabled="scope.row.financeSignStatusShow=='签约待确认'" >签约</el-button>
                </div>
                <div v-else>
                  <el-button
                    v-if="scope.row.financeSignType == 2 || scope.row.financeSignType == 1"
                    size="medium"
                    type="text"
                    @click="offLineSign(scope.row)"
                  >签约</el-button>
                  <el-button v-else size="medium" type="text" @click="checkDetial(scope.row)">详情</el-button>
                </div>
              </div>
              <div v-if="scope.row.sign == 1" style="display: inline-block;">
                <el-button size="medium" type="text" @click="checkDetial(scope.row)">详情</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>

    <el-dialog title :visible.sync="signDialogVisible" width="50%" center class="img-dialog">
      <div v-if="row.financeSignType == null">
        <el-radio v-model="radio" label="1">线上签约</el-radio>
        <!-- <el-radio v-model="radio" label="2">上传待签约合同</el-radio> -->
        <el-radio v-model="radio" label="3">上传已签约合同</el-radio>
            <div v-if="radio == 1" class="uploadfont wxtsk uploadContract">
            温馨提醒：当前资金机构还未设置在线合同模版，请联系客服人员配置后，在使用在线签约
            <br />
        </div>
           <div v-if="radio == 2" class="uploadfont wxtsk uploadContract">
              温馨提醒：
              1. 请上传待签约合同文件进行电子签章，格式仅限PDF
              2. 为确保合同盖章正确，请确认上传合同包含以下盖章关键字。如示：甲方（签章）   乙方（签章）
            <br />
        </div>
           <div v-if="radio == 3" class="uploadfont wxtsk uploadContract">
            温馨提醒：确定双方已完成线下签约，请上传已签约合同附件，确认签约成功。合同附件格式PDF
          <br />
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
        <el-button @click="signDialogVisible = false" style="
    margin-right: 50px;
">取 消</el-button>
        <el-button v-if="radio == 1" type="primary" @click="sure">确 定</el-button>
        <div v-else>
          <el-upload
            class="upload-demo upload-progress-hiden"
            :action="uploadFile()"
            :before-upload="beforeUp"
            :on-success="(a)=>uploadOk(a)"
            :data="fileTypes"
            :headers="Authorization"

          >
            <el-button
              style="margin-left: 10px;width: 74px;height: 40px;background-color: rgb(47, 85, 151);color: rgb(255, 255, 255);"
              size="medium"
              type="text"
              @click="offLine(row)"
            >确 定</el-button>
          </el-upload>
        </div>
      </span>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import upload from "@/api/upload.js";
import moment from "moment";
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  },
  data() {
    return {
      Authorization: {Authorization: sessionStorage.token},
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "payableBillFinanceNo",
          size: "small"
        },
        // {
        //   label: "优企通编号:",
        //   type: "input",
        //   field: "payableBillNo",
        //   size: "small"
        // },
        {
          label: "融资企业:",
          type: "input",
          field: "financeCorpName",
          size: "small"
        },
        {
          label: "融资金额: ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small"
        }
        // {
        //   label: "优企通利率: ",
        //   type: "inputrange",
        //   field: "financeInterestMin",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "financeInterestMax",
        //   size: "small"
        // },
        // {
        //   label: "承诺付款日:",
        //   type: "daterange",
        //   field: ["promiseStartTime", "promiseEndTime"],
        //   size: "small"
        // }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      fileTypes: {
        payableBillFinanceId: ""
      },
      radio: "1",
      signDialogVisible: false,
      row: {},
      filters: {},
      src: "",
      centerDialogVisible: false,
      loading: ""
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    moment: moment,
    startLoading: function() {
      this.loading = this.$loading({
        lock: true,
        text: "文件上传中",
        background: "rgba(0, 0, 0, 0.5)"
      });
    },
    endLoading: function() {
      this.loading.close();
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      (filters.review = 2), (filters.source = 2), (this.filters = filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      api.GetSignApproveList(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      this.signDialogVisible = true;
      this.row = row;
    },
    uploadFile() {
      if (this.radio == 2) {
        //上传待签约合同
        return upload.uploadOffineContractSign();
      } else {
        //上传已签约合同
        return upload.uploadContract();
      }
    },
    uploadOk(response) {
      if (response.code == "100000") {
        this.endLoading()
        this.$message.success('上传成功');
        this.signDialogVisible = false;
        this.handleRefresh(this.filters);
        
      } else {
        this.endLoading()
        this.$message.error("上传失败");
      }
    },
    offLine(row) {
      this.fileTypes.payableBillFinanceId = row.payableBillFinanceId;
    },
    //上传前配置
    beforeUp(file) {
      let excelfileExtend = ".pdf"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      this.startLoading()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    sure() {
      var isshowbtn;
      if (this.row.sign == 2) {
        isshowbtn = 1;
      } else {
        isshowbtn = 2;
      }
      this.$router.push({
        name: "资方融资签约详情",
        query: {
          payableBillId:this.row.payableBillId,
          payableBillFinanceId: this.row.payableBillFinanceId,
          payableBillFinanceNo: this.row.payableBillFinanceNo,
          fundCorpId: this.row.fundCorpId,
          isshowbtn: isshowbtn,
          financeSignStatus: this.row.financeSignStatus,
          approveStatus: this.row.approveStatus,
          financeSignStatus: this.row.financeSignStatus
        }
      });
    },
    checkDetial(row) {
      this.$router.push({
        name: "资方融资签约详情",
        query: {
          payableBillFinanceId: row.payableBillFinanceId,
          payableBillFinanceNo: row.payableBillFinanceNo,
          // financeCorpId: row.financeCorpId,
          fundCorpId: row.fundCorpId,
          isshowbtn: 2,
          financeSignStatus: row.financeSignStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus
        }
      });
    },
    offLineSign(row) {
      this.$router.push({
        name: "资方融资签约详情",
        query: {
          payableBillFinanceId: row.payableBillFinanceId,
          payableBillFinanceNo: row.payableBillFinanceNo,
          fundCorpId: row.fundCorpId,
          isshowbtn: 1,
          financeSignStatus: row.financeSignStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus
        }
      });
    },
    // 预览优企通
    preview(row) {
      window.open(api.getImgURL(row.evidencePath))
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   let binaryData = [];
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
      //   this.centerDialogVisible = true;
      //   this.listLoading = false;
      // });
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";

.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
