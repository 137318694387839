<template>
    <div>
        <!-- 核心企业    即显现出来的 授信企业目录 -->

        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                :filters="filterList"
                :isShowAll="isShowAll"
                :isHedden="isHedden"
                @data-showAll="filterShowAll"
                @data-refresh="handleRefresh"
                :total="total"
                ref="datalist"
            >
                <el-table
                :data="listData"
                :header-cell-style="headerStyle"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%;"
                >
                    <!-- <el-table-column label="序号" min-width="170"></el-table-column> -->
                    <el-table-column prop="corpName" label="企业名称" min-width="220" />

                    <el-table-column prop="corpCode" label="统一社会信用代码"  min-width="220"></el-table-column>
                    <el-table-column prop="corpIndustrySecond" label="所属行业" min-width="120"></el-table-column>
                    
                    <el-table-column
                      prop="createTime"
                      label="注册时间"
                      min-width="120"
                      :formatter="el=>moment(el.closeDate).format('YYYY-MM-DD')"
                    />
                    <el-table-column
                      prop="identificationTime"
                      label="认证时间"
                      min-width="120"
                      :formatter="el=>moment(el.closeDate).format('YYYY-MM-DD')"
                    />
                    <el-table-column label="操作" min-width="170">
                        <template slot-scope="scope">
                            <el-button size="medium" type="text" @click="handShow(scope.row)" >查看企业资料</el-button>
                        </template>
                    </el-table-column> 
                   
                </el-table>
            </data-list>

        </el-tabs>
    </div>
</template>

<script>
import api from "@/api/api";
import moment from 'moment';
export default {
    data(){
        return{
            isShowAll: false,
            isHedden: false,
            filterList: [
                {
                    label: "企业名称:",
                    type: "input",
                    field: "corpName",
                    size: "small"
                },
                {
                    label: "信用代码:",
                    type: "input",
                    field: "corpCode",
                    size: "small"
                },
            ],
            total: 0,
            activeName: "1",


            listData:[],
            listLoading: false,

        }
    },
     methods:{
        moment: moment,
        filterShowAll() {
            this.isShowAll = !this.isShowAll;
        },
        handleRefresh(filters) {
            this.getLists(filters);
        },
        headerStyle({ row, rowIndex }) {
            return { background: "#FAFAFA" };
        },
        getLists(param){
            //请求参数  type为1 时，请求的是 授信企业目录数据
            let data = Object.assign({
                type :1
            }, param)

            api.getFundEnterpriseListByPage(data).then((res)=>{
                this.listLoading = true;
                console.log(res);
                if(res.success){
                    this.listData = res.datas.list;
                    this.total = res.datas.total;
                    this.listLoading = false;
                }else{
                    this.$message({
                        message:res.message,
                        type: "error"
                    })
                }
                
            })
        },

        handShow(row){
            this.$router.push({
              name: "资方企业目录详情",
              query: { 
                  corpId:row.corpId
              }
            });
        }



    }
}
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>