<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList" style="padding: 10px 24px">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
          <el-button @click="addApply" type="primary">授信申请</el-button>
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column prop="grantCreditNo" label="授信编号" min-width="80">
          </el-table-column>
          <el-table-column prop="grantCorpName" label="企业名称" min-width="80">
          </el-table-column>
          <el-table-column
            prop="availableAmount"
            label="授信额度（元）"
            min-width="80"
          >
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.availableAmount)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="fundYearInterest"
            label="资金年利率（%）"
            min-width="80"
          >
            <template slot-scope="scope">{{
              scope.row.fundYearInterest ? scope.row.fundYearInterest : '--'
            }}</template>
          </el-table-column>
          <el-table-column prop="usedAmount" label="用信额度" min-width="80">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.usedAmount)
            }}</template>
          </el-table-column>
          <el-table-column prop="remainAmount" label="可用额度" min-width="80">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.remainAmount)
            }}</template>
          </el-table-column>
          <el-table-column prop="creditDays" label="授信期限" min-width="80">
            <template slot-scope="scope">
              {{ moment(scope.row.effectiveTime).format('YYYY-MM-DD') }} ~
              {{ moment(scope.row.closeDate).format('YYYY-MM-DD') }}</template
            >
          </el-table-column>
          <el-table-column prop="" label="审核状态" min-width="80">
            <template slot-scope="scope">
              {{ getCheckStatus(scope.row.grantCheck) }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="申请时间" min-width="80">
            <template slot-scope="scope">
              {{
                moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss')
              }}</template
            >
          </el-table-column>
          <el-table-column label="操作" min-width="80" >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.grantCheck == 10 || scope.row.grantCheck == 26"
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                v-else
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </div>
  </layout-main-view>
</template>

<script>
import layoutMainView from '../../components/layout-main-view.vue';
import api from '@/api/api';
import moment from 'moment';
export default {
  components: { layoutMainView },
  name: 'fundCreditApply',
  data() {
    return {
      breadcrumblist: ['授信申请管理'],
      isShowAll: false,
      isHedden: false,
      api: api,
      total: 1,
      listData: [],
      filterList: [
        {
          label: '企业名称',
          type: 'input',
          field: 'grandCorpName',
          size: 'small',
        },
        {
          label: '审核状态',
          type: 'select',
          field: 'grantCheck',
          size: 'small',
          options: [
            { label: '授信待提交', value: 10 },
            { label: '资金方授信待复核', value: 20 },
            { label: '资金方复核通过', value: 25 },
            { label: '资金方复核回退', value: 26 },
            { label: '平台待初审', value: 40 },
            { label: '平台初审通过', value: 41 },
            { label: '平台初审回退', value: 42 },
            { label: '平台授信回退', value: 46 },
            { label: '授信通过', value: 99 },
            { label: '授信过期', value: 999 },
          ],
        },
        {
          label: '授信期限日:',
          type: 'daterange',
          field: ['startTime', 'endTime'],
          size: 'small',
        },
      ],
    };
  },
  created() {
    // this.getFundApplyListByPage()
  },
  methods: {
    moment: moment,
    getFundApplyListByPage(filters) {
      api.getFundApplyListByPage(filters).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    addApply() {
      this.$router.push({
        path: '/fund/credit/add',
      });
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    getLists(filters) {
      this.getFundApplyListByPage(JSON.stringify(filters));
    },
    handleEdit(row) {
      console.log(row.grantCheck, 'grantCheck');
      if (row.grantCheck == 10 || row.grantCheck == 26) {
        this.$router.push({
          path: '/fund/credit/add',
          query: {
            grantCheck: row.grantCheck,
            grantCreditId: row.grantCreditId,
            row,
          },
        });
      } else {
        this.$router.push({
          path: '/fund/credit/add/detail',
          query: {
            grantCreditId: row.grantCreditId,
          },
        });
      }
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    getCheckStatus(v) {
      var state = '';
      switch (v) {
        case 10:
          state = '授信待提交';
          break;
        case 20:
          state = '资金方授信待复核';
          break;
        case 25:
          state = '资金方复核通过';
          break;
        case 26:
          state = '资金方复核回退';
          break;
        case 40:
          state = '平台待初审';
          break;
        case 41:
          state = '平台初审通过';
          break;
        case 42:
          state = '平台初审回退';
          break;
        case 43:
          state = '复审通过';
          break;
        case 44:
          state = '复审拒绝';
          break;
        case 46:
          state = '平台授信回退';
          break;
        case 99:
          state = '授信通过';
          break;
        case 999:
          state = '授信过期';
          break;
      }
      return state;
    },
  },
};
</script>

<style lang="scss">
</style>
