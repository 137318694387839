<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby bl">
        <div class="yfdtwo">
          <div class="yfdsize rback">退回原因</div>
          <div class="card-bd card-bd-row refuseback">
            <el-row>
              <el-col :span="16" class="lb_body">
                <img
                  src="../../../assets/cuowu.png"
                  alt=""
                  style="margin-left: -27px; vertical-align: middle"
                />
                <span class="info-key">被退回原因:</span>
                <span class="info-value index">{{ refusereason.reason }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="lb_body">
                <span class="info-key">备注:</span>
                <span class="info-value index">{{ refusereason.remark }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="yfdsize">基本信息</div>
          <div class="formstyle">
            <el-form
              :model="absAccountDetail"
              :rules="rules"
              ref="absAccountDetail"
              label-width="198px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    :label="
                      absAccountDetail.absAccountType == 1
                        ? '债务企业:'
                        : '债权企业:'
                    "
                    prop="companyInfo"
                  >
                    <el-select
                      v-model="absAccountDetail.companyInfo"
                      placeholder="请选择企业"
                      value-key="corpId"
                      filterable
                      @change="getcreditCodefn"
                    >
                      <el-option
                        v-for="(item, index) in companyList"
                        :key="index"
                        :label="item.corpName"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="统一社会信用代码: ">
                    <el-input
                      v-model="absAccountDetail.corpCreditNo"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="贸易合同编号: " prop="contractNo">
                    <el-input v-model="absAccountDetail.contractNo"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="账款起止时间: " prop="zktime">
                    <el-date-picker
                      v-model="absAccountDetail.zktime"
                      type="daterange"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      @change="zktimefn"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="账款金额: " prop="absAccountAmount">
                    <el-input
                      v-model="absAccountDetail.absAccountAmount"
                      @blur="tocapital"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大写: ">
                    <el-input
                      v-model="absAccountDetail.accountAmountCpt"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="yfdtwo">
          <div class="yfdsize">合同信息</div>
          <div class="formstyle">
            <div class="htbox">
              <div class="htactive">
                <span>交易合同 </span>
              </div>
              <div class="htpad">
                <el-form
                  :model="ruleform2"
                  label-position="left"
                  ref="ruleform2"
                  class="demo-ruleForm"
                >
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关合同: "
                      prop="files"
                      class="upload-text-requird"
                    >
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP格式。上传的合同必须加盖骑缝章，按照合同页数进行排列。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        multiple
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupfn(a, b, c)"
                        :on-remove="(a, b) => dragremovefn(a, b)"
                        :on-error="erruploadfp"
                        :headers="Authorization"
                        :file-list="ruleform2.filelist"
                        accept="image/png,image/jpeg,application/zip,application/rar"
                        :before-upload="beforeupfnfile"
                        drag
                        list-type="picture"
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传图片</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关发票: "
                      prop="img"
                      class="upload-text-requird"
                    >
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG格式。上传的发票必须真实不能重复。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadAbsAccountInvoice'"
                        :on-success="(a, b, c) => handleupfn(a, b, c)"
                        :on-remove="(a, b) => handleRemove(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.filelistfp"
                        :headers="Authorization"
                        accept="image/png,image/jpeg,image/jpg"
                        :before-upload="beforeupfn"
                        list-type="picture"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传图片</div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-table
                        :data="ruleform2.absAccountInvoiceList"
                        @cell-mouse-enter="cellhover"
                        @cell-mouse-leave="cellleave"
                        style="width: 100%; top: 20px"
                      >
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column
                          label="购买方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceBuyer"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="销售方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceSeller"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票代码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票号码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceNo"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="检验码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceCheckCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开票日期"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceOpenTime"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="不含税开具金额（元）"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceAmountWithoutTax"
                              class="tabright"
                              
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开具金额（元）"
                          align="right"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceAmount"
                              class="tabright"
                              @blur="blurfptotal(scope.row, scope.$index, item)"
                            />
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <div class="ljje">
                      累计发票金额:
                      <span>
                        {{ changeMoneyThousand(ruleform2.fptotal) }}
                      </span>
                      元
                    </div>
                  </div>
                  <div class="uploadlist">
                    <el-form-item label="上传其他资料: ">
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP格式。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupotherfn(a, b, c)"
                        :on-remove="(a, b) => dragremoveotherfn(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.otherListfp"
                        :headers="Authorization"
                        accept="image/png,image/jpeg,image/jpg,application/zip,application/rar,application/pdf"
                        :before-upload="beforeupfnfile"
                        list-type="picture"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传资料</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-hd"
          style="height: 150px"
          v-if="$route.query.accountBackStatus != 3"
        >
          <el-row style="padding-top: 40px">
            <el-col :span="4" class="lb_body" :offset="6">
              <el-button
                style="width: 160px; height: 40px; font-size: 16px"
                size="small"
                @click="openAbolishdiag"
                >账款作废</el-button
              >
            </el-col>
            <el-col :span="4" class="lb_body">
              <el-button
                style="
                  width: 160px;
                  height: 40px;
                  background: #2937a6;
                  color: #ffffff;
                  font-size: 16px;
                "
                size="small"
                @click="updateAccount"
                >重新提交</el-button
              >
            </el-col>
            <el-col :span="4" class="lb_body">
              <el-button
                style="
                  width: 160px;
                  height: 40px;
                  background: #2937a6;
                  color: #ffffff;
                  font-size: 16px;
                "
                size="small"
                @click="goback"
                >返回</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div
          class="card-hd"
          style="height: 150px"
          v-if="$route.query.accountBackStatus == 3"
        >
          <el-row style="padding-top: 40px">
            <el-col :span="8" class="lb_body" :offset="10">
              <el-button
                style="
                  width: 160px;
                  height: 40px;
                  background: #2937a6;
                  color: #ffffff;
                  font-size: 16px;
                "
                size="small"
                @click="goback"
                >返回</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import api from '@/api/api';
import { convertCurrency, adds } from '@/util/common.js';
import { baseURL } from '@/api/sys';
import moment from 'moment';
import { changeMoneyThousand } from '@/util/common';
Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
export default {
  data() {
    return {
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ['账款退回'],
      rules: {
        companyInfo: [
          { required: true, message: '请选择企业', trigger: 'change' },
        ],
        contractNo: [
          { required: true, message: '请输入合同编号' },
          { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
        ],
        absAccountAmount: [
          { required: true, message: '请输入账款金额' },
          {
            pattern: /^\d{1,12}(\.\d{1,2})?$/,
            message: '请输入12位以内的数字,小数点后最多2位',
          },
        ],
        zktime: [
          { required: true, message: '请选择账款时限', trigger: 'change' },
        ],
      },
      bclick: false,
      absAccountDetail: {
        companyInfo: '',
        debtCorpId: '',
        debtCorpName: '',
        deptCorpCreditNo: '',
        corpCreditNo: '',
        contractNo: '',
        absAccountAmount: '',
        accountAmountCpt: '',
        absAccountSignDate: '',
        absAccountCloseDate: '',
        zktime: '',
      },
      ruleform2: {
        absAccountContactList: [],
        absAccountInvoiceList: [],
        absAccountOthersList: [],
        fptotal: 0,
        filelist: [],
        filelistfp: [],
        otherListfp: [],
      },
      convertCurrency: convertCurrency,
      Authorization: { Authorization: sessionStorage.token },
      changeMoneyThousand: changeMoneyThousand,
      showAddDialog2: false,
      refusereason: {
        reason: this.$route.query.reason,
        reamrk: this.$route.query.reamrk,
      },
      companyList: [],
      isManage: this.$route.query.isManage, // 是否是从账款管理里列表进入的 1是
      approveStatus: this.$route.query.approveStatus,
      absAccountId: this.$route.query.id,
    };
  },
  created() {
    this.getCoreCorpListExcludeSelf();
    this.getAccountInfo();
  },
  methods: {
    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #ccc';
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #fff';
    },
    goback() {
      history.go(-1);
    },
    // 获取债务企业/债务企业
    getCoreCorpListExcludeSelf() {
      // 5 核心企业+链属企业（不包括自身）
      api.getCoreCorpListExcludeSelf({ type: 5 }).then((res) => {
        if (res.success) {
          this.companyList = res.datas.corpList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 金额大写
    tocapital() {
      this.absAccountDetail.accountAmountCpt = this.convertCurrency(
        this.absAccountDetail.absAccountAmount
      );
    },
    // 获取详情
    getAccountInfo() {
      let infoNode;
      if (this.isManage == 1) {
        // 账款管理列表
        if (this.approveStatus == 22) {
          // 内审退回
          infoNode = 2;
        } else if (this.approveStatus == 32) {
          // 外审退回
          infoNode = 3;
        } else {
          infoNode = 1;
        }
      }
      //获取详情
      api
        .getAccountInfo({ absAccountId: this.absAccountId, infoNode })
        .then((res) => {
          if (res.success) {
            this.absAccountDetail = {
              ...this.absAccountDetail,
              ...res.datas.absAccount,
            };
            this.refusereason = res.datas.absAccountReturnRecord || {};

            let data = res.datas.absAccount;
            this.absAccountDetail.companyInfo = {};
            if (data.absAccountType == 1) {
              // 应收账款 --> 债务企业
              this.absAccountDetail.companyInfo.corpId = data.debtCorpId;
              this.absAccountDetail.companyInfo.corpName = data.debtCorpName;
              this.absAccountDetail.corpCreditNo = data.deptCorpCreditNo;
            } else if (data.absAccountType == 2) {
              // 应付账款 --> 债权企业
              this.absAccountDetail.companyInfo.corpId = data.claimsCorpId;
              this.absAccountDetail.companyInfo.corpName = data.claimsCorpName;
              this.absAccountDetail.corpCreditNo = data.claimsCorpCreditNo;
            }

            this.absAccountDetail.zktime = [
              moment(data.absAccountSignDate).format('YYYY-MM-DD'),
              moment(data.absAccountCloseDate).format('YYYY-MM-DD'),
            ];
            this.absAccountDetail.absAccountSignDate = moment(
              data.absAccountSignDate
            ).format('YYYY-MM-DD');
            this.absAccountDetail.absAccountCloseDate = moment(
              data.absAccountCloseDate
            ).format('YYYY-MM-DD');

            this.absAccountDetail.accountAmountCpt = this.convertCurrency(
              data.absAccountAmount
            );
            //合同
            data.absAccountContactList.map((ele) => {
              this.ruleform2.filelist.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.absAccountContactList.push({
                fileType: 1,
                fileUrl: ele.fileUrl,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });
            //发票
            data.absAccountInvoiceList.map((ele) => {
              this.ruleform2.filelistfp.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.absAccountInvoiceList.push({
                absAccountFileId: ele.absAccountFileId,
                absAccountId: this.absAccountId,
                invoiceBuyer: ele.invoiceBuyer,
                invoiceSeller: ele.invoiceSeller,
                invoiceCode: ele.invoiceCode,
                invoiceNo: ele.invoiceNo,
                invoiceOpenTime: ele.invoiceOpenTime,
                invoiceAmount: ele.invoiceAmount,
                fileType: 2,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });
            //其他附件
            data.absAccountOthersList.map((ele) => {
              this.ruleform2.otherListfp.push({
                name: ele.fileName,
                url: api.getImgURL(ele.fileUrl),
                uid: ele.uid,
              });
              this.ruleform2.absAccountOthersList.push({
                fileType: 3,
                name: ele.fileName,
                fileUrl: ele.fileUrl,
                uid: ele.uid,
                fileName: ele.fileName,
              });
            });
            //发票总金额
            let arr = [];
            data.absAccountInvoiceList.map((el) => {
              arr.push(el.invoiceAmount);
            });
            this.ruleform2.fptotal = (0).adds(...arr);
          } else {
          }
        })
        .catch((err) => {
          this.bclick = false;
        });
    },
    // 编辑账款 重新提交
    updateAccount() {
      this.$refs['absAccountDetail'].validate((valid) => {
        if (valid) {
          var amount = 0,
            issome = false,
            amountarr = [];
          this.ruleform2.absAccountInvoiceList.map((ele) => {
            amountarr.push(Number(ele.invoiceAmount));
            // Object.keys(ele).forEach((els) => {
            //   if (ele[els] == '') {
            //     issome = true;
            //   }
            // });
          });
          amount = (0).adds(...amountarr);
          if (this.ruleform2.absAccountContactList.length == 0) {
            this.$message.error('请上传合同');
            return;
          }
          if (this.ruleform2.absAccountInvoiceList.length == 0) {
            this.$message.error('请上传发票');
            return;
          }
          if (issome) {
            this.$message.error('发票信息不全');
            return;
          }
          if (
            this.ruleform2.fptotal < +this.absAccountDetail.absAccountAmount
          ) {
            this.$message.warning('发票金额小于账款金额');
            return;
          }
          this.$refs['ruleform2'].validate((valid) => {
            if (valid) {
              if (this.absAccountDetail.absAccountType == 1) {
                // 应收账款 选择债务企业
                this.absAccountDetail.debtCorpId =
                  this.absAccountDetail.companyInfo.corpId;
                this.absAccountDetail.debtCorpName =
                  this.absAccountDetail.companyInfo.corpName;
                this.absAccountDetail.deptCorpCreditNo =
                  this.absAccountDetail.corpCreditNo;
              } else if (this.absAccountDetail.absAccountType == 2) {
                // 应付账款 选择债权企业
                this.absAccountDetail.claimsCorpId =
                  this.absAccountDetail.companyInfo.corpId;
                this.absAccountDetail.claimsCorpName =
                  this.absAccountDetail.companyInfo.corpName;
                this.absAccountDetail.claimsCorpCreditNo =
                  this.absAccountDetail.corpCreditNo;
              }
              this.absAccountDetail.absAccountSource = 1; // 1手动新增 2导入
              let params = Object.assign(
                {},
                this.absAccountDetail,
                this.ruleform2
              );
              this.$confirm('该账款将变更, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
              })
                .then(() => {
                  const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                  });
                  if (this.bclick) {
                    return;
                  }
                  this.bclick = true;
                  api
                    .updateAccount(params)
                    .then((res) => {
                      loading.close();
                      if (res.code == '100000') {
                        this.$alert('变更成功，提交内部审核', '', {
                          confirmButtonText: '确定',
                          callback: (action) => {
                            history.go(-1);
                          },
                        });
                      } else {
                        this.bclick = false;
                        this.$message.error(res.message);
                      }
                    })
                    .catch((err) => {
                      loading.close();
                      this.bclick = false;
                    });
                })
                .catch(() => {
                  loading.close();
                  this.bclick = false;
                });
            } else {
              return false;
            }
          });
        }
      });
    },
    // 上传合同
    dragupfn(response, file, fileList, index) {
      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.filelist[this.ruleform2.filelist.length] = file;

        this.ruleform2.absAccountContactList.push({
          fileType: 1,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.ruleform2.filelist = this.ruleform2.filelist;
        this.$forceUpdate();
        this.$message.error(file.name + '上传失败，请重新上传');
      }
    },
    // 移除合同
    dragremovefn(file, fileList, index) {
      if (
        this.ruleform2.absAccountContactList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.absAccountContactList.splice(
          this.ruleform2.absAccountContactList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        this.ruleform2.filelist.splice(
          this.ruleform2.filelist.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    //上传发票
    handleupfn(response, file, fileList, item) {
      var data = response.message.datas.absAccountFileDto;
      item.filelistfp[item.filelistfp.length] = file;
      item.filelistfp.forEach((el, index) => {
        el.name = index + 1;
      });
      item.invoiceList.push({
        ...data,
        fileCode: 15,
        uid: file.uid,
        fileName: file.name,
      });
      item.fptotal = (+item.fptotal).adds(data.invoiceAmount);

      if (response.code === -1) {
        this.$message.error('发票识别错误，请删除后重新上传或手动填写更新。');
      } else {
        this.$message.success(response.message);
      }
    },
    // 移除发票
    handleRemove(file, fileList) {
      if (
        this.ruleform2.absAccountInvoiceList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.filelistfp.splice(
          this.ruleform2.filelistfp.findIndex((el) => el.uid == file.uid),
          1
        );
        this.ruleform2.filelistfp.forEach((el, index) => {
          el.name = index + 1;
        });
        this.ruleform2.absAccountInvoiceList.splice(
          this.ruleform2.absAccountInvoiceList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        var arr = [];
        this.ruleform2.absAccountInvoiceList.map((el) => {
          arr.push(Number(el.invoiceAmount));
        });
        this.ruleform2.fptotal = (0).adds(...arr);
      }
    },
    // 上传其他资料
    dragupotherfn(response, file, fileList, item, index) {
      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.otherListfp[this.ruleform2.otherListfp.length] = file;

        this.ruleform2.absAccountOthersList.push({
          fileType: 3,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + '上传失败，请重新上传');
      }
    },
    // 移除其他的附件
    dragremoveotherfn(file, fileList, item, index) {
      if (
        this.ruleform2.absAccountOthersList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.absAccountOthersList.splice(
          this.ruleform2.absAccountOthersList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        this.ruleform2.otherListfp.splice(
          this.ruleform2.otherListfp.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    // 上传失败
    erruploadfp(err) {
      this.$message.error('服务器错误');
    },
    // 获取统一社会信用代码
    getcreditCodefn(val) {
      this.absAccountDetail.corpCreditNo = val.creditCode;
    },
    // 获取账款起止时间
    zktimefn(val) {
      this.absAccountDetail.absAccountSignDate = val[0];
      this.absAccountDetail.absAccountCloseDate = val[1];
    },
    // 处理发票金额
    blurfptotal(val, index, item) {
      var arr = [];
      this.ruleform2.absAccountInvoiceList.map((el) => {
        arr.push(Number(el.invoiceAmount));
      });
      this.ruleform2.fptotal = (0).adds(...arr);
    },
    beforeupfn(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (file.name.substr(0, index).length > 40) {
        this.$message.error('上传文件名长度不得超过40！');
        return false;
      }
      if (!/jpg|gif|bmp|png|jpeg|JPG|GIF|BMP|PNG|JPEG/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg格式的图片');
        return false;
      }
      if (file.size > 1048576) {
        this.$message.error('请上传1M以内的图片');
        return false;
      }
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (
        !/jpg|gif|bmp|png|jpeg|zip|rar|pdf|JPG|GIF|BMP|PNG|JPEG|ZIP|RAR|PDF/.test(
          suffix
        )
      ) {
        this.$message.error('请上传png,jpg,jpeg,zip,rar格式的文件');
        return false;
      }
    },
    // 账款作废
    openAbolishdiag() {
      this.$confirm(
        '提示：该账款作废以后，该数据将不可再被使用。  客户可重新增加一条相同编号的账款数据，是否确认作废该条账款数据',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning',
        }
      )
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          if (this.bclick) {
            return;
          }
          this.bclick = true;
          api
            .cancelAccount({ absAccountId: this.absAccountId })
            .then((res) => {
              loading.close();
              if (res.success) {
                this.$alert('作废成功', '', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    history.go(-1);
                  },
                });
              } else {
                this.bclick = false;
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              loading.close();
              this.bclick = false;
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消作废',
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
}
.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}
.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type='text'] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    .htpad {
      padding: 0 17px;
      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 5em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }
      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 300px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937a6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16 {
  position: relative;
  top: -16px;
}
.easypayby.bl {
  margin-top: 20px;
}
</style>
