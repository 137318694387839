<template>
    <div class="tableBox">
        <!-- 企业账户信息内容。 -->
        <div class="lineBorder"></div>
        <div class="tableBoxComtent">
            <el-table
                :data="listData"
                :header-cell-style="headerStyle"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%;"
                >
                    <el-table-column prop="creditAgreementNo" label="账户户名" min-width="170"></el-table-column>
                    <el-table-column prop="creditAgreementNo" label="账号" min-width="170"></el-table-column>
                    <!-- <el-table-column prop="creditAgreementNo" label="账户类型" min-width="170"></el-table-column> -->
                    <el-table-column prop="creditAgreementNo" label="开户行" min-width="170"></el-table-column>
                    <el-table-column prop="creditAgreementNo" label="绑定状态" min-width="170"></el-table-column>
                    <el-table-column prop="creditAgreementNo" label="添加时间" min-width="170"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data(){
        return{
            listData:[],
            listLoading:false,
        }
    },
    created(){
      this.getEnterpriseAccountInformatio();
    },
    methods:{
        headerStyle({ row, rowIndex }) {
            return { background: "#FAFAFA" };
        },

        getEnterpriseAccountInformatio(){
            let para = {
                corpId:this.$route.query.corpId
            }
            
        }


    }
}
</script>

<style lang='scss'>
    .lineBorder{
        width: 100%;
        border-bottom: 1px solid #dcdfe6;
    }
    .tableBoxComtent{
        padding-bottom: 30px;
    }
</style>