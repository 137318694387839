<!-- 已退回详情 -->
<!-- 发布票据 -->
<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div v-if="!releaseSuccess" class="release-bill">
      <div class="refuse-box">
        <span>被退回原因：</span>
        <span style="color: #f1555f">{{ billForm.reason }}</span>
      </div>
      <div class="refuse-box">
        <span>备注：</span>
        <span>{{ billForm.remark }}</span>
      </div>
      <el-form
        ref="billForm"
        :model="billForm"
        :rules="rules"
        label-width="100px"
      >
        <div class="upload-box">
          <el-row :gutter="30">
            <el-col :span="12">
              <div class="upload-box-item">
                <div class="upload-box-item-header">
                  <div>
                    <span>票据正面</span>
                  </div>
                  <div>
                    <span class="bill-example" @click="showBill(true)"
                      >示例</span
                    >
                    <span
                      class="bill-example"
                      :data-img="
                        billForm.hideStatus
                          ? billForm.billFiles
                          : billForm.billFileFront.filePath
                      "
                      @click="$imgPreview"
                      >预览大图</span
                    >
                    <el-checkbox
                      class="hide-payee"
                      v-model="billForm.hideStatus"
                      @change="hidePay()"
                      >隐藏收款人</el-checkbox
                    >
                  </div>
                </div>
                <el-upload
                  class="upload-box-item-cont"
                  :action="upload()"
                  :before-upload="beforeUpload"
                  :on-success="(res, file) => handleSuccess(res, file, true)"
                  :show-file-list="false"
                >
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__text">上传票据正面</div>
                  <img
                    v-if="billForm.billFileFront && !billForm.hideStatus"
                    :src="billForm.billFileFront.filePath"
                    class="upload-img"
                  />
                  <img
                    v-if="billForm.hideStatus"
                    :src="billForm.billFiles"
                    id="codeImg"
                    class="upload-img"
                  />
                </el-upload>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="upload-box-item">
                <div class="upload-box-item-header">
                  <div>
                    <span>票据背面</span>
                  </div>
                  <div>
                    <span class="bill-example" @click="showBill(false)"
                      >示例</span
                    >
                    <span
                      class="bill-example"
                      :data-img="billForm.billFileBack.filePath"
                      @click="$imgPreview"
                      >预览大图</span
                    >
                  </div>
                </div>
                <el-upload
                  class="upload-box-item-cont"
                  :action="upload()"
                  :before-upload="beforeUpload"
                  :on-success="(res, file) => handleSuccess(res, file, false)"
                  :show-file-list="false"
                >
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__text">上传票据背面</div>
                  <img
                    v-if="billForm.billFileBack"
                    :src="billForm.billFileBack.filePath"
                    class="upload-img"
                  />
                </el-upload>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 票据详情 -->
        <div class="bill-form-box border">
          <div class="bill-title">票据信息</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="承兑人" prop="acceptor">
                <el-input
                  v-model="billForm.acceptor"
                  placeholder="请输入承兑人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="票面金额" prop="billAmount">
                <el-input
                  v-model="billForm.billAmount"
                  placeholder="请输入票面金额"
                  @change="tocapital"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="大写" prop="billAmountCpt">
                <el-input
                  v-model="billForm.billAmountCpt"
                  placeholder="请输入票面金额"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="票据号码" prop="billNum">
                <el-input
                  v-model="billForm.billNum"
                  placeholder="请输入票据号码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="到期日" prop="expireTime">
                <el-date-picker
                  v-model="billForm.expireTime"
                  type="date"
                  :editable="false"
                  @change="changeDate"
                  :picker-options="expireTimerOptions"
                  placeholder="请选择到期日"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="背书次数" prop="endorseNum">
                <el-input
                  v-model="billForm.endorseNum"
                  placeholder="请输入背书次数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="票据瑕疵" prop="billFlaw">
                <el-select
                  v-model="billForm.billFlaw"
                  allow-create
                  default-first-option
                  filterable
                  multiple
                  value-key="code"
                  @change="getFlawStatus"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="i in flawList"
                    :key="i.code"
                    :label="i.name"
                    :value="i.name"
                    :disabled="i.disabled"
                  >
                    <slot>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="i.remark"
                        placement="right"
                      >
                        <span style="display: block; width: 100%">{{
                          i.name
                        }}</span>
                      </el-tooltip>
                    </slot>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="期望卖出价" required>
                <el-row>
                  <el-col :span="11">
                    <el-form-item prop="sellRateStr">
                      <el-input
                        class="inline-input"
                        v-model="billForm.sellRateStr"
                        placeholder="年利率"
                        @change="autoCharge('min')"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="connect-line">~</el-col>
                  <el-col :span="11">
                    <el-form-item prop="sellRateEnd">
                      <el-input
                        class="inline-input"
                        v-model="billForm.sellRateEnd"
                        placeholder="年利率"
                        @change="autoCharge('max')"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="每十万扣费" required>
                <el-row>
                  <el-col :span="11">
                    <el-form-item>
                      <el-input
                        class="inline-input"
                        v-model="billForm.deductionStr"
                        placeholder="自动换算"
                        readonly
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="connect-line">~</el-col>
                  <el-col :span="11">
                    <el-form-item>
                      <el-input
                        class="inline-input"
                        v-model="billForm.deductionEnd"
                        placeholder="自动换算"
                        readonly
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="票据类型" required>
                <el-select v-model="billForm.billType" placeholder="请选择">
                  <el-option
                    v-for="item in billTypeList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 合同信息 -->
        <div class="bill-form-box border">
          <div class="bill-title" style="margin-top: 30px">合同信息</div>
          <div class="httotal">
            已添加合同
            <span>{{ ruleform2.contractList.length }}</span> 笔，合同总计金额
            <span>{{ changeMoneyThousand(mounttoall) }}</span> 元。
          </div>
          <div class="formstyle">
            <div v-for="(item, index) in ruleform2.contractList" :key="index">
              <div class="htbox" :class="{ active: item.isactive }">
                <div class="htactive">
                  <span>
                    <i
                      :class="
                        item.isactive
                          ? 'fa fa-plus-circle'
                          : 'fa fa-minus-circle'
                      "
                      @click="item.isactive = !item.isactive"
                    ></i>
                    交易合同
                  </span>
                  <i class="el-icon-delete" @click="delDomain(index)"></i>
                </div>
                <div class="htpad">
                  <el-form
                    :model="item"
                    :rules="item.rules1"
                    label-position="left"
                    :ref="item.name + index"
                    class="demo-ruleForm"
                  >
                    <el-row :gutter="20" class="htno">
                      <el-col :span="8">
                        <el-form-item
                          label="合同编号: "
                          prop="contractNo"
                          label-width="100px"
                        >
                          <el-input v-model="item.contractNo"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="合同金额: "
                          prop="contractAmount"
                          label-width="100px"
                        >
                          <el-input
                            v-model="item.contractAmount"
                            @blur="jsmount(item.contractAmount)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="签署日期: "
                          prop="contractTime"
                          label-width="100px"
                        >
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="item.contractTime"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            :picker-options="expireTimerOptions2"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <div class="uploadlist">
                      <el-form-item
                        label="上传相关合同: "
                        prop="files"
                        class="upload-text-requird"
                      >
                        <div class="uploadfont wxtsk">
                          温馨提示：仅支持JPG、PNG、PDF、ZIP格式。上传的合同必须加盖骑缝章，按照合同页数进行排列。
                          <br />
                          <span class="pt16"
                            >使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                          >
                        </div>
                        <el-upload
                          :ref="item.name + index + 'a'"
                          class="upload-demo upload-contentr"
                          :action="baseURL + '/web/file/uploadFile'"
                          :on-success="
                            (a, b, c) => dragupfn(a, b, c, item, index)
                          "
                          :on-remove="(a, b) => dragremovefn(a, b, item, index)"
                          :headers="Authorization"
                          :file-list="item.filelist"
                          accept="image/png, image/jpeg, application/zip, application/rar, application/pdf"
                          :before-upload="beforeupfnfile"
                          drag
                          multiple
                          list-type="picture"
                        >
                          <i class="el-icon-plus"></i>
                          <div class="el-upload__text">上传合同</div>
                        </el-upload>
                      </el-form-item>
                    </div>

                    <div class="uploadlist">
                      <el-form-item
                        label="上传相关发票: "
                        prop="img"
                        class="upload-text-requird"
                      >
                        <div class="uploadfont wxtsk">
                          温馨提示：仅支持JPG、PNG格式。上传的发票必须真实不能重复。
                          <br />
                          <span class="pt16"
                            >使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                          >
                        </div>
                        <el-upload
                          class="upload-demo upload-contentr"
                          :action="baseURL + '/web/file/uploadBillInvoice'"
                          :on-success="
                            (a, b, c) => handleupfn(a, b, c, item, index)
                          "
                          :on-remove="(a, b) => handleRemove(a, b, item, index)"
                          :on-error="erruploadfp"
                          :headers="Authorization"
                          :file-list="item.filelistfp"
                          accept="image/png, image/jpeg, image/jpg"
                          :before-upload="beforeupfn"
                          multiple
                          list-type="picture"
                          drag
                          ref="uploadFile1"
                        >
                          <i class="el-icon-plus"></i>
                          <div class="el-upload__text">上传发票</div>
                        </el-upload>
                      </el-form-item>
                      <el-form-item>
                        <el-table
                          :data="item.invoiceList"
                          @cell-mouse-enter="cellhover"
                          @cell-mouse-leave="cellleave"
                          style="width: 100%; top: 20px"
                        >
                          <el-table-column
                            type="index"
                            label="序号"
                            width="80"
                          ></el-table-column>
                          <el-table-column
                            label="购买方"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceBuyer"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="销售方"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceSeller"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="发票代码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceCode"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="发票号码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceNo"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="检验码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceCheckCode"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="开票日期"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceOpenTime"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="不含税开具金额（元）"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceAmountWithoutTax"
                                class="tabright"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="开具金额（元）"
                            align="right"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model="scope.row.invoiceAmount"
                                class="tabright"
                                @blur="blurfptotal(scope.row, scope.$index, item)"
                              />
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                      <div class="ljje">
                        累计发票金额:
                        <span>{{ changeMoneyThousand(item.fptotal) }}</span> 元
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="addbtn" @click="addDomain">
              <i class="el-icon-plus"></i>
              <span>添加合同</span>
            </div>
          </div>
        </div>
      </el-form>
      <div class="push-setting border">
        <div class="bill-title">推送设置</div>
        <span class="push-title">推送设置：</span>
        <el-checkbox v-model="billForm.pushStatus"
          >将票据信息推送至白名单资金机构</el-checkbox
        >
      </div>
      <div class="bill-notice">
        <el-checkbox v-model="checked" @change="showAgreement"
          >我已阅读并愿意签署《票据收益权转让协议》</el-checkbox
        >
      </div>
      <div class="bill-submit">
        <el-button v-if="!offline" @click="billOff">下架票据</el-button>
        <el-button v-if="!offline" type="primary" @click="submit"
          >重新提交</el-button
        >
        <el-button v-if="offline" type="primary" @click="$router.go(-1)"
          >返回</el-button
        >
      </div>
      <!-- 票据示例弹框 -->
      <el-dialog
        :title="showBillDirection ? '票据正面示例' : '票据反面示例'"
        :visible.sync="billVisible"
      >
        <img :src="showBillPath" />
      </el-dialog>
      <!-- 票据打码 -->
      <el-dialog
        :visible.sync="codeDialog"
        v-if="codeDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="true"
        @close="hideCOdeDialog"
        width="1000px"
        center
      >
        <div id="codeBox"></div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="imgCode(billForm.billFileFront.filePath)"
            >打码</el-button
          >
          <el-button type="primary" @click="dialogUpload()">保存</el-button>
        </span>
      </el-dialog>
      <!-- 票据收益权转让协议弹框 -->
      <el-dialog
        id="dialog"
        class="agreement-dialog"
        :visible.sync="billDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="50%"
        center
      >
        <bill-agreement></bill-agreement>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" v-if="!readDisabled" @click="hasRead()"
            >同意</el-button
          >
          <el-button type="primary" v-else :disabled="readDisabled"
            >阅读并同意 {{ readCount }}秒</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-if="releaseSuccess" class="release-success">
      <div><i class="el-icon-circle-check"></i></div>
      <p>票据已提交审核。</p>
      <p>请耐心等待。</p>
      <div>
        <el-button type="primary" @click="goBillManage">查看挂牌票据</el-button>
        <!-- <el-button type="primary" @click="addOther">继续添加</el-button> -->
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import api from '@/api/api';
import upload from '../../api/upload';
import { convertCurrency, changeMoneyThousand } from '@/util/common';
import billAgreement from '@/components/agreementList/bill-agreement';
import { baseURL } from '@/api/sys';
export default {
  data() {
    var sellRateStr = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入利率最小值'));
      } else if (!/^[1-9]\d*(\.\d{1,2})?$|^0\.\d{1,2}$/g.test(value)) {
        callback(new Error('请输入最多2位小数的自然数'));
      } else {
        if (this.billForm.sellRateStr !== '') {
          this.$refs.billForm.validateField('sellRateEnd');
        }
        callback();
      }
    };
    var sellRateEnd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入利率最大值'));
      } else if (parseFloat(value) <= parseFloat(this.billForm.sellRateStr)) {
        callback(new Error('最大值要比最小值大'));
      } else if (!/^[1-9]\d*(\.\d{1,2})?$|^0\.\d{1,2}$/g.test(value)) {
        callback(new Error('请输入最多2位小数的自然数'));
      } else {
        callback();
      }
    };
    return {
      breadcrumblist: ['发布票据'],
      showBillDirection: true,
      showBillPath: '',
      billVisible: false,
      offline: false,
      billForm: {
        reason: '',
        remark: '',
        hideStatus: false,
        billFileFront: {
          filePath: '',
          fileUrl: '',
          fileName: '',
        },
        billFiles: '',
        billFileBack: {
          filePath: '',
          fileUrl: '',
          fileName: '',
        },
        acceptor: '',
        billAmount: '',
        billAmountCpt: '',
        endorseNum: '',
        expireTime: '',
        billNum: '',
        flawStatus: '',
        billFlaw: [],
        pushStatus: false,
        sellRateStr: '',
        sellRateEnd: '',
        deductionStr: '',
        deductionEnd: '',
        billType: '',
        isPermitted: false, //打码后才允许保存打码图片
      },
      flawList: [],
      billTypeList: [],
      rules: {
        acceptor: [
          { required: true, message: '请输入承兑人', trigger: 'blur' },
        ],
        billAmount: [
          { required: true, message: '请输入票面金额', trigger: 'blur' },
          {
            pattern: /^[1-9]\d*(\.\d{1,2})?$|^0\.\d{1,2}$/,
            message: '票面金额必须大于0,且最多两位小数',
          },
        ],
        billAmountCpt: [
          { required: true, message: '请输入大写', trigger: 'blur' },
        ],
        billNum: [
          { required: true, message: '请输入票据号码', trigger: 'blur' },
        ],
        endorseNum: [
          { required: true, message: '请输入背书次数', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '请输入数字' },
        ],
        expireTime: [
          {
            type: 'date',
            required: true,
            message: '请选择到期日',
            trigger: 'change',
          },
        ],
        billFlaw: [
          { required: true, message: '请选择是否有瑕疵', trigger: 'change' },
        ],
        sellRateStr: [
          { required: true, message: '请输入最低年利率', trigger: 'blur' },
          {
            pattern: /^\+?[1-9][0-9]*$|^(\d+\.\d+)$/,
            message: '请输入非负非零的整数或者小数',
          },
          { validator: sellRateStr },
        ],
        sellRateEnd: [
          { required: true, message: '请输入最高年利率', trigger: 'blur' },
          {
            pattern: /^\+?[1-9][0-9]*$|^(\d+\.\d+)$/,
            message: '请输入非负非零的整数或者小数',
          },
          { validator: sellRateEnd },
        ],
      },
      checked: false,
      billDialog: false,
      readDisabled: true,
      readCount: '',
      codeDialog: false,
      flag: false, // 是否绘制矩形
      clickX: '', // 开始绘制矩形时，鼠标点击时的x坐标
      clickY: '', // 开始绘制矩形时，鼠标点击时的y坐标
      releaseSuccess: false,
      expireTimerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      ruleform2: {
        contractList: [],
      },
      changeMoneyThousand: changeMoneyThousand,
      mounttoall: 0,
      expireTimerOptions2: {
        disabledDate(time) {
          return (
            time.getTime() > new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      baseURL: baseURL,
      Authorization: { Authorization: sessionStorage.token },
    };
  },
  created() {
    api.getDicListByType({ type: '21' }).then((res) => {
      // 票据瑕疵
      if (res.success) {
        this.flawList = res.datas.list;
        api.getDicListByType({ type: '22' }).then((res) => {
          // 票据类型
          if (res.success) {
            this.billTypeList = res.datas.list;

            api.BillDetails({ billId: this.$route.query.id }).then((res) => {
              //退回原因
              if (res.success) {
                //  this.billForm   = res.datas.billDto
                Object.keys(this.billForm).map((el) => {
                  this.billForm[el] = res.datas.billDto[el];
                });
                this.billForm.billFile = res.datas.billDto.billFile;
                console.log(this.billForm, 'form');
                this.billForm.offlineStauts == 2
                  ? (this.offline = true)
                  : (this.offline = false);
                this.billForm.reason = this.$route.query.reason
                  ? this.$route.query.reason
                  : '无';
                this.billForm.remark = this.$route.query.remark
                  ? this.$route.query.remark
                  : '无';
                // this.billForm.hideStatus=false;

                // let length = this.billForm.billFile.length
                this.billForm.billFile.forEach((item, index) => {
                  // if(item.fileType == 2) {
                  //     this.billForm.billFileBack = {
                  //         filePath: '',
                  //         fileUrl: item.fileUrl,
                  //         fileName: item.fileName,
                  //     }
                  //     this.billForm.billFileBack.filePath = api.getImgURL(item.fileUrl)
                  // }else if(item.fileType==1) {
                  //     this.billForm.billFileFront = {
                  //         filePath: '',
                  //         fileUrl: item.fileUrl,
                  //         fileName: item.fileName,
                  //     }
                  //     this.billForm.billFileFront.filePath = api.getImgURL(item.fileUrl)
                  // }
                  if (item.fileType == 2) {
                    this.billForm.billFileBack = {
                      filePath: '',
                      fileUrl: item.fileUrl,
                      fileName: item.fileName,
                    };
                    this.billForm.billFileBack.filePath = api.getImgURL(
                      item.fileUrl
                    );
                  } else if (item.fileType == 1) {
                    // length 2未打码 3打码
                    this.billForm.billFileFront = {
                      filePath: '',
                      fileUrl: item.fileUrl,
                      fileName: item.fileName,
                    };
                    this.billForm.billFileFront.filePath = api.getImgURL(
                      item.fileUrl
                    );
                  } else if (item.fileType == 3 && this.billForm.hideStatus) {
                    // 用户如果没有重新打码，手动把当前链接转成数据流传给后端
                    let url = api.getImgURL(item.fileUrl);
                    let that = this;
                    this.convertImgToBase64(url, function (dataURL) {
                      that.billForm.billFiles = dataURL;
                    });
                  }
                });

                // else{

                //             this.billForm.billFile.forEach((item,index) => {
                //                 if(item.fileType == 2) {

                //                     this.billForm.billFileBack = {
                //                         filePath: '',
                //                         fileUrl: item.fileUrl,
                //                         fileName: item.fileName,
                //                     }
                //                     this.billForm.billFileBack.filePath = api.getImgURL(item.fileUrl)
                //                 }else if(item.fileType == 3) {

                //                     this.billForm.billFileFront = {
                //                         filePath: '',
                //                         fileUrl: item.fileUrl,
                //                         fileName: item.fileName,
                //                     }
                //                     this.billForm.billFiles=this.imgCode(api.getImgURL(item.fileUrl))
                //                  //   this.billForm.billFiles=api.getImgURL(item.fileUrl)

                //                     this.billForm.billFileFront.filePath = api.getImgURL(item.fileUrl)
                //                 }
                //             })
                // }

                this.billForm.billFlaw = res.datas.billDto.billFlaw.split(',');
                this.billForm.hideStatus =
                  res.datas.billDto.hideStatus == 1 ? false : true;
                this.billForm.pushStatus =
                  res.datas.billDto.pushStatus == 1 ? false : true;
                this.billForm.billType = '' + res.datas.billDto.billType;
              }
            });
          }
        });
      }
    }),
      this.getInfo({ billId: this.$route.query.id });
  },
  methods: {
    // 展示票据示例
    showBill(direction) {
      this.billVisible = true;
      this.showBillDirection = direction;
      if (direction) {
        this.showBillPath = require('../../assets/LOGO.png');
      } else {
        this.showBillPath = require('../../assets/mobilebg.png');
      }
    },
    upload() {
      return upload.eqptImgSrc();
    },
    beforeUpload(file, fileList) {
      let fileName = file.name;
      let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        return true;
      } else {
        this.$message.error('请选择图片文件');
        return false;
      }
    },
    handleSuccess(res, file, type) {
      if (res.code == 100000) {
        if (type) {
          this.billForm.billFileFront.filePath = api.getImgURL(
            res.datas.uploadDate.message
          );
          this.billForm.billFileFront.fileUrl = res.datas.uploadDate.message;
          this.billForm.billFileFront.fileName = file.name;
          this.billForm.hideStatus = false;
          this.billForm.billFile.forEach((item, index) => {
            if (item.fileType == 1) {
              item.fileUrl = res.datas.uploadDate.message;
              item.fileName = file.name;
              this.billForm.billFiles = api.getImgURL(item.fileUrl);
            }
          });
          console.log(
            this.billForm.billFile,
            'this.billForm.billFilennnnnnnnn'
          );
        } else {
          this.billForm.billFileBack.filePath = api.getImgURL(
            res.datas.uploadDate.message
          );
          this.billForm.billFileBack.fileUrl = res.datas.uploadDate.message;
          this.billForm.billFileBack.fileName = file.name;
        }
        this.$message.success('上传成功');
      }
    },
    // 弹出打码框
    hidePay() {
      if (this.billForm.hideStatus) {
        this.codeDialog = true;
        this.initCode();
      } else {
        this.isPermitted = false;
      }
      if (this.billForm.billFile.length == 3) {
        this.billForm.billFile.forEach((item, index) => {
          if (item.fileType == 1) {
            this.billForm.billFileFront = {
              filePath: '',
              fileUrl: item.fileUrl,
              fileName: item.fileName,
            };

            this.billForm.billFileFront.filePath = api.getImgURL(item.fileUrl);
          }
        });
      }
    },
    // 关闭打码框
    hideCOdeDialog() {
      this.billForm.hideStatus = false;
    },
    // 图片初始化
    initCode() {
      this.$nextTick(() => {
        let parentBox = document.getElementById('codeBox');
        let drawImg = new Image();
        drawImg.setAttribute('crossOrigin', 'anonymous');
        drawImg.crossOrigin = 'Anonymous';
        drawImg.src = this.billForm.billFileFront.filePath;
        console.log(drawImg.src, ' drawImg.src');
        let addCanvas = document.createElement('canvas');
        parentBox.appendChild(addCanvas);
        let canvas = parentBox.lastElementChild;
        canvas.id = 'imgCanvas';
        if (canvas.getContext) {
          let ctx = canvas.getContext('2d');
          drawImg.onload = function () {
            canvas.width = 720;
            canvas.height = 500;
            ctx.drawImage(drawImg, 0, 0, 720, 500);
          };
        }
      });
    },
    // 图片打码
    imgCode(img) {
      console.log(img, 'img');
      let parentBox = document.getElementById('codeBox');
      let canvas = document.getElementById('imgCanvas');
      if (canvas.getContext) {
        let ctx = canvas.getContext('2d');
        let drawImage = new Image();
        drawImage.crossOrigin = 'Anonymous';
        drawImage.src = img;
        drawImage.onload = () => {
          ctx.drawImage(drawImage, 0, 0, 720, 500);
        };
        parentBox.onmousedown = (e) => {
          //ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(drawImage, 0, 0, 720, 500);
          this.flag = true;
          this.clickX = e.offsetX;
          this.clickY = e.offsetY;
          this.isPermitted = false;
        };
        parentBox.onmouseup = () => {
          this.flag = false;
        };
        parentBox.onmousemove = (e) => {
          if (this.flag) {
            this.isPermitted = true;
            //ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(drawImage, 0, 0, 720, 500);
            ctx.beginPath();
            let pixels = []; // 二维数组，每个子数组有5个值（绘制矩形左上角的X坐标、y坐标，矩形的宽、高，生成的4位随机数用于颜色值）
            for (let x = 0; x < (e.offsetX - this.clickX) / 15; x++) {
              for (let y = 0; y < (e.offsetY - this.clickY) / 15; y++) {
                pixels.push([
                  x * 15 + this.clickX,
                  y * 15 + this.clickY,
                  15,
                  15,
                  Math.floor(Math.random() * 9999),
                ]);
              }
              for (let y = 0; y > (e.offsetY - this.clickY) / 15; y--) {
                pixels.push([
                  x * 15 + this.clickX,
                  y * 15 + this.clickY,
                  15,
                  15,
                  Math.floor(Math.random() * 9999),
                ]);
              }
            }
            for (let x = 0; x > (e.offsetX - this.clickX) / 15; x--) {
              for (let y = 0; y > (e.offsetY - this.clickY) / 15; y--) {
                pixels.push([
                  x * 15 + this.clickX,
                  y * 15 + this.clickY,
                  15,
                  15,
                  Math.floor(Math.random() * 9999),
                ]);
              }
              for (let y = 0; y < (e.offsetY - this.clickY) / 15; y++) {
                pixels.push([
                  x * 15 + this.clickX,
                  y * 15 + this.clickY,
                  15,
                  15,
                  Math.floor(Math.random() * 9999),
                ]);
              }
            }
            for (let i = 0; i < pixels.length; i++) {
              //ctx.fillStyle = '#00' + pixels[i][4]
              ctx.fillStyle = '#BFBCB9';
              ctx.fillRect(
                pixels[i][0],
                pixels[i][1],
                pixels[i][2],
                pixels[i][3]
              );
            }
            ctx.fill();
            ctx.closePath();
          }
        };
      }
    },
    convertImgToBase64(url, callback, outputFormat) {
      var dataURL;
      var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'),
        img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat || 'image/png');
        callback.call(this, dataURL);
        canvas = null;
      };
      img.src = url;
      return dataURL;
    },
    // 保存打码图片
    dialogUpload() {
      if (!this.isPermitted) {
        this.billForm.hideStatus = false;
        this.isPermitted = false;
        this.codeDialog = false;
        this.$message.error('请先打码再保存');
        return;
      }
      console.log('--------后面执行了');
      let canvas = document.getElementById('imgCanvas');
      let billFiles = canvas.toDataURL('image/png');
      let codeImg = document.getElementById('codeImg');
      codeImg.crossOrigin = 'Anonymous';
      this.billForm.billFiles = billFiles;

      // this.billForm.hideStatus = true
      this.codeDialog = false;
    },
    // 金额转大写
    tocapital() {
      this.billForm.billAmountCpt = convertCurrency(this.billForm.billAmount);
      this.autoCharge('min');
      this.autoCharge('max');
    },
    // 判断是否有瑕疵
    getFlawStatus() {
      if (this.billForm.billFlaw.indexOf('无瑕疵') > -1) {
        this.billForm.flawStatus = 1;
        this.billForm.billFlaw = [];
        this.billForm.billFlaw.push('无瑕疵');
        this.flawList.forEach((i, index) => {
          if (index > 0) {
            i['disabled'] = true;
          }
        });
      } else {
        this.billForm.flawStatus = 2;
        this.flawList.forEach((i, index) => {
          i['disabled'] = false;
        });
      }
    },
    // 自动换算

    autoCharge(chargeType) {
      if (
        this.billForm.billAmount &&
        this.billForm.expireTime &&
        this.billForm.sellRateStr
      ) {
        console.log(this.billForm.billAmount, 'this.billForm.billAmount');
        this.billForm.billAmount = +this.billForm.billAmount;
        const closeDate = Math.ceil(
          (new Date(this.billForm.expireTime).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        ); // 到期天数
        if (chargeType == 'min') {
          let interest;

          interest = +(+this.billForm.sellRateStr)
            .div(36000)
            .mul(closeDate, +this.billForm.billAmount); // 票面贴现利息

          this.billForm.deductionStr = interest
            .div(+this.billForm.billAmount.div(100000))
            .toFixed(2);
        } else {
          let interest;
          interest = +(+this.billForm.sellRateEnd)
            .div(36000)
            .mul(closeDate, +this.billForm.billAmount); // 票面贴现利息
          this.$set(
            this.billForm,
            'deductionEnd',
            interest.div((+this.billForm.billAmount).div(100000)).toFixed(2)
          );
        }
      }
    },
    // 改变日期
    changeDate() {
      this.autoCharge('min');
      this.autoCharge('max');
    },
    // 增加票据
    addBill() {},
    // 强制阅读协议
    showAgreement() {
      if (this.checked) {
        this.billDialog = true;
        this.checked = false;
        this.readCountDown();
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      this.billDialog = false;
      this.checked = true;
    },
    // 提交票据
    submit() {
      if (!this.checked) {
        this.$alert('请先阅读票据收益转让协议');
      } else {
        this.$refs.billForm.validate((valid) => {
          let params = {};
          if (valid) {
            if (!this.billForm.hideStatus) {
              delete this.billForm.billFiles;
            }
            let obj = { billId: this.$route.query.id };
            ///  let obj={}
            Object.assign(
              obj,
              this.billForm,
              // {hideStatus: this.billForm.hideStatus?this.billForm.hideStatus = 2:this.billForm.hideStatus = 1},
              { hideStatus: this.billForm.hideStatus ? 2 : 1 },
              {
                pushStatus: this.billForm.pushStatus
                  ? (this.billForm.pushStatus = 2)
                  : (this.billForm.pushStatus = 1),
              },
              { billFlaw: this.billForm.billFlaw.join(',') }
            );

            let flag = true;
            // 新增合同发票录入
            var index = 0;
            this.ruleform2.contractList.map((el) => {
              var amount = 0,
                issome = false,
                amountarr = [];
              el.invoiceList.map((ele) => {
                amountarr.push(Number(ele.invoiceAmount));
                // Object.keys(ele).forEach((els) => {
                //   if (ele[els] == '') {
                //     issome = true;
                //   }
                // });
              });
              amount = (0).adds(...amountarr);

              if (el.imageList.length == 0) {
                this.$message.error(
                  '请上传合同编号:' + el.contractNo + '的合同'
                );
                flag = false;
                return;
              }
              if (el.invoiceList.length == 0) {
                this.$message.error(
                  '请上传合同编号:' + el.contractNo + '的发票'
                );
                flag = false;
                return;
              }
              console.log(el.contractAmount, amount, 99);

              this.$refs[el.name + index][0].validate((valid) => {
                if (valid) {
                  index++;
                  var invoiceAmounttotal = [];
                  let invoiceInfoList = [];
                  let tempArr = [];
                  this.ruleform2.contractList.map((el) => {
                    invoiceInfoList.push(...el.invoiceList);

                    el.invoiceList.map((ele) => {
                      invoiceAmounttotal.push(ele.invoiceAmount);
                    });
                  });

                  // 发票验重
                  invoiceInfoList.forEach((v) => {
                    v.unique = v.invoiceCode + v.invoiceNo;
                    if (tempArr.indexOf(v.unique) == -1) {
                      tempArr.push(v.unique);
                    } else {
                      this.$alert(
                        '发票代码：' +
                          v.invoiceCode +
                          ' 发票号码：' +
                          v.invoiceNo,
                        '发票信息重复',
                        {
                          confirmButtonText: '确定',
                          callback: (action) => {},
                        }
                      );
                      flag = false;
                      return false;
                    }
                    console.log(
                      v.invoiceCode.length,
                      '------------v.invoiceCode.length'
                    );
                    if (
                      v.invoiceCode.length <= 0 ||
                      v.invoiceCode.length > 12
                    ) {
                      this.$message.error(
                        '发票代码为12位以内数字，请重新输入后再提交'
                      );
                      flag = false;
                      return false;
                    }
                    if (v.invoiceNo.length <= 0 || v.invoiceNo.length > 8) {
                      this.$message.error(
                        '发票号码为8位以内数字，请重新输入后再提交'
                      );
                      flag = false;
                      return false;
                    }
                  });
                  if (!flag) {
                    return false;
                  }
                } else {
                  console.log('error submit!!');
                  this.$message.error('合同信息不能为空，请完善后再提交');
                  flag = false;
                  return false;
                }
              });
              params = Object.assign(
                obj,
                { contractAmount: this.mounttoall },
                this.ruleform2
                // this.PayableBillAddRequest,
                // this.ruleform,
                // { yfdMessageDto }
              );
              console.log(obj, '----------------obj');
            });

            if (flag) {
              console.log(params, '----------params');
              const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
              });
              api.UpdateBill(params).then((res) => {
                if (res.success) {
                  this.releaseSuccess = true;
                } else {
                  // this.billForm.hideStatus =  this.billForm.hideStatus==1?false:true
                  this.billForm.pushStatus =
                    this.billForm.pushStatus == 1 ? false : true;

                  this.$message.error(`${res.message}`);
                }
                loading.close();
              });
            }
          } else {
            this.$message.error('票据信息不能为空，请完善后再提交');
            //   this.billForm.billFlaw = [];
            return false;
          }
        });
      }
    },
    // 下架票据
    billOff() {
      api
        .SubmitBillResult({
          billId: this.$route.query.id,
          approveResult: 3,
        })
        .then((res) => {
          if (res.success) {
            this.$router.push('/bill/billmanage/6');
          } else {
            this.$message.error(`${res.message}`);
          }
        });
    },
    // 继续添加
    addOther() {
      this.$router.go(0);
      // this.releaseSuccess = false;
      // this.$refs[billForm].resetFields();
    },
    // 跳转至票据管理页面
    goBillManage() {
      this.$router.push('/bill/billmanage/1');
    },

    //获取详情
    getInfo(param) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      api
        .supplementDetail(param)
        .then((res) => {
          if ((res.code = '100000')) {
            this.financInfo = res.datas;
            //合同信息赋值
            var arr = [];
            this.financInfo.list.map((el, index) => {
              arr.push(el.contractAmount);
              this.ruleform2.contractList.push({
                name: 'form',
                contractAmount: '',
                contractNo: '',
                contractTime: '',
                imageList: [],
                invoiceList: [],
                otherList: [],
                fptotal: 0,
                rules1: {
                  contractNo: [
                    { required: true, message: '请输入合同编号' },
                    { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
                  ],
                  contractAmount: [
                    { required: true, message: '请输入合同金额' },
                    {
                      pattern: /^\d{1,12}(\.\d{1,2})?$/,
                      message: '请输入12位以内的数字,小数点后最多2位',
                    },
                  ],
                  contractTime: [{ required: true, message: '请选择签署日期' }],
                },
                filelist: [],
                filelistfp: [],
                otherListfp: [],
                otherListfp2: [],
                isactive: false,
              });
              this.ruleform2.contractList[index].contractAmount =
                el.contractAmount;
              this.ruleform2.contractList[index].contractNo = el.contractNo;
              this.ruleform2.contractList[index].contractTime = moment(
                el.contractTime
              ).format('YYYY-MM-DD');
              //合同
              el.billContractList.map((ele) => {
                this.ruleform2.contractList[index].filelist.push({
                  name: ele.fileName,
                  url: api.getImgURL(ele.fileUrl),
                  uid: ele.uid,
                });
                this.ruleform2.contractList[index].imageList.push({
                  fileCode: 14,
                  fileUrl: ele.fileUrl,
                  uid: ele.uid,
                  fileName: ele.fileName,
                });
              });
              //发票
              el.billInvoiceList.map((ele) => {
                this.ruleform2.contractList[index].filelistfp.push({
                  name: ele.fileName,
                  url: api.getImgURL(ele.fileUrl),
                  uid: ele.uid,
                });
                this.ruleform2.contractList[index].invoiceList.push({
                  payableBillFilleId: ele.billFileId,
                  invoiceBuyer: ele.invoiceBuyer,
                  invoiceSeller: ele.invoiceSeller,
                  invoiceCode: ele.invoiceCode,
                  invoiceNo: ele.invoiceNo,
                  invoiceOpenTime: ele.invoiceOpenTime,
                  invoiceAmount: ele.invoiceAmount,
                  fileCode: 15,
                  uid: ele.uid,
                  fileName: ele.fileName,
                });
              });
              //发票总金额
              this.ruleform2.contractList[index].fptotal = +el.invoiceAllAmount;
              //其他附件
              //物流
              // el.payableBillWuliuList.map(ele => {
              //     this.ruleform2.contractList[index].otherListfp.push({
              //     name: ele.fileName,
              //     url: api.getImgURL(ele.fileUrl),
              //     uid: ele.uid
              //     });
              //     this.ruleform2.contractList[index].otherList.push({
              //     fileCode: 16,
              //     name: ele.fileName,
              //     fileUrl: ele.fileUrl,
              //     uid: ele.uid,
              //     fileName: ele.fileName
              //     });
              // });
              //银行
              // el.payableBillBankList.map(ele => {
              //     this.ruleform2.contractList[index].otherListfp2.push({
              //     name: ele.fileName,
              //     url: api.getImgURL(ele.fileUrl),
              //     uid: ele.uid
              //     });
              //     this.ruleform2.contractList[index].otherList.push({
              //     fileCode: 17,
              //     name: ele.fileName,
              //     fileUrl: ele.fileUrl,
              //     uid: ele.uid,
              //     fileName: ele.fileName
              //     });
              // });
            });
            this.mounttoall = (0).adds(...arr);
          } else {
            this.$message.error(res.message);
          }
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    blurfptotal(val, index, item) {
      var arr = [];
      item.invoiceList.map((el) => {
        arr.push(Number(el.invoiceAmount));
      });
      item.fptotal = (0).adds(...arr);
    },
    beforeupfn(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (!/jpg|gif|bmp|png|jpeg/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg格式的图片');
        return false;
      }
      if (file.size > 1048576) {
        this.$message.error('请上传1M以内的图片');
        return false;
      }
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (!/jpg|gif|bmp|png|jpeg|zip|rar|pdf/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg,zip,rar,pdf格式的文件');
        return false;
      }
    },
    dragupfn(response, file, fileList, item, index) {
      //上传的合同

      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        item.filelist[item.filelist.length] = file;
        item.imageList.push({
          fileCode: 14,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        item.filelist = item.filelist;
        this.$forceUpdate();

        this.$message.error(file.name + '发票识别错误，请重新上传');
      }

      console.log(item.filelist, 654);
    },
    dragremovefn(file, fileList, item, index) {
      //移除合同
      console.log(file, fileList, item);
      if (item.imageList.findIndex((el) => el.uid == file.uid) != -1) {
        item.imageList.splice(
          item.imageList.findIndex((el) => el.uid == file.uid),
          1
        );
        item.filelist.splice(
          item.filelist.findIndex((el) => el.uid == file.uid),
          1
        );
        console.log(item.imageList);
      }
    },
    //上传发票
    handleupfn(response, file, fileList, item) {
      var data = response.message.datas.billFile;
      item.filelistfp[item.filelistfp.length] = file;
      item.filelistfp.forEach((el, index) => {
        el.name = index + 1;
      });
      item.invoiceList.push({
        ...data,
        fileCode: 15,
        uid: file.uid,
        fileName: file.name,
      });
      item.fptotal = (+item.fptotal).adds(data.invoiceAmount);

      if (response.code === -1) {
        this.$message.error('发票识别错误，请删除后重新上传或手动填写更新。');
      } else {
        this.$message.success(response.message);
      }
    },
    handleRemove(file, fileList, item) {
      //移除发票
      console.log(file, fileList);
      if (item.filelistfp.findIndex((el) => el.uid == file.uid) != -1) {
        item.filelistfp.splice(
          item.filelistfp.findIndex((el) => el.uid == file.uid),
          1
        );
        item.filelistfp.forEach((el, index) => {
          el.name = index + 1;
        });
        item.invoiceList.splice(
          item.invoiceList.findIndex((el) => el.uid == file.uid),
          1
        );

        var arr = [];
        item.invoiceList.map((el) => {
          arr.push(Number(el.invoiceAmount));
        });
        item.fptotal = (0).adds(...arr);
        console.log(item.invoiceList);
      }
    },
    erruploadfp(err) {
      this.$message.error('服务器错误');
    },
    jsmount() {
      var arr = [];
      this.ruleform2.contractList.map((el) => {
        arr.push(el.contractAmount);
      });
      this.mounttoall = (0).adds(...arr);
      console.log(arr);
      console.log(this.mounttoall);
    },
    addDomain() {
      this.ruleform2.contractList.push({
        name: 'form',
        contractAmount: '',
        contractNo: '',
        contractTime: '',
        imageList: [],
        invoiceList: [],
        otherList: [],

        fptotal: 0,
        rules1: {
          contractNo: [
            { required: true, message: '请输入合同编号' },
            { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
          ],
          contractAmount: [
            { required: true, message: '请输入合同金额' },
            {
              pattern: /^\d{1,12}(\.\d{1,2})?$/,
              message: '请输入12位以内的数字,小数点后最多2位',
            },
          ],
          contractTime: [{ required: true, message: '请选择签署日期' }],
        },
        filelist: [],
        filelistfp: [],
        // otherListfp: [],
        // otherListfp2: [],
        isactive: false,
      });
    },
    delDomain(index) {
      this.$confirm('此操作将删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
      })
        .then(() => {
          this.ruleform2.contractList.splice(index, 1);
          var arr = [];
          this.ruleform2.contractList.map((el) => {
            arr.push(Number(el.contractAmount));
          });
          this.mounttoall = (0).adds(...arr);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #ccc';
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #fff';
    },
  },
  components: {
    billAgreement,
  },
};
</script>
<style lang="scss">
.release-bill {
  background: #fff;
  padding: 33px 25px;
  .refuse-box {
    margin: 10px 0;
    span {
      font-size: 16px;
    }
  }
  .upload-box {
    margin-bottom: 40px;
    &-item {
      border: 1px solid #eee;
      background: #fff;
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 600;
        color: #222222;
        background: #ebebeb;
        .bill-example {
          display: inline-block;
          font-size: 12px;
          color: #2937a6;
          cursor: pointer;
        }
        .hide-payee {
          margin-left: 24px;
        }
      }
      &-cont {
        height: 300px;
        background: #fafafa;
        .el-upload {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: auto;
          .el-icon-plus {
            font-size: 50px;
            color: #c0c4cc;
            margin: 100px 0 10px;
          }
          .upload-img {
            // position: absolute;
            // top: 0;
            // left: 0;
            // width: 100%;
            // height: 100%;
            width: 100%;
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }
  .push-setting {
    margin-top: 40px;
    .push-title {
      padding-left: 40px;
    }
  }
  .border {
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e6e6;
    &.bill-form-box {
      padding-bottom: 18px;
    }
  }
  .bill-title {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 12px;
    margin-bottom: 24px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -7px;
      width: 3px;
      height: 14px;
      background: #cb8e51;
    }
  }
  .bill {
    &-form-box {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;
        padding: 0 20px;
        background: #eee;
      }
      .el-form {
        padding: 20px 0;
      }
      & + .bill-form {
        margin-top: 20px;
      }
      .connect-line {
        text-align: center;
      }
      .el-date-editor {
        width: 100% !important;
      }
      .el-select {
        width: 100%;
      }
    }
    &-add {
      text-align: center;
      border: 1px solid #eee;
      padding: 10px 20px;
      margin-top: 20px;
      .add-icon {
        display: inline-block;
        margin-right: 20px;
      }
      .add-bill-btn {
        font-size: 18px;
        color: #6e6e6e;
        font-weight: 600;
        cursor: pointer;
      }
    }
    &-notice {
      margin: 24px 0;
    }
    &-submit {
      margin-top: 20px;
      padding: 20px;
      text-align: center;
      button {
        width: 160px;
        & + button {
          margin-left: 32px;
        }
      }
    }
  }
  .el-dialog {
    &__body {
      #codeBox {
        margin: 0 auto;
        text-align: center;
        width: 720px;
        height: 500px;
      }
      img {
        max-width: 100%;
      }
    }
  }
}
.release-success {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 100px 0;
  text-align: center;
  background: #fff;
  i {
    font-size: 100px;
    color: #09bb07;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .el-button {
    width: 120px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin-top: 30px;
    text-align: center;
    & + .el-button {
      margin-left: 50px;
    }
  }
}
</style>
