<!-- 票据详情 -->
<template>
    <div class="absmaininfo">
        <layout-main-view :breadcrumb='breadcrumblist'>

        <div class="absaccountpost">
           <absaccountxmgk :detailsList='absPlanDto' :absPlanDetailDto='absPlanDetailDto'></absaccountxmgk>
            <absbindaccountsfundno :datalist='datalist' :moneytotal='moneytotal' :planCorpType='planCorpType' v-if="moneytotal>0&&planCorpType"></absbindaccountsfundno>
        </div>
     <div class="card-hd card-bottom" >
        <el-row >
          <el-col  class="lb_body btnmar" >
            <el-button class="card-btn"  type="primary" size="small" @click="goBack">返回</el-button>
          </el-col>

        </el-row>
      </div>

        </layout-main-view>

    </div>
</template>
<script>
    import api from "@/api/api";
    import  absaccountxmgk from '@/views/absbase/absaccountxmgk' 
    import  absbindaccountsfundno from '@/views/absbase/absbindaccountsfundno' 


 


    export default {
        data() {
            return {
                breadcrumblist:['专项计划简介'],
                datalist:[],
                moneytotal:0,
                absPlanDto:{},
                absPlanDetailDto:{},
                planCorpType:'',
                isbl:false

     
            }
        },
        created() {
             api.getEnterprisePlanDetail({absPlanId: this.$route.query.id}).then(res =>{ //审批结果查询
                if(res.success) {
                    this.absPlanDto =res.datas.planDetail.absPlanDto
                    this.absPlanDetailDto=res.datas.planDetail.absPlanDetailDto
                        this.planCorpType=res.datas.planDetail.planCorpType
    
                }
            });
    this.isbl=JSON.parse(sessionStorage.user).corpType==1?true:false

               api.getAccountListByCorp({absPlanId: this.$route.query.id,type:this.isbl?6:2}).then(res =>{ //审批结果查询
                if(res.success) {
                        this.datalist=res.datas.detail.list;
                        this.moneytotal=res.datas.detail.totalAmount


   
                }
            })
        },
        methods: {
                goBack(){
                    history.go(-1)
                }
        },
        components: {
           absaccountxmgk,
           absbindaccountsfundno

      
        }
    }
</script>
<style lang="scss">
   .absmaininfo{
       .content-container-inner{
         padding-top: 20px;
       }
       .absaccountpost{
           padding: 20px;
         background: #ffffff;

       }
     

   }
</style>