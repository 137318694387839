<template>
    <div class="companyBeneficiaries-box">
        <el-card shadow="never" class="companyBeneficiaries-card">
            <el-row>企业受益人</el-row>
                <div v-for="(form, index) in formList" :key="index">
                    <el-row type="flex" justify="end">
                        <el-button type="text" @click="handleDeleteForm(index)">删除</el-button>
                    </el-row>
                    <el-form 
                        ref="form" 
                        :model="form"
                        :rules="rules"
                        label-width="150px">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="受益人姓名：" prop='earningOwnerName'>
                                    <el-input v-model="form.earningOwnerName" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item  prop='earningOwnerType' label="受益人性质：">
                                    <el-select v-model="form.earningOwnerType" filterable>
                                        <el-option  v-for="(item,index) in dicList36" 
                                        :key="index" 
                                        :label="item.name" 
                                        :value="item.code" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item 
                                    v-if="form.earningOwnerType=='5'" 
                                    prop='earningOwnerTypeName' 
                                    label="自定义性质：" >
                                    <el-input v-model="form.earningOwnerTypeName" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="8">
                                <el-form-item prop='earningOwnerCountry' label="受益人国籍：">
                                    <el-select v-model="form.earningOwnerCountry" filterable>
                                        <el-option  v-for="(item,index) in bankCountryList" 
                                            :key="index" 
                                            :label="item.name" 
                                            :value="item.twoCode" />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item label="证件类型：" prop='earningOwnerIdType'>
                                    <el-select v-model="form.earningOwnerIdType" filterable>
                                        <el-option  v-for="(item,index) in dicList37" 
                                            :key="index" 
                                            :label="item.name" 
                                            :value="item.code" />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item label="证件号码：" 
                                    prop="earningOwnerIdNo"
                                    :rules="earningOwnerIdNoRule(form.earningOwnerIdType)">
                                    <el-input v-model="form.earningOwnerIdNo" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="8">
                                <el-form-item prop='earningOwnerLimitDate' label="证件到期日：" >
                                    <el-date-picker 
                                        type="date" 
                                        placeholder="选择日期" 
                                        v-model="form.earningOwnerLimitDate" 
                                        value-format="yyyyMMdd" />
                                </el-form-item>
                            </el-col>

                            <el-col :span="16">
                                <el-form-item prop="earningOwnerAddress" label="受益人地址：" >
                                    <el-input v-model="form.earningOwnerAddress" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="8">
                                <el-form-item prop="idCardFrontList" ref="idCardPositiveFormItem" label="受益人身份证：">
                                     <span style="color:#ccc;font-size:14px">人像面</span>
                                    <ImgUpload
                                        :key="index"
                                        :limit="1"
                                        :fileCode="2"
                                        ref="idCardPositiveImg"
                                        v-model="form.idCardFrontList"
                                        @success="handleUploadSuccess('idCardPositiveFormItem', index)"></ImgUpload>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item prop="idCardBackList" ref="idCardNegativeFormItem" >
                                    <span style="color:#ccc;font-size:14px">国徽面</span>
                                    <ImgUpload
                                        :key="index"
                                        :limit="1"
                                        :fileCode="3"
                                        ref="idCardNegativeImg"
                                        v-model="form.idCardBackList"
                                        @success="handleUploadSuccess('idCardNegativeFormItem', index)"></ImgUpload>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <p v-if="index != formList.length - 1" style="height:1px;border-bottom:1px solid #D9D9D9;"></p>
                </div>
        </el-card>
        <el-card  
            shadow="never" 
            class="companyBeneficiaries-add-card"
            @click.native="handleAddForm">
            <el-row type="flex" justify="center" align="middle">
                <i class="el-icon-plus"></i> &nbsp;&nbsp; 添加企业受益人
            </el-row>
        </el-card>
    </div>
  
</template>

<script>
import ImgUpload from './ImgUpload.vue'

export default {
    name: 'companyBeneficiaries',
    props: ['dicList36', 'bankCountryList', 'dicList37'],
    components: {
        ImgUpload
    },
    data() {
        return {
            formItem: {
                earningOwnerType: '',
                earningOwnerTypeName: '',
                earningOwnerName: '',
                earningOwnerCountry: 'CN',
                earningOwnerIdType: 'A',
                earningOwnerIdNo: '',
                earningOwnerLimitDate: '',
                earningOwnerAddress: '',
                idCardFrontList: [],
                idCardBackList: [],
            },
            formList: [{
                earningOwnerType: '',
                earningOwnerTypeName: '',
                earningOwnerName: '',
                earningOwnerCountry: 'CN',
                earningOwnerIdType: '',
                earningOwnerIdNo: '',
                earningOwnerLimitDate: '',
                earningOwnerAddress: '',
                idCardFrontList: [],
                idCardBackList: [],
            }],
        }
    },
    computed: {
        rules() {
            return {
                earningOwnerName: [
                    { required: true, message: "请输入受益人姓名", trigger: "blur" }
                ], 
                earningOwnerType: [
                    { required: true, message: "请选择受益人性质", trigger: "change" }
                ], 
                earningOwnerTypeName: [
                    { required: true, message: "请输入自定义性质", trigger: "blur" }
                ], 
                earningOwnerCountry: [
                    { required: true, message: "请选择受益人国籍", trigger: "change" }
                ], 
                earningOwnerIdType: [
                    { required: true, message: "请选择证件类型", trigger: "change" }
                ], 
                 earningOwnerLimitDate: [
                    { required: true, message: "请选择证件到期日", trigger: "change" }
                ],
                earningOwnerAddress: [
                    { required: true, message: "请输入受益人地址", trigger: "blur" }
                ],  
                idCardFrontList: [
                    { required: true, message: '请上传身份证(头像面)', trigger: 'blur' }
                ],
                idCardBackList: [
                    { required: true, message: '请上传身份证(国徽面)', trigger: 'blur' }

                ],
            }
        },
    },
    methods: {
        handleUploadSuccess(refName, index) {
            this.$message.success('上传成功');
            this.$refs[refName][index].clearValidate()
        },
        async handleAddForm() {
            // const result = await this.getFormValidateByIdx(this.formList.length - 1)
            this.$set(this.formList, this.formList.length, _.cloneDeep(this.formItem))
        },
        handleDeleteForm(index) {
            if(this.formList.length !== 1) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                         this.$delete(this.formList, index)
                        const list = this.getFormatFormList()
                        this.$nextTick(() => {
                            this.setFormData(list)
                            this.clearValidate()
                        })
                        this.$message({ type: 'success', message: '删除成功!' });

                }).catch(() => {
                    this.$message({ type: 'info', message: '已取消删除' });          
                });
            } else {
                this.$message.warning('至少保留一个企业受益人');
            }
           
        },
        getFormValidateByIdx(index) {
            return new Promise(resolve => {
                this.$refs.form[index].validate(valid => resolve(valid))
            })
        },
        earningOwnerIdNoRule(earningOwnerIdType) {
            if(earningOwnerIdType === 'A') {
                return  [
                    { required: true, message: "请输入证件号码", trigger: "blur" },
                    { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                ]
            }
            return [{ required: true, message: "请输入证件号码", trigger: "blur" }]
        },
        getFormatFormList() {
            return _.map(this.formList, item => {
                const earningOwnerFrontImg = !_.isEmpty(item.idCardFrontList) ? item.idCardFrontList[0].fileName : '';
                const earningOwnerFrontImgUrl = !_.isEmpty(item.idCardFrontList) ? item.idCardFrontList[0].fileUrl : '';
                const earningOwnerBackImg = !_.isEmpty(item.idCardBackList) ? item.idCardBackList[0].fileName : '';
                const earningOwnerBackImgUrl = !_.isEmpty(item.idCardBackList) ? item.idCardBackList[0].fileUrl : '';

                const omitItem = _.omit(item, ['idCardFrontList', 'idCardBackList'])
                const fileObj = {earningOwnerFrontImg, earningOwnerFrontImgUrl, earningOwnerBackImg, earningOwnerBackImgUrl}
                return _.assign(omitItem, fileObj)
                
            })
        },
        setFormData(list) {
            const result = _.map(list, (item, index) => {

                const idCardFrontList = !_.isEmpty(item.earningOwnerFrontImgUrl) ? [{ fileName: item.earningOwnerFrontImg, fileUrl: item.earningOwnerFrontImgUrl}] : []
                const idCardBackList = !_.isEmpty(item.earningOwnerBackImgUrl) ? [{ fileName: item.earningOwnerBackImg, fileUrl: item.earningOwnerBackImgUrl}] : []
                const omitItem = _.omit(item, ['earningOwnerFrontImg', 'earningOwnerFrontImgUrl', 'earningOwnerBackImg', 'earningOwnerBackImgUrl'])
                const fileObj = {idCardFrontList, idCardBackList}
                return _.assign(omitItem, fileObj)
            })
            this.formList = result;

            this.$nextTick(() => {
                for(let i = 0; i < this.formList.length; i++ ) {
                    this.$refs.idCardPositiveImg[i].setFileList(this.formList[i].idCardFrontList)
                    this.$refs.idCardNegativeImg[i].setFileList(this.formList[i].idCardBackList)
                }
            })
        },
        async getFormListValidate() {
            let resultArr = [];
            for(let i = 0; i < this.$refs.form.length; i ++ ) {
                const subResult = await this.getFormValidateByIdx(i);
                resultArr.push(subResult)
            }
            return _.every(resultArr)
        },
        clearValidate() {
            _.forEach(this.$refs.form, item => {
                item.clearValidate()
            })
        }
    }
}
</script>

<style lang='scss' >
.companyBeneficiaries-box {
    padding: 0 12px;
    .companyBeneficiaries-card, .companyBeneficiaries-add-card {
        border: 1px dashed #d9d9d9;
        .el-card__body {
            padding: 12px;
        }
    }
    .companyBeneficiaries-add-card {
        color: #828282;
        margin-top: 12px;
        cursor: pointer;
    }
}
</style>