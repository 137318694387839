<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list
          :filters="filterList"
          :isShowAll="isShowAll"
          :isHedden="isHedden"
          @data-showAll="filterShowAll"
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist"
          :otherFunc="otherFunc"
        >
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%;"
          >
            <el-table-column prop="phone" label="手机号" min-width="170"></el-table-column>
            <el-table-column prop="realName" label="用户姓名" min-width="170"></el-table-column>
            <el-table-column prop="roleName" label="用户角色" min-width="120"></el-table-column>
            <el-table-column prop="email" label="电子邮箱" min-width="200"></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              min-width="170"
              :formatter="(row) => weapon.formatTime(row.createTime)"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="是否通知人"
              min-width="120"
              :formatter="(row) => (row.messageNotifyPeople == 1 ? '是' : '否')"
            ></el-table-column>
            <el-table-column prop="userStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.userStatus == 1">启用</span>
                <span v-if="scope.row.userStatus == 2">禁用</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleDisable(scope.row)" v-if="scope.row.userStatus == 1"
                  >禁用</el-button
                >
                <el-button size="medium" type="text" @click="handleStart(scope.row)" v-else>启用</el-button>
                <el-button size="medium" type="text" @click="handleReset(scope.row)">重置密码</el-button>
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
      <el-dialog :title="isAdd ? '添加用户' : '编辑用户'" :visible.sync="dialogVisible" width="600px" class="dislog">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="150px" class="demo-ruleForm" :rules="rules">
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" :disabled="!isAdd"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="realName">
            <el-input v-model="ruleForm.realName" placeholder="请输入用户姓名"></el-input>
          </el-form-item>
          <el-form-item label="用户角色" prop="roleId">
            <el-select v-model="ruleForm.roleId" placeholder="请选择用户角色">
              <el-option
                v-for="(item, index) in option"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="userStatus">
            <el-select v-model="ruleForm.userStatus" placeholder="请选择状态">
              <el-option
                v-for="(item, index) in option2"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
             <el-form-item label="密码有效期" prop="deadLine">
            <el-select v-model="ruleForm.deadLine" placeholder="请选择密码有效期">
              <el-option
                v-for="(item, index) in option3"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图形验证码：" prop="captcha" v-if="isAdd">
            <el-row :gutter="20">
              <el-col :span="10" style="padding-left: 0px;padding-right: 0px;">
                <el-input v-model="ruleForm.captcha" placeholder="验证码" style="width: 200px"></el-input>
              </el-col>
              <el-col :span="10" class="bus-captcha">
                <img :src="captchaPath" @click="getCaptcha()" alt />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="短信验证码：" prop="smsVerifyCode" v-if="isAdd">
            <el-input v-model="ruleForm.smsVerifyCode" placeholder="请输入您的验证码" style="width: 200px"></el-input>
            <el-button
              v-if="sendCode"
              @click.native="getMessage"
              type="primary"
              style="width: 135px;height: 40px;background: #2937A6"
              >获取验证码</el-button
            >
            <el-button v-if="!sendCode" style="width: 140px;height: 40px;">{{ sendTime }}s后重新发送</el-button>
          </el-form-item>
          <el-form-item label="是否短信通知联系人">
            <el-checkbox
              v-model="ruleForm.messageNotifyPeople"
              :true-label="1"
              :false-label="2"
              :disabled="isdisabled"
            ></el-checkbox>
          </el-form-item>
        </el-form>
        <p class="bottom-text">注：新建用户初始密码为：Aa123456 请及时修改密码</p>
        <div class="fr">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd" :loading="isLoaing">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
import api from "../../api/api.js"
import * as weapon from "@/util/weapon.js"
import moment from "moment"
import { getUUID } from "@/util/index.js"

export default {
  data() {
    return {
      weapon,
      isLoaing: false,
      breadcrumblist: ["用户管理"],
      ruleForm: {
        phone: "",
        realName: "",
        roleId: "",
        email: "",
        userStatus: "",
        captcha: "",
        smsVerifyCode: "",
        messageNotifyPeople: 2,
        deadLine:90,
        uuid: ""
      },
      rules: {
        realName: [{ required: true, message: "请输入用户姓名", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择用户角色", trigger: "change" }],
        userStatus: [{ required: true, message: "请选择状态", trigger: "change" }],
        email: [
          { required: true, message: "请输入电子邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
            message: "请输入正确的电子邮箱",
            trigger: "blur"
          }
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        captcha: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
        smsVerifyCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }]
      },
      option: [],
      option2: [
        { name: "启用", value: 1 },
        { name: "禁用", value: 2 }
      ],
          option3: [
        { name: "永久", value: -1 },
        { name: "90天", value:90 },
        { name: "180天", value: 180 },

      ],
      dialogVisible: false,
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "手机号",
          type: "input",
          field: "phone",
          size: "small"
        },
        {
          label: "姓名: ",
          type: "input",
          field: "realName",
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      otherFunc: [{ text: "+ 新建", func: this.openDialog }],
      filters: "",
      isAdd: true,
      sendTime: 0,
      captchaPath: "",
      sendCode: true,
      isdisabled: false,
      isChainCorp: JSON.parse(sessionStorage.corpList).isChainCorp,
    }
  },
  created() {
    this.getSysRole()
    this.getCaptcha()
  },
  methods: {
    moment,
    getSysRole() {
      api.GetSysRole({}, { corpType: JSON.parse(sessionStorage.user).corpType }).then((res) => {
        if (res.success) {
          const arrOption = []
          Object.keys(res.datas.list).forEach((val) => {
            arrOption.push({ name: res.datas.list[val], value: val })
          })
          if(this.isChainCorp === 1) { // 链属企业
            this.option = arrOption.filter( p => {
              return p.name.includes('+')
            })
          } else {
            this.option = arrOption.filter( p => {
              return !p.name.includes('+')
            })
          }
        }
      })
    },
    submitAdd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isLoaing = true
          const API = this.isAdd ? api.AddSysUser : api.EditSysUser
          if (this.isAdd) {
            this.ruleForm.userId = ""
          }

          if (this.ruleForm.messageNotifyPeople == 2) {
            api
              .popMessage({ phone: this.ruleForm.phone })
              .then((res) => {
                if (res.success) {
                  if (res.datas.pop) {
                    this.$alert("你还没有设置公司短信通知联系人,管理员为本公司短信通知联系人")

                    this.$confirm("你还没有设置公司短信通知联系人,管理员为本公司短信通知联系人", "提示", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        API(this.ruleForm)
                          .then((res) => {
                            this.isLoaing = false
                            if (res.success) {
                              this.$alert(res.message)
                              this.dialogVisible = false
                              this.getLists(this.filters)
                            } else {
                              this.$message.error(res.message)
                            }
                          })
                          .catch(() => {
                            this.isLoaing = false
                          })
                      })
                      .catch(() => {
                        this.isLoaing = false
                      })
                  } else {
                    API(this.ruleForm)
                      .then((res) => {
                        this.isLoaing = false
                        if (res.success) {
                          this.$alert(res.message)
                          this.dialogVisible = false
                          this.getLists(this.filters)
                        } else {
                          this.$message.error(res.message)
                        }
                      })
                      .catch(() => {
                        this.isLoaing = false
                      })
                  }
                }
              })
              .catch(() => {
                this.isLoaing = false
              })
          } else {
            API(this.ruleForm)
              .then((res) => {
                this.isLoaing = false
                if (res.success) {
                  this.$alert(res.message)
                  this.dialogVisible = false
                  this.getLists(this.filters)
                } else {
                  this.$message.error(res.message)
                }
              })
              .catch(() => {
                this.isLoaing = false
              })
          }
        }
      })
    },
    openDialog() {
      this.dialogVisible = true
      this.isAdd = true
      this.sendCode = true
      this.ruleForm.captcha = ""
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.isdisabled = false
        this.ruleForm.messageNotifyPeople = 2
      })
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll
      console.log("filterShowAll  -----", this.isShowAll)
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" }
    },
    handleRefresh(filters) {
      this.filters = filters
      this.getLists(filters)
    },
    //查询
    getLists(param) {
      const data = param
      data.userSource = 2
      data.relationCorpId = JSON.parse(sessionStorage.user).relationCorpId
      api.GetSysUserListPage(data).then((res) => {
        console.log("res ===>", res)
        if (res.success) {
          this.listData = res.datas.list
          this.total = res.datas.total
          console.log("list ===>", this.listData)
        }
        this.listLoading = false
      })
    },
    handleEdit(row) {
      this.dialogVisible = true
      this.isAdd = false
      this.sendCode = true

      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        let temp = this.option.find((val) => val.name === row.roleName) || {}
        this.ruleForm = { ...this.ruleForm, ...row }
        delete this.ruleForm.roleName
        this.ruleForm.roleId = temp.value
        console.log(this.ruleForm, "pp")
        if (this.ruleForm.registyType == 1) {
          this.ruleForm.messageNotifyPeople = 1
          this.isdisabled = true
        } else {
          this.isdisabled = false
        }
        this.getCaptcha()
      })
    },
    handleReset(row) {
      this.$confirm("请再次确认是否要重置该客户的登录密码!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const DATA = {
          userId: row.userId,
          newPassword: "Aa123456"
        }
        api.modifyPassword(DATA).then((res) => {
          if (res.success) {
            this.$alert('密码重置成功！密码为"Aa123456"')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    handleStart(row) {
      this.$confirm("是否启用该账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = { ...row }
          data.userStatus = 1
          delete  data['password']

          api.EditSysUser(data).then((res) => {
            if (res.success) {
              this.$alert(res.message)
              this.getLists(this.filters)
            }
          })
        })
        .catch(() => {})
    },
    handleDisable(row) {
      this.$confirm("是否禁用该账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = { ...row }

       
          delete  data['password']
          data.userStatus = 2
          api.EditSysUser(data).then((res) => {
            if (res.success) {
              this.$alert(res.message)
              this.getLists(this.filters)
            }
          })
        })
        .catch(() => {})
    },
    // 获取验证码
    getCaptcha() {
      this.ruleForm.uuid = getUUID()
      this.captchaPath = api.verifycode(this.ruleForm.uuid)

      console.log(this.ruleForm.uuid, "getUUID")
    },
    getMessage() {
      console.log("22222222")
      var regu = /^1\d{10}$/
      var re = new RegExp(regu)
      if (re.test(this.ruleForm.phone)) {
        this.getCode()
      } else {
        this.$message.error("手机号格式错误")
        return
      }
    },
    getCode() {
      let param = {
        uuid: this.ruleForm.uuid,
        phone: this.ruleForm.phone,
        realName: this.ruleForm.realName,
        verifyCode: this.ruleForm.captcha
      }
      api.getPhoneVerifyCode(param).then((res) => {
        if (res.success) {
          this.sendCode = false
          this.sendTime = 60
          var auth_timetimer = setInterval(() => {
            this.sendTime--
            if (this.sendTime <= 0) {
              this.sendCode = true
              clearInterval(auth_timetimer)
            }
          }, 1000)
          this.$message.success("发送成功")
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/moreDetail/moudleDetail.scss";
.bottom-text {
  margin-bottom: 30px;
  color: red;
}
.Tips {
  width: 98%;
  height: 40px;
  line-height: 40px;
  background-color: #2937a6;
  border-radius: 5px;
  /*margin: 20px 0 20px 0;*/
  /*padding-left: 50px;*/
  opacity: 0.1;
  position: absolute;
}
.Tips-title {
  margin-bottom: 20px;
  height: 40px;
  width: 100%;
  // background-image: url("../../assets/gt.png");
  background-repeat: no-repeat;
  background-position: 1.5% 50%;
}
p {
  padding-left: 50px;
  color: #2937a6;
  width: 100%;
  position: relative;
  line-height: 40px;
  /*top:20px*/
}
.el-dialog .el-dialog__header {
  border-bottom: 1px solid #e6e6e6 !important;
}
.demo-ruleForm {
  /*margin-bottom: 80px;*/
  margin-top: 20px;
}
.el-button {
  line-height: 32px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}
.el-dialog {
  height: 65% !important;
}
.fr {
  position: absolute;
  right: 40px;
  bottom: 20px;
}
.an {
  border: 1px solid transparent; //自定义边框
  outline: none; //消除默认点击蓝色边框效果
  color: #2937a6;
}
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.bus-captcha {
  height: 40px;
  img {
    width: 100%;
    cursor: pointer;

    margin-left: 30px;
    height: 40px;
  }
}
</style>
