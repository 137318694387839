<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList" style="padding: 10px 24px;">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist">
        <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row v-loading="listLoading" style="width: 100%;">
          <el-table-column prop="payableBillFinanceNo" label="融资项目编号" min-width="180"></el-table-column>
          <el-table-column prop="grantCreditNo" label="授信编号" min-width="140"></el-table-column>
          <el-table-column prop="financeCorpName" label="企业名称" min-width="170"></el-table-column>
          <el-table-column prop="amount" label="融资金额（元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
          </el-table-column>
          <el-table-column prop="fundYearInterest" label="年化利率（％）" min-width="150"></el-table-column>
          <el-table-column prop="financeSignStatus" label="签约状态" min-width="120" :formatter="statusfn">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="downloadContract(scope.row)" :disabled="scope.row.financeSignStatus != 50">下载合同</el-button>
              <el-button size="medium" type="text" @click="viewDetails(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import moment from "moment";
function openDownloadDialog(url, saveName) {
  // for ie 10 and later
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
export default {
  data() {
    return {
      breadcrumblist: ["融资签约管理"],
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "企业名称:",
          type: "input",
          field: "corpName",
          size: "small"
        },
        {
          label: "融资金额(元): ",
          type: "inputrange",
          field: "mixAmount",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "maxAmount",
          size: "small"
        },
        {
            label: "年化利率(%): ",
            type: "inputrange",
            field: "mixFundYearInterest",
            size: "small",
            isSeparated: 1
        },
        {
            type: "inputrange",
            field: "maxFundYearInterest",
            size: "small"
        }
      ],
      api: api,
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      filters: {}
    };
  },
  created() {},
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      (filters.review = 1), (filters.source = 2), (this.filters = filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      api.getFundFinanceListByPage(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    statusfn(val){
      let state=''
      switch (val.financeSignStatus) {
        case 50:
          state='双方已签约'
          break;
        default: 
          state="未签约"
          break;
      }
      return state
    },
    downloadContract(row) {
      api.fundDownLoadFile({ payableBillFinanceId: row.payableBillFinanceId, payableBillFinanceNo: row.payableBillFinanceNo }).then(res => {
        openDownloadDialog(res, '合同' + '.zip')
      }).catch((err) => {
      })
    },
    viewDetails(row) {
      this.$router.push({
        name: "资方融资签约管理详情",
        query: { payableBillId: row.payableBillId, payableBillFinanceId: row.payableBillFinanceId }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";

.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
